import { Component, Input, OnInit } from '@angular/core';
import { Icons } from '../../cons/icons';
import { CResumen } from './CResumen';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
@AutoUnsubscribe
@Component({
  selector: 'app-resumen',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.scss']
})
export class ResumenComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  @Input() resumen:CResumen = new CResumen();

  constructor() { }

  ngOnInit(): void {
  }

}
