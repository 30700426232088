import { ITable } from "./ITable";

export class Table implements ITable{
  data: any[]=[];
  columnaEstado:string ='';
  columnaImagen:string ='';
  columnaEstiloEstado:string | null = null;  
  modoXs:string[]=[];//max 3 columnas
  modoM:string[]=[]; // 3 a 5 columnas
  modoLg:string[]=[];// mas de 5
  columnaChip?:string='';
  pageSizeOptions:number[]=[];
  pageSize:number=10;
  currentPage:number=1;
  totalRows:number=0;
  operaciones?:string[];
  nextPage: boolean | null=null;
  previousPage: boolean | null=null;
  minPage: boolean | null=null;
  maxPage: boolean | null=null;
  filtro:any[]=[];
  usuarioAdministrador:boolean = false;
}
