import { Injectable } from '@angular/core';
import { ConfiguracionGeneralDaService } from './configuracion-general-da.service';
import { Observable } from 'rxjs';
import { IConfigGen } from './models/IConfigGen';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionGeneralBlService {

  constructor(
    private configGenServices:ConfiguracionGeneralDaService
  ) { }
  
  existFetchConfigGen(){    
    this.configGenServices.fetchConfigGen();
  }

  getConfigGen():Observable<IConfigGen | null | undefined>{
    return this.configGenServices.confGen$;
  }

}
