<div fxLayout="row" fxLayoutAlign="center center" [style.marginLeft.px]="5" [style.marginRight.px]="5">
  <form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <mat-card class="mat-card">
      <mat-card-header>
        <mat-card-title>Datos</mat-card-title>
        <mat-card-subtitle>Administrador de Plataforma</mat-card-subtitle>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" class="form-tipo-usuario">
          <mat-form-field>
            <mat-label>Tipo de Usuario</mat-label>
            <mat-select
            required name="tipoUsuario"
            [(ngModel)]="cusuario.tipo_usuario"
            #tipoUsuario="ngModel" disabled="true">
              <mat-option *ngFor="let rol of lRoles_primarcas" [value]="rol.value">{{rol.denominacion}}</mat-option>
            </mat-select>
            <mat-error *ngIf="tipoUsuario.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          </mat-form-field>
          <mat-chip-list class="mat-chip-list-stacked">
            <mat-chip *ngIf="cusuario.tipo_usuario==lRoles_primarcas[0].value" color="primary">Responsable del
              negocio</mat-chip>
            <mat-chip *ngIf="cusuario.tipo_usuario==lRoles_primarcas[1].value" color="secondary">Colaborador del
              negocio</mat-chip>
          </mat-chip-list>
        </div>
        <mat-divider></mat-divider>
        <div [style.marginTop.px]="20">
          <label class="secciones" >Datos Personales.</label>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" class="form-datos-personales">
          <mat-form-field>
            <input required type="text" matInput placeholder="Nombres" name="nombres" ngModel
              [pattern]="PatternsRegex.onlyCharsTwoWordsNoSpanish" #nombreInput="ngModel"
              [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
              [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
              [disabled]="disabled_controls"
              oninput="this.value = this.value.toUpperCase()"
              [(ngModel)]="cusuario.nombres"
              >
            <mat-error *ngIf="nombreInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error
              *ngIf="nombreInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_2PALABRAS}}</mat-error>
            <mat-error
              *ngIf="nombreInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
            <mat-error
              *ngIf="nombreInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>

          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput placeholder="Apellido Paterno" name="apPaterno"
              [pattern]="PatternsRegex.onlyCharsOneWordsSpanish" #apPaterno="ngModel" required
              [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
              [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3" ngModel
              [disabled]="disabled_controls"
              oninput="this.value = this.value.toUpperCase()"
              [(ngModel)]="cusuario.apellido_paterno"
              >
            <mat-error *ngIf="apPaterno.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error
              *ngIf="apPaterno.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_1PALABRA}}</mat-error>
            <mat-error
              *ngIf="apPaterno.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
            <mat-error
              *ngIf="apPaterno.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
          <mat-form-field>
            <input type="text" matInput placeholder="Apellido Materno" name="apMaterno"
              [pattern]="PatternsRegex.onlyCharsOneWordsSpanish" #apMaterno="ngModel" required
              [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
              [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3" ngModel
              [disabled]="disabled_controls"
              oninput="this.value = this.value.toUpperCase()"
              [(ngModel)]="cusuario.apellido_materno"
              >
            <mat-error *ngIf="apMaterno.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error
              *ngIf="apMaterno.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_1PALABRA}}</mat-error>
            <mat-error
              *ngIf="apMaterno.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
            <mat-error
              *ngIf="apMaterno.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
          </mat-form-field>       
          <mat-form-field>                  
            <mat-label>Fecha de Nacimiento </mat-label>
            <input matInput placeholder="dd/mm/aaaa" [max]="maxDate" [min]="minDate" 
              #cumpleanios="ngModel"
              ngModel 
              name="cumpleanios"               
              [matDatepicker]="picker"
              [(ngModel)]="cusuario.fecha_nacimiento"
              disabled
              required
              >
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker [disabled]="disabled_controls" ></mat-datepicker>
            <mat-error *ngIf="cumpleanios.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO_FORMATO_INCORRECTO}}</mat-error>
            <mat-error *ngIf="isDateMaxError()">{{Msjs_Validations.MSJ_VAL_FECHA_MAXIMA_INCORRECTA}}</mat-error>
            <mat-error *ngIf="isDateMinError()">{{Msjs_Validations.MSJ_VAL_FECHA_MINIMO_INCORRECTA}}</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutGap.xs="10px">
          <mat-form-field>
            <mat-label>Sexo</mat-label>
            <mat-select required name="ddlSexo" #ddlSexo="ngModel" [disabled]="disabled_controls"
            [(ngModel)]="cusuario.sexo"
            >
              <mat-option *ngFor="let sexo of lSexo" [value]="sexo.value">{{sexo.denominacion}}</mat-option>
            </mat-select>
            <mat-error *ngIf="ddlSexo.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput placeholder="Teléfono movil" name="telefMovil" ngModel
              [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_9"
              [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_9" #telefMovil="ngModel"
              [pattern]="PatternsRegex.onlyIntegerUnsigned" required
              [disabled]="disabled_controls"
              (keypress) ="onlyNumbers($event)"
              [(ngModel)]="cusuario.telefono"
              >
            <mat-error *ngIf="telefMovil.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="telefMovil.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
            <mat-error *ngIf="telefMovil.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_MIN9_DIGITOS}}</mat-error>
            <mat-error *ngIf="telefMovil.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_MAX9_DIGITOS}}</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
          <mat-form-field>
            <mat-label>Tipo Documento</mat-label>
            <mat-select required name="ddlTipoDocumento" #ddlTipoDocumento="ngModel" [disabled]="disabled_controls"
            [(ngModel)]="cusuario.tipo_documento"
            >
              <mat-option *ngFor=" let tdocumento of lTipo_documento"
                [value]="tdocumento.value">{{tdocumento.denominacion}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="ddlTipoDocumento.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput placeholder="Núm. Documento" name="numDocumento" ngModel
              [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_9"
              [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8" #numDocumento="ngModel"
              [pattern]="PatternsRegex.onlyIntegerUnsigned" required
              [disabled]="disabled_controls"
              (keypress) ="onlyNumbers($event)"
              [(ngModel)]="cusuario.numero_documento"
              >
            <mat-error *ngIf="numDocumento.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="numDocumento.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
            <mat-error *ngIf="numDocumento.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_MIN8_DIGITOS}}</mat-error>
            <mat-error *ngIf="numDocumento.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_MAX9_DIGITOS}}</mat-error>
          </mat-form-field>
        </div>
        <div [style.marginTop.px]="15">
          <label class="secciones"  >Ubicación y dirección. </label>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" [style.marginTop.px]="15">
          <mat-form-field>
            <mat-label>Departamento</mat-label>
            <mat-select (selectionChange)="departamentoSelect($event.value)" [(ngModel)]="cusuario.codigo_departamento"
              name="ddlDepartamento" #ddlDepartamento="ngModel" required [disabled]="disabled_controls">
              <mat-option *ngFor="let dep of lDepartamento" [value]="dep.codigoID">{{dep.nombre}}</mat-option>
            </mat-select>
            <mat-error *ngIf="ddlDepartamento.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Provincia</mat-label>
            <mat-select (selectionChange)="provinciaSelect($event.value)" [(ngModel)]="cusuario.codigo_provincia" name="ddlProvincia"
              #ddlProvincia="ngModel" required [disabled]="disabled_controls">
              <mat-option *ngFor="let prov of lProvincia" [value]="prov.codProvincia">{{prov.provincia}}</mat-option>
            </mat-select>
            <mat-error *ngIf="ddlProvincia.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
          <mat-form-field>
            <mat-label>Distrito</mat-label>
            <mat-select [(ngModel)]="cusuario.codigo_distrito" name="ddlDistrito" #ddlDistrito="ngModel" required [disabled]="disabled_controls">
              <mat-option *ngFor='let dist of lDistrito' [value]='dist.codDistrito'>{{dist.distrito}}</mat-option>
            </mat-select>
            <mat-error *ngIf="ddlDistrito.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput placeholder="Dirección y número" name="direccion" ngModel
              [(ngModel)]="cusuario.direccion"
              [maxlength]="Length_Database.DB_LENGTH_SMALL_MAX_50" [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8"
              required #direccion="ngModel" [disabled]="disabled_controls">
            <mat-error *ngIf="direccion.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error
              *ngIf="direccion.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8CARACTERES}}</mat-error>
            <mat-error
              *ngIf="direccion.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.xs="row" [style.marginTop.px]="15" >
          <app-upload
          [multipleFiles]="false"
          [requiredFileType]="fileType"
          [label]="label"
          [max_size_width_height]="sizeMaxHeightWidth"
          [disabled]="disabled_controls"
          [is_edit]="esModoEdicion"
          [images_uploaded]="files_uploaded"
          ></app-upload>
        </div>
      </mat-card-content>
      <mat-card-actions align="start" [style.marginLeft.px]="5" *ngIf="!disabled_controls" >
        <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="f.invalid"
          [attr_type]="'submit'"></app-button>
        <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" color="gray"
          (click)="Limpiar(f)"></app-button>
        <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING"
          (click)="Limpiar(f)"></app-button>
      </mat-card-actions>
      <mat-card-footer>
      </mat-card-footer>
    </mat-card>
  </form>
</div>
