<div style="max-width: 300px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div mat-dialog-title class="dialog-title">Personalizar Producto:&nbsp;{{producto.nombre_producto}}</div>
        <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"
            [attr_disabled]="isLoading"></app-button>
    </div>
    <mat-divider></mat-divider>
    <mat-dialog-content style="min-width: 250px; margin-top: 20px;">
        <section>
            <form #f="ngForm" (ngSubmit)="onSubmit(f)" fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutGap="15px">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Personalización del producto</mat-label>
                        <mat-select
                        name="tipoCaracteristica"
                        required                        
                        [(ngModel)]="caracteristicaSeleccionada"
                        #tipoCaracteristica="ngModel"
                        >
                            <mat-option *ngFor=" let caracteristica of listaCaracteristicas"
                            [value]="caracteristica"
                             >{{caracteristica}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="tipoCaracteristica.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                    </mat-form-field>
                </div>
                <mat-divider style="margin-bottom: 20px; margin-top: 20px;"></mat-divider>
                <mat-card-actions fxLayout="row" fxLayoutAlign="space-around center">
                    <app-button [width]="100" text="Seleccionar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="(f.invalid)"
                      [attr_type]="'submit'"></app-button>
                    <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" color="gray"
                      (click)="Limpiar()"></app-button>
                    <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING"
                      (click)="Limpiar()"></app-button>
                  </mat-card-actions>
            </form>
        </section>
    </mat-dialog-content>
</div>