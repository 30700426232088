import { Injectable } from '@angular/core';
import { CColaborador } from '../../models/ccolaborador.model';
import { Utils } from 'src/app/shared/helpers/utils';
import { serverTimestamp, deleteField, OrderByDirection } from 'firebase/firestore';
import { Auditoria, colections, documentColaborador, documentsEmpresa, documentsUsuarioColaborador } from 'src/app/shared/cons/db.colections';
import { AngularFirestore, CollectionReference, Query} from '@angular/fire/compat/firestore';
import { IUsuarioColaborador } from '../../models/usuario-colaborador.model';
import { UploadService } from 'src/app/shared/components/upload/upload.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { IContadorDocumento } from 'src/app/shared/models/IContadorDocumentos';
import { ContadorDocumento } from 'src/app/shared/models/ContadorDocumento.model';
import { catchError, concatMap, debounceTime, first, map, switchMap, take, takeLast } from 'rxjs/operators';
import { MsjEstado, TableTypes } from 'src/app/shared/cons/common';
import { BehaviorSubject, Observable, Subscription, combineLatest, forkJoin, zip, of, throwError } from 'rxjs';
import { IRolAcceso } from 'src/app/shared/models/item.model';
import { IImageUploaded } from 'src/app/shared/components/upload/IImageUploaded';
import { ITableFiltroColaborador } from '../../models/ITableFiltroColaborador';
import { ICantidadModuloSel } from 'src/app/shared/models/ICantidadModuloSel';
import { CModuloRolSeleccionado } from '../../models/modulo-rol-seleccionado';

@Injectable({
  providedIn: 'root'
})

@AutoUnsubscribe

export class GestionColaboradorDaService {
  private readonly key = environment.key.SECRET_KEY_P;
  transaccion:TransaccionModel = new TransaccionModel();
  contadorResumenUpdate:ContadorDocumento = new ContadorDocumento();
  contadorResumenGet:ContadorDocumento = new ContadorDocumento();
  contadorResumenIsInsert:boolean=true;

  //busqueda y paginacion
  private filtroColaborador:ITableFiltroColaborador ={
    itemsPerPage:5,
    currentPage : 1,
    nombresFiltro:null,
    nroDocumentoFiltro:null,
    estadoFiltro:null,
    nextPage:null,
    previousPage:null,
    minPage:null,
    maxPage:null,
    ordenamiento:'desc',
  }



  constructor(
    private db: AngularFirestore,
    private uploadService: UploadService,
    private authService:AuthService,
    private functionService: FunctionsService,

  ) { }

  private removeUtilProperties(colaborador:CColaborador): CColaborador {
    delete colaborador.estado;
    delete colaborador.nombres_apellidos;
    delete colaborador.numeracion;
    delete colaborador.totalRegistrosActivos;
    delete colaborador.totalRegistrosInactivos;
    delete colaborador.totalRegistros_doc;
    delete colaborador.totalRegistros_query;
    delete colaborador.fecha_registro;
    delete colaborador.id;
    delete colaborador.masElementos;
    delete colaborador.nextPage;
    delete colaborador.previousPage;
    delete colaborador.empresa;
    delete colaborador.cantidad_modulos_seleccionados
    return colaborador;
  }

  utilCleanFlags(){
    this.filtroColaborador.firstVisibleDoc = null;
    this.filtroColaborador.lastVisibleDoc =null;
    this.filtroColaborador.ordenamiento = 'desc';
  }

  async cambiarContraseniaColaborador(colaborador:CColaborador, nuevoPassword:string, idEmpresa:string, correoUsuario:string){
    try {
      const passwordEncryp = CryptoJS.AES.encrypt(JSON.stringify(nuevoPassword), this.key).toString();
      const empresaRef = this.db.collection(colections.EMPRESA).doc(idEmpresa).ref
      const subCollectionRef = empresaRef.collection(colections.COLABORADOR).doc(colaborador.id);
      const transactionResult = await this.db.firestore.runTransaction(async (transaction) => {
        if(colaborador.id_usuario_cuenta){
          const data = await this.functionService.UpdateColaboradorPlataformaPassword(colaborador.id_usuario_cuenta, nuevoPassword).toPromise();
          this.transaccion.tx = data.tx?true:false;
        }
        //fijando transaciones
        transaction.update(subCollectionRef, {
          fecha_modificacion : serverTimestamp(),
          usuario_modificacion : correoUsuario,
          password: passwordEncryp

        });

      });
      return this.transaccion;
      ;}
    catch(error){
      this.transaccion = { tx: false, error: error };
      throw error;
    }

  }

  validarCorreoColaborador(correo:string){
    return this.db.collection(colections.USUARIO_COLABORADOR, ref =>ref
      .where(documentsUsuarioColaborador.CORREO_ELECTRONICO,'==',correo))
      .get().toPromise().then(doc =>{
        if(!doc.empty){
          this.transaccion ={
            tx:true,
            data:{
                idUsuarioColaborador:doc.docs[0].id
              }
          }

        }else{
          this.transaccion = {
            tx:false
          }
        }
        return this.transaccion
      });
  }

  getColaboradorResumen(idEmpresa: string): Observable<IContadorDocumento> {
    const docEmpresa = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docResumenColaboradorRef = docEmpresa.collection(colections.COLABORADOR_RESUMEN);

    return docResumenColaboradorRef.snapshotChanges()
      .pipe(
        map(resumen => {
          if (resumen.length > 0) {
            const datos = resumen[0].payload.doc.data() as IContadorDocumento;
            datos.id_documento = resumen[0].payload.doc.id;
            return datos;
          } else {
            const resumenFict:IContadorDocumento = {
              total_documentos: 0,
              total_documentos_activos: 0,
              total_documentos_inactivos: 0,
              fecha_actualizacion: null,
              id_documento: undefined,
              cantidad_modulos_seleccionados:[]
            };
            return resumenFict;
          }
        }),
        catchError(error => {
          console.error('Error en getColaboradorResumen:', error);
          // Puedes retornar un valor por defecto o rethrow del error
          // Aquí estamos rethrowing el error para que cualquier suscriptor pueda manejarlo
          throw error;
        })
      );
  }

  /*
  CombineLatest se utiliza para combinar las emisiones de múltiples observables
  en un nuevo observable, y switchMap se utiliza para cambiar a otro observable basado en el
  valor emitido por el observable anterior. En tu código, se combinan dos observables y luego
  se procesan los resultados utilizando switchMap. Esto asegura que los datos se procesen
  cuando ambos observables han emitido al menos una vez.
  */

  getColaborarResumenCombinado(idEmpresa: string, idUsuario: string, filtroColaborador: ITableFiltroColaborador): Observable<CColaborador[]> {
    const observableResumen = this.getColaboradorResumen(idEmpresa);
    const observableColaboradores = this.getColaboradores(idEmpresa, idUsuario, filtroColaborador);

    // Utilizamos forkJoin para combinar los observables
    return combineLatest([observableResumen, observableColaboradores]).pipe(
      map(([colaboradorResumen, colaboradoresList]) => {
        if (colaboradoresList?.length && colaboradorResumen !== null) {
          let nFinal = 0;
          let nInicial = 0;
          if (this.filtroColaborador.ordenamiento == 'desc') {
            nFinal = this.filtroColaborador.itemsPerPage * this.filtroColaborador.currentPage;
            nInicial = nFinal - this.filtroColaborador.itemsPerPage + 1;
          } else {
            nFinal = colaboradorResumen.total_documentos;
            nInicial = colaboradorResumen.total_documentos - this.filtroColaborador.itemsPerPage * (this.filtroColaborador.currentPage - 1);
          }

          colaboradoresList.forEach((elemento) => {
            elemento.numeracion = nInicial;
            elemento.totalRegistros_doc = colaboradorResumen.total_documentos;
            elemento.totalRegistrosActivos = colaboradorResumen.total_documentos_activos;
            elemento.totalRegistrosInactivos = colaboradorResumen.total_documentos_inactivos;
            elemento.cantidad_modulos_seleccionados = colaboradorResumen.cantidad_modulos_seleccionados;
            nInicial = this.filtroColaborador.ordenamiento == 'desc' ? nInicial + 1 : nInicial - 1;
          });

          return colaboradoresList; // Emitir los últimos resultados procesados
        } else {
          // Código para manejar el caso en que la lista esté vacía
          const listaColaboradorUtil: CColaborador[] = [];
          const colaboradorUtilitario = new CColaborador();
          colaboradorUtilitario.totalRegistros_doc = colaboradorResumen.total_documentos;
          colaboradorUtilitario.totalRegistrosActivos = colaboradorResumen.total_documentos_activos;
          colaboradorUtilitario.totalRegistrosInactivos = colaboradorResumen.total_documentos_inactivos;
          listaColaboradorUtil.push(colaboradorUtilitario);
          return listaColaboradorUtil; // Emitir los resultados procesados
        }
      }),
      catchError(error=>{
            console.error('Error en getColaboradorResumenCombinadoaa:', error);
            return of([]);
      })
    );
  }

  getColaboradores(
    idEmpresa: string,
    idUsuario: string,
    filtroColaborador: ITableFiltroColaborador
  ): Observable<CColaborador[]> {

    this.filtroColaborador.itemsPerPage = filtroColaborador.itemsPerPage;//seteamos los valores
    this.filtroColaborador.currentPage = filtroColaborador.currentPage;
    this.filtroColaborador.nombresFiltro = filtroColaborador.nombresFiltro;
    this.filtroColaborador.nroDocumentoFiltro = filtroColaborador.nroDocumentoFiltro;
    this.filtroColaborador.estadoFiltro = filtroColaborador.estadoFiltro;
    this.filtroColaborador.nextPage = filtroColaborador.nextPage;
    this.filtroColaborador.previousPage = filtroColaborador.previousPage;
    this.filtroColaborador.minPage = filtroColaborador.minPage;
    this.filtroColaborador.maxPage = filtroColaborador.maxPage;


    let listaColaboradoresLocal: CColaborador[] = [];
    if (this.filtroColaborador.minPage) {
      this.filtroColaborador.ordenamiento = 'desc';
    }
    if (this.filtroColaborador.maxPage) {
      this.filtroColaborador.ordenamiento = 'asc';
    }

    return this.db.collection(colections.EMPRESA, (ref) =>
      ref.where(Auditoria.es_borrado, '==', false)
        .where(documentsEmpresa.ID_DOC_USUARIO, '==', idUsuario))
      .doc(idEmpresa)
      .collection(colections.COLABORADOR, (ref1) => {
        let query = ref1
          .orderBy(documentColaborador.AUTO_NUMERICO, this.filtroColaborador.ordenamiento)
          .where(Auditoria.es_borrado, '==', false);

        if (this.filtroColaborador.nombresFiltro) {
          const lFiltroNombre = Utils.CadenaToArray(this.filtroColaborador.nombresFiltro);
          query = query
            .where(documentColaborador.NOMBRES_COMPLETO, 'array-contains-any', lFiltroNombre)
        }
        if (this.filtroColaborador.nroDocumentoFiltro) {
          query = query
            .where(documentColaborador.NUMERO_DOCUMENTO, '==', this.filtroColaborador.nroDocumentoFiltro)
        }
        if (this.filtroColaborador.estadoFiltro != null) {
          query = query
            .where(Auditoria.es_vigente, '==', this.filtroColaborador.estadoFiltro)

        }
        if (this.filtroColaborador.nextPage == true)
        {
          query = query.startAfter(this.filtroColaborador.lastVisibleDoc)
        }
        //no se implementa retroceso en busqueda
        if (this.filtroColaborador.previousPage == true)
        {
          query = query.endBefore(this.filtroColaborador.firstVisibleDoc)
        }

        if (this.filtroColaborador.previousPage)
        {

          query = query.limitToLast(this.filtroColaborador.itemsPerPage);
        }
        else{
          query = query.limit(this.filtroColaborador.itemsPerPage+1);// se suma 1 para traer un elemento más
        }

        return query;
      })
      .snapshotChanges()
      .pipe(
        map((colaboradores) => {
          if (colaboradores.length > 0) {
            // **para paginación
            this.filtroColaborador.firstVisibleDoc = colaboradores[0].payload.doc;
            const colaboradoresListaDatos = [...colaboradores];

            // para paginacion con busqueda
            let masElementos: boolean = false;
            if (colaboradores.length > this.filtroColaborador.itemsPerPage) {
              this.filtroColaborador.lastVisibleDoc = colaboradores[colaboradores.length - 2].payload.doc;
              masElementos = true;
              colaboradoresListaDatos.pop();
            }
            else {
              //cuando viene de una pagina previa y la pginacion actual será 1
              if (this.filtroColaborador.previousPage &&
                this.filtroColaborador.currentPage>1) {
                  masElementos = true;
              }else{
                masElementos = false;
              }
              this.filtroColaborador.lastVisibleDoc = colaboradores[colaboradores.length - 1].payload.doc;
            }

            // limpiamos el array y generamos numeracion
            listaColaboradoresLocal.length = 0;

            colaboradoresListaDatos.forEach((colab) => {
              const colaboradorData = Utils.convertDate({ ...colab.payload.doc.data(), }) as CColaborador;
              // **generando propiedades utilitarias
              colaboradorData.estado = colaboradorData.es_vigente ? MsjEstado.ACTIVO.toLowerCase() : MsjEstado.INACTIVO.toLowerCase();
              colaboradorData.fecha_registro = Utils.ToDateISO(colaboradorData.fecha_creacion, false);
              colaboradorData.nombres_apellidos = `${colaboradorData.nombres} ${colaboradorData.apellido_paterno} ${colaboradorData.apellido_materno}`;
              colaboradorData.id = colab.payload.doc.id;
              colaboradorData.masElementos = masElementos;
              colaboradorData.totalRegistros_query = colaboradoresListaDatos.length; // indica cuántos registros hay por query
              colaboradorData.previousPage= this.filtroColaborador.previousPage?true: undefined;
              colaboradorData.nextPage= this.filtroColaborador.nextPage?true: undefined;
              listaColaboradoresLocal.push(colaboradorData);
            });

            return listaColaboradoresLocal
          } else {
            this.filtroColaborador.firstVisibleDoc = null;
            this.filtroColaborador.lastVisibleDoc = null;

            const colaboradorFic: CColaborador = new CColaborador();
            const colabListaFic: CColaborador[] = new Array();
            colaboradorFic.totalRegistros_query=0;
            colabListaFic.push(colaboradorFic);

            return colabListaFic;
          }
        }),
        catchError(error => {
          console.log(error);
          return of([])
        })
      );
  }

  async getColaboradorXModuloRol(rol: IRolAcceso, idEmpresa: string) {
    try{
      const empresaRef = this.db.collection(colections.EMPRESA).doc(idEmpresa).ref
      const colaboradorRef = empresaRef.collection(colections.COLABORADOR);
      const querySnapshot = await colaboradorRef.where(documentColaborador.MODULO_ROL, "array-contains", {
        codigo_modulo: rol.codigo_modulo,
        nombre_modulo: rol.nombre_modulo,
        rol: rol.rol,
        personalizado: true,//solo se pueden eliminar roles personalizados
        acceso: rol.acceso
      }).get();

      if (!querySnapshot.empty) {
        this.transaccion.tx = true;
        this.transaccion.data= {numero_elementos :querySnapshot.size};
      } else {
        this.transaccion.tx = false;
        this.transaccion.data= {numero_elementos :0};
      }
      return this.transaccion;
    }
    catch(error){
      this.transaccion.tx=false;
      this.transaccion.data= {numero_elementos :0};
      this.transaccion.error=error
      return this.transaccion;
    }

  }

  validarPasswordAdminitradorEmpresa(password:string){

    return this.authService.validarPassword(password);
  }

  cerrarSesionAdministrador(){
    return this.authService.logout();
  }


  async deleteColaborador(idEmpresa: string, colaborador: CColaborador, pathFileAPI: string) {
    try {

      const transactionResult = await this.db.firestore.runTransaction(async (transaction) => {
        const docRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
        const subCollectionRef = docRef.collection(colections.COLABORADOR).doc(colaborador.id);
        //generando tabla resumen, siempre habrá un solo documento
        const contadorColaboradorRef = docRef.collection(colections.COLABORADOR_RESUMEN);

        const tranUsuarioColaboradorRef = this.db.collection(colections.USUARIO_COLABORADOR).ref
        .where(documentsUsuarioColaborador.ID_EMPRESA, '==', idEmpresa)
        .where(documentsUsuarioColaborador.ID_USUARIO_CUENTA, '==', colaborador.id_usuario_cuenta);

        await contadorColaboradorRef.get().toPromise()
          .then((docSnapshot) => {
            if (!docSnapshot.empty) {
              //actualizando contador
              this.contadorResumenGet = Utils.convertDate(docSnapshot.docs[0].data()) as IContadorDocumento;
              this.contadorResumenGet.id_documento = docSnapshot.docs[0].id;

              let activos = this.contadorResumenGet.total_documentos_activos;
              let inactivos = this.contadorResumenGet.total_documentos_inactivos;
              let total_documentos = this.contadorResumenGet.total_documentos - 1;

              if (colaborador.es_vigente) {
                //se disminuye el resumen en una unidad
                activos = activos - 1;
              }
              else {
                //se disminuye el resumen en una unidad
                inactivos = inactivos - 1;
              }

              //1.generando totales x cada modulo de la empresa
              this.contadorResumenGet.cantidad_modulos_seleccionados.forEach((modulo_rol)=>{
                colaborador.modulo_rol.forEach((moduloRolSel:IRolAcceso)=>{
                  if(modulo_rol.codigo===moduloRolSel.codigo_modulo){
                    modulo_rol.cantidad_total_usuarios_matriculados-=1;
                  }
                });
              });



              this.contadorResumenUpdate = {
                total_documentos: total_documentos,
                total_documentos_activos: activos,
                total_documentos_inactivos: inactivos,
                cantidad_modulos_seleccionados : this.contadorResumenGet.cantidad_modulos_seleccionados
              }
            }
          });

        //eliminando imagen
        if ('imagen' in colaborador) {
          // se manda un array vacío porque no se reemplaza nada
          const deleteImage = await this.uploadService.depuraFiles(pathFileAPI, [], colaborador.imagen);
        }

        //eliminando cuenta
        let data: any
        if(colaborador.id_usuario_cuenta){
          data = await this.functionService.DeleteColaboradorPlataforma(colaborador.id_usuario_cuenta).toPromise();
        }

        //eliminando tran.usuario_colaborador
        const queryUsuarioColaborador = await tranUsuarioColaboradorRef.get();
        if (!queryUsuarioColaborador.empty) {
          const documentRef = queryUsuarioColaborador.docs[0].ref;
          transaction.delete(documentRef);
        }

        //eliminando de ls subcolecion colaborador
        transaction.delete(subCollectionRef.ref);

        //estado colaborador variado
        const docResumenColaborador = Utils.SerializeJsonToDb(this.contadorResumenUpdate);
        docResumenColaborador.fecha_actualizacion = serverTimestamp();
        transaction.update(contadorColaboradorRef.doc(this.contadorResumenGet.id_documento).ref, docResumenColaborador);

        this.transaccion = {
          tx: data.tx?true:false,
          data: data
        };

      });
    } catch (error) {
      this.transaccion = {
        tx: true,
        error: error
      };
    }
    return this.transaccion;
  }


  async updateActivarColaborador(idEmpresa: string, correoUsuario: string, colaborador: CColaborador, esActivar:boolean) {
    try {

      const transactionResult = await this.db.firestore.runTransaction(async (transaction) => {
        const docRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
        const subCollectionRef = docRef.collection(colections.COLABORADOR).doc(colaborador.id);
        //generando tabla resumen, siempre habrá un solo documento
        const contadorColaboradorRef = docRef.collection(colections.COLABORADOR_RESUMEN);
        //cambiando estado en caso de ser distinto
        await contadorColaboradorRef.get().toPromise()
        .then((docSnapshot) => {
          if (!docSnapshot.empty) {
            //actualizando contador
            this.contadorResumenGet = Utils.convertDate(docSnapshot.docs[0].data()) as IContadorDocumento;
            this.contadorResumenGet.id_documento = docSnapshot.docs[0].id;

            let activos = this.contadorResumenGet.total_documentos_activos;
            let inactivos = this.contadorResumenGet.total_documentos_inactivos;

            if (esActivar) {
              activos = activos + 1;
              inactivos = inactivos - 1;
            }
            else {
              activos = activos - 1;
              inactivos = inactivos + 1;
            }

            this.contadorResumenUpdate = {
              total_documentos: this.contadorResumenGet.total_documentos,
              total_documentos_activos: activos,
              total_documentos_inactivos: inactivos,
              cantidad_modulos_seleccionados: this.contadorResumenGet.cantidad_modulos_seleccionados
            }
          }
        });
        try {
          //fijando transaciones
          transaction.update(subCollectionRef.ref, {
            fecha_modificacion : serverTimestamp(),
            usuario_modificacion : correoUsuario,
            es_vigente: esActivar

          });
          const newDocIdSubcoleccion = subCollectionRef.ref.id;

          //estado colaborador variado
          const docResumenColaborador = Utils.SerializeJsonToDb(this.contadorResumenUpdate);
            docResumenColaborador.fecha_actualizacion = serverTimestamp();
            transaction.update(contadorColaboradorRef.doc(this.contadorResumenGet.id_documento).ref, docResumenColaborador);

          this.transaccion = {
            tx: true,
            data: {
              idColaborador: newDocIdSubcoleccion,

            }
          };
          return this.transaccion;

        } catch (error) {
          this.transaccion = { tx: false, error: error };
          throw error;
        }
      });
      return transactionResult;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  compararArraysModuloRol(arr1:CModuloRolSeleccionado[], arr2:CModuloRolSeleccionado[]):boolean{
    if (arr1.length !== arr2.length) return false;
    const anterior = arr1.sort((a, b) => Number(a.codigo_modulo) - Number(b.codigo_modulo));
    const actual = arr2.sort((a, b) => Number(a.codigo_modulo) - Number(b.codigo_modulo));
    // Compara los elementos de los arrays ordenados
  return anterior.every((obj, index) => JSON.stringify(obj) === JSON.stringify(actual[index]));
  }

  retornaArrayDiferencia(arr1: CModuloRolSeleccionado[], arr2: CModuloRolSeleccionado[]): CModuloRolSeleccionado[] {
    //extrae la diferencia de 2 arrays con respecto al primer array
    //false - contrabando propiedad personalizado
    //true +  contrabando propiedad personalizado
    let differentObjects: CModuloRolSeleccionado[] = [];
    arr1.forEach(objFromArr1 => {
      if (!arr2.some(objFromArr2 => objFromArr1.codigo_modulo === objFromArr2.codigo_modulo)) {
        //seteamos la diferencia,con contrabando de propiedad personalizado
        objFromArr1.personalizado=false; //diferencia
        differentObjects.push(objFromArr1);
      }
    });

    arr2.forEach(objFromArr1 => {
      if (!arr1.some(objFromArr2 => objFromArr1.codigo_modulo === objFromArr2.codigo_modulo)) {
        //seteamos la diferencia,con contrabando de propiedad personalizado
        objFromArr1.personalizado=true; //add
        differentObjects.push(objFromArr1);
      }
    });

    return differentObjects;
  }


  async updateColaborador(idEmpresa: string, correoUsuario: string, colaboradorAnterior:CColaborador, colaborador: CColaborador, files_upload: File[], files_upload_anterior: IImageUploaded[], pathFileAPI: string, estadoColaboradorEdicion: boolean) {
    const colaboradorID = colaborador.id;
    //removiendo propiedades utilitarias
    colaborador = { ...this.removeUtilProperties(colaborador) };
    const passwordEncryp = CryptoJS.AES.encrypt(JSON.stringify(colaborador.password), this.key).toString();
    const docColaborador = Utils.SerializeJsonToDb(colaborador);
    docColaborador.fecha_modificacion = serverTimestamp();
    docColaborador.usuario_modificacion = correoUsuario;
    docColaborador.password = passwordEncryp;

    try {

      //caso: cuando se eliminó la imagen ANTERIOR subida (files_upload_anterior) y no se subio ninguna imagen actual
      if (files_upload_anterior.length == 0 && files_upload.length == 0) {
        await this.uploadService.depuraFiles(pathFileAPI, files_upload_anterior, colaborador.imagen);
        docColaborador.imagen = deleteField();
      }
      //caso: cuando quiere reemplazar la imagen actual por una nueva
      if (files_upload_anterior.length == 0 && files_upload.length > 0) {
        await this.uploadService.depuraFiles(pathFileAPI, files_upload_anterior, colaborador.imagen);
        const urlsImagen = await this.uploadService.onSaveFiles(files_upload, pathFileAPI);
        docColaborador.imagen = urlsImagen;
      }
      //creando transaccion
      const transactionResult = await this.db.firestore.runTransaction(async (transaction) => {
        const docRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
        const subCollectionRef = docRef.collection(colections.COLABORADOR).doc(colaboradorID);
        //generando tabla resumen, siempre habrá un solo documento
        const contadorColaboradorRef = docRef.collection(colections.COLABORADOR_RESUMEN);
        const resColabSnaps = await contadorColaboradorRef.get().toPromise();

        //cambiando estado en caso de ser distinto
        if (estadoColaboradorEdicion != colaborador.es_vigente) {
          if (!resColabSnaps.empty) {
            this.contadorResumenGet = Utils.convertDate(resColabSnaps.docs[0].data()) as IContadorDocumento;
            this.contadorResumenGet.id_documento = resColabSnaps.docs[0].id;
            let activos = this.contadorResumenGet.total_documentos_activos;
            let inactivos = this.contadorResumenGet.total_documentos_inactivos;
            if (colaborador.es_vigente) {
              activos = activos + 1;
              inactivos = inactivos - 1;
            }
            else {
              activos = activos - 1;
              inactivos = inactivos + 1;
            }

            this.contadorResumenUpdate = {
              total_documentos: this.contadorResumenGet.total_documentos,
              total_documentos_activos: activos,
              total_documentos_inactivos: inactivos,
              cantidad_modulos_seleccionados: this.contadorResumenGet.cantidad_modulos_seleccionados
            }
          }
        }
        //comprobando si existe diferencia en los roles seleccionados
        const sonRolesIguales = this.compararArraysModuloRol(colaboradorAnterior.modulo_rol, colaborador.modulo_rol);

        if (!sonRolesIguales) {
          if (estadoColaboradorEdicion == colaborador.es_vigente) {
            if (!resColabSnaps.empty) {
              this.contadorResumenGet = Utils.convertDate(resColabSnaps.docs[0].data()) as IContadorDocumento;
              this.contadorResumenGet.id_documento = resColabSnaps.docs[0].id;
              this.contadorResumenUpdate = {...this.contadorResumenGet}
              delete this.contadorResumenUpdate.id_documento //para no setear el id
            }
          }
          //seteamos la diferencia
          const arrayDif = this.retornaArrayDiferencia(colaboradorAnterior.modulo_rol, colaborador.modulo_rol)
          this.contadorResumenGet.cantidad_modulos_seleccionados.forEach(moduloRol => {
            arrayDif.forEach(modRolDif => {
              if (moduloRol.codigo === modRolDif.codigo_modulo) {
                //contrabando
                if(modRolDif.personalizado){
                  moduloRol.cantidad_total_usuarios_matriculados += 1
                }
                else{
                  moduloRol.cantidad_total_usuarios_matriculados -= 1
                }
              }
            })
          });
          this.contadorResumenUpdate.cantidad_modulos_seleccionados = this.contadorResumenGet.cantidad_modulos_seleccionados;
          //console.log(this.contadorResumenUpdate);
        }

        try {
          //fijando transaciones
          transaction.update(subCollectionRef.ref, docColaborador);
          const newDocIdSubcoleccion = subCollectionRef.ref.id;

          //estado colaborador variado o reles variados
          if ((estadoColaboradorEdicion != colaborador.es_vigente) || !sonRolesIguales) {
            const docResumenColaborador = Utils.SerializeJsonToDb(this.contadorResumenUpdate);
            docResumenColaborador.fecha_actualizacion = serverTimestamp();
            transaction.update(contadorColaboradorRef.doc(this.contadorResumenGet.id_documento).ref, docResumenColaborador);
          }

          this.transaccion = {
            tx: true,
            data: {
              idColaborador: newDocIdSubcoleccion,

            }
          };
          return this.transaccion;

        } catch (error) {
          this.transaccion = { tx: false, error: error };
          throw error;
        }
      });
      return transactionResult;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }


  async insertColaborador(empresa: any, correoUsuario: string, colaborador: CColaborador, files_upload: File[], pathFileAPI: string) {
    const idEmpresa = empresa.id;
    const nombreRazon = empresa.nombre_razon;
    const passwordEncryp = CryptoJS.AES.encrypt(JSON.stringify(colaborador.password), this.key).toString();
    const docColaborador = Utils.SerializeJsonToDb(colaborador);
    docColaborador.fecha_creacion = serverTimestamp();
    docColaborador.usuario_creacion = correoUsuario;
    docColaborador.password = passwordEncryp;

    let usuarioColaborador: IUsuarioColaborador = {
      correo_electronico: colaborador.correo_electronico,
      id_empresa: idEmpresa,
      nombre_empresa: nombreRazon
    };
    const docUsuarioColaborador = Utils.SerializeJsonToDb(usuarioColaborador);
    docUsuarioColaborador.fecha_creacion = serverTimestamp();

    try {
      //crea al usuario llamando a la cloud function
      const data = await this.functionService.SetRegistroColaborador(colaborador.correo_electronico, colaborador.password).toPromise();

      if (data && data.tx) {
        docColaborador.id_usuario_cuenta = data.message;//id que retorna la cloud function
        docUsuarioColaborador.id_usuario_cuenta = data.message; //id que retorna la cloud function
        if (files_upload.length > 0) {
          //guarda la imagen
          const urls = await this.uploadService.onSaveFiles(files_upload, pathFileAPI);
          docColaborador.imagen = urls;
        }

        const transactionResult = await this.db.firestore.runTransaction(async (transaction) => {
          const docRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
          const subCollectionRef = docRef.collection(colections.COLABORADOR).doc();
          const registroRef = this.db.collection(colections.USUARIO_COLABORADOR).doc();

          //generando tabla resumen, siempre habrá un solo documento
          const contadorColaboradorRef = docRef.collection(colections.COLABORADOR_RESUMEN);
          await contadorColaboradorRef.get().toPromise()
          .then((docSnapshot) =>{
            if(docSnapshot.empty){
              //***numeracion documento autogenerado
              docColaborador.auto_numerico=1;
              //***

              //1.generando totales x cada modulo de la empresa
              const lista_modulos_seleccionados: ICantidadModuloSel[]=[];
              empresa.lista_modulos_seleccionados.forEach((modulo:any) => {
                const modulo_total:ICantidadModuloSel ={
                  codigo:modulo.codigo,
                  cantidad_total_usuarios_permitidos:modulo.cantidad_total_usuarios,
                  cantidad_total_usuarios_matriculados:0,//inicializando el contador en 0 porque el administrador no tiene costo
                  nombre: modulo.nombre,
                  orden: modulo.orden
                }
                lista_modulos_seleccionados.push(modulo_total);
              });
              //2.seteando totales por modulo seleccionado
              lista_modulos_seleccionados.forEach((cantidadModulo:ICantidadModuloSel)=>{
                colaborador.modulo_rol.forEach((moduloRolSel:IRolAcceso)=>{
                  if(cantidadModulo.codigo===moduloRolSel.codigo_modulo){
                    cantidadModulo.cantidad_total_usuarios_matriculados+=1;
                  }
                });
              });
              //3.insertando en el contador
              this.contadorResumenUpdate={
                total_documentos:1,
                total_documentos_activos: colaborador.es_vigente?1:0,
                total_documentos_inactivos: !colaborador.es_vigente?1:0,
                cantidad_modulos_seleccionados: lista_modulos_seleccionados
              }
              this.contadorResumenUpdate.fecha_actualizacion = serverTimestamp();
              this.contadorResumenIsInsert = true;

            }else{
              //actualizando contador
              this.contadorResumenGet =  Utils.convertDate(docSnapshot.docs[0].data()) as IContadorDocumento;
              this.contadorResumenGet.id_documento = docSnapshot.docs[0].id;
              const total_docs = this.contadorResumenGet.total_documentos+1;

              //***numeracion documento autogenerado
              docColaborador.auto_numerico=total_docs;
              //***

              //1.generando totales de cada modulo seleccionado
              this.contadorResumenGet.cantidad_modulos_seleccionados.forEach((modulo_rol)=>{
                colaborador.modulo_rol.forEach((moduloRolSel:IRolAcceso)=>{
                  if(modulo_rol.codigo===moduloRolSel.codigo_modulo){
                    modulo_rol.cantidad_total_usuarios_matriculados+=1;
                  }
                });
              });
              //2.seteando valor de totales de modulos
              this.contadorResumenUpdate={
                total_documentos:total_docs,
                total_documentos_activos: colaborador.es_vigente?
                this.contadorResumenGet.total_documentos_activos+1: this.contadorResumenGet.total_documentos_activos,
                total_documentos_inactivos:!colaborador.es_vigente?
                this.contadorResumenGet.total_documentos_inactivos+1: this.contadorResumenGet.total_documentos_inactivos,
                cantidad_modulos_seleccionados: this.contadorResumenGet.cantidad_modulos_seleccionados
              }
              this.contadorResumenIsInsert = false;
            }
          });

          try {

            transaction.set(subCollectionRef.ref, docColaborador);
            transaction.set(registroRef.ref, docUsuarioColaborador);
            //inserta resumen
            const docResumenColaborador = Utils.SerializeJsonToDb(this.contadorResumenUpdate);
            docResumenColaborador.fecha_actualizacion = serverTimestamp();

            if(this.contadorResumenIsInsert){
              transaction.set(contadorColaboradorRef.doc().ref,
                docResumenColaborador);
            }
            else{
              transaction.update(contadorColaboradorRef.doc(this.contadorResumenGet.id_documento).ref,
                docResumenColaborador);
            }

            const newDocIdSubcoleccion = subCollectionRef.ref.id;
            const newDocUsuarioColaborador = registroRef.ref.id;
            const newDocUResumenlaborador = this.contadorResumenUpdate.id_documento;

            this.transaccion = {
              tx: true,
              data: {
                idColaborador: newDocIdSubcoleccion,
                idUsuarioColaborador: newDocUsuarioColaborador,
                idDocumentoColaboradorResumen: newDocUResumenlaborador
              }
            };
            return this.transaccion;
          } catch (error) {
            this.transaccion = { tx: false, error: error };
            throw error;
          }
        });

        return transactionResult;
      } else {
        this.transaccion = { tx: false };
        return this.transaccion;
      }
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

}
