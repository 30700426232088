<div style="max-width: 500px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div mat-dialog-title class="dialog-title">Pedido:&nbsp;{{pedido.codigo_pedido}}
        </div>
        <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"></app-button>
    </div>
    <mat-divider></mat-divider>
    <mat-dialog-content (touchstart)="touchStart($event)" (touchmove)="touchMove($event)" (touchend)="touchEnd($event)"
    class="scrollable">
        <section *ngIf="!isLoading">
            <div fxLayout="column">
                <div>Usuario: {{pedido.usuario_creacion | hideMail }}</div>
                <div>Fecha: {{pedido.fecha_key.anio}}/{{pedido.fecha_key.mes}}/{{pedido.fecha_key.dia}}</div>
                <div>Hora: {{pedido.fecha_key.hora}}:{{pedido.fecha_key.minuto}}:{{pedido.fecha_key.segundo}}</div>
            </div>
            <div fxLayout="row" style="padding-top: 10px;">
                <div fxLayout="row" fxLayoutGap="10px">
                    <h3>Mesas:</h3>
                    <div>
                        <mat-chip-list fxLayout="row wrap" fxLayoutAlign="start start">
                            <ng-container *ngFor="let mesa of pedido.ambiente_mesa; let i=index">
                                <mat-chip>
                                    <span>{{mesa.mesa_numero}}</span>
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div style="margin-top: 10px;">            
                <!-- <button mat-stroked-button matTooltip="Pre Pagar" class="estado-pendiente" [disabled]="disabledPrePagar" (click)="prePagarProductos()" >
                    <mat-icon class="mat-18">{{Icons.PAYMENTS }}</mat-icon>
                    <span fxShow.gt-md fxHide.lt-md style="margin-left: 5px;">Pre Pagar</span>
                </button>                 -->
            </div> 
            <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-top:15px; color: gray;">
                <div>
                    <span>N° de ítems:&nbsp;{{listaProductoPedido.length}} </span>
                </div>
                <div><span>N° de productos:&nbsp;{{totalProductos}} </span></div>
            </div>            
                     
            <div fxLayoutGap="10px" fxLayout="column">
                <ul style="list-style-type: none; ">
                    <li *ngFor="let prod of listaProductoPedido; let i=index" style="margin-top:10px;margin-left: -50px;">
                        <ng-container *ngIf="prod.es_pagado==undefined || !prod.es_pagado">
                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                                    <div fxLayout="row" style="font-size: 10pt;">
                                        <!-- <div>
                                            <mat-checkbox color="primary" (change)="seleccionarProd($event.checked, i)" ></mat-checkbox>
                                        </div>                                      -->
                                        <div style="margin-left: 10px;">
                                            <span>{{prod.cantidad_producto}}</span>
                                            &nbsp;
                                        </div>
                                        <div>
                                            <span>{{prod.nombre_producto}}</span>
                                            &nbsp;
                                        </div>
                                        <div fxShow.gt-xs fxHide.xs>
                                            <span>(P.U. {{prod.precio_unitario | number:'1.2-2'}})</span>
                                            &nbsp;
                                        </div>
                                        <div *ngIf="prod.tiene_descuento" style="margin-top: -5px;"                                        
                                        [matTooltip]="prod.descuento!.motivo" >
                                            <mat-chip-list>
                                                <mat-chip [@blink]="blinkState"  class="chip-aprobado">-{{prod.descuento?.porcentaje_descuento}}%&nbsp;(x{{prod.descuento?.cantidad_producto}})</mat-chip>                                                    
                                            </mat-chip-list>
                                        </div>
                                        <div *ngIf="prod.tiene_incidencia" style="margin-top: -5px;"                                        
                                        [matTooltip]="prod.incidencia!.motivo" >
                                            <mat-chip-list>
                                                <mat-chip [@blink]="blinkState"  class="chip-rechazado">                                                    
                                                    <mat-icon class="mat-18">{{Icons.INFO}}</mat-icon>
                                                    &nbsp;x{{prod.incidencia!.cantidad_producto}}                                                    
                                                </mat-chip>                                                    
                                            </mat-chip-list>
                                        </div>
                                    </div>
                                    <div class="precio-total">
                                        {{prod.precio_total | number:'1.2-2'}}
                                    </div>
                                </div>                            
                                <div>                                    
                                    <!-- menu emergente -->
                                    <app-button color="gray" type="icon" [icon_fig]="Icons.MORE_VERT" matTooltip="Opciones"
                                    matTooltipPosition="above"
                                    [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"></app-button>
                                    <mat-menu #menu="matMenu" class="mat-elevation-z8">
                                        <!-- <button *ngIf="!prod.tiene_incidencia" mat-menu-item  (click)="procesarProducto(1, prod);menuTrigger.closeMenu()">
                                          <mat-icon>{{Icons.PAYMENTS}}</mat-icon>
                                          <span>Pagar Producto</span>
                                        </button> -->
                                        <button *ngIf="!prod.tiene_incidencia && esAdministrador" mat-menu-item  (click)="procesarProducto(2, prod);menuTrigger.closeMenu()">
                                          <mat-icon>{{Icons.PERCENT}}</mat-icon>
                                          <span>Asignar Descuento</span>
                                        </button>
                                        <button *ngIf="!prod.tiene_descuento && esAdministrador" mat-menu-item  (click)="procesarProducto(3, prod);menuTrigger.closeMenu()">
                                          <mat-icon>{{Icons.REPORT}}</mat-icon>
                                          <span>Asignar Incidencia</span>
                                        </button>
                                    </mat-menu>                                    
                                </div>
                            </div>
            
                        </ng-container>
            
                    </li>
                </ul>
                <mat-divider></mat-divider>
                <div fxLayout="column" fxLayoutAlign="space-between end" fxLayoutGap="10px" class="divider">
                    <div fxLayout="row">
                        <div class="etiquetas-montos">Sub Total:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div class="montos">S/ {{this.pedido.sub_total| number:'1.2-2' }}</div>
                    </div>
                    <div *ngIf="pedido.servicio_total" fxLayout="row">
                        <div class="montos-subs">Servicio({{aspectosGenerales.tasa_servicio}}%):&nbsp;&nbsp;&nbsp;&nbsp;S/ {{this.pedido.servicio_total| number:'1.2-2' }}</div>
                    </div>
                    <div fxLayout="row" *ngIf="aspectosGenerales.sigla_impuesto!=''" >                        
                        <div class="montos-subs">{{aspectosGenerales.sigla_impuesto}}({{aspectosGenerales.tasa_impuesto}}%):&nbsp;&nbsp;&nbsp;&nbsp;S/ {{this.pedido.igv| number:'1.2-2' }}</div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div fxLayout="row" class="divider" fxLayoutAlign="end center">
                    <div>
                        <h2>TOTAL:&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                    </div>
                    <div class="montos">
                        <h2> S/ {{this.pedido.total| number:'1.2-2' }}</h2>
                    </div>
                </div>
            </div>
        </section>
        <section *ngIf="isLoading" fxLayout="column" fxLayoutAlign="end center">
            <app-spinner></app-spinner>
        </section>
    </mat-dialog-content>

</div>