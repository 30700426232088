import { Injectable } from '@angular/core';
import { GestionAmbientesDaService } from '../services.da/gestion-ambientes.da.service';

import { RegistroService } from 'src/app/shared/services/registro.service';
import { Observable } from 'rxjs';
import { IConfiguracionAmbiente } from '../../models/IConfiguracionAmbiente';
import { IMesaAmbiente } from 'src/app/shared/components/registrar-pedido/models/IMesaAmbiente';
import { IAmbienteMesa } from '../../models/IAmbienteMesa';

@Injectable({
  providedIn: 'root'
})
export class GestionAmbientesBlService {

  constructor(
    private gestionAmbienteDAService: GestionAmbientesDaService,
    private registroService: RegistroService
  ) { }

  existFetchAmbienteMesa(){
    const idEmpresa = this.registroService.empresa.id;
    const idUsuario = this.registroService.usuario.id;
    this.gestionAmbienteDAService.fetchAmbienteMesa(idEmpresa);
  }

  getAmbienteMesa():Observable<IAmbienteMesa[] | null | undefined>{
    return this.gestionAmbienteDAService.ambienteMesa$;
  }

  insertConfiguracionAmbiente(listaAmbientes:IAmbienteMesa[]){
    const idEmpresa = this.registroService.empresa.id;    
    return this.gestionAmbienteDAService.insertConfiguracionAmbiente(idEmpresa,listaAmbientes);
  }

  updateConfiguracionAmbiente(config:IAmbienteMesa[]){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionAmbienteDAService.updateConfiguracionAmbiente(config,idEmpresa,correoUsuario);
  }

  getConfiguracionAmbiente(){
    const idEmpresa = this.registroService.empresa.id;    
    return this.gestionAmbienteDAService.getConfiguracionAmbiente(idEmpresa);
  }
  updateEstadoMesa(mesaAmbiente:IAmbienteMesa){
    const idEmpresa = this.registroService.empresa.id;    
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionAmbienteDAService.updateEstadoMesa(mesaAmbiente, idEmpresa, correoUsuario);

  }
  //metodo utilitario que solo libera todas las mesas
  updateConfiguracionAmbienteDisponible(){
    const idEmpresa = this.registroService.empresa.id;
    this.gestionAmbienteDAService.updateConfiguracionAmbienteDisponible(idEmpresa);
  }
}
