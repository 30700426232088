<div *ngIf="dataSource.length!=0">
  <!-- my-table.component.html -->
  <mat-table #matTable [dataSource]="dataSource" class="datos-table" *ngIf="!isLoading">
    <ng-container *ngFor="let col of columnas;" [matColumnDef]="col">
      <mat-header-cell *matHeaderCellDef>
        <span *ngIf="col=='numeracion'">#</span>
        <span *ngIf="col=='total_string'">{{col | replaceChar:'_string':' ' | uppercase }}</span>
        <span *ngIf="col!='operaciones' && col!='numeracion' && col!='total_string'">{{col | uppercase | replaceChar:'_':' ' |
          replaceChar:'LABEL':'' }}</span>
        <span *ngIf="col=='operaciones' && tamanioPantalla!='xs' ">{{col | uppercase | replaceChar:'_':' ' |
          replaceChar:'LABEL':'' }}</span>
        <span *ngIf="col=='operaciones' && tamanioPantalla=='xs' ">
          <mat-icon class="mat-18">{{Icons.SETTINGS}}</mat-icon>
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let item">             
        <!-- matchiproles    -->
        <div *ngIf="dataTable.columnaChip">
          <!-- cuando la columna estado es nulo -->
          <mat-chip-list *ngIf="col==dataTable.columnaChip && tamanioPantalla!=='xs'">
            <mat-chip *ngFor="let elemento of item[col] let j = index; trackBy: trackByFn"
              [matTooltip]="elemento.nombre_modulo" matTooltipHideDelay="500" matTooltipPosition="left"
              selected="false">
              <span *ngIf="tamanioPantalla=='xs'">{{elemento.rol | sizeLabel:15 }}</span>
              <span *ngIf="tamanioPantalla!='xs'">{{elemento.rol}}</span>
            </mat-chip>
          </mat-chip-list>
          <!-- cuando la columna estado no es nulo -->
          <mat-chip-list
            *ngIf="col==dataTable.columnaChip && dataTable.columnaEstiloEstado  && dataTable.columnaEstado && tamanioPantalla==='xs'">
            <mat-chip [class]="'estado-'+ item[dataTable.columnaEstiloEstado]" *ngFor="let elemento of item[col]"
              [matTooltip]="elemento.nombre_modulo+' - Estado:'+item[dataTable.columnaEstado]" matTooltipHideDelay="500"
              matTooltipPosition="left" selected="false">
              <span>{{elemento.rol | sizeLabel:15 }}</span>
            </mat-chip>
          </mat-chip-list>
        </div>
        <!-- matchipestados -->
        <div *ngIf="dataTable.columnaEstado">
          <mat-chip-list *ngIf="col==dataTable.columnaEstado">
            <mat-chip *ngIf="dataTable.columnaEstiloEstado" [class]="'estado-' + item[dataTable.columnaEstiloEstado]"
              selected="false">
              {{item[col] | uppercase | replaceChar:'_':' '}}
            </mat-chip>
          </mat-chip-list>
        </div>   
        <span *ngIf="col!=dataTable.columnaEstado && col!=dataTable.columnaChip && col!='operaciones'">{{item[col] | sizeLabel:30 }}</span>
        <!-- operaciones -->
        <div fxLayout="row" *ngIf="col=='operaciones' && (tamanioPantalla!='xs' && tamanioPantalla!='sm' && tamanioPantalla!='md' )">
          <div fxLayout="row" fxLayoutAlign="start center">
            <app-button *ngIf="botonPagoTotal && (item[dataTable.columnaEstado]==EstadosPedidoType.CERRADO)" color="gray" type="icon" 
            [icon_fig]="Icons.POINT_OF_SALE" matTooltip="Pago Total"
            matTooltipPosition="above" (click)="btnPagoTotal(item)"></app-button>
            <app-button *ngIf="botonPagoParcial && (item[dataTable.columnaEstado]!=EstadosPedidoType.ANULADO && 
              item[dataTable.columnaEstado]!=EstadosPedidoType.CERRADO
            )" color="gray" type="icon" 
            [icon_fig]="Icons.EDIT_SQUARE" matTooltip="Dscto. e Incidencia"
            matTooltipPosition="above" (click)="btnPagoParcial(item)"></app-button>
            <app-button *ngIf="botonDividirCuenta &&              
               item[dataTable.columnaEstado]!=EstadosPedidoType.ANULADO &&                 
               item[dataTable.columnaEstado]!=EstadosPedidoType.ANULADO &&                 
               item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR  &&
               item[dataTable.columnaEstado]!==EstadosProductoType.PENDIENTE_REVISION &&
               item[dataTable.columnaEstado]!==EstadosPedidoType.CERRADO
               "              
            color="gray" type="icon" 
            [icon_fig]="Icons.CALL_SPLIT" matTooltip="Dividir Cuenta"
            matTooltipPosition="above" (click)="btnDividirCuenta(item)"></app-button>

            <app-button *ngIf="botonGestionarPassword" color="gray" type="icon" [icon_fig]="Icons.PASSWORD"
              matTooltip="Gestionar Contraseña" matTooltipPosition="above" (click)="btnPassword(item)"></app-button>
            <app-button *ngIf="botonNuevo" color="gray" type="icon" [icon_fig]="Icons.ADD" matTooltip="Nuevo"
              matTooltipPosition="above"></app-button>
            <app-button *ngIf="botonEditar" color="gray" type="icon" [icon_fig]="Icons.EDIT" matTooltip="Editar"
              matTooltipPosition="above" (click)="btnEditar(item)"></app-button>
            <app-button *ngIf="botonAgregar && item[dataTable.columnaEstado]!==EstadosPedidoType.ANULADO && item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR" color="gray" type="icon" [icon_fig]="Icons.ADD" matTooltip="Agregar"
              matTooltipPosition="above" (click)="btnAgregar(item, $event)"></app-button>

            <app-button 
            *ngIf="(botonEditarPedido &&             
            dataTable.usuarioAdministrador &&
            item[dataTable.columnaEstado]!==EstadosProductoType.TERMINADO &&
            item[dataTable.columnaEstado]!==EstadosPedidoType.ANULADO &&
            item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR            
            )
            ||
            (botonEditarPedido && 
            item[dataTable.columnaEstado]!==EstadosProductoType.PENDIENTE_REVISION &&            
            item[dataTable.columnaEstado]!==EstadosProductoType.TERMINADO &&
            item[dataTable.columnaEstado]!==EstadosPedidoType.ANULADO &&
            item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR &&            
            !dataTable.usuarioAdministrador)
            " color="gray" type="icon" [icon_fig]="Icons.EDIT" matTooltip="Editar"
              matTooltipPosition="above" (click)="btnEditarPedido(item)"></app-button>
            
            <app-button 
                *ngIf="botonTraspasarProducto && 
                      dataTable.usuarioAdministrador &&  
                      item[dataTable.columnaEstado]!==EstadosPedidoType.ANULADO &&
                      item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR &&    
                      item[dataTable.columnaEstado]!==EstadosProductoType.PENDIENTE_REVISION"
                color="gray" 
              type="icon" [icon_fig]="Icons.SWAP_HORIZ" 
              matTooltip="Traspasar" matTooltipPosition="above"
              (click)="btnTraspasarProducto(item)">
            </app-button>

            <app-button *ngIf="botonActivarPersona && item[dataTable.columnaEstado]===MsjEstado.ACTIVO.toLowerCase()"
              color="gray" type="icon" [icon_fig]="Icons.PERSON_OFF" matTooltip="Inactivar"
              (click)="btnEditarInactivarPersona(item)" matTooltipPosition="above"></app-button>
            <app-button *ngIf="botonActivar && item[dataTable.columnaEstado]===MsjEstado.ACTIVO.toLowerCase()"
              color="gray" type="icon" [icon_fig]="Icons.TOGGLE_OFF" matTooltip="Inactivar"
              (click)="btnEditarInactivar(item)" matTooltipPosition="above"></app-button>
            <app-button *ngIf="botonSuspender && item[dataTable.columnaEstado]===MsjEstado.INACTIVO.toLowerCase()"
              color="gray" type="icon" [icon_fig]="Icons.TOGGLE_ON" matTooltip="Activar"
              (click)="btnEditarActivar(item)" matTooltipPosition="above"></app-button>
            <app-button
              *ngIf="botonSuspenderPersona && item[dataTable.columnaEstado]===MsjEstado.INACTIVO.toLowerCase()"
              color="gray" type="icon" [icon_fig]="Icons.PERSON" (click)="btnEditarActivarPersona(item)"
              matTooltip="Activar" matTooltipPosition="above"></app-button>
            <app-button *ngIf="botonVer" color="gray" type="icon" [icon_fig]="Icons.VISIBILITY" matTooltip="Ver"
              matTooltipPosition="above" (click)="btnVer(item)"></app-button>           
            <app-button *ngIf="botonVerImagen" color="gray" type="icon" [icon_fig]="Icons.PHOTO_LIBRARY"
              matTooltip="Ver Imágenes" matTooltipPosition="above" (click)="btnVerImagen(item)"></app-button>
            <app-button *ngIf="botonImprimir && (item[dataTable.columnaEstado]===EstadosPedidoType.TERMINADO || 
              item[dataTable.columnaEstado]===EstadosPedidoType.CERRADO || 
              item[dataTable.columnaEstado]===EstadosPedidoType.PENDIENTE_REABRIR )" 
            color="gray" type="icon" [icon_fig]="Icons.PRINT" matTooltip="Imprimir y cerrar"
              matTooltipPosition="above" (click)="btnImprimir(item)" ></app-button>
            <app-button *ngIf="dataTable.usuarioAdministrador && botonResponderSolicitud && item[dataTable.columnaEstado]===EstadosPedidoType.PENDIENTE_REABRIR" 
            color="gray" type="icon" [icon_fig]="Icons.LIVE_HELP" matTooltip="Resolver"
              matTooltipPosition="above" (click)="btnResponderSolicitud(item)" ></app-button>
            <app-button *ngIf="botonEliminar" color="gray" type="icon" [icon_fig]="Icons.DELETE" matTooltip="Eliminar"
              matTooltipPosition="above" (click)="btnEliminar(item)"></app-button>

            <app-button *ngIf="botonCerrar &&                                    
            item[dataTable.columnaEstado]==EstadosPedidoType.TERMINADO" 
            color="gray" type="icon" [icon_fig]="Icons.LOCK" matTooltip="Cerrar"
              matTooltipPosition="above" (click)="btnCerrar(item)"></app-button>
            <app-button *ngIf="botonAbrir && (item[dataTable.columnaEstado]==EstadosPedidoType.CERRADO)" color="gray" type="icon" 
              [icon_fig]="Icons.LOCK_OPEN" matTooltip="Abrir"
              matTooltipPosition="above" (click)="btnAbrir(item)"></app-button>


          </div>
        </div>
        <!-- muestra un solo boton -->
        <div fxLayout="row" *ngIf="col=='operaciones' && (tamanioPantalla=='xs' || tamanioPantalla=='sm'|| tamanioPantalla=='md')">
          
          <div fxLayout="row" fxLayoutAlign="start center"
            *ngIf="dataTable.operaciones && dataTable.operaciones.length==1">
            <app-button *ngIf="botonPagoTotal && (item[dataTable.columnaEstado]==EstadosPedidoType.CERRADO)" color="gray" type="icon" 
            [icon_fig]="Icons.POINT_OF_SALE" matTooltip="Pago Total"
            matTooltipPosition="above" (click)="btnPagoTotal(item)"></app-button>
            <app-button *ngIf="botonPagoParcial && (item[dataTable.columnaEstado]!=EstadosPedidoType.ANULADO && 
              item[dataTable.columnaEstado]!=EstadosPedidoType.CERRADO )" color="gray" type="icon" 
            [icon_fig]="Icons.EDIT_SQUARE" matTooltip="Dscto. e Incidencia"
            matTooltipPosition="above" (click)="btnPagoParcial(item)"></app-button>
            <app-button *ngIf="botonDividirCuenta && 
              item[dataTable.columnaEstado]!=EstadosPedidoType.ANULADO &&
              item[dataTable.columnaEstado]!=EstadosPedidoType.CERRADO &&              
              item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR  &&
              item[dataTable.columnaEstado]!==EstadosProductoType.PENDIENTE_REVISION
              " color="gray" type="icon" 
            [icon_fig]="Icons.CALL_SPLIT" matTooltip="Dividir Cuenta"
            matTooltipPosition="above" (click)="btnDividirCuenta(item)"></app-button>

            <app-button *ngIf="botonGestionarPassword" color="gray" type="icon" [icon_fig]="Icons.PASSWORD"
              matTooltip="Gestionar Contraseña" matTooltipPosition="above" (click)="btnPassword(item)"></app-button>
            <app-button *ngIf="botonNuevo" color="gray" type="icon" [icon_fig]="Icons.ADD" matTooltip="Nuevo"
              matTooltipPosition="above"></app-button>
            <app-button *ngIf="botonEditar" color="gray" type="icon" [icon_fig]="Icons.EDIT" matTooltip="Editar"
              matTooltipPosition="above" (click)="btnEditar(item)"></app-button>
            <app-button *ngIf="botonAgregar && item[dataTable.columnaEstado]!==EstadosPedidoType.ANULADO && item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR" color="gray" type="icon" [icon_fig]="Icons.ADD" matTooltip="Agregar"
              matTooltipPosition="above" (click)="btnAgregar(item, $event)"></app-button>
            <app-button 
            *ngIf="(botonEditarPedido &&             
            dataTable.usuarioAdministrador &&
            item[dataTable.columnaEstado]!==EstadosProductoType.TERMINADO &&
            item[dataTable.columnaEstado]!==EstadosPedidoType.ANULADO &&
            item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR
            )
            ||
            (botonEditarPedido && 
            item[dataTable.columnaEstado]!==EstadosProductoType.PENDIENTE_REVISION &&            
            item[dataTable.columnaEstado]!==EstadosProductoType.TERMINADO &&
            item[dataTable.columnaEstado]!==EstadosPedidoType.ANULADO &&
            item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR &&            
            !dataTable.usuarioAdministrador)
              " color="gray" type="icon" [icon_fig]="Icons.EDIT" matTooltip="Editar"
                matTooltipPosition="above" (click)="btnEditarPedido(item)"></app-button>
            <app-button *ngIf="botonTraspasarProducto && 
                      dataTable.usuarioAdministrador &&                
                      item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR &&    
                      item[dataTable.columnaEstado]!==EstadosProductoType.PENDIENTE_REVISION &&    
                      item[dataTable.columnaEstado]!==EstadosPedidoType.ANULADO" color="gray" 
                type="icon" [icon_fig]="Icons.SWAP_HORIZ" 
                matTooltip="Traspasar" matTooltipPosition="above"
                (click)="btnTraspasarProducto(item)">
            </app-button>     
            <app-button *ngIf="botonActivarPersona && item[dataTable.columnaEstado]===MsjEstado.ACTIVO.toLowerCase()"
              color="gray" type="icon" [icon_fig]="Icons.PERSON_OFF" matTooltip="Inactivar"
              (click)="btnEditarInactivarPersona(item)" matTooltipPosition="above"></app-button>
            <app-button *ngIf="botonActivar && item[dataTable.columnaEstado]===MsjEstado.ACTIVO.toLowerCase()"
              color="gray" type="icon" [icon_fig]="Icons.TOGGLE_OFF" matTooltip="Inactivar"
              (click)="btnEditarInactivar(item)" matTooltipPosition="above"></app-button>
            <app-button *ngIf="botonSuspender && item[dataTable.columnaEstado]===MsjEstado.INACTIVO.toLowerCase()"
              color="gray" type="icon" [icon_fig]="Icons.TOGGLE_ON" matTooltip="Activar"
              (click)="btnEditarActivar(item)" matTooltipPosition="above"></app-button>
            <app-button
              *ngIf="botonSuspenderPersona && item[dataTable.columnaEstado]===MsjEstado.INACTIVO.toLowerCase()"
              color="gray" type="icon" [icon_fig]="Icons.PERSON" (click)="btnEditarActivarPersona(item)"
              matTooltip="Activar" matTooltipPosition="above"></app-button>
            <app-button *ngIf="botonVer" color="gray" type="icon" [icon_fig]="Icons.VISIBILITY" matTooltip="Ver"
              matTooltipPosition="above" (click)="btnVer(item)"></app-button>      
            <app-button *ngIf="botonVerImagen" color="gray" type="icon" [icon_fig]="Icons.PHOTO_LIBRARY"
              matTooltip="Ver Imágenes" matTooltipPosition="above" (click)="btnVerImagen(item)"></app-button>

            <app-button *ngIf="botonImprimir && (item[dataTable.columnaEstado]===EstadosPedidoType.TERMINADO || 
            item[dataTable.columnaEstado]===EstadosPedidoType.CERRADO || 
            item[dataTable.columnaEstado]===EstadosPedidoType.PENDIENTE_REABRIR )" 
            color="gray" type="icon" 
            [icon_fig]="Icons.PRINT" matTooltip="Imprimir y cerrar"
              matTooltipPosition="above" (click)="btnImprimir(item)"></app-button>
            <app-button *ngIf="botonEliminar" color="gray" type="icon" [icon_fig]="Icons.DELETE" matTooltip="Eliminar"
              matTooltipPosition="above" (click)="btnEliminar(item)"></app-button>
            
            <app-button *ngIf="dataTable.usuarioAdministrador && botonResponderSolicitud && item[dataTable.columnaEstado]===EstadosPedidoType.PENDIENTE_REABRIR" 
              color="gray" type="icon" [icon_fig]="Icons.LIVE_HELP" matTooltip="Resolver"
                matTooltipPosition="above" (click)="btnResponderSolicitud(item)" ></app-button>
            
            <app-button *ngIf="botonCerrar &&                                    
              item[dataTable.columnaEstado]==EstadosPedidoType.TERMINADO"
              color="gray" type="icon" [icon_fig]="Icons.LOCK" matTooltip="Cerrar"
              matTooltipPosition="above" (click)="btnCerrar(item)"></app-button>
            <app-button *ngIf="botonAbrir && item[dataTable.columnaEstado]===EstadosPedidoType.CERRADO" color="gray" type="icon" [icon_fig]="Icons.LOCK_OPEN" matTooltip="Abrir"
              matTooltipPosition="above" (click)="btnAbrir(item)"></app-button>
         
          </div>
          <!-- menu emergente -->
          <app-button color="gray" type="icon" [icon_fig]="Icons.MORE_VERT" matTooltip="Opciones"
            *ngIf="dataTable.operaciones && dataTable.operaciones.length>1" matTooltipPosition="above"
            [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"></app-button>
          <mat-menu #menu="matMenu" class="mat-elevation-z8">
            <button mat-menu-item *ngIf="botonPagoTotal && (item[dataTable.columnaEstado]==EstadosPedidoType.CERRADO)"
            (click)="btnPagoTotal(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.POINT_OF_SALE}}</mat-icon>
              <span>Pago Total</span>
            </button>

            <button mat-menu-item *ngIf="botonPagoParcial && (item[dataTable.columnaEstado]!=EstadosPedidoType.ANULADO &&
              item[dataTable.columnaEstado]!=EstadosPedidoType.CERRADO)"
            (click)="btnPagoParcial(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.EDIT_SQUARE}}</mat-icon>
              <span>Dscto. e Incidencia</span>
            </button>

            <button mat-menu-item *ngIf="botonDividirCuenta && 
              item[dataTable.columnaEstado]!=EstadosPedidoType.ANULADO &&           
              item[dataTable.columnaEstado]!=EstadosPedidoType.CERRADO &&
              item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR  &&
              item[dataTable.columnaEstado]!==EstadosProductoType.PENDIENTE_REVISION
            "
            (click)="btnDividirCuenta(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.CALL_SPLIT}}</mat-icon>
              <span>Dividir</span>
            </button>
            
            <button mat-menu-item *ngIf="botonGestionarPassword" (click)="btnPassword(item); menuTrigger.closeMenu()">
              <mat-icon>{{Icons.PASSWORD}}</mat-icon>
              <span>Gestión Contraseña</span>
            </button>
            <button mat-menu-item *ngIf="botonNuevo">
              <mat-icon>{{Icons.ADD}}</mat-icon>
              <span>Nuevo</span>
            </button>
            <button mat-menu-item *ngIf="botonEditar && item[dataTable.columnaEstado]!==EstadosPedidoType.ANULADO" (click)="btnEditar(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.EDIT}}</mat-icon>
              <span>Editar</span>
            </button>
            <button mat-menu-item *ngIf="botonAgregar && item[dataTable.columnaEstado]!==EstadosPedidoType.ANULADO && item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR" (click)="btnAgregar(item, $event); menuTrigger.closeMenu()">
              <mat-icon>{{Icons.ADD}}</mat-icon>
              <span>Agregar</span>
            </button>
            <button mat-menu-item 
            *ngIf="(botonEditarPedido &&             
            dataTable.usuarioAdministrador &&
            item[dataTable.columnaEstado]!==EstadosProductoType.TERMINADO &&
            item[dataTable.columnaEstado]!==EstadosPedidoType.ANULADO &&
            item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR
            )
            ||
            (botonEditarPedido && 
            item[dataTable.columnaEstado]!==EstadosProductoType.PENDIENTE_REVISION &&            
            item[dataTable.columnaEstado]!==EstadosProductoType.TERMINADO &&
            item[dataTable.columnaEstado]!==EstadosPedidoType.ANULADO &&
            item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR &&          
            !dataTable.usuarioAdministrador)
            "
            (click)="btnEditarPedido(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.EDIT}}</mat-icon>
              <span>Editar</span>
            </button>
            <button mat-menu-item 
            *ngIf="botonEditarPedido && 
              dataTable.usuarioAdministrador && 
              item[dataTable.columnaEstado]!==EstadosPedidoType.PENDIENTE_REABRIR &&    
              item[dataTable.columnaEstado]!==EstadosProductoType.PENDIENTE_REVISION &&                      
              item[dataTable.columnaEstado]!==EstadosPedidoType.ANULADO"
            (click)="btnTraspasarProducto(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.SWAP_HORIZ}}</mat-icon>
              <span>Traspasar</span>
            </button>                  
            <button mat-menu-item
              *ngIf="botonActivarPersona && item[dataTable.columnaEstado]===MsjEstado.ACTIVO.toLowerCase()"
              (click)="btnEditarInactivarPersona(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.PERSON_OFF}}</mat-icon>
              <span>Inactivar</span>
            </button>
            <button mat-menu-item
              *ngIf="botonSuspenderPersona && item[dataTable.columnaEstado]===MsjEstado.INACTIVO.toLowerCase()"
              (click)="btnEditarActivarPersona(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.PERSON}}</mat-icon>
              <span>Activar</span>
            </button>
            <button mat-menu-item *ngIf="botonActivar && item[dataTable.columnaEstado]===MsjEstado.ACTIVO.toLowerCase()"
              (click)="btnEditarInactivar(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.TOGGLE_OFF}}</mat-icon>
              <span>Inactivar</span>
            </button>
            <button mat-menu-item
              *ngIf="botonSuspender && item[dataTable.columnaEstado]===MsjEstado.INACTIVO.toLowerCase()"
              (click)="btnEditarActivar(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.TOGGLE_ON}}</mat-icon>
              <span>Activar</span>
            </button>
            <button mat-menu-item *ngIf="botonVer" (click)="btnVer(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.VISIBILITY}}</mat-icon>
              <span>Ver</span>
            </button>          
            <button mat-menu-item *ngIf="botonVerImagen && item[dataTable.columnaImagen]" (click)="btnVerImagen(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.PHOTO_LIBRARY}}</mat-icon>
              <span>Ver Imágenes</span>
            </button>
            <button mat-menu-item 
              *ngIf="botonImprimir && (item[dataTable.columnaEstado]===EstadosPedidoType.TERMINADO || 
              item[dataTable.columnaEstado]===EstadosPedidoType.CERRADO || 
              item[dataTable.columnaEstado]===EstadosPedidoType.PENDIENTE_REABRIR )" 
            (click)="btnImprimir(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.PRINT}}</mat-icon>
              <span>Imprimir</span>
            </button>      
            <button mat-menu-item *ngIf="dataTable.usuarioAdministrador && botonResponderSolicitud && item[dataTable.columnaEstado]===EstadosPedidoType.PENDIENTE_REABRIR" 
              (click)="btnResponderSolicitud(item);menuTrigger.closeMenu()">
                <mat-icon>{{Icons.LIVE_HELP}}</mat-icon>
                <span>Resolver</span>
            </button>
            <button mat-menu-item *ngIf="botonEliminar" (click)="btnEliminar(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.DELETE}}</mat-icon>
              <span>Eliminar</span>
            </button>
            <button mat-menu-item *ngIf="botonCerrar &&                                    
            item[dataTable.columnaEstado]==EstadosPedidoType.TERMINADO"(click)="btnCerrar(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.LOCK}}</mat-icon>
              <span>Cerrar</span>
            </button>
            <button mat-menu-item *ngIf="botonAbrir && item[dataTable.columnaEstado]===EstadosPedidoType.CERRADO" 
            (click)="btnAbrir(item);menuTrigger.closeMenu()">
              <mat-icon>{{Icons.LOCK_OPEN}}</mat-icon>
              <span>Abrir</span>
            </button>            
          
          </mat-menu>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnas; sticky: true"></mat-header-row>
    <ng-container *ngIf="rowSelection; else sinRipple">
      <mat-row matRipple *matRowDef="let row; columns: columnas;" (click)="selectedRow(row)"></mat-row>
    </ng-container>
    <ng-template #sinRipple>
      <mat-row *matRowDef="let row; columns: columnas;"></mat-row>
    </ng-template>
  </mat-table>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <app-pagination [paginacion]="paginatorN" *ngIf="!isLoading && showPagination"></app-pagination>
</div>
<div *ngIf="dataSource.length==0" style="margin-left: 20px;">
  <h4>No se tiene datos.</h4>
</div>
