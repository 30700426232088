import { Component, Inject, OnInit } from '@angular/core';
import { Icons } from '../../cons/icons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { Length_Database, Msjs, Msjs_Validations, PatternsRegex, SistOperation } from '../../cons/common';
import { GestionAmbientesBlService } from '../procesos/gestion-ambientes-mesas/services/services.bl/gestion-ambientes.bl.service';
import { Subscription } from 'rxjs';
import { MediosPagoBlService } from '../procesos/gestion-medios-pago/services/medios-pago.bl.service';
import { GestionAspectosBlService } from '../procesos/gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { Turno } from './models/Turno';
import { RegistroService } from '../../services/registro.service';
import { SnackBarType } from '../../types/snackbar-type';
import { UIService } from '../../services/ui.services';
import { GestionMontoBlService } from './services/gestion-monto.bl.service';
import { FunctionsService } from '../../services/functions.service';
import { Utils } from '../../helpers/utils';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import * as moment from "moment";
import { SolicitarCambioType } from '../../types/solicitar-cambio-type';
import { ProcesoType } from '../../types/proceso-type';
import { VariacionEfectivo } from './models/VariacionEfectivo';
import { UINavigateSidenavService } from '../../services/ui.navigate-sidenav.services';
@Component({
  selector: 'app-gestion-monto',
  templateUrl: './gestion-monto.component.html',
  styleUrls: ['./gestion-monto.component.scss']
})
@AutoUnsubscribe
export class GestionMontoComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  CLOSE: string = Icons.CLOSE;
  titleModal: string = '';
  isLoading: boolean = false;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly SistOperation: typeof SistOperation = SistOperation;
  readonly SolicitarCambioType: typeof SolicitarCambioType = SolicitarCambioType;
  readonly Msjs: typeof Msjs = Msjs;
  ambienteSb = new Subscription();
  mediosPagoSb = new Subscription();
  tasaServSb = new Subscription();
  existeAmbiente: boolean = false;
  existeMediosPago: boolean = false;
  existeTasa: boolean = false;
  turno = new Turno();

  fechaHoy: any;
  modalSb = new Subscription();
  esAdministrador: boolean = false;
  efectivoAnterior: number = 0;
  usuarioSolicitante: string = '';
  labelTxt: string = 'Monto inicial en efectivo';
  anotacionRetiro:string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado: any,
    private dialogRef: MatDialogRef<GestionMontoComponent>,
    private gestionAmbienteBlService: GestionAmbientesBlService,
    private mediosPagoBlServices: MediosPagoBlService,
    private aspectosGeneralesBlService: GestionAspectosBlService,
    private registroService: RegistroService,
    private uiService: UIService,
    private gestionMontoBlService: GestionMontoBlService,
    private functionService: FunctionsService,
    private readonly spinnerPageService: SpinnerPageService,
    private uiNavigateSide: UINavigateSidenavService,
  ) { }

  ngOnInit() {
    if (this.registroService.empresa) {
      this.comprobarRequisitos();
    }

    switch (this.datoPasado.operacion) {
      case SistOperation.NUEVO: {
        this.cargarFechas();
        this.titleModal = 'Nuevo Turno';
        this.turno.es_vigente = true;
        this.turno.es_abierto = true;
        break;
      }
      case SistOperation.AGREGAR: {
        this.cargarFechas();
        this.titleModal = 'Añadir Efectivo';
        this.labelTxt = 'Monto';
        //seteando turno
        const turnoPasado = (this.datoPasado.data) as Turno;
        this.turno.id = turnoPasado.id;
        //seteadno montos añadidos
        if (turnoPasado.montos_aniadidos) {
          this.turno.montos_aniadidos = turnoPasado.montos_aniadidos;
        }
        break;
      }
      case SistOperation.RETIRAR: {
        this.cargarFechas();
        this.titleModal = 'Retirar Efectivo';
        this.labelTxt = 'Monto';
        this.esAdministrador = false;
         //seteando turno
         const turnoPasado = (this.datoPasado.data) as Turno;
         this.turno.id = turnoPasado.id;
         //seteadno montos añadidos
         if (turnoPasado.montos_retirados) {
           this.turno.montos_retirados = turnoPasado.montos_retirados;
         }
        break;
      }
      case SistOperation.REINGRESAR: {
        this.cargarFechas();
        this.titleModal = 'Actualizar Efectivo';
        this.cargarDatosEdicion();
        this.esAdministrador = true;
        break;
      }
      case SistOperation.SOLICITAR: {
        this.cargarFechas();
        this.titleModal = 'Solicitar Actualizar';
        this.cargarDatosEdicion();
        this.esAdministrador = false;
        break;
      }
      case SistOperation.PROCESAR_SOLICITUD: {
        this.cargarFechas();
        this.titleModal = 'Solicitud Actualizar';
        this.cargarDatosEdicion();
        this.esAdministrador = true;
        break;
      }
      case SistOperation.PROCESAR_AGREGAR: {
        this.cargarFechas();
        this.cargarAgregar();
        this.titleModal = 'Solicitud Añadir';
        this.esAdministrador = true;
        break;
      }
      case SistOperation.PROCESAR_RETIRAR: {
        this.cargarFechas();
        this.cargarRetirar();
        this.titleModal = 'Solicitud Retirar';
        this.esAdministrador = true;
        break;
      }
      default:
        {
          this.dialogRef.close();
          return;
        }
    }

  }

  cargarRetirar(){
    const turnoPasado = (this.datoPasado.data) as Turno;
    const retirarPendiente = turnoPasado.montos_retirados?.filter(retEfectivo=>
      retEfectivo.estado_cambio==SolicitarCambioType.SOLICITADO &&
      retEfectivo.es_vigente
      );
    if(retirarPendiente && retirarPendiente.length>0){
      this.turno.monto_inicial_efectivo = retirarPendiente[0].monto;
      if(retirarPendiente[0].anotacion)
      this.anotacionRetiro = retirarPendiente[0].anotacion;
    }
    this.turno.montos_retirados = turnoPasado.montos_retirados;
    this.turno.id = turnoPasado.id;
  }

  cargarAgregar(){
    const turnoPasado = (this.datoPasado.data) as Turno;
    const agregarPendiente = turnoPasado.montos_aniadidos?.filter(addEfectivo=>
      addEfectivo.estado_cambio==SolicitarCambioType.SOLICITADO &&
      addEfectivo.es_vigente
      );
    if(agregarPendiente && agregarPendiente.length>0){
      this.turno.monto_inicial_efectivo = agregarPendiente[0].monto;
    }
    this.turno.montos_aniadidos = turnoPasado.montos_aniadidos;
    this.turno.id = turnoPasado.id;
  }

  async cargarFechas() {
    this.fechaHoy = moment(await this.functionService.GetDateServer()).local().format();
    const fechas = Utils.separarFechasHoras(this.fechaHoy);
    this.turno.fecha_key.anio = fechas[0];//anio
    this.turno.fecha_key.mes = fechas[1];//mes
    this.turno.fecha_key.dia = fechas[2];//dia
    this.turno.fecha_key.hora = fechas[3];//hora
    this.turno.fecha_key.minuto = fechas[4];//minuto
    this.turno.fecha_key.segundo = fechas[5];//seg

  }

  cargarDatosEdicion() {
    this.turno = ({ ...this.datoPasado.data }) as Turno;

    if (this.datoPasado.operacion === SistOperation.PROCESAR_SOLICITUD) {
      this.efectivoAnterior = this.turno.monto_inicial_efectivo;
      const montoFuturo = this.turno.correccion_monto_inicial?.filter(
        correcc => correcc.estado_cambio === SolicitarCambioType.SOLICITADO &&
          correcc.proceso === ProcesoType.CAMBIAR_MONTO_INICIAL &&
          correcc.es_vigente
      );
      if (montoFuturo && montoFuturo.length > 0) {
        this.usuarioSolicitante = montoFuturo[0].usuario_creacion;
        this.turno.monto_inicial_efectivo = montoFuturo[0].monto_actual
      }
    }

  }

  comprobarRequisitos() {
    //verificar si tiene medios de pago configurados
    this.mediosPagoBlServices.existFecthMedios();
    if (this.mediosPagoSb) {
      this.ambienteSb.unsubscribe();
    }
    this.mediosPagoSb = this.mediosPagoBlServices.getTasas().subscribe(confMedios => {
      if (confMedios?.id) {
        this.existeMediosPago = true;
      }
    });
    //verficiar si tiene tasa de servicio e impuesto configurados
    this.aspectosGeneralesBlService.existFetchTasas();
    if (this.tasaServSb) {
      this.tasaServSb.unsubscribe();
    }
    this.tasaServSb = this.aspectosGeneralesBlService.getTasas().subscribe(confTasas => {
      if (confTasas?.id) {
        this.existeTasa = true;
      }
    });
    this.gestionAmbienteBlService.existFetchAmbienteMesa();
    if (this.ambienteSb) {
      this.ambienteSb.unsubscribe();
    }
    //verrficiar si tiene mesas configuradas
    this.ambienteSb = this.gestionAmbienteBlService.getAmbienteMesa().subscribe(confAmbMesa => {
      if (confAmbMesa) {
        this.existeAmbiente = true;
      }
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  seleccionarTodo(event: any) {
    event.target.select();
  }

  onProcesar(f: NgForm, esAprobar: boolean) {
    if (f.valid) {
      this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon: Icons.QUESTION_MARK })
        .subscribe(async result => {
          if (result) {
            this.isLoading=true;
            if (this.datoPasado.operacion == SistOperation.PROCESAR_SOLICITUD) {
              const esProcesar: boolean = true;//indica si se procesa una solicitud            
              const accion = esAprobar ? SolicitarCambioType.APROBADO : SolicitarCambioType.RECHAZADO;
              this.gestionMontoBlService.updateTurnoReingresoMonto(this.turno, this.efectivoAnterior, 
                this.esAdministrador, esProcesar, accion, this.uiNavigateSide.goOptionSidenavItem // se envia el modulo de donde se crea el mensaje

              ).then(tx => {
                if (tx.tx) {
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                }
                else {
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                  this.spinnerPageService.hide();
                }
                this.dialogRef.close();
                return;
              });
            }

            if (this.datoPasado.operacion == SistOperation.PROCESAR_AGREGAR) {
              const esProcesar: boolean = true;//indica si se procesa una solicitud            
              const accion = SolicitarCambioType.RECHAZADO;
              this.gestionMontoBlService.updateTurnoProcesarAgregar(this.turno, accion).then(tx => {
                if (tx.tx) {
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                }
                else {
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                  this.spinnerPageService.hide();
                }
                this.dialogRef.close();
                return;
              });
            }

            if (this.datoPasado.operacion == SistOperation.PROCESAR_RETIRAR) {
              const esProcesar: boolean = true;//indica si se procesa una solicitud            
              const accion = SolicitarCambioType.RECHAZADO;
              this.gestionMontoBlService.updateTurnoProcesarRetirar(this.turno, accion).then(tx => {
                if (tx.tx) {
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                }
                else {
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                  this.spinnerPageService.hide();
                }
                this.dialogRef.close();
                return;
              });
            }


          }
        });
    }

  }

  completarDecimal(event: any) {
    let valor = parseFloat(event.target.value);
    if (!isNaN(valor)) {
      event.target.value = valor.toFixed(2);
      this.turno.monto_inicial_efectivo = event.target.value;
    }
  }

  onSubmit(f: NgForm) {
    if (f.valid) {
      if (!this.existeMediosPago) {
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_MEDIO_PAGO, 3000);
        return;
      }
      if (!this.existeTasa) {
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_TASA_SERVICIO, 3000);
        return;
      }
      if (!this.existeAmbiente) {
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_AMBIENTE_MESAS, 3000);
        return;
      }
      this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon: Icons.QUESTION_MARK })
        .subscribe(async result => {
          if (result) {
            this.spinnerPageService.show();
            //ADMINISTRADOR Y CAJA         
            if (this.datoPasado.operacion == SistOperation.NUEVO) {
              this.gestionMontoBlService.insertTurno(this.turno).then(tx => {
                if (tx.tx) {
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                }
                else {
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                  this.spinnerPageService.hide();
                }
                this.dialogRef.close();
                return;
              });
            }
            // ADMINISTRADOR Y CAJA
            if (this.datoPasado.operacion == SistOperation.AGREGAR) {
              const esAdministrador = this.registroService.esAdministrador;

              this.gestionMontoBlService.updateTurnoAgregarMonto(this.turno, esAdministrador,
                this.uiNavigateSide.goOptionSidenavItem // se envia el modulo de donde se crea el mensaje
              ).then(tx => {
                if (tx.tx) {
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                }
                else {
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                  this.spinnerPageService.hide();
                }
                this.dialogRef.close();
                return;
              });
            }
            // ADMINISTRADOR Y CAJA
            if (this.datoPasado.operacion == SistOperation.RETIRAR) {
              const esAdministrador = this.registroService.esAdministrador;

              this.gestionMontoBlService.updateTurnoRetirarMonto(this.turno, esAdministrador, this.anotacionRetiro,
                this.uiNavigateSide.goOptionSidenavItem // se envia el modulo de donde se crea el mensaje
              ).then(tx => {
                if (tx.tx) {
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                }
                else {
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                  this.spinnerPageService.hide();
                }
                this.dialogRef.close();
                return;
              });
            }
            // reingresa monto ADMINISTRADOR
            if (this.datoPasado.operacion == SistOperation.REINGRESAR) {
              const datoPasad = this.datoPasado.data as Turno;
              if (this.turno.monto_inicial_efectivo === datoPasad.monto_inicial_efectivo) {
                this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_SIN_CAMBIOS, 3000);
                this.dialogRef.close();
                return;
              }
              const esProcesar: boolean = false;
              this.gestionMontoBlService.updateTurnoReingresoMonto(this.turno, datoPasad.monto_inicial_efectivo, this.esAdministrador, esProcesar, null,
                this.uiNavigateSide.goOptionSidenavItem // se envia el modulo de donde se crea el mensaje
              ).then(tx => {
                if (tx.tx) {
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                  this.dialogRef.close();
                }
                else {
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                  this.spinnerPageService.hide();
                }
                
                return;
              });
            }
            // SOLICITA CAMBIO SOLO ROL CAJA
            if (this.datoPasado.operacion == SistOperation.SOLICITAR) {
              const datoPasad = this.datoPasado.data as Turno;
              if (this.turno.monto_inicial_efectivo === datoPasad.monto_inicial_efectivo) {
                this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_SIN_CAMBIOS, 3000);
                this.dialogRef.close();
                return;
              }
              const esProcesar: boolean = false;
              this.gestionMontoBlService.updateTurnoReingresoMonto(this.turno, datoPasad.monto_inicial_efectivo, this.esAdministrador, esProcesar, null,
                this.uiNavigateSide.goOptionSidenavItem // se envia el modulo de donde se crea el mensaje
              ).then(tx => {
                if (tx.tx) {
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                }
                else {
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                  this.spinnerPageService.hide();
                }
                this.dialogRef.close();
                return;
              });
            }
            //PROCESA SOLICITUD SOLO ADMINISTRADOR
            if (this.datoPasado.operacion == SistOperation.PROCESAR_SOLICITUD) {
              const esProcesar: boolean = true;//indica si se procesa una solicitud            
              const accion = SolicitarCambioType.APROBADO;
              this.gestionMontoBlService.updateTurnoReingresoMonto(this.turno, this.efectivoAnterior, this.esAdministrador, esProcesar, accion,
                this.uiNavigateSide.goOptionSidenavItem // se envia el modulo de donde se crea el mensaje
              ).then(tx => {
                if (tx.tx) {
                  this.dialogRef.close();
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                }
                else {
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                  this.spinnerPageService.hide();
                }
                
                return;
              });
            }
            // procesar agregar solo administrador
            if (this.datoPasado.operacion == SistOperation.PROCESAR_AGREGAR) {
              const esProcesar: boolean = true;//indica si se procesa una solicitud            
              const accion = SolicitarCambioType.APROBADO;
              this.gestionMontoBlService.updateTurnoProcesarAgregar(this.turno, accion).then(tx => {
                if (tx.tx) {
                  this.spinnerPageService.hide();
                  this.dialogRef.close();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                }
                else {
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                  this.spinnerPageService.hide();
                }
                
                return;
              });
            }

             // procesar agregar solo administrador
             if (this.datoPasado.operacion == SistOperation.PROCESAR_RETIRAR) {
              const esProcesar: boolean = true;//indica si se procesa una solicitud            
              const accion = SolicitarCambioType.APROBADO;
              this.gestionMontoBlService.updateTurnoProcesarRetirar(this.turno, accion).then(tx => {
                if (tx.tx) {
                  this.spinnerPageService.hide();
                  this.dialogRef.close();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                }
                else {
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                  this.spinnerPageService.hide();
                }
                
                return;
              });
            }
          }

        });



    }
  }

  Limpiar(f: NgForm) {
    this.turno.monto_inicial_efectivo = 0.0;
  }

 


}
