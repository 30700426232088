import { Injectable } from '@angular/core';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { GestionOperacionesDAService } from './gestion-operaciones.da.service';
import { Observable } from 'rxjs';
import { IModoOperacion } from '../models/IModoOperacion';

@Injectable({
  providedIn: 'root',
})
export class GestionOperacionesBLService {
  constructor(
    private gestionOperacionesDAService: GestionOperacionesDAService,
    private registroService: RegistroService
  ) {}

  existFetchDscto() {
    const idEmpresa = this.registroService.empresa.id;
    const idUsuario = this.registroService.usuario.id;
    this.gestionOperacionesDAService.fetchModoOp(idEmpresa, idUsuario);
  }

  getModoOp():Observable<IModoOperacion | null | undefined>{
    return this.gestionOperacionesDAService.modoOp$;
  }

  insertModoOp(dscto: IModoOperacion){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionOperacionesDAService.insertOperaciones(idEmpresa,correoUsuario,dscto);
  }

  updateModoOp(dscto:IModoOperacion){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionOperacionesDAService.updateModoOperacion(dscto,idEmpresa,correoUsuario);
  }



}