import { IModuloEmpresa } from "../../../dialog/models/modulo.empresa.model";
import { CostoFacturacion } from "./costo-facturacion.model";
import { MontoModulo } from "./monto-modulo";

export class Facturacion {
  id_empresa:string='';
  id_usuario_cuenta:string='';
  costo_facturacion!: CostoFacturacion[];
  fecha_registro: any;
  fecha_modificacion:any;
  dias_periodo_facturable: number = 30;
  //es_periodo_prueba:boolean=false;
  //propiedad utilitaria
  id?:string
}
