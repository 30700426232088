<div *ngIf="!showSpinner" >
  <form #f="ngForm" >
    <div [fxLayout]="formaFila?'row':'column'" [fxLayoutAlign]="formaFila?'start center':'space-around center'" fxLayout.xs="column" fxLayoutGap="20px">
      <p>
        Días laborales:
        <mat-button-toggle-group
          name="dias"
          aria-label="dias"
          multiple
          [(ngModel)]="horarioEmpresa.dias"
          (change)="onChange($event)"
          [disabled]="toogleDisabled || disabled"
           >
          <mat-button-toggle value="d">D</mat-button-toggle>
          <mat-button-toggle value="l">L</mat-button-toggle>
          <mat-button-toggle value="m">M</mat-button-toggle>
          <mat-button-toggle value="x">X</mat-button-toggle>
          <mat-button-toggle value="j">J</mat-button-toggle>
          <mat-button-toggle value="v">V</mat-button-toggle>
          <mat-button-toggle value="s">S</mat-button-toggle>
        </mat-button-toggle-group>
      </p>
      <div fxLayoutAlign="space-between center" fxLayout="row" [fxLayoutGap]="formaFila?'10px':'2px'" style="padding-top: 10px;" >
        <div>
          <mat-form-field appearance="outline" class="custom-input">
            <mat-label>Inicio</mat-label>
            <input matInput placeholder="00:00"
            [pattern]="PatternsRegex.onlyHour"
            class="custom-input"
            name="inicioInput"
            ngModel
            #inicioInput="ngModel"
            (keypress) ="onlyHorasMinutos($event)"
            [(ngModel)]="horarioEmpresa.hora_inicio"
            (blur)="gestionarCambio(f)"
            [disabled]="disabled"
            >
            <mat-icon matSuffix>{{Icons.SCHEDULE}}</mat-icon>
            <mat-hint>Formato de 24Hs</mat-hint>
            <mat-error *ngIf="inicioInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="inicioInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_HORAS_MINUTOS}}</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline"class="custom-input">
            <mat-label>Fin</mat-label>
            <input matInput placeholder="00:00"
            class="custom-input"
            [pattern]="PatternsRegex.onlyHour"
            name="finInput"
            ngModel
            #finInput="ngModel"
            (keypress) ="onlyHorasMinutos($event)"
            [(ngModel)]="horarioEmpresa.hora_final"
            (blur)="gestionarCambio(f)"
            [disabled]="disabled"
            >
            <mat-icon matSuffix>{{Icons.UPDATE}}</mat-icon>
            <mat-hint  *ngIf="!mostrarAdvertencia">Formato de 24Hs</mat-hint>
            <mat-hint style="color:var(--blueA200)" *ngIf="mostrarAdvertencia">Hora final menor a la hora inicial.</mat-hint>
            <mat-error *ngIf="finInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="finInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_HORAS_MINUTOS}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
<div *ngIf="showSpinner" >
  <app-spinner></app-spinner>
</div>


