import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Length_Database, Msjs, PatternsRegex } from '../../cons/common';
import { Utils } from '../../helpers/utils';
import { RegistroService } from '../../services/registro.service';
import { UIService } from '../../services/ui.services';
import { SnackBarType } from '../../types/snackbar-type';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Icons } from '../../cons/icons';
import { IModulo } from '../dialog/models/modulo.model';
import { AutoUnsubscribe } from "../../helpers/decorators/AutoUnsubscribe";
import { NgForm } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Facturacion } from '../procesos/gestion-colaborador/models/facturacion.model';
import { blinkAnimation } from '../../animations/animations';
import { MontoModulo } from '../procesos/gestion-colaborador/models/monto-modulo';
import { ReduccionUsuario } from '../procesos/gestion-colaborador/models/reduccion-usuario.model';

@Component({
  selector: 'app-modulo-plataforma',
  templateUrl: './modulo-plataforma.component.html',
  styleUrls: ['./modulo-plataforma.component.scss'],
  animations: [blinkAnimation]
})
@AutoUnsubscribe

export class ModuloPlataformaComponent implements OnInit {
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  onlyNumbers = Utils.InputOnlyNumbers;

  @Input() nombre:string="";
  @Input() descripcion:string="";
  @Input() isChecked:boolean=false;
  @Input() cantidadIn:number=0;
  @Input() isDisabledCantidad:boolean=false;
  @Input() isDisabledToogle: boolean = false;
  @Input() isCheckedToogle: boolean = false;
  @Input() orden:number=0;
  @Input() totalColaboradores:number=0;
  @Input() cantidad_usuarios_x_defecto: number=0;
  @Input() costo_modulo: number=0;
  @Input() es_modulo_x_defecto:boolean=false;
  @Input() costo_usuario_adicional:number=0;
  @Input() is_disabled:boolean = true;
  @Input() costoTotalModoEdicion:number=0;
  @Input() codigoModulo:string=''
  @Input() esModoEdicion:boolean = false;
  @Input() es_existente:boolean | undefined = false;
  @Input() set facturacion(factura:Facturacion){
    if(factura.id_usuario_cuenta!=''){
      this.factura = factura;
      this.facturacionSj.next(factura);

    }
  }

  readonly Icons: typeof Icons = Icons;
  costo_modulo_local!:number;
  reiniciarToogleSb!: Subscription;
  falsearTooglesSb!: Subscription;
  disabledModulosControlesSb!: Subscription;
  sbModalQuest!:Subscription;

  readonly Length_Database: typeof Length_Database = Length_Database;

  esDecremento:boolean =false;

  blinkState: string = 'inactive';
  msjReduccionUsuarios:string=''
  cantidadReduccionUsuarios:number=0;

  private facturacionSj = new BehaviorSubject<Facturacion | null>(null);
  private pendienteAnulacionModulSj = new BehaviorSubject<boolean>(false);

  facturacion$ = this.facturacionSj.asObservable();
  pendienteAnulacionModul$ = this.pendienteAnulacionModulSj.asObservable();
  pendienteAnulacionModulSb!: Subscription;
  factura: Facturacion = new Facturacion ();
  facturaSb!:Subscription;

  cantidadInTmp:number=0;
  disabledModulo:boolean = false;


  constructor(
  private registroService: RegistroService,
  private uiService:UIService,
  ) { }

  ngOnInit(): void {
    //animacion
    setInterval(() => {
      this.blinkState = this.blinkState === 'active' ? 'inactive' : 'active';
    }, 700);  // Cambia el estado cada 500ms

    if(this.costoTotalModoEdicion!=0){
      this.costo_modulo_local= this.costoTotalModoEdicion;
    }

    this.reiniciarToogleSb = this.registroService.reiniciarControlToogle$.subscribe(isReady => {
      if (this.orden == 1) {
        this.isCheckedToogle = true;
        this.isDisabledToogle = true;
      } else {
        this.isCheckedToogle = false;
        this.isDisabledToogle = false;
      }

      this.isDisabledCantidad = true;
      this.totalColaboradores = 0;
      this.cantidadIn = 0;
      this.costo_modulo_local=0;
    });

    //desactiva los controles despúes de guardar
    this.disabledModulosControlesSb = this.registroService.disabledModulosControles$.subscribe(res=>{
      this.isDisabledToogle = res;

    });

    this.falsearTooglesSb = this.registroService.falsearTooglesSinCantidad$.subscribe(flag=>{
      if(flag && this.orden!=1){
        if(this.isCheckedToogle && (this.cantidadIn==undefined || this.cantidadIn==0) ){
          this.isCheckedToogle=false;
          this.cantidadIn = 0;
          this.costo_modulo_local=0;
          this.isDisabledCantidad = true
          let msj = Msjs.MSJ_CANTIDAD_NO_VALIDA.replace("$",this.nombre);
          this.uiService.ShowSnackBar(SnackBarType.ERROR, msj, 5000);
        }
      }
    });
    //asignando decremento y anulacion de modulo si existe

    this.facturaSb = this.facturacion$.subscribe(factura => {
      if (factura?.id_empresa) {
        this.factura = factura;
        factura.costo_facturacion.forEach(modulo => {
          if (modulo.codigo_modulo == this.codigoModulo) {
            if (modulo.cantidad_usuarios_variacion) {
              this.esDecremento = true;
              this.cantidadReduccionUsuarios = modulo.cantidad_usuarios_variacion.cantidad_reduccion;
              this.registroService.envioDecremento$.next(modulo.cantidad_usuarios_variacion);
              this.msjReduccionUsuarios = Msjs.MENSAJE_PENDIENTE_REDUCCION_USUARIOS.replace('$', this.cantidadReduccionUsuarios.toString());
            }
            if (modulo.pendiente_anulacion_modulo) {
              this.disabledModulo = true;
              //cuando no tiene colaboradores.
              this.isDisabledToogle = true;
              this.isCheckedToogle = false;
              //TODO: remover anular facturacion
              this.removerDecremento(false);
              this.registroService.envioAnularModulo$.next(this.codigoModulo);
            }
          }
        });
      }
    });

    //asignando anulacion modulo
    this.pendienteAnulacionModulSb = this.pendienteAnulacionModul$.subscribe((anulacion:boolean)=>{
      if(anulacion){
        if(this.esModoEdicion){
          this.disabledModulo = true;
          //cuando no tiene colaboradores.
          this.isDisabledToogle = true;

          //TODO: Cuando se desactiva un modulo en el transcurso de un período, el costo del modulo es facturado por el mes completo.
          this.removerDecremento();
          this.registroService.envioAnularModulo$.next(this.codigoModulo);
        }
      }
    });

    if(!this.esModoEdicion){
      this.disabledModulo=true;
    }else{
      if(this.isCheckedToogle){
        this.disabledModulo=false;
      }else{
        this.disabledModulo=true;
      }
    }



  }


  validarLimiteCuotaUsuariosXDefectoModulo(nombre:string, reduccion:number){
    let continuar:boolean = true;
    for(const elemento of this.factura.costo_facturacion){
      if (this.codigoModulo === elemento.codigo_modulo) {
        const cantidad_maxima_reduccion = elemento.cantidad_total_usuarios - elemento.cantidad_usuarios_x_defecto
        if(!(reduccion<= cantidad_maxima_reduccion)){
          if(!this.esModoEdicion){
            let msj = Msjs.MSJ_CANTIDAD_NO_VALIDA.replace("$", nombre);
            this.uiService.ShowSnackBar(SnackBarType.ERROR, msj, 5000);
          }else{
            let msj = Msjs.MSJ_CANTIDAD_NO_VALIDA_EDITAR.replace("$", nombre);
            this.uiService.ShowSnackBar(SnackBarType.ERROR, msj, 5000);
          }
          continuar = false;
        }
        break;
      }
    }
    return continuar;
  }

  validarLimiteCuotaUsuariosExistentes(reduccion:number, nombre:string) {
    let continuar: boolean = true;
    if (this.registroService.cantidad_modulos_seleccionados.length > 0) {
      for(const elemento of this.registroService.cantidad_modulos_seleccionados){
        if (this.codigoModulo == elemento.codigo) {
          const cantidad_maxima_reduccion = elemento.cantidad_total_usuarios_permitidos - elemento.cantidad_total_usuarios_matriculados;
          if (!(reduccion <= cantidad_maxima_reduccion)) {
            continuar = false;
            const msj = Msjs.MENSAJE_CANTIDAD_USUARIOS_MATRICULADOS.replace('$',
              elemento.cantidad_total_usuarios_matriculados.toString()
            )
            this.uiService.ShowSnackBar(SnackBarType.WARNING, msj, 10000);
          }
          break;
        }
      }
    }

    return continuar
  }

  correctoReduccionUsuarios(nombre: string, operacion: string, reduccion: number) {
    let continuar: boolean = true;
    if (operacion == 'minus') {
      this.esDecremento = true;
      continuar = this.validarLimiteCuotaUsuariosExistentes(reduccion, nombre);
      if (continuar) {
        continuar = this.validarLimiteCuotaUsuariosXDefectoModulo(nombre, reduccion);
        if (continuar) {
          this.esDecremento = true;
        }
        else {
          this.esDecremento = true;
        }
      }
    }
    return continuar;
  }

  generarEnvio(orden:number ){
    let montos: any;
    montos = this.calcularCostoModulo(
      orden,
      this.cantidadIn,
      this.cantidad_usuarios_x_defecto,
      this.costo_usuario_adicional,
      this.costo_modulo,
      this.nombre,
      this.esModoEdicion? this.esDecremento: false
    );
    this.costo_modulo_local = montos.costo_total;
    this.registroService.envioCantidad$.next(montos);
  }

  generarDecremento(orden:number, cantidad_reduccion:number){
    const reduccion: ReduccionUsuario = new ReduccionUsuario();
    reduccion.cantidad_reduccion = cantidad_reduccion;
    reduccion.cantidad_total_usuarios = this.cantidadIn;
    reduccion.codigo_modulo = this.codigoModulo;
    reduccion.es_decremento = true;
    this.cantidadReduccionUsuarios = reduccion.cantidad_reduccion ;
    this.registroService.envioDecremento$.next(reduccion);
  }

  removerDecremento(mostrarMsje:boolean=true){
    this.esDecremento = false
    this.registroService.removerDecremento$.next(this.codigoModulo);
    this.cantidadReduccionUsuarios = 0;
    if(mostrarMsje){
      this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs.MSJ_GUARDADO_PENDIENTE, 10000);
    }
  }

  removerAnularModulo(){
    this.disabledModulo = false;
    this.isDisabledToogle = false;
    this.isCheckedToogle = true;
    this.registroService.removerAnularModulo$.next(this.codigoModulo);
    this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs.MSJ_GUARDADO_PENDIENTE, 10000);

  }

  async enviarValor(orden: number, nombre: string, operacion:string) {
    let reduccion=this.cantidadReduccionUsuarios;
    let tiene_reduccion_pendiente:boolean = false;
    let cantidad_reduccion_pendiente:number=0;
    let continuar_flujo:boolean = true;
    let reemplazar_variacion:boolean = true;


    // es edicion
    if(this.esModoEdicion){
      //cuando se tiene factura solo en modo edicion
      for(const elemento of this.factura.costo_facturacion){
        if(this.codigoModulo== elemento.codigo_modulo){
          if(elemento.cantidad_usuarios_variacion?.cantidad_reduccion){
            //agregamos la reduccion pendiente si es que hubiera;
            cantidad_reduccion_pendiente = elemento.cantidad_usuarios_variacion?.cantidad_reduccion;
            reduccion = reduccion+cantidad_reduccion_pendiente
            tiene_reduccion_pendiente = true;
            //console.log(reduccion);
            break;
          }
        }
      }

      //valida si tiene decrementos pendientes:
      if(operacion == 'add' && tiene_reduccion_pendiente){
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MENSAJE_CANTIDAD_USUARIOS_DECREMENTO_AVISO, 5000);
        continuar_flujo = false;
        return;
      }
      if(tiene_reduccion_pendiente && continuar_flujo){
        await this.uiService.showModalQuestions({
          title: Msjs.TITULO_CONFIRMACION,
          message: Msjs.MENSAJE_CANTIDAD_USUARIOS_DECREMENTO_UPDATE,
          icon: Icons.QUESTION_MARK
        }).toPromise().then(result=>{
          if(result==""){
            reduccion =0;
            reemplazar_variacion = false;
            continuar_flujo = false;
            return;
          }
          else{
            reemplazar_variacion = true;
          }
        });
      }
      //añadiendo operacion
      if(operacion=='minus'){
        if(!tiene_reduccion_pendiente){
          reduccion +=1;
        }        
      }
      else{
        this.cantidadIn+=1;
      }

      if(continuar_flujo && operacion=='minus' ){
        continuar_flujo = this.correctoReduccionUsuarios(nombre, operacion, reduccion);
      }
      if (continuar_flujo && reemplazar_variacion) {
        if (!this.esDecremento) {
          this.sbModalQuest = this.uiService.showModalQuestions({
            title: Msjs.TITULO_CONFIRMACION,
            message: Msjs.MENSAJE_CANTIDAD_USUARIOS_INCREMENTO,
            icon: Icons.QUESTION_MARK
          }).subscribe(result => {
            if (result) {
              this.cantidadInTmp = this.cantidadIn;
              this.generarEnvio(orden);
            } else {
              this.cantidadIn = this.cantidadInTmp;
            }
          });
        }else{
          this.sbModalQuest = this.uiService.showModalQuestions({
            title: Msjs.TITULO_CONFIRMACION,
            message: Msjs.MENSAJE_CANTIDAD_USUARIOS_DECREMENTO,
            icon: Icons.QUESTION_MARK
          }).subscribe(result => {
            if (result) {
              this.generarDecremento(orden, reduccion);
              /*1. cuando incrementas no se actualiza el total por modulo (X) */
              /*2. pendiente reducir modulo (X) */
              /*3. pendiente prorrateo por dias cuando se registra (X) */
              /*4. verificar modo registro (X)*/
              /*5. verificar el limpiar(X) */
              /*6. verificar con usuarios registrados (X) */
              /*7. verificar limite de cuota de usuario x defecto (X) */
              this.msjReduccionUsuarios = Msjs.MENSAJE_PENDIENTE_REDUCCION_USUARIOS.replace('$',reduccion.toString());
              this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs.MSJ_GUARDADO_PENDIENTE, 10000);

            }
            else{
              this.esDecremento = false;
            }
            reduccion=0;
          });
        }
      }else{
        return;
      }
    }
    //es registro
    else{
      if(operacion=='add'){
        this.cantidadIn +=1;
      }else{
        this.cantidadIn -=1;
        if(this.cantidadIn<this.cantidad_usuarios_x_defecto){
          let msj = Msjs.MSJ_CANTIDAD_NO_VALIDA.replace("$", nombre);
          this.uiService.ShowSnackBar(SnackBarType.ERROR, msj, 5000);
          //el modulo 1 es gratis
          if (this.orden != 1) {
            this.cantidadIn = this.cantidad_usuarios_x_defecto;
            this.costo_modulo_local = this.costo_modulo;
          }
        }
      }
      this.generarEnvio(orden);
    }
  }

  calcularCostoModulo(
      orden:number,
      cantidadIn:number,
      cantidad_usuarios_x_defecto:number,
      costo_usuario_adicional:number,
      costo_modulo:number,
      nombre:string,
      es_decremento:boolean
      ):any{
    let usuarios_adicionales:number = cantidadIn-cantidad_usuarios_x_defecto;
    let monto_adicional:number = usuarios_adicionales * costo_usuario_adicional;
    let totalMontoModulo:number= costo_modulo +  monto_adicional;

    let montos: MontoModulo ={
      orden: orden,
      cantidad_usuarios_x_defecto: cantidad_usuarios_x_defecto,
      costo_usuario_adicional: costo_usuario_adicional,
      cantidad_total_usuarios: cantidadIn,
      costo_modulo: costo_modulo,
      //costo_adicional: monto_adicional,
      costo_total: totalMontoModulo,
      nombre: nombre,
      es_decremento: es_decremento,
      cantidad_reduccion:0
    }
    return montos
  }

  toogleChange(event: MatSlideToggleChange) {
    this.isDisabledCantidad = !this.isDisabledCantidad;
    if (!event.checked) {
      if (this.esModoEdicion) {
        //si el modulo no esta previamente guardado
        if(this.es_existente==undefined){
          this.registroService.quitarTotalColaboradores$.next({ orden: this.orden, cantidad: this.cantidadIn, costo_total: this.costo_modulo_local });
          this.cantidadIn = 0;
          this.costo_modulo_local = 0;
          this.disabledModulo = true;
          return;
        }
        //cuando this.registroService.cantidad_modulos_seleccionados>0 es modo edicion
        this.sbModalQuest = this.uiService.showModalQuestions({
          title: Msjs.TITULO_CONFIRMACION
          , message: Msjs.MENSAJE_PREGUNTA_USUARIOS_MODULOS
          , icon: Icons.QUESTION_MARK
        }).subscribe((result) => {
          if (result) {
            //llenado en gestion colaborador
            if(this.registroService.cantidad_modulos_seleccionados.length>0){
              this.registroService.cantidad_modulos_seleccionados.forEach(modulo => {
                if (this.codigoModulo == modulo.codigo) {
                  if (modulo.cantidad_total_usuarios_matriculados > 0) {
                    const msj = Msjs.MENSAJE_CANTIDAD_USUARIOS_MATRICULADOS.replace('$',
                      modulo.cantidad_total_usuarios_matriculados.toString()
                    )

                    this.uiService.ShowSnackBar(SnackBarType.WARNING, msj, 5000);
                    //reiniciando el seteo a true
                    this.isCheckedToogle = true;
                    event.source.checked = true;

                    event.source._inputElement.nativeElement.checked = true;
                    event.source._inputElement.nativeElement.dispatchEvent(new Event('change'));

                    return;
                  } else {
                    if(this.esModoEdicion){
                      //se da cuando no se tiene ningun colaborador registrado
                      this.disabledModulo = true;
                      this.isDisabledToogle = true;
                      this.removerDecremento();
                      this.registroService.envioAnularModulo$.next(modulo.codigo);
                      return;
                    }
                    this.registroService.quitarTotalColaboradores$.next({ orden: this.orden, cantidad: this.cantidadIn, costo_total: this.costo_modulo_local });
                    this.cantidadIn = 0;
                    this.costo_modulo_local = 0;



                  }
                }
              });
            }else{
              this.disabledModulo = true;
              this.removerDecremento();
              this.registroService.envioAnularModulo$.next(this.codigoModulo);
            }
          }
          else{
            this.isCheckedToogle = true;
            event.source.checked = true;

            event.source._inputElement.nativeElement.checked = true;
            event.source._inputElement.nativeElement.dispatchEvent(new Event('change'));
          }
        });

      }
      else {
        this.registroService.quitarTotalColaboradores$.next({ orden: this.orden, cantidad: this.cantidadIn, costo_total: this.costo_modulo_local });
        this.cantidadIn = 0;
        this.costo_modulo_local = 0;
        this.disabledModulo = true;
      }

    }
    else {
      if (event.checked && (this.cantidadIn === 0 || this.cantidadIn== undefined )) {
        if (this.cantidad_usuarios_x_defecto > 0 && this.orden != 1) {
          this.cantidadIn = this.cantidad_usuarios_x_defecto;
          let msj = Msjs.MENSAJE_CANTIDAD_USUARIOS_DEFECTO.replace('$', this.cantidad_usuarios_x_defecto.toString());
          this.uiService.ShowSnackBar(SnackBarType.INFO, msj, 7000);
          this.costo_modulo_local = Number(this.costo_modulo.toFixed(2));
          let montos = this.calcularCostoModulo(
            this.orden,
            this.cantidadIn,
            this.cantidad_usuarios_x_defecto,
            this.costo_usuario_adicional,
            this.costo_modulo,
            this.nombre,
            false
          );
          this.disabledModulo=!this.disabledModulo;
          this.registroService.envioCantidad$.next(montos);
        }
      }
    }
  }
}
