import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Icons } from 'src/app/shared/cons/icons';
import { CONFIGURACION, PATH_API_FILES } from '../../../cons/config';
import dataUbigeo from '../../../cons/ubigeo-inei.json';
import dataDepartamento from '../../../cons/departamento-inei.json';
import * as Enumerable from 'linq-es2015';
import {
  Msjs_Validations,
  PatternsRegex,
  Length_Database,
  Msjs,
  EstadoRegistro,
} from '../../../cons/common';
import { UsuarioBLService } from '../../services/services.bl/usuario.bl.service';
import { FileTypes } from 'src/app/shared/types/file-types';
import { UploadService } from '../../upload/upload.service';
import { Subject, Subscription } from 'rxjs';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { UIService } from 'src/app/shared/services/ui.services';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { Utils } from 'src/app/shared/helpers/utils';
import { IUsuario } from '../../dialog/models/usuario.model';
import { CUsuario } from '../../dialog/models/cusuario.model';
import { AutoUnsubscribe } from "../../../helpers/decorators/AutoUnsubscribe";
import { AuthService } from 'src/app/shared/auth/auth.service';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss'],
})
@AutoUnsubscribe

export class UsuarioComponent implements OnInit {
  maxDate = new Date();
  minDate = new Date();
  readonly Icons: typeof Icons = Icons;
  lRoles_primarcas: any[] = CONFIGURACION.ROLES_PRIMARCAS;
  lSexo: any[] = CONFIGURACION.SEXO;
  lTipo_documento: any[] = CONFIGURACION.TIPO_DOCUMENTO;
  lDepartamento: any[] = new Array<any>();
  lProvincia: any[] = new Array<any>();
  lDistrito: any[] = new Array<any>();
  lUbigeo: any[] = new Array<any>();
  pathFileAPI = PATH_API_FILES.USER_IMAGE_URL;
  fileType:string = FileTypes.IMAGE;
  label:string='Foto :';
  onlyNumbers = Utils.InputOnlyNumbers;

  cusuario = new CUsuario();

  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;

  files_upload_subs!:Subscription;
  files_upload:File[]=[];
  //form
  disabled_controls:boolean=false;
  sbReloadUser!:Subscription;
  sbModalQuest!:Subscription;
  esModoEdicion:boolean = false;
  files_uploaded:any[]=[];
  sizeMaxHeightWidth:string='alto 200px - ancho 200px'
  userAuthSubs!:Subscription;

  constructor(
    private usuarioBlService: UsuarioBLService,
    private uploadService:UploadService,
    private readonly spinnerPageService: SpinnerPageService,
    private uiService:UIService,
    private registroService: RegistroService,
    private authService: AuthService

    ) {}

  ngOnInit(): void {
    this.maxDate = new Date();
    this.minDate = new Date();
    this.minDate.setFullYear(1900);
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
    dataDepartamento.forEach((dep) => {
      this.lDepartamento.push(dep);
    });
    dataUbigeo.forEach((ubigeo) => {
      this.lUbigeo.push(ubigeo);
    });

    //retorna del componente file upload el numero de archivos
    this.files_upload_subs = this.uploadService.files_upload$.subscribe((uploads)=>{
      this.files_upload = uploads.images_upload;
      if(this.esModoEdicion){
        // para lista de imagenes existentes modificadas
        this.files_uploaded = uploads.images_uploaded;
      }
    });

   this.cargarDatosSesion();

    //cuando es modo edicion
    if((this.registroService.estado== EstadoRegistro.REGISTRO_COMPLETO_ADMIN) ||
      (this.registroService.estado== EstadoRegistro.REGISTRO_COMPLETO_USUARIO)
    ){
      this.esModoEdicion=true;
      this.cargarDatosUsuario();
    }
    else{
      this.esModoEdicion = false;

    }
    //cuando actualiza la sesion
    this.userAuthSubs = this.authService.userAuth$.subscribe(()=>{
      this.cargarDatosSesion();
      this.esModoEdicion = false;

    });


  }

  cargarDatosSesion(){
    this.cusuario.tipo_usuario= this.registroService.esRegistroAdministrador;

    if(this.registroService.esRegistroAdministrador== this.lRoles_primarcas[0].value){
      this.cusuario.tipo_usuario= this.lRoles_primarcas[0].value;
    }

  }

  cargarDatosUsuario(){
    this.cusuario.administrador = this.registroService.usuario.administrador;
    this.cusuario.apellido_materno = this.registroService.usuario.apellido_materno;
    this.cusuario.apellido_paterno = this.registroService.usuario.apellido_paterno;
    this.cusuario.codigo_departamento= this.registroService.usuario.codigo_departamento;
    this.departamentoSelect(this.registroService.usuario.codigo_departamento);
    this.provinciaSelect(this.registroService.usuario.codigo_provincia)
    this.cusuario.codigo_provincia= this.registroService.usuario.codigo_provincia;
    this.cusuario.codigo_distrito= this.registroService.usuario.codigo_distrito;
    this.cusuario.nombres = this.registroService.usuario.nombres;
    this.cusuario.fecha_nacimiento = this.registroService.usuario.fecha_nacimiento;
    this.cusuario.sexo = this.registroService.usuario.sexo;
    this.cusuario.telefono = this.registroService.usuario.telefono;
    this.cusuario.tipo_documento = this.registroService.usuario.tipo_documento;
    this.cusuario.numero_documento = this.registroService.usuario.numero_documento;
    this.cusuario.direccion = this.registroService.usuario.direccion;
    this.uploadService.removeAllFiles();
    this.files_uploaded = this.registroService.usuario.imagen==undefined?[]:Utils.ObjecToListToArray(this.registroService.usuario.imagen);


  }

  onSubmit(f: NgForm) {
      if(f.valid){
        if(this.cusuario.fecha_nacimiento=='' || this.cusuario.fecha_nacimiento== undefined){
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs_Validations.MSJ_VAL_FECHA_MINIMO_INCORRECTA, 3000);          
          return;
        }
        this.sbModalQuest = this.uiService.showModalQuestions({title:Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon:Icons.QUESTION_MARK}).subscribe(result=>{
          if(result){
            this.spinnerPageService.show();
            if(!this.esModoEdicion){
              //cuando es nuevo   
              this.usuarioBlService.insertNuevoUsuario(f, this.files_upload, this.pathFileAPI, this.cusuario ).then(res=>{
                if(res.id){
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                  this.disabled_controls=true;
                  this.registroService.estado = EstadoRegistro.REGISTRO_EMPRESA;
                  this.registroService.nextStep$.next(EstadoRegistro.REGISTRO_EMPRESA)//estadoEmpresa
                  //01.necesario en caso de que se inscriba de una (para empresa y modulo)
                  this.registroService.usuario.id = res.id;
                }else{
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);
                }
              });
            }
            else{
              //cuando es edicion
              this.usuarioBlService.updateUsuario( f, this.files_upload, this.files_uploaded, this.pathFileAPI, this.cusuario).then(()=>{
                this.uiService.closeModalGestionarUsuario();
                this.disabled_controls=true;
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.spinnerPageService.hide();

              }).catch(error=>{
                this.uiService.closeModalGestionarUsuario();
                this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);
                this.disabled_controls=true;
                this.spinnerPageService.hide();
                console.error(error);
              });
            }
          }
        });
    }
  }

  isDateMaxError(){
    const selectedDate = new Date(this.cusuario.fecha_nacimiento);
    return selectedDate > this.maxDate?true:false;
  }

  isDateMinError(){
    const selectedDate = new Date(this.cusuario.fecha_nacimiento);
    return selectedDate < this.minDate?true:false;
  }

  departamentoSelect(codigoDepartamento: any) {
    this.cusuario.codigo_provincia = '';
    this.cusuario.codigo_distrito = '';
    this.lProvincia = [];
    this.lDistrito = [];
    codigoDepartamento = codigoDepartamento.substr(0, 2);
    this.lProvincia = Enumerable.asEnumerable(this.lUbigeo)
      .Where((prov) => prov.codDepartamento == codigoDepartamento)
      .Distinct((x) => x.codProvincia)
      .Select((x) => x)
      .ToArray();
  }

  provinciaSelect(codigo: any) {
    this.cusuario.codigo_distrito='';
    this.lDistrito = [];
    let codigoDepartamento = this.cusuario.codigo_departamento.substr(0, 2);
    this.lDistrito = Enumerable.asEnumerable(this.lUbigeo)
      .Where(
        (prov) =>
          prov.codProvincia == codigo &&
          prov.codDepartamento == codigoDepartamento
      )
      .Distinct((x) => x.codDistrito)
      .Select((x) => x)
      .ToArray();
  }

  Limpiar(f:any){
    let tipo_usuario =this.cusuario.tipo_usuario; //para no perder el tipo de usuario cuando se limpia
    this.cusuario = new CUsuario();
    this.cusuario.tipo_usuario = tipo_usuario;
    if(this.files_upload.length>0){
      //comunica que debe quitarse todos los archivos
      this.uploadService.removeAllFiles();
    }
  }
}
