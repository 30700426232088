import { Injectable } from '@angular/core';
import { MensajeDaService } from './mensaje.da.service';
import { RegistroService } from 'src/app/shared/services/registro.service';

@Injectable({
  providedIn: 'root'
})
export class MensajeBlService {

  constructor(
    private mensajeDaService: MensajeDaService,
    private registroService: RegistroService

  ) { }

  getMensaje(){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.mensajeDaService.getMensaje(idEmpresa);
  }

  getMensajeCache(){
    const idEmpresa = this.registroService.empresa.id;
    return this.mensajeDaService.mensaje$;
  }
  fetchMensajeByEmpresa(){
    const idEmpresa = this.registroService.empresa.id;
    this.mensajeDaService.fetchMensaje(idEmpresa);
  }
}
