import { NgModule, LOCALE_ID } from '@angular/core';
import '@angular/common/locales/es-419';

import { registerLocaleData } from '@angular/common';
import localeEs419 from '@angular/common/locales/es-419';

registerLocaleData(localeEs419);

import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule} from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { SharedModule } from "./shared/shared.module"
import { HammerModule } from '@angular/platform-browser';
import "firebase/compat/auth"
import "firebase/compat/firestore";
import { QRCodeModule } from 'angularx-qrcode';

import { CustomDateAdapter, MY_DATE_FORMATS } from '../app/shared/types/my-date-formats';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { PlatformModule } from '@angular/cdk/platform';


@NgModule({
  declarations: [
    AppComponent,   

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebase),
    SharedModule,
    FlexLayoutModule,
    HammerModule, 
    QRCodeModule
  ],
  providers: [
    AngularFireAuth,
    { provide: LOCALE_ID, useValue: 'es-419' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-PE' } // Ajusta esto según tus necesidades de localización
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
