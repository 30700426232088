import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IHorario } from './horario.model';

@Injectable({
  providedIn: 'root'
})
export class HorarioService {
  onSaveHorario$ = new Subject<IHorario>();
  hasErrorForm = new Subject<boolean>();// indica que como formulario hijo tiene o no errores
  constructor() { }
}
