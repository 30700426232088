import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Length_Database, Msjs_Validations, PatternsRegex, FormsConst, Msjs, MsjFirebase } from 'src/app/shared/cons/common';
import {Icons} from '../../../../shared/cons/icons';
import dataUbigeo from '../../../cons/ubigeo-inei.json';
import dataDepartamento from '../../../cons/departamento-inei.json'
import * as Enumerable from 'linq-es2015';
import { Departamento } from 'src/app/shared/models/departamento.model';
import { Ubigeo } from 'src/app/shared/models/ubigeo.model';
import { NgForm, Validators } from '@angular/forms';
import { UIService } from 'src/app/shared/services/ui.services';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { IUsuario } from '../models/usuario.model';
import { StateAuth } from 'src/app/shared/models/state-auth.model';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  maxDate = new Date();
  minDate = new Date();

  CLEAN:string = Icons.CLEANING;
  CANCEL:string = Icons.CANCEL;
  CLOSE:string = Icons.CLOSE;

  readonly Msjs_Validations:typeof Msjs_Validations = Msjs_Validations;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly TIPO_DOCUMENTO: string[] = FormsConst.TIPO_DOCUMENTO;
  readonly SEXO: string[] = FormsConst.SEXO;

  lDepartamento: Departamento[] = new Array<Departamento>();
  lProvincia: Ubigeo[] = new Array<Ubigeo>();
  lDistrito: Ubigeo[]= new Array<Ubigeo>();
  lUbigeo:Ubigeo[] = new Array<Ubigeo>();

  tipoDocumento:string=FormsConst.TIPO_DOCUMENTO[0];//valor por defecto DNI

  //se usa viewChild como instanciacion del formulario f que se crea en la vista
  //sino, no se podria usar las propiedades comunes de un formulario instanciado.
  @ViewChild('f') form!:NgForm;

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private _adapter:DateAdapter<any>,
    private uiService:UIService,
    private functionService:FunctionsService,
    )
    {
      dataDepartamento.forEach((dep:any)=>{
        let departamento = dep as Departamento;
        this.lDepartamento.push(departamento);
      });
      dataUbigeo.forEach((ubigeo:any)=>{
        let ubigeox = ubigeo as Ubigeo;
        this.lUbigeo.push(ubigeox);
      });
    }

  ngOnInit(){
    this._adapter.setLocale('sp')
    this.maxDate = new Date();
    this.minDate = new Date();
    this.minDate.setFullYear(1900);
    this.maxDate.setFullYear(this.maxDate.getFullYear()-18);
   }

  selectProvincia(codigoDepartamento:string, form:NgForm){
    form.controls.codigo_provincia.setValue('');
    form.controls.codigo_distrito.setValue('');
    this.lProvincia = [];
    this.lDistrito = [];
    this.lProvincia = Enumerable.asEnumerable(this.lUbigeo)
      .Where(prov=>prov.codDepartamento==codigoDepartamento)
      .Distinct(x=>x.codProvincia).Select(x=> x).ToArray();
   }

  selectDistrito(codigoProvincia:string, form:NgForm){
    form.controls.codigo_distrito.setValue('');
    this.lDistrito = [];
    let codigoDepartamento:string = form.value.codigo_departamento;
    this.lDistrito = Enumerable.asEnumerable(this.lUbigeo)
      .Where(prov=>prov.codProvincia==codigoProvincia.substring(0,2) && prov.codDepartamento==codigoDepartamento)
      .Distinct(x=>x.codDistrito).Select(x=> x).ToArray();
  }

  selectTipoDocumento(tipoDocumento:string){
    this.tipoDocumento = tipoDocumento
  }

  onSubmit(form:NgForm){
    if(form.valid){
      if(form.value.password!=form.value.password2){
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_PASSWORD_DISTITNTO_ERROR );
      }
      else{
        return;
      }
    }
  }
}
