import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { CaptchaCodigoComponent } from "../components/dialog/captcha-codigo/captcha-codigo.component";
import { CaptchaComponent } from "../components/dialog/captcha/captcha.component";
import { LoginComponent } from "../components/dialog/login/login.component";
import { SignupComponent } from "../components/dialog/signup/signup.component";
import { TerminosCondicionesComponent } from "../components/dialog/terminos-condiciones/terminos-condiciones.component";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarRef, MatSnackBarVerticalPosition } from "@angular/material/snack-bar"

import { ItemSidenav } from "../models/item.model";
import { SnackBarType } from "../types/snackbar-type"
import { ErrorSnackBarComponent } from "../components/snackbar/error-snackbar.component";
import { InfoSnackBarComponent } from "../components/snackbar/info-snackbar.component";
import { SuccessSnackBarComponent } from "../components/snackbar/success-snackbar.component";
import { WarningSnackBarComponent } from "../components/snackbar/warning-snackbar.component";
import { QuestionsComponent } from "../components/dialog/questions/questions.component";
import { CostosFacturacionComponent } from '../components/dialog/costos-facturacion/costos-facturacion.component'
import { TerminosPrivacidadComponent } from '../components/dialog/terminos-privacidad/terminos-privacidad.component';
import { GestionUsuarioComponent  } from '../components/dialog/gestion-usuario/gestion-usuario.component';
import { AvisoComponent } from "../components/dialog/aviso/aviso.component";
import { AvisoxSnackBarComponent } from "../components/snackbar/avisox-snackbar.component";

import { DialogGestionRolComponent } from "../components/procesos/gestion-rol/dialog-gestion-rol/dialog-gestion-rol.component";
import { GestionColaboradorComponent } from "../components/procesos/gestion-colaborador/gestion-colaborador.component";
import { VerificarPasswordComponent } from "../components/dialog/verificar-password/verificar-password.component";
import { DialogCatalogoComponent } from "../components/procesos/dialog/dialog-catalogo/dialog-catalogo.component";
import { DialogProductoComponent } from "../components/procesos/dialog/dialog-producto/dialog-producto.component";
import { VerImagenesComponent } from '../../shared/components/dialog/ver-imagenes/ver-imagenes.component';
import { DialogBuscarProductoComponent } from '../../shared/components/procesos/dialog/dialog-buscar-producto/dialog-buscar-producto.component';
import { GestionMontoComponent } from "../components/gestion-monto/gestion-monto.component";
import { HistorialComponent } from "../components/historial/historial.component";
import { CantidadComponent } from "../components/registrar-pedido/dialog/cantidad/cantidad.component";
import { BuscarProductoIntoComponent } from "../components/registrar-pedido/dialog/buscar-producto-into/buscar-producto-into.component";
import { BuscarCatalogoIntoComponent } from "../components/registrar-pedido/dialog/buscar-catalogo-into/buscar-catalogo-into.component";
import { Pedido } from "../components/registrar-pedido/models/Pedido";
import { ListarAmbienteMesaDialogComponent } from "../components/listar-ambiente-mesa-dialog/listar-ambiente-mesa-dialog.component";
import { ISendPedidoRegistro } from "./models/ISendPedidoRegistro";
import { DialogCaractProductoComponent } from "../components/procesos/dialog/dialog-caract-producto/dialog-caract-producto.component";
import { ListarCaracteristicasIntoComponent } from "../components/registrar-pedido/dialog/listar-caracteristicas-into/listar-caracteristicas-into.component";
import { ListarProductoComboComponent } from "../components/listar-producto-combo/listar-producto-combo.component";
import { VerPedidoComponent } from "../components/ver-pedido/ver-pedido.component";
import { CajaCobroComponent } from "../components/dialog/caja-cobro/caja-cobro.component";
import { GestionPagoParcialComponent } from "../components/gestion-pago-parcial/gestion-pago-parcial.component";
import { GestionDescuentoComponent } from "../components/dialog/gestion-descuento/gestion-descuento.component";
import { GestionIncidenciaComponent } from "../components/dialog/gestion-incidencia/gestion-incidencia.component";
import { ProductoCompuestoComponent } from "../components/dialog/producto-compuesto/producto-compuesto.component";
import { TraspasoProductosComponent } from "../components/dialog/traspaso-productos/traspaso-productos.component";
import { ProductoSimpleComponent } from "../components/dialog/producto-simple/producto-simple.component";
import { ListarAmbienteMesaOcupadoDialogComponent } from "../components/listar-ambiente-mesa-ocupado-dialog/listar-ambiente-mesa-ocupado-dialog.component";
import { Catalogo } from "../components/procesos/dialog/models/Catalogo";
import { EmisionComprobanteElectronicoComponent } from "../components/procesos/emision-comprobante-electronico/emision-comprobante-electronico.component";

@Injectable()
export class UIService{

  loadingStateChanged = new Subject<boolean>();
  selectedRoute = new Subject<ItemSidenav>();
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  snackAvisoXRef!:MatSnackBarRef<any>;

  /*propiedadExpandirMenuEmpresa*/
  isExpandedGEmpresa$ = new Subject<boolean>();
  reloadEmpresa$ = new Subject();
  isExpandedGModulos$ = new Subject<boolean>();
  reloadModulos$ = new Subject();
  //
  isExpandedAmbienteMesas$ = new Subject<boolean>();
  reloadAmbienteMesas$ = new Subject();
  isExpandedRoles$ = new Subject<boolean>();
  reloadRoles$ = new Subject();
  isExpandedTasas$ = new Subject<boolean>();
  reloadTasas$ = new Subject();
  reloadDscto$ = new Subject();
  isExpandedMediosPago$ = new Subject<boolean>();
  reloadMediosPago$ = new Subject();
  isExpandedModoOperacion$ = new Subject<boolean>();
  reloadModoOperacion$ = new Subject();
  isExpandedCartaDigital$ = new Subject<boolean>();
  reloadCartaDigital$ = new Subject();
  isExpandedComprobanteElectronico$ = new Subject<boolean>();
  reloadComprobanteElectronico$ = new Subject();
  

  showListarPedido = new Subject<Pedido |null>();
  showRegistrarPedido = new Subject<ISendPedidoRegistro>();
  showAgregarPedido = new Subject<ISendPedidoRegistro>();
  showCerrarPedido = new Subject<ISendPedidoRegistro>();
  showTrasladarProductos = new Subject<ISendPedidoRegistro>();
  printComanda$ = new Subject<string>();



  constructor(
    private dialogLogin:  MatDialog,
    private dialogSignup:  MatDialog,
    public dialogcaptcha:  MatDialog,
    private dialogcaptchaCodigo:  MatDialog,
    private snackbar: MatSnackBar,
    private dialogQuestions: MatDialog,
    private dialogTerminos: MatDialog,
    private dialogCostosFact: MatDialog,
    private dialogPrivacidad: MatDialog,
    private dialogGestionUsuario: MatDialog,
    private dialogAviso: MatDialog,
    private dialogGestionRolComponent:MatDialog,
    private gestionColaborador:MatDialog,
    private verificarPassword:MatDialog,
    private dialogCatalogo:MatDialog,
    private dialogProducto: MatDialog,
    private dialogVerImagenes:MatDialog,
    private dialogBuscarProductoAniadir:MatDialog,
    private dialogGestionMonto:MatDialog,
    private dialogHistorial:MatDialog,
    private dialogCantidad:MatDialog,
    private dialogBuscarProductoInto:MatDialog,
    private dialogBuscarCatalogoInto:MatDialog,
    private dialogListarAmbienteMesaD: MatDialog,
    private dialogCaractProducto:MatDialog,
    private dialogListarCaracteristicasInto:MatDialog,
    private dialogListarProdComboCaracteristica:MatDialog,
    private dialogVerPedido:MatDialog,
    private dialogCajaCobro:MatDialog,
    private dialogGestionPagoParcial:MatDialog,
    private dialogGestionarDescuento:MatDialog,
    private dialogGestionarIncidencia:MatDialog,
    private dialogProductoCompuesto:MatDialog,
    private dialogTraspasoProducto:MatDialog,
    private dialogProductoSimple:MatDialog,
    private dialogMesaOcupada:MatDialog,
    private dialogEmitirComprobanteElectronico:MatDialog
  ){}

  showDialogEmitirComprobante(dato:any){
    const dialog = this.dialogEmitirComprobanteElectronico.open(EmisionComprobanteElectronicoComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialog;
  }
  closeDialogEmitirComprobante(dato:any){
    this.dialogEmitirComprobanteElectronico.closeAll();
  }

  
  showDialogMesaOcupada(dato:any){
    const dialog = this.dialogMesaOcupada.open(ListarAmbienteMesaOcupadoDialogComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialog;
  }

  closeDialogMesaOcupada(dato:any){
    this.dialogMesaOcupada.closeAll();
  }


  showDialogProductoSimple(dato:any){
    const dialog = this.dialogProductoSimple.open(ProductoSimpleComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialog;
  }

  closeDialogProductoSimple(dato:any){
    this.dialogProductoSimple.closeAll();
  }

  showDialogTraspasoProductos(dato:any){
    const dialog = this.dialogTraspasoProducto.open(TraspasoProductosComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialog;
  }

  closeDialogTraspasoProductos(dato:any){
    this.dialogTraspasoProducto.closeAll();
  }

  showDialogProductoCompuesto(dato:any){
    const dialgoProductoCompuesto = this.dialogProductoCompuesto.open(ProductoCompuestoComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialgoProductoCompuesto;
  }

  closeDialogProductoCompuesto(dato:any){
    this.dialogProductoCompuesto.closeAll();
  }

  showDialogGestionIncidencia(dato:any){
    const dialogGestionDscto = this.dialogGestionarIncidencia.open(GestionIncidenciaComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialogGestionDscto;
  }

  closeDialogGestionIncidencia(dato:any){
    this.dialogGestionarIncidencia.closeAll();
  }


  showDialogGestionDescuento(dato:any){
    const dialogGestionDscto = this.dialogGestionarDescuento.open(GestionDescuentoComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialogGestionDscto;
  }

  closeDialogGestionDescuento(dato:any){
    this.dialogGestionarDescuento.closeAll();
  }


  showDialogGestionPagoParcial(dato:any){
    const dialogGestionPagoParcial = this.dialogGestionPagoParcial.open(GestionPagoParcialComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialogGestionPagoParcial;
  }

  closeDialogGestionPagoParcial(dato:any){
    this.dialogGestionPagoParcial.closeAll();
  }

  showDialogCajaCobro(dato:any){
    const dialogCajaCobro = this.dialogCajaCobro.open(CajaCobroComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialogCajaCobro;
  }

  closeDialogCajaCobro(dato:any){
    this.dialogCajaCobro.closeAll();
  }


  showDialoVerPedido(dato:any){
    const dialogVerPedido = this.dialogVerPedido.open(VerPedidoComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialogVerPedido;
  }

  closeDialoVerPedido(dato:any){
    this.dialogVerPedido.closeAll();
  }

  showDialoListarProdComboCaract(dato:any){
    const dialogCaractProductoInto = this.dialogListarProdComboCaracteristica.open(ListarProductoComboComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialogCaractProductoInto;
  }

  closeDialoListarProdComboCaract(dato:any){
    this.dialogListarProdComboCaracteristica.closeAll();
  }


  showDialogCaractProductoListaInto(dato:any){
    const dialogCaractProductoInto = this.dialogListarCaracteristicasInto.open(ListarCaracteristicasIntoComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialogCaractProductoInto;
  }

  closeDialogCaractProductoListaInto(dato:any){
    this.dialogListarCaracteristicasInto.closeAll();
  }


  showDialogCaractProducto(dato:any){
    const dialogCaractProducto = this.dialogCaractProducto.open(DialogCaractProductoComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialogCaractProducto;
  }

  closeDialogCaractProducto(dato:any){
    this.dialogCaractProducto.closeAll();
  }


  showModalListarAmbienteMesaD(dato:any){
    const dialogListarAmbienteMesa = this.dialogListarAmbienteMesaD.open(ListarAmbienteMesaDialogComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialogListarAmbienteMesa;
  }

  closeModalListarAmbienteMesaD(){
    this.dialogListarAmbienteMesaD.closeAll();
  }

  
  showModalBuscarCatalogoInto(dato:any){
    const dialogBuscarIntoCata = this.dialogBuscarCatalogoInto.open(BuscarCatalogoIntoComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialogBuscarIntoCata;
  }

  closeModalBuscarCatalogoInto(){
    const dialogBuscarCataInto = this.dialogBuscarCatalogoInto.closeAll();
  }


  showModalBuscarProductoInto(dato:any){
    const dialogBuscarInto = this.dialogBuscarProductoInto.open(BuscarProductoIntoComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialogBuscarInto;
  }

  closeModalBuscarProductoInto(){
    const dialogBuscarInto = this.dialogBuscarProductoInto.closeAll();
  }

  showModalCantidad(dato:any){
    const dialogCantidad = this.dialogCantidad.open(CantidadComponent,{data:dato, autoFocus:false, disableClose:true});
    return dialogCantidad;
  }

  closeModalCantidad(){
    const dialogCantidad = this.dialogCantidad.closeAll();
  }

  showModalHistorial(dato:any){
    const dialogHistorial = this.dialogHistorial.open(HistorialComponent,{data:dato, autoFocus:false, disableClose:false}).afterClosed();
    return dialogHistorial;
  }
  closeModalHistorial(){
    const dialogHistorial = this.dialogHistorial.closeAll();
  }

  showModalGestionMonto(dato:any){
    const dialogMonto = this.dialogGestionMonto.open(GestionMontoComponent,{data:dato, autoFocus:false, disableClose:true}).afterClosed();
    return dialogMonto;
  }
  closeModalGestionMonto(){
    const dialogMonto = this.dialogGestionMonto.closeAll();
  }

  showDialogBuscarAniadirProducto(dato:any){
    const dialogBuscarAniadir = this.dialogBuscarProductoAniadir.open(DialogBuscarProductoComponent,{data:dato, autoFocus:false}).afterClosed();
    return dialogBuscarAniadir;
  }

  closeDialogBuscarAniadirProducto():void{
    const dialogBuscarAniadir = this.dialogBuscarProductoAniadir.closeAll();
  }

  showDialogVerImagenes(dato:any){
    const dialogVerImagenes = this.dialogVerImagenes.open(VerImagenesComponent, {data:dato, autoFocus:false}).afterClosed();
    return dialogVerImagenes;

  }

  closeDialogVerImagenes():void{
    const dialogCarousel = this.dialogVerImagenes.closeAll();
  }

  showDialogProducto(dato:any){
    const dialogProducto = this.dialogProducto.open(DialogProductoComponent, {data:dato, disableClose:true});
    return dialogProducto;
    }

  closeDialogProducto():void{
    const dialogProducto = this.dialogProducto.closeAll();
  }


  showDialogCatalogo(dato:any): MatDialogRef<DialogCatalogoComponent, any>{
    const dialogCatalogo = this.dialogCatalogo.open(DialogCatalogoComponent, {data:dato, disableClose:true});
    return dialogCatalogo;
  }

  closeDialogCatalogo():void{
    const dialogCatalogo = this.dialogCatalogo.closeAll();
  }

  showModalVerificarPasswordAdmin(dato:any){
    const dialogVerificarPassword = this.verificarPassword.open(VerificarPasswordComponent, {data:dato, disableClose:true}).afterClosed();
      return dialogVerificarPassword;
  }
  closeodalVerificarContraseñaAdmin():void{
    const dialogVerificarPassword= this.verificarPassword.closeAll();
  }


    //region Modal
  showModalGestionColaborador(dato:any){
      const dialogGestionColaborador = this.gestionColaborador.open(GestionColaboradorComponent, {data:dato, disableClose:true});
      return dialogGestionColaborador;
  }
  closeModalGestionColaborador():void{
    const dialogGestionColaborador= this.gestionColaborador.closeAll();
  }

 showModalGestionRol(dato:any){
      const dialogGestionRol = this.dialogGestionRolComponent.open(DialogGestionRolComponent, {data:dato, disableClose:true}).afterClosed();
      return dialogGestionRol;
  }

  closeModalGestionRol():void{
    const dialogGestionRol= this.dialogGestionRolComponent.closeAll();
  }

  showModalAviso(dato:any){
      const dialogAviso = this.dialogAviso.open(AvisoComponent, {data:dato, disableClose:true}).afterClosed();
      return dialogAviso;
  }

  closeModalAviso():void{
    const dialogAviso= this.dialogAviso.closeAll();
  }


  showModalGestionarUsuario(dato:any){
      const dialogGestionar = this.dialogGestionUsuario.open(GestionUsuarioComponent, {data:dato, disableClose:true}).afterClosed();
      return dialogGestionar;
  }

  closeModalGestionarUsuario():void{
    const dialogGestionUsuario= this.dialogGestionUsuario.closeAll();
  }


  showModalPrivacidad(dato:any){
      const dialogPrivacidad = this.dialogPrivacidad.open(TerminosPrivacidadComponent, {data:dato, disableClose:true}).afterClosed();
      return dialogPrivacidad;
  }

  closeModalPrivacidad():void{
    const dialogPrivacidad= this.dialogPrivacidad.closeAll();
  }

  showModalCostos(dato:any){
      const dialogTerminos = this.dialogCostosFact.open(CostosFacturacionComponent, {data:dato, disableClose:true}).afterClosed();
      return dialogTerminos;
  }
  closeModalCostos():void{
    const dialogCostosFact= this.dialogCostosFact.closeAll();
  }

  showModalTerminos(dato:any){
    const dialogTerminos = this.dialogTerminos.open(TerminosCondicionesComponent, {data:dato, disableClose:true}).afterClosed();
    return dialogTerminos;
  }
  closeModalTerminos():void{
    const dialogTerminos= this.dialogTerminos.closeAll();
  }

  showModalQuestions(dato:any){
    const dialogQuestions = this.dialogQuestions.open(QuestionsComponent, {data:dato, disableClose:true}).afterClosed();
    return dialogQuestions;
  }
  closeModalQuestions(){
    const dialogQuestions = this.dialogQuestions.closeAll();

  }

  showModalSignup():void{
    const dialogSignup = this.dialogSignup.open(SignupComponent, {data:{texto:'hola mundo'}, disableClose:true});
  }

  showModalLogin(data:any):void{
    const dialogLogin = this.dialogLogin.open(LoginComponent, {data:data, disableClose:true} );
  }
  CloseModalLogin():void{
    const dialogCaptcha= this.dialogLogin.closeAll();
  }

  ShowModalCaptcha():void{
    const dialogCaptcha= this.dialogcaptcha.open(CaptchaComponent, {data:{texto:'hola mundo'}, disableClose:true});
  }

  CloseModalCaptcha():void{
    const dialogCaptcha= this.dialogcaptcha.closeAll();
  }

  ShowModalCaptchaCodigo(data:any):void{
    const dialogCaptchaCodigo = this.dialogcaptchaCodigo.open(CaptchaCodigoComponent, {data:data,disableClose:true});
  }
  CloseModalCaptchaCodigo():void{
    const dialogCaptcha= this.dialogcaptchaCodigo.closeAll();
  }

  CloseSnackBarAvisoX():void{
    this.snackAvisoXRef.dismiss();
  }


  //end region

  ShowSnackBar(type:string, data:any, duration:number=5000 ){
    switch (type){
      case SnackBarType.ERROR:{
        this.snackbar.openFromComponent(ErrorSnackBarComponent,{
          duration:duration,
          horizontalPosition:this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          data:data
        });
        break;
      }
      case SnackBarType.INFO:{
        this.snackbar.openFromComponent(InfoSnackBarComponent,{
          duration:duration,
          horizontalPosition:this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          data:data,
        });
        break;
      }
      case SnackBarType.SUCCESS:{
        this.snackbar.openFromComponent(SuccessSnackBarComponent,{
          duration:duration,
          horizontalPosition:this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          data:data,
          panelClass:['color-snackbar-success']
        });
        break;
      }
      case SnackBarType.WARNING:{
        this.snackbar.openFromComponent(WarningSnackBarComponent,{
          duration:duration,
          horizontalPosition:this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          data:data
        });
        break;
      }
      case SnackBarType.AVISO:{
        this.snackAvisoXRef =
        this.snackbar.openFromComponent(AvisoxSnackBarComponent,{
          horizontalPosition:this.horizontalPosition,
          verticalPosition: 'bottom',
          data:data
        });
        break;
      }
      default:{

      }
    }
  }
}

