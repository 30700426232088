import { SolicitarCambioType } from "src/app/shared/types/solicitar-cambio-type";
import { Fecha } from "./Fecha";
import { ProcesoType } from "src/app/shared/types/proceso-type";

export class CorreccionEfectivo{
  monto_actual:number=0;
  monto_anterior:number=0;
  fecha_key:Fecha = new Fecha();
  usuario_creacion:string='';
  usuario_aprobacion?:string;
  estado_cambio:SolicitarCambioType= SolicitarCambioType.SOLICITADO;
  proceso: ProcesoType = ProcesoType.INICIALIZAR;
  anotacion?:string;
  es_vigente:boolean=false
  es_impreso:boolean=false;
  //solicitud_impresion?:SolicitarCambioType = SolicitarCambioType.SOLICITADO;
}
