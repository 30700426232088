import { IBuscar } from "./IBuscar";

export class CBuscar implements IBuscar{
  lista_criterio: string[] =[];
  valor_criterio: string=''
  texto_criterio:string='';
  criterio_seleccionado: string=''
  lista_estado: string[] =[];
  texto_estado:string='';
  estado_seleccionado: string='';
  key:string='';

}
