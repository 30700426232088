<div style="max-width: 300px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div mat-dialog-title class="dialog-title">Producto:&nbsp;                     
            {{producto.nombre_producto}}
        </div>        
        <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"></app-button>
    </div>
    <mat-divider></mat-divider>
    <span>Seleccione la cantidad:</span>
    <div *ngIf="tipo_pago==TipoOperacionProductoCompuesto.TRASPASO"
    style="margin-top: 5px;margin-bottom: 5px;">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <mat-form-field style="min-width:250px">
            <mat-label>Cantidad </mat-label>
            <mat-select [(value)]="cantidadSel" required>         
              <mat-option *ngFor="let item of listaCantidadProducto" [value]="item">{{item}}</mat-option>          
            </mat-select>  
        </mat-form-field>
    </div>    
</div>
</div>