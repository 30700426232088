import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MediosAceptados } from '../models/MediosAceptados';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { Auditoria, colections, documentsEmpresa } from 'src/app/shared/cons/db.colections';
import { Utils } from 'src/app/shared/helpers/utils';
import { serverTimestamp } from 'firebase/firestore';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediosPagoDaService {
  transaccion: TransaccionModel = new TransaccionModel();

  private mediosCache: BehaviorSubject<MediosAceptados | undefined | null> = new BehaviorSubject<MediosAceptados | undefined |null>(undefined);
  public medios$: Observable<MediosAceptados | undefined |null> = this.mediosCache.asObservable();
  private fetchSb!: Subscription;

  constructor(
    private db: AngularFirestore,
  ) { }

  fetchMediosPago(idEmpresa:string,idUsuario:string): void {
    if(this.fetchSb && !this.fetchSb.closed ){
      this.mediosCache.next(this.mediosCache.value);
      return;
    }
    // if(this.fetchSb){
    //   this.fetchSb.unsubscribe();
    // }
    this.fetchSb = this.getConfiguracionMediosPago(idEmpresa, idUsuario).subscribe(tasas => {
      this.mediosCache.next(tasas);
    });
  }

  stopFetchingMedios(): void {
    if (this.fetchSb) {
      this.fetchSb.unsubscribe();
    }
    this.mediosCache.next(undefined);
  }

  getConfiguracionMediosPago(idEmpresa:string, idUsuario:string){
    return this.db.collection(colections.EMPRESA, (ref)=>
    ref.where(Auditoria.es_borrado,'==',false)
    .where(documentsEmpresa.ID_DOC_USUARIO,'==',idUsuario)
    ).doc(idEmpresa).collection(colections.CONFIGURACION_MEDIOS_PAGO, (ref1)=>
     ref1.where(Auditoria.es_borrado,'==',false).limit(1) )
     .snapshotChanges()
     .pipe(
      map(configuracion=>{
       if(configuracion.length>0){
        const config = (Utils.convertDate(configuracion[0].payload.doc.data())) as MediosAceptados;
         //const config = Utils.convertDate({...configuracion.docs[0].data()}) as MediosAceptados;
         config.id = configuracion[0].payload.doc.id;
         return config;
       }
       else{
         return null;
       }
     }));
  }

  insertMedios(medio:MediosAceptados, idEmpresa:string, idUsuario:string){
    const docMedios = Utils.SerializeJsonToDb(medio);
    docMedios.usuario_creacion = idUsuario;
    docMedios.fecha_creacion = serverTimestamp()
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docMediosRef = docEmpRef.collection(colections.CONFIGURACION_MEDIOS_PAGO);
    return docMediosRef.add(docMedios).then(result=>{
      this.transaccion.tx = true;
      this.transaccion.data = result.id
      return this.transaccion;
    }).catch((error)=>{
    this.transaccion.tx = false;
    this.transaccion.error = error;
    return this.transaccion;
  });
  }

  updateMedios(medios:MediosAceptados, idEmpresa:string, idUsuario:string,  idMedios:string){
    delete medios.id;
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docMediosRef = docEmpRef.collection(colections.CONFIGURACION_MEDIOS_PAGO);

    return docMediosRef.doc(idMedios).update({
      lista_medios_pago:medios.lista_medios_pago,
      fecha_modificacion: serverTimestamp(),
      usuario_modificacion: idUsuario
    }).then(result=>{
      this.transaccion.tx = true;
      this.transaccion.data = result;
      return this.transaccion;
    }).catch((error)=>{
      this.transaccion.tx = false;
      this.transaccion.error = error;
      return this.transaccion;
    });


  }

}
