<div style="max-width: 300px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div mat-dialog-title class="dialog-title">Producto:&nbsp;
            <span *ngIf="producto.estado_producto_vigente===EstadosProductoType.PENDIENTE_REVISION">
                {{producto.cantidad_producto - producto.cantidad_producto_reducido! }} &nbsp;
            </span>
            <span *ngIf="producto.estado_producto_vigente!==EstadosProductoType.PENDIENTE_REVISION">
                {{producto.cantidad_producto}} &nbsp;
            </span>            
            {{producto.nombre_producto}}
        </div>        
        <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"></app-button>
    </div>
    <div *ngIf="tipo_pago===TipoOperacionProductoCompuesto.DESCUENTO ||  tipo_pago===TipoOperacionProductoCompuesto.INCIDENCIA" mat-card-subtitle >Cantidad Pendiente N°:&nbsp;{{cantidadRestante}} </div>    
    <mat-divider></mat-divider>
    <div *ngIf="tipo_pago==TipoOperacionProductoCompuesto.DESCUENTO ||
            tipo_pago==TipoOperacionProductoCompuesto.INCIDENCIA ||
            tipo_pago==TipoOperacionProductoCompuesto.TRASPASO"
            style="margin-top: 5px;margin-bottom: 5px;"
            >
            <span>Seleccione producto(s):</span>
    </div>
    <div *ngIf="tipo_pago==TipoOperacionProductoCompuesto.TRASPASO" style="margin-top: 10px; margin-bottom: 10px;" >        
        <button mat-stroked-button matTooltip="Seleccionar todo" color="primary" (click)="seleccionarTodo()">
            <mat-icon class="mat-18">{{Icons.CHECK }}</mat-icon>
            <span fxShow.gt-md fxHide.lt-md style="margin-left: 5px;">Todo</span>
        </button>        
    </div>
    <div class="scrollable" style="max-height: 500px;" >
        <div *ngFor="let prod of this.caracteristicaMod; let i=index" >
            <!-- cabecera 1 -->
            <ng-container>
                <div class="grupo-container" 
                    *ngIf="prod.estado_producto_vigente!=EstadosProductoType.PENDIENTE_REVISION && 
                    tipo_pago===TipoOperacionProductoCompuesto.REGISTRAR">
                    <app-button color="gray" type="icon" [icon_fig]="Icons.DELETE"
                        (click)="removeProducto(prod.grupo_producto!)">
                    </app-button>            
                </div>
                <div class="grupo-container" 
                    *ngIf="
                    (tipo_pago===TipoOperacionProductoCompuesto.DESCUENTO || 
                    tipo_pago===TipoOperacionProductoCompuesto.INCIDENCIA ||
                    tipo_pago===TipoOperacionProductoCompuesto.TRASPASO ) &&
                    !esVerSeleccionado">
                    <mat-checkbox color="primary" (change)="seleccionarProdCompuesto($event.checked, i, prod.grupo_producto!)">
                        Seleccionar
                    </mat-checkbox>
                </div>
                <div class="grupo-container" 
                    *ngIf="
                    (tipo_pago===TipoOperacionProductoCompuesto.DESCUENTO || 
                    tipo_pago===TipoOperacionProductoCompuesto.INCIDENCIA) && 
                    esVerSeleccionado && 
                    prod.caracteristicas[0].es_seleccionado"
                    style="margin-top: 5px;  margin-left: -10px;">
                    <mat-chip-list>
                        <mat-chip  [@blink]="blinkState" class="chip-pendiente">
                            Seleccionado
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;" >
                    <span>{{producto.nombre_producto}}</span>
                    <span>Características:</span>
                </div>                
            </ng-container>

            <!-- cuerpo -->
            <div *ngFor="let caract of prod.caracteristicas; let j=index" >
                <div fxLayout="row" class="caracteristica">                
                    <mat-icon class="mat-18">{{Icons.ARROW_RIGHT}}</mat-icon>
                    <span>{{caract.cantidad}}&nbsp;{{caract.nombre_producto}}&nbsp;{{caract.caracteristica_seleccionada}}</span>                    
                </div>  
            </div>
            
            <div *ngIf="i<= caracteristicaMod.length-1" >
                <mat-divider></mat-divider>
            </div>
            <!-- ----------- -->                                   
            <ng-container 
                *ngIf="prod.estado_producto_vigente===EstadosProductoType.PENDIENTE_REVISION &&
                tipo_pago===TipoOperacionProductoCompuesto.REGISTRAR">
                <ng-container *ngFor="let item of caracteristicaMod; let i = index">
                    <mat-divider></mat-divider>
                    <div class="grupo-container-eliminado" style="margin-top: 10px; margin-bottom: 10px;">
                        <mat-chip-list>
                            <mat-chip [@blink]="blinkState"
                                class="chip-pendiente-revision">Eliminado&nbsp;({{producto.estado_producto_vigente.toString().replace('_','
                                ').toUpperCase()}})</mat-chip>
                        </mat-chip-list>
                    </div>
                    <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 10px;">
                        <span>{{producto.nombre_producto}}</span>
                        <span>Características:</span>
                    </div>
            
                    <ng-container *ngFor="let caract of item.caracteristicas">
                        <div fxLayout="row" class="caracteristica">
                            <mat-icon class="mat-18">{{Icons.ARROW_RIGHT}}</mat-icon>
                            <span>{{caract.cantidad}}&nbsp;{{caract.nombre_producto}}&nbsp;{{caract.caracteristica_seleccionada}}</span>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>

        </div>     
    </div>
</div>
