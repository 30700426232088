import { Component, HostBinding, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";

@Component({
  selector: 'app-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss']
})
export class CardInfoComponent implements OnInit {
  @Input() title: string = "";
  @Input() description: string = "";
  @Input() urlIcon: string = "";
  @Input() positionDescription = "";
  @Input() backgroundImage: any = "";
  @Input() icon_fig: string = "";

  @Input()
  @HostBinding("style.background")
  background: SafeStyle | undefined;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.backgroundImage.currentValue) {
      this.background = this.sanitizer.bypassSecurityTrustStyle(`url("${changes.backgroundImage.currentValue}") `);
    }
  }

}
