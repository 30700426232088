import { AuditoriaSet } from "src/app/shared/models/auditoria.models";
import { MediosPago } from "./MediosPago";

export class MediosAceptados implements AuditoriaSet{
  lista_medios_pago:MediosPago[]=[];
  es_borrado: boolean=false;
  es_vigente: boolean = false;
  fecha_creacion?: any;
  fecha_modificacion?: any;
  usuario_creacion?: any;
  usuario_modificacion?: any;
  //
  id?:any
}
