<div fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title class="dialog-title">{{titleModal}}</div>
  <app-button *ngIf="!isLoading" type="icon" color="gray" [icon_fig]="CLOSE" (click)="onClose()" [attr_disabled]="isLoading" ></app-button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <section *ngIf="!isLoading">
    <form fxLayout="column"#f="ngForm" (ngSubmit)="onSubmit(f)">
      <mat-form-field>
        <input required type="text" matInput placeholder="Nombre de Rol" name="nombre" ngModel #nombre="ngModel"
          [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30" [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
          [disabled]="disabled_controls" oninput="this.value = this.value.toUpperCase()" [(ngModel)]="cRol.rol"
          (blur)="comprobarValidez()"
          [pattern]="PatternsRegex.onlyGoodCharsWithNumbersAndSpace"
          class="input">
        <mat-error *ngIf="nombre.touched && nombre.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        <mat-error *ngIf="nombre.touched && nombre.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
        <mat-error *ngIf="nombre.touched && nombre.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
        <mat-error *ngIf="nombre.touched && nombre.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Accesos</mat-label>
        <mat-select (selectionChange)="ddlAcceso($event.value)" [(ngModel)]="accesos" name="ddlAcceso"
          #ddlAccesos="ngModel" required [disabled]="disabled_controls">
          <mat-option *ngFor="let acceso of rolAccesoMaster.acceso" [value]="acceso">{{acceso}}</mat-option>
        </mat-select>
        <mat-error *ngIf="ddlAccesos.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
      </mat-form-field>
      <mat-chip-list style="margin-top: 10px;" >
        <mat-chip *ngFor="let acceso of accesosSeleccionados" (select)="onSelectChip(acceso)" [selectable]="selectable"
          [removable]="removable" (removed)="onSelectChip(acceso)">{{acceso}}
          <button matChipRemove *ngIf="removable">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>
      <div class="actions-dialog" fxLayout="row" fxLayoutAlign="space-between end" fxFlex>
        <app-button *ngIf="this.datoPasado.operacion == SistOperation.NUEVO" [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="f.invalid" [attr_type]="'submit'"></app-button>
        <app-button *ngIf="this.datoPasado.operacion == SistOperation.EDITAR" [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="editarEsValido" [attr_type]="'submit'"></app-button>
        <app-button fxHide.xs type="basic" color="blueA200" [icon_fig]="Icons.CLEANING" (click)="Limpiar(f)"></app-button>
          <app-button fxShow.xs fxHide type="outline" color="blueA200-b0" [icon_fig]="Icons.CLEANING" (click)="Limpiar(f)"></app-button>
      </div>
    </form>
  </section>
  <section  *ngIf="isLoading">
    <app-spinner></app-spinner>
  </section>

</mat-dialog-content>
