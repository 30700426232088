import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AspectosGenerales } from './models/AspectosGenerales';
import { Length_Database, Msjs, Msjs_Validations, PatternsRegex, SistOperation } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';
import { Subscription } from 'rxjs';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { GestionAspectosBlService } from './services/services.bl/gestion-aspectos.bl.service';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { ConfiguracionGeneralBlService } from 'src/app/shared/services/configuracion-general-bl.service';
import { blinkAnimation } from 'src/app/shared/animations/animations';

@Component({
  selector: 'app-gestion-aspectos-generales',
  templateUrl: './gestion-aspectos-generales.component.html',
  styleUrls: ['./gestion-aspectos-generales.component.scss'],
  animations: [blinkAnimation]
})

@AutoUnsubscribe

export class GestionAspectosGeneralesComponent implements OnInit {
  isLoading:boolean = false;
  aspectosGenerales:AspectosGenerales = new AspectosGenerales();
  readonly Msjs: typeof Msjs = Msjs;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly Icons: typeof Icons = Icons;
  readonly SistOperation: typeof SistOperation = SistOperation;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  esEditar:boolean = false;
  modalSb= new Subscription();
  configSb = new Subscription();
  reloadTasasSb = new Subscription();
  existeConfig:boolean = false;
  idAspectos:string='';
  blinkState: string = 'inactive';

  constructor(
    private uiService:UIService,
    private readonly spinnerPageService: SpinnerPageService,
    private gestionAspectosBL: GestionAspectosBlService,
    private configGenService:ConfiguracionGeneralBlService,
    

  ) { }

  ngOnInit(): void {
    //animacion
    setInterval(() => {
      this.blinkState = this.blinkState === 'active' ? 'inactive' : 'active';
    }, 800);  // Cambia el estado cada 500ms

    this.reloadTasasSb = this.uiService.reloadTasas$.subscribe(() => {
      this.cargarConfiguracion();

    });
  }

  cargarConfiguracionGeneralPlataforma(){
    this.isLoading = true;
    this.configGenService.existFetchConfigGen();
    this.configSb?.unsubscribe();
    this.configSb = this.configGenService.getConfigGen().subscribe(confGen=>{
      if(confGen){
        this.aspectosGenerales.sigla_impuesto= confGen.sigla_impuesto;
        this.aspectosGenerales.tasa_impuesto = confGen.tasa_impuesto;
        this.isLoading = false;
        
      }
    });
  }

  cargarConfiguracion(){
    this.isLoading = true;
    this.gestionAspectosBL.existFetchTasas();
    if(this.configSb){
       this.configSb.unsubscribe();
    }
    this.configSb = this.gestionAspectosBL.getTasas().subscribe(configAmb=>{

        if(configAmb){
          this.isLoading = false;
          this.aspectosGenerales = configAmb;
          this.idAspectos = configAmb.id;
          this.existeConfig=true;
          
        }
        else{
          this.isLoading=false;
          this.aspectosGenerales = new AspectosGenerales();
          this.idAspectos ='';
          this.existeConfig=false;
          this.cargarConfiguracionGeneralPlataforma();          
        }
      });
  }

  seleccionarTodo(event:any){
    event.target.select();
  }

  onSubmit(f:NgForm){
    if (f.valid) {
      this.modalSb= this.uiService.showModalQuestions({title:Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon:Icons.QUESTION_MARK})
      .subscribe(result=>{
        if(result){
          this.spinnerPageService.show();
          this.aspectosGenerales.es_vigente = true;
          this.aspectosGenerales.es_borrado = false;
          if(this.aspectosGenerales.incluir_servicio){
            if(this.aspectosGenerales.tasa_servicio==0){
              this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_TASA_CERO, 3000);
              this.spinnerPageService.hide();
              return;
            }
          }
          if(!this.existeConfig){
            this.gestionAspectosBL.insertTasas(this.aspectosGenerales).then(transac=>{
              if(transac.tx){
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.esEditar = false;
                this.existeConfig=true;
              }
              else{
                this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.error, 3000);
                this.spinnerPageService.hide();
              }
            });
          }
          else{
            this.gestionAspectosBL.updateTasas(this.aspectosGenerales, this.idAspectos ).then(transac=>{
              if(transac.tx){
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.esEditar = false;
                this.existeConfig=true;
              }
              else{
                this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.error, 3000);
                this.spinnerPageService.hide();
              }
            });
          }

        }
      });

    }
  }
  changeTipoProducto(value:boolean){
    if(!value){
      this.aspectosGenerales.tasa_servicio=0;
    }

  }

  compareBoolean(option: any, value: any): boolean {
    return option === value;
  }

  Limpiar(f:NgForm){
    if(this.existeConfig){
      this.cargarConfiguracion();
    }
    this.esEditar = false;
  }

  editarTasas(){
    this.esEditar = true;
  }

}
