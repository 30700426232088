<div fxLayout="row" fxLayoutAlign="end center" class="content-paginator" fxLayout.xs="column"  fxLayoutAlign="end center" fxLayoutAlign.xs="space-evenly center"  >
  <div>
    <mat-form-field class="tamanio-pagina" matTooltip="Tamaño de página" >
      <mat-select
        name="nroItems"
        #nroItems="ngModel"
        [(ngModel)]="paginacion.tamanio_pagina_seleccionado"
        (selectionChange)="changeSizePage($event.value)"
        >
          <mat-option
            *ngFor="let tamanio of paginacion.opcion_tamanio_pagina"
            [value]="tamanio"
          >
          {{tamanio}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span class="label" *ngIf="!paginacion.es_busqueda">{{paginacion.numeracion_inicial_documento}} a {{paginacion.numeracion_final_documento}} de {{paginacion.total_registros_coleccion}} </span>
  </div>
  <div class="grupo-botones" fxLayout="row" fxLayoutAlign="center center" >
    <button mat-icon-button [disabled]="disabledBtnPrimeraPagina" matTooltip="Orden: Primero al 'ultimo" matTooltipPosition="above" class="button" (click)="btnPaginar(EventoPaginacion.PAGINA_PRIMERA)" >
      <mat-icon>{{Icons.VERTICAL_ALIGN_BOTTOM}}</mat-icon>
    </button>
    <button mat-icon-button [disabled]="disabledBtnPaginaAnterior" matTooltip="Anterior" matTooltipPosition="above" class="button" (click)="btnPaginar(EventoPaginacion.PAGINA_ANTERIOR)">
      <mat-icon>{{Icons.CHEVRON_LEFT}}</mat-icon>
    </button>
    <span class="label" matTooltip="# de página" >{{paginacion.pagina_actual}}</span>
    <span  class="label" *ngIf="!paginacion.es_busqueda"> de </span>
    <span  class="label" *ngIf="!paginacion.es_busqueda">{{totalPaginas}}</span>
    <button mat-icon-button [disabled]="disabledBtnPaginaSiguiente" matTooltip="Siguiente" matTooltipPosition="above" class="button" (click)="btnPaginar(EventoPaginacion.PAGINA_SIGUIENTE)">
      <mat-icon>{{Icons.CHEVRON_RIGHT}}</mat-icon>
    </button>
    <button mat-icon-button [disabled]="disabledBtnUltimaPagina" matTooltip="Orden: Último al primero" matTooltipPosition="above" class="button" (click)="btnPaginar(EventoPaginacion.PAGINA_ULTIMA)">
      <mat-icon>{{Icons.VERTICAL_ALIGN_TOP}}</mat-icon>
    </button>
  </div>

</div>
