import { Injectable } from '@angular/core';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { Mensaje } from '../models/Mensaje';
import { Auditoria, colections, documentsEmpresa } from 'src/app/shared/cons/db.colections';
import { filter, map } from 'rxjs/operators';
import { Utils } from 'src/app/shared/helpers/utils';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MensajeDaService {
  private mensajeCache: BehaviorSubject<Mensaje[] |null|undefined>= new BehaviorSubject<Mensaje[]|null|undefined>(null);
  public mensaje$: Observable<Mensaje[] |null|undefined> = this.mensajeCache.asObservable();
  private fetchSb!: Subscription;

  transaccion: TransaccionModel = new TransaccionModel();
  constructor(
    private db: AngularFirestore,
  ) { }

  fetchMensaje(idEmpresa: string): void {
    if(this.fetchSb && !this.fetchSb.closed){
      this.mensajeCache.next(this.mensajeCache.value);
      return;
    }    
    this.fetchSb = this.getMensaje(idEmpresa).subscribe(msjs => {
      this.mensajeCache.next(msjs);
    });
  }

  stopFetchingMensaje(): void {
    if (this.fetchSb) {
      this.fetchSb.unsubscribe();
    }
    this.mensajeCache.next(undefined);
  }

  getMensaje(idEmpresa:string){
    let listaMensajes: Mensaje[]= new Array();
    return this.db.collection(colections.EMPRESA, (ref)=>
    ref.where(Auditoria.es_borrado,'==',false)    
    ).doc(idEmpresa).collection(colections.MENSAJE, (ref1)=>
     ref1.where(Auditoria.es_borrado,'==',false)
     .where(Auditoria.es_vigente,'==',true))
     .snapshotChanges()
     .pipe(
       map((changes: DocumentChangeAction<any>[]) => {
         return changes
           .filter(change => change.type === 'added')  // Filtra solo los documentos añadidos
           .map(change => {
             const data = change.payload.doc.data() as Mensaje;
             data.id = change.payload.doc.id;
             return Utils.convertDate(data);
           });
       })
     );
   }
}