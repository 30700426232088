import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { Msjs, Msjs_Validations, PatternsRegex, SistOperation } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { IItemDsctoIncidencia } from '../dialog/models/IItemDsctoIncidencia';

import { UIService } from 'src/app/shared/services/ui.services';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { IDescuento } from '../dialog/models/IDescuento';
import { Subscription } from 'rxjs';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { GestionDescuentosBlService } from './services/gestion-descuentos.bl.service';
import { IIncidencia } from '../dialog/models/IInsidencia';
import { GestionIncidenciasBlService } from './services/gestion-incidencias.bl.service';

@Component({
  selector: 'app-gestion-descuentos-incidencias',
  templateUrl: './gestion-descuentos-incidencias.component.html',
  styleUrls: ['./gestion-descuentos-incidencias.component.scss']
})

@AutoUnsubscribe

export class GestionDescuentosIncidenciasComponent implements OnInit {

  @ViewChild('txtDsctoInput',{static:false}) inputDscto!:NgModel;
  @ViewChild('txtMotivoInput',{static:false}) inputMotivoDscto!:NgModel;
  @ViewChild('txtIncidenciaInput',{static:false}) inputIncidencia!:NgModel;

  txtDscto:string='';
  txtMotivo:string='';
  txtIncidencia:string='';
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly SistOperation: typeof SistOperation = SistOperation;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  modoOperacion: SistOperation = SistOperation.VER;
  modoOperacionIncid: SistOperation = SistOperation.VER;
  readonly Icons: typeof Icons = Icons;
  listaDscto:IItemDsctoIncidencia[]=[];
  listaIncidencia:IItemDsctoIncidencia[]=[];
  removable:boolean = true;
  descuento!: IDescuento | null;
  incidencia!:IIncidencia | null;
  modalSb!:Subscription;
  reloadDsctoSb = new Subscription();
  isLoading:boolean = false; 
  configSb = new Subscription();
  configIncidenciaSb = new Subscription();
  existeDsctoDB:boolean = false;
  existeIncidenciaDB:boolean = false;
  isLoadingIncidencia:boolean = false;

  constructor( 
    private uiService:UIService,
    private readonly spinnerPageService: SpinnerPageService,
    private gestionDsctoBLService:GestionDescuentosBlService,
    private gestionIncidenciaBLService: GestionIncidenciasBlService
  ) { }

  ngOnInit(): void {
    this.reloadDsctoSb = this.uiService.reloadDscto$.subscribe(()=>{
      this.isLoading = true;
      this.isLoadingIncidencia = true;
      this.cargarConfiguracionDscto()
      this.cargarConfiguracionIncidencia();
    });  

  }

  //#region DSCTO

  cargarConfiguracionDscto(){    
    this.gestionDsctoBLService.existFetchDscto();
    this.configSb?.unsubscribe();
    this.configSb = this.gestionDsctoBLService.getDscto().subscribe(dsctosre=>{
      if(dsctosre){
        this.isLoading = false;
        this.descuento = dsctosre;
        this.listaDscto = dsctosre.lista_descuento;
        this.descuento.id = dsctosre.id;
        this.existeDsctoDB=true;
      }
      else{
        this.isLoading=false;
        this.listaDscto=[];
        this.descuento = null;
        this.existeDsctoDB=false;
      }
    });
  }

  removerDscto(descto:IItemDsctoIncidencia){
    const dsctos = this.listaDscto.filter(des=> des.item!=descto.item);
    this.listaDscto = dsctos;
  }

  editarDscto(){
    this.modoOperacion = SistOperation.EDITAR;
  }

  aniadirDscto(){     
    if(this.txtMotivo=='' || this.txtMotivo==null){
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_DSCTO_MOTIVO_ERROR, 3000);
      return;
    }
    if(this.txtDscto=='' || this.txtDscto==null){
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_DSCTO_ERROR, 3000);
      return;
    }
    if(Number(this.txtDscto)>100){
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_DSCTO_ERROR_MAYOR100, 3000);
      return;
    }   
    const dsctosExist = this.listaDscto.some(des=> des.item==Number(this.txtDscto) && des.motivo== this.txtMotivo );
    if(dsctosExist){
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_DSCTO_ERROR_EXISTE, 3000);
      return;
    }
    const item:IItemDsctoIncidencia={
      item: Number(this.txtDscto),
      position: Number(this.listaDscto.length)+1,
      motivo:this.txtMotivo
    }
    this.listaDscto.push(item);
    this.txtDscto ='';
    this.txtMotivo ='';
    this.inputDscto.reset();
    this.inputMotivoDscto.reset();
  }

  cancelarDscto(){    
    this.txtDscto='';
    this.modoOperacion = SistOperation.VER;
    if(this.descuento?.lista_descuento)
      this.listaDscto = this.descuento.lista_descuento;
    else
      this.listaDscto =[];
  }

  guardarDscto() {
    this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon: Icons.QUESTION_MARK })
      .subscribe(result => {
        if (result) {
          this.spinnerPageService.show();
          this.descuento = {
            lista_descuento: this.listaDscto,
            es_borrado: false,
            es_vigente: true,
            ...this.existeDsctoDB && {id:this.descuento!.id}
          }

          this.descuento.lista_descuento = this.descuento.lista_descuento.sort((a, b) => {
            const valorA = typeof a.item === 'string' ? parseInt(a.item as string, 10) : a.item;
            const valorB = typeof b.item === 'string' ? parseInt(b.item as string, 10) : b.item;
            
            return valorA - valorB;
        });

          if (!this.existeDsctoDB) {
            //inserta nuevo dscto
            this.gestionDsctoBLService.insertDscto(this.descuento).then(transac => {
              if (transac.tx) {
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.modoOperacion = SistOperation.VER;
              }
              else {
                this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.error, 3000);
                this.spinnerPageService.hide();
              }
            });
            //actualiza dscto
          } else {
            this.gestionDsctoBLService.updateDsctos(this.descuento).then(transac => {
              if (transac.tx) {
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.modoOperacion = SistOperation.VER;
              }
              else {
                this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.error, 3000);
                this.spinnerPageService.hide();
              }
            });

          }
        }
      });
  }

  //#end region

  //#region incidencia

  cargarConfiguracionIncidencia(){    
    this.gestionIncidenciaBLService.existFetchIncidencia();
    this.configIncidenciaSb?.unsubscribe();
    this.configIncidenciaSb = this.gestionIncidenciaBLService.getIncidencia().subscribe(incidenciare=>{
      if(incidenciare){
        this.isLoadingIncidencia = false;
        this.incidencia = incidenciare;
        this.listaIncidencia = incidenciare.lista_incidencia;
        this.incidencia.id = incidenciare.id;
        this.existeIncidenciaDB=true;
      }
      else{
        this.isLoadingIncidencia=false;
        this.incidencia = null;
        this.listaIncidencia = [];        
        this.existeIncidenciaDB=false;
      }
    });
  }

  aniadirIncidencia(){
    if(this.txtIncidencia=='' || this.txtIncidencia==null){
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_INCIDENCIA_ERROR, 3000);
      return;
    }
    const incidenciaExist = this.listaIncidencia.filter(des=> des.item==this.txtIncidencia);
    if(incidenciaExist && incidenciaExist.length>0){
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_INCIDENCIA_ERROR_EXISTE, 3000);
      return;
    }
    const item:IItemDsctoIncidencia={
      item: this.txtIncidencia,
      position: Number(this.txtIncidencia.length)+1,
      motivo: this.txtMotivo
    }
    this.listaIncidencia.push(item);
    this.txtIncidencia ='';
    this.inputIncidencia.reset();
  }

  removerIncidencia(incidencia:IItemDsctoIncidencia){
    const incidencias = this.listaIncidencia.filter(des=> des.item!=incidencia.item);
    this.listaIncidencia = incidencias;
  }

  guardarIncidencia(){
    this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon: Icons.QUESTION_MARK })
    .subscribe(result => {
      if (result) {
        this.spinnerPageService.show();
        this.incidencia = {
          lista_incidencia: this.listaIncidencia,
          es_borrado: false,
          es_vigente: true,
          ...this.existeIncidenciaDB==true && {id:this.incidencia!.id}
        }

        if (!this.existeIncidenciaDB) {
          //inserta nuevo dscto
          this.gestionIncidenciaBLService.insertIncidencia(this.incidencia).then(transac => {
            if (transac.tx) {
              this.spinnerPageService.hide();
              this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
              this.modoOperacionIncid = SistOperation.VER;
            }
            else {
              this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.error, 3000);
              this.spinnerPageService.hide();
            }
          });
          //actualiza dscto
        } else {
          this.gestionIncidenciaBLService.updateIncidencia(this.incidencia).then(transac => {
            if (transac.tx) {
              this.spinnerPageService.hide();
              this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
              this.modoOperacionIncid = SistOperation.VER;
            }
            else {
              this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.error, 3000);
              this.spinnerPageService.hide();
            }
          });

        }
      }
    });
  }

  cancelarIncidencia(){
    this.txtIncidencia='';
    this.modoOperacionIncid = SistOperation.VER;
    if(this.incidencia && this.incidencia.lista_incidencia){
      this.listaIncidencia = this.incidencia.lista_incidencia;
    }else{
      this.incidencia = null;
    }
   
  }
  editarIncidencia(){
    this.modoOperacionIncid = SistOperation.EDITAR;
  }
  //#end region

}

