import { Injectable } from '@angular/core';
import { FacturacionDAService } from '../services.da/facturacion.da.service';

@Injectable({
  providedIn: 'root'
})

export  class FacturacionBLService{
  constructor(
    private facturacionDAService: FacturacionDAService
  ){

  }


  getFacturacionCache(){
    return this.facturacionDAService.facturacion$;
  }

  fetchEmpresaById(idEmpresa:string):void{
    this.facturacionDAService.fetchFacturacionById(idEmpresa);
  }

  getRecibo(idEmpresa:string){
    return this.facturacionDAService.getRecibo(idEmpresa);
  }


}
