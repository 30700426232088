import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { IUsuario } from '../components/dialog/models/usuario.model';
import { environment } from 'src/environments/environment';
import { CONFIGURACION } from '../cons/config';
import { CColaborador } from '../components/procesos/gestion-colaborador/models/ccolaborador.model';
import { CEmpresa } from '../components/dialog/models/cempresa.model';
import { Utils } from '../helpers/utils';
import { ISession } from '../auth/interface/ISession';
@Injectable({
  providedIn: 'root'
})
export class CookiesTokensService {
  private readonly key = environment.key.SECRET_KEY;

  constructor(private cookieService: CookieService) {

   }

   get user(): IUsuario | undefined | CColaborador {
    const encrypted = this.cookieService.get(CONFIGURACION.COOKIE_DATA_US);
    if (encrypted) {
      const bytes = CryptoJS.AES.decrypt(encrypted, this.key).toString(CryptoJS.enc.Utf8);
      return JSON.parse(bytes) as IUsuario;
    }
    return undefined;
  }

  get session(): ISession | null {
    const encrypted = this.cookieService.get(CONFIGURACION.COOKIE_DATA_SES);
    if (encrypted) {
      const bytes = CryptoJS.AES.decrypt(encrypted, this.key).toString(CryptoJS.enc.Utf8);
      return JSON.parse(bytes) as ISession;
    }
    return null;
  }

  setUser(data:IUsuario | CColaborador | null){
    if(data){
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), this.key).toString();
      this.cookieService.set(CONFIGURACION.COOKIE_DATA_US, encrypted,86400,'/',undefined,false,undefined );
      // no se usa HttpOnly, podria configurarse en un futuro
      //la duracion de la cookie es de 86400 un día, en caso de que no se elimine
    }
    else{
      this.cleanUser();
    }

  }

  cleanUser(){
    this.cookieService.delete(CONFIGURACION.COOKIE_DATA_US,'/', undefined,false, undefined);
  }

  setSession(data:ISession){
    if(data){
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), this.key).toString();
      this.cookieService.set(CONFIGURACION.COOKIE_DATA_SES, encrypted,86400,'/',undefined,false,undefined );
    }
    else{
      this.cleanSession();
    }
  }

  cleanSession(){
    this.cookieService.delete(CONFIGURACION.COOKIE_DATA_SES,'/', undefined,false, undefined);
  }
}


