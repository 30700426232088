<div fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title class="dialog-title">{{titleModal}}</div>
  <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()" [attr_disabled]="isLoading" ></app-button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <section>
    <form #f="ngForm" (ngSubmit)="onSubmit(f)">
      <div *ngIf="!esModoVer" fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" class="form-tipo-usuario">
        <mat-form-field>
          <mat-label>Módulo</mat-label>
          <mat-select
          (selectionChange)="moduloSelect($event.value)"
          name="modulo"
          [(ngModel)]="moduloRoleSelec.codigo_modulo"
          id="modulo"
          #modulo="ngModel">
            <mat-option *ngFor="let mod of modulos" [value]="mod.codigo_modulo">{{mod.nombre_modulo}}</mat-option>
          </mat-select>
          <mat-error *ngIf="modulo.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Rol</mat-label>
          <mat-select
          (selectionChange)="rolSelect($event.value)"
          name="rol"
          [(ngModel)]="moduloRoleSelec.rol"
          id="rol"
          #rol="ngModel">
            <mat-option *ngFor="let rol of rolesDisponibles" [value]="rol.rol">{{rol.rol}}</mat-option>
          </mat-select>
          <mat-error *ngIf="rol.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        </mat-form-field>
      </div>
      <div fxLayoutAlign="center start"  *ngIf="modulosRolesSelTodos.length>0" fxLayout="column" fxLayout.xs="column" style="margin-bottom: 10px;">
        <mat-chip-list class="mat-chip-list-stacked" #chipList aria-label="Seleccion de Roles y Modulos">
          <mat-chip
            removable="esModoVer?false:true"
            (removed)="onSelectChipModRol(moduloRol, f)"
            *ngFor="let moduloRol of modulosRolesSelTodos"
            >
            <span>{{moduloRol.nombre_modulo | sizeLabel:30}}: {{moduloRol.rol | sizeLabel:30 }}</span>
            <button matChipRemove *ngIf="esModoVer?false:true">
              <mat-icon>{{Icons.CANCEL}}</mat-icon>
            </button >
          </mat-chip>
        </mat-chip-list>
      </div>

      <mat-divider style="margin-bottom: 10px;" ></mat-divider>
      <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" class="form-datos-personales">
        <mat-form-field>
          <input required type="text" matInput placeholder="Nombres" name="nombres" ngModel
          [pattern]="PatternsRegex.onlyCharsTwoWordsNoSpanish" #nombreInput="ngModel"
          [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
          [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
          oninput="this.value = this.value.toUpperCase()"
          [(ngModel)]="ccolaborador.nombres"
          [disabled]="datoPasado.operacion==SistOperation.VER"
          >
        <mat-error *ngIf="nombreInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        <mat-error
          *ngIf="nombreInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_2PALABRAS}}</mat-error>
        <mat-error
          *ngIf="nombreInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
        <mat-error
          *ngIf="nombreInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" matInput placeholder="Apellido Paterno" name="apPaterno"
            [pattern]="PatternsRegex.onlyCharsOneWordsSpanish" #apPaterno="ngModel" required
            [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
            ngModel
            oninput="this.value = this.value.toUpperCase()"
            [(ngModel)]="ccolaborador.apellido_paterno"
            [disabled]="datoPasado.operacion==SistOperation.VER"
            >
          <mat-error *ngIf="apPaterno.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error
            *ngIf="apPaterno.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_1PALABRA}}</mat-error>
          <mat-error
            *ngIf="apPaterno.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
          <mat-error
            *ngIf="apPaterno.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
        <mat-form-field>
          <input type="text" matInput placeholder="Apellido Materno" name="apMaterno"
            [pattern]="PatternsRegex.onlyCharsOneWordsSpanish" #apMaterno="ngModel" required
            [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3" ngModel

            oninput="this.value = this.value.toUpperCase()"
            [(ngModel)]="ccolaborador.apellido_materno"
            [disabled]="datoPasado.operacion==SistOperation.VER"
            >
          <mat-error *ngIf="apMaterno.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error
            *ngIf="apMaterno.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_1PALABRA}}</mat-error>
          <mat-error
            *ngIf="apMaterno.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
          <mat-error
            *ngIf="apMaterno.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Fecha de Nacimiento </mat-label>
          <input matInput placeholder="dd/mm/aaaa" 
            [max]="maxDate" 
            [min]="minDate" 
            #cumpleanios="ngModel"
            ngModel name="cumpleanios" required [matDatepicker]="picker"
            [(ngModel)]="ccolaborador.fecha_nacimiento"
            disabled            
            >
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker  [disabled]="datoPasado.operacion==SistOperation.VER"  #picker></mat-datepicker>
          <mat-error *ngIf="cumpleanios.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO_FORMATO_INCORRECTO}}</mat-error>
          <mat-error *ngIf="isDateMaxError()">{{Msjs_Validations.MSJ_VAL_FECHA_MAXIMA_INCORRECTA}}</mat-error>
          <mat-error *ngIf="isDateMinError()">{{Msjs_Validations.MSJ_VAL_FECHA_MINIMO_INCORRECTA}}</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
        <mat-form-field>
          <mat-label>Sexo</mat-label>
          <mat-select required name="ddlSexo" #ddlSexo="ngModel"
          [(ngModel)]="ccolaborador.sexo"
          [disabled]="datoPasado.operacion==SistOperation.VER"
          >
            <mat-option *ngFor="let sexo of lSexo" [value]="sexo.value">{{sexo.denominacion}}</mat-option>
          </mat-select>
          <mat-error *ngIf="ddlSexo.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" matInput placeholder="Teléfono movil" name="telefMovil" ngModel
            [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_9"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_9" #telefMovil="ngModel"
            [pattern]="PatternsRegex.onlyIntegerUnsigned" required
            (keypress) ="onlyNumbers($event)"
            [(ngModel)]="ccolaborador.telefono"
            [disabled]="datoPasado.operacion==SistOperation.VER"
            >
          <mat-error *ngIf="telefMovil.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error *ngIf="telefMovil.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
          <mat-error *ngIf="telefMovil.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_MIN9_DIGITOS}}</mat-error>
          <mat-error *ngIf="telefMovil.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_MAX9_DIGITOS}}</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
        <mat-form-field>
          <mat-label>Tipo Documento</mat-label>
          <mat-select
            required name="ddlTipoDocumento"
            #ddlTipoDocumento="ngModel"
            [(ngModel)]="ccolaborador.tipo_documento"
            [disabled]="datoPasado.operacion==SistOperation.VER"
          >
            <mat-option *ngFor=" let tdocumento of lTipo_documento"
              [value]="tdocumento.value">{{tdocumento.denominacion}}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="ddlTipoDocumento.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" matInput placeholder="Núm. Documento" name="numDocumento" ngModel
            [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_9"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8" #numDocumento="ngModel"
            [pattern]="PatternsRegex.onlyIntegerUnsigned" required
            (keypress) ="onlyNumbers($event)"
            [(ngModel)]="ccolaborador.numero_documento"
            [disabled]="datoPasado.operacion==SistOperation.VER"
            >
          <mat-error *ngIf="numDocumento.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error *ngIf="numDocumento.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
          <mat-error *ngIf="numDocumento.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_MIN8_DIGITOS}}</mat-error>
          <mat-error *ngIf="numDocumento.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_MAX9_DIGITOS}}</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
        <mat-form-field>
          <input type="text" matInput placeholder="Dirección y número" name="direccion" ngModel
            [(ngModel)]="ccolaborador.direccion"
            [maxlength]="Length_Database.DB_LENGTH_SMALL_MAX_50"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8"
            required
            [disabled]="datoPasado.operacion==SistOperation.VER"
            #direccion="ngModel">
          <mat-error *ngIf="direccion.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error
            *ngIf="direccion.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8CARACTERES}}</mat-error>
          <mat-error
            *ngIf="direccion.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
        </mat-form-field>
        <app-upload
          [multipleFiles]="false"
          [requiredFileType]="fileType"
          [label]="label"
          [is_edit]="esModoEdicion"
          [max_size_file]="500000"
          [max_size_width_height]="sizeMaxHeightWidth"
          [images_uploaded]="files_uploaded"
          [disabled]="datoPasado.operacion==SistOperation.VER"
          [disabled_add]="datoPasado.operacion==SistOperation.VER"
        ></app-upload>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" class="toogle">
        <mat-slide-toggle (change)="onChangeEstado($event)"
              color="primary"
              name="estado"
              ngModel
              #estado="ngModel"
              [(ngModel)]="ccolaborador.es_vigente"
              [disabled]="datoPasado.operacion==SistOperation.VER"
              >
                  {{msjEstado}}
                  <span  class="label" > (Estado de la cuenta de usuario).</span>
              </mat-slide-toggle>
      </div>
      <mat-divider style="margin-top: 20px;"></mat-divider>
      <div class="horario">
        <app-horario-trabajo
        [horarioEmpresa]="cHorario2"
        [showSpinner]="showSpinnerHorario2"
        [formaFila]="false"
        [onlyTemplate]="true"
        [disabled]="datoPasado.operacion==SistOperation.VER"
        ></app-horario-trabajo>
      </div>
      <!-- se oculta en modo edicion -->
      <mat-divider style="margin-bottom: 20px;" ></mat-divider>
      <div fxLayout="column" fxLayout.xs="column" >
        <mat-form-field >
          <input type="text"
            matInput
            placeholder="Usuario (correo electrónico)"
            name="correo"
            ngModel
            autocomplete="new-off"
            autocomplete="off"
            [(ngModel)]="ccolaborador.correo_electronico"
            [maxlength]="Length_Database.DB_LENGTH_SMALL_MAX_50"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
            required
            [pattern]="PatternsRegex.onlyEmail"
            (blur)="validarCorreo(f)"
            oninput="this.value = this.value.toLowerCase()"
            [disabled]="datoPasado.operacion==SistOperation.VER || datoPasado.operacion==SistOperation.EDITAR"
            #correo="ngModel">
            <button mat-icon-button matSuffix type="button" disabled>
              <mat-icon>{{Icons.BADGE}}</mat-icon>
            </button>
          <mat-error *ngIf="correo.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error *ngIf="correo.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
          <mat-error *ngIf="correo.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
          <mat-error *ngIf="correo.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_EMAIL}}</mat-error>
          <mat-hint style="color: var(--red600);" *ngIf="correoExistente">{{Msjs.MSJ_SIGNUP_ERROR_EMAIL_USADO}}</mat-hint>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" *ngIf="!esModoEdicion">
          <mat-form-field >
            <input
              [type]="hide?'password':'text'"
              matInput
              placeholder="Contraseña"
              name="password1"
              ngModel
              autocomplete="off"
              autocomplete="new-password"
              [(ngModel)]="ccolaborador.password"
              [maxlength]="Length_Database.DB_LENGTH_SMALL_MAX_50"
              [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8"
              required
              [pattern]="PatternsRegex.onlyPassword"
              #password1="ngModel">
              <button mat-icon-button matSuffix (click)="hide = !hide" type="button" >
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            <mat-error *ngIf="password1.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="password1.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8CARACTERES}}</mat-error>
            <mat-error *ngIf="password1.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
            <mat-error *ngIf="password1.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_PASSWORD}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              [type]="hide2?'password':'text'"
              matInput
              placeholder="Repita la contraseña"
              name="password2"
              ngModel
              autocomplete="off"
              autocomplete="new-password"
              [(ngModel)]="contrasenia2"
              [maxlength]="Length_Database.DB_LENGTH_SMALL_MAX_50"
              [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8"
              required
              [pattern]="PatternsRegex.onlyPassword"
              (blur)="validarPassword()"
              #password2="ngModel">
              <button mat-icon-button matSuffix (click)="hide2 = !hide2" type="button" >
                <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="password2.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
              <mat-error *ngIf="password2.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8CARACTERES}}</mat-error>
              <mat-error *ngIf="password2.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
              <mat-error *ngIf="password2.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_PASSWORD}}</mat-error>
              <mat-hint style="color: var(--red600);" *ngIf="errorContrasenia2">{{Msjs_Validations.MSJ_HINT_PASSWORD_IGUAL}}</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <mat-divider style="margin-bottom: 20px; margin-top: 20px;" *ngIf="!esModoVer" ></mat-divider>
      <mat-card-actions fxLayout="row" fxLayoutAlign="space-around center" *ngIf="!esModoVer" >
        <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="(f.invalid)"
          [attr_type]="'submit'"></app-button>
        <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" color="gray"
          (click)="Limpiar(f)"></app-button>
        <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING"
          (click)="Limpiar(f)"></app-button>
      </mat-card-actions>
    </form>
  </section>
  <section  *ngIf="isLoading">
    <app-spinner></app-spinner>
  </section>

</mat-dialog-content>
