import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';
import { DialogProductoBlService } from '../services/services.bl/dialog-producto.bl.service';
import { FormControl, NgForm } from '@angular/forms';
import { ITableFiltroProducto } from '../models/ITableFiltroProducto';
import { Observable, of } from 'rxjs';
import { Producto } from '../models/Producto';
import { debounceTime, filter, startWith, switchMap } from 'rxjs/operators';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { Length_Database, Msjs, Msjs_Validations, PatternsRegex } from 'src/app/shared/cons/common';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { IProductoAnidado } from '../models/IProductoAnidado';
import { DialogBuscarProductoService } from './dialogBuscarProducto.service';
import { IProductoTicket } from '../../../registrar-pedido/models/IProductoTicket';

@Component({
  selector: 'app-dialog-buscar-producto',
  templateUrl: './dialog-buscar-producto.component.html',
  styleUrls: ['./dialog-buscar-producto.component.scss']
})

@AutoUnsubscribe
export class DialogBuscarProductoComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  isLoading:boolean=false;
  txtComplete = new FormControl('');
  filteredOptions!: Observable<Producto[]>;
  productosSeleccionados:IProductoAnidado[]=[]
  producto:string=''
  removable:boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef: MatDialogRef<DialogBuscarProductoComponent>,
    private uiService:UIService,
    private DialogProductoBLService: DialogProductoBlService,
    private dialogBuscarProductoSrv: DialogBuscarProductoService
  ) { }

  ngOnInit():void {
    this.filteredOptions = this.txtComplete.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      filter(value => typeof value === 'string' && value.length >= 3), // Filtro para la longitud mínima de caracteres
      switchMap(value => {
        if (value !== '') {
          return this.DialogProductoBLService.getProductosAutocomplete(value);
        } else {
          return [];
        }
      })
    );
  }

  removerProducto(producto:IProductoAnidado){
    const listaDepurada = this.productosSeleccionados.filter(prod=>prod.id!==producto.id);
    this.productosSeleccionados = [...listaDepurada];

  }

  onClose(){
    this.dialogRef.close();
  }

  onSelect(producto:Producto){
    if(producto.id){
      const productoRepetido = this.productosSeleccionados.filter(prods=>prods.id==producto.id);
      if(productoRepetido.length>0){
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_DOBLE_PRODUCTO, 3000);
        this.txtComplete.setValue('');
        return;
      }
      const idProducto= producto.id;
      delete producto.id;
      const productoAnidado:IProductoAnidado={
        id: idProducto,
        producto:producto,
        cantidad: 1
      }
      this.productosSeleccionados.push(productoAnidado);
      this.txtComplete.setValue('');
    }

  }

  onSubmit(f:NgForm){
    this.dialogBuscarProductoSrv.productosAnidados.next(this.productosSeleccionados);
    this.dialogRef.close();

  }

  Limpiar(f:NgForm){
    this.productosSeleccionados = new Array();
    this.txtComplete.setValue('');
  }

  cargarCantidad(indice: number){    
    const dialogRefCantidad =this.uiService.showModalCantidad({producto: this.productosSeleccionados[indice], esEditarProducto:false, dialog:'registrar_producto'});
    dialogRefCantidad.afterClosed().subscribe(cantidad=>{
      this.productosSeleccionados[indice].cantidad = cantidad; 
    });

  }


}
