<div style="min-width: 250px; min-height: 170px;"  fxLayout="column" fxLayoutAlign="end center" >

  <div class="carousel-container">
    <button *ngIf="slides.length>1" mat-icon-button (click)="previousImage()" class="previous">
      <mat-icon>{{Icons.CHEVRON_LEFT}}</mat-icon>
    </button>

    <div class="images">
      <div *ngFor="let image of slides; let i = index">
        <img [src]="image.image" [alt]="image.title" [class.active]="i === currentIndex">
        <div *ngIf="visibleEtiquetaFoto" class="image-title" [class.active]="i === currentIndex">
          {{image.title}}
        </div>
      </div>
    </div>

    <button *ngIf="slides.length>1" mat-icon-button (click)="nextImage()" class="next">
      <mat-icon>{{Icons.CHEVRON_RIGHT}}</mat-icon>
    </button>

    <div class="indices">
      <div *ngFor="let image of slides; let i = index" [class.active]="i === currentIndex">
        {{ i + 1 }}
      </div>
    </div>
  </div>


</div>