<div fxLayout="row" fxLayoutAlign="center center">
    <mat-card fxFlex="80%" fxFlex.xs="100%">
        <mat-card-header>
            <mat-card-title>Comprobante Electrónico </mat-card-title>
            <mat-card-subtitle>Configuración de Comprobante Electrónico (Emisión de Boletas de Venta Electrónicas y Facturas Electrónicas)</mat-card-subtitle>
          </mat-card-header>
          <mat-divider></mat-divider>
          <section style="margin-top: 20px;" *ngIf="empresa.comprobante_electronico==undefined">
            <span>Para solicitar la activación de emisión de Comprobante Electrónico, comunícate con nosotros:</span>            
            <ul  class="no-bullets">
                <li>
                    <div fxLayout="row" fxLayoutGap="10px">
                        <mat-icon>{{Icons.MAIL}}</mat-icon>
                        <span>{{DatosPlataforma.EMAIL_EMPRESA}}</span>                        
                    </div>
                </li>
                <li> 
                    <div fxLayout="row">
                        <div style="margin-left: -5px;" >
                            <button mat-icon-button (click)="abrirWathsAppLink()">
                                <mat-icon svgIcon="icon-wath-black"></mat-icon>                             
                            </button>
                        </div>
                        <div style="margin-top: 10px;" >
                            <span>{{DatosPlataforma.NUMERO_SOPORTE}}</span>
                        </div>                       
                            
                    </div>
                </li>
            </ul>
            <span>Envíanos los siguientes datos:</span>
            <ul>
                <li>Asunto del Correo o de Mensaje:&nbsp;<span style="font-weight: bold;" >REQUERIMIENTO CONFIGURACIÓN COMPROBANTE ELECTRÓNICO:&nbsp; {{empresa.nombre_razon}}.</span></li>
                <li>RUC de la empresa:&nbsp;<span style="font-weight: bold;">{{empresa.ruc}}</span>.</li>
                <li><span matTooltip="Código de producto SUNAT: Es el código que identifica tu producto ante SUNAT. Puedes revisarlo ingresando a SUNAT, con tu clave SOL." >Código SUNAT.</span></li>
                <li><span matTooltip="Tiene que ser igual a SUNAT">Ubigeo INEI de la Empresa.</span></li>
                <li>Ubicación-Departamento de la Empresa:&nbsp;<span style="font-weight: bold;">{{empresa.codigo_departamento}} - {{departamento}}</span>.</li>
                <li>Ubicación-Provincia de la empresa:&nbsp;<span style="font-weight: bold;">{{empresa.codigo_provincia}} - {{provincia}}</span>.</li>
                <li>Ubicación-Distrito de la empresa:&nbsp;<span style="font-weight: bold;">{{empresa.codigo_distrito}} - {{distrito}}</span>.</li>
                <li>Dirección exacta de la empresa:&nbsp;<span style="font-weight: bold;">{{empresa.direccion}}</span>.</li>
                <li><span matTooltip="La serie de una factura electrónica ante la SUNAT es un código alfanumérico de cuatro dígitos que comienza con la letra F. La numeración es correlativa, comienza en 1 y es independiente de la numeración de la factura física. La serie se usa principalmente para diferenciar facturas correspondientes a diferentes actividades de la empresa. Puedes revisarlo ingresando a SUNAT, con tu clave SOL. " >Código de Serie Factura Electrónica.</span></li>
                <li><span matTooltip="El código de serie de una boleta electrónica de una empresa ante la SUNAT es alfanumérico de cuatro dígitos y comienza con la letra B. La numeración es correlativa, comienza en uno (1) y es independiente a la numeración de la boleta física. Puedes revisarlo ingresando a SUNAT, con tu clave SOL. " >Código de Serie Factura Electrónica.</span></li>               
            </ul>
          </section>
          <section  *ngIf="empresa.comprobante_electronico!=undefined && 
            empresa.comprobante_electronico.estado_comprobante_empresa!=EstadosComprobanteElectronicoEmpresa.NO_REQUERIDO"  style="margin-top: 20px;">
            <div fxLayout="row" fxLayoutGap="10px">
                <div  style="padding-top:5px;">
                    <span>Emisión de comprobantes electrónicos Estado: </span>
                </div>                
                <mat-chip-list>
                    <mat-chip class="chip-solicitado" >{{empresa.comprobante_electronico.estado_comprobante_empresa.toUpperCase()}}</mat-chip>
                </mat-chip-list>
            </div>
            
            <div>
                <ul>
                    <li><div>Código Plataforma:<span  style="font-weight: bold;">&nbsp;{{empresa.comprobante_electronico.codigo_pse}}</span></div></li>
                    <li><div>Código SUNAT:<span  style="font-weight: bold;">&nbsp;{{empresa.comprobante_electronico.codigo_sunat_pse}}</span></div></li>
                    <li><div>Serie Boleta Electrónica: <span  style="font-weight: bold;">&nbsp;{{empresa.comprobante_electronico.serie_boleta_pse}}</span></div></li>
                    <li><div>Serie Factura Electrónica: <span  style="font-weight: bold;">&nbsp;{{empresa.comprobante_electronico.serie_factura_pse}}</span></div></li>
                    <li><div>Fecha de activación plataforma: <span  style="font-weight: bold;">&nbsp;{{empresa.comprobante_electronico.fecha_alta_pse  }}</span></div></li>
                </ul>                
            </div>
          </section>
    </mat-card>
</div>