<div  class="mat-elevation-z3" fxLayout="row">
  <div class="titulo" >
    {{titulo}}
  </div>
  <div fxLayout="row" *ngIf="item$ | async as item" style="margin-top: 10px;"  >
    <mat-icon class="mat-18">{{Icons.ARROW_RIGHT}}</mat-icon>
    <div style=" color: var(--8888);" ><mat-icon class="mat-18">{{item.icono}}</mat-icon></div>
    <div style="margin-top: 3px; margin-left: 5px;  color: var(--8888); ">
      {{item.nombre}}
    </div>
  </div>
  <div fxLayout="row" *ngIf="itemFirts" style="margin-top: 10px;"  >
    <mat-icon class="mat-18">{{Icons.ARROW_RIGHT}}</mat-icon>
    <div style=" color: var(--8888);" ><mat-icon class="mat-18">{{iconoFirst}}</mat-icon></div>
    <div style="margin-top: 3px; margin-left: 5px;  color: var(--8888); ">
      {{itemFirts}}
    </div>
  </div>
</div>

