import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TableTypes, TamanioPagina, SistOperation, EventoPaginacion } from '../../cons/common';
import { Icons } from '../../cons/icons';
import { Table } from '../table/table.model';
import { TableService } from '../table/table.service';
import { RegistroService } from '../../services/registro.service';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { DialogProductoBlService } from '../procesos/dialog/services/services.bl/dialog-producto.bl.service';
import { ITableFiltroProducto } from '../procesos/dialog/models/ITableFiltroProducto';
import { Subscription } from 'rxjs';
import { Producto } from '../procesos/dialog/models/Producto';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { PaginationService } from '../pagination/pagination.service';
import { ITableEvento } from '../table/ITableEvento';

@Component({
  selector: 'app-listar-producto',
  templateUrl: './listar-producto.component.html',
  styleUrls: ['./listar-producto.component.scss']
})

@AutoUnsubscribe
export class ListarProductoComponent implements OnInit {
  tipoTabla:string = TableTypes.LISTAR_PRODUCTO;
  readonly Icons: typeof Icons = Icons;
  dataTable:Table = new Table();
  listaProductoSb = new Subscription();
  paginacionProductoSb = new Subscription();
  btnVerSb = new Subscription();
  rowSelectSb = new Subscription();

  listaProductos:Producto[] = new Array<Producto>();

  constructor(
    
    private tableService: TableService,
    private registroService: RegistroService,
    private spinnerPageService: SpinnerPageService,
    private DialogProductoBLService: DialogProductoBlService,
    private paginacionService: PaginationService,

  ) {
        //definimos las propiedades de la tabla
        this.dataTable.modoXs = ['numeracion', 'nombre_producto', 'codigo_producto', 'operaciones'];
        this.dataTable.modoM = ['numeracion', 'nombre_producto', 'codigo_producto', 'total_stock','operaciones'];
        this.dataTable.modoLg = ['numeracion', 'nombre_producto', 'codigo_producto', 'total_stock','operaciones'];
        this.dataTable.columnaEstado = 'estado';
        this.dataTable.columnaImagen = 'imagen';
        this.dataTable.columnaEstiloEstado = 'estado';
        this.dataTable.pageSizeOptions = TamanioPagina.LISTA_TAMANIOS
        this.dataTable.pageSize =  TamanioPagina.LISTA_TAMANIOS[1];// tamaño de pagina
        this.dataTable.currentPage = 1; //pagina inicial
        this.dataTable.nextPage = null;
        this.dataTable.previousPage = null;
        this.dataTable.columnaChip = undefined;
        this.dataTable.operaciones = [SistOperation.AGREGAR];
        this.dataTable.filtro[0] = null; //filtro nombres
        this.dataTable.filtro[1] = null; //filtro codigo
        this.dataTable.filtro[2] = null; //filtro estado
        this.dataTable.minPage = null;//cambia ordenamiendo descendente
        this.dataTable.maxPage = null;//cambia ordenamiendo ascendente
   }

  ngOnInit(): void {
    this.btnVerSb = this.tableService.btnVer$.subscribe((callEvento: ITableEvento) => {
      this.procesarFila(callEvento);
      
    });

    this.rowSelectSb = this.tableService.rowSelect$.subscribe((callEvento:ITableEvento)=>{
      this.procesarFila(callEvento);
    });
  }

  procesarFila(callEvento:ITableEvento){
    let contador:number=1;
    //renderiza los productos de los catalogos seleccionados
    if (TableTypes.LISTAR_CATALOGO== callEvento.key){
      //quita productos no vigentes
      const productos = (callEvento.data.lista_productos as Producto[]).filter(prod=>prod.es_vigente);

      //quita productos sin stock
      let productosFinal = productos.filter(produc=>(produc.cantidad_stock && produc.cantidad_stock>0) || (produc.stock_ilimitado));

      //dando forma de numeracion y stock
      productosFinal.forEach(produc=>{
        produc.numeracion = contador;
        produc.total_stock = produc.stock_ilimitado==false?produc.cantidad_stock?.toString():'Ilimitado';
        contador++;
      })
      this.dataTable.data = productosFinal;
      //this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: true });//loading tabla
      this.tableService.loadDataTable$.next(this.tipoTabla);//refrescamos el objeto tabla
      //this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: false });//loading tabla


    }
  }
}
