<div fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title class="dialog-title">{{titleModal}}</div>
  <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()" [attr_disabled]="isLoading" ></app-button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content style="min-width: 240px;">
  <section>
    <form #f="ngForm" (ngSubmit)="onSubmit(f)">
      <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" class="form-tipo-usuario">
        <mat-form-field>
          <input type="text" matInput
          placeholder="Nombre"
          required
          name="nombre"
          ngModel
          [(ngModel)]="producto.nombre_producto"
          [pattern]="PatternsRegex.onlyGoodCharsWithNumbersAndSpace"
          #nombreInput="ngModel"
          [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
          [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
          oninput="this.value = this.value.toUpperCase()"
          [disabled]="datoPasado.operacion==SistOperation.VER"
          >
          <mat-error *ngIf="nombreInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error
            *ngIf="nombreInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
          <mat-error
            *ngIf="nombreInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
          <mat-error
            *ngIf="nombreInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Tipo de Producto</mat-label>
          <mat-select
          [disabled]="datoPasado.operacion==SistOperation.VER"
          (selectionChange)="changeTipoProducto($event.value)"
            required
            name="tipo_producto"
            id="tipo_producto"
            [(ngModel)]="producto.tipo_producto"
            #tipo_producto="ngModel"
            >
              <mat-option  *ngFor="let opcion of ListaTipoProducto.listaTipoProducto" [value]="opcion.codigo_producto">{{opcion.nombre_producto}}</mat-option>
          </mat-select>
          <mat-error *ngIf="tipo_producto.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" class="form-tipo-usuario">
        <mat-form-field>
          <input type="string" matInput
          placeholder="Precio Unitario"
          required
          minlength="1"
          maxlength="8"
          name="precio"
          [disabled]="datoPasado.operacion==SistOperation.VER"
          ngModel
          [(ngModel)]="producto.precio_unitario"
          [pattern]="PatternsRegex.onlyDecimalIntegerUnsigned"
          #precioInput="ngModel"
          (blur)="completarDecimal($event)"
          (focus)="seleccionarTodo($event)"
          (keydown)="($event.keyCode < 48 || ($event.keyCode > 57 && $event.keyCode < 96) ||
          $event.keyCode > 105) &&
          [8, 9, 37, 38, 39, 40, 46, 110, 190].indexOf($event.keyCode) === -1 ? $event.preventDefault() : null"
          [disabled]="datoPasado.operacion==SistOperation.VER"
          >
          <mat-error *ngIf="precioInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error
            *ngIf="precioInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
          <mat-error
            *ngIf="precioInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_8DIGITOS}}</mat-error>
          <mat-error
            *ngIf="precioInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_1DIGITOS}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" matInput
          placeholder="Código"
          required
          name="codigo"
          ngModel
          [(ngModel)]="producto.codigo_producto"
          [pattern]="PatternsRegex.onlyGoodCharsWithNumbers"
          #codigoInput="ngModel"
          [maxlength]="Length_Database.DB_LENGTH_X_SMALL_6"
          [minlength]="Length_Database.DB_LENGTH_X_SMALL_6"
          oninput="this.value = this.value.toUpperCase()"
          [disabled]="(datoPasado.operacion==SistOperation.VER) || (datoPasado.operacion==SistOperation.EDITAR)"
          >
          <mat-error *ngIf="codigoInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error
            *ngIf="codigoInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
          <mat-error
            *ngIf="codigoInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_6}}</mat-error>
          <mat-error
            *ngIf="codigoInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_6}}</mat-error>
        </mat-form-field>

      </div>
      <div fxLayout="row" fxLayoutGap="75px" fxLayoutGap.xs="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" class="form-tipo-usuario">
        <div>
          <mat-checkbox
          required
          color="primary"
          [checked]="producto.stock_ilimitado"
          (change)="producto.stock_ilimitado=!producto.stock_ilimitado"
          [disabled]="datoPasado.operacion==SistOperation.VER"
          >Stock Ilimitado</mat-checkbox>
        </div>
        <div *ngIf="producto.tipo_producto==ListaTipoProducto.listaTipoProducto[6].codigo_producto">
          <mat-checkbox
          required
          color="primary"
          [checked]="producto.tiene_subproductos"
          (change)="AniadirProducto($event)"
          [disabled]="datoPasado.operacion==SistOperation.VER"

          >Añadir SubProductos</mat-checkbox>
        </div>
      </div>
      <div style="margin-top: 20px;" fxLayout="row" fxLayoutGap="70px" fxLayoutGap.xs="15px" fxLayout.xs="column" class="form-tipo-usuario">
        <div *ngIf="!producto.stock_ilimitado">
          <mat-form-field>
            <input
            matInput
            placeholder="Cantidad"
            required
            minlength="1"
            maxlength="5"
            name="cantidad"
            ngModel
            [(ngModel)]="producto.cantidad_stock"
            [pattern]="PatternsRegex.onlyDecimalIntegerUnsigned"
            #cantidadInput="ngModel"
            (keydown)="($event.keyCode < 48 || ($event.keyCode > 57 && $event.keyCode < 96) ||
            $event.keyCode > 105) &&
            [8, 9, 37, 38, 39, 40, 46].indexOf($event.keyCode) === -1 ? $event.preventDefault() : null"
            [disabled]="datoPasado.operacion==SistOperation.VER"
            >
            <mat-error *ngIf="cantidadInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error
              *ngIf="cantidadInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
            <mat-error
              *ngIf="cantidadInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_5DIGITOS}}</mat-error>
            <mat-error
              *ngIf="cantidadInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_1DIGITOS}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="producto.subproductos!=undefined && producto.subproductos.length>0" >
        <mat-divider></mat-divider>
        <div style="margin-top: 10px;" *ngIf="datoPasado.operacion!=SistOperation.VER" >
          <button mat-mini-fab color="primary" type="button" (click)="AniadirProductoBtn()"
            matTooltip="Añadir Sub Producto" matTooltipPosition="after">
            <mat-icon>{{Icons.ADD}}
            </mat-icon>
          </button>
        </div>
        <mat-form-field style="margin-top: 20px; width: 100%;">
          <mat-label>Sub Productos Seleccionados</mat-label>
          <mat-chip-list *ngFor="let prodAnidado of producto.subproductos">
            <mat-chip [removable]="datoPasado.operacion!=SistOperation.VER" (removed)="removerProducto(prodAnidado)"
            >{{prodAnidado.cantidad}}&nbsp;{{prodAnidado.producto.nombre_producto}}
            <button matChipRemove>
              <mat-icon>{{Icons.CANCEL}}</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
        </mat-form-field>
        <mat-divider></mat-divider>
      </div>
      <div style="margin-top: 20px;" fxLayout="row" fxLayoutGap="65px" fxLayoutGap.xs="15px" fxLayout.xs="column" class="form-tipo-usuario">
        <mat-checkbox
          required
          color="primary"
          [checked]="producto.es_vigente"
          (change)="producto.es_vigente=!producto.es_vigente"
          [disabled]="datoPasado.operacion==SistOperation.VER"
          >Producto vigente</mat-checkbox>        
        <mat-checkbox *ngIf="producto.tipo_producto!==ListaTipoProducto.listaTipoProducto[6].codigo_producto && 
        !producto.tiene_subproductos"
          required
          color="primary"
          [checked]="producto.tiene_caracteristicas"
          (change)="AniadirCaracteristicas()"
          [disabled]="datoPasado.operacion==SistOperation.VER"
          >Incluir características</mat-checkbox>
      </div>

      <div *ngIf="producto.lista_caracteristicas!=undefined && producto.lista_caracteristicas.length>0" >
        <mat-divider></mat-divider>
        <div style="margin-top: 10px;" *ngIf="datoPasado.operacion!=SistOperation.VER" >
          <button mat-mini-fab color="primary" type="button" (click)="AniadirCaracteristicasBtn()"
            matTooltip="Añadir Características" matTooltipPosition="after">
            <mat-icon>{{Icons.ADD}}
            </mat-icon>
          </button>
        </div>
        <mat-form-field style="margin-top: 20px; width: 100%;">
          <mat-label>Características Añadidas</mat-label>
          <mat-chip-list *ngFor="let caract of producto.lista_caracteristicas; let i=index">
            <mat-chip [removable]="datoPasado.operacion!=SistOperation.VER" (removed)="removerCaracteristica(i)"
            >{{caract}}
            <button matChipRemove>
              <mat-icon>{{Icons.CANCEL}}</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
        </mat-form-field>
        <mat-divider></mat-divider>
      </div>


      <div style="margin-top: 20px;" fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" class="form-tipo-usuario">
        <mat-form-field class="text-box-multi">
          <mat-label>Descripción</mat-label>
          <textarea matInput placeholder="Los sabores de este ...."
          [disabled]="datoPasado.operacion==SistOperation.VER"
          ngModel
          [(ngModel)]="producto.descripcion_producto"
          [pattern]="PatternsRegex.onlyGoodCharsWithNumbersAndSpace"
          required
          name="descripcion"
          #descripcion="ngModel"
          [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
          [maxlength]="Length_Database.DB_LENGTH_LARGE_MAX_250"
          ></textarea>
          <mat-error *ngIf="descripcion.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error
            *ngIf="descripcion.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
          <mat-error
            *ngIf="descripcion.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
          <mat-error
            *ngIf="descripcion.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" class="form-tipo-usuario">
        <app-upload
        [multipleFiles]="true"
        [requiredFileType]="fileType"        
        [label]="label"
        [max_archivos]="3"
        [max_size_file]="500000"
        [max_size_width_height]="sizeMaxHeightWidth"
        [disabled]="datoPasado.operacion==SistOperation.VER"
        [disabled_add]="datoPasado.operacion==SistOperation.VER"
        [is_edit]="esModoEdicion"
        [is_view]="esModoVer"
        [images_uploaded]="files_uploaded"
        >
      </app-upload>
      </div>
      <mat-divider style="margin-bottom: 20px; margin-top: 20px;" *ngIf="!esModoVer" ></mat-divider>
      <mat-card-actions fxLayout="row" fxLayoutAlign="space-around center" *ngIf="!esModoVer" >
        <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="(f.invalid)"
          [attr_type]="'submit'"></app-button>
        <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" color="gray"
          (click)="Limpiar(f)"></app-button>
        <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING"
          (click)="Limpiar(f)"></app-button>
      </mat-card-actions>
    </form>
  </section>
  <section  *ngIf="isLoading">
    <app-spinner></app-spinner>
  </section>
</mat-dialog-content>
