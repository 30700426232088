import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { Length_Database, Msjs_Validations, PatternsRegex } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { Observable, of } from 'rxjs';
//import { IProductoAnidado } from '../../../procesos/dialog/models/IProductoAnidado';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UIService } from 'src/app/shared/services/ui.services';
import { Catalogo } from '../../../procesos/dialog/models/Catalogo';
import { debounceTime, filter, startWith, switchMap } from 'rxjs/operators';
import { IProducto } from '../../../procesos/dialog/models/IProducto';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-buscar-producto-into',
  templateUrl: './buscar-producto-into.component.html',
  styleUrls: ['./buscar-producto-into.component.scss']
})
@AutoUnsubscribe

export class BuscarProductoIntoComponent implements OnInit {

  readonly Icons: typeof Icons = Icons;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
 
  txtComplete = new FormControl('');
  filteredOptions!: Observable<IProducto[]>;
  catalogo = new Catalogo();
  producto:string='';


  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef: MatDialogRef<BuscarProductoIntoComponent>,
    private uiService:UIService,
  ) { }

  ngOnInit(): void {
    this.catalogo = this.datoPasado.catalogo;
    this.filteredOptions = this.txtComplete.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      filter(value => typeof value === 'string' && value.length >= 3), // Filtro para la longitud mínima de caracteres
      switchMap(value => {
        if (value !== '') {
          return this.buscarProductoInto(value);          
        } else {
          return [];
        }
      })
    );
  }

  buscarProductoInto(value:string):Observable<IProducto[]>{    
    let listaProductos: IProducto[]=[];
    let existe:string[] = new Array();
    for(let index=0; index<this.catalogo.lista_productos.length; index++){
      existe= new Array();
      const valor = Utils.CadenaToArray(value);
      existe = this.catalogo.lista_productos[index].nombres_completo.filter(palabra => valor.includes(palabra));
      if(existe.length>0){
        listaProductos.push(this.catalogo.lista_productos[index]);
      }     
    }
    return of(listaProductos);
  }

  
  onClose(){
    this.dialogRef.close();
  }
  onSubmit(f:NgForm){}
  onSelect(producto:IProducto){
    this.dialogRef.close(producto);
  }




}
