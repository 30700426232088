export enum ProcesoType{
  INICIALIZAR="inicializar",
  CAMBIAR_MONTO_INICIAL="Gestionar Caja - Turno: Cambiar Monto Inicial",
  CAMBIAR_ANADIR_MONTO="Gestionar Caja - Turno: Añadir Monto",
  CAMBIAR_IMPRIMIR_ANADIR="Gestionar Caja - Turno: Imprimir Monto Añadido",
  CAMBIAR_RETIRAR_MONTO="Gestionar Caja - Turno: Retirar Monto",
  CAMBIAR_IMPRIMIR_RETIRAR="Gestionar Caja - Turno: Imprimir Monto Retirado",
  //pedido
  CAMBIAR_VARIAR_MESA="Gestionar Pedido - Pedido: Modificar Mesa (Pedido)",
  VARIAR_PRODUCTO="Gestionar Pedido - Pedido: Modificar Producto (Pedido)",
  TERMINO_RAPIDO="Finalizado rápidamente",
  CERRAR_PEDIDO="Cerrar pedido",
  REABRIR_PEDIDO="Reabrir pedido",
  ACEPTAR_REABRIR="Aceptar reabrir",
  RECHAZAR_REABRIR="Rechazar reabrir",
  ASIGNAR_INCIDENCIA="Asignar incidencia",
  QUITAR_INCIDENCIA="Quitar incidencia",
  ASIGNAR_DESCUENTO="Asignar descuento",
  QUITAR_DESCUENTO="Quitar descuento",
  TRASPASO_PRODUCTO_ORIGEN="Traspaso producto origen",
  TRASPASO_PRODUCTO_DESTINO="Traspaso producto destino",
  DIVIDIR_CUENTA_DESTINO="Dividir cuenta destino",
  DIVIDIR_CUENTA_ORIGEN="Dividir cuenta origen",

}
