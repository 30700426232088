import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Icons } from 'src/app/shared/cons/icons';
import { IProductoTicket } from '../../models/IProductoTicket';
import { IProductoAnidado } from '../../../procesos/dialog/models/IProductoAnidado';

@Component({
  selector: 'app-cantidad',
  templateUrl: './cantidad.component.html',
  styleUrls: ['./cantidad.component.scss']
})
export class CantidadComponent implements OnInit {
  CLOSE: string = Icons.CLOSE;
  readonly Icons: typeof Icons = Icons;
  cantidad:number=1;
  producto:string='';
  esEditarProducto: boolean = false;  
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado: any,
    private dialogRef: MatDialogRef<CantidadComponent>,
  ) { }

  ngOnInit(): void {
    //formulario registrar producto//autocomplete de buscar producto y setear la cantidad
    if(this.datoPasado.dialog=='registrar_producto'){
      const producto = (this.datoPasado.producto) as IProductoAnidado;
      this.cantidad = producto.cantidad?producto.cantidad:1;
      this.producto = producto.producto.nombre_producto;
    }
    //formulario registrar pedido
    else if(this.datoPasado.dialog=='registrar_pedido'){
      const producto = (this.datoPasado.producto) as IProductoTicket;
      this.cantidad = producto.cantidad_producto;
      this.producto = producto.nombre_producto;
    }  
    this.esEditarProducto = this.datoPasado.esEditarProducto;    
  }

  onClose(): void {
    this.dialogRef.close(this.cantidad);
    
  }
  operar(operacion:boolean){
    if(operacion){
      this.cantidad +=1;
    }else{
      if(this.cantidad>1){
        this.cantidad -=1;
      }      
    }

  }


}
