<div fxLayout="row" fxLayoutAlign="space-between start" class="header-dialog" >
  <div mat-dialog-title fxLayout="row" class="dialog-title" fxLayout.xs="column" >
    <div class="title-number" >
      1
    </div>
    <div class="title-tipo1" >Datos Personales</div>
    <div class="title-tipo2">
      (Administrador de Plataforma)
    </div>
  </div>

  <app-button type="icon" color="gray" [icon_fig]="CLOSE" ></app-button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content >
  <section>
    <form fxLayout="column" #f="ngForm" (ngSubmit)="onSubmit(f)" >
      <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="5px" >
        <mat-form-field color="accent" >
          <input
            type="text"
            matInput
            placeholder="Nombres"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
            [maxlength]="Length_Database.DB_LENGTH_SMALL_MAX_50"
            ngModel
            name="nombres"
            #nombres="ngModel"
            [pattern] ="PatternsRegex.onlyCharsTwoWordsSpanish"
            required >
            <mat-error *ngIf="nombres.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="nombres.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_2PALABRAS}}</mat-error>
            <mat-error *ngIf="nombres.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
            <mat-error *ngIf="nombres.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            type="text"
            matInput
            placeholder="Apellido Paterno"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
            [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
            [pattern] ="PatternsRegex.onlyCharsOneWordsSpanish"
            ngModel
            name="apellido_paterno"
            #apellido_paterno="ngModel"
            required >
            <mat-error *ngIf="apellido_paterno.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="apellido_paterno.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_1PALABRA}}</mat-error>
            <mat-error *ngIf="apellido_paterno.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
            <mat-error *ngIf="apellido_paterno.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column"  fxLayoutGap.xs="0px">
        <mat-form-field>
          <input
            type="text"
            matInput
            placeholder="Apellido Materno"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
            [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
            [pattern] ="PatternsRegex.onlyCharsOneWordsSpanish"
            ngModel
            name="apellido_materno"
            #apellido_materno="ngModel"
            required >
            <mat-error *ngIf="apellido_materno.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="apellido_materno.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_1PALABRA}}</mat-error>
            <mat-error *ngIf="apellido_materno.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
            <mat-error *ngIf="apellido_materno.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Sexo</mat-label>
          <mat-select
            ngModel
            name="sexo"
            placeholder="Seleccione"
            #sexo="ngModel"
            required >
              <mat-option *ngFor="let opcion of SEXO" [value]="opcion">{{opcion}}</mat-option>
          </mat-select>
          <mat-error *ngIf="sexo.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column"  fxLayoutGap.xs="0px">
        <mat-form-field>
          <mat-label>Tipo Documento</mat-label>
          <mat-select
            ngModel
            name="tipo_documento"
            placeholder="Seleccione"
            #tipo_documento="ngModel"
            required >
            <mat-option *ngFor="let opcion of TIPO_DOCUMENTO" [value]="opcion" (click)="selectTipoDocumento(opcion)" >{{opcion}}</mat-option>
          </mat-select>
          <mat-error *ngIf="tipo_documento.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            type="text"
            matInput
            placeholder="Número Documento"
            [pattern] ="PatternsRegex.onlyIntegerUnsigned"
            [minlength]="tipoDocumento==TIPO_DOCUMENTO[0]? Length_Database.DB_LENGTH_X_SMALL_MIN_8: Length_Database.DB_LENGTH_X_SMALL_MIN_10"
            [maxlength]="tipoDocumento==TIPO_DOCUMENTO[0]? Length_Database.DB_LENGTH_X_SMALL_MIN_8: Length_Database.DB_LENGTH_X_SMALL_MIN_10"
            ngModel
            name="numero_documento"
            #numero_documento="ngModel"
            required >
            <mat-error *ngIf="numero_documento.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="numero_documento.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
            <mat-error *ngIf="numero_documento.hasError('minlength')&& (tipoDocumento==TIPO_DOCUMENTO[0])">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8DIGITOS}}</mat-error>
            <mat-error *ngIf="numero_documento.hasError('maxlength')&& (tipoDocumento==TIPO_DOCUMENTO[0])">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_8DIGITOS}}</mat-error>
            <mat-error *ngIf="numero_documento.hasError('minlength')&& (tipoDocumento==TIPO_DOCUMENTO[1])">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_10DIGITOS}}</mat-error>
            <mat-error *ngIf="numero_documento.hasError('maxlength')&& (tipoDocumento==TIPO_DOCUMENTO[1])">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_10DIGITOS}}</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column"  fxLayoutGap.xs="0px">
        <mat-form-field>
          <input
            type="text"
            matInput
            placeholder="Dirección (denominación y núm.) "
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
            [maxlength]="Length_Database.DB_LENGTH_MEDIUM_MAX_100"
            ngModel
            name="direccion"
            #direccion="ngModel"
            required >
            <mat-error *ngIf="direccion.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="direccion.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
            <mat-error *ngIf="direccion.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_MEDIUM_MAX_100}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="Seleccione su fecha de nacimiento"
            [max]="maxDate"
            [min]="minDate"
            ngModel
            name="fecha_nacimiento"
            [matDatepicker]="picker"
            #fecha_nacimiento="ngModel"
            disabled
            required >
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker disabled="false"></mat-datepicker>
          <mat-error *ngIf="fecha_nacimiento.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column"  fxLayoutGap.xs="0px">
        <mat-form-field>
          <input
            type="email"
            matInput
            [maxlength]="Length_Database.DB_LENGTH_SMALL_MAX_50"
            [pattern] ="PatternsRegex.onlyEmail"
            placeholder="Correo Electrónico (nombre de usuario)"
            #correo_electronico="ngModel"
            ngModel
            name="correo_electronico"
            required >
            <mat-error *ngIf="correo_electronico.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="correo_electronico.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_EMAIL}}</mat-error>
            <mat-error *ngIf="correo_electronico.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            type="text"
            matInput
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_9"
            [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_9"
            placeholder="Teléfono"
            [pattern] ="PatternsRegex.onlyNumberPhone"
            ngModel
            name="telefono"
            #telefono="ngModel"
            required >
            <mat-error *ngIf="telefono.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="telefono.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_REQ9_DIGITOS_MOVIL}}</mat-error>
            <mat-error *ngIf="telefono.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_MIN9_DIGITOS}}</mat-error>
            <mat-error *ngIf="telefono.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_MAX9_DIGITOS}}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column"  fxLayoutGap.xs="0px">
        <mat-form-field hintLabel="Departamento de residencia" >
          <mat-label>Departamento</mat-label>
          <mat-select
          ngModel name="codigo_departamento"
          placeholder="Seleccione"
          required
          #codigo_departamento="ngModel"
          >
            <mat-option
              *ngFor="let departamento of lDepartamento"
              [value]="departamento.codigoID"
              (click)="selectProvincia(departamento.codigoID, f)" >{{departamento.nombre}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="codigo_departamento.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Provincia</mat-label>
          <mat-select
            ngModel
            name="codigo_provincia"
            placeholder="Seleccione"
            required
            #codigo_provincia="ngModel"
            >
              <mat-option
                *ngFor="let provincia of lProvincia"
                [value]="provincia.codProvincia"
                (click)="selectDistrito(provincia.codProvincia, f)">
                {{provincia.provincia}}
              </mat-option>
          </mat-select>
          <mat-error *ngIf="codigo_provincia.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Distrito</mat-label>
          <mat-select
            ngModel
            name="codigo_distrito"
            placeholder="Seleccione"
            #codigo_distrito="ngModel"
            required>
              <mat-option
                *ngFor="let distrito of lDistrito"
                [value]="distrito.codProvincia">
                {{distrito.distrito}}
              </mat-option>
          </mat-select>
          <mat-error *ngIf="codigo_distrito.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column"  fxLayoutGap.xs="0px">
        <mat-form-field [hintLabel]="Msjs_Validations.MSJ_HINT_PASSWORD" >
          <input
            type="password"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8"
            [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
            [pattern]="PatternsRegex.onlyPassword"
            matInput
            placeholder="Contraseña"
            ngModel
            name="password"
            #password="ngModel"
            required >
            <mat-error *ngIf="password.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="password.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_PASSWORD}}</mat-error>
            <mat-error *ngIf="password.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8}}</mat-error>
            <mat-error *ngIf="password.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
        </mat-form-field>
        <mat-form-field [hintLabel]="Msjs_Validations.MSJ_HINT_PASSWORD_IGUAL">
          <input
            type="password"
            matInput
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8"
            [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
            [pattern]="PatternsRegex.onlyPassword"
            placeholder="Repita la contraseña"
            ngModel
            name="password2"
            #password2="ngModel"
            required >
            <mat-error *ngIf="password2.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="password2.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_PASSWORD}}</mat-error>
            <mat-error *ngIf="password2.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8}}</mat-error>
            <mat-error *ngIf="password2.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
        </mat-form-field>
      </div>

      <div class="actions-dialog" fxLayout="row" fxLayoutAlign="space-between end" fxFlex>
        <app-button [width]="100" text="Registrarme" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="f.invalid" [attr_type]="'submit'"  ></app-button>
        <app-button fxHide.xs type="basic" color="blueA200" [icon_fig]="CLEAN" ></app-button>
        <app-button fxShow.xs fxHide  type="outline" color="blueA200-b0" [icon_fig]="CLEAN" ></app-button>
        <app-button fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray"></app-button>
        <app-button fxShow.xs fxHide  type="outline" color="gray-b0" [icon_fig]="CANCEL" ></app-button>
      </div>
    </form>
  </section>
</mat-dialog-content>
