<mat-nav-list>

  <!-- menu siempre visible -->
  <mat-list-item
    routerLink="menu_permanente[0].link"
    [class.is-active]='menu_permanente[0].active'
    (click)="onClose(menu_permanente[0])">
    <mat-icon mat-list-icon [fontIcon]="menu_permanente[0].icono">{{menu_permanente[0].icono}}</mat-icon>
    <span>{{menu_permanente[0].nombre}}</span>
  </mat-list-item>
  <mat-list-item
    *ngIf="esAdministrador"
    routerLink="menu_permanente[1].link"
    [class.is-active]='menu_permanente[1].active'
    (click)="onClose(menu_permanente[1])">
    <mat-icon mat-list-icon [fontIcon]="menu_permanente[1].icono">{{menu_permanente[1].icono}}</mat-icon>
    <span>{{menu_permanente[1].nombre}}</span>
  </mat-list-item>
  <mat-divider></mat-divider>

  <!-- menu dinamico -->
  <ng-container *ngFor="let modulo of modulos_distintos; let i = index">
    <mat-expansion-panel [hideToggle]="false" [expanded]="i==0?true:false" >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="titulo-accordeon">{{modulo}}</span>
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let opcion of list" >
        <mat-list-item
        [routerLink]="opcion.link"
        [class.is-active]='opcion.active'
        (click)="onClose(opcion)"
        *ngIf="opcion.modulo == modulo"
        >
        <mat-icon mat-list-icon [fontIcon]="opcion.icono">{{opcion.icono}}</mat-icon>
        <span>{{opcion.nombre}}</span>
      </mat-list-item>
      </ng-container>
    </mat-expansion-panel>
  </ng-container>


</mat-nav-list>
