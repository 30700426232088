<div fxLayout="row" fxLayoutAlign="center center">
    <mat-card fxFlex="80%" fxFlex.xs="100%">
        <mat-card-header>
            <mat-card-title>Modo de Operación</mat-card-title>
            <mat-card-subtitle>Si activa esta opción individualizará los pedidos por cada gestor de mesa a excepción de
                los administradores que visualizarán todos los pedidos. </mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <form #f="ngForm" (ngSubmit)="onSubmit(f)">
            <section *ngIf="!isLoading">
                <mat-card-content fxLayout="column" fxLayoutAlign="center center" style="margin-top: 20px;"
                    fxLayoutGap="10px">
                    <mat-slide-toggle [disabled]="!esEditar" (change)="changeModoOperacion()" [checked]="modoOp.es_individualizado"
                        color="primary" class="example-margin">
                        Individualizar pedidos
                    </mat-slide-toggle>
                </mat-card-content>
                <mat-card-actions *ngIf="!esEditar" fxLayout="row" fxLayoutAlign="end center" [ngStyle.lt-md]="{}"
                    [ngStyle.gt-md]="{'margin-left': '35%', 'margin-right': '35%'}">
                    <app-button type="icon" color="gray" [icon_fig]="Icons.EDIT" (click)="editarModoOperacion()"
                        matTooltip="Editar" matTooltipPosition="above"></app-button>
                </mat-card-actions>
                <mat-card-actions fxLayout="row" fxLayoutAlign="space-around center" *ngIf="esEditar" [ngStyle.lt-md]="{}"
                    [ngStyle.gt-md]="{'margin-left': '35%', 'margin-right': '35%'}">
                    <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="(f.invalid)"
                        [attr_type]="'submit'"></app-button>
                    <app-button fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray"
                        (click)="Limpiar(f)"></app-button>
                    <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CANCEL"
                        (click)="Limpiar(f)"></app-button>
                </mat-card-actions>
            </section>
            <section *ngIf="isLoading">
                <app-spinner></app-spinner>
              </section>
        </form>
    </mat-card>
</div>