import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideMail'
})
export class HideMailPipe implements PipeTransform {

  transform(value: string | undefined ): string | null {
    if(value==undefined){
      return null;
    }
    if (!value || value.indexOf('@') === -1) {
      return value;
    }

    return value.split('@')[0];
  }
}
