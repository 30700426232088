<div fxLayout="row" fxLayoutAlign="space-between start" style="min-width: 250px;">
  <div mat-dialog-title class="dialog-title">{{titleModal}}</div>
  <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()" [attr_disabled]="isLoading" ></app-button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content style="min-width: 240px; max-width: 700px;">
  <section>
    <form #f="ngForm" (ngSubmit)="onSubmit(f)">
      <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" class="form-tipo-usuario">
        <mat-form-field fxFlex='50%' fxFlex.xs="100%" >
          <input type="text" matInput
          placeholder="Nombre"
          required
          name="nombre"
          ngModel
          [(ngModel)]="catalogo.nombre_catalogo"
          [pattern]="PatternsRegex.onlyGoodCharsWithNumbersAndSpace"
          #nombreInput="ngModel"
          [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
          [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
          oninput="this.value = this.value.toUpperCase()"
          [disabled]="datoPasado.operacion==SistOperation.VER"
          (blur)="generarCodigoCatalogo()"
          >
          <mat-error *ngIf="nombreInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error
            *ngIf="nombreInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
          <mat-error
            *ngIf="nombreInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
          <mat-error
            *ngIf="nombreInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex='50%' fxFlex.xs="100%">
          <input type="text" matInput
          placeholder="Código"
          required
          name="codigo"
          ngModel
          [(ngModel)]="catalogo.codigo_catalogo"
          [pattern]="PatternsRegex.onlyGoodCharsWithNumbers"
          #codigoInput="ngModel"
          [maxlength]="Length_Database.DB_LENGTH_X_SMALL_6"
          [minlength]="Length_Database.DB_LENGTH_X_SMALL_6"
          oninput="this.value = this.value.toUpperCase()"
          [disabled]="(datoPasado.operacion==SistOperation.VER) || (datoPasado.operacion==SistOperation.EDITAR)"
          >
          <mat-error *ngIf="nombreInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error
            *ngIf="nombreInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
          <mat-error
            *ngIf="nombreInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_6}}</mat-error>
          <mat-error
            *ngIf="nombreInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_6}}</mat-error>
        </mat-form-field>
      </div>
      <div style="margin-top: 20px;" fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" >
        <mat-form-field fxFlex="100%">
          <mat-label>Descripción</mat-label>
          <textarea matInput placeholder="Catalogo de productos de...."
          [disabled]="datoPasado.operacion==SistOperation.VER"
          ngModel
          [pattern]="PatternsRegex.onlyGoodCharsWithNumbersAndSpace"
          required
          [(ngModel)]="catalogo.descripcion_catalogo"
          name="descripcion"
          #descripcion="ngModel"
          [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
          [maxlength]="Length_Database.DB_LENGTH_LARGE_MAX_250"
          ></textarea>
          <mat-error *ngIf="descripcion.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error
            *ngIf="descripcion.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
          <mat-error
            *ngIf="descripcion.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
          <mat-error
            *ngIf="descripcion.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
        </mat-form-field>
      </div>
      <div style="margin-top: 20px; margin-bottom: 20px;">
        <mat-checkbox
          required
          color="primary"
          [checked]="catalogo.es_vigente"
          (change)="catalogo.es_vigente=!catalogo.es_vigente"
          [disabled]="datoPasado.operacion==SistOperation.VER"
          >Vigente</mat-checkbox>
      </div>

      <mat-accordion displayMode="default" [multi]="true" [hideToggle]="false">
        <mat-expansion-panel [expanded]="isExpandedCatalogoProducto">
          <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
            <mat-panel-title>
              <mat-icon>{{Icons.CHECK}}</mat-icon>
              <span class="titulo-accordeon"> ({{catalogo.lista_productos.length}}) Productos De Catálogo</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="5px" fxLayoutGap.xs="2px">
            <mat-chip-list *ngFor="let producto of catalogo.lista_productos" class="mat-chip-element" >
              <mat-chip [removable]="datoPasado.operacion!=SistOperation.VER" (removed)="removerProducto(producto)" [matTooltip]="producto.es_vigente? producto.nombre_producto +' | Estado: Activo ': producto.nombre_producto+' | Estado: Inactivo' "
              >{{producto.nombre_producto | sizeLabel:15 }} | {{producto.codigo_producto}}
                <button matChipRemove>
                  <mat-icon>{{Icons.CANCEL}}</mat-icon>
                </button>
              </mat-chip>
            </mat-chip-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-divider *ngIf="datoPasado.operacion!=SistOperation.VER" style="margin-bottom: 20px; margin-top: 40px;"></mat-divider>

      <mat-accordion *ngIf="datoPasado.operacion!=SistOperation.VER"  displayMode="default" [multi]="true" [hideToggle]="false">
        <mat-expansion-panel [expanded]="isExpanded" >
          <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
            <mat-panel-title>
              <mat-icon>{{Icons.SEARCH}}</mat-icon>
              <span class="titulo-accordeon">Búsqueda</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-buscar [busqueda]="buscar" ></app-buscar>
        </mat-expansion-panel>
      </mat-accordion>
      <div *ngIf="datoPasado.operacion!=SistOperation.VER"  class="container-table">
        <app-table [dataTable]="dataTable" [key]="tipoTabla"></app-table>
      </div>

      <mat-divider style="margin-bottom: 20px; margin-top: 20px;" *ngIf="!esModoVer" ></mat-divider>
      <mat-card-actions fxLayout="row" fxLayoutAlign="space-around center" *ngIf="!esModoVer" >
        <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="(f.invalid)"
          [attr_type]="'submit'"></app-button>
        <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" color="gray"
          (click)="Limpiar(f)"></app-button>
        <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING"
          (click)="Limpiar(f)"></app-button>
      </mat-card-actions>

    </form>
  </section>
  <section  *ngIf="isLoading">
    <app-spinner></app-spinner>
  </section>
</mat-dialog-content>
