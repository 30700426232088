import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { map, switchMap } from "rxjs/operators";
import { colections, documentRecibo } from "src/app/shared/cons/db.colections";
import { Utils } from "src/app/shared/helpers/utils";
import { Facturacion } from "../../procesos/gestion-colaborador/models/facturacion.model";
import { BehaviorSubject, from, Observable, of, Subscription } from "rxjs";
import { TransaccionModel } from "src/app/shared/services/models/trasaccion.model";
import { AutoUnsubscribe } from "src/app/shared/helpers/decorators/AutoUnsubscribe";
import { IRecibo } from "../../procesos/gestion-colaborador/models/IRecibo";

@Injectable({
  providedIn: 'root',
})
@AutoUnsubscribe
export class FacturacionDAService {
  transaccion: TransaccionModel = new TransaccionModel();
  private facturacionCache: BehaviorSubject<Facturacion | null | undefined> =
    new BehaviorSubject<Facturacion | null | undefined>(null);
  public facturacion$: Observable<Facturacion | null | undefined> =
    this.facturacionCache.asObservable();
  private fetchFacturacionSubscription!: Subscription;

  constructor(private db: AngularFirestore) {}

  //cacheando
  fetchFacturacionById(idEmpresa: string): void {
    if (
      this.fetchFacturacionSubscription &&
      !this.fetchFacturacionSubscription.closed
    ) {
      this.facturacionCache.next(this.facturacionCache.value);
      return;
    }
    // if(this.fetchFacturacionSubscription){
    //   this.fetchFacturacionSubscription.unsubscribe();
    // }
    this.fetchFacturacionSubscription = this.getFacturacionSnapshot(
      idEmpresa
    ).subscribe((facturacion) => {
      this.facturacionCache.next(facturacion);
    });
  }

  stopFetchingFacturacion(): void {
    if (this.fetchFacturacionSubscription) {
      this.fetchFacturacionSubscription.unsubscribe();
    }
    this.facturacionCache.next(undefined);
  }

  private getFacturacionSnapshot(
    idEmpresa: string
  ): Observable<Facturacion | null> {
    return this.db
      .collection(colections.EMPRESA)
      .doc(idEmpresa)
      .collection(colections.FACTURACION)
      .snapshotChanges()
      .pipe(
        map((facturacion) => {
          if (facturacion.length > 0) {
            const datos = Utils.convertDate(
              facturacion[0].payload.doc.data()
            ) as Facturacion;
            const id = facturacion[0].payload.doc.id;
            const data = { id, ...datos } as Facturacion;
            return data;
          } else {
            return null;
          }
        })
      );
  }

  generarFechaFormateadoDDMMYYY( fechaMarcaTiempo:any){
    const date = new Date(fechaMarcaTiempo.seconds*1000);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;

  }

  // async getRecibo(idEmpresa: string) {
  //   const refEmpresa = this.db.collection(colections.EMPRESA).doc(idEmpresa);
  //   const refFactura = await refEmpresa.collection(colections.FACTURACION).get().toPromise();
  //   let reciboRet:IRecibo | null = null;
  //   if (!refFactura.empty) {
  //     const docFactura = refFactura.docs[0].data() as Facturacion;
  //     docFactura.id = refFactura.docs[0].id;
  //     return refEmpresa.collection(colections.FACTURACION).doc(docFactura.id)
  //       .collection(colections.RECIBO, (ref) => ref.where(documentRecibo.PENDIENTE_PAGO, '==', true)).snapshotChanges()
  //       .pipe(
  //         map((listaRecibos)=>{
  //           if(listaRecibos.length>0){
  //             reciboRet = listaRecibos[0].payload.doc.data() as IRecibo;
  //             reciboRet.id = listaRecibos[0].payload.doc.id;
  //             reciboRet.fecha_calculo_formateado = this.generarFechaFormateadoDDMMYYY(reciboRet.fecha_calculo);
  //             reciboRet.ultimo_dia_pago_formateado = this.generarFechaFormateadoDDMMYYY(reciboRet.ultimo_dia_pago);
  //             return reciboRet;
  //           }else{
  //             return reciboRet;
  //           }
  //         })
  //       )    

  //   }else{
  //     return  null;
  //   }
  // }

  getRecibo(idEmpresa: string): Observable<IRecibo | null> {
    const refEmpresa = this.db.collection(colections.EMPRESA).doc(idEmpresa);
  
    return from(refEmpresa.collection(colections.FACTURACION).get().toPromise()).pipe(
      switchMap(refFactura => {
        if (!refFactura.empty) {
          const docFactura = refFactura.docs[0].data() as Facturacion;
          docFactura.id = refFactura.docs[0].id;
  
          return refEmpresa.collection(colections.FACTURACION).doc(docFactura.id)
            .collection(colections.RECIBO, ref => ref.where(documentRecibo.PENDIENTE_PAGO, '==', true)).snapshotChanges()
            .pipe(
              map(listaRecibos => {
                if (listaRecibos.length > 0) {
                  const reciboRet = listaRecibos[0].payload.doc.data() as IRecibo;
                  reciboRet.id = listaRecibos[0].payload.doc.id;
                  reciboRet.fecha_calculo_formateado = this.generarFechaFormateadoDDMMYYY(reciboRet.fecha_calculo);
                  reciboRet.ultimo_dia_pago_formateado = this.generarFechaFormateadoDDMMYYY(reciboRet.ultimo_dia_pago);
                  return reciboRet;
                } else {
                  return null;
                }
              })
            );
        } else {
          return of(null);
        }
      })
    );
  }
  
}
