<div *ngIf="isLoading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div *ngIf="!isLoading" style="padding-bottom: 10px;">
  <mat-accordion displayMode="default" [multi]="true" [hideToggle]="false">
    <mat-expansion-panel *ngFor="let ambiente of ambientesUnicos;" [expanded]="isExpanded" >
      <mat-expansion-panel-header>
        <mat-panel-title fxLayoutGap="10px">
          <mat-icon>{{Icons.DECK}}</mat-icon>
          <span class="titulo-accordeon">{{ambiente.toUpperCase()}}</span>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="5px" style="padding-bottom: 5px;">
        <ng-container *ngFor="let mesa of configuracionAmbiente">
          <button mat-fab color="primary" type="button"
          *ngIf="mesa.ambiente== ambiente"
          matTooltipPosition="above"
          [ngClass]="mesa.mesa_estado==EstadosMesaType.DISPONIBLE ? 'button-after-click':'button-before-click'"
          (click)="seleccionarMesa(mesa)"
          [matTooltip]="(mesa.mesa_estado.replace('_',' ') | titlecase) +' | ' + (mesa.usuario_modificacion? (mesa.usuario_modificacion | hideMail):'')"
          [disabled]="
          (mesa.mesa_estado!= EstadosMesaType.DISPONIBLE  && 
          usuario.correo_electronico!==mesa.usuario_modificacion && !esAdministrador) ||       
          (mesa.mesa_estado== EstadosMesaType.OCUPADO) ||
          (mesa.mesa_estado== EstadosMesaType.RESERVADO && !esAdministrador)
          "
          >
          <mat-icon>{{Icons.TABLE_BAR}}</mat-icon>
          {{mesa.mesa_numero}}
        </button>
        </ng-container>        
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
