import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Length_Database, Msjs, Msjs_Validations, PatternsRegex } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { GestionColaboradorBlService } from '../../procesos/gestion-colaborador/services/services.bl/gestion-colaborador.bl.service';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { UIService } from 'src/app/shared/services/ui.services';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { CColaborador } from '../../procesos/gestion-colaborador/models/ccolaborador.model';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-verificar-password',
  templateUrl: './verificar-password.component.html',
  styleUrls: ['./verificar-password.component.scss']
})
export class VerificarPasswordComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  readonly Length_Database: typeof Length_Database = Length_Database;
  errorPassword:boolean = false;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  passwordText:string='';
  hide:boolean = true;
  hide2:boolean = true;
  hide3:boolean = true;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  isLoading:boolean=false;
  isLoadin2:boolean=false;
  validacionOK:boolean = false;
  errorContrasenia2:boolean = false;
  nuevoPassword:string=''
  contrasenia2:string=''
  title:string='Validar Identidad';
  valorInicial:number=2;
  contador:number =0;
  subtitleInicial:string ='Para continuar es necesario validar tu identidad, ingresa tu contraseña de ADMINISTRADOR, tienes {0} INTENTOS, en caso de error serás redirigido a la página de inicio para que te autentiques.';
  msj:string=''

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef: MatDialogRef<VerificarPasswordComponent>,
    private gestionColaboradorBlService: GestionColaboradorBlService,
    private registroService: RegistroService,
    private uiService:UIService,
  ) { }


  ngOnInit(): void {
    this.calcularIntentos();

  }

  comprobarPassword(f: NgForm) {
    this.contador +=1;
    this.isLoading = true;
    this.gestionColaboradorBlService.validarPasswordAdminitradorEmpresa(this.passwordText).then(resultado => {
      this.isLoading = false;
      if(resultado?.tx){
        if (this.registroService.usuario.id_usuario_cuenta == resultado?.data?.user.uid) {
          this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_VALIDACION_COMPLETA, 3000);
          this.validacionOK = true;
          this.title = 'Nueva contraseña.'
        }

      }else{
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_LOGIN_ERROR_PASSWORD, 3000);
        this.calcularIntentos();
        if(this.contador>=2){
          this.uiService.closeodalVerificarContraseñaAdmin();
          this.gestionColaboradorBlService.cerrarSesionAdministrador();

        }

      }
    }).catch((e) => {
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);
    });
  }

  calcularIntentos(){
    this.valorInicial = this.valorInicial - this.contador;
    this.msj = Utils.formatString(this.subtitleInicial,[this.valorInicial.toString()])
  }

  registrarNuevoPassword(g:NgForm){

    if(this.errorContrasenia2){
      return;
    }
    if(g.valid){
      this.isLoadin2 = true;
      const colaborador = this.datoPasado.data as CColaborador;
      this.gestionColaboradorBlService.cambiarContraseniaColaborador(colaborador,this.nuevoPassword).then(result=>{
        if(result.tx){
          this.isLoadin2 = false;
          this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
          this.uiService.closeodalVerificarContraseñaAdmin();
        }
        else{
          this.isLoadin2 = false;
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);
        }
      })


    }

  }

  validarPassword(){
    this.errorContrasenia2 =false
    if(this.nuevoPassword!=this.contrasenia2){
      this.errorContrasenia2 =true;
    }
  }

}
