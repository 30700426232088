<div fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title>
    <mat-icon class="icon" >{{Icons.QUESTION_MARK}}</mat-icon>
    {{titleModal}}
  </div>
  <!-- <app-button type="icon" color="gray" [icon_fig]="CLOSE" (click)="onClose()" ></app-button> -->
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="dialog-content">
  <section>
    <h3>{{message}} <span *ngIf="tieneCodigo"> <em><b>{{codigo}}</b></em></span></h3>
  </section>
  <div *ngIf="tieneCodigo">
    <mat-form-field appearance="outline">
      <input matInput
      type="text"
      required
      [placeholder]="codigo"
      name="codigo"
      #codigoInput="ngModel"
      [(ngModel)]="codigoIngresado"
      [maxlength]="Length_Database.DB_LENGTH_X_SMALL_6"
      [minlength]="Length_Database.DB_LENGTH_X_SMALL_6"
      (blur)="comprobarCodigo()"
      >
      <mat-error *ngIf="esError">{{Msjs_Validations.MSJ_HINT_CODIGO_IGUAL}}</mat-error>
      <mat-icon matSuffix>{{Icons.VPN_KEY}}</mat-icon>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancelar</button>
  <!-- cuando trabaja sin esperar accion en el input -->
  <button *ngIf="!tieneCodigo" mat-button [mat-dialog-close]="true" cdkFocusInitial>Aceptar</button>
  <!-- cuando trabaja esperando accion en el input -->
  <button *ngIf="tieneCodigo" mat-button [mat-dialog-close]="true" [disabled]="disabled" cdkFocusInitial>Aceptar</button>
</mat-dialog-actions>

