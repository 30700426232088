import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Length_Database, Msjs_Validations, PatternsRegex } from 'src/app/shared/cons/common';
import { CONFIGURACION } from 'src/app/shared/cons/config';
import { Icons } from 'src/app/shared/cons/icons';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { Utils } from 'src/app/shared/helpers/utils';
import { GetApiDNIRUCService } from 'src/app/shared/services/get-api-dniruc.service';
import { IGetAPIPersona } from 'src/app/shared/services/models/IGetAPIPersona';
import { SendPersona } from 'src/app/shared/services/models/sendPersona';
import { Pedido } from '../../registrar-pedido/models/Pedido';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SendApiComprobanteElectronicoService } from 'src/app/shared/services/send-api-comprobante-electronico.service';
import { IProductoTicket } from '../../registrar-pedido/models/IProductoTicket';
import { GestionAspectosBlService } from '../gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { AspectosGenerales } from '../gestion-aspectos-generales/models/AspectosGenerales';
import { IRespuestaReseller } from 'src/app/shared/services/models/IRespuestaReseller';

@Component({
  selector: 'app-emision-boleta-venta',
  templateUrl: './emision-boleta-venta.component.html',
  styleUrls: ['./emision-boleta-venta.component.scss']
})

@AutoUnsubscribe

export class EmisionBoletaVentaComponent implements OnInit {
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Icons: typeof Icons = Icons;
  readonly Msjs_Validations:typeof Msjs_Validations = Msjs_Validations;
  lTipo_documento: any[] = CONFIGURACION.TIPO_DOCUMENTO;
  isLoading:boolean=false;
  dniResponse:IGetAPIPersona | null=null;
  personaSend:SendPersona= new SendPersona();
  getAPIPersonaSub!:Subscription;
  esBuscado:boolean=false;
  onlyNumbers = Utils.InputOnlyNumbers;
  pedido = new Pedido();
  listaProductoPedido!: IProductoTicket[];
  tasasSb!:Subscription;
  aspectosGenerales = new AspectosGenerales();
  
  constructor(
    private getApiDNIRUCService: GetApiDNIRUCService,
    private sendApiComprobante:SendApiComprobanteElectronicoService,
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private gestionAspectosBL: GestionAspectosBlService,
  ) { }

  ngOnInit(): void {
    
    this.pedido = this.datoPasado.dato as Pedido;
    this.listaProductoPedido = this.datoPasado.listaProductoPedido as IProductoTicket[];
    this.gestionAspectosBL.existFetchTasas();
    this.tasasSb?.unsubscribe();
    this.tasasSb = this.gestionAspectosBL.getTasas().subscribe((tasas) => {
      if (tasas) {
        this.aspectosGenerales = tasas;
      }
    });
   
  }

  buscarPorDNI(f:NgForm){
    if(f.valid){
      this.isLoading = true;
      this.getAPIPersonaSub = this.getApiDNIRUCService.getDNI(f.value.dni).subscribe(res=>{
        if(res.success && res.nombres!="" ){
          this.esBuscado = true;
          this.dniResponse = res as IGetAPIPersona;
          this.isLoading = false;           
        }else{
          this.esBuscado = true;
          this.dniResponse = null;
          this.isLoading = false; 
        }
        
      });
    }    
  }

  emitirBoletaManualCNombre(f:NgForm){
    if(f.valid){      
      this.sendApiComprobante.emitirBoletaVenta( true, this.pedido, this.personaSend, 
        this.listaProductoPedido, this.aspectosGenerales).then((res)=>{
          if(res){
            const respuesta = res as IRespuestaReseller;
            if(respuesta.aceptada_por_sunat){
              console.log(respuesta.cadena_para_codigo_qr);
            }
          }
        });
    }
    
  }

  emitirBoletaAutomatico(){

  }

  onKeydown(event: KeyboardEvent, f:NgForm): void {
    if (event.key === 'Enter') {
      this.buscarPorDNI(f); // Llama al método que quieras ejecutar
    }
  }

  limpiarBusqueda(f:NgForm){
    this.isLoading = false;
    this.esBuscado = false;
    this.dniResponse= null;
    f.reset();    
  }
  LimpiarManual(f:NgForm){
    this.isLoading = false;
    this.esBuscado = false;
    this.dniResponse= null;
    f.reset();
    this.personaSend= new SendPersona();
  }

}
