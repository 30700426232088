import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-plataforma',
  templateUrl: './layout-plataforma.component.html',
  styleUrls: ['./layout-plataforma.component.scss']
})
export class LayoutPlataformaComponent implements OnInit {


  constructor(

  ) { }

  ngOnInit(): void {
  
  }



}
