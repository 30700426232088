import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { finalize } from 'rxjs/operators';
import { FileTypes } from '../../types/file-types';

import { PATH_API_FILES } from '../../cons/config';
import { Utils } from '../../helpers/utils';
import { Observable, Subject } from 'rxjs';
import { EventEmitter } from 'stream';
import { UploadService } from './upload.service';
import { UIService } from '../../services/ui.services';
import { SnackBarType } from '../../types/snackbar-type';
import { Msjs } from '../../cons/common';
import { AutoUnsubscribe } from "../../helpers/decorators/AutoUnsubscribe";
import { IImageUploaded } from './IImageUploaded';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
@AutoUnsubscribe

export class UploadComponent implements OnInit {

  files_upload:File[]=[];
  @Input() requiredFileType:string=FileTypes.IMAGE;
  @Input() multipleFiles:boolean=false;
  //@Input() state:string='_';
  @Input() label:string='';
  @Input() max_archivos:number=1;
  @Input() max_size_file:number=100000; //kb
  @Input() max_size_width_height:string | null= null;
  @Input() disabled:boolean = false;
  @Input() is_edit:boolean = false;
  @Input() is_view:boolean = false;
  @Input() images_uploaded:any[] =[]//input que sirve para modo edicion, lista de archivos previamente subidos
  @Input() disabled_add:boolean=false;

  type_files!:string;
  @ViewChild("fileUpload", {static:false})
  inputVar!:ElementRef;

  //chips
  selectable = true;
  removable = true;
  addOnBlur = true;

  remove_all_filesSb!: Subscription;
  num_files:number=0;

  constructor(
    private http: HttpClient,
    private uploadService: UploadService,
    private uiService: UIService
    ) {

  }

  ngOnInit(): void {
    this.remove_all_filesSb = this.uploadService.remove_all_files$.subscribe(()=>{
      this.files_upload=[];
      //reseteamos el upload porque se queda pegado
      this.inputVar.nativeElement.value="";
      if(this.images_uploaded.length>=this.max_archivos){
        this.disabled_add=true;
      }else{
        this.disabled_add=false;
      }
    });

    if(!this.multipleFiles){
      this.max_archivos =1;
    }

    if(this.images_uploaded.length>=this.max_archivos){
      this.disabled_add=true;
    }
  }

  //Soporta multiples archivos multipleFiles, indicando el maximo de archivos max_archivos
  onFilesSelected(event: any) {
    if (this.disabled) {
      return;
    }
    const files = event.target.files;
    let flag_archivo_valido: boolean | string = true;
    //comprobando si es modo edicion
    this.num_files = 0;
    this.num_files = this.images_uploaded.length + files.length+ this.files_upload.length;
    if (this.is_edit) {
      if (this.num_files > this.max_archivos) {
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MAXIMO_NRO_ARCHIVOS.replace('$', this.max_archivos.toString()), 5000);
        this.inputVar.nativeElement.value = "";
        this.num_files =0;
        return;
      }
    }
    else {
      //comprobando maximo de archivos: default1
      if (this.num_files > this.max_archivos) {
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MAXIMO_NRO_ARCHIVOS.replace('$', this.max_archivos.toString()), 5000);
        this.inputVar.nativeElement.value = "";
        this.num_files =0;
        return;
      }
    }


    //comprobando si la imagen se repite o ya se agrego previamente (multiupload)
    for (const file of this.files_upload) {
      for (const file_new of files) {
        if (file.name == file_new.name) {
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ARCHIVOS_DUPLICADOS, 5000);
          this.inputVar.nativeElement.value = "";
          this.num_files =0;
          return;
        }
      }
    }

    //comprobando tamaño maximo de cada archivo
    let flag_tamanio_valido: boolean = true;
    Array.from(files).forEach((file: any) => {
      if (file.size > this.max_size_file) {
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MAXIMO_TAMANIO_ARCHIVOS.replace('$', (this.max_size_file / 1000).toString()), 5000);
        flag_tamanio_valido = false;
        this.num_files =0;
        return;
      }
    });
    //rompe sin añadir la imagen
    if (!flag_tamanio_valido) {
      this.num_files =0;
      return;
    }

    //comprobando extension
    Array.from(files).forEach((file: any) => {
      if (flag_archivo_valido) {
        const ext = Utils.ComprobarExtensionArchivo(file.type, this.requiredFileType);
        flag_archivo_valido = ext[0];
        if (!flag_archivo_valido) {
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ARCHIVO_ERROR + file.name, 5000);
          this.resetUpload();
          return;
        } else {
          this.files_upload.push(file);
        }
      }
    });
    if (flag_archivo_valido) {
      if (files) {
        if (files.length >= this.max_archivos) {
          this.disabled_add = true;
        } else {
          this.disabled_add = false;
        }
        this.uploadService.onFilesSelected({
          images_upload: this.files_upload, //subidas actual
          images_uploaded: this.images_uploaded // subidas anteriormente
        });
      }
    }
  }

  removeFile(file: File): void {
    //reseteamos el upload porque se queda pegado
    this.inputVar.nativeElement.value="";
    this.files_upload = this.files_upload.filter(filex=> filex.name!= file.name);
    this.uploadService.onFilesSelected({
      images_upload:this.files_upload,
      images_uploaded: this.images_uploaded // subidas anteriormente
    });

    this.num_files = this.files_upload.length + this.images_uploaded.length;
    if(this.files_upload.length<this.max_archivos){
      this.disabled_add =false;
    }
    if(this.files_upload.length==this.max_archivos){
      this.disabled_add =true;
    }
    if(this.files_upload.length>=this.max_archivos){
      this.disabled_add =true;
    }
  }

  removeFileEdit(file: IImageUploaded): void {

    //reseteamos el upload porque se queda pegado
    this.inputVar.nativeElement.value="";
    this.images_uploaded = this.images_uploaded.filter(filex=> filex.nombre_original!= file.nombre_original);
    if((this.images_uploaded.length<= this.max_archivos) && this.is_edit ){
      this.disabled_add = false;
    }
    this.uploadService.onFilesSelected({
      images_upload:this.files_upload,
      images_uploaded: this.images_uploaded // subidas anteriormente
    });
    this.num_files = this.files_upload.length + this.images_uploaded.length;
  }

  resetUpload(){
    this.inputVar.nativeElement.value="";
    this.files_upload= [];

  }
}
