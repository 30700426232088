import { IResumen } from "./IResumen";
import { Icons } from 'src/app/shared/cons/icons';
export class CResumen implements IResumen{
  valor1: number=0;
  valor2: number=0;
  icono1: string= Icons.SUBJECT;
  icono2: string= Icons.SUBJECT;
  texto1: string='?';
  texto2: string='?';
}
