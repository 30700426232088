import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Length_Database, Msjs, Msjs_Validations, PatternsRegex, SistOperation } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';
import { CRol } from '../models/crol.model';
import { ItemSidenav, IRolAcceso } from 'src/app/shared/models/item.model';
import { MatSelect } from '@angular/material/select';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { Subscription } from 'rxjs';
import { SpinnerPageService } from '../../../spinner-page/spinner-page.service';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { Utils } from 'src/app/shared/helpers/utils';
import { EmpresaBLService } from '../../../services/services.bl/empresa.bl.service';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';

@Component({
  selector: 'app-dialog-gestion-rol',
  templateUrl: './dialog-gestion-rol.component.html',
  styleUrls: ['./dialog-gestion-rol.component.scss']
})

@AutoUnsubscribe


export class DialogGestionRolComponent implements OnInit {
  CLOSE:string = Icons.CLOSE;
  titleModal:string='';
  readonly Icons: typeof Icons = Icons;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly SistOperation: typeof SistOperation = SistOperation;

  disabled_controls:boolean=false;
  cRol = new CRol();
  rolAccesoMaster!: IRolAcceso;
  accesos:string='';
  accesosSeleccionados:string[]=[];
  @ViewChild(MatSelect) ddlAccesos!: MatSelect;
  @ViewChild('f') formulario!: NgForm;
  selectable = true;
  removable = true;
  sbModalQuest!:Subscription;
  item!:ItemSidenav;
  indexModulo:number=0;
  indexRol:number=0;
  rolesAniadidos:IRolAcceso[]=[];
  isLoading:boolean=false;
  editarEsValido:boolean=false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef: MatDialogRef<DialogGestionRolComponent>,
    private uiService:UIService,
    private readonly spinnerPageService: SpinnerPageService,
    private registroService: RegistroService,
    private empresaBLService: EmpresaBLService
  ) { }

  ngOnInit(): void {

    if (this.datoPasado.operacion == SistOperation.NUEVO) {
      this.titleModal = 'Nuevo Rol';
    }
    else {
      if (this.datoPasado.operacion == SistOperation.EDITAR) {
        this.titleModal = 'Editar Rol';
        this.cargarDatosEdicion();
      }
    }
    //rol maestro que contiene todos los accesos
    this.rolAccesoMaster = this.datoPasado.data;
    // item de modulo seleccionado
    this.item = this.datoPasado.item;
  }

  cargarDatosEdicion(){
    this.cRol.rol = this.datoPasado.rol.rol;
    //se usa el operador de propagacion para no referenciar al string original, porque sino se enlazan los dos
    this.accesosSeleccionados = [...this.datoPasado.rol.acceso];

  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(f: NgForm) {
    let msj= this.datoPasado.operacion==SistOperation.NUEVO? Msjs.MENSAJE_CONFIRMACION: this.datoPasado.operacion== SistOperation.EDITAR?Msjs.MENSAJE_CONFIRMACION_CONSECUENCIAS_MODIFICAR:Msjs.MENSAJE_CONFIRMACION;

    this.sbModalQuest = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: msj, icon: Icons.QUESTION_MARK })
      .subscribe(result => {
        if (result) {
          //comprobando que el nombre del rol sea nuevo
          let duplicidad_nombre: boolean = false;
          this.spinnerPageService.show();
          this.isLoading = true;
          let roles: IRolAcceso[] = [];
          this.cRol.acceso = this.accesosSeleccionados;
          //buscando el modulo al que corresponde la configuracion de roles
          this.registroService.empresa.lista_modulos_seleccionados.forEach((modulo: { codigo: string; roles: CRol[] }, indice: number) => {
            if (modulo.codigo == this.item.codigo) {
              //guardando el indice
              this.indexModulo = indice;
              //añadiendo el nuevo rol
              this.rolesAniadidos = Utils.GetArrayMenuRol(modulo.roles);

              this.rolesAniadidos.forEach((itemRol: { rol: string, acceso: string[] }, index:number) => {
                //comprobamos que los indices sean distintos, porque si son iguales se trata del mismo rol
                if ((itemRol.rol.toLowerCase() == this.cRol.rol.toLowerCase())&& this.datoPasado.indiceRol!=index ) {
                  duplicidad_nombre = true;
                }
              });
            }
          });
          if (duplicidad_nombre) {
            this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_MULTIPLICIDAD_ROL, 3000);
            this.isLoading = false;
            this.spinnerPageService.hide();
            return;
          }
          //NUEVO
          if (this.datoPasado.operacion == SistOperation.NUEVO) {
            //añadiendo el nuevo rol
            this.rolesAniadidos.push({ acceso: this.cRol.acceso, rol: this.cRol.rol, personalizado: true });
          }else{
            //EDITAR
            if (this.datoPasado.operacion == SistOperation.EDITAR){
              //editando rol
              this.rolesAniadidos.forEach((itemRol: { rol: string, acceso: string[] }, indexRol:number) => {
                if (itemRol.rol == this.datoPasado.rol.rol) {
                  this.rolesAniadidos[indexRol].acceso = this.cRol.acceso;
                  this.rolesAniadidos[indexRol].rol = this.cRol.rol;
                  this.indexRol = indexRol;
                  return;
                }
              });
            }
          }
          //actualizando empresa modulos y roles (mismo procedimiento para nuevo y editar)
          this.empresaBLService.updateEmpresaModuloRoles(this.rolesAniadidos,this.indexModulo,this.indexRol, this.datoPasado.operacion)
          .then((result: any) => {
            if (result.tx) {
              this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
              this.uiService.closeModalGestionRol();
              this.spinnerPageService.hide();
              this.isLoading = false;
              //recarga los roles en el componente padre
              //this.uiService.reloadRolesAccesos$.next();
            } else {
              this.spinnerPageService.show();
              this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);
              this.uiService.closeModalGestionRol();
              this.isLoading = true;
            }
          });
        //cuando cancela el dialog aceptar
        }else{
          //EDITAR
          if (this.datoPasado.operacion == SistOperation.EDITAR){
            this.ddlAccesos.ngControl.control?.setErrors(null);
          }
        }
      });

  }

  ddlAcceso(acceso:any){
    if(this.accesosSeleccionados.includes(acceso)){
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.OPCION_EXISTENTE, 3000);
    }else{
      this.accesosSeleccionados.push(acceso);
    }
    this.ddlAccesos.value = null;
    this.comprobarValidez();
  }

  onSelectChip(acceso:string){
    this.accesosSeleccionados= this.accesosSeleccionados.filter((item:string)=>item!==acceso);
    this.comprobarValidez();//solo para modo edicion, pero no afecta en modo nuevo
  }

  //sirve para modo edicion
  comprobarValidez(){
    const control = this.formulario.form.get('nombre');
    if(control?.valid && this.accesosSeleccionados.length>0){
      this.editarEsValido=false;////continuar con la validacion
    }else{
      this.editarEsValido=true;
    }
  }

  Limpiar(f:NgForm){
    this.cRol = new CRol();
    this.accesosSeleccionados=[];
    f.resetForm();
    this.comprobarValidez();

  }

}
