import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IModoOperacion } from '../models/IModoOperacion';
import { Utils } from 'src/app/shared/helpers/utils';
import { serverTimestamp } from 'firebase/firestore';
import { colections } from 'functions/src/class_interface';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { Auditoria, documentsEmpresa } from 'src/app/shared/cons/db.colections';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GestionOperacionesDAService {
  transaccion: TransaccionModel = new TransaccionModel();

  private modoOpCache: BehaviorSubject<IModoOperacion | undefined | null> = new BehaviorSubject<IModoOperacion | undefined |null>(undefined);
  public modoOp$: Observable<IModoOperacion | undefined |null> = this.modoOpCache.asObservable();
  private fetchSb!: Subscription;
  

  constructor(private db: AngularFirestore) {}

  fetchModoOp(idEmpresa: string, idUsuario: string): void {
    if (this.fetchSb && !this.fetchSb.closed) {
      this.modoOpCache.next(this.modoOpCache.value);
      return;
    }

    this.fetchSb = this.getConfiguracionModoOperacion(idEmpresa, idUsuario).subscribe(
      (modo) => {
        this.modoOpCache.next(modo);
      }
    );
  }

  stopFetchingModoOp(): void {
    if (this.fetchSb) {
      this.fetchSb.unsubscribe();
    }
    this.modoOpCache.next(undefined);
  }

  updateModoOperacion(
    modoOperacion: IModoOperacion,
    idEmpresa: string,
    correoUsuario: string
  ) {
    const idModoOp = modoOperacion.id;
    delete modoOperacion.id;
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(
      colections.CONFIGURACION_MODO_OPERACION
    );

    return docConfigRef
      .doc(idModoOp)
      .update({
        es_individualizado: modoOperacion.es_individualizado,
        fecha_modificacion: serverTimestamp(),
        usuario_modificacion: correoUsuario,
      })
      .then((result) => {
        this.transaccion.tx = true;
        this.transaccion.data = result;
        return this.transaccion;
      })
      .catch((error) => {
        this.transaccion.tx = false;
        this.transaccion.error = error;
        return this.transaccion;
      });
  }

  getConfiguracionModoOperacion(idEmpresa: string, idUsuario: string) {
    return this.db
      .collection(colections.EMPRESA, (ref) =>
        ref
          .where(Auditoria.es_borrado, '==', false)
          .where(documentsEmpresa.ID_DOC_USUARIO, '==', idUsuario)
      )
      .doc(idEmpresa)
      .collection(colections.CONFIGURACION_MODO_OPERACION, (ref1) =>
        ref1.where(Auditoria.es_borrado, '==', false).limit(1)
      )
      .snapshotChanges()
      .pipe(
        map((configuracion) => {
          if (configuracion.length > 0) {
            const config = Utils.convertDate(
              configuracion[0].payload.doc.data()
            ) as IModoOperacion;
            config.id = configuracion[0].payload.doc.id;
            return config;
          } else {
            return null;
          }
        })
      );
  }

  insertOperaciones(
    idEmpresa: string,
    correoUsuario: string,
    modoOperacion: IModoOperacion
  ) {
    modoOperacion.usuario_creacion = correoUsuario;
    const confModo = Utils.SerializeJsonToDb(modoOperacion);
    confModo.fecha_creacion = serverTimestamp();
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(
      colections.CONFIGURACION_MODO_OPERACION
    );
    return docConfigRef
      .add(confModo)
      .then((result) => {
        this.transaccion.tx = true;
        this.transaccion.data = result.id;
        return this.transaccion;
      })
      .catch((error) => {
        this.transaccion.tx = false;
        this.transaccion.error = error;
        return this.transaccion;
      });
  }
}