<div fxLayout="row" fxLayoutAlign="center center" [style.marginLeft.px]="5" [style.marginRigth.px]="5" >
  <form #f="ngForm" (ngSubmit)="onSubmit(f)" >
    <mat-card class="mat-card">
        <mat-card-header>
            <mat-card-title>2. Empresa</mat-card-title>
            <mat-card-subtitle>Datos de la empresa</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
            <div [style.marginTop.px]="10" [style.marginBottom.px]="20">
              <label class="secciones"  >Datos de la empresa.</label>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
                <mat-form-field>
                  <input required type="text" matInput placeholder="Nombre o Razón Social" name="razon" ngModel
                  #razonInput="ngModel"
                  [maxlength]="Length_Database.DB_LENGTH_MEDIUM_MAX_100"
                  [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
                  [disabled]="disabled_controls"
                  oninput="this.value = this.value.toUpperCase()"
                  [(ngModel)]="cempresa.nombre_razon"
                  class="input"
                  >
                  <mat-error
                    *ngIf="razonInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                  <mat-error
                    *ngIf="razonInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_MEDIUM_MAX_100}}</mat-error>
                  <mat-error
                    *ngIf="razonInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
              <mat-form-field>
                <input type="text" matInput placeholder="RUC" name="ruc" ngModel
                #rucInput="ngModel"
                [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_11"
                [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_11"
                [disabled]="disabled_controls"
                [pattern]="PatternsRegex.onlyIntegerUnsigned"
                (keypress) ="onlyNumbers($event)"
                [(ngModel)]="cempresa.ruc"
                (blur)="validarRUCUnico(f)"
                >
                <mat-error
                  *ngIf="rucInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                <mat-error
                  *ngIf="rucInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
                <mat-error
                  *ngIf="rucInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_11}}</mat-error>
                <mat-error
                  *ngIf="rucInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_11}}</mat-error>
                <mat-hint style="color: var(--red600);" *ngIf="rucExiste">{{Msjs.MSJ_RUC_EXISTE}}</mat-hint>
              </mat-form-field>
              <mat-form-field>
                <input type="text" matInput placeholder="Giro de negocio" name="giroInput" ngModel
                #giroInput="ngModel"
                [maxlength]="Length_Database.DB_LENGTH_SMALL_MAX_50"
                [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
                [disabled]="disabled_controls"
                [pattern]="PatternsRegex.onlyGoodCharsWithNumbersAndSpace"
                required
                [(ngModel)]="cempresa.giro_negocio"
                >
                <mat-error
                  *ngIf="giroInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                <mat-error
                  *ngIf="giroInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
                <mat-error
                  *ngIf="giroInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
                <mat-error
                  *ngIf="giroInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
              <mat-form-field>
                <input type="text" matInput placeholder="Correo electrónico" name="email"
                [pattern]="PatternsRegex.onlyEmail" #email="ngModel" required
                [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
                [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8" ngModel
                [disabled]="disabled_controls"
                oninput="this.value = this.value.toLowerCase()"
                [(ngModel)]="cempresa.correo_electronico"
                >
                <mat-error *ngIf="email.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                <mat-error
                  *ngIf="email.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_EMAIL}}</mat-error>
                <mat-error
                  *ngIf="email.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
                <mat-error
                  *ngIf="email.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8}}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Inicio de Operaciones </mat-label>
                <input matInput placeholder="mm/dd/aaaa"
                [max]="maxDate"
                [min]="minDate"
                #operaciones="ngModel"
                ngModel name="operaciones"
                required
                disabled
                [matDatepicker]="pickerop"
                [(ngModel)]="cempresa.inicio_operacion"
                >
                <mat-datepicker-toggle matSuffix [for]="pickerop"></mat-datepicker-toggle>
                <mat-datepicker #pickerop [disabled]="disabled_controls"></mat-datepicker>
                <mat-error *ngIf="operaciones.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO_FORMATO_INCORRECTO}}</mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
              <mat-form-field>
                <input type="text" matInput placeholder="Telefono 1" name="telefono1"
                [pattern]="PatternsRegex.onlyIntegerUnsigned" #telefono1="ngModel"
                [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_9"
                [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_9" ngModel
                [disabled]="disabled_controls"
                required
                (keypress) ="onlyNumbers($event)"
                [(ngModel)]="cempresa.telefono1"
                >
                <mat-error
                  *ngIf="telefono1.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                <mat-error
                  *ngIf="telefono1.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_REQ9_DIGITOS_MOVIL}}</mat-error>
                <mat-error
                  *ngIf="telefono1.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_9DIGITOS}}</mat-error>
                <mat-error
                  *ngIf="telefono1.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_9DIGITOS}}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <input type="text" matInput placeholder="Telefono 2" name="telefono2"
                [pattern]="PatternsRegex.onlyIntegerUnsigned" #telefono2="ngModel"
                [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_9"
                [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_9" ngModel
                [disabled]="disabled_controls"
                (keypress) ="onlyNumbers($event)"
                [(ngModel)]="cempresa.telefono2"
                >
                <mat-error
                  *ngIf="telefono2.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                <mat-error
                  *ngIf="telefono2.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_REQ9_DIGITOS_MOVIL}}</mat-error>
                <mat-error
                  *ngIf="telefono2.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_9DIGITOS}}</mat-error>
                <mat-error
                  *ngIf="telefono2.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_9DIGITOS}}</mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" class="toogle" >
              <mat-slide-toggle (change)="onChange($event)"
              color="primary"
              name="estado"
              ngModel
              #estado="ngModel"
              [disabled]="disabled_controls"
              [(ngModel)]="cempresa.es_vigente"
              >
                  {{msjEstado}}
                  <span  class="label" > (Estado de la empresa).</span>
              </mat-slide-toggle>
            </div>
            <div [style.marginTop.px]="20" [style.marginBottom.px]="20">
              <label class="secciones"  >Ubicación y dirección de la empresa. </label>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
              <mat-form-field>
                <mat-label>Departamento</mat-label>
                <mat-select (selectionChange)="departamentoSelect($event.value)" [(ngModel)]="cempresa.codigo_departamento"
                  name="ddlDepartamento" #ddlDepartamento="ngModel" required [disabled]="disabled_controls">
                  <mat-option *ngFor="let dep of lDepartamento" [value]="dep.codigoID">{{dep.nombre}}</mat-option>
                </mat-select>
                <mat-error *ngIf="ddlDepartamento.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Provincia</mat-label>
                <mat-select (selectionChange)="provinciaSelect($event.value)" [(ngModel)]="cempresa.codigo_provincia" name="ddlProvincia"
                  #ddlProvincia="ngModel" required [disabled]="disabled_controls">
                  <mat-option *ngFor="let prov of lProvincia" [value]="prov.codProvincia">{{prov.provincia}}</mat-option>
                </mat-select>
                <mat-error *ngIf="ddlProvincia.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
              <mat-form-field>
                <mat-label>Distrito</mat-label>
                <mat-select [(ngModel)]="cempresa.codigo_distrito" name="ddlDistrito" #ddlDistrito="ngModel" required [disabled]="disabled_controls">
                  <mat-option *ngFor='let dist of lDistrito' [value]='dist.codDistrito'>{{dist.distrito}}</mat-option>
                </mat-select>
                <mat-error *ngIf="ddlDistrito.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <input type="text" matInput placeholder="Dirección y número" name="direccion" ngModel
                  [maxlength]="Length_Database.DB_LENGTH_SMALL_MAX_50" [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8"
                  required #direccion="ngModel" [disabled]="disabled_controls"
                  [(ngModel)]="cempresa.direccion"
                  >
                <mat-error *ngIf="direccion.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                <mat-error
                  *ngIf="direccion.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8CARACTERES}}</mat-error>
                <mat-error
                  *ngIf="direccion.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
              </mat-form-field>
            </div>
           <div fxLayout="row" fxLayout.xs="row" [style.marginTop.px]="15" >
              <app-upload
              [multipleFiles]="false"
              [requiredFileType]="fileType"
              [label]="label"
              [max_size_file]="500000"
              [max_size_width_height]="sizeMaxHeightWidth"
              [disabled]="disabled_controls"
              [is_edit]="esModoEdicion"
              [images_uploaded]="files_uploaded"
              >
            </app-upload>
          </div>
        </mat-card-content>
        <mat-card-actions align="start" [style.marginLeft.px]="5" *ngIf="!disabled_controls" >
          <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="f.invalid"
          [attr_type]="'submit'"></app-button>
          <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" color="gray"
            (click)="Limpiar(f)"></app-button>
          <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING"
            (click)="Limpiar(f)"></app-button>
        </mat-card-actions>
        <mat-card-footer>
        </mat-card-footer>
    </mat-card>
  </form>
</div>
