import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Icons } from 'src/app/shared/cons/icons';
import { Utils } from 'src/app/shared/helpers/utils';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { UIService } from 'src/app/shared/services/ui.services';
import { Msjs, Msjs_Validations} from '../../../cons/common'
import { SnackBarType } from '../../../types/snackbar-type';
import { AutoUnsubscribe } from "../../../helpers/decorators/AutoUnsubscribe";


@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
@AutoUnsubscribe

export class CaptchaComponent implements OnInit {

  CLOSE:string = Icons.CLOSE;
  CANCEL:string = Icons.CANCEL;
  isLoading:boolean=false;
  error_resultado:boolean=false;
  //constantes
  readonly Msjs_Validations:typeof Msjs_Validations = Msjs_Validations;

  private loadingSubscription!:Subscription;

  lCaptcha:(string|number)[]=[];
  onlyNumbers = Utils.InputOnlyNumbers;

  constructor(
    private functionService:FunctionsService,
    private uiService:UIService
    ) {}

  ngOnInit(): void {
    this.loadingSubscription = this.uiService.loadingStateChanged.subscribe(loading=>{
      this.isLoading = loading;
    });
    this.functionService.GetCaptchaAritmetico().then(result=>{
      this.lCaptcha = result;
      //para ocultar el modal
      this.uiService.loadingStateChanged.next(false);
    });
  }

  onSubmit(form:NgForm){
    let numeroMovilx = form.value.numeroMovil;
    let flag = Number(form.value.resultado)===Number(this.lCaptcha[2])? true:false;
    !flag?(
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_RESPUESTA_INCORRECTA)
      //incorrecto
    ):(
      //correcto
      this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs.MSJ_ENVIO_SMS),

      this.uiService.CloseModalCaptcha(),
      //verificar que el servicio de sms este completando el codigo de pais si es que falla
      this.functionService.SendSMSClient(form.value.numeroMovil).then(resultado=>{
        this.uiService.ShowModalCaptchaCodigo({codigo:resultado.code, numero: numeroMovilx});
        //this.uiService.ShowSnackBar(SnackBarType.INFO, ': codigoTMP-'+resultado.code)
      })
    )
  }

  onClose(){
    this.uiService.CloseModalCaptcha();
  }
}
