import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ItemSidenav } from "../models/item.model";
import { StaticRoutes } from "../cons/route.class";
import { Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { AutoUnsubscribe } from "../helpers/decorators/AutoUnsubscribe";

@Injectable({
  providedIn: 'root'
})

@AutoUnsubscribe

export class UINavigateSidenavService{
  goOptionSidenav$: Subject<ItemSidenav> = new Subject<ItemSidenav>();

  private reloadOptionsMenuCache: BehaviorSubject<ItemSidenav[] | null> = new BehaviorSubject<ItemSidenav[] | null>(null);
  public reloadOptionsMenu$:  Observable<ItemSidenav[] | null> = this.reloadOptionsMenuCache.asObservable();
  //carga valor al hacer click en la opcion del sidenav
  public goOptionSidenavItem!:ItemSidenav;

  constructor(
    private router: Router,
    private authService: AuthService
  ){}

  //emite y cachea el valor
  public fetchOptionMenuSidenav(items:ItemSidenav[]){
    this.reloadOptionsMenuCache.next(items);
  }
  stopfetchOptionMenuSidenav(): void {
    this.reloadOptionsMenuCache.next(null);
  }

  //

  navegarSidenavOpcion(opcion:ItemSidenav){
    switch(opcion.link){
      case(StaticRoutes.PANEL_ADMINISTRADOR):{
        this.router.navigate(['/'+StaticRoutes.ADMINISTRADOR+'/'+StaticRoutes.PANEL_ADMINISTRADOR]);
        break;
      }
      case(StaticRoutes.EMPRESA):{
        this.router.navigate(['/'+StaticRoutes.ADMINISTRADOR+'/'+StaticRoutes.EMPRESA+'/'+StaticRoutes.PANEL_EMPRESA]);
        break;
      }
      case(StaticRoutes.PROCESO):{
        this.router.navigate(['/'+StaticRoutes.ADMINISTRADOR+'/'+StaticRoutes.PROCESO+'/'+StaticRoutes.PANEL_PROCESO]);
        break;
      }
      case(StaticRoutes.INICIO):{
        this.router.navigate(['/inicio/' + StaticRoutes.TOUR]);
        this.authService.logout()
        break;
      }
      //modulo empresa
      case(StaticRoutes.CONFIGURACION):{
        this.router.navigate(['/'+StaticRoutes.ADMINISTRADOR+'/'+StaticRoutes.RESTAURANT+'/'+StaticRoutes.CONFIGURACION]);
        break;
      }
      case(StaticRoutes.  GESTIONAR_BAR):{
        this.router.navigate(['/'+StaticRoutes.ADMINISTRADOR+'/'+StaticRoutes.RESTAURANT+'/'+StaticRoutes.GESTIONAR_BAR]);
        break;
      }
      case(StaticRoutes.GESTIONAR_CAJA):{
        this.router.navigate(['/'+StaticRoutes.ADMINISTRADOR+'/'+StaticRoutes.RESTAURANT+'/'+StaticRoutes.GESTIONAR_CAJA]);
        break;
      }
      case(StaticRoutes.GESTIONAR_COCINA):{
        this.router.navigate(['/'+StaticRoutes.ADMINISTRADOR+'/'+StaticRoutes.RESTAURANT+'/'+StaticRoutes.GESTIONAR_COCINA]);
        break;
      }
      case(StaticRoutes.GESTIONAR_MESA):{
        this.router.navigate(['/'+StaticRoutes.ADMINISTRADOR+'/'+StaticRoutes.RESTAURANT+'/'+StaticRoutes.GESTIONAR_MESA]);
        break;
      }
      case(StaticRoutes.GESTIONAR_CATALOGO):{
        this.router.navigate(['/'+StaticRoutes.ADMINISTRADOR+'/'+StaticRoutes.RESTAURANT+'/'+StaticRoutes.PROCESO+'/'+StaticRoutes.GESTIONAR_CATALOGO]);
        break;
      }
      case(StaticRoutes.REPORTE):{
        this.router.navigate(['/'+StaticRoutes.ADMINISTRADOR+'/'+StaticRoutes.RESTAURANT+'/'+StaticRoutes.REPORTE]);
        break;
      }
      // modulo crm
      case(StaticRoutes.GESTIONAR_FORMULARIO):{
        this.router.navigate(['/'+StaticRoutes.ADMINISTRADOR+'/'+StaticRoutes.CLIENTES_CRM+'/'+StaticRoutes.GESTIONAR_FORMULARIO]);
        break;
      }

      default:{

      }
    }
  }
}
