import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Length_Database, Msjs_Validations } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  titleModal:string='';
  message:string='';
  icono:string='';
  tieneCodigo:boolean=false;
  codigo:string="";
  readonly Icons: typeof Icons = Icons;
  readonly Length_Database: typeof Length_Database = Length_Database;
  codigoIngresado:string="";
  disabled:boolean=true;
  esError:boolean=false;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;

  constructor(
    private uiService:UIService,
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
  ) {
      this.titleModal = datoPasado.title;
      this.message = datoPasado.message;
      this.icono = datoPasado.icon;
      if(datoPasado.tieneCodigo){
        this.tieneCodigo = datoPasado.tieneCodigo;
        this.codigo= datoPasado.codigo;
      }
   }

  ngOnInit(): void {
  }

  onClose(){
  }

  comprobarCodigo(){
    if(this.codigo===this.codigoIngresado){
      this.disabled = false;
      this.esError = false;
    }
    else{
      this.disabled = true;
      this.esError = true;
    }
  }


}
