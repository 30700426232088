import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'negativizar'
})
export class NegativizarPipe implements PipeTransform {

  transform(value: unknown, cantidad:number, ...args: unknown[]): unknown {
    if(cantidad!=0){
      cantidad = cantidad*(-1)
    }
    return cantidad;
  }

}
