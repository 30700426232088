import { ComprobanteElectronico } from "./comprobante-electronico";
import { IEmpresa } from "./empresa.model";

export class CEmpresa implements IEmpresa{
  id_usuario_cuenta: any;
  nombre_razon: string='';
  ruc: string='';
  giro_negocio: string='';
  correo_electronico: string='';
  inicio_operacion: any;;
  telefono1: string='';
  telefono2: string='';
  codigo_departamento: string='';
  codigo_provincia: string='';
  codigo_distrito: string='';
  direccion: string='';
  logo_empresa?: string[] | undefined;
  es_borrado: boolean=false;
  es_vigente: boolean=false;
  usuario_creacion: any;
  usuario_modificacion?: any;
  es_sin_pago:boolean=false;
  comprobante_electronico?:ComprobanteElectronico;

}
