import { AuditoriaSet } from "src/app/shared/models/auditoria.models";
import { IColaborador } from "./colaborador.model";
import { IHorario } from "../../../horario-trabajo/horario.model";
import { CModuloRolSeleccionado } from "./modulo-rol-seleccionado";
import { CEmpresa } from "../../../dialog/models/cempresa.model";
import { ICantidadModuloSel } from "src/app/shared/models/ICantidadModuloSel";


export class CColaborador implements IColaborador, AuditoriaSet {
  auto_numerico!:number
  id_usuario_cuenta: string='';
  //id!:string;
  nombres:string='';
  apellido_paterno:string='';
  apellido_materno:string='';
  sexo:string='';
  tipo_documento:string='';
  numero_documento!:number;
  direccion:string='';
  fecha_nacimiento:any;
  correo_electronico:string='';
  password:string='';
  telefono!:number;
  horario_laboral!:IHorario
  imagen?:string[];
  es_borrado: boolean=false;
  es_vigente: boolean=false;
  usuario_creacion: any;
  usuario_modificacion?: any;
  modulo_rol:CModuloRolSeleccionado[] = [];
  fecha_creacion?: any;
  fecha_modificacion?: any;
  nombres_completo?:string[] =[];
  //propiedades generadas utilitarias
  estado?:string;
  nombres_apellidos?:string;
  numeracion?:number;
  totalRegistrosActivos?:number;
  totalRegistrosInactivos?:number;
  totalRegistros_doc?:number;
  totalRegistros_query?:number;
  fecha_registro?:string;
  id?:string;//id del Documento
  masElementos?:boolean;
  nextPage?:boolean;
  previousPage?:boolean;
  //empresa, sirve para usuario_colaborador
  empresa?:CEmpresa
  cantidad_modulos_seleccionados?:ICantidadModuloSel[]



}
