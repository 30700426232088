export abstract class StaticRoutes {
  //modulo navigation
  static readonly INICIO: string = 'inicio';
  static readonly TOUR: string = 'tour';
  static readonly PRECIO: string = 'precio';
  static readonly CONTACTO: string = 'contacto';
  static readonly LOGIN: string = 'login';
  static readonly RECUPERAR_PASSWORD: string = 'recuperarPassword';
  static readonly PAGINA404: string = 'pagina404';
  static readonly ENCUESTA: string = 'encuesta';
  static readonly PARAMETRO_CARTA: string='externo/carta/:id';//:id representa el parametros que se va a pasar a la aplicacion

  //modulo administrador
  static readonly ADMINISTRADOR: string = 'admin';
  static readonly PANEL_ADMINISTRADOR: string = 'panel';
  static readonly REGISTRO: string = 'registro';
  //modulo empresa
  static readonly CATALOGO: string = 'catalogo';
  static readonly PRODUCTO: string = 'producto';
  static readonly EMPRESA: string = 'empresa';
  static readonly GESTIONAR_EMPRESA: string = 'gestionar_empresa';
  static readonly GESTIONAR_MODULOS: string = 'gestionar_modulos';
  static readonly PANEL_EMPRESA: string = 'panel_empresa';
  //procesos comunes a todos los modulos
  static readonly PANEL_PROCESO: string = 'panel_proceso';
  static readonly PROCESO: string = 'proceso';
  static readonly GESTIONAR_PRODUCTO: string = 'gestionar_producto';
  static readonly GESTIONAR_CATALOGO: string = 'gestionar_catalogo';
  //modulo RESTAURANTE
  static readonly RESTAURANT: string = 'restaurant';
  static readonly GESTIONAR_CAJA: string = 'gestionar_caja';
  static readonly GESTIONAR_COCINA: string = 'gestionar_cocina';
  static readonly GESTIONAR_BAR: string = 'gestionar_bar';
  static readonly GESTIONAR_MESA: string = 'gestionar_mesa';
  static readonly REPORTE: string = 'reporte';
  static readonly CONFIGURACION: string = 'configuracion';
  //modulo CRM
  static readonly CLIENTES_CRM: string = 'clientes_crm';
  static readonly CRM: string = 'crm';
  static readonly GESTIONAR_CRM: string = 'gestionar_crm';
  static readonly GESTIONAR_COMUNICACIONES: string = 'comunicaciones_crm';
  static readonly GESTIONAR_FORMULARIO: string = 'gestionar_formulario';
  //superadministradores
  static readonly SP_CONSOLA: string = 'sp_consola';
  static readonly SP_CONFIGURACION: string = 'sp_configuracion';




}
