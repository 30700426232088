export enum EstadosPedidoType {
    PENDIENTE = "pendiente", //cuando se hace el pedido pero aun no se prepara ningun producto
    EN_PROCESO = "en_proceso", //cuando todos o alguno de los productos esta en preparacion
    TERMINADO = "terminado", // cuando se termina de entregar todos los productos
    //ENTREGADO = "entregado", // cuando se entrego todos los productos en mesa
    //RECHAZADO ="rechazado", //cuando un pedido se rechaza por el cliente
    ANULADO='anulado', //cuando un pedido es anulado por el administrador
    //estados cuando existe una revision pendiente
    PENDIENTE_REVISION='pendiente_revision', // cuando se solicita un cambio de mesa    
    APROBADO='aprobado', // cuando se solicita un cambio de mesa    
    RECHAZADO='rechazado', // cuando se solicita un cambio de mesa  
    CERRADO='cerrado', // cuando se solicita un cambio de mesa
    PENDIENTE_REABRIR='pendiente_reabrir',
    //el estado reabierto será el terminado    
    
}
  