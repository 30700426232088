import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Auditoria, colections, documentsUsuario } from "src/app/shared/cons/db.colections";
import { map } from 'rxjs/operators';
import { Utils } from '../../../helpers/utils';
import { IUsuario } from '../../dialog/models/usuario.model';
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { serverTimestamp, deleteField } from 'firebase/firestore';//retorna fecha y hora servidor
import { UploadService } from "../../upload/upload.service";
import { RegistroService } from "src/app/shared/services/registro.service";
import { AutoUnsubscribe } from "src/app/shared/helpers/decorators/AutoUnsubscribe";
import { IImageUploaded } from "../../upload/IImageUploaded";


@Injectable({
  providedIn: 'root'
})

@AutoUnsubscribe


export class UsuarioDAService {
  //subject que servirá de cache
  private usuarioCache: BehaviorSubject<IUsuario | undefined | null> = new BehaviorSubject<IUsuario | undefined |null>(undefined);
  //variable expuesta pra consumo
  public usuario$: Observable<IUsuario | undefined |null> = this.usuarioCache.asObservable();
  //eliminar al observable interno
  private fetchUsuarioSubscription!: Subscription;

  constructor(
    private db: AngularFirestore,
    private uploadService: UploadService,
    private registroService: RegistroService,

  ) {

  }
  /*
  metodos cache
  */
  //solo llamar para recargar esta informacion
  fetchUsuarioById(idUsuario: string): void {
    if(this.fetchUsuarioSubscription && !this.fetchUsuarioSubscription.closed){
      this.usuarioCache.next(this.usuarioCache.value);
      return;
    }
    // if(this.fetchUsuarioSubscription){
    //   this.fetchUsuarioSubscription.unsubscribe();
    // }
    this.fetchUsuarioSubscription = this.getUsuarioByID(idUsuario).subscribe(usuario => {
      this.usuarioCache.next(usuario);
    });
  }
  //TODO:desuscribirse de todo antes de cerrar sesion
  stopFetchingUsuario(): void {
    if (this.fetchUsuarioSubscription) {
      this.fetchUsuarioSubscription.unsubscribe();
    }
    this.usuarioCache.next(undefined);
  }
  /********************/


  private getUsuarioByID(idUsuario: string): Observable<IUsuario | null> {
    return this.db.collection(
      colections.USUARIO,
      ref => ref.where(documentsUsuario.ID_USUARIO_CUENTA, '==', idUsuario)
        .where(Auditoria.es_vigente, '==', true)
        .limit(1)).snapshotChanges()
      .pipe(
        map(users => {
          const user = users[0];
          if (user) {
            const data = Utils.convertDate(user.payload.doc.data()) as IUsuario;
            const id = user.payload.doc.id;
            return { id, ...data }
          }
          else return null;
        }));
  }

  insertNuevoUsuario(usuario: IUsuario, files_upload: File[], pathFileAPI: string) {
    if (files_upload.length == 0) {
      const doc = Utils.SerializeJsonToDb(usuario);
      doc.fecha_creacion = serverTimestamp();
      return this.db.collection(colections.USUARIO).add(doc);
    }
    else {
      let promesa = this.uploadService.onSaveFiles(files_upload, pathFileAPI).then(urls => {
        const doc = Utils.SerializeJsonToDb(usuario);
        doc.fecha_creacion = serverTimestamp();
        doc.imagen = urls;
        return this.db.collection(colections.USUARIO).add(doc);
      });
      return promesa;
    }

  }

  //imagen nueva y reemplaza a una o varias anteriores
  updateUsuarioAniadirReemplazarImagen(usuario: IUsuario, files_upload_actual: File[], files_upload_anterior: IImageUploaded[], pathFileAPI: string) {
    let urlsAll: any
    return new Promise<void>((resolve, reject) => {
      //depura files soporta que no se le envia imagenes previamnte cargadas tanto en nuevo y en edicion
      //depura files conserva imagenees residuales (files_upload) subidas de todo el grupo anterior
      this.uploadService.depuraFiles(pathFileAPI, files_upload_anterior, this.registroService.usuario.imagen)
        .then(() => {
          return this.uploadService.onSaveFiles(files_upload_actual, pathFileAPI);
        })
        .then(urls => {
          urlsAll = urls;
          const doc = Utils.SerializeJsonToDb(usuario);
          doc.fecha_modificacion = serverTimestamp();
          doc.usuario_modificacion = this.registroService.usuario.correo_electronico;
          doc.imagen = urlsAll;
          return this.db.firestore.collection(colections.USUARIO).doc(this.registroService.usuario.id).update(doc)
        }).then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  //imagen nueva, no existe imagen anterior
  updateUsuarioAniadirImagen(usuario: IUsuario, files_upload_actual: File[], pathFileAPI: string) {
    return new Promise<void>((resolve, reject) => {
      this.uploadService.onSaveFiles(files_upload_actual, pathFileAPI).then(urls => {
        const doc = Utils.SerializeJsonToDb(usuario);
        doc.fecha_modificacion = serverTimestamp();
        doc.usuario_modificacion = this.registroService.usuario.correo_electronico;
        doc.imagen = urls;
        return this.db.firestore.collection(colections.USUARIO).doc(this.registroService.usuario.id)
        .update(doc)
      }).then(() => {
        resolve();
      }).catch(err => {
        reject(err);
      });
    });
  }

  //se elimina la imagen anterior y no se reemplaa con ninguna
  updateUsuarioQuitarImagen(usuario: IUsuario, files_upload_anterior: IImageUploaded[], pathFileAPI: string) {
    let urlsAll: any
    return new Promise<void>((resolve, reject) => {
      this.uploadService.depuraFiles(pathFileAPI, files_upload_anterior, this.registroService.usuario.imagen)
        .then(() => {
          const doc = Utils.SerializeJsonToDb(usuario);
          doc.fecha_modificacion = serverTimestamp();
          doc.usuario_modificacion = this.registroService.usuario.correo_electronico;
          doc.imagen = deleteField();
          return this.db.firestore.collection(colections.USUARIO).doc(this.registroService.usuario.id)
          .update(doc)
        })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  updateUsuarioNoModificarImagen(usuario: IUsuario) {
    return new Promise<void>((resolve, reject) => {
      const doc = Utils.SerializeJsonToDb(usuario);
      doc.fecha_modificacion = serverTimestamp();
      doc.usuario_modificacion = this.registroService.usuario.correo_electronico;
      this.db.collection(colections.USUARIO).doc(this.registroService.usuario.id).update(doc)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

}
