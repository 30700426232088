import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { colections } from '../cons/db.colections';
import { map } from 'rxjs/operators';
import { IConfigGen } from './models/IConfigGen';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ConfiguracionGeneralDaService {

  private confGenCache: BehaviorSubject<IConfigGen | undefined | null> = new BehaviorSubject<IConfigGen | undefined |null>(undefined);
  public confGen$: Observable<IConfigGen | undefined |null> = this.confGenCache.asObservable();
  private fetchSb!: Subscription;
  constructor(
    private db: AngularFirestore,
  ) { }

  fetchConfigGen(): void {  
    if(this.fetchSb &&!this.fetchSb.closed){
      this.confGenCache.next(this.confGenCache.value);
      return;
    }

    this.fetchSb = this.getConfiguracionGeneral().subscribe(tasas => {
      this.confGenCache.next(tasas);
    });
  }

  stopFetchingConfigGen(): void {
    if (this.fetchSb) {
      this.fetchSb.unsubscribe();
    }
    this.confGenCache.next(undefined);
  }

  getConfiguracionGeneral(){
    return this.db.collection(colections.CONFIGURACION_GENERAL)      
       .snapshotChanges()
       .pipe(
        map(configuracion=>{
         if(configuracion.length>0){
           const confGen = configuracion[0].payload.doc.data() as IConfigGen           
           confGen.id = configuracion[0].payload.doc.id;
           return confGen;
         }
         else{
           return null;
         }
       }));
  }
}
