import { Component, Input, OnInit } from '@angular/core';
import { Icons } from '../../cons/icons';
import { CPagination } from './CPagination';
import { ITableEvento } from '../table/ITableEvento';
import { PaginationService } from './pagination.service';
import { EventoPaginacion } from '../../cons/common';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})

@AutoUnsubscribe

export class PaginationComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  readonly EventoPaginacion: typeof EventoPaginacion = EventoPaginacion;

  @Input() paginacion: CPagination = new CPagination();
  disabledBtnPrimeraPagina:boolean = false;
  disabledBtnPaginaAnterior:boolean = false;
  disabledBtnPaginaSiguiente:boolean = false;
  disabledBtnUltimaPagina:boolean = false;
  btnCheckEstadoBtnSb = new Subscription();
  btnUpdateCheckEstadoBtn = new Subscription();
  totalPaginas:number =0

  constructor(
    private paginationService:PaginationService
  ) { }

  ngOnInit(): void {
    this.determinarBotones();

    this.btnCheckEstadoBtnSb = this.paginationService.btnCheckEstadoBtn$.subscribe((callEvento: ITableEvento)=>{
      if (this.paginacion.key == callEvento.key) {
        this.determinarBotones();
      }
    });

  }

  determinarBotones(){

    const total_registros_coleccion = this.paginacion.total_registros_coleccion?this.paginacion.total_registros_coleccion:0;
    this.totalPaginas = Math.ceil(total_registros_coleccion/this.paginacion.tamanio_pagina_seleccionado)
    //no es busqueda
    if(this.paginacion.masElementos && this.paginacion.pagina_actual==1){
      this.disabledBtnPrimeraPagina = true;
      this.disabledBtnPaginaAnterior = true;
      this.disabledBtnPaginaSiguiente = false;
      this.disabledBtnUltimaPagina = false;
    }
    if(this.paginacion.masElementos && this.paginacion.pagina_actual!=1){
      this.disabledBtnPrimeraPagina = false;
      this.disabledBtnPaginaAnterior = false;
      this.disabledBtnPaginaSiguiente = false;
      this.disabledBtnUltimaPagina = false;
    }
    if(!this.paginacion.masElementos && this.paginacion.pagina_actual==1 &&
      this.paginacion.nextPage==undefined && this.paginacion.previousPage==undefined
      ){
      this.disabledBtnPrimeraPagina = true;
      this.disabledBtnPaginaAnterior = true;
      this.disabledBtnPaginaSiguiente = true;
      this.disabledBtnUltimaPagina = true;
    }
    if(!this.paginacion.masElementos && this.paginacion.pagina_actual==1 &&
      this.paginacion.nextPage==undefined && this.paginacion.previousPage &&
      !this.paginacion.es_busqueda){
      this.disabledBtnPrimeraPagina = true;
      this.disabledBtnPaginaAnterior = true;
      this.disabledBtnPaginaSiguiente = false;
      this.disabledBtnUltimaPagina = false;
    }
    if(!this.paginacion.masElementos && this.paginacion.pagina_actual==1 &&
      this.paginacion.nextPage==undefined && this.paginacion.previousPage &&
      this.paginacion.es_busqueda){
      this.disabledBtnPrimeraPagina = true;
      this.disabledBtnPaginaAnterior = true;
      this.disabledBtnPaginaSiguiente = false;
      this.disabledBtnUltimaPagina = false;
    }
    if(!this.paginacion.masElementos && this.paginacion.pagina_actual!=1){
      this.disabledBtnPrimeraPagina = false;
      this.disabledBtnPaginaAnterior = false;
      this.disabledBtnPaginaSiguiente = true;
      this.disabledBtnUltimaPagina = true;
    }

  }

  changeSizePage(newPageSize:any){
    const callEvento:ITableEvento = {
      key:this.paginacion.key,
      data:{opcion:EventoPaginacion.CAMBIO_TAMANIO_PAGINA, tamanio:newPageSize}
    }
    this.paginationService.btnPagination$.next(callEvento);
  }

  btnPaginar(tipo_paginacion:string){

    const callEvento:ITableEvento = {
      key:this.paginacion.key,
      data:{opcion:tipo_paginacion, tamanio:this.paginacion.tamanio_pagina}
    }
    this.paginationService.btnPagination$.next(callEvento);
  }

}
