import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Length_Database, Msjs, Msjs_Validations, PatternsRegex } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';

@Component({
  selector: 'app-dialog-caract-producto',
  templateUrl: './dialog-caract-producto.component.html',
  styleUrls: ['./dialog-caract-producto.component.scss']
})
export class DialogCaractProductoComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  isLoading:boolean=false;
  caracteristica:string='';
  listaCaracteristicas:string[]=[];




  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef:MatDialogRef<DialogCaractProductoComponent>,
    private uiService:UIService,

  ) { }

  ngOnInit(): void {
  }

  onSubmit(f:NgForm){
    if(this.listaCaracteristicas.length>0){
      this.dialogRef.close(this.listaCaracteristicas);  
    }    
  }

  AgregarCaracteristica(){
    if(this.caracteristica!=''){
      this.listaCaracteristicas.push(this.caracteristica);
      this.caracteristica='';
    }
  }

  onClose(){
    this.dialogRef.close();
  }

  removerCaracteristica(indice:number){
    this.listaCaracteristicas.splice(indice,1);

  }

  Limpiar(f:NgForm){
    this.caracteristica ='';
    this.listaCaracteristicas = [];

  }


}
