import { Injectable } from '@angular/core';
import { RegistroService } from './registro.service';
import { IEmpresa } from '../components/dialog/models/empresa.model';
import { Pedido } from '../components/registrar-pedido/models/Pedido';
import { IInvoice } from './models/IInvoice';
import { ComprobanteElectronico } from '../components/dialog/models/comprobante-electronico';
import { SendPersona } from './models/sendPersona';
import { IItems } from './models/IItems';
import { IProductoTicket } from '../components/registrar-pedido/models/IProductoTicket';
import { FunctionsService } from './functions.service';
import * as moment from "moment";
import { Utils } from '../helpers/utils';
import { Fecha } from '../components/gestion-monto/models/Fecha';
import { AspectosGenerales } from '../components/procesos/gestion-aspectos-generales/models/AspectosGenerales';

@Injectable({
  providedIn: 'root'
})
export class SendApiComprobanteElectronicoService {
  fechaHoy: Fecha = new Fecha();

  
  constructor(
    private registroService:RegistroService,
    private functionService: FunctionsService,
  ) { }

  async cargarFechas() {
    const fechaServer = await moment(await this.functionService.GetDateServer()).local().format();
    const fechaServerProcesado = Utils.separarFechasHoras(fechaServer);
    this.fechaHoy.anio = fechaServerProcesado[0]; //anio
    this.fechaHoy.mes = fechaServerProcesado[1]; //mes
    this.fechaHoy.dia = fechaServerProcesado[2]; //dia
    this.fechaHoy.hora = fechaServerProcesado[3]; //hora
    this.fechaHoy.minuto = fechaServerProcesado[4]; //minuto
    this.fechaHoy.segundo = fechaServerProcesado[5]; //seg  
  }
  
  async emitirBoletaVenta(conNombre:boolean, pedido:Pedido, personaSend:SendPersona, 
    listaProductoPedido: IProductoTicket[], aspectosGenerales: AspectosGenerales){
    await this.cargarFechas();
    const empresa = this.registroService.empresa as IEmpresa;
    if(empresa.comprobante_electronico!=undefined){
      
      const cuerpoBoleta = this.generarBoletaVenta(conNombre,pedido, empresa.comprobante_electronico, 
        personaSend, listaProductoPedido, aspectosGenerales);           
      //emitir boleta de venta
      return this.enviarBoletaPSEReseller(cuerpoBoleta,empresa);

    }
  }

  generarItems(listaProductoPedido:IProductoTicket[]){
    let listaItems:IItems[]=[];
    listaProductoPedido.forEach(prod=>{
      const item:IItems={
        unidad_de_medida:'NIU',// NIU para producto, ZZ para servicio
        codigo:prod.codigo_producto,
        descripcion:prod.nombre_producto,
        cantidad:prod.cantidad_producto,
        valor_unitario:prod.valor_producto,
        precio_unitario:prod.precio_unitario,
        igv: prod.igv_valor_producto * prod.cantidad_producto,
        total:prod.precio_unitario * prod.cantidad_producto,
        subtotal: prod.valor_producto * prod.cantidad_producto,
        descuento:'',//**********, 
        tipo_de_igv:1,
        anticipo_regularizacion:false,
        anticipo_comprobante_numero:'',
        anticipo_comprobante_serie:''
      }
      listaItems.push(item);
      
    });
    return listaItems;
  }

  generarBoletaVenta(conNombre:boolean, pedido:Pedido, comprobanteElectronico:ComprobanteElectronico, 
    personaSend:SendPersona, listaProductoPedido:IProductoTicket[], aspectosGenerales:AspectosGenerales
  ){
    //1.generando detalles de boleta.
    let listaItems:IItems[] = this.generarItems(listaProductoPedido);
    
    //2. generando cuerpo de boleta.
    let invoice:IInvoice={
      operacion:'generar_comprobante',
      tipo_de_comprobante: 2,//boleta de venta
      serie: comprobanteElectronico.serie_boleta_pse,
      numero: 1, //**********Número correlativo del documento, sin ceros a la izquierda//necesario
      sunat_transaction: 1,//venta interna
      cliente_tipo_de_documento: conNombre? 1: 0,/*
                            6 = RUC - REGISTRO ÚNICO DE CONTRIBUYENTE, 1 = DNI - DOC. NACIONAL DE IDENTIDAD,- = VARIOS - VENTAS MENORES A S/.700.00 Y OTROS, 4 = CARNET DE EXTRANJERÍA, 7 = PASAPORTE,  A = CÉDULA DIPLOMÁTICA DE IDENTIDAD
                            B = DOC.IDENT.PAIS.RESIDENCIA-NO.D
                            0 = NO DOMICILIADO, SIN RUC (EXPORTACIÓN)
                            G = Salvoconducto*/
      cliente_numero_de_documento: conNombre? personaSend.dni:'',//**********revisar para cuando no se manda dni
      cliente_denominacion: conNombre?  personaSend.nombres +' '+personaSend.apellidoPaterno +' '+personaSend.apellidoMaterno:'',      
      fecha_de_emision: this.fechaHoy.dia+'-'+this.fechaHoy.mes+'-'+this.fechaHoy.anio, //'21-12-2024'
      moneda: 1,//soles
      tipo_de_cambio: '',
      porcentaje_de_igv: aspectosGenerales.tasa_impuesto,
      descuento_global: '',
      total_descuento: '',
      total_anticipo: '',
      total_gravada: pedido.sub_total,
      total_inafecta: '',
      total_exonerada: '',
      total_igv: pedido.igv,
      total_gratuita: '',
      total_otros_cargos: '',
      total: pedido.total,
      percepcion_tipo: '',
      percepcion_base_imponible: '',
      total_percepcion: '',      
      detraccion: false,
      documento_que_se_modifica_tipo: '',
      documento_que_se_modifica_serie: '',
      documento_que_se_modifica_numero: '',
      tipo_de_nota_de_credito: '',
      tipo_de_nota_de_debito: '',
      enviar_automaticamente_a_la_sunat: true,
      enviar_automaticamente_al_cliente: false,  
      condiciones_de_pago: '',
      tabla_personalizada_codigo: '',
      formato_de_pdf: '',
      items: listaItems      
    }
    //3. retornando cuerpo y detalle
    return invoice
    
  }

  async enviarBoletaPSEReseller(boleta:IInvoice, empresa:IEmpresa){
    const token = empresa.comprobante_electronico!.token_pse;
    const ruta = empresa.comprobante_electronico!.ruta_pse;

    const jsonObj = JSON.stringify(boleta,null,2);    
    // Convertir la cadena JSON a un array de bytes (utilizando la codificación original)
    const bytes = new TextEncoder().encode(jsonObj);
    // Convertir el array de bytes a una cadena UTF-8
    const json_en_utf_8 = new TextDecoder('utf-8').decode(bytes);
    try {
      // Configuramos los headers para la petición
      const headers = {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": `Token token=${token}`
      };

      // Realizamos la solicitud POST y obtenemos la respuesta
      const response = await fetch(ruta, {
          method: "POST",
          headers: headers,
          body: json_en_utf_8
      });

      // Verificamos si la respuesta fue exitosa
      if (!response.ok) {
          throw new Error(await response.text());
      }

      // Leemos y retornamos la respuesta como texto
      console.log(response);
      return await response.text();
  } catch (error:any) {
      // En caso de error, retornamos el mensaje de error
      console.log(error);
      return error.message;
  }

  }
}
