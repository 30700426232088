<div class="dialog">
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div mat-dialog-title>
        <mat-icon class="icon">{{Icons.WARNING}}</mat-icon>
        {{title}}
      </div>
    </div>
    <mat-divider></mat-divider>
    <form #f="ngForm" (ngSubmit)="comprobarPassword(f)">
    <mat-dialog-content class="dialog-content">
      <div *ngIf="!isLoading" >
        <div *ngIf="!validacionOK" >
          <section>{{msj}}</section>
          <div class="input-dialog">
            <mat-form-field appearance="outline">
              <input matInput
                [type]="hide?'password':'text'"
                required
                placeholder="Contraseña"
                name="password"
                autocomplete="off"
                autocomplete="new-password"
                #password="ngModel"
                [pattern]="PatternsRegex.onlyPassword"
                [(ngModel)]="passwordText"
                [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
                [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8 ">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide" type="button">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="password.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
              <mat-error *ngIf="password.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_PASSWORD}}</mat-error>
              <mat-error
                *ngIf="password.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8}}</mat-error>
              <mat-error
                *ngIf="password.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>

            </mat-form-field>
          </div>
          <div style="margin-top: 25px;">
            <button mat-button mat-dialog-close>Cancelar</button>
            <!-- cuando trabaja sin esperar accion en el input -->
            <button mat-button  type="submit" [disabled]="(f.invalid)" >Aceptar</button>
          </div>
        </div>
      </div>
      <div *ngIf="isLoading">
        <app-spinner></app-spinner>
      </div>
    </mat-dialog-content>
  </form>
  <form #g="ngForm" (ngSubmit)="registrarNuevoPassword(g)">
    <div *ngIf="validacionOK">
      <div *ngIf="!isLoadin2" >
        <section>Ingrese la nueva contraseña.</section>
        <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px" [style.marginTop.px]="15" >
        <mat-form-field >
          <input
            [type]="hide2?'password':'text'"
            matInput
            placeholder="Contraseña"
            name="password1"
            ngModel
            autocomplete="off"
            autocomplete="new-password"
            [(ngModel)]="nuevoPassword"
            [maxlength]="Length_Database.DB_LENGTH_SMALL_MAX_50"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8"
            required
            [pattern]="PatternsRegex.onlyPassword"
            #password1="ngModel">
            <button mat-icon-button matSuffix (click)="hide2 = !hide2" type="button" >
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          <mat-error *ngIf="password1.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error *ngIf="password1.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8CARACTERES}}</mat-error>
          <mat-error *ngIf="password1.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
          <mat-error *ngIf="password1.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_PASSWORD}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            [type]="hide3?'password':'text'"
            matInput
            placeholder="Repita la contraseña"
            name="password2"
            ngModel
            autocomplete="off"
            autocomplete="new-password"
            [(ngModel)]="contrasenia2"
            [maxlength]="Length_Database.DB_LENGTH_SMALL_MAX_50"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8"
            required
            [pattern]="PatternsRegex.onlyPassword"
            (blur)="validarPassword()"
            #password2="ngModel">
            <button mat-icon-button matSuffix (click)="hide3 = !hide3" type="button" >
              <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="password2.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="password2.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8CARACTERES}}</mat-error>
            <mat-error *ngIf="password2.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
            <mat-error *ngIf="password2.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_PASSWORD}}</mat-error>
            <mat-hint style="color: var(--red600);" *ngIf="errorContrasenia2">{{Msjs_Validations.MSJ_HINT_PASSWORD_IGUAL}}</mat-hint>
        </mat-form-field>
        </div>
        <div style="margin-top: 25px;">
          <button mat-button mat-dialog-close>Cancelar</button>
          <!-- cuando trabaja sin esperar accion en el input -->
          <button mat-button  type="submit" [disabled]="(g.invalid)" >Aceptar</button>
        </div>
      </div>
      <div *ngIf="isLoadin2" >
        <app-spinner></app-spinner>
      </div>
    </div>
  </form>
</div>
