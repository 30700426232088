import { Component, Inject, OnInit } from '@angular/core';
import { Pedido } from '../registrar-pedido/models/Pedido';
import { Icons } from '../../cons/icons';
import { Subscription } from 'rxjs';
import { Turno } from '../gestion-monto/models/Turno';
import { EstadosProductoType } from '../../types/estado-producto-type';
import { IProductoTicket } from '../registrar-pedido/models/IProductoTicket';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PedidoBlService } from '../registrar-pedido/services/services.bl/pedido.bl.service';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { UIService } from '../../services/ui.services';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { TableService } from '../table/table.service';
import { Msjs, Msjs_Validations, TableTypes } from '../../cons/common';
import { ITableEvento } from '../table/ITableEvento';
import { SnackBarType } from '../../types/snackbar-type';
import { TipoPagoType } from '../../types/tipo-pago-type';
import { GestionDescuentosBlService } from '../procesos/gestion-descuentos-incidencias/services/gestion-descuentos.bl.service';
import { IDescuento } from '../procesos/dialog/models/IDescuento';
import { AspectosGenerales } from '../procesos/gestion-aspectos-generales/models/AspectosGenerales';
import { GestionAspectosBlService } from '../procesos/gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { blinkAnimation } from '../../animations/animations';
import { RegistroService } from '../../services/registro.service';
import { GestionIncidenciasBlService } from '../procesos/gestion-descuentos-incidencias/services/gestion-incidencias.bl.service';
import { IIncidencia } from '../procesos/dialog/models/IInsidencia';

@Component({
  selector: 'app-gestion-pago-parcial',
  templateUrl: './gestion-pago-parcial.component.html',
  styleUrls: ['./gestion-pago-parcial.component.scss'],
  animations: [blinkAnimation]
})

@AutoUnsubscribe

export class GestionPagoParcialComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  pedido = new Pedido();
  turno = new Turno();
  listaProductoSb!:Subscription;
  historialSb!:Subscription;
  btnPagoTotalSb!:Subscription;
  tipoTabla: string = TableTypes.LISTAR_PEDIDOS_CAJA;

  listaProductoPedido: IProductoTicket[] = [];
  listaProductoSeleccionadoPago:IProductoTicket[]=[];
  totalProductos:number =0;
  readonly EstadosProductoType:typeof EstadosProductoType = EstadosProductoType;
  readonly TipoPagoType:typeof TipoPagoType = TipoPagoType;
  startY: number = 0;
  endY: number = 0;
  configSb!:Subscription;
  configIncidenciaSb!:Subscription;
  tasasSb = new Subscription();
  descuento!: IDescuento | null;
  incidencia!: IIncidencia | null;
  blinkState: string = 'inactive';
  esAdministrador:boolean = false;
  configTasasSb!:Subscription;
  aspectosGenerales = new AspectosGenerales();
  isLoading: boolean = false;
  disabledPrePagar:boolean = true;
  modalSb!:Subscription;


  
  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef:MatDialogRef<GestionPagoParcialComponent>,
    private pedidoBLService: PedidoBlService,
    private readonly spinnerPageService: SpinnerPageService,    
    private uiService:UIService,
    private tableService: TableService,
    private gestionDsctoBLService:GestionDescuentosBlService,
    private gestionIncidenciaBLService:GestionIncidenciasBlService,
    private gestionAspectosBL: GestionAspectosBlService,
    private registroService: RegistroService  
  ) { }

  ngOnInit(): void {
    if (this.registroService.empresa) {            

      if (this.registroService.esAdministrador) {
        this.esAdministrador = true;
      }
    }
    this.pedido = this.datoPasado.dato as Pedido;
    this.turno = this.datoPasado.turno as Turno;
    if (this.turno.id && this.pedido.id) {
      this.cargarProductosXPedido(this.turno.id, this.pedido.id);
      this.cargarDescuento();
      this.cargarIncidencia();
      this.isLoading=true;
      this.cargarTasas();  
    }
    //animacion
    setInterval(() => {
      this.blinkState = this.blinkState === 'active' ? 'inactive' : 'active';
    }, 800);  // Cambia el estado cada 500ms
  }

  cargarTasas(){
    this.gestionAspectosBL.existFetchTasas();
    this.configTasasSb?.unsubscribe();
    this.configTasasSb = this.gestionAspectosBL.getTasas().subscribe( aspGen=>{
      if (aspGen) {
        
        this.isLoading = false;
        this.aspectosGenerales = aspGen;
      }
    }
     
    )
  }

  separarPedido(){
    const prodsSel = this.listaProductoPedido.filter(prod=>prod.es_seleccionado);
    if(prodsSel.length==this.listaProductoPedido.length){
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_PRODUCTO_PREPAGADO_ERROR, 5000);     
      return;
    }
    if (this.turno.id && this.pedido.id) {
      const prodsSel = this.listaProductoPedido.filter(prod=>prod.es_seleccionado);

      if(prodsSel.length == 0){
        this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs_Validations.MSJ_MINIMO_PRODUCTO_PREPAGO, 5000);
        return;
      }

      this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION_SEPARAR, icon: Icons.QUESTION_MARK })
      .subscribe(async result => {
        if(result){
          console.log('separar');
        }
      });

    }
  }

  prePagarProductos(){
    const prodsSel = this.listaProductoPedido.filter(prod=>prod.es_seleccionado);
    if(prodsSel.length==this.listaProductoPedido.length){
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_PRODUCTO_PREPAGADO_ERROR, 5000);     
      return;
    }
    if (this.turno.id && this.pedido.id) {
      const prodsSel = this.listaProductoPedido.filter(prod=>prod.es_seleccionado);

      if(prodsSel.length == 0){
        this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs_Validations.MSJ_MINIMO_PRODUCTO_PREPAGO, 5000);
        return;
      }         
      const pagoParcial = this.uiService.showDialogCajaCobro({ dato: this.pedido, turno: this.turno, productos:this.listaProductoPedido, tipo_pago: TipoPagoType.PARCIAL});
      pagoParcial.afterClosed().subscribe((data)=>{
        //actualizando el pedido con descuento modificado, ya que el observable pasado por datoPasado, no se actualiza
        if(data){
          this.pedido = data.pedido as Pedido;
        }
      });
    }
  }

  seleccionarProd(selecionado:boolean, indice:number){

    if(selecionado){
      this.listaProductoPedido[indice].es_seleccionado = true;
    }else{
      delete this.listaProductoPedido[indice].es_seleccionado;
    }
    const prodsSel = this.listaProductoPedido.filter(prod=>prod.es_seleccionado);
    this.disabledPrePagar = prodsSel.length>0? false:true;
  }


  cargarDescuento() {
    this.gestionDsctoBLService.existFetchDscto();
    this.configSb?.unsubscribe();
    this.configSb = this.gestionDsctoBLService.getDscto().subscribe(dsctosre => {
      if (dsctosre != undefined) {
        if (dsctosre) {
          this.spinnerPageService.hide();
          this.descuento = dsctosre;
          this.descuento.id = dsctosre.id;
        }
        else {
          this.spinnerPageService.hide();
          this.descuento = null;
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_DSCTO_ERROR, 5000);          
          return;
        }
      }
    });
  }

  cargarIncidencia() {
    this.gestionIncidenciaBLService.existFetchIncidencia();
    this.configIncidenciaSb?.unsubscribe();
    this.configIncidenciaSb = this.gestionIncidenciaBLService.getIncidencia().subscribe(incidencias => {
      if (incidencias != undefined) {
        if (incidencias) {
          this.spinnerPageService.hide();
          this.incidencia = incidencias;
          this.incidencia.id = incidencias.id;
        }
        else {
          this.spinnerPageService.hide();
          this.descuento = null;
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_DSCTO_ERROR, 5000);          
          return;
        }
      }
    });
  }



  cargarProductosXPedido(idTurno: string, idPedido: string) {
    this.listaProductoSb = this.pedidoBLService.getProductosXPedido(idTurno, idPedido)
      .subscribe(listaProds => {
        if (listaProds) {
          this.totalProductos = 0;
          this.listaProductoPedido = [];
          this.spinnerPageService.hide();
          this.listaProductoPedido = JSON.parse(JSON.stringify(listaProds));
          //quitanto productos prepagados
          this.listaProductoPedido = JSON.parse(JSON.stringify(this.listaProductoPedido.filter(prod => prod.es_pagado == undefined)));
          this.listaProductoPedido.forEach(prod => {
            this.totalProductos += prod.cantidad_producto;
          });
        }
      });
  }

  onClose(){    
    if(this.listaProductoSb){
      this.listaProductoSb.unsubscribe();
    }    
    this.dialogRef.close();
  }

  touchStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY;
  }

  touchMove(event: TouchEvent) {
    this.endY = event.touches[0].clientY;
  }

  touchEnd(event: TouchEvent) {
    if (this.startY > this.endY + 30) {
      //console.log('Deslizado hacia arriba');
      // Acción específica al deslizar hacia arriba
    } else if (this.startY < this.endY - 30) {
      //console.log('Deslizado hacia abajo');
      // Acción específica al deslizar hacia abajo
    }
  }

  procesarProducto(opcion: number, producto:IProductoTicket) {
    switch (opcion) {

      case 1: {
        if (this.turno.id && this.pedido.id) {
          //validando que haya mas de un producto para hacer el prepago
          if(this.listaProductoPedido.length ==1){
            this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs_Validations.MSJ_MINIMO_PRODUCTO_PREPAGO, 5000);
            return;
          }         
          const pagoParcial = this.uiService.showDialogCajaCobro({ dato: this.pedido, turno: this.turno, producto:producto, tipo_pago: TipoPagoType.PARCIAL, otros_productos: this.listaProductoPedido });
          pagoParcial.afterClosed().subscribe((data)=>{
            //actualizando el pedido con descuento modificado, ya que el observable pasado por datoPasado, no se actualiza
            if(data){
              this.pedido = data.pedido as Pedido;
            }
          });
        }
        break;
      }

      case 2: {
        if (this.turno.id && this.pedido.id) {          
          if(this.descuento == null){
            this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_DSCTO_ERROR, 5000);
            return;
          }else{
            const dialgoDscto = this.uiService.showDialogGestionDescuento({ dato: this.pedido, turno: this.turno, producto:producto, tipo_pago: TipoPagoType.DESCUENTO, descuento: this.descuento, otros_productos: this.listaProductoPedido});            
            dialgoDscto.afterClosed().subscribe((data)=>{
              //actualizando el pedido con descuento modificado, ya que el observable pasado por datoPasado, no se actualiza
              if(data){
                this.pedido = data.pedido as Pedido;
              }
            });
          }
        }
        break;
      }
      
      case 3: {
        if (this.turno.id && this.pedido.id) {          
          if(this.incidencia == null){
            this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_INCIDENCIA_ERROR, 5000);
            return;
          }else{
            const dialgoDscto = this.uiService.showDialogGestionIncidencia({ dato: this.pedido, turno: this.turno, producto:producto, tipo_pago: TipoPagoType.INCIDENCIA, incidencia: this.incidencia, otros_productos: this.listaProductoPedido});            
            dialgoDscto.afterClosed().subscribe((data)=>{
              //actualizando el pedido con descuento modificado, ya que el observable pasado por datoPasado, no se actualiza
              if(data){
                this.pedido = data.pedido as Pedido;
              }
            });
          }
        }
        break;
      }
      
    }

  }

}
