import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Icons } from '../../cons/icons';
import { Msjs, Msjs_Validations, PatternsRegex } from '../../cons/common';
import { Utils } from '../../helpers/utils';
import { CHorario } from './horario.model';
import { HorarioService } from './horario.service';
import { NgForm } from '@angular/forms';
import { UIService } from '../../services/ui.services';
import { SnackBarType } from '../../types/snackbar-type';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';

@Component({
  selector: 'app-horario-trabajo',
  templateUrl: './horario-trabajo.component.html',
  styleUrls: ['./horario-trabajo.component.scss']
})

@AutoUnsubscribe

export class HorarioTrabajoComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  onlyHorasMinutos = Utils.InputOnlyHorasMinutos24H;
  @Input() horarioEmpresa:CHorario= new CHorario();
  @ViewChild('f') formulario!: NgForm;
  @Input() showSpinner:boolean=false;
  toogleDisabled:boolean =false;
  @Input() formaFila:boolean=true;//indica como debe comportarse cuando es mobile
  @Input() onlyTemplate:boolean = false;// indica que solo se usa como interfaz no modifica la configuracion a nivel empresa
  mostrarAdvertencia:boolean=false;
  @Input() disabled:boolean = false;



  constructor(
    private horarioService: HorarioService,
    private uiService:UIService,
  ) { }

  ngOnInit(): void {
  }

  onChange(e:any){
    if(this.formulario.valid){
      if(!this.onlyTemplate){
        this.horarioService.onSaveHorario$.next(this.horarioEmpresa);
      }
    }
    else{
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_FORMULARIO, 3000);
    }
  }

  gestionarCambio(f:NgForm){
    if(this.horarioEmpresa.hora_inicio && this.horarioEmpresa.hora_final){
      this.mostrarAdvertencia = this.checkTimeValidity(this.horarioEmpresa.hora_inicio, this.horarioEmpresa.hora_final);
    }
    if(f.valid){
      this.horarioService.hasErrorForm.next(false);//comunicamos si NO tiene error el formulario
      this.toogleDisabled = false;
      if(!this.onlyTemplate){
        this.horarioService.onSaveHorario$.next(this.horarioEmpresa);
      }

    }else{
      this.toogleDisabled = true; //comunicamos si TIENE error el formulario
      this.horarioService.hasErrorForm.next(true) ;
    }
  }

  checkTimeValidity(startTime: string, endTime: string): boolean {
    const startParts = startTime.split(':');
    const endParts = endTime.split(':');

    const startHour = parseInt(startParts[0], 10);
    const startMin = parseInt(startParts[1], 10);
    const endHour = parseInt(endParts[0], 10);
    const endMin = parseInt(endParts[1], 10);

    const startTotalMinutes = startHour * 60 + startMin;
    const endTotalMinutes = endHour * 60 + endMin;

    if (endTotalMinutes < startTotalMinutes) {
      return true;
    }
    return false; // Todo está válido
  }


}
