<div fxLayout="row" fxLayoutAlign="center center" [style.marginLeft.px]="5" [style.marginRight.px]="5">
  <form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <mat-card class="mat-card">
      <mat-card-header>
        <mat-card-title>3. Módulos</mat-card-title>
        <mat-card-subtitle>Selecciona tus módulos y cantidad de usuarios
        </mat-card-subtitle>

      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div *ngFor="let modulo of listaModulos$" >
          <app-modulo-plataforma
            [nombre]="modulo.nombre"
            [descripcion]="modulo.descripcion"
            [isDisabledToogle]="modulo.control_desactivado_togle"
            [isDisabledCantidad]="modulo.control_desactivado_todo"
            [isCheckedToogle]="modulo.checked_toogle"
            [totalColaboradores]="totalColaboradores"
            [cantidad_usuarios_x_defecto]="modulo.cantidad_usuarios_x_defecto"
            [costo_modulo]="modulo.costo_modulo"
            [is_disabled]="disabled_controls"
            [es_modulo_x_defecto]="modulo.es_modulo_por_defecto"
            [costo_usuario_adicional]="modulo.costo_usuario_adicional"
            [cantidadIn]="modulo.cantidadIn"
            [costoTotalModoEdicion]="modulo.costoTotalModoEdicion"
            [orden]="modulo.orden"
            [codigoModulo]="modulo.codigo"
            [esModoEdicion]="esModoEdicion"
            [facturacion]="facturacion"
            [es_existente]="modulo.es_existente"
            #modulo
            ></app-modulo-plataforma
            >
        </div>
        <div>
          <div *ngIf="esDecremento" class="label-decremento" fxLayout="row">
            <mat-icon [@blink]="blinkState">{{Icons.PENDING_ACTIONS}}</mat-icon>
            <h5> &nbsp; PENDIENTE: Reducir cantidad de usuario(s):</h5>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div><h3>{{totalDecremento}}</h3></div>
          </div>
          <div *ngIf="esAnularModulo" class="label-decremento" fxLayout="row">
            <mat-icon [@blink]="blinkState">{{Icons.PENDING_ACTIONS}}</mat-icon>
            <h5> &nbsp; PENDIENTE: Desactivar módulo(s):</h5>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div><h3>{{totalAnulacionModulo}}</h3></div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="column" fxLayoutAlign="start start">
          <div fxLayout="row" fxLayoutAlign="space-evenly center"  class="label-total">
            <h5>Costo Total Mensual :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </h5>
            <h3>  S/ {{totalMontoModulo | number:'1.2-2'}}</h3>
          </div>
          <div class="label-igv" >
            <h6 *ngIf="totalMontoModulo>0" >No incluye IGV.</h6>
          </div>
          <div class="label-igv" *ngIf="esTiempoPrueba" matTooltip="El primer costo
          dependerá de los días usados posteriores al vencimiento
          de los días de prueba hasta  el cierre del período (día 30 de cada mes)." >
            <h6>Terminado el tiempo de PRUEBA (10 días):
              <br>
              Se calculará el costo, al día 30 del mes en curso.
            </h6>
          </div>

        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row" class="button-costos-fact" >
          <app-button text="Costos y Facturación" type="basic" (click)="mostrarCostos()" ></app-button>
        </div>
        <div class="label-terminos" fxLayout="row">
          <mat-slide-toggle
          color="primary"
          name="terminos"
          [disabled]="disabled_controls"
          ngModel
          #terminos="ngModel"
          [(ngModel)]="terminosCondiciones"
          (change)="changeTerminosCondiciones($event, f)"
          required
          >Acepto los Términos y condiciones</mat-slide-toggle>
        </div>
      </mat-card-content>
      <mat-card-actions align="start" [style.marginLeft.px]="5" *ngIf="!disabled_controls" >
        <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="f.invalid"
        [attr_type]="'submit'"></app-button>
        <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" color="gray"
          (click)="Limpiar(f)"></app-button>
        <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING"
          (click)="Limpiar(f)"></app-button>
      </mat-card-actions>
      <mat-card-footer>
    </mat-card-footer>
    </mat-card>
  </form>
</div>
