import { Component, Inject, OnInit } from '@angular/core';
import { Icons } from '../../cons/icons';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UIService } from '../../services/ui.services';
import { IPedidoMesa } from './models/IPedidoMesa';

@Component({
  selector: 'app-listar-ambiente-mesa-ocupado-dialog',
  templateUrl: './listar-ambiente-mesa-ocupado-dialog.component.html',
  styleUrls: ['./listar-ambiente-mesa-ocupado-dialog.component.scss']
})

@AutoUnsubscribe

export class ListarAmbienteMesaOcupadoDialogComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  mesaSel:string="";
  listaMesaOcupada:IPedidoMesa[]=[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef:MatDialogRef<ListarAmbienteMesaOcupadoDialogComponent>,
    private uiService:UIService,
  ) { }

  ngOnInit(): void {
    this.listaMesaOcupada = JSON.parse(JSON.stringify(this.datoPasado.lista_mesa as IPedidoMesa[])); 
  }

  onClose(){
    this.dialogRef.close({mesa_seleccionada:this.mesaSel});
  }

}
