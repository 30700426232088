import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Msjs_Validations, SistOperation, TableTypes, TamanioPagina } from '../../cons/common';
import { Table } from '../table/table.model';
import { Subscription } from 'rxjs';
import { Icons } from '../../cons/icons';
import { Producto } from '../procesos/dialog/models/Producto';
import { TableService } from '../table/table.service';
import { RegistroService } from '../../services/registro.service';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { DialogProductoBlService } from '../procesos/dialog/services/services.bl/dialog-producto.bl.service';
import { PaginationService } from '../pagination/pagination.service';
import { ITableEvento } from '../table/ITableEvento';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UIService } from '../../services/ui.services';
import { IProducto } from '../procesos/dialog/models/IProducto';
import { IProductoAnidado } from '../procesos/dialog/models/IProductoAnidado';
import { ICaracteristicaProducto } from '../registrar-pedido/models/CaracteristicaProducto';

@Component({
  selector: 'app-listar-producto-combo',
  templateUrl: './listar-producto-combo.component.html',
  styleUrls: ['./listar-producto-combo.component.scss']
})
export class ListarProductoComboComponent implements OnInit {
  tipoTabla:string = TableTypes.LISTAR_PRODUCTO;
  readonly Icons: typeof Icons = Icons;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  isLoading:boolean = false;
  productoCombo!: IProducto;
  subProductosCombo:IProductoAnidado[]=[];
  miFormulario!: FormGroup;
  contadorArray: number[] = [];
  listaCarat:ICaracteristicaProducto[]=[];

  constructor(
    private fb: FormBuilder,                   
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef:MatDialogRef<ListarProductoComboComponent>,   

  ) { 
 
  }

  ngOnInit(): void {
    this.productoCombo = this.datoPasado.dato as IProducto;
    //generando lista de productos
    this.productoCombo.subproductos?.forEach(prod=>{
      if(prod.cantidad)
      for(let i=0; i<prod.cantidad; i++){
        const subProd:IProductoAnidado={
          id:prod.id,
          cantidad:1,
          producto:prod.producto
        }
        this.subProductosCombo.push(subProd);
      }
    });    
    this.inicializarFormulario();
  }

  inicializarFormulario() {
    this.miFormulario = this.fb.group({
      subProductos: this.fb.array([])
    });
    this.generarControles();
  }


  generarControles(){
    const subProductosControl = this.miFormulario.get('subProductos') as FormArray;
    this.subProductosCombo.forEach((_, index) => {      
      subProductosControl.push(this.fb.group({        
        caracteristica: [!_.producto.tiene_caracteristicas?_.producto.nombre_producto:'', Validators.required]
      })); 
    });  
  }

  get subProductosFormArray() {
    return this.miFormulario.get('subProductos') as FormArray;
  }

  onClose(){
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.miFormulario.get('subProductos')?.valid) {
      const subProductosValores: any[] = this.miFormulario.get('subProductos')?.value;
      
      subProductosValores.forEach((valor, index) => {
        const productoActual = this.subProductosCombo[index].producto;
        const caracteristicaSeleccionada = valor.caracteristica == productoActual.nombre_producto ? '---' : valor.caracteristica;
        
        // Busca si ya existe una característica seleccionada para este producto
        const caracteristicaExistente = this.listaCarat.find(caract => 
          caract.codigo_producto === productoActual.codigo_producto &&
          caract.caracteristica_seleccionada === caracteristicaSeleccionada);
  
        if (caracteristicaExistente) {
          caracteristicaExistente.cantidad += 1;
        } else {
          this.listaCarat.push({
            caracteristica_seleccionada: caracteristicaSeleccionada,
            tipo_producto: productoActual.tipo_producto,
            codigo_producto: productoActual.codigo_producto,
            nombre_producto: productoActual.nombre_producto,
            cantidad: 1
          });
        }
      });
  
      // Retornamos el valor
      this.dialogRef.close(this.listaCarat);
    }
  }
  

  Limpiar(){
    this.miFormulario.reset();
  }
}
