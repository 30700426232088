import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutAdminComponent } from './modules/admin/layout-admin/layout-admin.component';
import { LayoutComponent } from './modules/navigation/layout/layout.component';
import { StaticRoutes } from "./shared/cons/route.class";
import { AdminGuard } from './shared/auth/admin.guard';
import { ErrorComponent } from './modules/navigation/landing/error/error.component';
import { CartaComponent } from './modules/navigation/landing/externo/carta/carta.component';
import { LayoutPlataformaComponent } from './modules/admin-plataforma/layout-plataforma/layout-plataforma.component';

const routes: Routes = [
  //solo al inicio
  { path: "", redirectTo: StaticRoutes.INICIO+"/"+StaticRoutes.TOUR, pathMatch: "full" },  
  { path: StaticRoutes.PARAMETRO_CARTA, component:CartaComponent, },
  {
    path: StaticRoutes.INICIO,
    component:LayoutComponent,
    loadChildren: ()=> import("./modules/navigation/navigation.module")
    .then((m)=> m.NavigationModule)
  },
  //solo para administración y gestión de usuarios de negocios
  {
    path: StaticRoutes.ADMINISTRADOR,
    canLoad:[AdminGuard],//se verifica que minimamente este autenticado
    component:LayoutAdminComponent,
    loadChildren: ()=> import("./modules/admin/admin.module")
    .then((m)=> m.AdminModule)
  },
  // solo para administracion de plataforma (super administradores)
  {
    path: StaticRoutes.SP_CONSOLA,
    component:LayoutPlataformaComponent,
    loadChildren: ()=> import("./modules/admin-plataforma/admin-plataforma.module")
    .then((m)=> m.AdminPlataformaModule)
  },
  //cuando no se encuentra la pagina
  { path: '**', component: ErrorComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers:[AdminGuard]
})
export class AppRoutingModule { }
