import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Auditoria, colections, documentColaborador, documentsEmpresa, documentsUsuarioColaborador } from 'src/app/shared/cons/db.colections';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { IUsuarioColaborador } from '../../procesos/gestion-colaborador/models/usuario-colaborador.model';
import { Utils } from 'src/app/shared/helpers/utils';
import { CColaborador } from '../../procesos/gestion-colaborador/models/ccolaborador.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription, combineLatest, from, of } from 'rxjs';
import { IEmpresa } from '../../dialog/models/empresa.model';
import { CEmpresa } from '../../dialog/models/cempresa.model';

@AutoUnsubscribe

@Injectable({
  providedIn: 'root'
})

export class UsuarioColaboradorDaService {

  //subject que servirá de cache
  private usuarioColaboradorEmpresaCache: BehaviorSubject<CColaborador | null |undefined > = new BehaviorSubject<CColaborador | null | undefined>(null);
  //variable expuesta pra consumo
  public usuarioColaboradorEmpresa$: Observable<CColaborador | null | undefined> = this.usuarioColaboradorEmpresaCache.asObservable();
  //eliminar la suscripcion
  private fetchUsrColabEmpSubscription!: Subscription;

  constructor(
    private db: AngularFirestore,
  ) { }

  private removeUtilProperties(usuarioColaborador:CColaborador): CColaborador {
    delete usuarioColaborador.empresa;
    return usuarioColaborador;
  }

      /*
  metodos cache
  */
  //solo llamar para recargar esta informacion
  fetchUserColabEmpById(idColaboradorCuenta: string): void {
    if(this.fetchUsrColabEmpSubscription && !this.fetchUsrColabEmpSubscription.closed){
      this.usuarioColaboradorEmpresaCache.next(this.usuarioColaboradorEmpresaCache.value);
      return;
    }
    // if(this.fetchUsrColabEmpSubscription){
    //   this.fetchUsrColabEmpSubscription.unsubscribe();
    // }
    this.fetchUsrColabEmpSubscription = this.getUsuarioColaboradorXEmpresa(idColaboradorCuenta).subscribe(colaborador => {
      this.usuarioColaboradorEmpresaCache.next(colaborador);
    });
  }

  stopFetchingUsuarioColaborador(): void {
    if (this.fetchUsrColabEmpSubscription) {
      this.fetchUsrColabEmpSubscription.unsubscribe();
    }
    this.usuarioColaboradorEmpresaCache.next(undefined);
  }
  /********************/

  private getUsuarioColaboradorXEmpresa(idColaboradorCuenta: string): Observable<CColaborador | null> {
    return from(this.db.collection(colections.USUARIO_COLABORADOR, ref => ref
      .where(documentsUsuarioColaborador.ID_USUARIO_CUENTA, '==', idColaboradorCuenta)
      ).get())
      .pipe(
        switchMap(doc => {
          if (!doc.empty) {
            const datos = Utils.convertDate(doc.docs[0].data()) as IUsuarioColaborador;
            const id = doc.docs[0].id;

            const observableGetEmpresa = this.getEmpresa(datos);
            const observableGetColaborador = this.getColaborador(datos);

            return combineLatest([observableGetEmpresa, observableGetColaborador]).pipe(
              map(([empresa, colaborador]) => {
                if (empresa && colaborador) {
                  colaborador.empresa = empresa;
                  return colaborador;
                } else {
                  return null;
                }
              }));
          } else {
            return of(null);
          }
        }),
        catchError(error => {
          //console.log(error);
          return of(null);
        })
      );
  }

  getEmpresa(usuarioColaborador: IUsuarioColaborador): Observable<any> {
    return this.db.collection(colections.EMPRESA,
      ref => ref.where(Auditoria.es_borrado, '==', false)
        .where(Auditoria.es_vigente, '==', true)
      )
      .doc(usuarioColaborador.id_empresa)
      .snapshotChanges()
      .pipe(
        map(empresa => {
          if (empresa) {
            const datosEmp = (Utils.convertDate(empresa.payload.data())) as IEmpresa;
            const id = empresa.payload.id;
            const data = { id, ...datosEmp } as IEmpresa;
            return data;
          } else {
            return of();
          }
        })
      );
  }

  getColaborador(usuarioColaborador: IUsuarioColaborador): Observable<CColaborador | null> {
    return this.db.collection(colections.EMPRESA).doc(usuarioColaborador.id_empresa)
      .collection(colections.COLABORADOR, ref1 => ref1
        .where(documentColaborador.ID_USUARIO_CUENTA, '==', usuarioColaborador.id_usuario_cuenta)
        //.where(documentColaborador.ES_VIGENTE, '==', true)
        .where(documentColaborador.ES_BORRADO, '==', false))
      .snapshotChanges()
      .pipe(
        map(colaboradores => {
          if (colaboradores.length > 0) {
            const datosColab = (Utils.convertDate(colaboradores[0].payload.doc.data())) as CColaborador;
            datosColab.id = colaboradores[0].payload.doc.id;
            return datosColab;
          } else {
            return null;
          }
        })
      );
  }
}
