<div fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title class="dialog-title">{{titleModal}}</div>
  <app-button *ngIf="!isLoading" type="icon" color="gray" [icon_fig]="CLOSE" (click)="onClose()"
    [attr_disabled]="isLoading"></app-button>
</div>
<mat-divider></mat-divider>
<form fxLayout="column" #f="ngForm" (ngSubmit)="onSubmit(f)">
  <mat-dialog-content>
    <section *ngIf="!isLoading">
      <div style="margin-bottom: 10px;" *ngIf="datoPasado.operacion===SistOperation.PROCESAR_SOLICITUD" [matTooltip]="'Solicitado: '+ usuarioSolicitante" >
        Monto anterior: S/{{this.efectivoAnterior}}        
      </div>
      <div>        
        <mat-form-field>
          <mat-label>{{labelTxt}}</mat-label>
          <input type="text"
            matInput
            placeholder="S/"
            ngModel
            name="monto"
            [(ngModel)]="turno.monto_inicial_efectivo"
            [pattern]="PatternsRegex.onlyDecimalIntegerUnsigned"
            (focus)="seleccionarTodo($event)"
            (blur)="completarDecimal($event)"
            (keydown)="($event.keyCode < 48 || ($event.keyCode > 57 && $event.keyCode < 96) ||
              $event.keyCode > 105) &&
              [8, 9, 37, 38, 39, 40, 46, 110, 190].indexOf($event.keyCode) === -1 ? $event.preventDefault() : null"
            #montoInput="ngModel" required maxlength="8" minlength="1">
          <mat-error *ngIf="montoInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error *ngIf="montoInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
          <mat-error *ngIf="montoInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_MAX8_DIGITOS}}</mat-error>
          <mat-error *ngIf="montoInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_MIN1_DIGITOS}}</mat-error>
        </mat-form-field>
        <ng-container *ngIf="this.datoPasado.operacion===SistOperation.RETIRAR ||
          this.datoPasado.operacion===SistOperation.PROCESAR_RETIRAR        
        " >
          <mat-form-field class="text-box-multi" appearance="outline">
            <mat-label>Anotación</mat-label>
            <textarea matInput         
            ngModel
            [disabled]="this.datoPasado.operacion===SistOperation.PROCESAR_RETIRAR"
            [(ngModel)]="anotacionRetiro"
            [pattern]="PatternsRegex.onlyGoodCharsWithNumbersAndSpace"            
            name="anotacion"
            #anotacion="ngModel"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
            [maxlength]="Length_Database.DB_LENGTH_LARGE_MAX_250"
            ></textarea>            
            <mat-error
              *ngIf="anotacion.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
            <mat-error
              *ngIf="anotacion.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
            <mat-error
              *ngIf="anotacion.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
          </mat-form-field>
        </ng-container>     
      </div>

    </section>
    <section *ngIf="isLoading">
      <app-spinner></app-spinner>
    </section>
  </mat-dialog-content>
  <mat-card-actions 
    *ngIf="datoPasado.operacion!==SistOperation.PROCESAR_SOLICITUD &&
    datoPasado.operacion!==SistOperation.PROCESAR_AGREGAR &&
    datoPasado.operacion!==SistOperation.PROCESAR_RETIRAR
    " 
    fxLayout="row" 
    fxLayoutAlign="space-around center">
    <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="(f.invalid)"
      [attr_type]="'submit'"></app-button>
    <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" color="gray" (click)="Limpiar(f)"></app-button>
    <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING"
      (click)="Limpiar(f)"></app-button>
  </mat-card-actions>
  <section *ngIf="!isLoading" >
    <mat-card-actions   
    *ngIf="datoPasado.operacion===SistOperation.PROCESAR_SOLICITUD ||
      datoPasado.operacion===SistOperation.PROCESAR_AGREGAR ||
      datoPasado.operacion===SistOperation.PROCESAR_RETIRAR
    " 
    fxLayout="row" 
    fxLayoutAlign="space-around center">
      <!-- viaja por el submit -->
      <app-button [width]="100" text="Aprobar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="(f.invalid)"
        [attr_type]="'submit'"></app-button>
        <!-- viaja por onProcesar -->
      <app-button fxHide.xs type="outline" [width]="100" text="Rechazar" color="gray" (click)="onProcesar(f, false)"></app-button>
      <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CANCEL"
      (click)="onProcesar(f, false)"></app-button>
    </mat-card-actions>
  </section>
 
</form>
