import { Injectable } from '@angular/core';
import { Producto } from '../../models/Producto';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UploadService } from 'src/app/shared/components/upload/upload.service';
import { Utils } from 'src/app/shared/helpers/utils';
import { deleteField, serverTimestamp } from 'firebase/firestore';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { IResumenProducto } from '../../models/IResumenProducto';
import { documentProducto, Auditoria, documentsEmpresa, colections, documentCatalogo } from 'src/app/shared/cons/db.colections';
import { catchError, map } from 'rxjs/operators';
import { ITableFiltroProducto } from '../../models/ITableFiltroProducto';
import { Observable, combineLatest, of } from 'rxjs';
import { MsjEstado } from 'src/app/shared/cons/common';
import { ContadorDocumento } from 'src/app/shared/models/ContadorDocumento.model';
import { IImageUploaded } from 'src/app/shared/components/upload/IImageUploaded';
import { ICatalogo } from '../../models/ICatalogo';
import { Catalogo } from '../../models/Catalogo';


@Injectable({
  providedIn: 'root'
})
export class DialogProductoDaService {
  transaccion: TransaccionModel = new TransaccionModel();
  contadorResumenUpdate!:IResumenProducto;
  contadorResumenGet!:IResumenProducto;
  productosAnidadosOtros:Producto[]=[];

   //busqueda y paginacion
   private filtroProducto:ITableFiltroProducto ={
    itemsPerPage:5,
    currentPage : 1,
    nombresFiltro:null,
    codigoFiltro:null,
    estadoFiltro:null,
    nextPage:null,
    previousPage:null,
    minPage:null,
    maxPage:null,
    ordenamiento:'desc',
  }


  constructor(
    private db: AngularFirestore,
    private uploadService: UploadService,
  ) { }


  private removeUtilProperties(producto:Producto): Producto {
    delete producto.estado;
    delete producto.fecha_registro;
    delete producto.masElementos;
    delete producto.totalRegistros_query;
    delete producto.previousPage;
    delete producto.nextPage;
    delete producto.numeracion;
    delete producto.totalRegistrosActivos;
    delete producto.totalRegistrosInactivos;
    delete producto.totalRegistros_doc;
    delete producto.stock_ilimitado_label;
    delete producto.tiene_subproductos_label;
    delete producto.cantidad_imagenes;
    delete producto.total_stock;
    delete producto.lista_imagen;
    delete producto.ref;
    return producto;
  }

  utilCleanFlags(){
    this.filtroProducto.firstVisibleDoc = null;
    this.filtroProducto.lastVisibleDoc =null;
    this.filtroProducto.ordenamiento = 'desc';
  }




  getProductoResumenCombinado(idEmpresa: string, idUsuario: string, filtroProducto: ITableFiltroProducto): Observable<Producto[]> {
    const observableResumen = this.getProductoResumen(idEmpresa);
    const observableProductos = this.getProductos(idEmpresa, idUsuario, filtroProducto);

    return combineLatest([observableResumen, observableProductos]).pipe(
      map(([productoResumen, productoList]) => {
        if (productoList?.length && productoResumen !== null) {
          let nFinal = 0;
          let nInicial = 0;
          if (this.filtroProducto.ordenamiento == 'desc') {
            nFinal = this.filtroProducto.itemsPerPage * this.filtroProducto.currentPage;
            nInicial = nFinal - this.filtroProducto.itemsPerPage + 1;
          } else {
            nFinal = productoResumen.total_productos;
            nInicial = productoResumen.total_productos - this.filtroProducto.itemsPerPage * (this.filtroProducto.currentPage - 1);
          }

          productoList.forEach((elemento) => {
            elemento.numeracion = nInicial;
            elemento.totalRegistros_doc = productoResumen.total_productos;
            elemento.totalRegistrosActivos = productoResumen.total_productos_activos;
            elemento.totalRegistrosInactivos = productoResumen.total_productos_inactivos;
            nInicial = this.filtroProducto.ordenamiento == 'desc' ? nInicial + 1 : nInicial - 1;
          });

          return productoList; // Emitir los últimos resultados procesados
        } else {
          // Código para manejar el caso en que la lista esté vacía
          const listaProductoUtil: Producto[] = [];
          const productoUtilitario = new Producto();
          productoUtilitario.totalRegistros_doc = productoResumen.total_productos;
          productoUtilitario.totalRegistrosActivos = productoResumen.total_productos_activos;
          productoUtilitario.totalRegistrosInactivos = productoResumen.total_productos_inactivos;
          listaProductoUtil.push(productoUtilitario);
          return listaProductoUtil; // Emitir los resultados procesados
        }
      }),
      catchError(error=>{
            console.error('Error en getColaboradorResumenCombinado:', error);
            return of([]);
      })
    );
  }

  getProductoResumen(idEmpresa:string):Observable<IResumenProducto>{
    const docEmpresa = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docProductoResumen = docEmpresa.collection(colections.PRODUCTO_RESUMEN);

    return docProductoResumen.snapshotChanges()
    .pipe(
      map(resumen=>{
        if(resumen.length>0){
          const datos = resumen[0].payload.doc.data() as IResumenProducto;
          datos.id_documento = resumen[0].payload.doc.id;
          return datos;
        }
        else{
          const resumenFict:IResumenProducto = {
            total_productos: 0,
            total_productos_activos: 0,
            total_productos_inactivos: 0,
            fecha_actualizacion: null,
            id_documento: undefined,
          };
          return resumenFict;
        }

      }),
      catchError(error => {
        console.error('Error en getColaboradorResumen:', error);
        // Puedes retornar un valor por defecto o rethrow del error
        // Aquí estamos rethrowing el error para que cualquier suscriptor pueda manejarlo
        throw error;
      })
    );
  }

  getProductos(
    idEmpresa: string,
    idUsuario: string,
    filtroProducto: ITableFiltroProducto
  ){

    this.filtroProducto.itemsPerPage = filtroProducto.itemsPerPage;//seteamos los valores
    this.filtroProducto.currentPage = filtroProducto.currentPage;
    this.filtroProducto.nombresFiltro = filtroProducto.nombresFiltro;
    this.filtroProducto.codigoFiltro = filtroProducto.codigoFiltro;
    this.filtroProducto.estadoFiltro = filtroProducto.estadoFiltro;
    this.filtroProducto.nextPage = filtroProducto.nextPage;
    this.filtroProducto.previousPage = filtroProducto.previousPage;
    this.filtroProducto.minPage = filtroProducto.minPage;
    this.filtroProducto.maxPage = filtroProducto.maxPage;

    let listaProductosLocal: Producto[] = [];
    if (this.filtroProducto.minPage) {
      this.filtroProducto.ordenamiento = 'desc';
    }
    if (this.filtroProducto.maxPage) {
      this.filtroProducto.ordenamiento = 'asc';
    }

    return this.db.collection(colections.EMPRESA, (ref) =>
    ref.where(Auditoria.es_borrado, '==', false)
      .where(documentsEmpresa.ID_DOC_USUARIO, '==', idUsuario))
    .doc(idEmpresa)
    .collection(colections.PRODUCTO, (ref1) => {
      let query = ref1
        .orderBy(documentProducto.AUTO_NUMERICO, this.filtroProducto.ordenamiento)
        .where(Auditoria.es_borrado, '==', false);

      if (this.filtroProducto.nombresFiltro) {
        const lFiltroNombre = Utils.CadenaToArray(this.filtroProducto.nombresFiltro);
        query = query
          .where(documentProducto.NOMBRES_COMPLETO, 'array-contains-any', lFiltroNombre)
      }
      if (this.filtroProducto.codigoFiltro) {
        query = query
          .where(documentProducto.CODIGO_PRODUCTO, '==', this.filtroProducto.codigoFiltro)
      }
      if (this.filtroProducto.estadoFiltro != null) {
        query = query
          .where(Auditoria.es_vigente, '==', this.filtroProducto.estadoFiltro)

      }
      if (this.filtroProducto.nextPage == true)
      {
        query = query.startAfter(this.filtroProducto.lastVisibleDoc)
      }
      //no se implementa retroceso en busqueda
      if (this.filtroProducto.previousPage == true)
      {
        query = query.endBefore(this.filtroProducto.firstVisibleDoc)
      }

      if (this.filtroProducto.previousPage)
      {

        query = query.limitToLast(this.filtroProducto.itemsPerPage);
      }
      else{
        query = query.limit(this.filtroProducto.itemsPerPage+1);// se suma 1 para traer un elemento más
      }

      return query;
    })
    .snapshotChanges()
    .pipe(
      map((productos) => {
        if (productos.length > 0) {
          // **para paginación
          this.filtroProducto.firstVisibleDoc = productos[0].payload.doc;
          const productosListaDatos = [...productos];

          // para paginacion con busqueda
          let masElementos: boolean = false;
          if (productos.length > this.filtroProducto.itemsPerPage) {
            this.filtroProducto.lastVisibleDoc = productos[productos.length - 2].payload.doc;
            masElementos = true;
            productosListaDatos.pop();
          }
          else {
            //cuando viene de una pagina previa y la pginacion actual será 1
            if (this.filtroProducto.previousPage &&
              this.filtroProducto.currentPage>1) {
                masElementos = true;
            }else{
              masElementos = false;
            }
            this.filtroProducto.lastVisibleDoc = productos[productos.length - 1].payload.doc;
          }

          // limpiamos el array y generamos numeracion
          listaProductosLocal.length = 0;

          productosListaDatos.forEach((prod) => {
            const productoData = Utils.convertDate({ ...prod.payload.doc.data(), }) as Producto;
            // **generando propiedades utilitarias
            productoData.estado = productoData.es_vigente ? MsjEstado.ACTIVO.toLowerCase() : MsjEstado.INACTIVO.toLowerCase();
            productoData.fecha_registro = Utils.ToDateISO(productoData.fecha_creacion, false);
            productoData.id = prod.payload.doc.id;
            productoData.masElementos = masElementos;
            productoData.totalRegistros_query = productosListaDatos.length; // indica cuántos registros hay por query
            productoData.previousPage= this.filtroProducto.previousPage?true: undefined;
            productoData.nextPage= this.filtroProducto.nextPage?true: undefined;

            productoData.stock_ilimitado_label = productoData.stock_ilimitado?'Si':'No';
            productoData.tiene_subproductos_label = productoData.tiene_subproductos?'Si':'No';
            productoData.cantidad_imagenes = productoData.imagen==undefined?0: Utils.ObjecToListToArray(productoData.imagen).length;
            productoData.lista_imagen = productoData.imagen==undefined?[]: Utils.ObjecToListToArray(productoData.imagen);
            productoData.total_stock = productoData.stock_ilimitado==false?productoData.cantidad_stock?.toString():'Ilimitado';
            listaProductosLocal.push(productoData);
          });

          return listaProductosLocal
        } else {
          this.filtroProducto.firstVisibleDoc = null;
          this.filtroProducto.lastVisibleDoc = null;

          const prodFic: Producto = new Producto();
          const prodListaFic: Producto[] = new Array();
          prodFic.totalRegistros_query=0;
          prodListaFic.push(prodFic);

          return prodListaFic;
        }
      }),
      catchError(error => {
        //console.log(error);
        return of([])
      })
    );
  }

  getProductosAutocomplete(
    idEmpresa: string,
    idUsuario: string,
    nombreProducto: string
  ){

    let listaProductosLocal: Producto[] = [];
    this.filtroProducto.ordenamiento = 'desc';
    const nombreFiltro = Utils.CadenaToArray(nombreProducto);
    return this.db.collection(colections.EMPRESA, (ref) =>
    ref.where(Auditoria.es_borrado, '==', false)
      .where(documentsEmpresa.ID_DOC_USUARIO, '==', idUsuario))
    .doc(idEmpresa)
    .collection(colections.PRODUCTO, (ref1) => {
      let query = ref1
        .where(Auditoria.es_borrado, '==', false)
        .where(documentProducto.NOMBRES_COMPLETO, 'array-contains-any', nombreFiltro)
        .limit(5)
      return query;
    })
    .get().pipe(map(listaProductos=>{
      if(!listaProductos.empty){
        listaProductos.forEach(producto=>{
          const productoData = Utils.convertDate({ ...producto.data(), }) as Producto;
          productoData.id = producto.id;
          listaProductosLocal.push(productoData);
        });
       return listaProductosLocal;
      }else{
        return listaProductosLocal;
      }
    }));
  }

  async insertProducto(producto: Producto, idEmpresa: string, correoUsuario:string,files_upload: File[], pathFileAPI: string) {
    // Serialize el objeto producto para la base de datos.
    let isResumenInsert:boolean = false;
    let resumenDocInsert!:IResumenProducto;
    let resumenDocUpd!:IResumenProducto;
     let resumenDocGet!:IResumenProducto;

    const docProducto = Utils.SerializeJsonToDb(producto);
    docProducto.fecha_creacion = serverTimestamp();
    docProducto.usuario_creacion = correoUsuario;

    // Sube los archivos si existen.
    if (files_upload.length > 0) {
      const urls = await this.uploadService.onSaveFiles(files_upload, pathFileAPI);
      docProducto.imagen = urls;
    }
    // Agrega el producto a la base de datos.
    try {
      const txResult = await this.db.firestore.runTransaction(async(transaccion)=>{
        const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
        const docProdRef = docEmpRef.collection(colections.PRODUCTO);
        const docResumenProdRef = docEmpRef.collection(colections.PRODUCTO_RESUMEN);

        await docResumenProdRef.get().toPromise()
        .then(docResumenRef=>{
          //cuando no existe ningun producto
          if(docResumenRef.empty){
            docProducto.auto_numerico=1;

            resumenDocInsert = {
              total_productos:1,
              total_productos_activos: producto.es_vigente? 1:0,
              total_productos_inactivos:!producto.es_vigente? 1:0,
              fecha_actualizacion: serverTimestamp()
            }
            isResumenInsert=true;
          }
          //cuando existen productos
          else{
            isResumenInsert=false;

            resumenDocGet = Utils.convertDate(docResumenRef.docs[0].data()) as IResumenProducto;
            resumenDocGet.id_documento = docResumenRef.docs[0].id;
            const total_docs = resumenDocGet.total_productos+1;
            //seteando el total docs al autonumerico
            docProducto.auto_numerico = total_docs;
            //seteando nuevos valores
            resumenDocUpd = {
              total_productos:total_docs,
              total_productos_activos: producto.es_vigente? resumenDocGet.total_productos_activos+1: resumenDocGet.total_productos_activos,
              total_productos_inactivos: !producto.es_vigente? resumenDocGet.total_productos_inactivos+1: resumenDocGet.total_productos_inactivos,
              fecha_actualizacion: serverTimestamp()
            }
          }
        });

        //seteando valores
        transaccion.set(docProdRef.doc().ref, docProducto);
        if(isResumenInsert){
          transaccion.set(docResumenProdRef.doc().ref, resumenDocInsert);
        }else{
          transaccion.update(docResumenProdRef.doc(resumenDocGet.id_documento).ref, resumenDocUpd);
        }

        const newProducto = docProdRef.ref.id;
        const newDocResumenProd = docResumenProdRef.doc().ref.id;

        this.transaccion = {
          tx: true,
          data: {
            idProducto: newProducto,
            idResumentProducto: newDocResumenProd
          }
        };
        return this.transaccion;

      });
      return txResult;
    } catch (error) {
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }
  }

  async updateProductoEstado(idEmpresa: string, correoUsuario: string, producto: Producto, esActivar:boolean) {
    try{
      const transactionResult = await this.db.firestore.runTransaction(async (transaction) => {
        const docRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
        const docProducto = docRef.collection(colections.PRODUCTO).doc(producto.id);
        //genernado tabla resumen, siempre hay un solo documento
        const contadorProductoRef = docRef.collection(colections.PRODUCTO_RESUMEN);
        await contadorProductoRef.get().toPromise()
        .then(docSnapshot=>{
          if(!docSnapshot.empty){
            this.contadorResumenGet = Utils.convertDate(docSnapshot.docs[0].data()) as IResumenProducto;
            this.contadorResumenGet.id_documento = docSnapshot.docs[0].id;
            let activos = this.contadorResumenGet.total_productos_activos;
            let inactivos = this.contadorResumenGet.total_productos_inactivos;
            if (esActivar) {
              activos = activos + 1;
              inactivos = inactivos - 1;
            }
            else {
              activos = activos - 1;
              inactivos = inactivos + 1;
            }

            this.contadorResumenUpdate = {
              total_productos: this.contadorResumenGet.total_productos,
              total_productos_activos: activos,
              total_productos_inactivos: inactivos
            }
            const contadorResumenUpd = Utils.SerializeJsonToDb(this.contadorResumenUpdate);
            contadorResumenUpd.fecha_actualizacion = serverTimestamp();
            transaction.update(contadorProductoRef.doc(this.contadorResumenGet.id_documento).ref,contadorResumenUpd);
          }
        });

        //upd colectivo para catalogos que incluyen este producto
        let listaCodigoProd:string[]= [];
        listaCodigoProd.push(producto.codigo_producto);
        const catalogoRef = docRef.collection(colections.CATALOGO, ref=>ref
          .where(documentCatalogo.CODIGO_PRODUCTOS_ANIDADOS_COMPLETO,'array-contains-any',listaCodigoProd)
          );
        const resCatalogoSnaps = await catalogoRef.get().toPromise();
        if(!resCatalogoSnaps.empty){
          for(const catalogo of resCatalogoSnaps.docs){
            const docCatalogo = catalogo.data() as Catalogo;
            const idCatalogo = catalogo.id;

            const productoCatalogo = docCatalogo.lista_productos.filter(prodCata=>prodCata.codigo_producto===producto.codigo_producto)[0];
            if(productoCatalogo){
              productoCatalogo.es_vigente = esActivar;
            }
            const lista_productosCatalogoDepurada = docCatalogo.lista_productos.filter(prodCata=>prodCata.codigo_producto!==producto.codigo_producto);

            lista_productosCatalogoDepurada.push(productoCatalogo);

            transaction.update(catalogoRef.ref.doc(idCatalogo), {
              lista_productos: Utils.SerializeJsonToDb(lista_productosCatalogoDepurada),
              fecha_actualizacion:serverTimestamp(),
              usuario_modificacion: correoUsuario
            } )
          }
        }


        transaction.update(docProducto.ref, {
          fecha_modificacion : serverTimestamp(),
          usuario_modificacion : correoUsuario,
          es_vigente: esActivar
        });
        const newDocIdSubcoleccion = docProducto.ref.id;

        this.transaccion = {
          tx: true,
          data: {
            idColaborador: newDocIdSubcoleccion,
          }
        };
        return this.transaccion;
      });
      return transactionResult;
    }
    catch(error){
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

  async updateProducto(producto:Producto, files_upload:File[], files_upload_anterior: IImageUploaded[], pathFileAPI:string, estadoProductoEdicion:boolean, correoUsuario:string, idEmpresa:string, tiene_prod_anidado_anterior:boolean){
    const productoOrig = {...this.removeUtilProperties(producto)};
    const productoUpd = Utils.SerializeJsonToDb(productoOrig);
    productoUpd.fecha_modificacion= serverTimestamp();
    productoUpd.usuario_modificacion = correoUsuario;
    try{
      //cuando se tienen imagenes anteriores
      if(producto.imagen){
        const imagenActual= Utils.ObjecToListToArray(producto.imagen);
        //cuando se quita alguna imagen existente
        if( imagenActual.length!=files_upload_anterior.length){
          //eliminar archivos anteriores
          await this.uploadService.depuraFiles(pathFileAPI,files_upload_anterior,producto.imagen);
        }

        //imagenes cargadas actualmente
        if(files_upload.length>0){
          const urlsImagen = await this.uploadService.onSaveFiles(files_upload, pathFileAPI);
          let urlsImagenNuevo =  Utils.ObjecToListToArray(urlsImagen);

          if(files_upload_anterior.length>0){
            //combinamos las urls
            files_upload_anterior.forEach(image=>{
              urlsImagenNuevo.push(image);
            });
          }
          productoUpd.imagen = Utils.ArrayToObjectToObjects(urlsImagenNuevo);;
        }else{
          if(files_upload_anterior.length==0 && producto.imagen){
            productoUpd.imagen = deleteField();
          }else{
            productoUpd.imagen = Utils.ArrayToObjectToObjects(files_upload_anterior);
          }
        }
      }
      //cuando no se tienen imagenes anteriores
      else{
        if(files_upload.length>0){
          const urlsImagen = await this.uploadService.onSaveFiles(files_upload, pathFileAPI);
          productoUpd.imagen = urlsImagen;
        }
      }
      //verificando que no se tienen subproductos
      if(!producto.tiene_subproductos && tiene_prod_anidado_anterior){
        productoUpd.subproductos = deleteField();
        productoUpd.id_productos_anidados_completo = deleteField();
      }

      const transactionResult = await this.db.firestore.runTransaction(async (transaction) => {
        const docRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
        const docProducto = docRef.collection(colections.PRODUCTO).doc(producto.id);

        //upd colectivo para productos anidados otros (cascada)
        this.productosAnidadosOtros= new Array();
        const prodID:string[]=[];
        if(producto.id)prodID.push(producto.id);
        await docRef.collection(colections.PRODUCTO, (ref1)=>
        ref1.where(Auditoria.es_borrado,'==',false)
        .where(documentProducto.ID_PRODUCTOS_ANIDADOS_COMPLETO,'array-contains-any',prodID)
        ).get().toPromise().then(docSnapsProd=>{
          if(!docSnapsProd.empty){
            docSnapsProd.forEach(docsprod=>{
              const data = Utils.convertDate(docsprod.data()) as Producto;
              data.id = docsprod.id;
              data.ref = docsprod.ref;
              this.productosAnidadosOtros.push(data);
            });
          }
        });

        //genernado tabla resumen, siempre hay un solo documento
        const contadorProductoRef = docRef.collection(colections.PRODUCTO_RESUMEN);
        const resProductoSnaps = await contadorProductoRef.get().toPromise();
        //cambiando estado si esta variado
        if(estadoProductoEdicion !=producto.es_vigente){
          if(!resProductoSnaps.empty){
            this.contadorResumenGet = Utils.convertDate(resProductoSnaps.docs[0].data()) as IResumenProducto;
            this.contadorResumenGet.id_documento = resProductoSnaps.docs[0].id;
            let activos = this.contadorResumenGet.total_productos_activos;
            let inactivos = this.contadorResumenGet.total_productos_inactivos;
            if (producto.es_vigente) {
              activos = activos + 1;
              inactivos = inactivos - 1;
            }
            else {
              activos = activos - 1;
              inactivos = inactivos + 1;
            }

            this.contadorResumenUpdate = {
              total_productos: this.contadorResumenGet.total_productos,
              total_productos_activos: activos,
              total_productos_inactivos: inactivos
            }
            const contadorResumenUpd = Utils.SerializeJsonToDb(this.contadorResumenUpdate);
            contadorResumenUpd.fecha_actualizacion = serverTimestamp();
            transaction.update(contadorProductoRef.doc(this.contadorResumenGet.id_documento).ref,contadorResumenUpd);
          }
        }
        //upd colectivo para productos anidados otros (cascada)
        if(this.productosAnidadosOtros.length>0){
          this.productosAnidadosOtros.forEach(prodAnidadoOtro=>{
            if(prodAnidadoOtro.subproductos){
              for(const anidado of prodAnidadoOtro.subproductos){
                if(anidado.id==producto.id){
                  anidado.producto = productoOrig
                  break;
                }
              }
              transaction.update(prodAnidadoOtro.ref,{
                subproductos:prodAnidadoOtro.subproductos,
                fecha_actualizacion: serverTimestamp(),
                usuario_modificacion: correoUsuario
              });
            }
          });

        }
        //upd colectivo para catalogos que incluyen este producto
        let listaCodigoProd:string[]= [];
        listaCodigoProd.push(productoOrig.codigo_producto);
        const catalogoRef = docRef.collection(colections.CATALOGO, ref=>ref
          .where(documentCatalogo.CODIGO_PRODUCTOS_ANIDADOS_COMPLETO,'array-contains-any',listaCodigoProd)
          );
        const resCatalogoSnaps = await catalogoRef.get().toPromise();
        if(!resCatalogoSnaps.empty){
          for(const catalogo of resCatalogoSnaps.docs){
            const docCatalogo = catalogo.data() as Catalogo;
            const idCatalogo = catalogo.id;

            const lista_productosCatalogo = docCatalogo.lista_productos.filter(prodCata=>prodCata.codigo_producto!==productoOrig.codigo_producto);
            lista_productosCatalogo.push(productoOrig);

            transaction.update(catalogoRef.ref.doc(idCatalogo), {
              lista_productos: Utils.SerializeJsonToDb(lista_productosCatalogo),
              fecha_actualizacion:serverTimestamp(),
              usuario_modificacion: correoUsuario
            } )
          }
        }

        transaction.update(docProducto.ref, productoUpd);
        const newDocIdSubcoleccion = docProducto.ref.id;

        this.transaccion = {
          tx: true,
          data: {
            idColaborador: newDocIdSubcoleccion,
          }
        };
        return this.transaccion;
      });
      return transactionResult;
    }catch(error){
      this.transaccion = { tx: false, error: error };
      //console.log(error);
      return this.transaccion;
    }
  }


  async getProductoXCodigo(codigoProducto:string, idEmpresa:string){
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const queryDoc = await docEmpRef.collection(colections.PRODUCTO, ref=>
      ref.where(documentProducto.CODIGO_PRODUCTO,'==',codigoProducto)).get().toPromise();

    if(queryDoc.empty){
      return true;
    }
    else{
      return false;
    }
  }

  async deleteProducto(producto: Producto, idEmpresa: string, pathFileAPI: string) {
    try {
      const transactionResult = await this.db.firestore.runTransaction(async (transaction) => {
        const docRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
        const docProducto = docRef.collection(colections.PRODUCTO).doc(producto.id);

        //upd colectivo para productos anidados otros (cascada)
        this.productosAnidadosOtros= new Array();
        const prodID: string[] = [];
        if (producto.id) prodID.push(producto.id);
        await docRef.collection(colections.PRODUCTO, (ref1) =>
          ref1.where(Auditoria.es_borrado, '==', false)
            .where(documentProducto.ID_PRODUCTOS_ANIDADOS_COMPLETO, 'array-contains-any', prodID)
        ).get().toPromise().then(docSnapsProd => {
          if (!docSnapsProd.empty) {
            docSnapsProd.forEach(docsprod => {
              const data = Utils.convertDate(docsprod.data()) as Producto;
              data.id = docsprod.id;
              data.ref = docsprod.ref;
              this.productosAnidadosOtros.push(data);
            });
          }
        });

        //generando tabla resumen, siempre hay un solo documento
        const contadorProductoRef = docRef.collection(colections.PRODUCTO_RESUMEN);
        await contadorProductoRef.get().toPromise()
          .then(docSnapshot => {
            if (!docSnapshot.empty) {
              this.contadorResumenGet = Utils.convertDate(docSnapshot.docs[0].data()) as IResumenProducto;
              this.contadorResumenGet.id_documento = docSnapshot.docs[0].id;
              let activos = this.contadorResumenGet.total_productos_activos;
              let inactivos = this.contadorResumenGet.total_productos_inactivos;
              if (producto.es_vigente) {
                activos -= 1;
              }
              else {
                inactivos -= 1;
              }

              this.contadorResumenUpdate = {
                total_productos: this.contadorResumenGet.total_productos - 1,
                total_productos_activos: (this.contadorResumenGet.total_productos - 1)===0 ? 0 : activos,
                total_productos_inactivos: (this.contadorResumenGet.total_productos - 1)===0 ? 0 : inactivos
              }
              const contadorResumenUpd = Utils.SerializeJsonToDb(this.contadorResumenUpdate);
              contadorResumenUpd.fecha_actualizacion = serverTimestamp();
              transaction.update(contadorProductoRef.doc(this.contadorResumenGet.id_documento).ref, contadorResumenUpd);
            }
          });

         //upd colectivo para productos anidados otros (cascada)
         if(this.productosAnidadosOtros.length>0){
          this.productosAnidadosOtros.forEach(prodAnidadoOtro=>{
            if(prodAnidadoOtro.subproductos){
              prodAnidadoOtro.subproductos =[...prodAnidadoOtro.subproductos.filter(prodAnid=>prodAnid.id!==producto.id)];
              prodAnidadoOtro.id_productos_anidados_completo =prodAnidadoOtro.id_productos_anidados_completo?.filter(idAnid=>idAnid!==producto.id);
              if(prodAnidadoOtro.subproductos.length==0){
                //actualizando los productos anidados en otros padres
                transaction.update(prodAnidadoOtro.ref,{
                  tiene_subproductos:false,
                  subproductos:deleteField(),
                  id_productos_anidados_completo:deleteField(),
                  fecha_actualizacion: serverTimestamp(),
                });
              }else{
                transaction.update(prodAnidadoOtro.ref,{
                  subproductos:prodAnidadoOtro.subproductos,
                  fecha_actualizacion: serverTimestamp(),
                  id_productos_anidados_completo:prodAnidadoOtro.id_productos_anidados_completo,

                });
              }
            }
          });
        }

           //upd colectivo para catalogos que incluyen este producto
           let listaCodigoProd:string[]= [];
           listaCodigoProd.push(producto.codigo_producto);
           const catalogoRef = docRef.collection(colections.CATALOGO, ref=>ref
             .where(documentCatalogo.CODIGO_PRODUCTOS_ANIDADOS_COMPLETO,'array-contains-any',listaCodigoProd)
             );
           const resCatalogoSnaps = await catalogoRef.get().toPromise();
           if(!resCatalogoSnaps.empty){
             for(const catalogo of resCatalogoSnaps.docs){
               const docCatalogo = catalogo.data() as Catalogo;
               const idCatalogo = catalogo.id;

               const lista_productosCatalogoDepurada = docCatalogo.lista_productos.filter(prodCata=>prodCata.codigo_producto!==producto.codigo_producto);

               transaction.update(catalogoRef.ref.doc(idCatalogo), {
                 lista_productos: Utils.SerializeJsonToDb(lista_productosCatalogoDepurada),
                 fecha_actualizacion:serverTimestamp()
               } )
             }
           }


        transaction.delete(docProducto.ref);
        //eliminando imagen
        if ('imagen' in producto) {
          // se manda un array vacío porque no se reemplaza nada
          const deleteImage = await this.uploadService.depuraFiles(pathFileAPI, [], producto.imagen);
        }

        this.transaccion = {
          tx: true,
        };
        return this.transaccion;
      });
      return transactionResult;
    }
    catch (error) {
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }

  }
}
