import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Msjs_Validations } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';
import { IProducto } from '../../../procesos/dialog/models/IProducto';

@Component({
  selector: 'app-listar-caracteristicas-into',
  templateUrl: './listar-caracteristicas-into.component.html',
  styleUrls: ['./listar-caracteristicas-into.component.scss']
})
export class ListarCaracteristicasIntoComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  isLoading:boolean=false;
  listaCaracteristicas:string[]=[];
  producto!:IProducto;
  caracteristicaSeleccionada:string='';
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef:MatDialogRef<ListarCaracteristicasIntoComponent>,
    private uiService:UIService,
  ) { }

  ngOnInit(): void {
    this.producto = this.datoPasado.dato as IProducto;
    if(this.producto.lista_caracteristicas)
    this.listaCaracteristicas = this.producto.lista_caracteristicas;
  }

  onClose(){
    this.dialogRef.close();
  }

  onSubmit(f:NgForm){
    if(f.valid){
      this.dialogRef.close(this.caracteristicaSeleccionada);  
    }

  }

  Limpiar(){
    this.caracteristicaSeleccionada ='';
  }

}
