<div fxLayout="row" fxLayoutAlign="space-between start">
    <div mat-dialog-title class="dialog-title">{{titleModal}}</div>
    <app-button *ngIf="botonCerrarVisible" type="icon" color="gray" [icon_fig]="CLOSE" (click)="onClose()" ></app-button>
</div>
<mat-divider></mat-divider>
  <mat-dialog-content>
    <section *ngIf="!isLoading">
      <form fxFlex fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="10" fxFlex.xs="100%" fxFlex="100%" #f="ngForm" (ngSubmit)="onSubmit(f)" >
        <mat-form-field >
          <input type="email"
            matInput
            placeholder="Correo Electrónico"
            [maxlength]="Length_Database.DB_LENGTH_SMALL_MAX_50"
            ngModel
            #correo_electronico="ngModel"
            [pattern] ="PatternsRegex.onlyEmail"
            ngModel
            name="correo_electronico"
            oninput="this.value = this.value.toLowerCase()"
            required >
            <mat-error *ngIf="correo_electronico.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="correo_electronico.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_EMAIL}}</mat-error>
            <mat-error *ngIf="correo_electronico.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-hint>{{Msjs_Validations.MSJ_HINT_PASSWORD}}</mat-hint>
          <input
          [type]="hide?'password':'text'"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8"
            [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
            [pattern]="PatternsRegex.onlyPassword"
            matInput
            placeholder="Contraseña"
            ngModel
            name="password"
            #password="ngModel"
            autocomplete="current-password"
            required >
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" type="button" >
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="password.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="password.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_PASSWORD}}</mat-error>
            <mat-error *ngIf="password.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8}}</mat-error>
            <mat-error *ngIf="password.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="datoPasado.operacion==SistType.REGISTRO" class="mat-text-box" >
          <input
          [type]="hide2?'password':'text'"
            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8"
            [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
            [pattern]="PatternsRegex.onlyPassword"
            matInput
            placeholder="Repita la contraseña"
            ngModel
            name="password2"
            #password2="ngModel"
            required >
            <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2" type="button" >
              <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="password2.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="password2.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_PASSWORD}}</mat-error>
            <mat-error *ngIf="password2.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8}}</mat-error>
            <mat-error *ngIf="password2.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
        </mat-form-field>
        <div class="actions-dialog" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px" fxFlex.xs="100%">
          <app-button [width]="100" [text]="titleButton" [attr_disabled]="f.invalid" [attr_type]="'submit'"></app-button>
          <app-button *ngIf="datoPasado.data==SistType.LOGIN" text="Recuperar mi contraseña" type="basic" ></app-button>
          <button  type  mat-button color="primary" *ngIf="!showBotonReenvioMailVerificacion" (click)="resetContrasenia(f)" >Olvidé la contraseña</button>         
          <div *ngIf="showBotonReenvioMailVerificacion"  fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">           
            <button mat-button color="primary" [disabled]="disabledBotonReenvio"  (click)="verificarReenviarEmail(f)" >{{btnText}}</button>
            <!-- <app-button [text]="btnText" type="basic" (click)="verificarReenviarEmail(f)" [attr.disabled]="disabledBotonReenvio" ></app-button>     -->
          </div>
        </div>
      </form>
    </section>
    <section *ngIf="isLoading">
      <app-spinner></app-spinner>
    </section>
  </mat-dialog-content>
