<div fxLayout="row" fxLayoutAlign="center center">
    <mat-card fxFlex="50%" fxFlex.xs="100%">
        <mat-card-header>
            <mat-card-title>Gestionar Comprobantes Electrónicos</mat-card-title>
            <mat-card-subtitle>Activar/Desactivar acceso a comprobantes electrónicos </mat-card-subtitle>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <form #f="ngForm" (ngSubmit)="buscarEmpresa(f)" >
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <mat-form-field appearance="outline" class="control-row" style="padding-top: 10px;">
                        <mat-label>RUC</mat-label>
                        <input type="text" 
                            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_10"
                            [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_11"
                            [pattern]="PatternsRegex.onlyIntegerUnsigned" 
                            matInput placeholder="Número de RUC" ngModel
                            name="ruc" #ruc="ngModel" 
                            autocomplete="off" 
                            required>
                        <mat-icon matSuffix>{{Icons.QR_CODE_2}}</mat-icon>
                        <mat-error
                            *ngIf="ruc.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                        <mat-error
                            *ngIf="ruc.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_REQ6_DIGITOS_CODIGO}}</mat-error>
                        <mat-error
                            *ngIf="ruc.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_10}}</mat-error>
                        <mat-error
                            *ngIf="ruc.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_11}}</mat-error>
                    </mat-form-field>
                    <div fxLayout="row" fxLayoutGap="20px" >
                        <button mat-stroked-button [disabled]="f.invalid">
                            <mat-icon>{{Icons.SEARCH}}</mat-icon>
                        </button>
                        <button mat-stroked-button type="button" (click)="limpiarBusqueda(f)" >
                            <mat-icon>{{Icons.CLEANING}}</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
            <div *ngIf="isLoading"  fxLayout="row" fxLayoutAlign="center center" style="margin-top:10px">
                <app-spinner></app-spinner>
            </div>
            <div *ngIf="!isLoading" fxLayout="column" fxLayoutGap="20px" >
                <span *ngIf="(empresa==undefined || empresa==null) && esBuscado" >No se encontró la empresa</span>
                <div *ngIf="empresa!=undefined">Empresa:&nbsp;<span style="font-weight: bold;">{{empresa.nombre_razon}}</span></div>                
                <div fxLayout="row" *ngIf="empresa">                    
                    <div style="margin-top:10px">Estado Faturación:&nbsp;</div>
                    <mat-chip-list>
                        <mat-chip  
                            *ngIf="empresa.comprobante_electronico==undefined" 
                            class="chip-solicitado" >{{EstadosComprobanteElectronicoEmpresa.NO_REQUERIDO.toUpperCase()}}
                        </mat-chip>
                        <mat-chip  
                            *ngIf="empresa.comprobante_electronico"
                            class="chip-solicitado" >{{empresa.comprobante_electronico.estado_comprobante_empresa.toUpperCase()}}
                        </mat-chip>                        
                    </mat-chip-list>                 
                </div>
                <div *ngIf="empresa && empresa.comprobante_electronico" >
                    <div *ngIf="empresa.comprobante_electronico.estado_comprobante_empresa!= EstadosComprobanteElectronicoEmpresa.NO_REQUERIDO" >
                        <mat-form-field>
                        <mat-label>Estado Comprobante Electrónico</mat-label>
                        <mat-select 
                            (selectionChange)="seleccionarEstadoComprobante($event.value)" 
                            [(ngModel)]="comprobanteElectronico.estado_comprobante_empresa" name="ddlEstadoComprobante"
                            #ddlEstadoComprobante="ngModel" [disabled]="disabled">
                          <mat-option *ngFor="let estado of estadosArray" [value]="estado">{{estado}}</mat-option>
                        </mat-select>                        
                      </mat-form-field>
                    </div>
                </div>
                
                <div>
                    <mat-divider></mat-divider>
                </div>                
                <div *ngIf="empresa && empresa!=null">
                    <form #sunat="ngForm" (ngSubmit)="generarComprobante(sunat)" >
                        <div fxLayout="column" fxLayoutAlign="space-between center" >
                            <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
                                <mat-form-field>
                                    <input type="text" matInput placeholder="Código" name="codigo" ngModel #codigo="ngModel"
                                        [disabled]="disabled"
                                        [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_4"
                                        [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_4" [pattern]="PatternsRegex.onlyIntegerUnsigned"
                                        (keypress)="onlyNumbers($event)" [(ngModel)]="comprobanteElectronico.codigo_pse" required>
                                    <mat-error *ngIf="codigo.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                    <mat-error *ngIf="codigo.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
                                    <mat-error
                                        *ngIf="codigo.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_4}}</mat-error>
                                    <mat-error
                                        *ngIf="codigo.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_4}}</mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <input type="text" matInput placeholder="Nombre Corto" name="nombreCorto" ngModel #nombreCorto="ngModel"
                                        [disabled]="disabled"
                                        oninput="this.value = this.value.toUpperCase()"
                                        [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
                                        [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_4" [pattern]="PatternsRegex.onlyGoodCharsWithNumbersAndSpace"
                                        [(ngModel)]="comprobanteElectronico.nombre_corto_pse" required>
                                    <mat-error *ngIf="nombreCorto.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                    <mat-error
                                        *ngIf="nombreCorto.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_NUMEROS_CON_ESPACIO_INTERMEDIO}}</mat-error>
                                    <mat-error
                                        *ngIf="nombreCorto.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
                                    <mat-error
                                        *ngIf="nombreCorto.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_4}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
                                <mat-form-field>
                                    <input type="text" matInput placeholder="Código SUNAT" name="codigoSunat" ngModel #codigoSunat="ngModel"
                                        [disabled]="disabled"
                                        [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_4"
                                        [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_4" [pattern]="PatternsRegex.onlyIntegerUnsigned"
                                        (keypress)="onlyNumbers($event)" [(ngModel)]="comprobanteElectronico.codigo_sunat_pse" required>
                                    <mat-error *ngIf="codigoSunat.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                    <mat-error *ngIf="codigoSunat.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
                                    <mat-error
                                        *ngIf="codigoSunat.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_4}}</mat-error>
                                    <mat-error
                                        *ngIf="codigoSunat.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_4}}</mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <input type="text" matInput placeholder="Ubigeo INEI" name="ubigeoInei" ngModel #ubigeoInei="ngModel"
                                        [disabled]="disabled"
                                        [maxlength]="Length_Database.DB_LENGTH_X_SMALL_6" [minlength]="Length_Database.DB_LENGTH_X_SMALL_6"
                                        [pattern]="PatternsRegex.onlyIntegerUnsigned" (keypress)="onlyNumbers($event)"
                                        [(ngModel)]="comprobanteElectronico.ubigeo_inei_pse" required>
                                    <mat-error *ngIf="ubigeoInei.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                    <mat-error *ngIf="ubigeoInei.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
                                    <mat-error *ngIf="ubigeoInei.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_MAX6_DIGITOS}}</mat-error>
                                    <mat-error *ngIf="ubigeoInei.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_MIN6_DIGITOS}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
                                <mat-form-field>
                                    <mat-label>Departamento</mat-label>
                                    <mat-select (selectionChange)="departamentoSelect($event.value)"
                                        [disabled]="disabled"
                                        [(ngModel)]="comprobanteElectronico.departamento_pse" name="ddlDepartamento" #ddlDepartamento="ngModel"
                                        required>
                                        <mat-option *ngFor="let dep of lDepartamento" [value]="dep.codigoID">{{dep.nombre}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="ddlDepartamento.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Provincia</mat-label>
                                    <mat-select (selectionChange)="provinciaSelect($event.value)"
                                        [disabled]="disabled"
                                        [(ngModel)]="comprobanteElectronico.provincia_pse" name="ddlProvincia" #ddlProvincia="ngModel" required>
                                        <mat-option *ngFor="let prov of lProvincia" [value]="prov.codProvincia">{{prov.provincia}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="ddlProvincia.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
                                <mat-form-field>
                                    <mat-label>Distrito</mat-label>
                                    <mat-select
                                        [disabled]="disabled"
                                        [(ngModel)]="comprobanteElectronico.distrito_pse" name="ddlDistrito" #ddlDistrito="ngModel"
                                        required>
                                        <mat-option *ngFor='let dist of lDistrito' [value]='dist.codDistrito'>{{dist.distrito}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="ddlDistrito.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <input type="text" matInput placeholder="Dirección y número" name="direccion"
                                        [disabled]="disabled"
                                        ngModel
                                        [maxlength]="Length_Database.DB_LENGTH_SMALL_MAX_50"
                                        [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8" required #direccion="ngModel"
                                        [(ngModel)]="comprobanteElectronico.direccion_pse">
                                    <mat-error *ngIf="direccion.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                    <mat-error
                                        *ngIf="direccion.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8CARACTERES}}</mat-error>
                                    <mat-error
                                        *ngIf="direccion.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" fxLayoutGap.xs="10px">
                                <mat-form-field>
                                    <input type="text" matInput placeholder="Serie Boleta" name="serieBoleta" ngModel #serieBoleta="ngModel"
                                        [disabled]="disabled"
                                        oninput="this.value = this.value.toUpperCase()"
                                        [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_8"
                                        [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_4" [pattern]="PatternsRegex.onlySerieBoleta"
                                        [(ngModel)]="comprobanteElectronico.serie_boleta_pse" required>
                                    <mat-error *ngIf="serieBoleta.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                    <mat-error
                                        *ngIf="serieBoleta.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_SERIE_BOLETA}}</mat-error>
                                    <mat-error
                                        *ngIf="serieBoleta.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_8}}</mat-error>
                                    <mat-error
                                        *ngIf="serieBoleta.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_4}}</mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <input type="text" matInput placeholder="Serie Factura" name="serieFactura" ngModel
                                        [disabled]="disabled"
                                        #serieFactura="ngModel"
                                        oninput="this.value = this.value.toUpperCase()"
                                        [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_8"
                                        [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_4" [pattern]="PatternsRegex.onlySerieFactura"
                                        [(ngModel)]="comprobanteElectronico.serie_factura_pse" required>
                                    <mat-error *ngIf="serieFactura.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                    <mat-error
                                        *ngIf="serieFactura.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_SERIE_FACTURA}}</mat-error>
                                    <mat-error
                                        *ngIf="serieFactura.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_8}}</mat-error>
                                    <mat-error
                                        *ngIf="serieFactura.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_4}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field  style="width: 380px;">
                                    <input type="text" matInput placeholder="Ruta API" name="rutaApi" ngModel #rutaApi="ngModel"
                                        [disabled]="disabled"
                                        [(ngModel)]="comprobanteElectronico.ruta_pse" required>
                                    <mat-error *ngIf="rutaApi.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>                                    
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field style="width: 380px;">
                                    <input type="text" matInput placeholder="Token API" name="tokenApi" ngModel #tokenApi="ngModel"
                                        [disabled]="disabled"                                      
                                        [(ngModel)]="comprobanteElectronico.token_pse" required>
                                    <mat-error *ngIf="tokenApi.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>                                    
                                </mat-form-field>
                            </div>
                                               
                        </div>
                        <div  fxLayout="row" fxLayoutAlign="space-around center" *ngIf="!disabled" >
                            <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="sunat.invalid"
                            [attr_type]="'submit'"></app-button>
                            <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" color="gray" (click)="Limpiar(sunat)"></app-button>
                            <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING"
                                (click)="Limpiar(sunat)"></app-button>
                        </div> 
                    </form>
                </div>
                
               
                
            </div>
            
          </mat-card-content>
          
    </mat-card>
</div>