import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UIService } from 'src/app/shared/services/ui.services';
import { ModulosBlService } from '../../services/services.bl/modulos.bl.service';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { IModulo } from '../../dialog/models/modulo.model';
import {
  Length_Database,
  Msjs_Validations,
  PatternsRegex,
  Msjs,
  MsjEstado,
  EstadoRegistro
} from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { Subscription, forkJoin, from, timer, concat } from 'rxjs';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { MatSlideToggleChange} from '@angular/material/slide-toggle'
import { EmpresaBLService } from '../../services/services.bl/empresa.bl.service';
import { AutoUnsubscribe } from "../../../helpers/decorators/AutoUnsubscribe";
import { concatMap, delayWhen, switchMap, takeUntil, tap } from 'rxjs/operators';
import { error } from 'console';
import { FacturacionBLService } from '../../services/services.bl/facturacion.bl.service';
import { Observable } from 'rxjs-compat';
import { Facturacion } from '../../procesos/gestion-colaborador/models/facturacion.model';
import { blinkAnimation } from 'src/app/shared/animations/animations';
import { ReduccionUsuario } from '../../procesos/gestion-colaborador/models/reduccion-usuario.model';

@Component({
  selector: 'app-modulo',
  templateUrl: './modulo.component.html',
  styleUrls: ['./modulo.component.scss'],
  animations: [blinkAnimation]
})

@AutoUnsubscribe

export class ModuloComponent implements OnInit {
  listaModulos$!: IModulo[] | null;
  listaModulosSb!: Subscription;
  visible_controls:boolean=false;



  readonly Icons: typeof Icons = Icons;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  totalColaboradores:number = 0;
  totalMontoModulo:number=0;
  totalMonto: number =0;
  listaModulosSel:any[]=[];
  terminosCondiciones:boolean = false;
  //form
  disabled_controls:boolean=false;
  disabled_form:boolean=true;
  sbEnvioCantidad!:Subscription;
  envioDecrementoSb!:Subscription;
  envioAnulacionModSb!:Subscription;
  removerDecrementoSb!:Subscription;
  removerAnularModuloSb!:Subscription;
  sbQuitarTotalColab!:Subscription;
  sbModal!:Subscription;
  sbModalQuest!:Subscription;
  esModoEdicion:boolean=false;
  sbEmpresa!:Subscription;
  sbReloadModulos!:Subscription;
  sbModulos!:Subscription;
  sbNextStep!:Subscription;//ppara cargar cuando se regustra empresa

  facturacion$= new Observable<Facturacion | null | undefined>(undefined);
  facturacion:Facturacion = new Facturacion();
  facturacionSb!:Subscription;
  esDecremento: boolean =false;
  esAnularModulo: boolean = false;
  listaDecremento:ReduccionUsuario[]=[];
  totalDecremento:number=0;
  totalAnulacionModulo:number=0;
  blinkState: string = 'inactive';
  esTiempoPrueba:boolean=false;
  /*
  - Cada incremento de modulos o usuarios es instantaneo.
  - Las programaciones de anulación de modulos o reducción de usuarios se realiza para el siguiente
    mes de facturación (generación de recibo).
  - Se permita la reducción de usuarios varias veces, la última reducción sobre escribe a las demas.
  - Al solicitar la anulación de un módulo se bloquea hasta la facturación
  - La facturación se lleva a cabo cada 30 de cada mes, ya sea por mes completo(registro con mas de un mes de anterioridad)
    y registro días antes de facturación se proratea).

  */

  constructor(
    private modulosBlServices: ModulosBlService,
    private uiService:UIService,
    private readonly spinnerPageService: SpinnerPageService,
    private registroService: RegistroService,
    private empresaBLService: EmpresaBLService,
    private facturacionBLService: FacturacionBLService,
    //para indicar que actualize el detector de cambios
    private cdRef: ChangeDetectorRef

  ) { }

  ngOnInit(): void {
    this.totalDecremento=0;
    setInterval(() => {
      this.blinkState = this.blinkState === 'active' ? 'inactive' : 'active';
    }, 700);

    this.spinnerPageService.show();

    //con f5 o regarga

    this.listaModulosSb=this.modulosBlServices.getModulo().pipe(
      tap(modulos=>{
        this.listaModulos$ = modulos;
        //para modo edicion
        if(this.registroService.usuario && !this.registroService.usuario.tiene_modulos){
          this.cargarModulosEdicion();
        }
        this.spinnerPageService.hide();
      })).subscribe();

    //funciona cuando se presiona un f5 o recarga la pagina (nuevo/edicion) recarga desde el admin-nav-bar
    this.sbEmpresa = this.registroService.loadEmpresa$.subscribe(() => {
      this.spinnerPageService.show();
      this.listaModulosSb=this.modulosBlServices.getModulo().pipe().subscribe(modulos=>{
        this.listaModulos$ = modulos;
        //EDICION
        if(this.registroService.usuario.tiene_modulos){
          this.cargarModulosEdicion();
        }
        //si no tiene modulos no se hace nada
        this.spinnerPageService.hide();
      });
    });


    //recarga para edicion cargado por gestion-empresa-componente
    this.sbReloadModulos = this.uiService.reloadModulos$.subscribe(()=>{
      this.spinnerPageService.show();
      if ((this.registroService.estado == EstadoRegistro.REGISTRO_COMPLETO_ADMIN)
      ) {
        // se recarga porque algunas propiedades se quedan pegadas cuando no se modifican pero no se guardan
        this.listaModulosSb=this.modulosBlServices.getModulo().pipe().subscribe(modulos=>{

          this.listaModulos$ = modulos;
          this.cargarModulosEdicion();
          this.esModoEdicion = true;
          this.disabled_controls=false;
          this.spinnerPageService.hide();
        });

      }
      else {
        this.esModoEdicion = false;
        this.spinnerPageService.hide();
      }
    });

    this.envioAnulacionModSb = this.registroService.envioAnularModulo$.subscribe((codigo:string)=>{
      this.totalAnulacionModulo=0;
      this.listaModulosSel.forEach(modulo=>{
        if(modulo.codigo===codigo){
          modulo.pendiente_anulacion_modulo=true;
          this.totalAnulacionModulo+=1;

          this.esAnularModulo = true;
        }
      });
      this.cdRef.detectChanges();
    });

    this.envioDecrementoSb = this.registroService.envioDecremento$.subscribe((decremento:ReduccionUsuario)=>{
      if(decremento){
        const listaDecrementosDistintos = this.listaDecremento.filter(decre => decre.codigo_modulo!= decremento.codigo_modulo);
        if(listaDecrementosDistintos.length>0){
          listaDecrementosDistintos.push(decremento);
          this.listaDecremento = [...listaDecrementosDistintos];
        }
        else{
          this.listaDecremento=[];
          this.listaDecremento.push(decremento);
        }

        this.esDecremento = true;
        this.cdRef.detectChanges();
        this.totalDecremento = this.listaDecremento.reduce((acumulador, item)=>{
          return acumulador+item.cantidad_reduccion;
        },0);

      }
    });

    this.removerDecrementoSb = this.registroService.removerDecremento$.subscribe(decremento=>{
      const listaResultDecremento = this.listaDecremento.filter(decre=> decre.codigo_modulo!= decremento);
      if(listaResultDecremento.length>0){
        this.listaDecremento = [...listaResultDecremento]
        this.totalDecremento = this.listaDecremento.reduce((acumulador, item)=>{
          return acumulador+item.cantidad_reduccion;
        },0);
      }else{
        this.listaDecremento =[];
        this.esDecremento = false;
        this.totalDecremento = 0;
      }
    });

    this.removerAnularModuloSb = this.registroService.removerAnularModulo$.subscribe(codigoModulo=>{
      this.listaModulosSel.forEach(modulo=>{
        if(modulo.codigo===codigoModulo){
          delete modulo.pendiente_anulacion_modulo;
          this.totalAnulacionModulo-=1;
        }
      });
      const totalMod =  this.listaModulosSel.filter(module=> module.pendiente_anulacion_modulo);
      if(totalMod.length==0){
        this.esAnularModulo=false;
      }

    });

    this.sbEnvioCantidad= this.registroService.envioCantidad$.subscribe(ordenCantidad => {
      if(this.listaModulosSel.length>0){

        let flag:boolean = false;
        this.listaModulosSel.forEach(modulo=>{
          if(modulo.orden== ordenCantidad.orden){
            modulo.cantidad_total_usuarios= ordenCantidad.cantidad_total_usuarios;
            modulo.costo_total= ordenCantidad.costo_total;
            flag=true;
          }
        });
        this.listaModulos$?.forEach(modulo_sist=>{
          if(modulo_sist.orden== ordenCantidad.orden){
            ordenCantidad.menu=modulo_sist.menu;
            ordenCantidad.roles = modulo_sist.roles;
            ordenCantidad.codigo = modulo_sist.codigo;
          }
        });
        if(!flag){
          this.listaModulosSel.push(ordenCantidad);
        }
      }else{
        this.listaModulos$?.forEach(modulo_sist=>{
          if(modulo_sist.orden== ordenCantidad.orden){
            ordenCantidad.menu=modulo_sist.menu;
            ordenCantidad.roles = modulo_sist.roles;
            ordenCantidad.codigo = modulo_sist.codigo;
          }
        });
        this.listaModulosSel.push(ordenCantidad);
      }
      this.totalColaboradores =0;
      this.totalMontoModulo =0;
      this.listaModulosSel.forEach(modulo=>{
        this.totalColaboradores =  this.totalColaboradores+modulo.cantidad_total_usuarios;
        this.totalMontoModulo =  this.totalMontoModulo+modulo.costo_total;
      });

      //verifica si hay slidersToogles activos sin cantidad
      this.registroService.falsearTooglesSinCantidad();
    });

    this.sbQuitarTotalColab= this.registroService.quitarTotalColaboradores$.subscribe(ordenCantidad => {
      if (this.listaModulosSel.length > 0) {
        this.totalColaboradores = this.totalColaboradores - ordenCantidad.cantidad;
        this.totalMontoModulo = this.totalMontoModulo - ordenCantidad.costo_total;
        let seleccionados: any[] = [];
        this.listaModulosSel.forEach(mods => {
          if (mods.orden != ordenCantidad.orden) {
            seleccionados.push(mods);
          }
        });
        this.listaModulosSel = seleccionados;
        if(this.listaModulosSel.length==0){
          this.terminosCondiciones=false;
        }
      }
    });

  }

  cargarModulosEdicion() {
    //modo EDICION
    if ((this.registroService.estado == EstadoRegistro.REGISTRO_COMPLETO_ADMIN) &&(this.registroService.empresa.lista_modulos_seleccionados)) {
      this.esModoEdicion = true;
      this.terminosCondiciones = true;
      this.totalColaboradores = 0;
      this.totalMontoModulo = 0;
      this.listaModulosSel = [];
      let lista_modulos_seleccionados = JSON.parse(JSON.stringify(this.registroService.empresa.lista_modulos_seleccionados));//para mantener los valores del array original

      lista_modulos_seleccionados
        .forEach((moduloEmp: {
          orden: number;
          cantidad: any;
          cantidad_total_usuarios: any;
          costo_total: any;
          menu:any;
          roles:string[];
          codigo:string;
        }) => {
          this.listaModulosSel.push(moduloEmp);
          this.listaModulos$!.forEach(moduloSist => {
            if (moduloEmp.orden == moduloSist.orden) {
              moduloSist.checked_toogle = true;
              moduloSist.control_desactivado_todo = false;
              moduloSist.cantidadIn = moduloEmp.cantidad_total_usuarios;
              moduloSist.costoTotalModoEdicion = moduloEmp.costo_total;
              //indica si se selecciono previamente
              moduloSist.es_existente = true;
              moduloEmp.menu=moduloSist.menu;//conservar el menu
              moduloEmp.roles =moduloSist.roles;
              moduloEmp.codigo =moduloSist.codigo;

              this.totalColaboradores = this.totalColaboradores + moduloEmp.cantidad_total_usuarios;
              this.totalMontoModulo = this.totalMontoModulo + moduloEmp.costo_total;

            }
          });
        });

        this.cargarFacturacion( this.registroService.empresa.id)
    }
  }

  cargarFacturacion(idEmpresa:string){
    this.facturacionBLService.fetchEmpresaById(idEmpresa);
    this.facturacionSb = this.facturacionBLService.getFacturacionCache().subscribe(fact=>{
      if(fact!=null && fact!=undefined){
        this.facturacion = fact
        // if(fact.es_periodo_prueba){
        //   this.esTiempoPrueba = true;
        // }
      }
    });
  }

  //f.invalid solo funciona si algun control esta activo y requerido
  changeTerminosCondiciones(event:MatSlideToggleChange, form:NgForm) {
    if (event.checked && this.listaModulosSel.length>0) {
      this.sbModal = this.uiService.showModalTerminos({}).subscribe(result=>{
        this.terminosCondiciones = result;
      });
      //verifica si hay slidersToogles activos sin cantidad
      this.registroService.falsearTooglesSinCantidad();
    }
    else{
      //sin el if se realiza un bucle infinito
      if(event.checked){
        this.terminosCondiciones = false;
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_REQUIERE_SELECCION, 5000);
        //instanciando al MatSlideToogle
        event.source.checked=false;
        event.source._inputElement.nativeElement.checked=this.terminosCondiciones;//desmarca el slide
        event.source._inputElement.nativeElement.dispatchEvent(new Event('change'));//emite el evento nuevamente;
      }
    }
  }

  async onSubmit(f: NgForm) {
    this.registroService.falsearTooglesSinCantidad();
    let msjCondicional = this.esModoEdicion? Msjs.MENSAJE_CONFIRMACION_CONSERVA_ROL: Msjs.MENSAJE_CONFIRMACION
    if (f.valid) {
      const modulosValidos = this.listaModulosSel.filter(mod=>mod.pendiente_anulacion_modulo==false || 
        mod.pendiente_anulacion_modulo==undefined
      );
      //Se permite vari
      //console.log(this.listaModulosSel);
      if(modulosValidos.length==0){
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MENSAJE_NINGUN_MODULO, 3000);
        return;
      }   
      this.sbModalQuest = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: msjCondicional, icon: Icons.QUESTION_MARK }).subscribe(async result => {
        if (result) {
          this.spinnerPageService.show();
          //EDICION
          if (this.esModoEdicion) {
            const observables = [
              from(this.modulosBlServices.updateModulos(this.listaModulosSel,
                this.registroService.empresa.id,
                this.registroService.empresa.lista_modulos_seleccionados,
                this.listaDecremento

                )),//convertimos la promesa en observable
            ];
            this.disabled_controls = true;
            this.sbModulos = forkJoin(observables).pipe(
              tap((res) => {
                if (res) {
                  //cerrar el expander
                  this.uiService.isExpandedGModulos$.next(false)
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                }
              }), delayWhen(() => timer(100))).subscribe(() => {
                this.spinnerPageService.hide();
              }, error => {
                console.log(error);
                //cerrar el expander
                this.uiService.isExpandedGModulos$.next(false);
                this.uiService.ShowSnackBar(SnackBarType.ERROR, error.e, 3000);
                this.spinnerPageService.hide();

              });
          }
          //NUEVO REGISTRO
          else {
            //si no tiene la empresa cargada en memoria
            if (this.registroService.empresa ?? true) {
              this.empresaBLService.getEmpresa().then(() => {
                this.modulosBlServices.insertModulos(this.listaModulosSel, this.registroService.empresa.id)
                .then((res)=>{
                  if (res.tx) {
                    this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_REGISTRO_COMPLETO, 3000);
                    this.disabled_controls = true;
                    this.registroService.estado = EstadoRegistro.REGISTRO_COMPLETO_ADMIN;
                    this.registroService.nextStep$.next(EstadoRegistro.REGISTRO_COMPLETO_ADMIN);
                    this.registroService.desactivarControlesModulos(true);
                  }else{
                    this.uiService.isExpandedGModulos$.next(false);
                    this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);
                    this.spinnerPageService.hide();
                  }
                  this.spinnerPageService.hide();
                })
              });

            }
            else{
              const observables = [
                this.modulosBlServices.insertModulos(this.listaModulosSel, this.registroService.empresa.id),//convertimos la promesa en observable
              ];
              this.sbModulos = forkJoin(observables).pipe(
                tap((res) => {
                  if (res[0].tx) {
                    this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_REGISTRO_COMPLETO, 3000);
                    this.disabled_controls = true;
                    this.registroService.desactivarControlesModulos(true);
                    this.registroService.estado = EstadoRegistro.REGISTRO_COMPLETO_ADMIN;
                    this.registroService.nextStep$.next(EstadoRegistro.REGISTRO_COMPLETO_ADMIN);

                  }
                  else{
                    this.uiService.isExpandedGModulos$.next(false);
                    this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);
                    this.spinnerPageService.hide();
                  }
                }), delayWhen(() => timer(100))).subscribe(() => {
                  this.spinnerPageService.hide();
                }, error => {
                  //cerrar el expander
                  this.uiService.isExpandedGModulos$.next(false);
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, error.e, 3000);
                  this.spinnerPageService.hide();
                });
            }

          }
        }
      });
    }
  }

  Limpiar(f: NgForm){
    if(!this.esModoEdicion){
      this.listaModulosSel=[];
      this.terminosCondiciones = false;
      this.totalColaboradores = 0;
      this.totalMontoModulo=0
      this.registroService.reiniciarControlesToogle(true);
    }
    else{
      this.cargarModulosEdicion();
    }

  }

  mostrarCostos(){
    this.uiService.showModalCostos({listaModulos: this.listaModulos$});
  }
}
