<div style="max-width: 300px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div mat-dialog-title class="dialog-title">Incidencia Producto:&nbsp;{{producto.nombre_producto}}

        </div>
        <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"></app-button>
    </div>
    <mat-divider></mat-divider>
    <div style="margin-top: 20px;">
        <div fxLayout = "column" fxLayoutGap="5px" >
            <div *ngIf="totalConIncidencia==null">
                <h2>Total:&nbsp;S/&nbsp;{{producto.precio_total | number:'1.2-2'}}</h2>
            </div>
            <div *ngIf="totalConIncidencia!=null">
                <h2>Total:&nbsp;S/&nbsp;{{totalConIncidencia | number:'1.2-2'}}</h2>
            </div>     
            <mat-chip-list *ngIf="producto.incidencia!=undefined">
                <div fxLayout="column">
                    <div  fxLayout="row" fxLayoutGap="10px" >
                        <mat-chip [matTooltip]="producto.incidencia!.comentario!" removable (removed)="quitarIncidencia()">
                            {{producto.incidencia!.motivo}}
                            <button matChipRemove>
                                <mat-icon>{{Icons.CANCEL}}</mat-icon>
                            </button>
                        </mat-chip>
                        <div style="margin-top: -10px;" *ngIf="producto.incidencia?.lista_caract_seleccionada_individualizada!=undefined" >
                            <app-button color="gray" type="icon" [icon_fig]="Icons.VISIBILITY"
                                matTooltip="Ver"                  
                                (click)="mostrarListaIncidencia()">
                            </app-button>          
                        </div> 
                    </div>
                    <div *ngIf="producto.incidencia.comentario">
                        <ul>
                            <li>
                                {{producto.incidencia.comentario}}
                            </li>
                        </ul>
                    </div>
                    <mat-divider></mat-divider>
                    <div style="margin-left: 10px; margin-top: 10px;">
                        <span style="font-weight: bold;" >N° Incidencia(s):&nbsp;{{producto.incidencia.cantidad_producto}}</span>
                    </div>                   
                  
                </div>            
            </mat-chip-list>
            <mat-chip-list *ngIf="incidenciaSelFake!=null && producto.incidencia==undefined">
                <mat-chip>
                    {{incidenciaSelFake.motivo}}                 
                </mat-chip>                
            </mat-chip-list>
            <div fxLayout="row" style="margin-top: 10px;">        
                <div *ngIf="prodCompuestoSeleccionado" >
                    <span>Prod. seleccionado(s) :&nbsp;{{cantidadReturn}}</span>
                </div>
                <div style="margin-top: -10px; margin-left: 10px;" >
                    <app-button color="gray" type="icon" [icon_fig]="Icons.VISIBILITY"
                        matTooltip="Ver" 
                        *ngIf="prodCompuestoSeleccionado"
                        (click)="mostrarProductoCompuesto()">
                    </app-button>
                    <app-button color="gray" type="icon" [icon_fig]="Icons.CLEANING"
                        matTooltip="Limpiar" 
                        *ngIf="prodCompuestoSeleccionado"
                        (click)="limpiarProductoCompuestoSel()">
                    </app-button>
                </div>                
            </div> 
        </div>
        <div style="margin-top: 10px;" >
            <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 10px;">
            <div fxLayout="row" style="font-size: 10pt;">
                <div>
                    <span>{{producto.cantidad_producto}}</span>
                    &nbsp;
                </div>
                <div>
                    <span>{{producto.nombre_producto}}</span>
                    &nbsp;
                </div>
                <div>
                    <span>(P.U. {{producto.precio_unitario | number:'1.2-2'}})</span>
                </div>
            </div>
            <div>
                <span>{{producto.precio_total | number:'1.2-2'}}</span>
            </div>
        </div>
        <div style="margin-top: 20px; margin-bottom: 10px;" 
            fxLayout="column" 
            fxLayoutAlign="space-around center">
            <mat-form-field *ngIf="producto.incidencia==undefined && producto.cantidad_producto>1" style="width: 100%;">
                <mat-label>Cantidad de Producto</mat-label>
                <mat-select [(value)]="cantidadSel" (valueChange)="calcularTotalConIncidencia()">
                    <mat-option *ngFor="let item of listaCantidadProducto;let i=index"
                        [value]="item">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="producto.incidencia==undefined" style="width: 100%;">
                <mat-label>Motivo de Incidencia</mat-label>
                <mat-select [(value)]="incidenciaObjSel" (valueChange)="calcularTotalConIncidencia()">
                    <mat-option *ngFor="let desc of incidencia?.lista_incidencia;let i=index"
                        [value]="desc">{{desc.item}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="producto.incidencia==undefined" style="width: 100%;">
                <mat-label>Comentario</mat-label>
                <textarea matInput ngModel [(ngModel)]="comentario"></textarea>
            </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <div style="margin-top: 20px;"  fxLayout="row" fxLayoutAlign="space-around center" >
            <app-button *ngIf="producto.incidencia==undefined" 
                [width]="100" text="Asignar"
                fxFlex="70%" fxFlex.xs="60%"
                [attr_disabled]="totalConIncidencia==null"
                (click)="asignarIncidencia()"></app-button>
            
            <app-button *ngIf="producto.incidencia==undefined" [width]="100" text="Limpiar" color="gray" type="outline"
                (click)="limpiarIncidencia()"></app-button>
        </div>
    </div>
</div>
