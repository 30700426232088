import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toUpper'
})
export class ToUpperPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let cadena:string = String(value)
    cadena= cadena.toUpperCase();
    return cadena
  }

}
