import { Injectable } from '@angular/core';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { GestionIncidenciasDaService } from './gestion-incidencias.da.service';
import { IIncidencia } from '../../dialog/models/IInsidencia';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GestionIncidenciasBlService {

  constructor(
    private registroService: RegistroService,
    private gestionIncidenciasDAService: GestionIncidenciasDaService
  ) { }

  existFetchIncidencia(){
    const idEmpresa = this.registroService.empresa.id;
    const idUsuario = this.registroService.usuario.id;
    this.gestionIncidenciasDAService.fetchIncidencia(idEmpresa,idUsuario);
  }

  getIncidencia():Observable<IIncidencia | null | undefined>{
    return this.gestionIncidenciasDAService.incidencia$;
  }

  insertIncidencia(incidencia: IIncidencia){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionIncidenciasDAService.insertIncidencia(idEmpresa,correoUsuario,incidencia);
  }

  updateIncidencia(incidencia:IIncidencia){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionIncidenciasDAService.updateIncidencia(incidencia,idEmpresa,correoUsuario);
  }

}
