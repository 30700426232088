import { Component, OnInit } from '@angular/core';
import { DatosPlataforma } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';

@Component({
  selector: 'app-terminos-condiciones',
  templateUrl: './terminos-condiciones.component.html',
  styleUrls: ['./terminos-condiciones.component.scss']
})
export class TerminosCondicionesComponent implements OnInit {

  titleModal:string='Terminos y Condiciones';
  readonly Icons: typeof Icons = Icons;
  readonly datosEmpresa: typeof DatosPlataforma = DatosPlataforma;

  constructor(private uiService:UIService,) { }

  ngOnInit(): void {
  }

  mostrarPrivacidad(){
    this.uiService.showModalPrivacidad({});

  }

}
