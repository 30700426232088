<div>
  <form #f="ngForm" (ngSubmit)="onSearch(f)">
    <div fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column" fxLayoutGap.xs="10px">
      <mat-form-field [ngClass]="{'mat-form-field':tamanioPantalla!='xs', 'mat-form-field-xs':tamanioPantalla=='xs'}">
        <mat-label>Buscar por:</mat-label>
        <mat-select
          name="lsCriterio"
          id="lsCriterio"
          #lsCriterio="ngModel"
          [(ngModel)]="busqueda.criterio_seleccionado"
          (selectionChange)="criterioSelect($event.value, f)"
          >
          <mat-option *ngFor="let estado of busqueda.lista_criterio" [value]="estado">{{estado |
            uppercase}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="busqueda.lista_estado.length>0 && busqueda.criterio_seleccionado!='estado'">
        <mat-form-field appearance="outline" [ngClass]="{'mat-form-field':tamanioPantalla!='xs', 'mat-form-field-xs':tamanioPantalla=='xs'}">
          <input  class="mat-form-field"
            type="text" matInput
            [placeholder]="busqueda.criterio_seleccionado | uppercase" name="txtCriterio"
            ngModel
            [pattern]="validador"
            #txtCriterio="ngModel"
            [maxlength]="maximoChar"
            [minlength]="minimoChar"
            (input)="convertirMayusculas($event)"
            [(ngModel)]="busqueda.valor_criterio"
            [disabled]="busqueda.criterio_seleccionado==''"
            >
            <mat-hint *ngIf="busqueda.criterio_seleccionado==CriteriosBusqueda.nombres" >{{ejemploNombres}}</mat-hint>
            <mat-hint *ngIf="busqueda.criterio_seleccionado==CriteriosBusqueda.numero_documento">{{ejemploDocumento}}</mat-hint>
            <mat-hint *ngIf="busqueda.criterio_seleccionado==CriteriosBusqueda.codigo">{{ejemploCodigo}}</mat-hint>
            <mat-hint *ngIf="busqueda.criterio_seleccionado==CriteriosBusqueda.usuario_creacion">{{ejemploCodigo}}</mat-hint>

          <mat-icon matSuffix>{{Icons.SEARCH}}</mat-icon>
          <mat-error *ngIf="txtCriterio.hasError('minlength') && busqueda.criterio_seleccionado== CriteriosBusqueda.nombres ">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
          <mat-error *ngIf="txtCriterio.hasError('maxlength') && busqueda.criterio_seleccionado== CriteriosBusqueda.nombres ">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>

          <mat-error *ngIf="txtCriterio.hasError('minlength') && busqueda.criterio_seleccionado== CriteriosBusqueda.usuario_creacion ">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
          <mat-error *ngIf="txtCriterio.hasError('maxlength') && busqueda.criterio_seleccionado== CriteriosBusqueda.usuario_creacion ">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>

          <mat-error *ngIf="txtCriterio.hasError('minlength') && busqueda.criterio_seleccionado== CriteriosBusqueda.numero_documento">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8}}</mat-error>
          <mat-error *ngIf="txtCriterio.hasError('maxlength') && busqueda.criterio_seleccionado== CriteriosBusqueda.numero_documento">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_9}}</mat-error>

          <mat-error *ngIf="txtCriterio.hasError('minlength') && busqueda.criterio_seleccionado== CriteriosBusqueda.codigo">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_6}}</mat-error>
          <mat-error *ngIf="txtCriterio.hasError('maxlength') && busqueda.criterio_seleccionado== CriteriosBusqueda.codigo">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_6}}</mat-error>

          <mat-error *ngIf="txtCriterio.hasError('pattern') && busqueda.criterio_seleccionado== CriteriosBusqueda.nombres ">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_2PALABRAS}}</mat-error>
          <mat-error *ngIf="txtCriterio.hasError('pattern') && busqueda.criterio_seleccionado== CriteriosBusqueda.numero_documento">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
          <mat-error *ngIf="txtCriterio.hasError('pattern') && busqueda.criterio_seleccionado== CriteriosBusqueda.codigo">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
          
          <mat-error *ngIf="txtCriterio.hasError('pattern') && busqueda.criterio_seleccionado== CriteriosBusqueda.usuario_creacion">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_NUMEROS_PUNTO}}</mat-error>          
        </mat-form-field>
      </div>
      <div *ngIf="busqueda.lista_estado.length>0 && busqueda.criterio_seleccionado=='estado' ">
        <mat-form-field [ngClass]="{'mat-form-field':tamanioPantalla!='xs', 'mat-form-field-xs':tamanioPantalla=='xs'}" >
          <mat-label>Seleccione:</mat-label>
          <mat-select
            name="lsEstado"
            id="lsEstado"
            #lsCriterio="ngModel"
            [(ngModel)]="busqueda.estado_seleccionado"
            >
            <mat-option *ngFor="let estado of busqueda.lista_estado" [value]="estado">{{estado | uppercase}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <mat-divider [ngClass]="{'mat-divider-footer':tamanioPantalla!='xs', 'mat-divider-footer-xs':tamanioPantalla=='xs'}"></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <app-button [width]="100" text="Buscar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="f.invalid"
        [attr_type]="'submit'"></app-button>
      <app-button  id="btnLimpiar" fxHide.xs type="outline" [width]="100" text="Limpiar" color="gray" (click)="Limpiar(f)"></app-button>
      <app-button id="btnLimpiar" fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING" (click)="Limpiar(f)"></app-button>
    </div>
  </form>
</div>
