import { Injectable } from '@angular/core';
import { MediosPagoDaService } from './medios-pago.da.service';
import { MediosAceptados } from '../models/MediosAceptados';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediosPagoBlService {

  constructor(
    private mediosPagoService:MediosPagoDaService,
    private registroService: RegistroService
  ) { }

  existFecthMedios(){
    const idEmpresa = this.registroService.empresa.id;
    const idUsuario = this.registroService.usuario.id;
    this.mediosPagoService.fetchMediosPago(idEmpresa,idUsuario);
  }

  getTasas():Observable<MediosAceptados | null | undefined>{
    return this.mediosPagoService.medios$;
  }


  insertMedios(medio:MediosAceptados){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario =  this.registroService.usuario.correo_electronico;
    return this.mediosPagoService.insertMedios(medio, idEmpresa, correoUsuario);
  }

  updateMedios(medio:MediosAceptados, idMedios:string){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario =  this.registroService.usuario.correo_electronico;
    return this.mediosPagoService.updateMedios(medio, idEmpresa,correoUsuario, idMedios)
  }

}
