import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { CartaDigital } from '../models/CartaDigital';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Auditoria, colections, documentCatalogo, documentsEmpresa, documentsExterno } from 'src/app/shared/cons/db.colections';
import { map } from 'rxjs/operators';
import { Utils } from 'src/app/shared/helpers/utils';
import { deleteField, serverTimestamp } from 'firebase/firestore';
import { Catalogo } from '../../dialog/models/Catalogo';

@Injectable({
  providedIn: 'root'
})
export class GestionExternoCartaDaService {

  transaccion: TransaccionModel = new TransaccionModel();
  
  private cartaCache: BehaviorSubject<CartaDigital | undefined | null> = new BehaviorSubject<CartaDigital | undefined |null>(undefined);
  public carta$: Observable<CartaDigital | undefined |null> = this.cartaCache.asObservable();
  private fetchSb!: Subscription;
  
  constructor(
    private db: AngularFirestore,
  ) { }

  fetchCarta(idEmpresa:string,idUsuario:string): void {   
    if(this.fetchSb &&!this.fetchSb.closed){
      this.cartaCache.next(this.cartaCache.value);
      return;
    }

    this.fetchSb = this.getConfiguracionCarta(idEmpresa).subscribe(carta => {
      this.cartaCache.next(carta);
    });
  }

  stopFetchingCarta(): void {
    if (this.fetchSb) {
      this.fetchSb.unsubscribe();
    }
    this.cartaCache.next(undefined);
  }

  async insertCarta(idEmpresa:string, correoUsuario:string, carta: CartaDigital){
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);

    //recuperando catalogos
    let catalogos:Catalogo[] = [];

    const listaCatalogos = await docEmpRef.collection(colections.CATALOGO, (ref)=> ref.where(Auditoria.es_vigente,'==',true)).get().toPromise();
    if(!listaCatalogos.empty){
      listaCatalogos.docs.forEach((catalogo)=>{
        const cata = catalogo.data()  as Catalogo;
        cata.id = catalogo.id;
        catalogos.push(cata);
      });
    }else{
      this.transaccion.tx = false;
      this.transaccion.data = 'No existen catálogos';
      return this.transaccion;
    }

    try{
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        //guardando carta externa
        carta.usuario_creacion = correoUsuario;
        const docCartaSerialize = Utils.SerializeJsonToDb(carta);
        docCartaSerialize.fecha_creacion = serverTimestamp();
        const docCartaExternoRef = this.db.collection(colections.EXTERNO).doc();
        transaccion.set(docCartaExternoRef.ref,docCartaSerialize);

        //insertando catalogo dentro de carta
        catalogos.forEach(catalogoX=>{
          const docCartaExternoInsRef = this.db.collection(colections.EXTERNO).doc(docCartaExternoRef.ref.id).collection(colections.CARTA_DIGITAL).doc();
          transaccion.set(docCartaExternoInsRef.ref,catalogoX);
        });
        this.transaccion.tx = true;
        return this.transaccion;         

      });
      return txResult;
    }
    catch (error) {
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }
  }

  async updateSincronizarCarta(idEmpresa:string, carta:CartaDigital){
    const docCartaRef = this.db.collection(colections.EXTERNO).doc(carta.id);
    //recuperamos contenido para eliminar
    let listaCatalogoCarta: Catalogo[]=[];
    const catalogosCarta = await docCartaRef.collection(colections.CARTA_DIGITAL).get().toPromise();
    if(!catalogosCarta.empty){
      catalogosCarta.docs.forEach((catCarta)=>{
        const cata = catCarta.data()  as Catalogo;
        cata.id = catCarta.id;
        listaCatalogoCarta.push(cata);
      });
    }
    
    try{
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
        //elimando catalogo anterior
        if(listaCatalogoCarta.length>0){
          listaCatalogoCarta.forEach((cata)=>{
            const catCartaRef = docCartaRef.collection(colections.CARTA_DIGITAL).doc(cata.id);
            transaccion.delete(catCartaRef.ref);
          });
        }
        //insertando nuevo catalogo
        //recuperando catalogos
        let catalogos:Catalogo[] = [];
        const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
        const listaCatalogos = await docEmpRef.collection(colections.CATALOGO, (ref)=> ref.where(Auditoria.es_vigente,'==',true)).get().toPromise();
        if(!listaCatalogos.empty){
          listaCatalogos.docs.forEach((catalogo)=>{
            const cata = catalogo.data()  as Catalogo;
            cata.id = catalogo.id;
            catalogos.push(cata);
          });
        }
        //insertando catalogo dentro de carta
        catalogos.forEach(catalogoX=>{
          const docCartaExternoInsRef = this.db.collection(colections.EXTERNO).doc(carta.id).collection(colections.CARTA_DIGITAL).doc();
          transaccion.set(docCartaExternoInsRef.ref,catalogoX);
        });

        this.transaccion.tx = true;
        return this.transaccion;
      });
      return txResult;
    }
    catch (error) {
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }
  }

  async updateCarta(carta:CartaDigital, usuario:string):Promise<TransaccionModel> {
    const docCartaRef = this.db.collection(colections.EXTERNO).doc(carta.id);
    try{
      const txResult = this.db.firestore.runTransaction(async (transaction) => {
        transaction.update(docCartaRef.ref,{
          es_vigente:carta.es_vigente,
          fecha_modificacion: serverTimestamp(),
          usuario_modificacion:usuario,
          ruta_parcial:carta.ruta_parcial,
          nombre_razon:carta.nombre_razon,
          direccion: carta.direccion,
          ...(carta.logo_empresa!=undefined && {logo_empresa:carta.logo_empresa}),
          ...(carta.telefono!=undefined && {telefono:carta.telefono}),
          ...(carta.ruta_instagram!=undefined && {ruta_instagram:carta.ruta_instagram}),
          ...(carta.ruta_facebook!=undefined && {ruta_facebook:carta.ruta_facebook}),

          ...(carta.logo_empresa ==undefined && {logo_empresa:deleteField()}),
          ...(carta.telefono==undefined && {telefono:deleteField()}),
          ...(carta.ruta_instagram==undefined && {ruta_instagram:deleteField()}),
          ...(carta.ruta_facebook==undefined && {ruta_facebook:deleteField()})
        });
        this.transaccion.tx = true;
        return this.transaccion;
      });
      return txResult;
    }
    catch (error) {
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }
    
  }

  getConfiguracionCartaExisteRuta(rutaParcial:string){
    return this.db.collection(colections.EXTERNO, (ref)=>
    ref.where(documentsExterno.RUTA_PARCIAL,'==',rutaParcial)
      .where(Auditoria.es_borrado,'==',false)
    ).get().toPromise();    
   }

  getConfiguracionCarta(idEmpresa:string){
    return this.db.collection(colections.EXTERNO, (ref)=>
    ref.where(documentsExterno.ID_EMPRESA,'==',idEmpresa)
      .where(Auditoria.es_borrado,'==',false).limit(1)
    ).snapshotChanges()
     .pipe(
      map(listaCarta=>{
       if(listaCarta.length>0){
         const config = Utils.convertDate(listaCarta[0].payload.doc.data()) as CartaDigital;
         config.id = listaCarta[0].payload.doc.id;
         return config;
       }
       else{
         return null;
       }
     }));
   }

 
}
