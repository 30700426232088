<div fxLayout="column">
  <app-resumen [resumen]="resumen" ></app-resumen>
  <mat-accordion displayMode="default" [multi]="true" [hideToggle]="false">
    <mat-expansion-panel [expanded]="isExpanded"(expandedChange)="panelExpandedChange($event)">
      <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
        <mat-panel-title>
          <mat-icon>{{Icons.SEARCH}}</mat-icon>
          <span class="titulo-accordeon">Búsqueda</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-buscar [busqueda]="buscar" ></app-buscar>
      <mat-action-row>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="container-button-table">
    <!-- boton estatico -->
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxHide.xs style="margin-bottom: 10px;">
        <button mat-mini-fab color="primary" class="upload-btn" type="button" (click)="openModal()"
          matTooltip="Agregar Catálogo" matTooltipPosition="before">
          <mat-icon>{{Icons.ADD}}
          </mat-icon>
        </button>
      </div>
      <div fxHide.xs style="margin-bottom: 10px;">
        <button mat-mini-fab color="primary" class="upload-btn" type="button" (click)="btnRestablecer()"
          matTooltip="Restablecer" matTooltipPosition="before" [disabled]="!isExpanded">
          <mat-icon>{{Icons.RESTORE}}
          </mat-icon>
        </button>
      </div>
    </div>
       <!-- boton flotante     -->
       <div fxLayout="row" fxLayoutAlign="space-between center" >
        <div class="floating-button" fxShow.xs fxHide>
          <button mat-fab color="primary" class="upload-btn" type="button" (click)="openModal()"
            matTooltip="Agregar Catálogo" matTooltipPosition="after">
            <mat-icon>{{Icons.ADD}}
            </mat-icon>
          </button>
        </div>
        <div class="floating-button-mini" fxShow.xs fxHide>
          <button mat-mini-fab color="primary" class="upload-btn" type="button" (click)="btnRestablecer()"
            matTooltip="Restablecer" matTooltipPosition="after" [disabled]="!isExpanded">
            <mat-icon>{{Icons.RESTORE}}
            </mat-icon>
          </button>
        </div>
      </div>

  </div>

  <div class="container-table">
    <app-table [dataTable]="dataTable" [key]="tipoTabla"></app-table>
  </div>
</div>
