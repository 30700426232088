import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog"
import { of,Subscription } from 'rxjs';
import { UIService } from 'src/app/shared/services/ui.services';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import {Icons} from '../../../../shared/cons/icons';
import {EstadoRegistro, Length_Database, MsjFirebase, Msjs, Msjs_Validations, PatternsRegex, SistType} from '../../../cons/common'
import { AuthService } from '../../../auth/auth.service';
import { StaticRoutes } from "src/app/shared/cons/route.class";
import { Router } from "@angular/router";
import { RegistroService } from 'src/app/shared/services/registro.service';
import { AutoUnsubscribe } from "../../../helpers/decorators/AutoUnsubscribe";
import { CookiesTokensService } from 'src/app/shared/services/cookies.tokens.service';
import { delay } from 'rxjs/operators';
import { FunctionsService } from 'src/app/shared/services/functions.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

@AutoUnsubscribe

export class LoginComponent implements OnInit {
  readonly Msjs_Validations:typeof Msjs_Validations = Msjs_Validations;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  CLOSE:string = Icons.CLOSE;
  titleModal:string='';
  titleButton:string='';
  readonly SistType:typeof SistType = SistType;
  authChangeCodes$!: Subscription;
  userAuthSb!: Subscription;
  isLoading:boolean=false;
  botonCerrarVisible:boolean=true;
  showBotonReenvioMailVerificacion:boolean = false;

  counter: number=61;
  btnText:string = "";
  clickadoBtnReenviarMail:boolean = false;
  disabledBotonReenvio:boolean = false;
  initialCounter: number = 60;
  intervalId: any;

  hide = true;
  hide2 = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef: MatDialogRef<LoginComponent>,
    dialog: MatDialog,
    private authService:AuthService,
    private uiService:UIService,
    private router: Router,
    private registroService: RegistroService,
    private cookieService: CookiesTokensService,
    private functionService: FunctionsService
    )
    { }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    if(this.datoPasado.operacion==SistType.LOGIN){
      this.titleModal='Iniciar Sesión';
      this.titleButton='Entrar'
    }
    else{
      if(this.datoPasado.operacion==SistType.REGISTRO){
        this.titleModal='Registrarse';
        this.titleButton = 'Registrarme'
      }
    }

    //2
    this.userAuthSb = this.authService.userAuth$.subscribe((user) => {

      //en caso de que no exista usuario auth.service redirige al index
      this.uiService.CloseModalLogin();
      this.registroService.comprobarEstadoRegistro();

      //si el usuario tiene cuenta y ya esta registrado su usuario
      switch (this.registroService.estado) {
        case (EstadoRegistro.REGISTRO_USUARIO): {
          this.router.navigate(['/' + StaticRoutes.ADMINISTRADOR + '/' + StaticRoutes.REGISTRO]);
          this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_COMPLETA_REGISTRO, 5000);
          break;
        }
        case (EstadoRegistro.REGISTRO_EMPRESA): {
          this.router.navigate(['/' + StaticRoutes.ADMINISTRADOR + '/' + StaticRoutes.REGISTRO]);
          this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_COMPLETA_REGISTRO, 5000);
          break;
        }
        case (EstadoRegistro.REGISTRO_MODULO): {
          this.router.navigate(['/' + StaticRoutes.ADMINISTRADOR + '/' + StaticRoutes.REGISTRO]);
          this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_COMPLETA_REGISTRO, 5000);
          break;
        }
        case (EstadoRegistro.REGISTRO_COMPLETO_ADMIN): {
          this.router.navigate(['/' + StaticRoutes.ADMINISTRADOR + '/' + StaticRoutes.PANEL_ADMINISTRADOR]);
          break;
        }
        case (EstadoRegistro.REGISTRO_COMPLETO_USUARIO): {
          this.router.navigate(['/' + StaticRoutes.ADMINISTRADOR + '/' + StaticRoutes.PANEL_ADMINISTRADOR]);
          break;
        }
        default:{
          //si no cumple ninguna condicion se sale al index;
          this.authService.logout()

        }
      }

    });

    //1
    this.authChangeCodes$ = this.authService.authChangeCodes$.subscribe(result=>{
      if(result.is_Error){
        this.botonCerrarVisible=true;
        switch (result.errorCode){
          case MsjFirebase.ERROR_USUARIO:{
            this.uiService.ShowSnackBar(SnackBarType.ERROR,Msjs.MSJ_LOGIN_ERROR_USUARIO);
            this.isLoading=false;
            break;
          }
          case MsjFirebase.ERROR_PASSWORD:{
            this.uiService.ShowSnackBar(SnackBarType.ERROR,Msjs.MSJ_LOGIN_ERROR_PASSWORD);
            this.isLoading=false;
            break;
          }
          case MsjFirebase.ERROR_REGISTRO:{
            this.uiService.ShowSnackBar(SnackBarType.ERROR,Msjs.MSJ_SIGNUP_ERROR_EMAIL_USADO);
            this.isLoading=false;
            break;
          }

          default:{
            this.uiService.ShowSnackBar(SnackBarType.ERROR,result.errorMessage);
            this.isLoading=false;
            break;
          }
        }
      }
      else{
        if(result.uid){
         
          switch (this.datoPasado.operacion){
            case SistType.LOGIN:{
              if(!result.email_verified){
                this.isLoading=false;
                this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_VALIDA_EMAIL, 7000);
                this.botonCerrarVisible=true;
                //mostrar boton reenviar correo de validacion
                this.showBotonReenvioMailVerificacion = true;
                this.clickadoBtnReenviarMail = false;                
                this.btnText = 'Reenviar Email?';
                this.disabledBotonReenvio = false;
                this.stopTimerSMS()                
                
              }
              //si esta verificado se lanza el observable de userAuth$
              break;
            }
            case SistType.REGISTRO:{
              this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_VALIDA_EMAIL, 7000);
              this.uiService.CloseModalLogin();
              this.authService.logout();
              break;
            }
        }

      }
    }
    });
  }

  resetContrasenia(f:NgForm){
    if(f.value.correo_electronico){      
      const email = f.value.correo_electronico;
      if(PatternsRegex.onlyEmail.test(email)){
        this.isLoading=true;
        this.authService.resetPassword(f.value.correo_electronico).then(() => {
          this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_RESET_PASSWORD_EMAIL, 7000);
          this.isLoading=false;
        }).catch((error) => {
          if (error.code === 'auth/user-not-found') {
            this.uiService.ShowSnackBar(SnackBarType.ERROR,Msjs.MSJ_LOGIN_ERROR_USUARIO);
          } else {
            this.uiService.ShowSnackBar(SnackBarType.ERROR,Msjs.MSJ_LOGIN_ERROR_ENVIO_RESET_PASSWORD);
          }
          this.isLoading=true;
        });
      }else{
        this.uiService.ShowSnackBar(SnackBarType.ERROR,Msjs_Validations.MSJ_VAL_SOLO_EMAIL);        
      }     
    }else{
      this.uiService.ShowSnackBar(SnackBarType.ERROR,Msjs_Validations.MSJ_VAL_CAMPO_VACIO); 
    }
  }

  verificarReenviarEmail(f:NgForm){
    if(f.valid){
      if(!this.clickadoBtnReenviarMail){
        this.clickadoBtnReenviarMail = true;
        this.startTimerSMS();
      }else{
        this.isLoading=true;
        this.showBotonReenvioMailVerificacion = false;
        this.functionService.VerifyAndResendEmail(f.value.correo_electronico).toPromise().then(res=>{
          if(res.tx){
            this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_VALIDA_EMAIL, 7000);
          }
          else{
            this.uiService.ShowSnackBar(SnackBarType.ERROR,Msjs.MSJ_LOGIN_ERROR_USUARIO);
          }              
          this.isLoading=false;
        });
      }
      }
  }
    
  
  startTimerSMS() {
    this.disabledBotonReenvio = true;
    this.counter = this.initialCounter; // Reiniciar el contador al valor inicial

    if (this.intervalId) {
      clearInterval(this.intervalId); // Limpiar cualquier intervalo anterior
    }

    this.intervalId = setInterval(() => {
      this.counter--;
      let textBt = `Reenviar email (${this.counter}) seg.`;
      this.btnText = textBt;
      if (this.counter === 0) {
        clearInterval(this.intervalId);
        this.disabledBotonReenvio = false;
        this.btnText = "Reenviar email";
      }
    }, 1000);
  }

  stopTimerSMS() {
    if (this.intervalId) {
      clearInterval(this.intervalId); // Limpiar el intervalo
      this.intervalId = null;
    }
    this.disabledBotonReenvio = false;
    this.btnText = "Reenviar email";
    this.counter = this.initialCounter; // Opcional: Restablecer el contador
  }


  onSubmit(f:NgForm){
    switch(this.datoPasado.operacion){
      case SistType.LOGIN:
        {
          this.botonCerrarVisible=false;
          this.onLogin(f);
          break;
        }
      case SistType.REGISTRO:
        {
          this.botonCerrarVisible=false;
          this.onRegister(f);
          break;
        }
    }
  }

  onLogin(f:NgForm){
    if(f.valid){
      this.isLoading=true;
      this.authService.login(f.value.correo_electronico, f.value.password);
    }
  }

  onRegister(f:NgForm){
    if(f.valid){
      if(f.value.password!=f.value.password2){
        this.uiService.ShowSnackBar(SnackBarType.ERROR,Msjs.MSJ_PASSWORD_DISTITNTO_ERROR);
      }
      else{
        this.isLoading=true;
        this.authService.register(f.value.correo_electronico, f.value.password);

      }
    }
  }

  onClose(){
    this.uiService.CloseModalLogin();
  }

  // ngOnDestroy(): void {
  //   this.userAuth$.unsubscribe();
  //   this.authChangeCodes$.unsubscribe();
  // }
}
