import { Component, Input, OnInit } from '@angular/core';
import { KeyboardService } from '../../services/ui.teclado.service';
import { ITableEvento } from '../table/ITableEvento';


@Component({
  selector: 'app-teclado-numerico-virtual',
  templateUrl: './teclado-numerico-virtual.component.html',
  styleUrls: ['./teclado-numerico-virtual.component.scss']
})
export class TecladoNumericoVirtualComponent implements OnInit {
  input = '';
  @Input() key:string ='';
  constructor(private keyboardService: KeyboardService) { }

  ngOnInit(): void {
    this.keyboardService.currentInput.subscribe(input => this.input += input);
  }

  sendInput(input: string) {
    const callEvento:ITableEvento = {
      key:this.key,
      data:input
    }
    this.keyboardService.changeInput(callEvento);
  }

}
