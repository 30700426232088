import { Component, OnInit } from '@angular/core';
import { MsjEstado, Msjs, Msjs_Validations, PatternsRegex, SistOperation } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { CartaDigital } from './models/CartaDigital';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { Location } from '@angular/common';
import { StaticRoutes } from 'src/app/shared/cons/route.class';
import { CEmpresa } from '../../dialog/models/cempresa.model';
import { Utils } from 'src/app/shared/helpers/utils';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/shared/services/ui.services';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { GestionExternoCartaBlService } from './services/gestion-externo-carta-bl.service';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-gestion-externo-carta',
  templateUrl: './gestion-externo-carta.component.html',
  styleUrls: ['./gestion-externo-carta.component.scss']
})

@AutoUnsubscribe

export class GestionExternoCartaComponent implements OnInit {
  isLoadingCarta:boolean = false;
  isLoadingReserva:boolean = false;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly SistOperation: typeof SistOperation = SistOperation;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  modoOperacion: SistOperation = SistOperation.VER;
  modoOperacionIncid: SistOperation = SistOperation.VER;
  readonly Icons: typeof Icons = Icons;
  existeConfCartaDigital:boolean = false;
  cartaDigital:CartaDigital = new CartaDigital();
  msjEstado:string= MsjEstado.INACTIVO;
  baseUrl:string='';
  qrData:string='';
  logoEmpresa:string='';
  empresa:CEmpresa = new CEmpresa();
  modalSb!:Subscription;
  configSb!:Subscription;
  reloadCartaSb!:Subscription;
  esEditar:boolean = false;

  telefono:string='';
  ruta_instagram:string='';
  ruta_facebook:string='';

  constructor(
    private registroService: RegistroService,
    private location: Location,
    private uiService:UIService,
    private readonly spinnerPageService: SpinnerPageService,
    private gestionExternoCartaBlService: GestionExternoCartaBlService,
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'icon-wath-black', // Nombre del ícono para usar en el template
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/icons/whats-black.svg') 
    );
    this.matIconRegistry.addSvgIcon(
      'icon-insta-black', // Nombre del ícono para usar en el template
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/icons/insta-black.svg') 
    );
    this.matIconRegistry.addSvgIcon(
      'icon-face-black', // Nombre del ícono para usar en el template
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/icons/face-black.svg') 
    );
   }

  ngOnInit(): void {
    this.reloadCartaSb= this.uiService.reloadCartaDigital$.subscribe(()=>{    
      this.cargarCarta();
    });
  }

  sincronizarCarta(){
    this.modalSb= this.uiService.showModalQuestions({title:Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION_SINCRONIZAR, icon:Icons.QUESTION_MARK})
        .subscribe(result=>{
          if(result){
            this.spinnerPageService.show();
            this.gestionExternoCartaBlService.updateSincronizarCarta(this.cartaDigital).then(transac=>{
              if(transac.tx){
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);               
              }
              else{
                this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.error, 3000);
                this.spinnerPageService.hide();
              }
            });
          }});

  }
  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }

  guardarQr(parent:any){    
    let parentElement = null;
    parentElement = parent.qrcElement.nativeElement
    .querySelector("canvas")
    .toDataURL("image/png")
    if (parentElement) {
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement)
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      // name of the file
      link.download = this.registroService.empresa.nombre_razon.toLowerCase()+'_qr_code';
      link.click()
    }

  }

  cargarCarta(){
    this.isLoadingCarta = true;
    this.gestionExternoCartaBlService.existFetchCarta();
    this.configSb?.unsubscribe();
    this.configSb = this.gestionExternoCartaBlService.getCarta().subscribe(cartaConf=>{
      if(cartaConf){
        this.cartaDigital = new CartaDigital();
        this.cartaDigital = JSON.parse(JSON.stringify(cartaConf));
        this.telefono = this.cartaDigital.telefono? this.cartaDigital.telefono : '';
        this.ruta_instagram = this.cartaDigital.ruta_instagram? this.cartaDigital.ruta_instagram : '';
        this.ruta_facebook = this.cartaDigital.ruta_facebook? this.cartaDigital.ruta_facebook : '';
        if(this.cartaDigital.es_vigente){
          this.msjEstado = MsjEstado.ACTIVO;
        }
        this.existeConfCartaDigital = true;
        this.isLoadingCarta = false;
        this.generarQr();
      }
      else{        
        this.cartaDigital = new CartaDigital();
        this.existeConfCartaDigital = false;
        this.isLoadingCarta = false;
        if(this.registroService.empresa){
          this.empresa = this.registroService.empresa;
          this.cartaDigital.ruta_parcial = this.registroService.empresa.nombre_razon.replace(' ','').toLowerCase();    
        }
        this.telefono='';
        this.ruta_facebook='';
        this.ruta_instagram = '';
      }
    });
  }

  onSubmit(f:NgForm){
    if(f.valid){
      if(this.cartaDigital.ruta_parcial!=null && this.cartaDigital.ruta_parcial!=''){
        this.modalSb= this.uiService.showModalQuestions({title:Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon:Icons.QUESTION_MARK})
        .subscribe(async result=>{
          if(result){
            this.spinnerPageService.show();
            this.cartaDigital.es_borrado = false;
            this.cartaDigital.tiene_qr = true;
            this.cartaDigital.id_empresa = this.registroService.empresa.id;
            this.cartaDigital.nombre_razon = this.registroService.empresa.nombre_razon;
            this.cartaDigital.direccion = this.registroService.empresa.direccion;
            if(this.telefono!=''){
              this.cartaDigital.telefono = this.telefono;
            }else{
              delete this.cartaDigital.telefono;
            }
            if(this.ruta_instagram!=''){
              this.cartaDigital.ruta_instagram = this.ruta_instagram;
            }else{
              delete this.cartaDigital.ruta_instagram;
            }
            if(this.ruta_facebook!=''){
              this.cartaDigital.ruta_facebook = this.ruta_facebook;
            }else{
              delete this.cartaDigital.ruta_facebook;
            }
            //validar si la ruta parcial no existe            
            const existeRutaParcial = await this.gestionExternoCartaBlService.getConfiguracionCartaExisteRuta(this.cartaDigital.ruta_parcial);           
            if(!existeRutaParcial.empty){
              const cartaExternaItem = existeRutaParcial.docs[0].data() as CartaDigital;
              cartaExternaItem.id = existeRutaParcial.docs[0].id;
              if(this.existeConfCartaDigital){
                if(this.cartaDigital.id!=cartaExternaItem.id){
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DUPLICIDAD_URL_PARCIAL, 3000);
                  return;
                }
              }
            }
            if (this.empresa.logo_empresa) {
              if ('1' in this.empresa.logo_empresa) {
                this.cartaDigital.logo_empresa = Utils.ObjecToListToArray(this.empresa?.logo_empresa)[0].path_image;
              }              
            }
            if(!this.existeConfCartaDigital){
              this.gestionExternoCartaBlService.insertCarta(this.cartaDigital).then(transac=>{
                if(transac.tx){
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                  this.esEditar = false;
                  this.existeConfCartaDigital=true;
                  this.generarQr();
                  this.modoOperacion = SistOperation.VER
                }
                else{
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.data, 3000);
                  this.cartaDigital.tiene_qr = false;
                  this.spinnerPageService.hide();                  
                }
              });

            }
            //actualizar la carta
            else{
              this.gestionExternoCartaBlService.updateCarta(this.cartaDigital).then(transac=>{
                if(transac.tx){
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                  this.modoOperacion = SistOperation.VER
                  this.existeConfCartaDigital=true;
                  this.generarQr();                 
                  this.esEditar = false;
                }
                else{
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.data, 3000);
                  this.spinnerPageService.hide();
                }
              });

            }
          }
        });
      }
    }
    

  }

  generarCarta(){
    this.cartaDigital.tiene_qr = true;
  }

  onChangeTogleCarta(event:any){
    this.cartaDigital.es_vigente = event.checked as boolean;
    if(this.cartaDigital.es_vigente){
      this.msjEstado = MsjEstado.ACTIVO;
    }
  }

  cancelarCarta(){
    this.modoOperacion = SistOperation.VER;
    this.esEditar = false;
    this.generarQr();
  }
  editarCarta(){
    this.esEditar = true;
    this.modoOperacion = SistOperation.EDITAR;
    this.getBaseUrl();
  }
  getBaseUrl() {
    const fullUrl = window.location.href;
    const baseUrl = fullUrl.substring(0, fullUrl.indexOf(this.location.path()));
    this.baseUrl = baseUrl;   
  }

  generarQr(){
    this.getBaseUrl();
    this.qrData = this.baseUrl+'/externo/carta/'+this.cartaDigital.ruta_parcial;

  }

}
