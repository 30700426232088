<div fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title class="dialog-title">Historial&nbsp;{{titulo}} </div>
  <app-button *ngIf="!isLoading" type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"
    [attr_disabled]="isLoading"></app-button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content
(touchstart)="touchStart($event)"
(touchmove)="touchMove($event)"
(touchend)="touchEnd($event)"
class="touchable-content-historial" 
>
  <div *ngFor="let item of listaHistorial; let i=index" fxLayout="column">
    <!-- monto -->
    <div matTooltip="Usuario de Creación" *ngIf="
    (item.proceso==ProcesoType.CAMBIAR_MONTO_INICIAL ||
    item.proceso==ProcesoType.CAMBIAR_ANADIR_MONTO ||
    item.proceso== ProcesoType.CAMBIAR_RETIRAR_MONTO) && item.usuario_creacion ">
      <div fxLayout="row" >
        <mat-icon class="mat-18">{{Icons.MANAGE_ACCOUNTS}}</mat-icon>
        <div style="margin-top: 5px;">
          <span>&nbsp;&nbsp;{{item.usuario_creacion |hideMail }}</span>
        </div>        
      </div>
    </div>
    <div matTooltip="Usuario de Aprobación" *ngIf="item.estado!==SolicitarCambioType.SOLICITADO && 
    (item.proceso==ProcesoType.CAMBIAR_MONTO_INICIAL ||
    item.proceso==ProcesoType.CAMBIAR_ANADIR_MONTO ||
    item.proceso== ProcesoType.CAMBIAR_RETIRAR_MONTO
    )&& item.usuario_aprobacion">
      <div fxLayout="row">
        <mat-icon class="mat-18">{{Icons.ACCOUNT_CIRCLE}}</mat-icon>
        <div style="margin-top: 5px;">
          <span>&nbsp;&nbsp;{{item.usuario_aprobacion | hideMail}}</span>
        </div>        
      </div>
    </div>

    <!-- mesa -->
    <div matTooltip="Usuario de Creación" *ngIf="item.accion_mesa && !item.usuario_aprobacion">
      <div fxLayout="row">
        <mat-icon class="mat-18">{{Icons.MANAGE_ACCOUNTS}}</mat-icon>
        <div style="margin-top: 5px;"><span>&nbsp;&nbsp;{{item.usuario_creacion |hideMail }}</span></div>
        
      </div>
    </div>
    <div matTooltip="Usuario de Aprobación" *ngIf=" item.accion_mesa && item.usuario_aprobacion && !item.estado_pedido">
      <div fxLayout="row">
        <mat-icon class="mat-18">{{Icons.ACCOUNT_CIRCLE }}</mat-icon>
        <div style="margin-top: 5px;">
          <span>&nbsp;&nbsp;{{item.usuario_aprobacion |hideMail }}</span>
        </div>  
      </div>      
    </div>

    <!-- producto -->
    <div matTooltip="Usuario de Creación" *ngIf="item.estado_producto && !item.usuario_aprobacion">
      <div fxLayout="row">
        <mat-icon class="mat-18">{{Icons.MANAGE_ACCOUNTS}}</mat-icon>
        <div style="margin-top: 5px;"><span>&nbsp;&nbsp;{{item.usuario_creacion |hideMail }}</span></div>
        
      </div>
    </div>
    <div matTooltip="Usuario de Aprobación" *ngIf="item.usuario_aprobacion && 
      !item.accion_mesa && 
      !item.estado_pedido &&
      !item.estado"
      >
      <div fxLayout="row">
        <mat-icon class="mat-18">{{Icons.ACCOUNT_CIRCLE }}</mat-icon>
        <div style="margin-top: 5px;">
          <span>&nbsp;&nbsp;{{item.usuario_aprobacion |hideMail }}</span>
        </div>        
      </div>  
    </div>

    <!-- pedido -->
    <div matTooltip="Usuario de Creación" *ngIf="item.estado_pedido && !item.usuario_aprobacion">
      <div fxLayout="row">        
        <mat-icon class="mat-18">{{Icons.MANAGE_ACCOUNTS}}</mat-icon>
        <div style="margin-top: 5px;"><span>&nbsp;&nbsp;{{item.usuario_creacion |hideMail }}</span></div>
        
      </div>
    </div>
    <div matTooltip="Usuario de Aprobación" *ngIf="item.usuario_aprobacion && 
    !item.accion_mesa && 
    item.estado_pedido &&
    !item.estado_producto
    ">
      <div fxLayout="row">
        <mat-icon class="mat-18">{{Icons.ACCOUNT_CIRCLE }}</mat-icon>
        <div style="margin-top: 5px;">
          <span>&nbsp;&nbsp;{{item.usuario_aprobacion |hideMail }}</span>
        </div>        
      </div>  
    </div>



    <div *ngIf="item.proceso" fxLayout="row">      
      <mat-icon class="mat-18">{{Icons.SETTINGS}}</mat-icon>      
      <div matTooltip="Proceso">&nbsp;&nbsp;&nbsp;{{item.proceso}}</div>
    </div>
    <div matTooltip="Fecha" fxLayout="row">
      <mat-icon class="mat-18">{{Icons.CALENDAR_MONTH}}</mat-icon>
      <div style="margin-top: 5px;">
        <span>
          &nbsp;&nbsp;
          {{item.fecha.anio}}/
          {{item.fecha.mes}}/
          {{item.fecha.dia}}&nbsp;&nbsp;
          {{item.fecha.hora}}:
          {{item.fecha.minuto}}:
          {{item.fecha.segundo}}
        </span>
      </div>        
    </div>
    <div fxLayout="row">
       <!-- pedido -->
       <mat-icon  *ngIf="item.estado_pedido" class="mat-18">{{Icons.MANAGE_HISTORY}}</mat-icon>
       <!-- mesa -->
       <mat-icon  *ngIf="item.accion_mesa" class="mat-18">{{Icons.TABLE_BAR}}</mat-icon>
      <!-- producto -->
      <mat-icon  *ngIf="item.estado_producto && !item.accion_mesa" class="mat-18">{{Icons.CATEGORY}}</mat-icon>
      <!-- otros procesos -->
      <mat-icon *ngIf="item.estado && !item.accion_mesa"  class="mat-18">{{Icons.MANAGE_HISTORY}}</mat-icon>
      <div style="margin-top: 5px;" fxLayout="row" >
        <div matTooltip="Anterior" *ngIf="item.anterior" style="margin-left: 5px;" > &nbsp;&nbsp;&nbsp;{{item.anterior}}</div>
        <mat-icon class="mat-18" *ngIf="item.anterior" >{{Icons.ARROW_FORWARD}}</mat-icon>
        <div matTooltip="Actual" *ngIf="item.actual!='inicializar'" >&nbsp;&nbsp;&nbsp;{{item.actual}}</div>
        <div matTooltip="Actual" *ngIf="item.actual=='inicializar'" >&nbsp;&nbsp;&nbsp;Agregado</div>        
      </div>
    </div>
    <div *ngIf="item.anotacion" fxLayout="row">
      <mat-icon class="mat-18">{{Icons.CHAT}}</mat-icon>
      &nbsp;&nbsp;&nbsp;
      <div style="max-width: 200px; text-align: justify;">
        {{item.anotacion}}
      </div>
    </div>
    <div style="padding-top: 5px;">
      <mat-chip-list matTooltip="Estado" *ngIf="item.estado">
        <mat-chip selected [ngClass]="{
                'chip-activo': item.estado===SolicitarCambioType.APROBADO,
                'chip-solicitado': item.estado===SolicitarCambioType.SOLICITADO,
                'chip-rechazado': item.estado===SolicitarCambioType.RECHAZADO}">
          {{item.estado | toUpper }}&nbsp;&nbsp;
        </mat-chip>
      </mat-chip-list>

      <!-- producto -->
    
      <mat-chip-list matTooltip="Estado de Producto" *ngIf="item.estado_producto">
        <mat-chip selected [ngClass]="{ 
              'chip-pendiente': item.estado_producto===EstadosProductoType.PENDIENTE,
              'chip-proceso': item.estado_producto===EstadosProductoType.EN_PROCESO,
              'chip-terminado': item.estado_producto===EstadosProductoType.TERMINADO,            
              'chip-anulado': item.estado_producto===EstadosProductoType.ANULADO,
              'chip-pendiente-revision': item.estado_producto===EstadosProductoType.PENDIENTE_REVISION}">
          {{item.estado_producto | toUpper }}&nbsp;&nbsp;
        </mat-chip>
      </mat-chip-list>

      <!-- pedido -->

      <mat-chip-list matTooltip="Estado Pedido" *ngIf="item.estado_pedido">
        <mat-chip selected [ngClass]="{ 
              'chip-pendiente': item.estado_pedido===EstadosPedidoType.PENDIENTE,
              'chip-proceso': item.estado_pedido===EstadosPedidoType.EN_PROCESO,
              'chip-terminado': item.estado_pedido===EstadosPedidoType.TERMINADO,            
              'chip-anulado': item.estado_pedido===EstadosPedidoType.ANULADO,
              'chip-pendiente-revision': item.estado_pedido===EstadosPedidoType.PENDIENTE_REVISION,
              'chip-pendiente-reabrir': item.estado_pedido===EstadosPedidoType.PENDIENTE_REABRIR,
              'chip-activo': item.estado_pedido===EstadosPedidoType .APROBADO,
              'chip-rechazado': item.estado_pedido===EstadosPedidoType.RECHAZADO,
              'chip-cerrado': item.estado_pedido===EstadosPedidoType.CERRADO
              }">
          {{item.estado_pedido.replace('_',' ')| toUpper }}&nbsp;&nbsp;
        </mat-chip>
      </mat-chip-list>
    
    </div>
    <!-- mesa -->
    <div style="padding-top: 5px;" *ngIf="item.estado_cambio" >
      <mat-chip-list matTooltip="Estado de Cambio" >
          <mat-chip  selected  [ngClass]="{
            'chip-activo': item.estado_cambio===SolicitarCambioType.APROBADO,
            'chip-solicitado': item.estado_cambio===SolicitarCambioType.SOLICITADO,
            'chip-rechazado': item.estado_cambio===SolicitarCambioType.RECHAZADO}" >
            <div fxLayout="row" >
              <div>
                {{item.estado_cambio | toUpper }}&nbsp;&nbsp;
              </div>              
            </div>                     
          </mat-chip>
          <div>
            <div *ngIf="item.es_modificado" style="font-weight: bold;" >
              {{item.reducir_cantidad | toUpper }}
            </div>
            <div *ngIf="item.es_eliminado" style="font-weight: bold;">
              ELIMINADO             
            </div> 
          </div>
      </mat-chip-list>
    </div>
    <div style="padding-top: 5px; padding-bottom: 5px;">
      <mat-divider></mat-divider>
    </div>


  </div>

</mat-dialog-content>
