import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetApiDNIRUCService {
  private apiUrlDNI = environment.apisPERU.apiDNI;
  private apiUrlRUC = environment.apisPERU.apiRUC;
  private complement ='?token=';


  constructor(private http: HttpClient) { }
  
  getDNI(dni:string): Observable<any> {  
      return this.http.get<any>(this.apiUrlDNI+dni+this.complement+environment.apisPERU.token);
  }

  getRUC(ruc:string): Observable<any> {  
    return this.http.get<any>(this.apiUrlRUC+ruc+this.complement+environment.apisPERU.token);
}
}
