import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IProductoAnidado } from '../models/IProductoAnidado';

@Injectable({
  providedIn: 'root'
})
export class DialogBuscarProductoService {
productosAnidados = new Subject<IProductoAnidado[]>();
constructor() { }

}
