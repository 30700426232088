import { Component, OnInit } from '@angular/core';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { Subscription } from 'rxjs';
import { RegistroService } from '../../services/registro.service';
import { GestionMontoBlService } from '../gestion-monto/services/gestion-monto.bl.service';
import { filter } from 'rxjs/operators';
import { Turno } from '../gestion-monto/models/Turno';
import { Table } from '../table/table.model';
import { CriteriosBusqueda, MsjEstado, SistOperation, TableTypes, TamanioPagina } from '../../cons/common';
import { ITablePedidosFiltro } from '../registrar-pedido/services/models/ITablePedidosFiltro';
import { TableService } from '../table/table.service';
import { PedidoBlService } from '../registrar-pedido/services/services.bl/pedido.bl.service';
import { Pedido } from '../registrar-pedido/models/Pedido';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { Icons } from '../../cons/icons';
import { CBuscar } from '../buscar/CBuscar';
import { ITableEvento } from '../table/ITableEvento';
import { UIService } from '../../services/ui.services';
import { BuscarService } from '../buscar/buscar.service';

@Component({
  selector: 'app-gestion-incidencias-lista',
  templateUrl: './gestion-incidencias-lista.component.html',
  styleUrls: ['./gestion-incidencias-lista.component.scss'],
})
@AutoUnsubscribe
export class GestionIncidenciasListaComponent implements OnInit {
  tieneTurnoAperturado: boolean = false;
  isLoading: boolean = false;
  turnoSb!: Subscription;
  esAdministrador: boolean = false;
  turno: Turno = new Turno();
  listaPedidoSb!: Subscription;
  dataTable: Table = new Table();
  tipoTabla: string = TableTypes.LISTAR_PEDIDOS_C_INCIDENCIA;
  listadoMesas: number[] = new Array();
  isExpanded: boolean = false;
  readonly Icons: typeof Icons = Icons;
  buscar: CBuscar = new CBuscar();
  btnVerSb!:Subscription;
  btnBuscarSb!: Subscription;
  
  constructor(
    private registroService: RegistroService,
    private gestionMontoBLService: GestionMontoBlService,
    private tableService: TableService,
    private pedidoBlService: PedidoBlService,
    private spinnerPageService: SpinnerPageService,
    private uiService: UIService,
    private buscarService: BuscarService,
  ) {
    //definimos las propiedades de la tabla
    this.dataTable.modoXs = [
      'numeracion',
      'codigo_pedido',
      'estado_pedido_vigente',
      'mesas_asignado',
      'operaciones',
    ];
    this.dataTable.modoM = [
      'numeracion',
      'codigo_pedido',
      'estado_pedido_vigente',
      'total_productos_pedidos',
      'fecha_registro',
      'mesas_asignado',
      'operaciones',
    ];
    this.dataTable.modoLg = [
      'numeracion',
      'codigo_pedido',
      'estado_pedido_vigente',
      'total_productos_pedidos',
      'mesas_asignado',
      'total_string',
      'fecha_registro',
      'usuario_registro',
      'operaciones',
    ];
    this.dataTable.columnaEstado = 'estado_pedido_vigente';
    this.dataTable.columnaImagen = '';
    this.dataTable.columnaEstiloEstado = 'estado_pedido_vigente';
    this.dataTable.pageSizeOptions = TamanioPagina.LISTA_TAMANIOS;
    this.dataTable.pageSize = TamanioPagina.LISTA_TAMANIOS[1]; // tamaño de pagina
    this.dataTable.currentPage = 1; //pagina inicial
    this.dataTable.nextPage = null;
    this.dataTable.previousPage = null;
    this.dataTable.columnaChip = undefined;
    this.dataTable.operaciones = [
      SistOperation.VER,
    ];
    this.dataTable.filtro[0] = null; //filtro codigo
    this.dataTable.filtro[1] = null;
    this.dataTable.filtro[2] = null; //filtro usuario
    //this.dataTable.filtro[3] = null; //filtro mesas
    this.dataTable.filtro[4] = null; //filtro estadoPedidoVigenteFiltro
    this.dataTable.minPage = null; //cambia ordenamiendo descendente
    this.dataTable.maxPage = true; //cambia ordenamiendo ascendente
  }

  ngOnInit(): void {
    if (this.registroService.empresa) {
      this.esAdministrador = this.registroService.esAdministrador;
      this.isLoading = true;
      //consultando existencia de turno
      this.gestionMontoBLService.existFetchTurno();
      this.turnoSb = this.gestionMontoBLService
        .getTurno()
        .pipe(filter((turno) => turno !== undefined))
        .subscribe((turno) => {
          if (turno && turno.id) {
            this.isLoading = false;
            this.tieneTurnoAperturado = true;
            this.turno = turno;
            //generando la tabla de pedidos que pertenecen al turno
            this.generarTabla(turno.id);
          } else {
            this.isLoading = false;
            this.tieneTurnoAperturado = false;
          }
        });
    }

    this.cargarBusqueda();

    //boton ver pedido
    this.btnVerSb = this.tableService.btnVer$.subscribe(
      (callEvento: ITableEvento) => {
        if (this.tipoTabla == callEvento.key) {
          const pedido = callEvento.data as Pedido;
          if (this.turno.id && pedido.id) {
            this.spinnerPageService.show();
            this.uiService.showDialoVerPedido({
              dato: pedido,
              turno: this.turno,
            });
          }
        }
      }
    );

    //busqueda
    this.btnBuscarSb = this.buscarService.goBuscar$.subscribe(
      (callEvento: ITableEvento) => {
        if (this.tipoTabla == callEvento.key) {
          this.dataTable.filtro[0] = null; //filtro codigo
          //this.dataTable.filtro[1] = null; //filtro estado
          this.dataTable.filtro[2] = null; //filtro usuario

          this.dataTable.minPage = null;
          this.dataTable.maxPage = null;
          this.dataTable.nextPage = null;
          this.dataTable.previousPage = null;
          this.dataTable.currentPage = 1;
          this.dataTable.pageSize = TamanioPagina.LISTA_TAMANIOS[1]; //tamaño de pagina
          const data: CBuscar = callEvento.data;

          switch (data.criterio_seleccionado) {
            case CriteriosBusqueda.codigo: {
              this.dataTable.filtro[0] = data.valor_criterio; //filtro codigo
              break;
            }
            case CriteriosBusqueda.estado: {
              this.dataTable.filtro[1] =
                data.estado_seleccionado == MsjEstado.ABIERTO ? true : false; //CODIGO estado
              break;
            }
            case CriteriosBusqueda.usuario_creacion: {
              this.dataTable.filtro[2] = data.valor_criterio; // usuario
              break;
            }
            // case CriteriosBusqueda.mesa: {
            //   this.dataTable.filtro[3] = data.valor_criterio;// mesas
            //   break;
            // }
            default: {
              this.dataTable.filtro[0] = null; //filtro codigo
              this.dataTable.filtro[1] = null; //filtro estado
              this.dataTable.filtro[2] = null; //filtro usuario
              this.dataTable.filtro[3] = null; //filtro mesas
              this.dataTable.filtro[4] = null; //filtro estadoPedidoVigenteFiltro
            }
          }

          if (this.turno.id) {
            this.generarTabla(this.turno.id);
          }
        }
      }
    );
  }

  cargarBusqueda() {
    this.buscar.lista_criterio = [CriteriosBusqueda.codigo];

    if (this.registroService.usuario && this.registroService.esAdministrador) {
      this.buscar.lista_criterio.push(CriteriosBusqueda.usuario_creacion);
    }  
    
    this.buscar.key = this.tipoTabla;
    this.buscar.lista_estado = [MsjEstado.ABIERTO, MsjEstado.CERRADO];
  }
  

  panelExpandedChange(event: any) {
    this.isExpanded = event;
  }

  seleccionarMesa(mesa:number){
    this.dataTable.filtro[0] = null;
    this.dataTable.filtro[1] = null;
    this.dataTable.filtro[2] = null;
    this.dataTable.filtro[3] = mesa.toString();
    this.dataTable.filtro[4] = null;
   
    if(this.turno.id){
      this.generarTabla(this.turno.id);
    }    
  }

  
  restablecerEstado(){
    this.dataTable.filtro[0] = null;
    this.dataTable.filtro[1] = null;
    this.dataTable.filtro[2] = null;
    this.dataTable.filtro[3] = null;
    this.dataTable.filtro[4] = null;   
    if(this.turno.id){
      this.generarTabla(this.turno.id);
    }
    this.isExpanded = false;
  }

  generarTabla(idTurno: string) {
    this.listaPedidoSb?.unsubscribe();
    this.tableService.isLoadingDataTable$.next({
      key: this.tipoTabla,
      data: true,
    }); //loading tabla
    const tablaFiltro: ITablePedidosFiltro = {
      codigoFiltro: this.dataTable.filtro[0], //codigo
      estadoFiltro: this.dataTable.filtro[1], //estado
      usuarioFiltro: this.dataTable.filtro[2], //usuario
      mesas: this.dataTable.filtro[3], //mesas
      estadoPedidoVigenteFiltro: this.dataTable.filtro[4], //estadoPedidoVigenteFiltro
      minPage: this.dataTable.minPage,
      maxPage: this.dataTable.maxPage,
    };

    const esIncidencia: boolean = true;

    this.listaPedidoSb = this.pedidoBlService
      .getPedidos(
        this.registroService.empresa.id,
        tablaFiltro,
        idTurno,
        false,
        false,
        esIncidencia
      )
      .subscribe((pedidos: Pedido[] | null) => {
        if (
          pedidos != null &&
          pedidos.length > 0 &&
          pedidos[0].codigo_pedido != ''
        ) {
          this.dataTable.data = [];
          this.dataTable.data = [...pedidos];
        } else {
          this.dataTable.data = [];
        }
        //genera filtro mesas
        this.listadoMesas = new Array();
        if (pedidos != null) {
          pedidos.forEach((pedido) => {
            pedido.mesas_completo.forEach((mesas) => {
               // solo para mesas existentes
            if( !isNaN(parseFloat(mesas)) && isFinite(Number(mesas))){
              this.listadoMesas.push(Number(mesas));
            }
            
            });
          });
        }
        this.listadoMesas = Array.from(new Set(this.listadoMesas));
        this.listadoMesas.sort((a, b) => a - b); //ordenamos las mesas

        this.tableService.loadDataTable$.next(this.tipoTabla); //refrescamos el objeto tabla
        this.tableService.isLoadingDataTable$.next({
          key: this.tipoTabla,
          data: false,
        }); //loading tabla

        this.spinnerPageService.hide(); //cerrado del ngOnInit
      });
  }
}
