import { AuditoriaSet } from "src/app/shared/models/auditoria.models";
import { IUsuario } from "./usuario.model";

export class CUsuario implements IUsuario  {
  id_usuario_cuenta: string='';
  id?: string | undefined;
  nombres: string='';
  apellido_paterno: string='';
  apellido_materno: string='';
  sexo: string='';
  tipo_documento: string='';
  numero_documento: number | null | undefined;
  direccion: string='';
  fecha_nacimiento: any;
  correo_electronico: string | null | undefined;
  telefono: number | null | undefined;
  codigo_departamento: string='';
  codigo_provincia: string='';
  codigo_distrito: string='';
  administrador: boolean=false;
  tiene_empresa?: boolean | undefined;
  tiene_modulos?: boolean | undefined;
  tipo_usuario?: string | undefined;
  es_borrado: boolean=false;
  es_vigente: boolean=false;
  usuario_creacion: any;
  usuario_modificacion?: any;

}
