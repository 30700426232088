import { Injectable } from '@angular/core';
import { EncuestaDaService } from './encuesta.da.service';
import { CEncuesta } from './models/encuesta.model';
import { RegistroService } from '../../services/registro.service';

@Injectable({
  providedIn: 'root'
})
export class EncuestaBlService {

  constructor( private encuestaDAService: EncuestaDaService,
    private registroService: RegistroService
    ) { }

  insertEncuesta(encuesta:CEncuesta){

    return this.encuestaDAService.insertEncuesta(
      //this.registroService.empresa.id,
      'HePPza93mbCFYbjG0R0c',
      this.registroService.usuario,
      encuesta
    );
  }
}
