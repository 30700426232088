import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, interval } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Icons } from '../../cons/icons';

@Component({
  selector: 'app-semaforo',
  templateUrl: './semaforo.component.html',
  styleUrls: ['./semaforo.component.scss']
})
export class SemaforoComponent implements OnInit {
  public speed: any;
  readonly Icons: typeof Icons = Icons;
  sbSemaforo!: Subscription;
  public speed$: Observable<any> = interval(5000).pipe(
    map(() => {
      const cnx = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      let color: string = 'warn';
      let downlinkSpeed: number;
      let effectiveType: string;

      if (cnx) {
        downlinkSpeed = cnx.downlink;
        effectiveType = cnx.downlink == 0 ? 'x' : cnx.effectiveType;

        if (downlinkSpeed >= 8) {
          color = 'primary';
        } else if (downlinkSpeed >= 4 && downlinkSpeed < 8) {
          color = 'accent';
        }
      } else {
        // Cuando cnx es nulo, usamos navigator.onLine como fallback
        const isOnline = navigator.onLine;
        downlinkSpeed = isOnline ? 1 : 0; // Asignamos un valor simbólico de velocidad
        effectiveType = isOnline ? 'Ok' : 'x';
        color = isOnline ? 'primary' : 'warn'; // Usamos 'accent' para en línea (como fallback) y 'warn' para fuera de línea
      }

      return {
        downlink: downlinkSpeed.toFixed(1),
        effectiveType,
        semaforo: color
      };
    }),
    distinctUntilChanged() // Emitimos solo si cambia el valor
  );

  constructor() {}

  ngOnInit() {
    this.sbSemaforo = this.speed$.subscribe((speed) => {
      this.speed = speed;
    });
  }
}
