<div fxLayout="row" fxLayoutAlign="center center">
  <mat-card fxFlex="80%" fxFlex.xs="100%">
    <mat-card-header>
      <mat-card-title>Medios de Pago</mat-card-title>
      <mat-card-subtitle>Configuración de Medios de Pago: </mat-card-subtitle>
    </mat-card-header>
    <mat-divider></mat-divider>
    <form #f="ngForm" (ngSubmit)="onSubmit(f)">
      <section *ngIf="!isLoading">
        <mat-card-content fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px"
        [ngStyle.lt-md]="{'margin-top': '40px','margin-bottom ':'80px'}"
        [ngStyle.gt-md]="{'margin-top': '20px','margin-bottom': '40px'}"
        >
          <div fxLayout="column" fxLayoutAlign="center start" >
            <mat-list>
                <mat-list-item>
                  <div
                  [ngStyle.lt-md]="{'margin-bottom ':'80px'}"
                  [ngStyle.gt-md]="{'margin-bottom ':'0px'}"
                  >
                    <div class="toogle">
                      <mat-slide-toggle color="primary" [disabled]="!esEditar"
                      (change)="mediosAceptados.lista_medios_pago[0].es_vigente=!mediosAceptados.lista_medios_pago[0].es_vigente"
                      [checked]="mediosAceptados.lista_medios_pago[0].es_vigente" >
                        {{mediosAceptados.lista_medios_pago[0].codigo_medio}} - {{mediosAceptados.lista_medios_pago[0].nombre_medio}}
                      </mat-slide-toggle>
                    </div>
                  </div>

                </mat-list-item>
                <mat-list-item >
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px"
                  [ngStyle.lt-md]="{}"
                  [ngStyle.gt-md]="{'margin-top':'15px'}"
                  >
                    <div class="toogle" >
                      <mat-slide-toggle color="primary" [disabled]="!esEditar"
                      (change)="cambiarTasa()"
                      [checked]="mediosAceptados.lista_medios_pago[1].es_vigente" >
                        {{mediosAceptados.lista_medios_pago[1].codigo_medio}} - {{mediosAceptados.lista_medios_pago[1].nombre_medio}}
                      </mat-slide-toggle>
                    </div>
                    <div
                    [ngStyle.lt-md]="{'padding-left ':'50px'}"
                    [ngStyle.gt-md]="{'margin-left ':'0px'}"
                    >
                      <!-- <mat-form-field class="tasa">
                        <input type="text"
                        matInput
                        placeholder="Cargo %"
                        name="{{'tasa'+ mediosAceptados.lista_medios_pago[1].codigo_medio}}"
                        [(ngModel)]="mediosAceptados.lista_medios_pago[1].tasa_medio"
                        ngModel
                        [pattern]="PatternsRegex.onlyIntegerUnsigned"
                        #tasaInput="ngModel"
                        [disabled]="!mediosAceptados.lista_medios_pago[1].es_vigente || !esEditar"
                        required
                        maxlength="2"
                        minlength="1"
                        >
                        <mat-error
                        *ngIf="tasaInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                      <mat-error
                        *ngIf="tasaInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
                      <mat-error
                        *ngIf="tasaInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_MAX2_DIGITOS}}</mat-error>
                      <mat-error
                        *ngIf="tasaInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_MIN1_DIGITOS}}</mat-error>
                      </mat-form-field> -->
                    </div>
                  </div>
                </mat-list-item>
                <mat-list-item >
                  <div fxLayout="row" fxLayoutGap="20px"
                  [ngStyle.lt-md]="{'margin-top':'50px'}"
                  [ngStyle.gt-md]="{'margin-top':'0px'}"
                  >
                    <div class="toogle">
                      <mat-slide-toggle color="primary" [disabled]="!esEditar"
                      (change)="mediosAceptados.lista_medios_pago[2].es_vigente=!mediosAceptados.lista_medios_pago[2].es_vigente"
                      [checked]="mediosAceptados.lista_medios_pago[2].es_vigente" >
                        {{mediosAceptados.lista_medios_pago[2].codigo_medio}} - {{mediosAceptados.lista_medios_pago[2].nombre_medio}}
                      </mat-slide-toggle>
                    </div>                  
                  </div>
                </mat-list-item>
                <mat-list-item >
                  <div fxLayout="row" fxLayoutGap="20px"
                  [ngStyle.lt-md]="{'margin-top':'50px'}"
                  [ngStyle.gt-md]="{'margin-top':'10px'}"
                  >
                    <div class="toogle">
                      <mat-slide-toggle color="primary" [disabled]="!esEditar"
                      (change)="mediosAceptados.lista_medios_pago[3].es_vigente=!mediosAceptados.lista_medios_pago[3].es_vigente"
                      [checked]="mediosAceptados.lista_medios_pago[3].es_vigente" >
                        {{mediosAceptados.lista_medios_pago[3].codigo_medio}} - {{mediosAceptados.lista_medios_pago[3].nombre_medio}}
                      </mat-slide-toggle>
                    </div>                    
                  </div>
                </mat-list-item>
                <mat-list-item >
                  <div fxLayout="row" fxLayoutGap="20px"
                  [ngStyle.lt-md]="{'margin-top':'50px'}"
                  [ngStyle.gt-md]="{'margin-top':'20px'}"
                  >
                    <div class="toogle">
                      <mat-slide-toggle color="primary" [disabled]="!esEditar"
                      (change)="mediosAceptados.lista_medios_pago[4].es_vigente=!mediosAceptados.lista_medios_pago[4].es_vigente"
                      [checked]="mediosAceptados.lista_medios_pago[4].es_vigente" >
                        {{mediosAceptados.lista_medios_pago[4].codigo_medio}} - {{mediosAceptados.lista_medios_pago[4].nombre_medio}}
                      </mat-slide-toggle>
                    </div>                    
                  </div>
                </mat-list-item>
                <!-- <mat-list-item >
                  <div fxLayout="row" fxLayoutGap="20px"
                  [ngStyle.lt-md]="{'margin-top':'50px'}"
                  [ngStyle.gt-md]="{'margin-top':'20px'}"
                  >
                    <div class="toogle">
                      <mat-slide-toggle color="primary" [disabled]="!esEditar"
                      (change)="mediosAceptados.lista_medios_pago[5].es_vigente=!mediosAceptados.lista_medios_pago[5].es_vigente"
                      [checked]="mediosAceptados.lista_medios_pago[5].es_vigente" >
                        {{mediosAceptados.lista_medios_pago[5].codigo_medio}} - {{mediosAceptados.lista_medios_pago[5].nombre_medio}}
                      </mat-slide-toggle>
                    </div>                    
                  </div>
                </mat-list-item> -->
            </mat-list>
          </div>
        </mat-card-content>
        <mat-card-actions *ngIf="!esEditar" fxLayout="row" fxLayoutAlign="end center"
        [ngStyle.lt-md]="{}"
        [ngStyle.gt-md]="{'margin-left': '35%', 'margin-right': '35%'}">
        <app-button type="icon" color="gray" [icon_fig]="Icons.EDIT" (click)="editarMedios()" matTooltip="Editar"
          matTooltipPosition="above"></app-button>
      </mat-card-actions>
      <mat-card-actions fxLayout="row" fxLayoutAlign="space-around center" *ngIf="esEditar"
        [ngStyle.lt-md]="{}"
        [ngStyle.gt-md]="{'margin-left': '35%', 'margin-right': '35%'}">
        <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="(f.invalid)"
          [attr_type]="'submit'"></app-button>
        <app-button fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray"
          (click)="Limpiar(f)"></app-button>
        <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CANCEL"
          (click)="Limpiar(f)"></app-button>
      </mat-card-actions>
      </section>
      <section *ngIf="isLoading">
        <app-spinner></app-spinner>
      </section>
    </form>
  </mat-card>
</div>
