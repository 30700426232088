<div>
  <div [ngClass]="backgroundImage ? 'card-info-container__title--white' : ''" class="card-info-container__title">
    {{ title }}
  </div>
  <div
    [ngClass]="[
      backgroundImage ? 'card-info-container__description--white' : '',
      positionDescription ? 'card-info-container__description--right' : ''
    ]"
    class="card-info-container__description">
    <div class="card-info-container__description__box">{{ description }}</div>
  </div>
</div>
<div class="card-info-container__footer">
  <app-button [color]="backgroundImage ? '' : 'blue'" text="Conocer más" type="outline" [icon]="true"></app-button>
  <mat-icon class="icon" [ngClass]="backgroundImage ? 'icon__image' : ''" >{{urlIcon}}</mat-icon>
</div>
