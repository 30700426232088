import { AuditoriaSet } from "src/app/shared/models/auditoria.models";

export class ModoOperacion  implements AuditoriaSet{
    es_individualizado:boolean=false;
    es_borrado: boolean=false;
    es_vigente: boolean = false;
    fecha_creacion?: any;
    fecha_modificacion?: any;
    usuario_creacion?: any;
    usuario_modificacion?: any;
    //
    id?:any
}