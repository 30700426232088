<div *ngIf="!tieneTurnoAperturado">
  <div *ngIf="isLoading" style="margin-top: 20px;">
    <app-spinner></app-spinner>
  </div>
  <div *ngIf="!isLoading" style="margin-left: 10px; margin-top: 10px;" >
    <h4>No se aperturó un turno.</h4>
  </div>
</div>
<div *ngIf="tieneTurnoAperturado">
  <!-- boton estatico -->
  <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center" style="margin-top: 10px; margin-left:10px;" >
  
    <button *ngIf="!isDisabledMesa" mat-stroked-button matTooltip="Elegir Mesa" color="primary" (click)="nuevoPedido()">
      <mat-icon class="mat-18">{{Icons.TABLE_BAR}}</mat-icon>
      <span fxShow.gt-md fxHide.lt-md style="margin-left: 5px;">1. Mesa</span>
    </button>
  
    <button *ngIf="esEditar || esNuevo || esAgregar" mat-stroked-button matTooltip="Ver Pedido" color="primary"
      (click)="contraerPedido()" fxHide fxShow.lt-md>
      <mat-icon class="mat-18">{{Icons.STICKY}}</mat-icon>
      <span fxShow.gt-md fxHide.lt-md style="margin-left: 5px;">Ver Pedido</span>
    </button>
  
    <button *ngIf="!visibleNuevoPedido || esEditar || esAgregar" mat-stroked-button matTooltip="Cancelar" color="primary"
      (click)="cancelarPedido()" class="restablecer">
      <mat-icon class="mat-18">{{Icons.CANCEL}}</mat-icon>
      <span fxShow.gt-md fxHide.lt-md style="margin-left: 5px;">Cancelar</span>
    </button>
  
  </div>

  <!-- fin boton estatico -->
  <div 
       fxLayout.xs="column"
       fxLayout.gt-xs="row"     
       fxLayoutAlign.xs="center center"
       fxLayoutAlign.gt-xs ="space-around start"
       fxLayoutGap="10px">
       
    <!-- izquierda loading -->    
    <div *ngIf="isLoadingCatalogo && !showCatalogo"  
      fxFlex.gt-xs="40"    
      fxFlex.xs="100" 
      fxLayoutAlign="center center"  
      style="padding-bottom: 10px; padding-top:100px">  
      <app-spinner></app-spinner>
    </div>
    <!-- izquierda catalogos -->
    <div
      [ngClass]="esPantallaXs?'mat-card--xs-md':'mat-card--md-lg'" 
      *ngIf="showCatalogo"  
      fxFlex.gt-xs="40" 
      fxFlex.xs="100" 
      style="padding-bottom: 10px;">
      <mat-tab-group #matTabGroupPedido>
        <mat-tab label="catalogo">
          <div style="margin-left: 30px; margin-top: 10px; ">
            <button mat-stroked-button matTooltip="Buscar Catálogo" (click)="buscarCatalogoInto()">
              <mat-icon class="mat-18">{{Icons.SEARCH}}</mat-icon>
            </button>
          </div>
          <app-listar-catalogo></app-listar-catalogo>
          <ng-template mat-tab-label>
            <mat-icon>{{Icons.MENU_BOOK}}</mat-icon>
            <span class="titulo-tab">&nbsp;&nbsp;2. Catálogo</span>
          </ng-template>
        </mat-tab>
        <mat-tab label="producto">
          <div style="margin-left: 30px; margin-top: 10px;" fxLayout="row">
            <button mat-stroked-button matTooltip="Buscar Producto" (click)="buscarProductoInto()">
              <mat-icon class="mat-18">{{Icons.SEARCH}}</mat-icon>
            </button>
            <div style="padding-left: 10px; margin-top: 10px;">
              <span *ngIf="nombreCatalogoSel" style="font-weight: bold; ">{{nombreCatalogoSel}} </span>
            </div>
          </div>
          <app-listar-producto></app-listar-producto>
          <ng-template mat-tab-label>
            <mat-icon>{{Icons.FASTFOOD}}</mat-icon>
            <div fxLayout="row">
              <span>&nbsp;&nbsp;3. Productos</span>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>   

    <!-- derecha -->
     <div fxLayoutAlign="center center" fxFlex.gt-xs="60" fxFlex.xs="100" style="margin-left: 5px;" *ngIf="pedido.ambiente_mesa.length==0">
      <section >
        <h3>No se tienen asignaciones.</h3>
      </section>
     </div>


    <div 
      *ngIf="pedido.ambiente_mesa.length>0" 
      fxFlex.gt-xs="60" 
      fxFlex.xs="100" 
      style="margin-left: 5px;" 
      [ngClass]="esPantallaXs?'mat-card--xs-md':'mat-card--md-lg-dr'" 
      >
      <mat-card >
        <section id="comanda">
          <form #f="ngForm" (ngSubmit)="onSubmit(f)">
            <mat-card-header>
              <mat-card-title>Pedido: {{pedido.codigo_pedido}}</mat-card-title>
              <mat-card-subtitle fxLayout="row" fxLayoutGap="20px">
                <div fxLayout="column" style="width: 240px;">
                  <div>Usuario: {{pedido.usuario_creacion | hideMail }}</div>
                  <div>Fecha: {{pedido.fecha_key.anio}}/{{pedido.fecha_key.mes}}/{{pedido.fecha_key.dia}}</div>
                  <div>Hora: {{pedido.fecha_key.hora}}:{{pedido.fecha_key.minuto}}:{{pedido.fecha_key.segundo}}</div>
                </div>
                <button type="button" *ngIf="esEditar && esAdministrador && 
                    listaProductoPedido.length==1 && 
                    listaProductoPedido[0].estado_producto_vigente===EstadosProductoType.PENDIENTE" mat-stroked-button
                  matTooltip="Anular Pedido" (click)="anularPedido(pedido)">
                  <mat-icon>{{Icons.DELETE}}</mat-icon>
                </button>
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-divider></mat-divider>
              <div fxLayout="row" fxLayoutGap="10px" style="padding-top: 10px;">
                <div>
                  <h3>Mesas: </h3>
                </div>                
                <mat-chip-list fxLayout="row wrap" fxLayoutAlign="start start">
                  <ng-container *ngFor="let config of pedido.ambiente_mesa">
                    <mat-chip *ngIf="config.es_vigente" [matTooltip]="config.mesa_estado.toUpperCase().replace('_',' ')"
                      [@blink]="(config.mesa_estado=== EstadosMesaType.RESERVADO ||
                                    config.mesa_estado=== EstadosMesaType.DISPONIBLE
                                    )? blinkState:''" [ngClass]="{
                                      'chip-estado-mesa-disponible': config.mesa_estado===EstadosMesaType.DISPONIBLE,
                                      'chip-estado-mesa-reservado': config.mesa_estado===EstadosMesaType.RESERVADO,
                                      'chip-estado-mesa-ocupado': config.mesa_estado===EstadosMesaType.OCUPADO,
                                      'chip-estado-mesa-proceso': config.mesa_estado===EstadosMesaType.EN_PROCESO
                                      }" [removable]="(!esAgregar && !esEditar) ||  
                        ( esEditarMesa && esEditar && config.accion_mesa!==AccionMesaType.QUITAR && !esAgregar)                    
                        " (removed)="removerMesa(config)">
                      {{config.mesa_numero}}
                      <button matChipRemove *ngIf="(!esEditar && !esAgregar )
                             ||
                          ( esEditarMesa && esEditar && config.accion_mesa!==AccionMesaType.QUITAR && !esAgregar)">
                        <mat-icon>{{Icons.CLOSE}}</mat-icon>
                      </button>
                    </mat-chip>
                    <mat-chip *ngIf="config.estado_cambio && 
                                  esVigenteSolicitudMesa &&
                                  config.es_vigente &&
                                  !esAdministrador && 
                                  config.estado_cambio===SolicitarCambioType.SOLICITADO" [@blink]="blinkState" selected
                      [ngClass]="
                                    {'chip-solicitado': config.estado_cambio===SolicitarCambioType.SOLICITADO}">
                      {{config.estado_cambio| toUpper }}
                    </mat-chip>
                  </ng-container>
                </mat-chip-list>
                
                <div>
                  <div
                    *ngIf="esEditar && !esEditarMesa && !esVigenteSolicitudMesa &&!esVigenteSolicitudProducto && !esEditarProducto && pedido.estado_pedido_vigente!= EstadosPedidoType.PENDIENTE_REVISION">
                    <app-button color="gray" type="icon" [icon_fig]="Icons.EDIT" matTooltip="Editar Mesa"
                      matTooltipPosition="above" (click)="btnEditarMesa()"></app-button>
                  </div>
                  <div *ngIf="esAdministrador && esVigenteSolicitudMesa" style="padding-top: 6px;">
                    <button type="button" [@blink]="blinkState" color="primary" style="margin-top: -10px;" mat-icon-button
                      matTooltip="Variación Solicitado" (click)="procesarSolicitud(SistOperation.PROCESAR_SOLICITUD, true)">
                      <mat-icon>{{Icons.LIVE_HELP}}</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
             


              <div style="margin-bottom: 20px;">
                <div fxLayoutGap="10px" fxLayout="row">
                  <div>
                    <h3>Productos:</h3>
                  </div>
                  <div
                    *ngIf="esEditar && !esEditarProducto && !esVigenteSolicitudMesa &&!esVigenteSolicitudProducto && !esEditarMesa && pedido.estado_pedido_vigente!= EstadosPedidoType.PENDIENTE_REVISION">
                    <app-button color="gray" type="icon" [icon_fig]="Icons.EDIT" matTooltip="Editar Productos"
                      matTooltipPosition="above" (click)="btnEditarProducto()"></app-button>
                  </div>
                  <div *ngIf="esAdministrador && esVigenteSolicitudProducto" style="padding-top: 6px;">
                    <button type="button" [@blink]="blinkState" color="primary" style="margin-top: -10px;" mat-icon-button
                      matTooltip="Variación Solicitado"
                      (click)="procesarSolicitudProducto(SistOperation.PROCESAR_SOLICITUD)">
                      <mat-icon>{{Icons.LIVE_HELP}}</mat-icon>
                    </button>
                  </div>
                </div>
                <div *ngIf="esAgregar">
                  <ng-container *ngFor="let product of listaProductoGuardadoPrevio">
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="producto-agreado">
                      <span *ngIf="product.es_pagado==undefined">
                        {{product.cantidad_producto}}&nbsp;&nbsp;{{product.nombre_producto}}&nbsp;&nbsp;(P.U.&nbsp;{{product.precio_unitario
                        | number:'1.2-2'}})&nbsp;&nbsp;
                      </span>
                      <span *ngIf="product.es_pagado!=undefined" matBadge="P" matTooltip="PrePagado">
                        {{product.cantidad_producto}}&nbsp;&nbsp;{{product.nombre_producto}}&nbsp;&nbsp;(P.U.&nbsp;{{product.precio_unitario
                        | number:'1.2-2'}})&nbsp;&nbsp;
                      </span>
                      <span>
                        {{product.precio_total | number: '1.2-2'}}
                      </span>
                    </div>
                  </ng-container>
                  <div *ngIf="listaProductoPedido.length>0" style="margin-top: 5px; margin-bottom: 5px;">
                    <mat-divider></mat-divider>
                  </div>
    
                </div>
                <ng-container *ngFor="let product of listaProductoPedido; let i= index">
                  <div fxLayout="row">
                    <div [ngClass]="esPantallaXs?'nombres-precio-xs':'nombres-precio'">
                      <mat-chip-list>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                          <mat-chip [ngStyle]="{'width': tamanioPantalla=='xs'?'250px':'270px'}"
                            [@blink]="(product.es_eliminado || product.es_modificado)? blinkState:''" [ngClass]="{                              
                                                                  'chip-pendiente-revision': product.estado_producto_vigente==EstadosProductoType.PENDIENTE_REVISION,
                                                                  'chip-solicitado': product.es_modificado,
                                                                  'chip-rechazado': product.es_eliminado,
                                                                  'chip-pendiente': product.estado_producto_vigente==EstadosProductoType.PENDIENTE,
                                                                  'chip-proceso': product.estado_producto_vigente==EstadosProductoType.EN_PROCESO,
                                                                  'chip-terminado': product.estado_producto_vigente==EstadosProductoType.TERMINADO
                                                                }" [removable]="esEditarProducto && product.es_pagado==undefined && 
                                                                product.descuento==undefined && product.incidencia== undefined
                                                                " (removed)="removeProducto(product)"
                            [selectable]="esEditarProducto && product.es_pagado==undefined && 
                                                                  product.descuento==undefined && product.incidencia==undefined"
                            [matTooltip]="'Estado: '+product.estado_producto_vigente.toUpperCase()"
                            matTooltipPosition="after" (click)="cargarCantidad(i)"
                            [matBadge]="product.es_pagado!=undefined?'P':''">
    
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                              <div style="margin-right: 5px; font-weight:bold;">{{product.cantidad_producto}}</div>
                              <div *ngIf="product.es_modificado">
                                (-{{product.cantidad_producto_reducido}}) &nbsp;
                              </div>
                              <div style="margin-right: 5px;"> {{product.nombre_producto}}</div>
                              <div>(P.U. {{product.precio_unitario | number:'1.2-2' }})</div>
                              &nbsp;
                              &nbsp;
                              <div *ngIf="product.descuento" [@blink]="blinkState" matTooltip="Descuento aplicado">
                                -{{product.descuento!.porcentaje_descuento}}%&nbsp;(x{{product.descuento!.cantidad_producto}})
                              </div>
                              &nbsp;
                              <div *ngIf="product.tiene_incidencia==true" [@blink]="blinkState"
                                [matTooltip]="product.incidencia!.motivo">
                                <mat-icon class="mat-18">{{Icons.INFO}}</mat-icon>
                              </div>
                            </div>
                            <button *ngIf=" !esEditar || esEditarProducto" matChipRemove (click)="removeProducto(product)">
                              <mat-icon>{{Icons.CLOSE}}</mat-icon>
                            </button>
                          </mat-chip>
    
                          <div fxLayout="row">
                            <div class="botones" fxShow fxHide.xs fxShow.gt-sm *ngIf="(esEditarProducto || !esEditar) && 
                                                                    product.estado_producto_vigente==EstadosProductoType.PENDIENTE &&
                                                                    product.es_pagado==undefined && product.descuento==undefined &&
                                                                    product.incidencia==undefined">
                              <ng-container *ngIf="!product.lista_caract_seleccionada">
                                <app-button *ngIf="(!esEditarProducto) ||(!esEditar && !esEditarProducto)" color="gray"
                                  type="icon" [icon_fig]="Icons.ADD" (click)="addProducto(product)"></app-button>
                                <app-button color="gray" type="icon" [icon_fig]="Icons.HORIZONTAL_RULE"
                                  *ngIf="!product.es_eliminado && !product.lista_caract_seleccionada"
                                  (click)="minusProducto(product)"></app-button>
                              </ng-container>
                              <app-button color="gray" type="icon" [icon_fig]="Icons.RULE"
                                *ngIf="!product.es_eliminado && product.lista_caract_seleccionada"
                                (click)="mostrarProductoCompuesto(product)">
                              </app-button>
                              <app-button color="gray" type="icon" [icon_fig]="Icons.DELETE" *ngIf="!product.es_eliminado"
                                (click)="removeProducto(product)">
                              </app-button>
                            </div>
                            <div class="precio-total">
                              <span style="color: brown;" *ngIf="product.es_eliminado">(-)</span>&nbsp;
                              {{product.precio_total | number:'1.2-2'}}
                            </div>


                           
                            <div fxHide.xs fxShow.gt-xs>
                              <app-button color="gray" type="icon" [icon_fig]="Icons.VISIBILITY" *ngIf="(product.es_modificado || product.es_eliminado)  && 
                                                product.lista_caract_seleccionada && 
                                                product.estado_producto_vigente == EstadosProductoType.PENDIENTE_REVISION
                                                " (click)="mostrarProductoCompuesto(product)">
                              </app-button>
                            </div>
                          </div>
                        </div>
                      </mat-chip-list>
                    </div>
    
                  </div>
    
                  <ng-container *ngIf="product.lista_caract_seleccionada && product.lista_caract_seleccionada.length>0">
                    <ul *ngFor="let caract of product.lista_caract_seleccionada" class="caracteristica-item">
                      <li>
                        <div fxLayout="row" class="caracteristica">
                          <mat-icon class="mat-18">{{Icons.ARROW_RIGHT}}</mat-icon>
                          <span>{{caract.cantidad}}&nbsp;</span>
                          <span *ngIf="caract.nombre_producto">
                            {{caract.nombre_producto}}&nbsp;
                          </span>
                          <span>{{caract.caracteristica_seleccionada}}</span>
                        </div>
                      </li>
                    </ul>
                  </ng-container>
                </ng-container>
              </div>
              <mat-divider></mat-divider>
              <div fxLayout="column" fxLayoutAlign="space-between end" fxLayoutGap="10px" class="divider">
                <div fxLayout="row">
                  <div class="etiquetas-montos">Sub Total:</div>
                  <div class="montos">S/ {{this.pedido.sub_total| number:'1.2-2' }}</div>
                </div>
                <div *ngIf="aspectosGenerales.incluir_servicio" fxLayout="row">
                  <div class="etiquetas-montos-subs">Servicio {{aspectosGenerales.tasa_servicio}}%:</div>
                  <div class="montos-subs">S/ {{this.pedido.servicio_subtotal| number:'1.2-2' }}</div>
                </div>
                <!-- <div *ngIf="aspectosGenerales.incluir_servicio" fxLayout="row">
                  <div class="etiquetas-montos-subs">Servicio IGV({{aspectosGenerales.tasa_impuesto}}%) :</div>
                  <div class="montos-subs">S/ {{this.pedido.servicio_igv| number:'1.2-2' }}</div>
                </div> -->
                <div fxLayout="row">
                  <div class="etiquetas-montos-subs"><span *ngIf="aspectosGenerales.incluir_servicio">Total</span>&nbsp;{{aspectosGenerales.sigla_impuesto}}
                    {{aspectosGenerales.tasa_impuesto}}%
                    :</div>
                  <div class="montos-subs">S/ {{this.pedido.igv| number:'1.2-2' }}</div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div fxLayout="row" class="divider" fxLayoutAlign="end center">
                <div>
                  <h2>TOTAL:</h2>
                </div>
                <div class="montos">
                  <h2> S/ {{this.pedido.total| number:'1.2-3' }}</h2>
                </div>
              </div>
              <mat-divider *ngIf="
                (!esEditar && !esVigenteSolicitudMesa && !esVigenteSolicitudProducto)||
                !esEditar 
                "></mat-divider>
              <div *ngIf="
                (!esEditar && !esVigenteSolicitudMesa && !esVigenteSolicitudProducto)||
                !esEditar 
                ">
                <mat-form-field style="width: 99%; margin-top: 10px;">
                  <mat-label>Anotación</mat-label>
                  <textarea matInput placeholder="Alguna anotación aquí..." ngModel [(ngModel)]="pedido.anotacion"
                    name="descripcion" #descripcion="ngModel" [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
                    [maxlength]="Length_Database.DB_LENGTH_LARGE_MAX_250"></textarea>
                  <mat-error
                    *ngIf="descripcion.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
                  <mat-error
                    *ngIf="descripcion.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
                </mat-form-field>
              </div>
            </mat-card-content>
            <mat-divider *ngIf="
              (!esEditar && !esVigenteSolicitudMesa && !esVigenteSolicitudProducto)||
              (esEditar && esEditarMesa && !esVigenteSolicitudMesa && !esVigenteSolicitudProducto) || 
              (esEditar && esEditarProducto && !esVigenteSolicitudMesa && !esVigenteSolicitudProducto)
              "></mat-divider>
            <mat-card-actions *ngIf="
              (!esEditar && !esVigenteSolicitudMesa && !esVigenteSolicitudProducto)||
              (esEditar && esEditarMesa && !esVigenteSolicitudMesa && !esVigenteSolicitudProducto) || 
              (esEditar && esEditarProducto && !esVigenteSolicitudMesa && !esVigenteSolicitudProducto)
              " style="margin-top: 40px;" fxLayout="row" fxLayoutAlign="space-around center">
              <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%"
                [attr_disabled]="(listaProductoPedido.length==0)" [attr_type]="'submit'"></app-button>
              <app-button type="outline" [width]="100" text="Cancelar" color="gray" (click)="Limpiar()"></app-button>
            </mat-card-actions>
          </form>
        </section>
      </mat-card>
    </div>
  </div>
</div>