import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerPageService } from './spinner-page.service';
import { AutoUnsubscribe } from "../../helpers/decorators/AutoUnsubscribe";


@Component({
  selector: 'app-spinner-page',
  template: `
    <div class="overlay" *ngIf="isLoading" >
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>`,
  styleUrls: ['./spinner-page.component.scss']
})
@AutoUnsubscribe

export class SpinnerPageComponent implements OnInit {
  isLoadingSubs!: Subscription;
  isLoading: boolean = false;

  constructor( private readonly spinnerPService: SpinnerPageService,
    private cdRef: ChangeDetectorRef

  ) { }

  ngOnInit(): void {
    this.isLoadingSubs = this.spinnerPService.isLoading$.subscribe(flag=>{
      this.isLoading = flag;
      this.cdRef.detectChanges();
    });

  }

}

