import { ICantidadModuloSel } from "./ICantidadModuloSel";
import { IContadorDocumento } from "./IContadorDocumentos";

export class ContadorDocumento implements IContadorDocumento{
  id_documento?:string;
  total_documentos: number=0;
  fecha_actualizacion?: any;
  total_documentos_activos:number=0;
  total_documentos_inactivos:number=0;
  cantidad_modulos_seleccionados: ICantidadModuloSel[]=[];

}
