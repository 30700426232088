import { Injectable } from '@angular/core';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { GestionDescuentosDaService } from './gestion-descuentos.da.service';
import { IDescuento } from '../../dialog/models/IDescuento';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GestionDescuentosBlService {

  constructor(
    private gestionDsctoDaService:GestionDescuentosDaService,
    private registroService: RegistroService
  ) { }

  existFetchDscto(){
    const idEmpresa = this.registroService.empresa.id;
    const idUsuario = this.registroService.usuario.id;
    this.gestionDsctoDaService.fetchDscto(idEmpresa,idUsuario);
  }

  getDscto():Observable<IDescuento | null | undefined>{
    return this.gestionDsctoDaService.dscto$;
  }

  insertDscto(dscto: IDescuento){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionDsctoDaService.insertDscto(idEmpresa,correoUsuario,dscto);
  }

  updateDsctos(dscto:IDescuento){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionDsctoDaService.updateDcto(dscto,idEmpresa,correoUsuario);
  }
}
