import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DatosPlataforma, Length_Database, Msjs, Msjs_Validations, PatternsRegex } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { SpConsolaService } from 'src/app/shared/services/sp-consola.service';
import { IEmpresa } from '../../dialog/models/empresa.model';
import { EstadosComprobanteElectronicoEmpresa } from 'src/app/shared/types/estado-comprobante-electronico-empresa-type';
import { Utils } from 'src/app/shared/helpers/utils';
import { ComprobanteElectronico } from '../../dialog/models/comprobante-electronico';
import * as Enumerable from 'linq-es2015';
import dataUbigeo from '../../../cons/ubigeo-inei.json';
import dataDepartamento from '../../../cons/departamento-inei.json';
import { UIService } from 'src/app/shared/services/ui.services';
import { Subscription } from 'rxjs';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';

@Component({
  selector: 'app-gestionar-comprobantes-electronico-plataf',
  templateUrl: './gestionar-comprobantes-electronico-plataf.component.html',
  styleUrls: ['./gestionar-comprobantes-electronico-plataf.component.scss']
})

@AutoUnsubscribe

export class GestionarComprobantesElectronicoPlatafComponent implements OnInit {

  readonly DatosPlataforma: typeof DatosPlataforma = DatosPlataforma;
  readonly Icons: typeof Icons = Icons;
  readonly Msjs_Validations:typeof Msjs_Validations = Msjs_Validations;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly EstadosComprobanteElectronicoEmpresa: typeof EstadosComprobanteElectronicoEmpresa = EstadosComprobanteElectronicoEmpresa;
  esBuscado:boolean = false;
  empresa!:IEmpresa | null;
  onlyNumbers = Utils.InputOnlyNumbers;
  comprobanteElectronico:ComprobanteElectronico = new ComprobanteElectronico();
  lDepartamento: any[] = new Array<any>();
  lProvincia: any[] = new Array<any>();
  lDistrito: any[] = new Array<any>();
  lUbigeo: any[] = new Array<any>();
  modalSb!: Subscription;
  disabled:boolean = false;
  estadosArray: string[] = Object.values(EstadosComprobanteElectronicoEmpresa);
  isLoading:boolean = false;
  constructor(
    private uiService:UIService,
    private spConsolaService: SpConsolaService,
    private registroService: RegistroService,
    private readonly spinnerPageService: SpinnerPageService,
  ) { }

  ngOnInit(): void {
    dataDepartamento.forEach((dep) => {
      this.lDepartamento.push(dep);
    });
    dataUbigeo.forEach((ubigeo) => {
      this.lUbigeo.push(ubigeo);
    });
  }

  seleccionarEstadoComprobante(valor: EstadosComprobanteElectronicoEmpresa){
    this.comprobanteElectronico.estado_comprobante_empresa = valor;

  }

  
  buscarEmpresa(f:NgForm){    
    this.disabled = false;
    this.comprobanteElectronico = new ComprobanteElectronico();
    if(f.valid){
      this.isLoading = true;
      this.spConsolaService.getEmpresa(f.value.ruc).then(empresa=>{
        if(empresa){
          this.empresa = empresa[0];
          this.esBuscado = true;
          this.isLoading = false;
          if(this.empresa!.comprobante_electronico!=undefined){                       
            this.departamentoSelect(this.empresa!.comprobante_electronico.departamento_pse);
            this.comprobanteElectronico = this.empresa!.comprobante_electronico;
            this.provinciaSelect(this.empresa!.comprobante_electronico.provincia_pse);
            
          }
        }else{
          this.esBuscado=true;
          this.isLoading = false;
        }
      });

    }
  }

  departamentoSelect(codigoDepartamento: any) {
    this.comprobanteElectronico.provincia_pse = '';
    this.comprobanteElectronico.distrito_pse = '';
    this.lProvincia = [];
    this.lDistrito = [];
    codigoDepartamento = codigoDepartamento.substr(0, 2);
    this.lProvincia = Enumerable.asEnumerable(this.lUbigeo)
      .Where((prov) => prov.codDepartamento == codigoDepartamento)
      .Distinct((x) => x.codProvincia)
      .Select((x) => x)
      .ToArray();
  }

  provinciaSelect(codigo: any) {
    this.lDistrito = [];
    let codigoDepartamento = this.comprobanteElectronico.departamento_pse.substr(0, 2);
    this.lDistrito = Enumerable.asEnumerable(this.lUbigeo)
      .Where(
        (prov) =>
          prov.codProvincia == codigo &&
          prov.codDepartamento == codigoDepartamento
      )
      .Distinct((x) => x.codDistrito)
      .Select((x) => x)
      .ToArray();
  }

  generarComprobante(sunat:NgForm){
    if(sunat.valid){
      this.modalSb= this.uiService.showModalQuestions({title:Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon:Icons.QUESTION_MARK}).subscribe(result=>{
        if(result){
          this.spinnerPageService.show();
          if(this.empresa!.comprobante_electronico==undefined){
            this.comprobanteElectronico.estado_comprobante_empresa = EstadosComprobanteElectronicoEmpresa.ACTIVO;
          }          
          this.comprobanteElectronico.usuario_activacion = this.registroService.usuario.correo_electronico;
          this.spConsolaService.updateEmpresaComprobante(this.comprobanteElectronico, this.empresa!.id!).then((tx)=>{
            if(tx.tx){
              this.spConsolaService.getEmpresa(this.empresa!.ruc).then(empresa=>{
                if(empresa){
                  this.empresa = empresa[0];
                  this.esBuscado = true;              
                }
              });
  
              this.uiService.ShowSnackBar(
                SnackBarType.SUCCESS,
                Msjs.MSJ_GUARDADO_OK,
                5000
              );
              this.disabled = true;
              this.spinnerPageService.hide();
            }
        
          }).catch((error)=>{
            this.uiService.ShowSnackBar(
              SnackBarType.ERROR,
              error,
              5000
            );
            this.spinnerPageService.hide();
          });       

        }
      })
    }
  }

  limpiarBusqueda(f:NgForm){
    f.controls['ruc'].reset();
    this.empresa = null;
    this.esBuscado = false;   
  }

  Limpiar(sunat:NgForm){
    sunat.reset();
    this.comprobanteElectronico = new ComprobanteElectronico();

  }


}
