import { Component, OnInit } from '@angular/core';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';
import { TableService } from '../../table/table.service';
import { PaginationService } from '../../pagination/pagination.service';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { BuscarService } from '../../buscar/buscar.service';
import { CriteriosBusqueda, EventoPaginacion, MsjEstado, Msjs, Msjs_Validations, PatternsRegex, SistOperation, TableTypes, TamanioPagina } from 'src/app/shared/cons/common';
import { DialogCatalogoBlService } from '../dialog/services/services.bl/dialog-catalogo.bl.service';
import { Table } from '../../table/table.model';
import { Subscription } from 'rxjs';
import { CBuscar } from '../../buscar/CBuscar';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { CResumen } from '../../resumen/CResumen';
import { Catalogo } from '../dialog/models/Catalogo';
import { ITableFiltroCatalogo } from '../dialog/models/ITableCatalogoFiltro';
import { ITableEvento } from '../../table/ITableEvento';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { DialogCatalogoComponent } from '../dialog/dialog-catalogo/dialog-catalogo.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-gestion-catalogo',
  templateUrl: './gestion-catalogo.component.html',
  styleUrls: ['./gestion-catalogo.component.scss']
})
@AutoUnsubscribe

export class GestionCatalogoComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  dataTable:Table = new Table();
  modalSb = new Subscription();
  resumenSb = new Subscription();
  tipoTabla:string = TableTypes.GESTIONAR_CATALOGO;
  buscar:CBuscar = new CBuscar();
  resumen:CResumen = new CResumen();
  listaCatalogo:Catalogo[] | null =[];
  listaCatalogoSb = new Subscription();
  esEditarActivarInactivar:boolean = false;
  esEliminar:boolean = false;//indica que se eliminó un registro;
  paginacionCatalogoSb = new Subscription();
  btnEditarSb = new Subscription();
  btnEditarActivarCatalogoSb = new Subscription();
  btnEditarInactivarCatalogoSb = new Subscription();
  btnVerSb = new Subscription();
  btnEliminarSb = new Subscription();
  btnBuscarSb = new Subscription();
  btnLimpiarSb = new Subscription();
  isExpanded:boolean = false;
  dialogRefSb= new Subscription();

  constructor(
    private uiService: UIService,
    private tableService: TableService,
    private paginacionService: PaginationService,
    private spinnerPageService: SpinnerPageService,
    private buscarService: BuscarService,
    private catalogoBLService: DialogCatalogoBlService,
    private registroService: RegistroService,
  ) {

    //definimos las propiedades de la tabla
    this.dataTable.modoXs = ['numeracion', 'nombre_catalogo', 'codigo_catalogo', 'operaciones'];
    this.dataTable.modoM = ['numeracion', 'nombre_catalogo', 'estado', 'total_productos','fecha_registro', 'operaciones'];
    this.dataTable.modoLg = ['numeracion', 'nombre_catalogo', 'codigo_catalogo', 'total_productos', 'estado', 'fecha_registro', 'operaciones'];
    this.dataTable.columnaEstado = 'estado';
    this.dataTable.columnaImagen = '';
    this.dataTable.columnaEstiloEstado = 'estado';
    this.dataTable.pageSizeOptions = TamanioPagina.LISTA_TAMANIOS
    this.dataTable.pageSize =  TamanioPagina.LISTA_TAMANIOS[0];// tamaño de pagina
    this.dataTable.currentPage = 1; //pagina inicial
    this.dataTable.nextPage = null;
    this.dataTable.previousPage = null;
    this.dataTable.columnaChip = undefined;
    this.dataTable.operaciones = [SistOperation.EDITAR, SistOperation.ACTIVAR, SistOperation.SUSPENDER, SistOperation.VER, SistOperation.ELIMINAR];
    this.dataTable.filtro[0] = null; //filtro nombres
    this.dataTable.filtro[1] = null; //filtro codigo
    this.dataTable.filtro[2] = null; //filtro estado
    this.dataTable.minPage = null;//cambia ordenamiendo descendente
    this.dataTable.maxPage = null;//cambia ordenamiendo ascendente
  }

  ngOnInit(): void {
    this.spinnerPageService.show(); //se cierra cuando carga la tabla
    this.cargarBusqueda();
    //observable que carga usuario colaborador vinculado con el objeto Tabla
    if (this.registroService.empresa) {
      this.generarTabla();
      this.cargarResumen();
    }
    //paginacion de tabla
    this.paginacionCatalogoSb = this.paginacionService.btnPagination$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        switch (callEvento.data.opcion) {
          case EventoPaginacion.PAGINA_PRIMERA: {
            this.dataTable.minPage = true
            this.dataTable.maxPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
            break;
          }
          case EventoPaginacion.PAGINA_ANTERIOR: {
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = true;
            this.dataTable.currentPage = this.dataTable.currentPage - 1;
            this.dataTable.maxPage = null;
            this.dataTable.minPage = null;
            break;
          }
          case EventoPaginacion.PAGINA_SIGUIENTE: {
            this.dataTable.nextPage = true;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = this.dataTable.currentPage + 1;
            this.dataTable.maxPage = null;
            this.dataTable.minPage = null;
            break;
          }
          case EventoPaginacion.PAGINA_ULTIMA: {
            this.dataTable.maxPage = true
            this.dataTable.minPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
            break;
          }
          case EventoPaginacion.CAMBIO_TAMANIO_PAGINA: {
            this.dataTable.maxPage = null
            this.dataTable.minPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
            this.dataTable.pageSize = callEvento.data.tamanio;
            break;
          }
          default: {
            this.dataTable.maxPage = null
            this.dataTable.minPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
          }
        }
        this.generarTabla();

      }
    });

    //boton Editar catalogo
    this.btnEditarSb = this.tableService.btnEditar$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        //flag si previamente hay mas elementos
        if (this.dataTable.filtro.some((elemento) => elemento !== null)) {
          this.esEditarActivarInactivar = true;
        }
        //
        //this.uiService.closeDialogProducto();
        this.uiService.showDialogCatalogo({ operacion: SistOperation.EDITAR, data: callEvento.data });
      }
    });

    this.btnEditarActivarCatalogoSb = this.tableService.btnActivar$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        this.uiService.closeModalQuestions();
        this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION_ACTIVAR_OTROS.replace('$','catálogo'), icon: Icons.QUESTION_MARK }).subscribe(result => {
          if (result) {
            this.spinnerPageService.show();
            //flag si previamente hay mas elementos
            if (this.dataTable.filtro.some((elemento) => elemento !== null)) {
              this.esEditarActivarInactivar = true;
            }
            //
            this.catalogoBLService.updateCatalogoEstado(callEvento.data, true).then(resultTx => {
              if (resultTx.tx) {
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.spinnerPageService.hide();

              } else {
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.ERROR, resultTx.error, 3000);
              }
            });
          }
        });
      }
    });

    this.btnEditarInactivarCatalogoSb = this.tableService.btnSuspender$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        this.uiService.closeModalQuestions();
        this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION_INACTIVAR_OTROS.replace('$','catálogo'), icon: Icons.QUESTION_MARK }).subscribe(result => {
          if (result) {
            this.spinnerPageService.show();
            //flag si previamente hay mas elementos
            if (this.dataTable.filtro.some((elemento) => elemento !== null)) {
              this.esEditarActivarInactivar = true;
            }
            //
            this.catalogoBLService.updateCatalogoEstado(callEvento.data, false).then(resultTx => {
              if (resultTx.tx) {
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.spinnerPageService.hide();

              } else {
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.ERROR, resultTx.error, 3000);
              }
            });
          }
        });
      }
    });

    this.btnVerSb = this.tableService.btnVer$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        this.uiService.closeDialogProducto();
        this.uiService.showDialogCatalogo({ operacion: SistOperation.VER, data: callEvento.data });
      }

    });

    this.btnEliminarSb = this.tableService.btnEliminar$.subscribe((callEvento: ITableEvento)=>{
      if (this.tipoTabla == callEvento.key) {
        this.uiService.closeModalQuestions();
        this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION_CONSECUENCIAS_ELIMINAR, icon: Icons.QUESTION_MARK }).subscribe(result => {
          if(result){
            this.spinnerPageService.show();
            this.catalogoBLService.deleteCatalogo(callEvento.data).then(resultTx=>{
              if (resultTx.tx) {
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.spinnerPageService.hide();
                //cuando se borra un elemento debe recargarse e iniciar en la primera página ya que el paginado ya cambió
                this.reloadTableIndex();
              } else {
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.ERROR, resultTx.error, 3000);
              }
            });
          }
        });
      }
    });


    //busqueda
    this.btnBuscarSb = this.buscarService.goBuscar$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        this.dataTable.filtro[0] = null; //filtro nombres
        this.dataTable.filtro[1] = null; //filtro nro documento
        this.dataTable.filtro[2] = null; //filtro estado
        this.dataTable.minPage = null;
        this.dataTable.maxPage = null;
        this.dataTable.nextPage = null;
        this.dataTable.previousPage = null;
        this.dataTable.currentPage = 1;
        this.dataTable.pageSize = TamanioPagina.LISTA_TAMANIOS[0];//tamaño de pagina
        const data: CBuscar = callEvento.data;

        switch (data.criterio_seleccionado) {
          case CriteriosBusqueda.nombres: {
            this.dataTable.filtro[0] = data.valor_criterio; //filtro nombres
            break;
          }
          case CriteriosBusqueda.codigo: {
            this.dataTable.filtro[1] = data.valor_criterio; //CODIGO PRODUCTO
            break;
          }
          case CriteriosBusqueda.estado: {
            this.dataTable.filtro[2] = data.estado_seleccionado == MsjEstado.ACTIVO ? true : false; //filtro estado
            break;
          }
          default: {
            this.dataTable.filtro[0] = null; //filtro nombres
            this.dataTable.filtro[1] = null; //codigo
            this.dataTable.filtro[2] = null; //filtro estado
          }
        }

        this.generarTabla();
      }
    });

    //limpiar
    this.btnLimpiarSb = this.buscarService.goLimpiar$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
          this.reloadTableIndex();
      }
    });
  }

  cargarBusqueda() {
    this.buscar.lista_criterio = [CriteriosBusqueda.nombres, CriteriosBusqueda.codigo,CriteriosBusqueda.estado];
    this.buscar.key = this.tipoTabla;
    this.buscar.lista_estado = [MsjEstado.ACTIVO, MsjEstado.INACTIVO];
  }

  cargarResumen(){
    this.resumen.icono1 = Icons.TOGGLE_ON;
    this.resumen.icono2 = Icons.TOGGLE_OFF;
    this.resumen.texto1 = 'Catálogos activos';
    this.resumen.texto2 = 'Catálogos inactivos';
  }

  generarTabla() {
    //para no almacenar los observables de paginas previas y solo escuchar lo quee sta en curso
    if (this.listaCatalogoSb) {
      this.listaCatalogoSb.unsubscribe();
    }

    this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: true });//loading tabla
    const tablaFiltro:ITableFiltroCatalogo = {
      itemsPerPage: this.dataTable.pageSize,
      currentPage: this.dataTable.currentPage,
      nombresFiltro: this.dataTable.filtro[0],
      codigoFiltro: this.dataTable.filtro[1],
      estadoFiltro: this.dataTable.filtro[2],
      nextPage: this.dataTable.nextPage,
      previousPage: this.dataTable.previousPage,
      minPage: this.dataTable.minPage,
      maxPage: this.dataTable.maxPage,
    }

    this.listaCatalogoSb = this.catalogoBLService.getCatalogoResumenCombinado(
      tablaFiltro////click boton fin
    ).subscribe((catalogos: Catalogo[]) => {
        if (catalogos[0].totalRegistros_query != undefined && catalogos[0].totalRegistros_query > 0) {
          this.dataTable.data = [...catalogos];
          //total de registros en la bd para mostrar paginacion
          this.dataTable.totalRows = catalogos[0].totalRegistros_query;
        }
        else {
          if(tablaFiltro.currentPage>1){
            this.dataTable.currentPage = tablaFiltro.currentPage-1;
            this.dataTable.nextPage = false;
            this.generarTabla();
          }
          else{
            this.dataTable.data = new Array();
            this.dataTable.totalRows = 0;
          }
        }
        //reinicia la busqueda si edita filtrado o elimina
        if (this.esEditarActivarInactivar || this.esEliminar) {
          this.esEditarActivarInactivar = false;
          this.esEliminar = false;
          const callEvento: ITableEvento = {
            key: this.tipoTabla,
            data: this.simularBusqueda()
          }
          this.buscarService.goBuscar$.next(callEvento);
          return;
        }
        //para resumen
        this.resumen.valor1 = catalogos[0].totalRegistrosActivos? catalogos[0].totalRegistrosActivos:0;
        this.resumen.valor2 = catalogos[0].totalRegistrosInactivos? catalogos[0].totalRegistrosInactivos:0;

        this.tableService.loadDataTable$.next(this.tipoTabla);//refrescamos el objeto tabla
        this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: false });//loading tabla

        this.spinnerPageService.hide();//cerrado del ngOnInit
      }, error => {
        console.error('Error en el Observable:', error);
      },
        () => {
          //cuando se completa el observable
        });
  }


  simularBusqueda(){
    const busquedaS:CBuscar = new CBuscar();
    busquedaS.criterio_seleccionado= this.dataTable.filtro[0] !== null ? CriteriosBusqueda.nombres
                : this.dataTable.filtro[1] !== null ? CriteriosBusqueda.codigo
                  : this.dataTable.filtro[2] !== null ? CriteriosBusqueda.estado : '';
    this.dataTable.filtro[0] !== null ? busquedaS.valor_criterio = this.dataTable.filtro[0]
    : this.dataTable.filtro[1] !== null ? busquedaS.valor_criterio = this.dataTable.filtro[1]
      : this.dataTable.filtro[2] !== null ? busquedaS.estado_seleccionado = this.dataTable.filtro[1]:'';

      return busquedaS;

  }

  reloadTableIndex(){
    this.dataTable.filtro[0] = null, //filtro nombres
    this.dataTable.filtro[1] = null, //filtro codigo
    this.dataTable.filtro[2] = null, //filtro estado
    this.dataTable.currentPage = 1;
    this.dataTable.nextPage = null;
    this.dataTable.previousPage = null;
    this.dataTable.minPage = null;
    this.dataTable.maxPage = null;
    this.dataTable.pageSize = TamanioPagina.LISTA_TAMANIOS[0];//tamaño de pagina
    this.generarTabla();
  }

  openModal(){
    const dialogRef = this.uiService.showDialogCatalogo({
      operacion: SistOperation.NUEVO,
      data:null
    });

    //recargando la tabla siempre en cuando la pagina sea mayor a 1 y es nuevo
    this.dialogRefSb = dialogRef.afterClosed().subscribe((resultado:any)=>{
      if(resultado){
        if(resultado.tx_reload && this.dataTable.currentPage>1){
          this.reloadTableIndex();
         }
      }
    });
  }

  btnRestablecer() {
    const callEvento: ITableEvento = {
      key: this.tipoTabla,
      data: null
    }
    this.buscarService.goLimpiarInput$.next(callEvento);
    this.isExpanded = !this.isExpanded;
  }

  panelExpandedChange(event: any) {
    this.isExpanded = event;
  }


}
