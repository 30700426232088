<button *ngIf="type === 'outline'"
  [ngClass]="color === 'blue' ? 'button-outline--blue' :
  color==='gray'?'button-outline--gray':
  color==='blueA200'?'button-outline--blueA200':
  color==='gray-b0'?'button-outline--gray-b0':
  color==='blueA200-b0'?'button-outline--blueA200-b0':
  ''" [style.width]="width > 0 ? width + '.px' : width === -1 ? '100%' : null" class="btn-no-style button-outline"
  [type]="attr_type==null? 'button': attr_type=='submit'?'submit':'button'"
  mat-stroked-button>
  <span *ngIf="text">{{ text }}</span>
  <img *ngIf="icon" [src]="
      color === 'blue'
        ? 'assets/icons/navigate-next-fill-blue.png'
        : 'assets/icons/navigate-next-fill-white.png'
    " alt="icon" />
  <mat-icon *ngIf="icon_fig" [ngClass]="color === 'blue' ? 'button-outline--blue' :
  color==='gray'?'button-outline--gray':
  color==='blueA200'?'button-outline--blueA200':
  color==='gray-b0'?'button-outline--gray-b0':
  ''">{{icon_fig}}</mat-icon>
</button>

<button *ngIf="type === 'normal'" [style.width]="width > 0 ? width + '.px' : width === -1 ? '100%' : null"
  mat-flat-button
  [attr.disabled]="attr_disabled ? '':null"
  [type]="attr_type=='button'? 'button': 'submit'"
  [ngClass]="attr_disabled==false?'button-normal':'button-outline--blueA200'"
  >
  <span>{{ text }}</span>
</button>

<button *ngIf="type === 'link'" [ngClass]="color === 'white' ? '' : ''"
  [style.width]="width > 0 ? width + '.px' : width === -1 ? '100%' : null" class="btn-no-style button-link"
  color="primary" mat-flat-button>
  <span>{{ text }}</span>
  <img *ngIf="icon" [src]="
      color === 'white'
        ? 'assets/img/icons/navigate-next-fill-white.png'
        : 'assets/img/icons/navigate-next-fill-blue.png'
    " alt="icon" />
</button>

<button mat-mini-fab *ngIf="type === 'fab_icon'" [ngClass]="color === '' ? 'button-fab-icon' :
color==='gray'?'button-fab-icon--gray':''
">
  <mat-icon>{{ icon_fig }}</mat-icon>
</button>

<button mat-button
  *ngIf="type === 'basic'"
  [style.width]="width > 0 ? width + '.px' : width === -1 ? '100%' : null"
  [type]="attr_type==null? 'button': attr_type=='submit'?'submit':'button'"
  [ngClass]="icon_fig?'button-basic':'button-basic--border0'"
  [disabled]="attr_disabled ? '':null"
  >
  <span *ngIf="text">{{text}}</span>
  <mat-icon *ngIf="icon_fig" color="primary">{{icon_fig}}</mat-icon>
</button>

<button mat-icon-button
type="button"
*ngIf="type==='icon'"
[ngClass]="color === 'gray' ? 'icon-mat--gray' :
color==='blue'?'icon-mat--blue':'icon-mat--white'"
[attr.disabled]="attr_disabled ? '':null"
>
  <mat-icon>{{icon_fig}}</mat-icon>
</button>
