import { Injectable } from '@angular/core';
import { UsuarioDAService } from '../components/services/services.da/usuario.da.service';
import { EmpresaDAService } from '../components/services/services.da/empresa.da.service';
import { UsuarioColaboradorDaService } from '../components/services/services.da/usuarioColaborador.da.service';
import { ModulosDAService } from '../components/services/services.da/modulos.da.service';
import { FacturacionDAService } from '../components/services/services.da/facturacion.da.service';
import { AuthSessionService } from '../auth/auth.session.service';
import { GestionAmbientesDaService } from '../components/procesos/gestion-ambientes-mesas/services/services.da/gestion-ambientes.da.service';
import { MediosPagoDaService } from '../components/procesos/gestion-medios-pago/services/medios-pago.da.service';
import { GestionAspectosDaService } from '../components/procesos/gestion-aspectos-generales/services/services.da/gestion-aspectos.da.service';
import { GestionMontoDaService } from '../components/gestion-monto/services/gestion-monto.da.service';
import { MensajeDaService } from '../components/gestion-monto/services/mensaje.da.service';
import { GestionDescuentosDaService } from '../components/procesos/gestion-descuentos-incidencias/services/gestion-descuentos.da.service';
import { GestionIncidenciasDaService } from '../components/procesos/gestion-descuentos-incidencias/services/gestion-incidencias.da.service';
import { ConfiguracionGeneralDaService } from './configuracion-general-da.service';
import { GestionOperacionesDAService } from '../components/procesos/gestion-operaciones/services/gestion-operaciones.da.service';
import { GestionExternoCartaDaService } from '../components/procesos/gestion-externo-carta/services/gestion-externo-carta-da.service';
@Injectable({
  providedIn: 'root'
})
export class CloseCnxBDService {
  constructor(
    private usuarioDAService: UsuarioDAService, //optimizado
    private empredaDAService: EmpresaDAService, //optimizado
    private usuarioColaboradorDaService: UsuarioColaboradorDaService,//optimizado
    private facturaciobDAService: FacturacionDAService, //optimizado
    private authSessionService: AuthSessionService, //optimizado
    private gestionAmbienteMesaDaService: GestionAmbientesDaService,//optimizado
    private mediosPagoDaService:MediosPagoDaService,//optimizado
    private gestionAspectosDaService: GestionAspectosDaService,// optimizado
    private gestionMontoDaService: GestionMontoDaService,//optimizado
    private mensajeDAService: MensajeDaService,
    private descuentoDAService: GestionDescuentosDaService,
    private incidenciaDAService: GestionIncidenciasDaService,
    private configGen:ConfiguracionGeneralDaService,
    private configModoOp: GestionOperacionesDAService,
    private configCartaDigital: GestionExternoCartaDaService
  ){

  }

  CloseCnxBDAll():void{
      this.usuarioDAService.stopFetchingUsuario();
      this.empredaDAService.stopFetchingEmpresa();
      this.usuarioColaboradorDaService.stopFetchingUsuarioColaborador();
      this.facturaciobDAService.stopFetchingFacturacion();
      this.authSessionService.stopFetchingSession();
      this.gestionAmbienteMesaDaService.stopFetchingAmbienteMesa();
      this.mediosPagoDaService.stopFetchingMedios();
      this.gestionAspectosDaService.stopFetchingTasas();
      this.gestionMontoDaService.stopFetchingTurno();
      this.mensajeDAService.stopFetchingMensaje();
      this.descuentoDAService.stopFetchingDscto();
      this.incidenciaDAService.stopFetchingIncidencia();
      this.configGen.stopFetchingConfigGen();
      this.configModoOp.stopFetchingModoOp();
      this.configCartaDigital.stopFetchingCarta();

  }
}
