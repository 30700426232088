import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ItemSidenav } from 'src/app/shared/models/item.model';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { EstadoRegistro } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { CONFIGURACION } from 'src/app/shared/cons/config';
import { EmpresaBLService } from 'src/app/shared/components/services/services.bl/empresa.bl.service';
import { AutoUnsubscribe } from "../../../shared/helpers/decorators/AutoUnsubscribe";
import { UINavigateSidenavService } from 'src/app/shared/services/ui.navigate-sidenav.services';
import { Utils } from 'src/app/shared/helpers/utils';
import { IEmpresa } from 'src/app/shared/components/dialog/models/empresa.model';


@Component({
  selector: 'app-layout-admin',
  templateUrl: './layout-admin.component.html',
  styleUrls: ['./layout-admin.component.scss']
})

@AutoUnsubscribe

export class LayoutAdminComponent implements OnInit {
  es_registro: boolean = false;
  estado_usuario!: number;
  stepSb!: Subscription;
  sbEmpresa!: Subscription;
  readonly Icons: typeof Icons = Icons;
  nextStepSb!: Subscription;
  lRoles_primarcas: any[] = CONFIGURACION.ROLES_PRIMARCAS;
  goOptionSidenavSb!: Subscription;
  reloadOptionsMenuSb!: Subscription;
  //controla la recarga de la pagina y la redirige
  sidenav_item: ItemSidenav[] = []


  constructor(
    private registroService: RegistroService,
    private empresaBLService: EmpresaBLService,
    private uiNavigateSide: UINavigateSidenavService,
  ) {
  }

  ngOnInit(): void {
    //cuando viene del login
    this.cambiarMenuSidenav(this.registroService.estado);

    //cuando se selecciona una opcion del sidenav administrador
    this.goOptionSidenavSb = this.uiNavigateSide.goOptionSidenav$.subscribe(path => {
      this.uiNavigateSide.navegarSidenavOpcion(path);
    });

    //cuarto paso cuando se completa el registro del administrador
    this.stepSb = this.registroService.loadSidenav$.subscribe(()=>{
      this.cambiarMenuSidenav(this.registroService.estado);
    });

    this.reloadOptionsMenuSb = this.uiNavigateSide.reloadOptionsMenu$.subscribe((listModulos)=>{
      if(listModulos){
        this.sidenav_item = listModulos;
      }
    });

    //se activa para modo edicion, cuando hay algun cambio en empresa;
    this.sbEmpresa = this.empresaBLService.getEmpresaCache().subscribe(empresa => {
      if(empresa && (EstadoRegistro.REGISTRO_COMPLETO_ADMIN || EstadoRegistro.REGISTRO_COMPLETO_USUARIO)){
        this.cargarEmpresaDatosMenu(empresa);
      }
    })

  }

  cargarEmpresaDatosMenu(empresa:IEmpresa){
    this.registroService.empresa = { ...empresa };
    this.registroService.empresa.lista_modulos_seleccionados = Utils.ObjecModuloToListToArray(this.registroService.empresa.lista_modulos_seleccionados);
    //setea opciones de menu de en sidenav
    this.sidenav_item = Utils.GetArrayMenu(this.registroService.empresa.lista_modulos_seleccionados);
    //mandamos a cachear y emitir las opciones del menu
    this.uiNavigateSide.fetchOptionMenuSidenav(this.sidenav_item);
    this.registroService.loadEmpresa$.next();
  }

  cambiarMenuSidenav(estado: number | null) {
    switch (estado) {
      case (null):{
        //no hacemos nada
        break;
      }
      case (EstadoRegistro.REGISTRO_USUARIO): {
        this.es_registro = true;
        break;
      }
      case (EstadoRegistro.REGISTRO_EMPRESA): {
        this.es_registro = true;
        break;
      }
      case (EstadoRegistro.REGISTRO_MODULO): {
        this.es_registro = true;
        break;
      }
      case (EstadoRegistro.REGISTRO_COMPLETO_ADMIN): {
        this.es_registro = false;
        //cargando a cache la empresa por primera vez
        if (this.registroService.usuario) {
          this.empresaBLService.fetchEmpresaById(this.registroService.usuario.id_usuario_cuenta);
          this.sbEmpresa = this.empresaBLService.getEmpresaCache().subscribe(empresa => {
            //aqui se nulifica cuando eliminas empresa
            if (empresa) {
             this.cargarEmpresaDatosMenu(empresa);
            }
          });
        }
        break;
      }
      case (EstadoRegistro.REGISTRO_COMPLETO_USUARIO): {
        this.es_registro = false;
        if (this.registroService.usuario) {
          //aqui se llena la empresa cuando carga el usuario_colaborador
          this.registroService.empresa = { ...this.registroService.usuario.empresa };
          this.sidenav_item = Utils.generarMenuSidenav(
            this.registroService.usuario.modulo_rol,
            this.registroService.empresa.lista_modulos_seleccionados
          );
          //mandamos a cachear y emitir las opciones del menu
          this.uiNavigateSide.fetchOptionMenuSidenav(this.sidenav_item);
          this.registroService.loadEmpresa$.next();
        }
        break;
      }
      default: {
        this.es_registro = true;
      }
    }
  }

  sidenav_item_registro: ItemSidenav[] = [
    {
      active: false,
      icono: Icons.BADGE,
      nombre: "1. Datos Personales",
      link: '',
      visible: true,
      visible_text: false,
      orden: 1,
      codigo: '001'
    }, {
      active: false,
      icono: Icons.CASES,
      nombre: "2. Empresa",
      link: '',
      visible: true,
      visible_text: false,
      orden: 2,
      codigo: '002'
    },
    {
      active: false,
      icono: Icons.EXTENSION,
      nombre: "3. Mis Módulos",
      link: '',
      visible: true,
      visible_text: false,
      orden: 3,
      codigo: '003'
    },];

}
