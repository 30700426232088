import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ITableEvento } from '../table/ITableEvento';

@Injectable({
  providedIn: 'root'
})
export class BuscarService {
  goBuscar$ = new Subject<ITableEvento>();
  goLimpiar$ = new Subject<ITableEvento>();
  goLimpiarInput$ = new Subject<ITableEvento>();
  constructor() { }
}
