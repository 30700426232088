import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Utils } from '../../helpers/utils';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/compat/storage';
import { IImageUploaded } from './IImageUploaded';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';

@Injectable({
  providedIn: 'root'
})

@AutoUnsubscribe

export class UploadService {
  files_upload$ = new Subject<any>();
  remove_all_files$ = new Subject();

  constructor(
    private storage : AngularFireStorage,
  ) { }

  onFilesSelected(event:any){
    //comunica al padre los archivos seleccionados
    this.files_upload$.next(event);
  }


  //comunica que se tiene que remover todos los archivos
  removeAllFiles(){
    this.remove_all_files$.next();
  }

  onSaveFiles(files:File[], pathAPI:string) {
    const promises:any[] = [];//datos para el upload
    const metadata_archivo:any[]=[];//guardar el nuevo nombre
    files.forEach(file=>{
      const ext = Utils.ExtraerExtension(file);
      const name_original = file.name.replace('.'+ext,'')+"_"+ Utils.newGuid()+'.'+ext;
      const name = pathAPI+ name_original;
      const fileRef = this.storage.ref(name);
      const metadata = { contentType: ext};
      const task = fileRef.put(file, metadata);
      promises.push(task);
      metadata_archivo.push({
        nombre_original: file.name,
        nombre_plataforma: name_original,
        path_api: pathAPI,
        tamaño: file.size,
        extension: ext
      });
    });
    return Promise.all(promises)
    .then(tasks_upload=>{
      const promises_res: any[]=[];
      tasks_upload.forEach(task_up=>{
        promises_res.push(task_up.ref.getDownloadURL());
      });
      return Promise.all(promises_res);
    }).then(urls=>{
      var objImagenes: {[k:string]:unknown}={};
      var contador = 1;
      //agregando metadatos al obj de retorno
      urls.forEach(url => {
          var objImagen: {[k:string]:unknown}={};
          objImagen.path_image = url;
          //metadatos
          objImagen.nombre_original = metadata_archivo[contador-1].nombre_original;
          objImagen.nombre_plataforma =  metadata_archivo[contador-1].nombre_plataforma;
          objImagen.path_api = metadata_archivo[contador-1].path_api;
          objImagen.tamanioKBs =  metadata_archivo[contador-1].tamaño;
          objImagen.extension =  metadata_archivo[contador-1].extension;
          objImagenes[contador.toString()] = objImagen;
          contador += 1
         });
      return objImagenes;
    });
  }

  async depuraFiles(pathFileAPI: string, files_uploaded_modified: IImageUploaded[], actual_files: any) {
    let archivosXEliminar: IImageUploaded[] = [];

    //el usuario no tiene archivos previamente guardados, no se elimina nada
    if (actual_files == undefined) {
      return null;
    }
    //el usuario tiene archivos previamente guardados, se procede a eliminar
    else {
      //cuando no viene ningun elemento quiere decir que se debe quitar todo
      if (files_uploaded_modified.length == 0) {
        archivosXEliminar = Utils.ObjecToListToArray(actual_files);
      }
      //cuando viene la lista modificada, indica que archivos deben conservarse
      else {
        archivosXEliminar = Utils.DiferenciasObjetoDeObjetos(files_uploaded_modified, actual_files);
      }
    }
    //procedemos a eliminar los archivos cuando no se retorna ningun sobrante de imagene
    //(archivo que se va a conservar)
    if (archivosXEliminar.length> 0) {
      let listaReferencias: AngularFireStorageReference[] = [];
      archivosXEliminar.forEach(archivo => {
        listaReferencias.push(this.storage.ref(pathFileAPI + archivo.nombre_plataforma));
      });

      const promesas = listaReferencias.map(ref => ref.delete());
      return Promise.all(promesas);
    }
    else {
      return null;
    }
  }
}
