export class CPagination{
  opcion_tamanio_pagina:number[]=[5,10,20];
  tamanio_pagina_seleccionado:number=5;
  pagina_actual:number=0;
  numeracion_inicial_documento:number=0;
  numeracion_final_documento:number=0;
  total_registros_coleccion: number | null =0;
  tamanio_pagina:number=10;
  masElementos:boolean=false;
  nextPage:boolean = false;
  previousPage:boolean = false;
  disabled:boolean =false;
  key:string='';
  es_busqueda:boolean = false;
}
