import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { StaticRoutes } from 'src/app/shared/cons/route.class';
import { ItemSidenav } from 'src/app/shared/models/item.model';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  inicialRoute:ItemSidenav={
    active:true,
    icono:"tour",
    nombre:"Tour",
    link: StaticRoutes.TOUR,
    visible:true,
    orden:1,
    codigo:'001'
  };

  tab_item:ItemSidenav[]=[{
    active:true,
    icono:"tour",
    nombre:"Tour",
    link: StaticRoutes.TOUR,
    visible:true,
    orden:1,
    codigo:'001'
  },{
    active:false,
    icono:"payments",
    nombre:"Precios",
    link:StaticRoutes.PRECIO,
    visible:true,
    orden:2,
    codigo:'002'
  },
  {
    active:false,
    icono:"contact_support",
    nombre:"Contacto",
    link:StaticRoutes.CONTACTO,
    visible:true,
    orden:3,
    codigo:'003'
  },
  {
    active:false,
    icono:"contact_support",
    nombre:"Encuesta",
    link:StaticRoutes.ENCUESTA,
    visible:true,
    orden:4,
    codigo:'004'
  },
];

  sidenav_item:ItemSidenav[]=[{
    active:true,
    icono:"tour",
    nombre:"Tour",
    link: StaticRoutes.TOUR,
    visible:true,
    orden:1,
    codigo:'001'
  },{
    active:false,
    icono:"payments",
    nombre:"Precios",
    link:StaticRoutes.PRECIO,
    visible:true,
    orden:2,
    codigo:'002'
  },
  {
    active:false,
    icono:"contact_support",
    nombre:"Contacto",
    link:StaticRoutes.CONTACTO,
    visible:true,
    orden:3,
    codigo:'003'

  },
  {
    active:false,
    icono:"contact_support",
    nombre:"Encuesta",
    link:StaticRoutes.ENCUESTA,
    visible:true,
    orden:4,
    codigo:'004'
  },
];

constructor(
    //private authService:AuthService,
  ) { }

  ngOnInit(): void {

  }

}
