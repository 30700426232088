<div fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title class="dialog-title">Buscar Productos</div>
  <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()" [attr_disabled]="isLoading" ></app-button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content style="min-width: 250px; margin-top: 20px;">
 <section>
  <form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <div fxLayout="column" fxLayoutGap="15px">
      <mat-form-field style="width: 100%;">
        <mat-label>Nombre de Producto</mat-label>
        <input  type="text"
        matInput
        [formControl]="txtComplete"
        [matAutocomplete]="auto"
        [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
        [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
        [pattern]="PatternsRegex.onlyGoodCharsWithNumbersAndSpace"
        oninput="this.value = this.value.toUpperCase()"
        ngModel
        [(ngModel)]="producto"
        >
        <mat-icon matSuffix>{{Icons.SEARCH}}</mat-icon>
        <mat-hint>Ingrese una palabra del nombre de producto</mat-hint>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option.nombre_producto" (click)="onSelect(option)" >
            {{ option.nombre_producto}} | {{ option.codigo_producto}}
          </mat-option>
        </mat-autocomplete>
        <mat-error
        *ngIf="txtComplete.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
      <mat-error
        *ngIf="txtComplete.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
      <mat-error
        *ngIf="txtComplete.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
      </mat-form-field>
      <mat-list>
          <mat-list-item *ngIf="productosSeleccionados.length>0">
              <div>
                <mat-form-field  style="margin-top: 20px;" >
                  <mat-label>Productos Seleccionados</mat-label>
                  <mat-chip-list *ngFor="let producto of productosSeleccionados; let i=index"  >
                    <mat-chip [removable]="removable" (removed)="removerProducto(producto)"
                    selectable="true"
                    (click)="cargarCantidad(i)"
                    >{{producto.cantidad}}&nbsp;{{producto.producto.nombre_producto}}
                    <button matChipRemove *ngIf="removable">
                      <mat-icon>{{Icons.CANCEL}}</mat-icon>
                    </button>
                  </mat-chip>
                </mat-chip-list>
                </mat-form-field>
              </div>          
          </mat-list-item>
      </mat-list>
   
    </div>
    <mat-divider style="margin-bottom: 20px; margin-top: 20px;"></mat-divider>
    <mat-card-actions fxLayout="row" fxLayoutAlign="space-around center" *ngIf="productosSeleccionados.length>0" >
      <app-button [width]="100" text="Agregar" fxFlex="70%" fxFlex.xs="60%"
        [attr_type]="'submit'"></app-button>
      <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" color="gray"
        (click)="Limpiar(f)"></app-button>
      <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING"
        (click)="Limpiar(f)"></app-button>
    </mat-card-actions>
  </form>
 </section>
</mat-dialog-content>
