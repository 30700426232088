<div fxLayout="row" fxLayoutAlign="space-between start">
    <div mat-dialog-title class="dialog-title"> Cantidad</div>
    <app-button type="icon" color="gray" [icon_fig]="CLOSE" (click)="onClose()"></app-button>
</div>
<mat-divider></mat-divider>
<form #f="ngForm">
    <mat-dialog-content fxLayout="column" fxLayoutGap="10px">
        <div style="padding-top: 10px; margin-bottom: 20px; max-width: 200px;">
            <span>Producto: {{producto}}</span>            
        </div>
        <mat-button-toggle-group #group="matButtonToggleGroup">
            <mat-button-toggle class="button-w-toogle" aria-label="Restar" (click)="operar(false)" >
              <mat-icon>{{Icons.REMOVE}}</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle class="button-w-toogle" disabled aria-label="Cantidad">
              {{cantidad}}
            </mat-button-toggle>
            <mat-button-toggle class="button-w-toogle" aria-label="Sumar" (click)="operar(true)" *ngIf="!esEditarProducto" >
              <mat-icon>{{Icons.ADD}}</mat-icon>
            </mat-button-toggle>     
          </mat-button-toggle-group>
    </mat-dialog-content>
</form>