<div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="slider-toggle">
      <mat-slide-toggle color="primary" name="estado" ngModel #estado="ngModel"
        [disabled]="orden==1?true: isDisabledToogle" [(ngModel)]="isCheckedToogle" (change)="toogleChange($event)">
      </mat-slide-toggle>
    </div>
    <div class="label" fxLayout="column" >
      <div fxLayout="row" fxLayout.xs="column">
        <span><h4>{{orden}} {{nombre}}.</h4></span>
        &nbsp;&nbsp;
        <span *ngIf="orden!=1" class="label-cantidad">
          <h6>(Mínimo {{cantidad_usuarios_x_defecto}} usuarios)</h6>
        </span>
      </div>
      <h5 class="label-descripcion"><span> {{descripcion}}</span> <span *ngIf="orden==1"> Gratuito</span></h5>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <div fxLayout="row" *ngIf="orden!=1" style="margin-top: -5px;" matTooltip="Cantidad de usuarios por módulo"
      matTooltipDisabled="false" matTooltipPosition="left" matTooltipShowDelay="500" matTooltipHideDelay="1000">
      <div style="margin-right: 5px; margin-top: -5px;" ><span>{{cantidadIn}}</span></div>
      <div style="margin-top: -8px;"><mat-icon>{{Icons.PEOPLE}}</mat-icon></div>
    </div>
    <div fxLayout="row" *ngIf="orden==1" matTooltip="Cantidad total de usuarios"
      matTooltipDisabled="false" matTooltipPosition="left" matTooltipShowDelay="500" matTooltipHideDelay="1000">
      <div style="margin-top: -5px; margin-right: 5px;" ><span>{{totalColaboradores}}</span></div>
      <div style="margin-top: -8px;"><mat-icon >{{Icons.PEOPLE}}</mat-icon></div>
    </div>

    <div fxLayout="column">
      <button [disabled]="disabledModulo" matTooltip="Añadir usuario" matTooltipPosition="above" matTooltipHideDelay="1000" style="margin-top: -10px;"
        mat-icon-button type="button" *ngIf="orden!=1" (click)="enviarValor(orden, nombre, 'add')">
        <mat-icon>{{Icons.EXPAND_LESS}}</mat-icon>
      </button>
      <button [disabled]="disabledModulo" matTooltip="Quitar usuario" matTooltipPosition="below" matTooltipHideDelay="1000" style="margin-top: -12px;"
        mat-icon-button type="button" *ngIf="orden!=1" (click)="enviarValor(orden, nombre, 'minus')">
        <mat-icon>{{Icons.EXPAND_MORE}}</mat-icon>
      </button>
    </div>
    <div *ngIf="orden!=1 && !disabledModulo" [@blink]="cantidadReduccionUsuarios>0?blinkState:''" fxLayout="row" [matTooltip]="msjReduccionUsuarios" class="decremento">
      <div style="margin-top: 4px;"><span>{{cantidadReduccionUsuarios | negativizar: cantidadReduccionUsuarios}}</span>
      </div>
      &nbsp;
      &nbsp;
      <button style="margin-top: -10px;" mat-icon-button [matMenuTriggerFor]="menu" type="button">
        <mat-icon>{{Icons.PERSON_REMOVE}}</mat-icon>
        <mat-icon>{{Icons.PENDING_ACTIONS}}</mat-icon>
      </button>
    </div>   
    <div [@blink]="blinkState" *ngIf="disabledModulo && esModoEdicion && orden!=1 && es_existente!=undefined" matTooltip="PENDIENTE: Desactivar módulo." >
      <button style="margin-top: -10px; margin-left: 15px;" mat-icon-button [matMenuTriggerFor]="menuTogle" type="button">
        <mat-icon>{{Icons.TOGGLE_OFF}}</mat-icon>
        <mat-icon>{{Icons.PENDING_ACTIONS}}</mat-icon>
      </button>
    </div>
    
    <div *ngIf="orden==1" >
      <mat-form-field appearance="outline" class="input" [matTooltip]="orden!=1?'Sub total por modulo':'Sin costo'"
        matTooltipDisabled="false" matTooltipPosition="right" matTooltipShowDelay="500" matTooltipHideDelay="1000">
        <input [pattern]="PatternsRegex.onlyDecimalIntegerUnsigned" type="number" matInput class="input-cantidad"
          (keypress)="onlyNumbers($event)" disabled="true" placeholder="S/."
          step="0.01">
        <mat-icon matSuffix>{{Icons.PAYMENTS}}</mat-icon>
      </mat-form-field>
    </div>

    <div *ngIf="orden!=1" >
      <mat-form-field appearance="outline" class="input" [matTooltip]="orden!=1?'Sub total por modulo':'Sin costo'"
        matTooltipDisabled="false" matTooltipPosition="right" matTooltipShowDelay="500" matTooltipHideDelay="1000">
        <input [pattern]="PatternsRegex.onlyDecimalIntegerUnsigned" type="number" matInput class="input-cantidad"
          (keypress)="onlyNumbers($event)" disabled="true" placeholder="S/."
          [(ngModel)]="costo_modulo_local" step="0.01">
        <mat-icon matSuffix>{{Icons.PAYMENTS}}</mat-icon>
      </mat-form-field>
    </div>      
  <mat-menu #menu="matMenu" class="mat-elevation-z8">
    <button [disabled]="cantidadReduccionUsuarios==0?true:false" mat-menu-item (click)="removerDecremento()">
      <mat-icon>{{Icons.CANCEL}}</mat-icon>
      <span>Remover</span>
    </button>
  </mat-menu>
  <mat-menu #menuTogle="matMenu" class="mat-elevation-z8">
    <button mat-menu-item (click)="removerAnularModulo()">
      <mat-icon>{{Icons.CANCEL}}</mat-icon>
      <span>Remover</span>
    </button>
  </mat-menu>
</div>
<mat-divider></mat-divider>
