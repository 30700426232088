import { Subscription } from 'rxjs';

export function AutoUnsubscribe(constructor: Function) {
  // Guarda la referencia original a ngOnDestroy (si existe)
  const originalOnDestroy = constructor.prototype.ngOnDestroy;

  // Sobreescritura del método ngOnDestroy
  constructor.prototype.ngOnDestroy = function() {
    for (const prop in this) {
      const property = this[prop];
      if (property instanceof Subscription) {
        property.unsubscribe();
      }
    }

    // Invoca la ngOnDestroy original (si existe)
    if (originalOnDestroy && typeof originalOnDestroy === 'function') {
      originalOnDestroy.apply(this, arguments);
    }
  };
}


// constructor.prototype.subscriptions = [];

// constructor.prototype.ngOnDestroy = function() {
//   for (const prop in this) {
//     const property = this[prop];
//     if (property instanceof Subscription) {
//       property.unsubscribe();
//       const index = this.subscriptions.indexOf(property);
//       if (index !== -1) {
//         this.subscriptions.splice(index, 1);
//       }
//     }
//   }
//   original?.apply(this, arguments);
// };

// const originalNgOnInit = constructor.prototype.ngOnInit;
// constructor.prototype.ngOnInit = function() {
//   if (!this.subscriptions) {
//     this.subscriptions = [];
//   }
//   originalNgOnInit?.apply(this, arguments);
// };

// const originalNgOnChanges = constructor.prototype.ngOnChanges;
// constructor.prototype.ngOnChanges = function() {
//   if (!this.subscriptions) {
//     this.subscriptions = [];
//   }
//   originalNgOnChanges?.apply(this, arguments);
// };
