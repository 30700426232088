import { ChangeDetectorRef, Component, DoCheck, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Icons } from 'src/app/shared/cons/icons';
import { CEncuesta } from '../models/encuesta.model';
import { Msjs, Msjs_Validations, PatternsRegex } from 'src/app/shared/cons/common';
import { MatStepper } from '@angular/material/stepper';
import { Utils } from 'src/app/shared/helpers/utils';
import {
  trigger,
  state,
  style,
  animate,
  transition
  // ... puedes importar más funciones si las necesitas
} from '@angular/animations';
import { UIService } from 'src/app/shared/services/ui.services';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { EncuestaBlService } from '../encuesta.bl.service';
import { Subscription } from 'rxjs';

export interface mozo {
  nombres:string,
  codigo:number
}



@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class EncuestaComponent implements OnInit {

  errorMsg:string='La pregunta es requerida';
  readonly Icons: typeof Icons = Icons;
  encuesta:CEncuesta= new CEncuesta();
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;

  validMozoFlag:boolean = false;
  validServicioFlag:boolean = false;
  validCoctelesFlag:boolean = false;
  validPiqueosFlag:boolean = false;
  validComentarioFlag:boolean = false;
  validDatosFlag:boolean = false;
  onlyNumbers = Utils.InputOnlyNumbers;

  @ViewChild('stepper', {static:false}) private myStepper!: MatStepper;
  previousStepCount:number=1;
  visible1:boolean = true;
  visible2:boolean = false;

  lMozo:mozo[]=[
    {
      nombres: 'No recuerdo',
      codigo:1
    },
    {
      nombres: 'Viviana',
      codigo:2
    },
    {
      nombres: 'Rody',
      codigo:3
    },
    {
      nombres: 'Yuriko',
      codigo:4
    },
    {
      nombres: 'Percy',
      codigo:5
    },
    {
      nombres: 'Arelys',
      codigo:6
    },
    {
      nombres: 'Teresa',
      codigo:7
    },
    {
      nombres: 'Joel',
      codigo:8
    },
    {
      nombres: 'Edith',
      codigo:9
    },
]


  constructor(
    private cdRef: ChangeDetectorRef,
    private uiService:UIService,
    private encuestaBLService: EncuestaBlService
    ) { }

  ngOnInit(): void {

  }

  sendServicio(punto:number){
    this.encuesta.puntaje_servicio = punto;
    this.validCoctelesFlag = true;
  }

  sendCocteles(punto:number){
    this.encuesta.puntaje_coctel = punto;
    this.validPiqueosFlag=true;
  }

  sendPiqueo(punto:number){
    this.encuesta.puntaje_piqueos = punto;
    this.validComentarioFlag=true;
  }

  sendComentario(){
    this.validDatosFlag=true;
  }

  nextMozo(){
    this.validMozoFlag = true;

  }

  ngAfterViewChecked(){
    if(this.myStepper){
      if(this.myStepper.steps.length!==this.previousStepCount){
        this.myStepper.selectedIndex = this.myStepper.steps.length-1;
        this.previousStepCount = this.myStepper.steps.length;
        //console.log(this.myStepper.steps.length);
        // Solicita una nueva detección de cambios
        this.cdRef.detectChanges();
      }
    }
  }
  Guardar(){
    this.visible1= true;
    this.visible2 = false;
    this.encuestaBLService.insertEncuesta(this.encuesta).then(res=>{
      if(res.id){
        this.encuesta = new CEncuesta();
        this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 5000);
      }else{
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 5000);
      }
    }).catch(error=>{
      this.uiService.ShowSnackBar(SnackBarType.ERROR, error, 5000);
      //console.log(error)
    });
  }

  irEncuesta(){
    this.visible1= false;
    this.visible2 = true;
  }

}
