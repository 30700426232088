<mat-card class="mat-card-config" >
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <mat-card-title class="font-resumen" >{{resumen.valor1}}</mat-card-title>
      <mat-card-content fxLayoutAlign="space-between center" style="margin-bottom: -5px;" >
        <mat-icon >{{resumen.icono1}}</mat-icon>
        <span>{{resumen.texto1}}</span>
      </mat-card-content>
    </div>
    <div>
      <mat-card-title class="font-resumen">{{resumen.valor2}}</mat-card-title>
      <mat-card-content fxLayoutAlign="space-between center" style="margin-bottom: -5px;">
        <mat-icon >{{resumen.icono2}}</mat-icon>
        <span>{{resumen.texto2}}</span>
      </mat-card-content>
    </div>
  </div>
</mat-card>
