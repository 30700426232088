import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { colections, documentActiveSession } from "../cons/db.colections";
import { map } from 'rxjs/operators';
import { ISession } from "./interface/ISession";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { TransaccionModel } from "../services/models/trasaccion.model";
import { Utils } from "../helpers/utils";
import { IUsuario } from "../components/dialog/models/usuario.model";

@Injectable({
  providedIn: 'root',
})
export class AuthSessionService {
  transaccion: TransaccionModel = new TransaccionModel();

  private sessionCache: BehaviorSubject<ISession | null | undefined> =
    new BehaviorSubject<ISession | null | undefined>(null);
  public session$: Observable<ISession | null | undefined> =
    this.sessionCache.asObservable();
  private fectchSessionSubscription!: Subscription;

  constructor(private db: AngularFirestore) {}

  fetchSessionIdCuentaUsuario(uniqueSession: ISession): void {
    if (
      this.fectchSessionSubscription &&
      !this.fectchSessionSubscription.closed
    ) {
      this.sessionCache.next(this.sessionCache.value);
      return;
    }
    // if(this.fectchSessionSubscription){
    //   this.fectchSessionSubscription.unsubscribe();
    // }
    this.fectchSessionSubscription = this.getSession(uniqueSession).subscribe(
      (session) => {
        this.sessionCache.next(session);
      }
    );
  }

  stopFetchingSession(): void {
    if (this.fectchSessionSubscription) {
      this.fectchSessionSubscription.unsubscribe();
    }
    this.sessionCache.next(undefined);
  }

  public getSession(uniqueSession: ISession): Observable<ISession | null> {
    return this.db
      .collection(colections.ACTIVE_SESION, (ref) =>
        ref
          .where(
            documentActiveSession.ID_USUARIO_CUENTA,
            '==',
            uniqueSession.id_usuario_cuenta
          )
          .where(
            documentActiveSession.TOKEN_SESSION,
            '==',
            uniqueSession.token_session
          )
      )
      .snapshotChanges()
      .pipe(
        map((docSession: any) => {
          if (docSession.length > 0) {
            const data = docSession[0].payload.doc.data() as ISession;
            data.id = docSession[0].payload.doc.id;
            return data;
          } else {
            const fakeSession: ISession = {
              id_usuario_cuenta: 'fake_user',
              token_session: 'fake_token',
            };
            return fakeSession;
          }
        })
      );
  }

  public getSessionInicio(idUsuario: string) {
    return this.db
      .collection(colections.ACTIVE_SESION, (ref) =>
        ref
          .where(documentActiveSession.ID_USUARIO_CUENTA, '==', idUsuario)
          .limit(1)
      )
      .get()
      .toPromise()
      .then((docSession: any) => {
        if (!docSession.empty) {
          if (docSession.size > 0) {
            const data = docSession.docs[0].data() as ISession;
            data.id = docSession.docs[0].id;
            return data;
          } else {
            return null;
          }
        } else {
          return null;
        }
      });
  }

  public setSesion(session: ISession) {
    //delete session.id;
    const docInsSesion = Utils.SerializeJsonToDb(session);
    return this.db
      .collection(colections.ACTIVE_SESION)
      .add(docInsSesion)
      .then((res) => {
        this.transaccion.tx = true;
        this.transaccion.data = res.id;
        return this.transaccion;
      });
  }

  public deleteSession(idDocumento: string) {
    return this.db
      .collection(colections.ACTIVE_SESION)
      .doc(idDocumento)
      .delete();
  }

  public async deleteSessionByUser(uniqueSession: ISession) {
    try {
      const queryGet = await this.db
        .collection(colections.ACTIVE_SESION, (ref) =>
          ref.where(
              documentActiveSession.ID_USUARIO_CUENTA,
              '==',
              uniqueSession.id_usuario_cuenta
            )
            .where(
              documentActiveSession.TOKEN_SESSION,
              '==',
              uniqueSession.token_session
            )
        ).get().toPromise();
      if (!queryGet.empty) {
        return queryGet.docs[0].ref.delete();
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
