import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sizeLabel'
})
export class SizeLabelPipe implements PipeTransform {

  transform(value: unknown, size:number, ...args: unknown[]): string {
    let cadena:string = String(value)
    cadena= cadena.length>size? cadena.slice(0,size)+'...':cadena
    return cadena
  }

}
