import { Component, Inject, OnInit } from '@angular/core';
import { Icons } from 'src/app/shared/cons/icons';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-aviso',
  templateUrl: './aviso.component.html',
  styleUrls: ['./aviso.component.scss']
})
export class AvisoComponent implements OnInit {
  titleModal:string='';
  message:string='';
  icono:string='';
  tieneCodigo:boolean=false;
  codigo:string="";

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any
  ) {
    this.titleModal = datoPasado.title;
      this.message = datoPasado.message;
      this.icono = datoPasado.icon;
      if(datoPasado.tieneCodigo){
        this.tieneCodigo = datoPasado.tieneCodigo;
        this.codigo= datoPasado.codigo;
      }
   }

  readonly Icons: typeof Icons = Icons;
  ngOnInit(): void {
  }

}
