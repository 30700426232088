import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ListaMediosPago, Msjs, Msjs_Validations, PatternsRegex } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { MediosAceptados } from './models/MediosAceptados';
import { MediosPago } from './models/MediosPago';
import { UIService } from 'src/app/shared/services/ui.services';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { Subscription } from 'rxjs';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { MediosPagoBlService } from './services/medios-pago.bl.service';


@Component({
  selector: 'app-gestion-medios-pago',
  templateUrl: './gestion-medios-pago.component.html',
  styleUrls: ['./gestion-medios-pago.component.scss']
})
export class GestionMediosPagoComponent implements OnInit {
  isLoading:boolean = false;
  esEditar:boolean = false;
  readonly Icons: typeof Icons = Icons;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly ListaMediosPago:typeof ListaMediosPago = ListaMediosPago
  mediosAceptados:MediosAceptados = new MediosAceptados();
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  modalSb = new Subscription();
  existeConfig:boolean = false;
  idMedios:string='';
  reloadMediosSb = new Subscription();
  configSb = new Subscription();

  constructor(
    private uiService: UIService,
    private readonly spinnerPageService: SpinnerPageService,
    private mediosBLService: MediosPagoBlService
  ) {
    this.ListaMediosPago.listaMediosPago.forEach((medio, index)=>{
      this.mediosAceptados.lista_medios_pago[index]=medio;
    })


  }

  ngOnInit(): void {
    this.reloadMediosSb = this.uiService.reloadMediosPago$.subscribe(()=>{
      this.cargarConfiguracion();
    });
  }

  cargarConfiguracion(){
    this.isLoading = true;
    this.mediosBLService.existFecthMedios();
    if(this.configSb){
       this.configSb.unsubscribe();
    }
    this.configSb = this.mediosBLService.getTasas().subscribe(configAmb=>{
        if(configAmb){
          this.isLoading = false;
          this.mediosAceptados = configAmb;
          this.idMedios = configAmb.id;
          this.existeConfig=true;
        }
        else{
          this.isLoading=false;
        }
      });
  }

  cambiarTasa(){
    this.mediosAceptados.lista_medios_pago[1].es_vigente=!this.mediosAceptados.lista_medios_pago[1].es_vigente
    if(!this.mediosAceptados.lista_medios_pago[1].es_vigente){
      this.mediosAceptados.lista_medios_pago[1].tasa_medio=0;
    }
  }



  onSubmit(f:NgForm){
    if(!f.invalid){
      let vigenteMediosPago =  this.mediosAceptados.lista_medios_pago.filter(item=>item.es_vigente);
      if(vigenteMediosPago.length==0){
        this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_MEDIO_PAGO_OBLIGATORIO, 3000);
        return;
      }
      this.modalSb= this.uiService.showModalQuestions({title:Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon:Icons.QUESTION_MARK})
      .subscribe(result=>{
        if(result){
          this.spinnerPageService.show();
          if(!this.existeConfig){
            this.mediosBLService.insertMedios(this.mediosAceptados).then(transac=>{
              if(transac.tx){
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.esEditar = false;
              }
              else{
                this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.error, 3000);
                this.spinnerPageService.hide();
              }
            });
          }
          else{
            this.mediosBLService.updateMedios(this.mediosAceptados, this.idMedios).then(transac=>{
              if(transac.tx){
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.esEditar = false;
              }
              else{
                this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.error, 3000);
                this.spinnerPageService.hide();
              }
            });
          }
        }
      });

    }

  }
  editarMedios(){
    this.esEditar = true;

  }

  Limpiar(f:NgForm){
    if(this.existeConfig){
      this.cargarConfiguracion();
    }
    this.esEditar = false;
  }

}
