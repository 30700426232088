import { Injectable, Inject } from '@angular/core';
import { NativeDateAdapter, MatDateFormats, MAT_DATE_LOCALE } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  constructor(@Inject(MAT_DATE_LOCALE) matDateLocale: string, platform: Platform) {
    super(matDateLocale, platform);
  }

  parse(value: any): Date | null {
    if (typeof value === 'string') {
      const match = value.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
      if (match) {
        let day = parseInt(match[1], 10);
        let month = parseInt(match[2], 10);
        const year = parseInt(match[3], 10);

        // Check if day and month are valid
        if (day < 1 || day > 31 || month < 1 || month > 12) {
          return null;
        }

        // Swap day and month if month is greater than 12 and day is less than or equal to 12
        if (month > 12 && day <= 12) {
          [day, month] = [month, day];
        }

        // Months are 0-based in JavaScript Date object, so subtract 1 from month
        month--;

        return new Date(year, month, day);
      }
    }
    return super.parse(value);
  }

  format(date: Date, displayFormat: string): string {
    if (displayFormat === 'input') {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${this._to2digit(day)}/${this._to2digit(month)}/${year}`;
    }
    return super.format(date, displayFormat);
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

export const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { day: 'numeric', month: 'numeric', year: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};
