<div fxLayout="row" fxLayoutAlign="center center">
  <div *ngIf="isLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <mat-card fxFlex="80%" fxFlex.xs="100%" *ngIf="!isLoading" >
    <mat-card-header>
      <mat-card-title>Ambientes</mat-card-title>
      <mat-card-subtitle>Configuración de grupos de mesas: Las mesas se enumerarán correlativamente incluyendo a todos los ambientes. </mat-card-subtitle>
    </mat-card-header>
    <mat-divider></mat-divider>
    <section  *ngIf="!isLoading">
      <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="start center">
          <div fxLayout="column" style="margin-top: 20px;">
            <div fxLayout="row" fxLayoutGap="10px" >
              <div style="padding-top: 3px;" ><h3>Número de Ambientes:</h3></div>
              <div><h2>{{value}}</h2></div>
            </div>
            <div style="color: gray;">
              <h5 *ngIf="value==0">No se tienen ambientes configurados.</h5>
            </div>            
            <mat-slider
              (change)="generarAmbiente(true)"
              class="color-slider"
              [disabled]="!esEditar"
              max="20"
              min="1"
              step="1"
              thumbLabel="true"
              [tickInterval]="getSliderTickInterval()"
              [(ngModel)]="value"
              aria-labelledby="example-name-label">
            </mat-slider>
          </div>
          <form [formGroup]="miFormulario" (ngSubmit)="submitForm()">
            <div *ngFor="let elemento of contadorArray; let i = index"
            fxLayoutAlign="space-around center"
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutGap="10px"
            xLayoutGap.xs="10px"
            style="margin-top: 10px;"
            >
              <mat-form-field>
                <input type="text" matInput
                placeholder="Nombre"
                required
                formControlName="{{'nombre' + (i+1)}}"
                [pattern]="PatternsRegex.onlyGoodCharsWithNumbersAndSpace"
                [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
                [maxlength]="Length_Database.DB_LENGTH_LARGE_MAX_250"                
                >
                <mat-hint>Nombre para el ambiente: {{i+1}}</mat-hint>
                <mat-error *ngIf="miFormulario.get('nombre' + (i + 1).toString())?.hasError('pattern')">
                  {{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_NUMEROS_CON_ESPACIO_INTERMEDIO}}
                </mat-error>
                <mat-error *ngIf="miFormulario.get('nombre' + (i + 1).toString())?.hasError('required')">
                  {{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <input type="text" matInput
                placeholder="Cantidad"
                required
                formControlName="{{'campo' + (i+1)}}"
                [pattern]="PatternsRegex.onlyDecimalIntegerUnsigned"
                maxlength="3"
                minlength="1"                               
                (keydown)="!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes($event.key) ? $event.preventDefault() : null"
                >
                <mat-hint>Número de mesas, ambiente: {{i+1}}</mat-hint>
                <mat-error *ngIf="miFormulario.get('campo' + (i + 1).toString())?.hasError('pattern')">
                  {{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}
                </mat-error>
                <mat-error *ngIf="miFormulario.get('campo' + (i + 1).toString())?.hasError('required')">
                  {{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}
                </mat-error>
              </mat-form-field>
              <div class="botones" *ngIf="esEditar" >
                <!-- <app-button color="gray" type="icon" [icon_fig]="Icons.ADD"
                  (click)="addMesa(elemento)"></app-button>
                <app-button color="gray" type="icon" [icon_fig]="Icons.HORIZONTAL_RULE"
                  (click)="minusMesa(elemento)"></app-button> -->
                <app-button color="gray" type="icon" [icon_fig]="Icons.DELETE"
                  (click)="removeAmbiente(elemento)"></app-button>
              </div>

            </div>
            <mat-card-actions *ngIf="!esEditar" fxLayout="row" fxLayoutAlign="end center">
              <app-button type="icon" color="gray" [icon_fig]="Icons.EDIT"
              (click)="editarAmbientes()" matTooltip="Editar"
              matTooltipPosition="above"></app-button>
            </mat-card-actions>

            <mat-card-actions *ngIf="esEditar && value>0" fxLayout="row" fxLayoutAlign="space-around center" style="margin-top: 20px;">
              <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%"
                [attr_type]="'submit'" [attr_disabled]="miFormulario.invalid"></app-button>
              <app-button fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray"
                (click)="Limpiar()"></app-button>
              <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CANCEL"
                (click)="Limpiar()"></app-button>
            </mat-card-actions>
          </form>
        </div>
      </mat-card-content>
    </section>

    <section  *ngIf="isLoading">
      <app-spinner></app-spinner>
    </section>
  </mat-card>
</div>
