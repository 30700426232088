import { Component, Input, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { Icons } from 'src/app/shared/cons/icons';
import { IRecibo } from '../gestion-colaborador/models/IRecibo';
import { blinkAnimation } from 'src/app/shared/animations/animations';

@Component({
  selector: 'app-alerta-pago-pendiente',
  templateUrl: './alerta-pago-pendiente.component.html',
  styleUrls: ['./alerta-pago-pendiente.component.scss'],
  animations: [blinkAnimation],
})
export class AlertaPagoPendienteComponent implements OnInit {
  @Input() recibo:IRecibo | null=null;
  readonly Icons: typeof Icons = Icons;
  blinkState: string = 'inactive';
  constructor(
    
  ) { }

  ngOnInit(): void {  
    setInterval(() => {
      this.blinkState = this.blinkState === 'active' ? 'inactive' : 'active';
    }, 1000);
    
  }

}
