<div fxLayout="row" fxLayoutAlign="center center">
  <mat-card fxFlex="80%" fxFlex.xs="100%">
    <mat-card-header>
      <mat-card-title>Roles y Accesos</mat-card-title>
      <mat-card-subtitle>Para cada Rol se muestra los accesos: </mat-card-subtitle>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div class="titulo-rol" *ngFor="let rol of roles; let index=index ">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 20px;">
          <div>
            <h4><span>Rol: "{{ rol.rol | toUpper }}"</span></h4>
          </div>
          <div *ngIf="rol.personalizado">
            <app-button type="icon" color="gray" [icon_fig]="Icons.EDIT"
              (click)="editarRolPersonalizado(rol, index)" matTooltip="Editar Rol"
              matTooltipPosition="above"></app-button>
            <app-button type="icon" color="gray" [icon_fig]="Icons.DELETE" (click)="eliminarRolPersonalizado(rol)"
              matTooltip="Eliminar Rol" matTooltipPosition="above"></app-button>
          </div>
        </div>
        <mat-chip-list>
          <mat-chip *ngFor="let acceso of rol.acceso" (select)="onSelect(rol)">{{acceso}}</mat-chip>
        </mat-chip-list>
        <mat-divider></mat-divider>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="button-nuevo">
        <button mat-mini-fab color="primary" class="upload-btn" type="button" (click)="openModal()"
          matTooltip="Nuevo Rol" matTooltipPosition="above">
          <mat-icon>{{Icons.ADD}}
          </mat-icon>
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>

