<div fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title>
    <mat-icon class="icon" >{{Icons.INFO}}</mat-icon>
    {{titleModal}}
  </div>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <section style="margin-top: 15px;" >
    <p class="texto-ordenado">
      Bienvenido/a a la plataforma web {{datosEmpresa.NOMBRE_PLATAFORMA}},
      propiedad de {{datosEmpresa.NOMBRE_EMPRESA}} Al utilizar esta plataforma,
      usted acepta cumplir con los siguientes términos y condiciones de uso.
    </p>
    <ol>
      <li>
        <h3 style="font-weight: bold;" >Registro de usuarios</h3>
        <p class="texto-ordenado">Para utilizar los servicios ofrecidos en la plataforma,
          los usuarios deberán registrarse proporcionando información precisa y
          actualizada. Al registrarse,
          los usuarios aceptan que la información proporcionada es
          precisa y completa.
        </p>
      </li>
      <li>
        <h3 style="font-weight: bold;">Propiedad intelectual</h3>
        <p class="texto-ordenado">Los contenidos y materiales disponibles en la plataforma,
          incluyendo, entre otros, textos, gráficos, imágenes, diseños,
          software, código fuente, y
          cualquier otro material que se encuentre en la plataforma,
          están protegidos por derechos de propiedad intelectual y son
          propiedad exclusiva de {{datosEmpresa.NOMBRE_EMPRESA}} o de sus respectivos propietarios.
          Los usuarios aceptan no utilizar estos contenidos y materiales
          de ninguna manera que infrinja los derechos de propiedad intelectual de
          {{datosEmpresa.NOMBRE_EMPRESA}} o de terceros.
        </p>
      </li>
      <li>
        <h3 style="font-weight: bold;">Uso de la plataforma</h3>
        <p class="texto-ordenado">Los usuarios pueden utilizar la plataforma únicamente para los
          fines para los que se ha creado, y no para ningún otro propósito.
          Los usuarios aceptan no utilizar la plataforma de manera que pueda dañar,
          deshabilitar, sobrecargar o afectar negativamente la plataforma o
          los servicios ofrecidos en la misma.
        </p>
      </li>
      <li>
        <h3 style="font-weight: bold;">Privacidad</h3>
        <p class="texto-ordenado"> {{datosEmpresa.NOMBRE_EMPRESA}} se compromete a proteger la privacidad de los usuarios de la
          plataforma. Para obtener más información sobre cómo se maneja la privacidad
          de los usuarios, consulte nuestra Política de Privacidad.
        </p>
      </li>
      <li>
        <h3 style="font-weight: bold;">Contenido generado por el usuario</h3>
        <p class="texto-ordenado"> Los usuarios pueden crear y publicar contenido en la plataforma.
          Al crear y publicar contenido, los usuarios aceptan que el contenido
          generado por el usuario es su responsabilidad exclusiva y que no
          infringe los derechos de propiedad intelectual de terceros.
          Los usuarios aceptan indemnizar y eximir de responsabilidad a
          {{datosEmpresa.NOMBRE_EMPRESA}} por cualquier reclamo relacionado con el contenido
          generado por el usuario.
        </p>
      </li>
      <li>
        <h3 style="font-weight: bold;">Modificaciones a los términos y condiciones</h3>
        <p class="texto-ordenado"> {{datosEmpresa.NOMBRE_EMPRESA}} se reserva el derecho de modificar estos términos y
          condiciones en cualquier momento.
          Los usuarios serán notificados de cualquier cambio en los términos y
          condiciones. Al continuar utilizando la plataforma después de la
          notificación de cualquier cambio en los términos y condiciones,
          los usuarios aceptan los términos y condiciones modificados.
        </p>
      </li>
      <li>
        <h3 style="font-weight: bold;">Cesión de datos</h3>
        <p class="texto-ordenado"> Al registrarse en la plataforma {{datosEmpresa.NOMBRE_EMPRESA}}, el usuario acepta expresamente que pueda ceder los datos personales proporcionados en el proceso de registro a
          terceros, incluyendo a aquellos que formen parte del mismo grupo empresarial que
          para los fines específicos de esta plataforma.
          Los datos personales cedidos a terceros se utilizarán exclusivamente para fines
          comerciales relacionados con la plataforma y no serán compartidos con otras
          empresas sin el consentimiento previo del usuario.
          El usuario tiene derecho a oponerse a la cesión de sus datos personales a
          terceros y a solicitar su eliminación en cualquier momento,
          de acuerdo con lo establecido en nuestra Política de Privacidad.
        </p>
      </li>
      <li>
        <h3 style="font-weight: bold;">Terminación del servicio</h3>
        <p class="texto-ordenado"> {{datosEmpresa.NOMBRE_EMPRESA}} se reserva el derecho de terminar los servicios ofrecidos
          en la plataforma en cualquier momento y sin previo aviso.
        </p>
      </li>
      <li>
        <h3 style="font-weight: bold;">Ley aplicable</h3>
        <p class="texto-ordenado"> Estos términos y condiciones se regirán e interpretarán de acuerdo
          con las leyes de nuestro pais Perú, y cualquier disputa que surja en
          relación con estos términos y condiciones estará sujeta a la
          jurisdicción exclusiva de los tribunales de Lima - Perú.
        </p>
      </li>
      <li>
        <h3 style="font-weight: bold;">Política de privacidad</h3>
        <div fxLayout="row" class="button-costos-fact" >
          <app-button text="Politica de Privacidad" type="basic" (click)="mostrarPrivacidad()" ></app-button>
        </div>
      </li>

    </ol>
  </section>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Aceptar</button>
</mat-dialog-actions>
