import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { CriteriosBusqueda, Length_Database, MsjEstado, Msjs, Msjs_Validations, PatternsRegex } from '../../cons/common';
import { CBuscar } from './CBuscar';
import { NgForm } from '@angular/forms';
import { Icons } from '../../cons/icons';
import { UIService } from '../../services/ui.services';
import { SnackBarType } from '../../types/snackbar-type';
import { BuscarService } from './buscar.service';
import { ITableEvento } from '../table/ITableEvento';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { Subscription } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html',
  styleUrls: ['./buscar.component.scss']
})

@AutoUnsubscribe

export class BuscarComponent implements OnInit {
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly CriteriosBusqueda: typeof CriteriosBusqueda = CriteriosBusqueda;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly Icons: typeof Icons = Icons;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  private mediaSub!: Subscription;
  @Input() busqueda:CBuscar = new CBuscar();
  private goLimpiarInputSb!: Subscription;
  @ViewChild('btnLimpiar', { static: true }) btnLimpiar!: ElementRef;
  validador!:RegExp;
  minimoChar:number=0;
  maximoChar:number=0;
  ejemploNombres1:string='Ej. MIGUEL ANGEL, LOMO SALTADO, etc.';
  ejemploNombres:string='';
  ejemploDocumento1:string='Ej. 42456095 etc.';
  ejemploDocumento:string=''
  ejemploCodigo1:string='Ej. AXBB70 etc.';
  ejemploCodigo:string='';
  ejemploUsuarioCreacion1:string ='administrador.negocio'
  ejemploUsuarioCreacion:string ='';
  tamanioPantalla:string='';
  anchoMatFormField:string='350px';

  constructor(
    private uiService:UIService,
    private buscarService: BuscarService,
    private mediaObserver: MediaObserver,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.busqueda.criterio_seleccionado = this.busqueda.lista_criterio[0];//seleccionado por defecto primera opcion
    this.seleccionarValidador();

    this.goLimpiarInputSb = this.buscarService.goLimpiarInput$.subscribe((callEvento: ITableEvento)=>{
      if (this.busqueda.key == callEvento.key) {
        this.simulateClick();
      }
    });

    // Suscribirse a los cambios en el tamaño de pantalla
    this.mediaSub = this.mediaObserver.asObservable().subscribe((changes) => {
      this.tamanioPantalla = changes[0].mqAlias;
    });
  }

  simulateClick() {
    const buttonElement = document.getElementById('btnLimpiar') as HTMLButtonElement;
    if (buttonElement) {
      buttonElement.click();
    }
  }

  seleccionarValidador(){
    switch(this.busqueda.criterio_seleccionado){
      case CriteriosBusqueda.nombres:{
        this.validador = PatternsRegex.onlyWordSpanishWithSpaceBlank;
        this.minimoChar = 3;
        this.maximoChar = 50;
        this.ejemploNombres = this.ejemploNombres1+' (Mínimo '+ this.minimoChar.toString()+ ' caracteres).'
        break;
      }
      case CriteriosBusqueda.numero_documento:{
        this.validador = PatternsRegex.onlyIntegerUnsigned;
        this.minimoChar = 8;
        this.maximoChar = 9;
        this.ejemploDocumento = this.ejemploDocumento1+' (Mínimo '+ this.minimoChar.toString()+ ' dígitos).'
        break;
      }
      case CriteriosBusqueda.codigo:{
        this.validador = PatternsRegex.onlyGoodCharsWithNumbers;
        this.minimoChar = 6;
        this.maximoChar = 6;
        this.ejemploCodigo = this.ejemploCodigo1+' (Mínimo '+ this.minimoChar.toString()+ ' caracteres).'
        break;
      }
      case CriteriosBusqueda.usuario_creacion:{
        this.validador = PatternsRegex.onlyWordSpanishNumberPoint;
        this.minimoChar = 3;
        this.maximoChar = 50;
        this.ejemploCodigo = this.ejemploUsuarioCreacion1 +' (Mínimo '+ this.minimoChar.toString()+ ' caracteres).'
        break;
      }
      default:{
        // this.busqueda.criterio_seleccionado = this.busqueda.lista_criterio[0];
        // this.validador = PatternsRegex.onlyWordSpanishWithSpaceBlank;
        // this.minimoChar = 3;
        // this.maximoChar = 50;
        // this.ejemploNombres = this.ejemploNombres+' (Mínimo '+ this.minimoChar.toString()+ ' caracteres).'
        // break;
        break;
      }
    }
    this.cdr.detectChanges();
  }

  convertirMayusculas(event:any){
    if(this.busqueda.criterio_seleccionado!=CriteriosBusqueda.usuario_creacion){
      event.target.value = event.target.value.toUpperCase();

    }else{
      event.target.value = event.target.value;
    }
    this.busqueda.valor_criterio = event.target.value;
    
  }


  onSearch(f:NgForm){
    if(f.valid){
      if(this.busqueda.criterio_seleccionado== 'estado'){
        if(this.busqueda.estado_seleccionado==''){
          this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_ERROR_SELECCIONAR_ESTADO, 3000);
          return;
        }else{
          const callEvento:ITableEvento = {
            key:this.busqueda.key,
            data: this.busqueda
          }
          this.buscarService.goBuscar$.next(callEvento);
        }

      }else{
        if(this.busqueda.criterio_seleccionado==''){
          this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_ERROR_SELECCIONAR_CRITERIO, 3000);
          return;
        }else{
          if(this.busqueda.valor_criterio==''){
            this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_ERROR_INGRESAR_CRITERIO, 3000);
            return;
          }else{
            const callEvento:ITableEvento = {
              key:this.busqueda.key,
              data: this.busqueda
            }
            this.buscarService.goBuscar$.next(callEvento);
          }

        }

      }
    }

  }


  criterioSelect(value:any, f:NgForm){
    f.resetForm();
    this.busqueda.criterio_seleccionado= value;
    if(value!=='estado'){
      this.busqueda.estado_seleccionado='';
    }
    this.seleccionarValidador();
  }

  Limpiar(f:NgForm){
    this.busqueda.criterio_seleccionado='';
    this.busqueda.estado_seleccionado='';
    this.busqueda.valor_criterio='';
    this.busqueda.criterio_seleccionado= this.busqueda.lista_criterio[0];
    this.seleccionarValidador();
    const callEvento:ITableEvento = {
      key:this.busqueda.key,
      data: this.busqueda
    }
    this.buscarService.goLimpiar$.next(callEvento);
  }

}
