<div fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title class="dialog-title">Imágenes</div>
  <button mat-icon-button (click)="onClose()">
    <mat-icon>{{ Icons.CLOSE }}</mat-icon>
  </button>
</div>
<mat-divider style="margin-bottom: 20px;"></mat-divider>
<mat-card-content>
  <div fxLayout="column" fxLayoutAlign="center center" class="container-carrousel">
    <app-carousel [slides]="slides" ></app-carousel>
  </div>
</mat-card-content>
