import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EstadoRegistro } from '../cons/common';
import { CONFIGURACION } from '../cons/config';
import { AutoUnsubscribe } from '../helpers/decorators/AutoUnsubscribe';
import { ICantidadModuloSel } from '../models/ICantidadModuloSel';
import { CModuloRolSeleccionado } from '../components/procesos/gestion-colaborador/models/modulo-rol-seleccionado';

@Injectable({
  providedIn: 'root'
})
@AutoUnsubscribe

export class RegistroService {
  estado:number | null = null;
  usuario!:any;
  empresa!:any;
  //carga los modulos a los que tiene acceso el usuario colaborador
  rolesModulosUsuarioColaborador: CModuloRolSeleccionado[]  | null = null;
  esSuperAdministrador:boolean | null= null;
  esAdministrador:boolean = false;

  nextStep$ = new Subject<number>();
  nextStepsFromNavBar$ = new Subject();
  reloadUsuario$ = new Subject<boolean>();
  loadEmpresa$ = new Subject();
  loadSidenav$ = new Subject();

  envioCantidad$ = new Subject<any>()
  quitarTotalColaboradores$ = new Subject<any>();
  reiniciarControlToogle$ = new Subject<any>();
  disabledModulosControles$ = new Subject<boolean>();
  falsearTooglesSinCantidad$= new Subject<boolean>();
  //es_invitacion:Boolean =false;

  //steppers para registro de admin
  isEditableSteps:boolean[] =[false, false, false];
  lRoles_primarcas: any[] = CONFIGURACION.ROLES_PRIMARCAS;
  esRegistroAdministrador:string='';

  //Variables de Administracion
  cambiarMenuSuscripcion$ = new Subject<boolean>();

  //Variable cantidades totales por modulo
  cantidad_modulos_seleccionados:ICantidadModuloSel[]=[]

  envioDecremento$ = new Subject<any>();
  envioAnularModulo$ = new Subject<any>();
  removerDecremento$ = new Subject<any>();
  removerAnularModulo$ = new Subject<any>();

  constructor(
  ) {

  }

  desactivarControlesModulos(flag:boolean){
    this.disabledModulosControles$.next(flag);
  }

  falsearTooglesSinCantidad(){
    this.falsearTooglesSinCantidad$.next(true);
  }

  reiniciarControlesToogle(isStart:boolean){
    this.reiniciarControlToogle$.next(isStart);
  }

  //para registro y estado de registro, solo los que no tienen usuario son los administradores
  //colaboradores nacen con su usuario registrado
  comprobarEstadoRegistro(){
    this.esRegistroAdministrador = this.lRoles_primarcas[0].value;//administrador
    if(this.usuario){
      //administrador
      if('administrador' in this.usuario){
        if(this.usuario.tiene_empresa){
          if(this.usuario.tiene_modulos){
            this.estado = EstadoRegistro.REGISTRO_COMPLETO_ADMIN;
            this.isEditableSteps =[true, true, true];
          }
          else{
            this.estado = EstadoRegistro.REGISTRO_MODULO;
            this.isEditableSteps =[true, true, false];
          }
        }
        else{
          this.estado = EstadoRegistro.REGISTRO_EMPRESA;
          this.isEditableSteps =[true, false, true];
        }
      }
      else{
        this.estado = EstadoRegistro.REGISTRO_COMPLETO_USUARIO;
        this.isEditableSteps =[false, false, false];
      }
    }else{
      //cuando el adminitrador no creó aun su cuenta solo se registró
      this.estado = EstadoRegistro.REGISTRO_USUARIO;
      this.isEditableSteps =[false, true, true];
    }
  }

  limpiarVariablesRegistro(){
    this.estado=0;
    this.usuario=null;
    this.empresa=null;
    this.rolesModulosUsuarioColaborador = null;
    this.esSuperAdministrador = null;
  }
}
