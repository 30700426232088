import { AuditoriaSet } from "src/app/shared/models/auditoria.models";
export class AspectosGenerales implements AuditoriaSet {
  sigla_impuesto:string='';
  tasa_impuesto:number=0;
  incluir_servicio:boolean=false;
  tasa_servicio:number=0;
  es_borrado: boolean=false;
  es_vigente: boolean = false;
  fecha_creacion?: any;
  fecha_modificacion?: any;
  usuario_creacion?: any;
  usuario_modificacion?: any;
  //
  id?:any
}
