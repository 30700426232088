import { Injectable } from '@angular/core';
import { DialogCatalogoDaService } from '../services.da/dialog-catalogo.da.service';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { Catalogo } from '../../models/Catalogo';
import { ITableFiltroCatalogo } from '../../models/ITableCatalogoFiltro';

@Injectable({
  providedIn: 'root'
})
export class DialogCatalogoBlService {

  constructor(
    private catalogoDAService: DialogCatalogoDaService,
    private registroService: RegistroService    
  ) { }

  getCatalogosAutocomplete(nombreCatalogo: string){
    return this.catalogoDAService.getCatalogosAutocomplete(
      this.registroService.empresa.id,
      this.registroService.usuario.id,
      nombreCatalogo
    )
  }

  getCatalogoXCodigo(codigoCatalogo:string){
    const idEmpresa = this.registroService.empresa.id;
    return this.catalogoDAService.getProductoXCodigo(codigoCatalogo, idEmpresa);
  }

  insertCatalogo(catalogo:Catalogo){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.catalogoDAService.insertCatalogo(catalogo, idEmpresa, correoUsuario);
  }

  getCatalogoResumenCombinado(filtroCatalogo: ITableFiltroCatalogo){
    return this.catalogoDAService.getCatalogoResumenCombinado(
      this.registroService.empresa.id,
      this.registroService.usuario.id,
      filtroCatalogo
    )
  }

  updateCatalogo(catalogo:Catalogo, estadoProductoEdicion:boolean){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.catalogoDAService.updateCatalogo(catalogo,correoUsuario,idEmpresa,estadoProductoEdicion);
  }

  updateCatalogoEstado(catalogo:Catalogo, esActivar:boolean){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.catalogoDAService.updateCatalogoEstado(idEmpresa,correoUsuario,catalogo,esActivar);
  }

  deleteCatalogo(catalog:Catalogo){
    const idEmpresa = this.registroService.empresa.id;
    return this.catalogoDAService.deleteCatalogo(catalog, idEmpresa);
  }
}
