import { Injectable, } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route, UrlSegment } from '@angular/router';
import { Subject, Subscription, Observable } from 'rxjs';

import { StaticRoutes } from '../cons/route.class';
import { AuthService } from './auth.service';
import { RegistroService } from '../services/registro.service';
import { UsuarioDAService } from '../components/services/services.da/usuario.da.service';
import { CookiesTokensService } from '../services/cookies.tokens.service';
import { map, take, tap } from 'rxjs/operators';
import { AutoUnsubscribe } from '../helpers/decorators/AutoUnsubscribe';
import { UIService } from '../services/ui.services';
import { SnackBarType } from '../types/snackbar-type';
import { Msjs } from '../cons/common';


@Injectable({
  providedIn: 'root'
})

@AutoUnsubscribe

export class AdminGuard implements CanActivate, CanLoad {
  adminSubject = new Subject<boolean>();
  isAdmin: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private registroService: RegistroService,
    private cookieService: CookiesTokensService,
    private uiService:UIService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    if (this.registroService.usuario) {
      if (this.registroService.usuario.administrador) {
        return true;
      }
      else {
        if(this.registroService.usuario.empresa!=undefined){
          return true;
        }
        else{
          this.authService.logout();
          this.router.navigate(['/inicio/' + StaticRoutes.TOUR]);
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ACCESO_NO_AUTORIZADO , 5000);
          return false;
        }
      }
    } else {
      const user = this.cookieService.user;
      if (user) {
        if('administrador' in user){
          return true
        }
        if ('modulo_rol' in  user) {
          return true;//simula ser administrador
        } else {
          return false;
        }
      } else {
        this.authService.logout();
        this.router.navigate(['/inicio/' + StaticRoutes.TOUR]);
        return false;
      }
    }
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<any | boolean> {
    return this.authService.getAuthState().pipe(take(1),
      map(user => {
        if (!user) {
          this.router.navigate(['/inicio/' + StaticRoutes.TOUR]);
          return false;
        }
        return true;
      }));
  }
}
