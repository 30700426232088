<mat-nav-list>
  <mat-list-item *ngFor="let opcion of list"  [routerLink]="opcion.link" [routerLinkActive]="['is-active']" (click)="onClose(opcion)" >
    <mat-icon mat-list-icon fontIcon="opcion.icon" >{{opcion.icono}}</mat-icon>
    <span>{{opcion.nombre}}</span>
  </mat-list-item>
  <mat-list-item>
    <button mat-icon-button class="option-logout" (click)="openModalSignup()" >
      <mat-icon>app_registration</mat-icon>
      <span class="nav-caption">Registrarme</span>
    </button>
  </mat-list-item>
  <!-- <mat-list-item>
    <button mat-icon-button class="option-logout" (click)="Closed()">
      <mat-icon>logout</mat-icon>
      <span class="nav-caption">Salir</span>
    </button>
  </mat-list-item> -->
</mat-nav-list>
