import { Injectable } from '@angular/core';
import { ITableEvento } from '../table/ITableEvento';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  btnPagination$ = new Subject<ITableEvento>();
  btnCheckEstadoBtn$ = new Subject<ITableEvento>();
  //btnUpdateCheckEstadoBtn$ = new Subject<ITableEvento>();
  constructor() { }
}
