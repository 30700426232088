import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Icons } from 'src/app/shared/cons/icons';

@Component({
  selector: 'app-ver-imagenes',
  templateUrl: './ver-imagenes.component.html',
  styleUrls: ['./ver-imagenes.component.scss']
})
export class VerImagenesComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  slides:any[]=[]
  constructor(
    public dialogRef: MatDialogRef<VerImagenesComponent>,
    @Inject(MAT_DIALOG_DATA) public datoPasado: any,
  ) {
    if(this.datoPasado.data.lista_imagen){
      this.datoPasado.data.lista_imagen.forEach((imagen:any)=>{
        this.slides.push({image:imagen.path_image, title:imagen.nombre_original})
      });
    }
  }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }

}
