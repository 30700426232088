import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subject, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { TableService } from './table.service';
import { Icons } from '../../cons/icons';
import { MsjEstado, SistOperation } from '../../cons/common';
import { ITableEvento } from './ITableEvento';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Table } from './table.model';
import { BuscarService } from '../buscar/buscar.service';
import { CPagination } from '../pagination/CPagination';
import { PaginationService } from '../pagination/pagination.service';
import { EstadosProductoType } from '../../types/estado-producto-type';
import { EstadosPedidoType } from '../../types/estado-pedido-type';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

@AutoUnsubscribe
/* DIRECTIVA: TABLA */
/* Esta directiva es reusable, en componente, proporciona eventos a nivel de observables, para que solo reaccione la suscripcion
que le compete, emplea el uso de keys */
export class TableComponent implements OnInit {
   // MatPaginator Output
  pageEvent!: PageEvent;
  paginatorN: CPagination = new CPagination();
  @Input() dataTable:Table = new Table();
  @Input() key:string='';
  @Input() showPagination:boolean = true;
  @Input() rowSelection:boolean = false;
  readonly Icons: typeof Icons = Icons;
  private mediaSub!: Subscription;
  private loadTableSub!: Subscription;
  private isLoadingDataTableSub!: Subscription;
  readonly MsjEstado: typeof MsjEstado = MsjEstado
  private goBuscarSub!: Subscription;
  private goLimpiarInput!: Subscription;
  readonly EstadosProductoType: typeof EstadosProductoType = EstadosProductoType;
  readonly EstadosPedidoType: typeof EstadosPedidoType = EstadosPedidoType;

  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;


  columnas:string[]=[];
  tamanioPantalla:string='';
  dataSource: any[] =[];

  botonNuevo:boolean = false;
  botonEditar:boolean = false;
  botonEditarPedido:boolean = false;
  botonEliminar:boolean = false;
  botonActivar:boolean = false;
  botonActivarPersona:boolean = false;
  botonSuspenderPersona:boolean = false;
  botonSuspender:boolean = false;
  botonVer:boolean = false;
  botonVerImagen:boolean = false;
  botonImprimir:boolean = false;
  botonGestionarPassword:boolean=false;
  botonAgregar:boolean=false;
  botonCerrar:boolean=false;
  botonAbrir:boolean=false;
  botonResponderSolicitud: boolean=false;
  botonPagoTotal:boolean = false;
  botonPagoParcial:boolean = false;
  botonDividirCuenta:boolean = false;
  botonTraspasarProducto:boolean = false;

  isLoading:boolean =false;
  event: any;

  constructor(
    private mediaObserver: MediaObserver,
    private tableServices:TableService,
    private buscarService:BuscarService,
    private paginationService:PaginationService,
  ) {

  }

  ngOnInit(): void {
    //loading bar table
    this.isLoadingDataTableSub = this.tableServices.isLoadingDataTable$.subscribe((callEvento: ITableEvento)=>{
      if(callEvento.key==this.key){
        this.isLoading = callEvento.data;//loading
      }
    });

    //cuando termina de cargar los datos
    this.loadTableSub = this.tableServices.loadDataTable$.subscribe((keyTipoTabla: string) => {
      //para que solamente responda el tipotabla de donde se le llama
      if (keyTipoTabla == this.key) {
        this.dataSource=[];
        this.dataSource = JSON.parse(JSON.stringify(this.dataTable.data));
        this.asignarBotones();
        this.redimensionaPantalla();
        if(this.showPagination){
          this.generarPaginacion();
        }
      }
    });

    // Suscribirse a los cambios en el tamaño de pantalla
    this.mediaSub = this.mediaObserver.asObservable().subscribe((changes) => {
      this.tamanioPantalla = changes[0].mqAlias;
      this.redimensionaPantalla();
    });
  }

  selectedRow(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.rowSelect$.next(callEvento);
  }

  trackByFn(index: number, item: any) {
    return item.id;
    }

  generarPaginacion(){
    //genera los totales de la consulta paginada, el total se obtiene del resumen
    this.paginatorN.es_busqueda = this.dataTable.filtro.some((element)=> element !==null)?true:false;
    this.paginatorN.key = this.key;
    this.paginatorN.tamanio_pagina_seleccionado = this.dataTable.pageSize;
    this.paginatorN.opcion_tamanio_pagina = this.dataTable.pageSizeOptions;
    this.paginatorN.tamanio_pagina = this.dataTable.pageSize;

    if(this.dataTable.data.length>0){
      this.paginatorN.total_registros_coleccion = this.dataTable.data[0].totalRegistros_doc ;
      this.paginatorN.pagina_actual = this.dataTable.currentPage;
      this.paginatorN.masElementos = this.dataTable.data[0].masElementos;
      this.paginatorN.nextPage = this.dataTable.data[0].nextPage;
      this.paginatorN.previousPage = this.dataTable.data[0].previousPage;
      this.paginatorN.numeracion_inicial_documento = this.dataTable.data[0].numeracion;
      this.paginatorN.numeracion_final_documento = this.dataTable.data[this.dataTable.data.length-1].numeracion;


    }else{
      this.paginatorN.pagina_actual = 0;
      this.paginatorN.masElementos = false;
    }
    const callEvento:ITableEvento = {
      key:this.key,
      data:null
    }
    this.paginationService.btnCheckEstadoBtn$.next(callEvento);
  }



  btnPassword(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnGestionarPassword$.next(callEvento);
  }

  btnEditar(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnEditar$.next(callEvento);
  }

  btnEditarPedido(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnEditarPedido$.next(callEvento);
  }

  btnDividirCuenta(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnPagoDividirCuenta$.next(callEvento);
  }

  btnTraspasarProducto(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnPagoTraspasarProducto$.next(callEvento);
  }


  btnAgregar(fila:any, e:any){
    e.stopPropagation();
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnAgregar$.next(callEvento);
  }


  btnEditarActivarPersona(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnEditarActivarPersona$.next(callEvento);
  }

  btnEditarInactivarPersona(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnEditarInactivarPersona$.next(callEvento);
  }

  btnVer(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnVer$.next(callEvento);
  }

  btnVerImagen(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnVerImagen$.next(callEvento);
  }

  btnImprimir(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnImprimir$.next(callEvento);
  }


  btnResponderSolicitud(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnResponderSolicitud$.next(callEvento);
  }

  btnCerrar(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnCerrar$.next(callEvento);
  }

  btnAbrir(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnAbrir$.next(callEvento);
  }

  btnEliminar(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnEliminar$.next(callEvento);
  }

  btnEditarActivar(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnActivar$.next(callEvento);
  }
  btnEditarInactivar(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnSuspender$.next(callEvento);
  }
  btnPagoTotal(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnPagoTotal$.next(callEvento);
  }
  btnPagoParcial(fila:any){
    const callEvento:ITableEvento = {
      key:this.key,
      data:fila
    }
    this.tableServices.btnPagoParcial$.next(callEvento);
  }

  asignarBotones(){
    this.dataTable.operaciones?.forEach((typo:string) => {
      switch(typo){
        case SistOperation.NUEVO:{
          this.botonNuevo=true;
          break;
        }
        case SistOperation.EDITAR:{
          this.botonEditar=true;
          break;
        }
        case SistOperation.DIVIDIR_CUENTA:{
          this.botonDividirCuenta=true;
          break;
        }
        case SistOperation.TRASPASAR_PRODUCTO:{
          this.botonTraspasarProducto=true;
          break;
        }
        case SistOperation.EDITAR_PEDIDO:{
          this.botonEditarPedido=true;
          break;
        }
        case SistOperation.ELIMINAR:{
          this.botonEliminar=true;
          break;
        }
        case SistOperation.ACTIVAR:{
          this.botonActivar=true;
          break;
        }
        case SistOperation.SUSPENDER:{
          this.botonSuspender=true;
          break;
        }
        case SistOperation.ACTIVAR_PERSONA:{
          this.botonActivarPersona=true;
          break;
        }
        case SistOperation.SUSPENDER_PERSONA:{
          this.botonSuspenderPersona=true;
          break;
        }
        case SistOperation.VER:{
          this.botonVer=true;
          break;
        }      
        case SistOperation.VER_IMAGEN:{
          this.botonVerImagen=true;
          break;
        }
        case SistOperation.IMPRIMIR:{
          this.botonImprimir=true;
          break;
        }
        case SistOperation.RESPONDER_SOLICITUD:{
          this.botonResponderSolicitud=true;
          break;
        }
        case SistOperation.GESTIONAR_PASSWORD:{
          this.botonGestionarPassword=true;
          break;
        }
        case SistOperation.AGREGAR:{
          this.botonAgregar=true;
          break;
        }
        case SistOperation.CERRAR:{
          this.botonCerrar=true;
          break;
        }
        case SistOperation.PAGO_TOTAL:{
          this.botonPagoTotal=true;
          break;
        }
        case SistOperation.PAGO_PARCIAL:{
          this.botonPagoParcial=true;
          break;
        }
        case SistOperation.ABRIR:{
          this.botonAbrir=true;
          break;
        }
        default:{
          break;
        }
      }
    });
  }

  redimensionaPantalla(){
    if(this.dataTable){
      if (this.tamanioPantalla === 'xs') {

        this.columnas = this.dataTable.modoXs;

      } else if (this.tamanioPantalla === 'sm') {

        this.columnas = this.dataTable.modoM;

      } else if (this.tamanioPantalla === 'md') {

        this.columnas = this.dataTable.modoM;

      } else if (this.tamanioPantalla === 'lg') {

        this.columnas = this.dataTable.modoLg;

      } else if (this.tamanioPantalla === 'xl') {

        this.columnas = this.dataTable.modoLg;

      }

    }
  }
}
