import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Icons } from 'src/app/shared/cons/icons';
import { PedidoBlService } from '../../registrar-pedido/services/services.bl/pedido.bl.service';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { UIService } from 'src/app/shared/services/ui.services';
import { GestionAspectosBlService } from '../../procesos/gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { Pedido } from '../../registrar-pedido/models/Pedido';
import { Turno } from '../../gestion-monto/models/Turno';
import { IProductoTicket } from '../../registrar-pedido/models/IProductoTicket';
import { TipoPagoType } from 'src/app/shared/types/tipo-pago-type';
import { IIncidencia } from '../../procesos/dialog/models/IInsidencia';
import { Subscription } from 'rxjs';
import { AspectosGenerales } from '../../procesos/gestion-aspectos-generales/models/AspectosGenerales';
import { IItemDsctoIncidencia } from '../../procesos/dialog/models/IItemDsctoIncidencia';
import { Msjs } from 'src/app/shared/cons/common';
import { IIncidenciaProducto } from '../../registrar-pedido/models/IIncidenciaProducto';
import { Utils } from 'src/app/shared/helpers/utils';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { TipoOperacionProductoCompuesto } from 'src/app/shared/types/tipo-operacion-prod-comp-type';
import { EstadosProducto } from '../../registrar-pedido/models/EstadoProducto';
import { Fecha } from '../../gestion-monto/models/Fecha';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import * as moment from "moment";
import { ProcesoType } from 'src/app/shared/types/proceso-type';
import { SolicitarCambioType } from 'src/app/shared/types/solicitar-cambio-type';
import { EstadosProductoType } from 'src/app/shared/types/estado-producto-type';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';

@Component({
  selector: 'app-gestion-incidencia',
  templateUrl: './gestion-incidencia.component.html',
  styleUrls: ['./gestion-incidencia.component.scss']
})
@AutoUnsubscribe
export class GestionIncidenciaComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  pedido = new Pedido();
  turno = new Turno();
  producto!:IProductoTicket;
  tipo_pago!:TipoPagoType;
  incidencia:IIncidencia | null = null;
  configTasasSb!:Subscription;
  aspectosGeneralesSb!:Subscription;
  aspeGen:AspectosGenerales = new AspectosGenerales();
  existeConfigTasaServicio:boolean = false;
  incidenciaSelFake: IIncidenciaProducto |null = null;
  incidenciaObjSel: IItemDsctoIncidencia | null = null;
  comentario:string='';
  modalSb!:Subscription;
  totalConIncidencia:number | null=null;
  listaProductoPedido: IProductoTicket[] = [];
  cantidadSel:number | null = null;
  cantidadReturn:number | null = null;
  cantidadRestanteReturn: number | null=null;
  listaCantidadProducto:number[]=[];
  fechaHoy: Fecha = new Fecha();

  prodCompuestoSeleccionado:boolean=false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef:MatDialogRef<GestionIncidenciaComponent>,
    private pedidoBLService: PedidoBlService,
    private readonly spinnerPageService: SpinnerPageService,    
    private uiService:UIService,
    private gestionAspectosBL: GestionAspectosBlService,
    private registroService: RegistroService,
    private functionService: FunctionsService,
  ) { }

  onClose(){
    this.dialogRef.close({pedido:this.pedido, producto:this.producto});
  }

  ngOnInit(): void {
    this.pedido = this.datoPasado.dato as Pedido;
    this.turno = this.datoPasado.turno as Turno;
    this.producto = JSON.parse(JSON.stringify(this.datoPasado.producto as IProductoTicket));
    this.tipo_pago = this.datoPasado.tipo_pago as TipoPagoType;
    this.listaProductoPedido = this.datoPasado.otros_productos as IProductoTicket[];
    if (this.turno.id && this.pedido.id) {
      this.incidencia = this.datoPasado.incidencia as IIncidencia;     
      if(this.producto.incidencia!=undefined){        
        this.incidenciaSelFake = {         
          cantidad_producto: this.producto.incidencia!.cantidad_producto,
          motivo: this.producto.incidencia!.motivo,
          ...(this.producto.incidencia!.comentario!=undefined &&{ comentario: this.producto.incidencia.comentario}),
          total_anterior: this.producto.incidencia!.total_anterior,
          usuario_creacion: this.producto.incidencia!.usuario_creacion,
        }
      }
    }
    this.cargarTasas();
    this.generarCantidadProducto();    
  }

  generarCantidadProducto(){  
    let inicial=1;
    let cantidad = this.producto.cantidad_producto;
    for(let i=1; i<=cantidad;i++){
      this.listaCantidadProducto.push(i);
    }
    if(cantidad==1){
      this.cantidadSel = 1
    }
  }

  limpiarIncidencia(){
    this.limpiarProductoCompuestoSel();
  }

  cargarTasas(){
    this.gestionAspectosBL.existFetchTasas();
    this.configTasasSb?.unsubscribe();
    this.aspectosGeneralesSb = this.gestionAspectosBL.getTasas().subscribe(aspGen=>{
      if (aspGen) {
        this.aspeGen = aspGen;          
        this.existeConfigTasaServicio = true;                                     
      }
    })
  }

  mostrarListaIncidencia(){
    const prodFake = JSON.parse(JSON.stringify(this.producto)) as IProductoTicket;
    prodFake.lista_caract_seleccionada_individualizada = this.producto.incidencia!.lista_caract_seleccionada_individualizada;
    const prodCompuesto = this.uiService.showDialogProductoCompuesto({
      producto: prodFake,
      tipo_pago: TipoOperacionProductoCompuesto.INCIDENCIA,
      cantidad_sel: this.producto.incidencia!.cantidad_producto,
      esVer:true
    });
  }

  quitarIncidencia(){
    this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon: Icons.QUESTION_MARK })
    .subscribe(async result => {
      if(result){
        this.spinnerPageService.show();                
        this.pedido.tiene_incidencia = false;

            //generando historial producto
            await this.cargarFechas();

            const historialProd:EstadosProducto= new EstadosProducto();
            historialProd.fecha = this.fechaHoy;
            historialProd.usuario_creacion = this.registroService.usuario.correo_electronico;
            historialProd.proceso =  ProcesoType.QUITAR_INCIDENCIA,
            historialProd.usuario_aprobacion = this.registroService.usuario.correo_electronico;
            historialProd.estado_cambio = SolicitarCambioType.APROBADO;
            historialProd.estado_producto = this.producto.estado_producto_vigente;
            historialProd.es_vigente = true;
            historialProd.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
            historialProd.codigo_ticket = this.producto.codigo_ticket;
            historialProd.codigo_producto = this.producto.codigo_producto;
            historialProd.nombre_producto = this.producto.nombre_producto;
            historialProd.cantidad_producto = this.producto.incidencia!.cantidad_producto
            historialProd.reducir_cantidad = false;
            historialProd.es_eliminado = false;

        //generando objeto descuento para producto        
        let pedidoTmp:Pedido = JSON.parse(JSON.stringify(this.pedido));        
        this.producto.precio_total = this.producto.incidencia!.total_anterior;
        //console.log(this.producto);
        let productoTmp:IProductoTicket = JSON.parse(JSON.stringify(this.producto));
        
        //actualizando precio de producto
        this.listaProductoPedido.forEach(prod => {
          if (prod.es_pagado == undefined &&
              prod.id_producto == productoTmp.id_producto &&
              prod.codigo_ticket== productoTmp.codigo_ticket &&
              prod.incidencia != undefined) {

                //recolocamos el precio anterior
                prod.precio_total = productoTmp.incidencia!.total_anterior;

          }
        });
         //recalculando el total
         pedidoTmp.total =0;
         this.listaProductoPedido.forEach(prod=>{
          if(prod.es_pagado==undefined){
            pedidoTmp.total += prod.precio_total;
          }         
        });       
        
        //recalculando el igv      
        const montos = Utils.calcularTotalTicket(this.existeConfigTasaServicio,this.aspeGen.tasa_impuesto, pedidoTmp.total, this.aspeGen.tasa_servicio);                
        pedidoTmp.igv = montos[0];
        pedidoTmp.sub_total = montos[1];
        if(this.aspeGen.incluir_servicio){
          pedidoTmp.servicio_total = montos[2];
          pedidoTmp.servicio_subtotal=montos[3];
          pedidoTmp.servicio_igv=montos[4];
        }        
        pedidoTmp.total = montos[5];

        const otrasIncidencias = this.listaProductoPedido.filter(prod=>prod.id!==productoTmp.id && prod.tiene_incidencia);

        const tieneOtrosIncidencias = otrasIncidencias!= undefined && otrasIncidencias.length>0? true:false;
        this.pedido =  JSON.parse(JSON.stringify(pedidoTmp));
        this.producto =  JSON.parse(JSON.stringify(productoTmp));

        this.pedidoBLService.updateQuitarIncidenciaProducto(pedidoTmp, this.turno.id!, productoTmp, tieneOtrosIncidencias, historialProd).then(tx=>{
          if(tx.tx){
            this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 5000);
            this.spinnerPageService.hide();
            delete this.producto.descuento;
            delete this.producto.tiene_descuento;
            //this.totalConDescuento = null;
            this.incidenciaSelFake=null;
            this.dialogRef.close({pedido:this.pedido});
          }
          else{
            this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
            this.spinnerPageService.hide();
          }
        });
      }
    });
  }

  cargarDialogCompuesto(cantidadSelX:number |null, esVer:boolean = false){
    const prodCompuesto = this.uiService.showDialogProductoCompuesto({
      producto: this.producto,
      tipo_pago: TipoOperacionProductoCompuesto.INCIDENCIA,
      cantidad_sel: cantidadSelX,
      esVer:esVer
    });
    prodCompuesto.afterClosed().subscribe((result) => {
      if(!esVer){
        if(result.cantidad_restante=== 0){
          this.cantidadReturn = result.cantidad_acumulada;
          this.cantidadRestanteReturn = result.cantidad_restante;
          this.prodCompuestoSeleccionado= true;          
          this.producto = JSON.parse(JSON.stringify(result.producto));          
          //marcando todo el grupo de caracteristicas seleccionado
          const gruposProducto = this.producto.lista_caract_seleccionada_individualizada?.filter(caract=>caract.es_seleccionado);
          this.producto.lista_caract_seleccionada_individualizada?.forEach(caract=>{
            gruposProducto!.forEach(grupo=>{
              if(caract.grupo_producto===grupo.grupo_producto){
                caract.es_seleccionado = true;
              }
            });
          });                   
        }else{
          this.uiService.ShowSnackBar(
            SnackBarType.ERROR,
            Msjs.MSJ_PRODUCTO_DIFERENTE,
            3000
          );
          this.limpiarProductoCompuestoSel();
        }   
      }            
    });
  }

  mostrarProductoCompuesto(){
    this.cargarDialogCompuesto(this.cantidadReturn, true);  
  }
  
  limpiarProductoCompuestoSel(){
    this.producto.lista_caract_seleccionada_individualizada?.forEach(caract=>{
      if(caract.es_seleccionado){
        delete caract.es_seleccionado;
      }
    });
    
    this.cantidadSel= null;
    this.incidenciaSelFake = null;
    this.incidenciaObjSel = null;
    this.prodCompuestoSeleccionado = false;
    this.totalConIncidencia = null;
    this.cantidadReturn = null;
  } 

  calcularTotalConIncidencia(){        
    if(this.cantidadSel==null){
      return;
    }else{
      if(this.cantidadSel !== this.cantidadReturn){
        //se selecciona el maximo de productos, no es necesario mostrar dialog
        if(this.cantidadSel === this.producto.cantidad_producto){
          this.producto.lista_caract_seleccionada_individualizada?.forEach(caract=>{
           caract.es_seleccionado = true;
          });
        }
        else{
          //se muestra el dialog
          if(this.producto.lista_caract_seleccionada_individualizada){
            this.cargarDialogCompuesto(this.cantidadSel);
          }              
        }        
      }
    }

    if(this.incidenciaObjSel==null){
      return;
    }else{
      this.incidenciaSelFake={
        motivo: this.incidenciaObjSel!.item.toString(),
        total_anterior:0,
        cantidad_producto:this.cantidadSel,
        usuario_creacion: this.registroService.usuario.correo_electronico,
        comentario:'',
      }
    }

    let count=1;
    let acum=0;
    while(count<= this.cantidadSel){
      acum = Number(this.producto.precio_unitario)+acum;
      count+=1;
    }   
    
    this.totalConIncidencia = this.producto.precio_total - acum;
  }

  async cargarFechas() {
    const fechaServer = moment(await this.functionService.GetDateServer())
      .local().format();
    const fechaServerProcesado = Utils.separarFechasHoras(fechaServer);
    this.fechaHoy.anio = fechaServerProcesado[0]; //anio
    this.fechaHoy.mes = fechaServerProcesado[1]; //mes
    this.fechaHoy.dia = fechaServerProcesado[2]; //dia
    this.fechaHoy.hora = fechaServerProcesado[3]; //hora
    this.fechaHoy.minuto = fechaServerProcesado[4]; //minuto
    this.fechaHoy.segundo = fechaServerProcesado[5]; //seg
  }


  asignarIncidencia(){
    if(this.totalConIncidencia===null){
      this.uiService.ShowSnackBar(
        SnackBarType.ERROR,
        Msjs.MSJ_DSCTO_MOTIVO_ERROR,
        3000
      );
      return;
    }
    this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon: Icons.QUESTION_MARK })
    .subscribe(async result => {
      if(result){
        this.spinnerPageService.show();
        //await this.cargarFechas();        
        this.pedido.tiene_incidencia = true;

        //generando historial producto
        await this.cargarFechas();

        const historialProd:EstadosProducto= new EstadosProducto();
        historialProd.fecha = this.fechaHoy;
        historialProd.usuario_creacion = this.registroService.usuario.correo_electronico;
        historialProd.proceso =  ProcesoType.ASIGNAR_INCIDENCIA,
        historialProd.usuario_aprobacion = this.registroService.usuario.correo_electronico;
        historialProd.estado_cambio = SolicitarCambioType.APROBADO;
        historialProd.estado_producto = this.producto.estado_producto_vigente;
        historialProd.es_vigente = true;
        historialProd.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
        historialProd.codigo_ticket = this.producto.codigo_ticket;
        historialProd.codigo_producto = this.producto.codigo_producto;
        historialProd.nombre_producto = this.producto.nombre_producto;
        historialProd.cantidad_producto = this.cantidadSel!;
        historialProd.reducir_cantidad = true;
        historialProd.es_eliminado = true;
        
        //generando objeto descuento para producto
        const incidenciaProd: IIncidenciaProducto={
          cantidad_producto:this.cantidadSel!,       
          motivo: this.incidenciaSelFake!.motivo,         
          total_anterior: this.producto.precio_total,
          usuario_creacion: this.registroService.usuario.correo_electronico,
          ...(this.comentario!='' && {comentario: this.comentario}),
          ...(this.producto.lista_caract_seleccionada_individualizada!=undefined && 
            {lista_caract_seleccionada_individualizada: JSON.parse(JSON.stringify(this.producto.lista_caract_seleccionada_individualizada!.filter(
            caract=> caract.es_seleccionado==true)))}),
        }

        let productoTmp:IProductoTicket = JSON.parse(JSON.stringify(this.producto));
        productoTmp.incidencia = incidenciaProd;        
        productoTmp.tiene_incidencia = true;
        productoTmp.precio_total = this.totalConIncidencia!;

        let pedidoTmp:Pedido = JSON.parse(JSON.stringify(this.pedido));
        
        //actualizando precio de producto        
        this.listaProductoPedido.forEach(prod => {
          if (prod.es_pagado == undefined &&
              prod.id_producto == productoTmp.id_producto &&
              prod.codigo_ticket== productoTmp.codigo_ticket &&
              prod.incidencia== undefined &&
              prod.precio_total == productoTmp.incidencia!.total_anterior) {

              //recolocamos el precio anterior
              prod.precio_total = productoTmp.precio_total;
          }
        });       
        
        //recalculando el total
        pedidoTmp.total =0;
        this.listaProductoPedido.forEach(prod=>{
           if(prod.es_pagado==undefined){
             pedidoTmp.total += prod.precio_total;
           }         
         }); 
        
         //recalculando el igv
        const montos = Utils.calcularTotalTicket(this.existeConfigTasaServicio,this.aspeGen.tasa_impuesto, pedidoTmp.total, this.aspeGen.tasa_servicio);        
        pedidoTmp.igv = montos[0];
        pedidoTmp.sub_total = montos[1];
        if(this.aspeGen.incluir_servicio){
          pedidoTmp.servicio_total = montos[2];
          pedidoTmp.servicio_subtotal=montos[3];
          pedidoTmp.servicio_igv=montos[4];
        }        
        pedidoTmp.total = montos[5];

        this.producto =  JSON.parse(JSON.stringify(productoTmp));
        this.pedido =  JSON.parse(JSON.stringify(pedidoTmp));
        this.pedidoBLService.updateAsignarIncidenciaProducto(pedidoTmp, this.turno.id!, productoTmp, historialProd).then(tx=>{
          if(tx.tx){
            this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 5000);
            this.spinnerPageService.hide();
            this.dialogRef.close({pedido:this.pedido});       
          }
          else{
            this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
            this.spinnerPageService.hide();
          }
        });
      }
    });
  }



}
