<app-spinner-page></app-spinner-page>
<mat-sidenav-container fullscreen>
  <mat-sidenav #sidenav role="navigation">
    <app-sidenav-list-admin (closeSidenav)="sidenav.close()" [list]="es_registro? sidenav_item_registro: sidenav_item"></app-sidenav-list-admin>
  </mat-sidenav>
  <mat-sidenav-content fxLayout="column">
    <app-nav-bar-admin (sidenavToggleAdmin)="sidenav.toggle()"></app-nav-bar-admin>
    <main class="router-outlet">
      <router-outlet></router-outlet>
    </main>
    <footer fxFlexOffset="auto" class="fixed-footer">
      <app-footer></app-footer>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>




