<div id="comandaPed" class="imprimir">
    <section>
      <div class="logo-com">
        <img *ngIf="logoEmpresa" [src]="logoEmpresa" alt="Logotipo" height="80px">
      </div>
      <div *ngIf="empresa" class="empresa-com">
        <div>
          <h3>{{empresa.nombre_razon}}</h3>
        </div>
        <div>
          RUC&nbsp;{{empresa.ruc}}
        </div>
      </div>
    </section>
    <div>=======================================</div>
    <section>
      <div class="encabezado-com">
        <div class="columna-com">Usuario Pedido: {{pedidoImprimir.usuario_creacion | hideMail }}</div>
        <div class="columna-com" *ngIf="usuarioImprimir">Usuario Imp.: {{usuarioImprimir | hideMail }}</div>
        <div class="columna-com">Fecha:
          {{fechaHoy.dia}}/{{fechaHoy.mes}}/{{fechaHoy.anio}}
        </div>
        <div class="columna-com">Hora:
          {{fechaHoy.hora}}:{{fechaHoy.minuto}}:{{fechaHoy.segundo}}</div>
        <div>=======================================</div>
        <div class="mesas-com">
          <h3>Mesas:&nbsp;{{listaMesasImprimir}}</h3>
        </div>
        <div>=======================================</div>
      </div>
      <div class="items-com">
        <div>
          <span>N° de ítems:&nbsp;{{totalItemsImprimir}} </span>
        </div>
        <div><span>N° de productos:&nbsp;{{totalProductoImprimir}} </span></div>
      </div>
      <ul class="lista-com">

        <li *ngFor="let prod of listaProductoPedido" class="lista-com-ul">
          <div class="lista-com-elemento-todo">
            <div>
              {{prod.cantidad_producto}}
              &nbsp;
              {{prod.nombre_producto}}
              &nbsp;
              ({{prod.precio_unitario | number:'1.2-2'}})
            </div>
            <div class="precio-total">
              {{prod.precio_total | number:'1.2-2'}}
            </div>
          </div>      
        </li>
      </ul>
      <div>=======================================</div>
      <div class="totales-com">
        <div>
          <div>Sub Total:&nbsp;S/&nbsp;{{pedidoImprimir.sub_total| number:'1.2-2' }}</div>
        </div>
        <div *ngIf="pedidoImprimir.servicio_total">
          <div>Servicio:&nbsp;S/&nbsp;{{pedidoImprimir.servicio_total| number:'1.2-2' }}</div>
        </div>
        <div>
          <div>Impuesto (IGV) :&nbsp;S/&nbsp;{{pedidoImprimir.igv| number:'1.2-2' }}</div>
        </div>
      </div>
      <div>=======================================</div>
      <div class="totales-item">
        <div>
          <h2>TOTAL:&nbsp;&nbsp;</h2>
        </div>
        <div class="montos">
          <h2> S/ {{pedidoImprimir.total| number:'1.2-2' }}</h2>
        </div>
      </div>
      <div>=======================================</div>
    </section>
  </div>