import { Injectable } from '@angular/core';
import { UsuarioColaboradorDaService } from '../services.da/usuarioColaborador.da.service';
import { Observable } from 'rxjs';
import { CColaborador } from '../../procesos/gestion-colaborador/models/ccolaborador.model';
@Injectable({
  providedIn: 'root'
})

export class UsuarioColaboradorBlService {
  constructor(
    private usuarioColaboradorDaService:UsuarioColaboradorDaService
  ) { }

  getUsuarioColaboradorXEmpresa() : Observable<CColaborador | null | undefined>{
    return this.usuarioColaboradorDaService.usuarioColaboradorEmpresa$;
  }

  existFetchingUsuarioColaborador(idColaboradorCuenta:string):void{
    this.usuarioColaboradorDaService.fetchUserColabEmpById(idColaboradorCuenta);
  }


}
