import { Component, OnInit } from '@angular/core';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';

@Component({
  selector: 'app-gestion-usuario',
  templateUrl: './gestion-usuario.component.html',
  styleUrls: ['./gestion-usuario.component.scss']
})
export class GestionUsuarioComponent implements OnInit {

  constructor(
    private uiService: UIService
  ) { }
  readonly Icons: typeof Icons = Icons;
  ngOnInit(): void {
  }

  onClose(){
    this.uiService.closeModalGestionarUsuario();
  }

}
