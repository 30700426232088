import { Component, Inject, OnInit } from '@angular/core';
import { Icons } from 'src/app/shared/cons/icons';
import { ITile } from './tile.model'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatosPlataforma } from 'src/app/shared/cons/common';

@Component({
  selector: 'app-costos-facturacion',
  templateUrl: './costos-facturacion.component.html',
  styleUrls: ['./costos-facturacion.component.scss']
})
export class CostosFacturacionComponent implements OnInit {

  readonly Icons: typeof Icons = Icons;
  titleModal:string='Costos y Facturación';
  readonly datosEmpresa: typeof DatosPlataforma = DatosPlataforma;

  constructor(@Inject(MAT_DIALOG_DATA) public datoPasado:any,) { }

  ngOnInit(): void {
  }

}
