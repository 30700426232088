import { NgModule } from "@angular/core";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from "@angular/material/core";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDividerModule } from "@angular/material/divider";
import { MatStepperModule } from "@angular/material/stepper";
import { MatRadioModule} from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatTooltipModule } from "@angular/material/tooltip";
//import { MatGridListModule} from '@angular/material/grid-list';
import { MatBadgeModule } from '@angular/material/badge';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatRippleModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSliderModule} from '@angular/material/slider';

@NgModule({
  exports: [MatButtonModule, MatIconModule, MatFormFieldModule,
    MatCheckboxModule, MatSliderModule,
    MatInputModule, MatDatepickerModule, MatNativeDateModule,
    MatSidenavModule, MatToolbarModule, MatListModule, MatTabsModule,
    MatCardModule, MatSelectModule, MatProgressSpinnerModule,
    MatDialogModule, MatTableModule, MatSortModule,
    MatPaginatorModule, MatSnackBarModule,MatDividerModule,
    MatStepperModule, MatRadioModule, MatChipsModule,
    MatProgressBarModule, MatSlideToggleModule, MatTooltipModule,
    MatBadgeModule, MatMenuModule, MatExpansionModule,
    MatButtonToggleModule, MatRippleModule, MatAutocompleteModule
  ]
})
export class MaterialModule{}
