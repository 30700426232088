import { AuditoriaSet } from "src/app/shared/models/auditoria.models";
import { Fecha } from "../../gestion-monto/models/Fecha";
import { Pedido } from "./Pedido";

export class Ventas implements AuditoriaSet{
    fecha_key:Fecha = new Fecha();
    id_empresa:string='';    
    pedido:Pedido = new Pedido();
    usuario_creacion: any;
    usuario_creacion_busqueda:string='';
    usuario_modificacion?: any;
    fecha_creacion?: any;
    fecha_modificacion?: any;
    es_borrado: boolean=false;
    es_vigente: boolean=false;
}