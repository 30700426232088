import { ReduccionUsuario } from "./reduccion-usuario.model";
export class CostoFacturacion {
  cantidad_total_usuarios:number=0;
  cantidad_usuarios_x_defecto:number=0;

  cantidad_usuarios_variacion?: ReduccionUsuario
  codigo_modulo:string='';
  costo_modulo:number=0;
  nombre_modulo:string='';
  costo_usuario_adicional:number=0;
  fecha_creacion:string='';
  fecha_modificacion?:any
  es_activo:boolean=true;
  es_borrado:boolean = false;
  es_pendiente_calculo_variacion:boolean = false;
  pendiente_anulacion_modulo?:boolean

}
