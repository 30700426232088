import { EstadosComprobanteElectronicoEmpresa } from "src/app/shared/types/estado-comprobante-electronico-empresa-type";

export class ComprobanteElectronico{
    estado_comprobante_empresa: EstadosComprobanteElectronicoEmpresa = EstadosComprobanteElectronicoEmpresa.NO_REQUERIDO;
    codigo_pse:string='';
    nombre_corto_pse:string='';
    codigo_sunat_pse:string='';
    ubigeo_inei_pse:string='';
    departamento_pse:string='';
    provincia_pse:string='';
    distrito_pse:string='';
    direccion_pse:string='';
    serie_factura_pse:string='';
    serie_boleta_pse:string='';
    ruta_pse:string='';
    token_pse:string='';
    fecha_alta_pse:any;
    usuario_activacion:string='';
}