<div *ngIf="!tieneTurnoAperturado">
    <div *ngIf="isLoading" style="margin-top: 20px;">
        <app-spinner></app-spinner>
    </div>
    <div *ngIf="!isLoading" style="margin-left: 10px; margin-top: 10px;">
        <h4>No se aperturó un turno.</h4>
    </div>
</div>
<div fxLayout="column" *ngIf="tieneTurnoAperturado" style="overflow: hidden;">
    <mat-accordion displayMode="default" [multi]="true" [hideToggle]="false">
      <mat-expansion-panel [expanded]="isExpanded" (expandedChange)="panelExpandedChange($event)">
        <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
          <mat-panel-title>
            <mat-icon>{{Icons.SEARCH}}</mat-icon>
            <span class="titulo-accordeon">Búsqueda</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-buscar [busqueda]="buscar"></app-buscar>
        <mat-action-row>
        </mat-action-row>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
          <mat-panel-title>
            <mat-icon>{{Icons.TABLE_BAR}}</mat-icon>
            <span class="titulo-accordeon">Mesas</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-chip-list>
          <mat-chip style="cursor: pointer;" *ngFor="let mesa of listadoMesas" removable="false" selectable="true"
            (click)="seleccionarMesa(mesa)">{{mesa}}</mat-chip>
        </mat-chip-list>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="grupo-botones" >
      <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between center">
        <div fxLayoutGap="5px">
          <button mat-stroked-button matTooltip="Restablecer" class="restablecer" (click)="restablecerEstado()">
            <mat-icon class="mat-18">{{Icons.RESTORE}}</mat-icon>
            <span fxShow.gt-md fxHide.lt-md style="margin-left: 5px;"> Restablecer</span>
          </button>
        </div>
      </div>
    </div>
    <!-- imprimir comanda -->
    <app-imprimir-pedido 
    [pedidoImprimir]="pedidoImprimir"   
    [listaProductoPedido]="listaProductoPedido"
    [listaMesasImprimir]="listaMesasImprimir"
    [aspectosGenerales]="aspectosGenerales"
    [fechaHoy]="fechaHoy"
    [key]="tipoTabla"
   ></app-imprimir-pedido>
    <div class="container-table" style="margin-top: 10px;" >
      <app-table [dataTable]="dataTable" [key]="tipoTabla" [showPagination]="false" [rowSelection]="false"></app-table>
    </div>
  </div>