import { Injectable } from '@angular/core';
import { ModulosDAService } from '../services.da/modulos.da.service';
import { IModuloEmpresa } from '../../dialog/models/modulo.empresa.model';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { MontoModulo } from '../../procesos/gestion-colaborador/models/monto-modulo';
import { ReduccionUsuario } from '../../procesos/gestion-colaborador/models/reduccion-usuario.model';

@Injectable({
  providedIn: 'root'
})
export class ModulosBlService {

  constructor(
    private moduloDAService: ModulosDAService,
    private authService:AuthService,
  ) { }

  getModulo(){
    return this.moduloDAService.getModulos();
  }

  insertModulos( listaModulosSel:any[], idEmpresa:string){
    let modulosEmpresa:IModuloEmpresa;
    return this.authService.getUserIDAsync().then(user=>{
      if(user){
        modulosEmpresa= {
          lista_modulos_seleccionados: listaModulosSel
        }
        return this.moduloDAService.insertModulos(modulosEmpresa, idEmpresa);
      }
      else{
        return null;
      }
    });
  }

  updateModulos( listaModulosSel:any[], idEmpresa:string, lista_modulos_seleccionados:any[], listaDecremento:ReduccionUsuario[] ){
    let modulosEmpresa:IModuloEmpresa;
    return this.authService.getUserIDAsync().then(user=>{
      if(user){
        modulosEmpresa= {
          lista_modulos_seleccionados: listaModulosSel
        }
        return this.moduloDAService.updateModulos(modulosEmpresa, idEmpresa, lista_modulos_seleccionados,listaDecremento);
      }
      else{
        return null;
      }
    });
  }
}
