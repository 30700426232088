import { Injectable } from '@angular/core';
import { DialogProductoDaService } from '../services.da/dialog-producto.da.service';
import { Producto } from '../../models/Producto';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { ITableFiltroProducto } from '../../models/ITableFiltroProducto';
import { IImageUploaded } from 'src/app/shared/components/upload/IImageUploaded';

@Injectable({
  providedIn: 'root'
})
export class DialogProductoBlService {

  constructor(
    private DialogProductoDAService: DialogProductoDaService,
    private registroService: RegistroService
  ) { }

  insertProducto(producto:Producto, files_upload:File[], pathFileAPI:string){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.DialogProductoDAService.insertProducto(producto, idEmpresa,correoUsuario,files_upload,pathFileAPI);
  }

  updateProducto(producto:Producto, files_upload:File[], files_upload_anterior: IImageUploaded[],
     pathFileAPI:string, estadoProductoEdicion:boolean, tiene_prod_anidado_anterior:boolean ){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario =  this.registroService.usuario.correo_electronico;
    return this.DialogProductoDAService.updateProducto(
      producto, files_upload, files_upload_anterior, pathFileAPI,
      estadoProductoEdicion, correoUsuario, idEmpresa, tiene_prod_anidado_anterior
    )
  }

  updateProductoEstado(producto: Producto, esActivar:boolean) {
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario =  this.registroService.usuario.correo_electronico;
    return this.DialogProductoDAService.updateProductoEstado(idEmpresa, correoUsuario, producto, esActivar);

  }

  getProductoXCodigo(codigoProducto:string){
    const idEmpresa = this.registroService.empresa.id;
    return this.DialogProductoDAService.getProductoXCodigo(codigoProducto, idEmpresa);
  }

  getProductoResumenCombinado(filtroProducto: ITableFiltroProducto){
    return this.DialogProductoDAService.getProductoResumenCombinado(
      this.registroService.empresa.id,
      this.registroService.usuario.id,
      filtroProducto
    )
  }

  getProductosAutocomplete(nombreProducto: string){
    return this.DialogProductoDAService.getProductosAutocomplete(
      this.registroService.empresa.id,
      this.registroService.usuario.id,
      nombreProducto
    )
  }

  deleteProducto(producto:Producto, pathFileAPI:string){
    const idEmpresa = this.registroService.empresa.id;
    return this.DialogProductoDAService. deleteProducto(producto, idEmpresa,pathFileAPI)
  }
  utilCleanFlags(){
    return this.DialogProductoDAService.utilCleanFlags();
  }

}
