<mat-sidenav-container fullscreen>
  <mat-sidenav #sidenav role="navigation">
    <app-sidenav-list (closeSidenav)="sidenav.close()" [list]="sidenav_item"></app-sidenav-list>
  </mat-sidenav>
  <mat-sidenav-content fxLayout="column">
    <app-nav-bar (sidenavToggle)="sidenav.toggle()"
    [list_tab]="tab_item"
    [use_btn_login]="true"
    [use_btn_signup]="true"
    [use_btn_login_mobile]="true"
    [inicialRoute]="inicialRoute"
    ></app-nav-bar>
    <main class="router-outlet">
      <router-outlet></router-outlet>
    </main>
    <footer fxFlexOffset="auto">
      <app-footer></app-footer>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>


