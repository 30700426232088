import { trigger, state, style, animate, transition, AUTO_STYLE } from '@angular/animations';

export const blinkAnimation = trigger('blink', [
  state('inactive', style({
    opacity: 0.2
  })),
  state('active', style({
    opacity: 1
  })),
  transition('inactive => active', animate('500ms ease-in')),
  transition('active => inactive', animate('500ms ease-out'))
]);

export const slideAnimation =  trigger('slideAnimation', [
  state('hidden', style({
    height: '0',
    opacity: '0',
    overflow: 'hidden'
  })),
  state('shown', style({
    height: '*',
    opacity: '1'
  })),
  transition('hidden <=> shown', animate('500ms ease-in-out'))
])
