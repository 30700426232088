import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceChar'
})
export class ReplaceCharPipe implements PipeTransform {

  transform(value: string, charToReplace: string, replacementChar: string): string {
    return value.replace(new RegExp(charToReplace, 'g'), replacementChar);}
}
