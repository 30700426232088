import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Pedido } from '../registrar-pedido/models/Pedido';
import { EstadoPedido } from '../registrar-pedido/models/EstadoPedido';
import { Fecha } from '../gestion-monto/models/Fecha';
import { IProductoTicket } from '../registrar-pedido/models/IProductoTicket';
import { Subscription } from 'rxjs';
import { RegistroService } from '../../services/registro.service';
import { FunctionsService } from '../../services/functions.service';
import { Utils } from '../../helpers/utils';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import * as moment from "moment";
import { CONFIGURACION } from '../../cons/config';
import * as printJS from 'print-js';
import { UIService } from '../../services/ui.services';

@Component({
  selector: 'app-imprimir-pedido',
  templateUrl: './imprimir-pedido.component.html',
  styleUrls: ['./imprimir-pedido.component.scss']
})

@AutoUnsubscribe

export class ImprimirPedidoComponent implements OnInit {
  @Input() pedidoImprimir:Pedido = new Pedido();
  @Input() totalProductoImprimir:number=0;
  @Input() totalItemsImprimir:number=0;
  @Input() listaProductoPedido!:IProductoTicket[];
  @Input() listaMesasImprimir:string='';
  @Input() fechaHoy: Fecha = new Fecha();
  @Input() key:string='';

  printComandaSb!: Subscription;


  empresa!:any;
  logoEmpresa!:string;
  usuarioImprimir!:string;
  

  
  constructor( private registroService: RegistroService,
    private functionService: FunctionsService,
    private uiService:UIService
  ) { }

  ngOnInit(): void {
    //solo para impresion
    if(this.registroService.empresa && this.registroService.usuario){
      this.empresa = this.registroService.empresa;
      this.logoEmpresa = Utils.ObjecToListToArray(this.empresa?.logo_empresa)[0].path_image;
      this.usuarioImprimir = this.registroService.usuario.correo_electronico;
    }
    this.printComandaSb = this.uiService.printComanda$.subscribe((keyTipoTabla:string)=>{
      if (keyTipoTabla == this.key) {
        this.printComanda();
      }      
    });


  }
  
  printComanda(){
    var style = CONFIGURACION.COMANDA;
    const id='comandaPed';
    printJS({ printable: id, type: 'html', style:style, css:[CONFIGURACION.COMANDA_FUENTE] });
  }


}
