import { Component, OnInit } from '@angular/core';
import { Icons } from 'src/app/shared/cons/icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Length_Database, Msjs, Msjs_Validations, PatternsRegex } from 'src/app/shared/cons/common';
import { GestionAmbientesBlService } from './services/services.bl/gestion-ambientes.bl.service';

import { Utils } from 'src/app/shared/helpers/utils';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { UIService } from 'src/app/shared/services/ui.services';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { filter, map } from 'rxjs/operators';
import { IAmbienteMesa } from './models/IAmbienteMesa';
import { AmbienteMesa } from './models/AmbienteMesa';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { IMesaAmbiente } from '../../registrar-pedido/models/IMesaAmbiente';
import { EstadosMesaType } from 'src/app/shared/types/estado-mesa-type';
import { GestionMontoBlService } from '../../gestion-monto/services/gestion-monto.bl.service';
import { AccionMesaType } from 'src/app/shared/types/accion-mesa-type';

@Component({
  selector: 'app-gestion-ambientes-mesas',
  templateUrl: './gestion-ambientes-mesas.component.html',
  styleUrls: ['./gestion-ambientes-mesas.component.scss']
})
@AutoUnsubscribe
export class GestionAmbientesMesasComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  readonly Length_Database: typeof Length_Database = Length_Database;
  value = 0;
  showTicks = false;
  autoTicks = false;
  tickInterval = 1;
  contadorArray: number[] = [];
  miFormulario!: FormGroup;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  esEditar:boolean = false;
  modalSb= new Subscription();
  turnoSb= new Subscription();
  tieneTurnoAperturado:boolean = false;
  configSb = new Subscription();
  configuracionAmbiente:IAmbienteMesa[]= new Array();

  existeConfig:boolean = false;  
  isLoading:boolean = false;
  reloadAmbienteMesasSb= new Subscription();
  listaAmbientes: IAmbienteMesa[] = new Array();
  ambientesUnicos:string[] = new Array();



  constructor(private fb: FormBuilder,
    private gestionAmbienteBLService:GestionAmbientesBlService,
    private readonly spinnerPageService: SpinnerPageService,
    private uiService:UIService,
    private registroService: RegistroService,
    private gestionMontoBLService: GestionMontoBlService,
    ) { }

  ngOnInit(): void {
    this.inicializarFormulario(false);

    this.reloadAmbienteMesasSb = this.uiService.reloadAmbienteMesas$.subscribe(()=>{
      this.cargarConfiguracion();
    });

  }

  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }

    return 0;
  }

  cargarConfiguracion() {
    this.isLoading = true;    
    this.gestionAmbienteBLService.existFetchAmbienteMesa();
    if (this.configSb) {
      this.configSb.unsubscribe();
    }

    this.configSb = this.gestionAmbienteBLService.getAmbienteMesa().subscribe(configAmb => {
      if (configAmb) {
        this.gestionMontoBLService.existFetchTurno();
        this.ambientesUnicos=[];
        this.isLoading = false;        
        this.configuracionAmbiente = [...configAmb];
        //extrayendo ambientes
        let ambientesUnicos = new Set<string>();
        this.configuracionAmbiente.forEach(amb_mesa => {
          ambientesUnicos.add(amb_mesa.ambiente);
        });
        const ambientesUnicosArray = Array.from(ambientesUnicos);
        const orderAmbMesa:any[]=[];
        ambientesUnicosArray.forEach(ambiente=>{
          const amb_orden= configAmb.filter(amb_mesa1=>amb_mesa1.ambiente==ambiente)[0];
          orderAmbMesa.push({
            ambiente: ambiente,
            orderAmbMesa: amb_orden.orden
          });
        });
        orderAmbMesa.sort((a,b)=>{
          return a.orderAmbMesa-b.orderAmbMesa}//para orden descendente invertir el orden
          );
        orderAmbMesa.forEach(obj=>{
          this.ambientesUnicos.push(obj.ambiente);
        });        
        
        this.existeConfig = true;
        this.generarAmbiente();
      }
      else {
        this.isLoading = false;
        this.value = 0;
      }

    });

    if (this.turnoSb) {
      this.turnoSb.unsubscribe();
    }
    this.turnoSb = this.gestionMontoBLService.getTurno()
    .pipe(filter(turno => turno !== undefined))
    .subscribe(turno => {
      if (turno && turno.id) {        
        this.isLoading = false;
        this.tieneTurnoAperturado = true;
      } else {
        this.isLoading = false;
        this.tieneTurnoAperturado = false;
      }
    });

  }

  generarAmbiente(cambioSlider:boolean=false){
    this.inicializarFormulario(cambioSlider);
  }

  inicializarFormulario(cambioSlider:boolean): void {
    this.contadorArray = []
    const formGroup: { [key: string]: any } = {};
    let contador: number = 1;
    if (this.existeConfig) {
      if(!cambioSlider){
        this.value = this.ambientesUnicos.length;
      }else{
        if(this.value<this.ambientesUnicos.length){
          this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_REDUCIR_AMBIENTE_NO, 3000);
          this.value = this.ambientesUnicos.length;          
        }
      }          
    }
    while (contador <= this.value) {
      this.contadorArray.push(contador);
      formGroup['campo' + contador] = [{ value: '', disabled: true }, [Validators.required, Validators.pattern(PatternsRegex.onlyDecimalIntegerUnsigned)]];
      formGroup['nombre' + contador] = [{ value: '', disabled: true }, [Validators.required, Validators.pattern(PatternsRegex.onlyGoodCharsWithNumbersAndSpace)]];
      contador++;
    }
    this.miFormulario = this.fb.group(formGroup);
    this.miFormulario.reset();
    //this.miFormulario.enable();   

    if (this.existeConfig) {      
      let counter = 1;

      while (counter <= this.ambientesUnicos.length) {
        const nombre = this.ambientesUnicos[counter-1];
        const campo = this.configuracionAmbiente.filter(amb_mesa => amb_mesa.ambiente == nombre).length;
        this.miFormulario.get('campo' + counter.toString())?.setValue(campo);
        this.miFormulario.get('nombre' + counter.toString())?.setValue(nombre);
        counter++;
      }
      

    }
    if(this.esEditar){
      this.miFormulario.enable();
    }
  }

  editarAmbientes(){
    //si se tiene un turno aperturado no se pueden realizar cambios en las mesas.
    if(this.tieneTurnoAperturado){
      this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_ERROR_SI_TURNO, 3000);
    }else{
      this.esEditar=true;
      this.miFormulario.enable();      
    }          
  }

  submitForm(){
    if (this.miFormulario.valid) {
      this.modalSb= this.uiService.showModalQuestions({title:Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon:Icons.QUESTION_MARK})
      .subscribe(result=>{
        if(result){
          this.spinnerPageService.show();          
          this.listaAmbientes = Utils.getAmbientesCantidad(this.miFormulario.value, this.registroService.usuario.correo_electronico);                    
          if(!this.existeConfig){
            this.gestionAmbienteBLService.insertConfiguracionAmbiente(this.listaAmbientes).then(transac=>{
              if(transac.tx){
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.miFormulario.disable();
                this.esEditar = false;
              }
              else{
                this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.error, 3000);
                this.spinnerPageService.hide();
              }
            });
          }
          else{
            //validar si existe turno abierto
            this.gestionAmbienteBLService.updateConfiguracionAmbiente(this.listaAmbientes).then(transac=>{
              if(transac.tx){
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.miFormulario.disable();
                this.esEditar = false;
              }
              else{
                this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.error, 3000);
                this.spinnerPageService.hide();
              }
            });
          }

        }
      });

    }
    else{
      this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_ERROR_FORMULARIO, 3000);
    }
  }
  Limpiar(){
    if(this.existeConfig){
      this.cargarConfiguracion();
      this.miFormulario.disable();
    }else{      
      this.value = 0;     
      this.generarAmbiente(false);
     
      this.miFormulario.reset();
      this.miFormulario.disable();
    }
    this.esEditar = false;
  }

  addMesa(item:number){
    const ambienteSel = this.ambientesUnicos[item-1];
    const lista_mesas = this.configuracionAmbiente.filter(amb_mesa=>amb_mesa.ambiente== ambienteSel);
    if(lista_mesas.length>0){
      const nueva_mesa:IAmbienteMesa ={
        ambiente: ambienteSel,
        accion_mesa: AccionMesaType.ANIADIR,
        orden: lista_mesas[0].orden,
        mesa_numero:lista_mesas.length+1,
        mesa_estado:EstadosMesaType.DISPONIBLE,
        fecha_creacion:null,
        usuario_creacion: this.registroService.usuario.correo_electronico,
        es_vigente:true,
        auto_numerico:lista_mesas.length+1,
      }
      this.configuracionAmbiente.push(nueva_mesa);
      this.generarAmbiente();
      
     }  
  }

  minusMesa(item:number){
    const ambienteSel = this.ambientesUnicos[item-1];
    const lista_mesas = this.configuracionAmbiente.filter(amb_mesa=>amb_mesa.ambiente== ambienteSel);
    lista_mesas.sort((a,b)=>{
      return a.mesa_numero-b.mesa_numero}//para orden descendente invertir el orden
      );
    lista_mesas.pop();

    this.configuracionAmbiente = [...this.configuracionAmbiente.filter(amb_mesa=>(amb_mesa.ambiente!== ambienteSel))];
    lista_mesas.forEach(element => {
      this.configuracionAmbiente.push({...element});      
    });
    this.generarAmbiente();
    
    
  }

  removeAmbiente(item:number){
    if(!this.existeConfig && this.contadorArray.length>0){
      if(this.contadorArray.length==1){
        this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_ERROR_CANTIDAD_MINIMA_AMBIENTE, 3000);
        return;
      }
      //disminuyendo la cantidad en el slider
      this.value = this.value-1;      
      const campoControlName = `campo${item}`;
      const nombreControlName = `nombre${item}`;      
      this.miFormulario.removeControl(campoControlName);
      this.miFormulario.removeControl(nombreControlName);      
      this.contadorArray = this.contadorArray.filter(numero=>numero!=item);
      return;
    }
    const ambienteSel = this.ambientesUnicos[item-1];
    this.ambientesUnicos = [...this.ambientesUnicos.filter(elemento=>elemento!==ambienteSel)];
    this.configuracionAmbiente = [...this.configuracionAmbiente.filter(amb_mesa=>amb_mesa.ambiente!== ambienteSel)];
    this.generarAmbiente();
   
    
  }


}
