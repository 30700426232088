export interface IHorario {
  dias:string[],
  hora_inicio:string,
  hora_final:string
}

export class CHorario implements IHorario{
  dias:string[]= [];
  hora_inicio: string='';
  hora_final: string='';
}
