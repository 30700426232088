import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpConsolaService } from '../../services/sp-consola.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-nav-bar-consola',
  templateUrl: './nav-bar-consola.component.html',
  styleUrls: ['./nav-bar-consola.component.scss']
})
export class NavBarConsolaComponent implements OnInit {
  es_autenticado:boolean  = false;
  spConsolaSb!:Subscription;


  constructor(
    private spConsolaService:SpConsolaService,
    private authService:AuthService,
  ) { }
  

   ngOnInit(): void {
    this.spConsolaSb = this.spConsolaService.esAutenticadoConsola$.subscribe(result=>{
      this.es_autenticado = result;
    });    
  }

  onLogout(){
    this.authService.logout();
    this.es_autenticado = false;
  }

}
