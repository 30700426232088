<div fxLayout="row" fxLayoutAlign="space-between start">
    <div mat-dialog-title class="dialog-title" fxLayout="row"  fxLayout.lt-sm="column">
        Emisión Comprobante Electrónico
    </div>
    <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"></app-button>
</div>
<mat-divider></mat-divider>
<div>
    <mat-tab-group mat-align-tabs="center">
        <mat-tab label="Boleta de Venta">
            <app-emision-boleta-venta></app-emision-boleta-venta>
        </mat-tab>
        <mat-tab label="Factura">
            <app-emision-factura></app-emision-factura>            
        </mat-tab>
    </mat-tab-group>
</div>