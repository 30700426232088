import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IEmpresa } from '../../dialog/models/empresa.model';
import { AuthService } from '../../../auth/auth.service';
import { EmpresaDAService } from '../services.da/empresa.da.service';
import { IModuloEmpresa } from '../../dialog/models/modulo.empresa.model';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { IImageUploaded } from '../../upload/IImageUploaded';
import { IRolAcceso } from 'src/app/shared/models/item.model';
import { horarioTrabajo } from 'src/app/shared/cons/common';
import { IHorario } from '../../horario-trabajo/horario.model';
import { IUsuario } from '../../dialog/models/usuario.model';
import { CColaborador } from '../../procesos/gestion-colaborador/models/ccolaborador.model';
import { MontoModulo } from '../../procesos/gestion-colaborador/models/monto-modulo';
import { ReduccionUsuario } from '../../procesos/gestion-colaborador/models/reduccion-usuario.model';
import { CEmpresa } from '../../dialog/models/cempresa.model';

@Injectable({
  providedIn: 'root'
})

@AutoUnsubscribe

export class EmpresaBLService {

  constructor(
    private authService:AuthService,
    private empresaDAService: EmpresaDAService,
    private registroService:RegistroService
  ) {}

  validarRUCUnico(ruc:string){
    return this.empresaDAService.validarRUCUnico(ruc);
  }

  insertEmpresa(f:NgForm, files_upload:File[], pathFileAPI:string, empresaO:CEmpresa){
    let empresa: IEmpresa;
    return this.authService.getUserIDAsync().then(user=>{
      if(user){
        empresa ={
          id_usuario_cuenta:user.uid,
          nombre_razon: f.value.razon,
          ruc: f.value.ruc,
          giro_negocio: f.value.giroInput,
          correo_electronico: f.value.email,
          inicio_operacion: new Date(empresaO.inicio_operacion),  
          telefono1: f.value.telefono1,
          telefono2: f.value.telefono2,
          codigo_departamento: f.value.ddlDepartamento,
          codigo_provincia: f.value.ddlProvincia,
          codigo_distrito: f.value.ddlDistrito,
          direccion: f.value.direccion,
          es_borrado:false,
          es_vigente:f.value.estado,
          usuario_creacion:user?.email,
          horario_trabajo_gral: horarioTrabajo.horarioGeneral,
          es_sin_pago:false//nace con falso
        }
        return this.empresaDAService.insertNuevaEmpresa(empresa,files_upload,pathFileAPI);
      }
      else return
    });
  }

  getEmpresa(){
    return this.authService.getUserIDAsync().then(user=>{
      return this.empresaDAService.getEmpresa(user?.uid);
    });
  }

  getEmpresaCache(){
    return this.empresaDAService.empresa$;
  }

  fetchEmpresaById(idUsuario:string):void{
    this.empresaDAService.fetchEmpresaById(idUsuario);
  }


  //file_uploaded: lista de imagenes subidas anteriormente, se muestra en modo edicion
  //file_upload: imagen con la que se va reemplazar
  updateEmpresa(f:NgForm, files_upload_actual: File[], files_upload_anterior: IImageUploaded[], pathFileAPI: string, empresaO:CEmpresa ){
    let empresa:IEmpresa = this.generarEmpresa(f, this.registroService.usuario, empresaO);
    let addImagen: boolean = files_upload_actual.length > 0 ? true : false;

    //cuando se añade una nueva imagen
    if (addImagen) {
      //cuando se tienen imagenes previamente subidas
      if(this.registroService.empresa.logo_empresa){
        return this.empresaDAService.updateEmpresaAniadirReemplazarImagen(empresa, files_upload_actual, files_upload_anterior, pathFileAPI);
      }
      //cuando no se tienen imagenes previamente subidas
      else{
        return this.empresaDAService.updateEmpresaAniadirImagen(empresa, files_upload_actual, pathFileAPI);
      }
    }
    //cuando no se añade una nueva imagen
    else {
      //cuando se tienen imagenes previamente subidas
      if(this.registroService.empresa.logo_empresa){
        //cuando la lista retorna vacia, quiere decir que se quitaron todas las imagenes
        if(files_upload_anterior.length==0){
          return this.empresaDAService.updateEmpresaQuitarImagen(empresa, files_upload_anterior, pathFileAPI);
        }else{
          return this.empresaDAService.updateEmpresaNoModificarImagen(empresa);
        }

      }
      //cuando no se tienen imagenes previamente subidas
      else{
        return this.empresaDAService.updateEmpresaNoModificarImagen(empresa);
      }
    }

  }

  generarEmpresa(f:NgForm, usuarioRegistroServ:any, empresaO:CEmpresa){
    let empresa: IEmpresa;
    empresa={
      id_usuario_cuenta: usuarioRegistroServ.id_usuario,
      nombre_razon : f.value.razon,
      ruc: f.value.ruc,
      giro_negocio: f.value.giroInput,
      correo_electronico: f.value.email,
      inicio_operacion: new Date(empresaO.inicio_operacion),  
      telefono1: f.value.telefono1,
      telefono2: f.value.telefono2,
      codigo_departamento: f.value.ddlDepartamento,
      codigo_provincia: f.value.ddlProvincia,
      codigo_distrito: f.value.ddlDistrito,
      direccion: f.value.direccion,
      es_borrado: false,
      es_vigente: f.value.estado,
      usuario_modificacion: usuarioRegistroServ.email,
      horario_trabajo_gral: horarioTrabajo.horarioGeneral,
      es_sin_pago:false

    }
    return empresa;
  }

  getEmpresaSnapshot(){
    return this.empresaDAService.empresa$;
  }

  updateEmpresaEstadoPago(empresa:any){
    return this.empresaDAService.updateEmpresaEstadoPago(empresa);
  }

  updateEmpresaModulos(modulosSeleccionados:IModuloEmpresa, idEmpresa:string){
    const idUsuario = this.registroService.usuario.id_usuario_cuenta;
    return this.empresaDAService.updateEmpresaModulos(modulosSeleccionados, idEmpresa, idUsuario);
  }

  updateEmpresaModulosEditar(modulosSeleccionados:IModuloEmpresa, idEmpresa:string, lista_modulos_seleccionados:any[], lista_decremento:ReduccionUsuario[]){
    return this.empresaDAService.updateEmpresaModulosEditar(modulosSeleccionados, idEmpresa, lista_modulos_seleccionados, lista_decremento);
  }

  updateEmpresaEstado(){
    return this.empresaDAService.updateEmpresaEstado(this.registroService.empresa.id, this.registroService.empresa.es_vigente, this.registroService.usuario.correo_electronico);
  }

  updateEmpresaEstadoEliminacion(){
    return this.empresaDAService.updateEmpresaEstadoEliminacion(this.registroService.empresa.id, this.registroService.usuario.correo_electronico, this.registroService.empresa.id_doc_usuario);
  }
  updateEmpresaModuloRoles( rolesAniadidos: IRolAcceso[], indexModulo:number,indexRol:number, operacion:string){
    return this.empresaDAService.updateEmpresaModuloRoles(rolesAniadidos, indexModulo,indexRol, this.registroService.empresa.id, this.registroService.empresa.lista_modulos_seleccionados, this.registroService.usuario.correo_electronico, operacion);
  }
  deleteEmpresaModuloRoles( rolesAniadidos: IRolAcceso[], indexModulo:number){
    return this.empresaDAService.deleteEmpresaModuloRoles(rolesAniadidos, indexModulo, this.registroService.empresa.id, this.registroService.empresa.lista_modulos_seleccionados, this.registroService.usuario.correo_electronico);
  }

  updateEmpresaHorarioLaboral(horarioLaboral:IHorario){
    return this.empresaDAService.updateEmpresaHorarioLaboral(horarioLaboral, this.registroService.empresa.id, this.registroService.usuario.correo_electronico);
  }

  getUsuarioAdministradorColaboradorEmpresa(uid:string){
    return this.empresaDAService.getUsuarioAdministradorColaboradorEmpresa(uid);
  }


}
