<!-- cabecera -->
<div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="5px" >
        <div mat-dialog-title class="dialog-title" fxLayout="row">      
            <span *ngIf="!esDividir">
                Traspasar Producto
            </span> 
            <span  *ngIf="esDividir">
                Dividir Cuenta
            </span>        
            &nbsp;&nbsp;                
            <span  *ngIf="!esDividir">
                Pedido: {{pedido.codigo_pedido}}
            </span>
            <span *ngIf="esDividir">
                Pedido Origen : {{pedido.codigo_pedido}}
            </span>       
           </div>
           <div>
            <div *ngIf="!esDividir" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start" fxLayoutGap="5px" >
                <button *ngIf="!isDisabledMesa" mat-stroked-button matTooltip="Elegir Mesa Nueva" 
                    class="estado-pendiente" (click)="nuevoPedido()">
                    <mat-icon class="mat-18">{{Icons.TABLE_BAR}}</mat-icon>
                    <span fxShow.gt-md fxHide.lt-md style="margin-left: 5px;">Mesa Nueva</span>
                </button>
                <button *ngIf="!isDisabledMesa" mat-stroked-button matTooltip="Elegir Mesa Ocupada" 
                    class="estado-ocupado" (click)="mostrarMesasOcupadas()">
                    <mat-icon class="mat-18">{{Icons.TABLE_BAR}}</mat-icon>
                    <span fxShow.gt-md fxHide.lt-md style="margin-left: 5px;">Mesa Ocupada</span>
                </button>
            </div>
            
        </div>
        <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"
            [attr_disabled]="isLoading"></app-button>
    </div>
    <div style="margin-bottom:10px;">
        <mat-divider></mat-divider>
    </div>
    <form #f="ngForm" (ngSubmit)="onSubmit(f)">
        <div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center" style="margin-top: 50px; margin-bottom: 10px;" >
            <app-spinner></app-spinner>
        </div>       
        <!-- cuerpo -->
        <div 
        fxLayout="column" 
        fxLayout.gt-sm="row" 
        fxLayoutGap="10px"
        fxLayoutGap.gt-sm="20px"              
        [ngClass.gt-sm]="{'scrollable-gt-sm':true}"
        [ngClass.sm]="{'scrollable':true}" 
        [ngClass.lt-sm]="{'scrollable-lt-sm':true}"
        (touchstart)="touchStart($event)"
        (touchmove)="touchMove($event)"
        (touchend)="touchEnd($event)"
        >   
              
        
            <!-- columna izquierda -->
            <ng-container *ngIf="!isLoading">
                <div 
                    fxLayout="column"
                    fxFlex.gt-sm="50" 
                    fxFlex.xs="100" >
                    <div fxLayout="column">
                        <mat-card-header>
                            <mat-card-title>Pedido: {{pedido.codigo_pedido}}&nbsp;&nbsp;(ORIGEN)</mat-card-title>
                            <mat-card-subtitle>
                              <div fxLayout="column" style="width: 240px;" >
                                <div>Usuario: {{pedido.usuario_creacion | hideMail }}</div>
                                <div>Fecha: {{pedido.fecha_key.anio}}/{{pedido.fecha_key.mes}}/{{pedido.fecha_key.dia}}</div>
                                <div>Hora: {{pedido.fecha_key.hora}}:{{pedido.fecha_key.minuto}}:{{pedido.fecha_key.segundo}}</div>
                              </div>                   
                            </mat-card-subtitle>
                          </mat-card-header>                                
                    </div>
                   
                    <!-- mesas -->
                    <div fxLayout="row" style="padding-top: 10px;">
                        <div fxLayout="row" fxLayoutGap="10px">
                            <h3>Mesas:</h3>
                            <div>
                                <mat-chip-list fxLayout="row wrap" fxLayoutAlign="start start">
                                    <ng-container *ngFor="let mesa of pedido.ambiente_mesa; let i=index">
                                        <mat-chip>
                                            <span>{{mesa.mesa_numero}}</span>
                                        </mat-chip>
                                    </ng-container>
                                </mat-chip-list>
                            </div>                    
                        </div>           
                    </div>
                    <mat-divider></mat-divider>
                    <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top:10px; color: gray;">        
                        <span>N° de ítems:&nbsp;{{listaProductoPedido.length}} </span>        
                        <span>N° de productos:&nbsp;{{totalProductos}} </span>
                    </div>
                    <!-- productos -->
                    <div fxLayoutGap="10px" fxLayout="column" style="margin-top: 10px;">
                        <ng-container *ngFor="let product of listaProductoPedido; let i= index">
                            <div fxLayout="row" *ngIf="(product.es_pagado== undefined || !product.es_pagado)">
                                <div [ngClass]="esPantallaXs?'nombres-precio-xs':'nombres-precio'">
                                    <mat-chip-list>
                                        <div fxLayout="row" fxLayoutAlign="space-between center">
                                            <div>
                                                <mat-chip 
                                                fxLayout="row" fxLayoutAlign="space-between center" 
                                                [ngStyle]="{'width': tamanioPantalla=='xs'?'250px':'290px'}" 
                                                [ngClass]="{                              
                                                                'chip-pendiente-revision': product.estado_producto_vigente==EstadosProductoType.PENDIENTE_REVISION,
                                                                'chip-solicitado': product.es_modificado,
                                                                'chip-rechazado': product.es_eliminado,
                                                                'chip-pendiente': product.estado_producto_vigente==EstadosProductoType.PENDIENTE,
                                                                'chip-proceso': product.estado_producto_vigente==EstadosProductoType.EN_PROCESO,
                                                                'chip-terminado': product.estado_producto_vigente==EstadosProductoType.TERMINADO
                                                            }" 
                                                            selectable
                                                            (click)="traspasarProducto(i)"                                                    
                                                    [matTooltip]="'Estado: '+product.estado_producto_vigente.toUpperCase()" matTooltipPosition="after">
                                                
                                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                                        <div style="margin-right: 5px; font-weight:bold;">{{product.cantidad_producto}}</div>
                                                        <div *ngIf="product.es_modificado">
                                                            (-{{product.cantidad_producto_reducido}}) &nbsp;
                                                        </div>
                                                        <div style="margin-right: 5px;"> {{product.nombre_producto}}</div>
                                                        <div>(P.U. {{product.precio_unitario | number:'1.2-2' }})</div>
                                                        &nbsp;
                                                        &nbsp;
                                                        <div *ngIf="product.descuento" [@blink]="blinkState" matTooltip="Descuento aplicado">
                                                            -{{product.descuento!.porcentaje_descuento}}%&nbsp;(x{{product.descuento!.cantidad_producto}})
                                                        </div>
                                                        &nbsp;
                                                        <div *ngIf="product.tiene_incidencia==true" [@blink]="blinkState" [matTooltip]="product.incidencia!.motivo">
                                                            <mat-icon class="mat-18">{{Icons.INFO}}</mat-icon>
                                                        </div>
                                                    </div>
                                                    <mat-icon>{{Icons.CHEVRON_RIGHT}}</mat-icon>
                                                </mat-chip>
                                            </div>
                        
                                            <div fxLayout="row">
                                                <!-- boton compuesto -->
                                                <!-- <div class="botones" fxShow fxHide.xs>                                                                                                
                                                    <app-button color="gray" type="icon" [icon_fig]="Icons.RULE"
                                                        *ngIf="!product.es_eliminado && product.lista_caract_seleccionada"
                                                        (click)="mostrarProductoCompuesto(product)">
                                                    </app-button>                                        
                                                </div> -->
                                                <div class="precio-total" fxHide.xs fxShow>                                                
                                                    {{product.precio_total | number:'1.2-2'}}
                                                </div>                                          
                                            
                                            </div>
                        
                                        </div>
                                    </mat-chip-list>
                                </div>
                        
                            </div>
                        
                            <ng-container *ngIf="product.lista_caract_seleccionada && product.lista_caract_seleccionada.length>0">
                                <ul *ngFor="let caract of product.lista_caract_seleccionada" class="caracteristica-item">
                                    <li>
                                        <div fxLayout="row" class="caracteristica">
                                            <mat-icon class="mat-18">{{Icons.ARROW_RIGHT}}</mat-icon>
                                            <span>{{caract.cantidad}}&nbsp;</span>
                                            <span *ngIf="caract.nombre_producto">
                                                {{caract.nombre_producto}}&nbsp;
                                            </span>
                                            <span>{{caract.caracteristica_seleccionada}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </ng-container>
                        </ng-container>        
                        <mat-divider></mat-divider>
                        <!-- subtotal -->
                        <div fxLayout="column" fxLayoutAlign="space-between end" fxLayoutGap="10px" class="divider">
                            <div fxLayout="row">
                                <div class="etiquetas-montos">Sub Total:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                <div class="montos">S/ {{this.pedido.sub_total| number:'1.2-2' }}</div>
                            </div>
                            <div *ngIf="pedido.servicio_total" fxLayout="row">                        
                                <div class="montos-subs">Servicio({{aspectosGenerales.tasa_servicio}}%):&nbsp;&nbsp;&nbsp;&nbsp;S/ {{this.pedido.servicio_total| number:'1.2-2' }}</div>
                            </div>
                            <div fxLayout="row">                        
                                <div class="montos-subs">{{aspectosGenerales.sigla_impuesto}}({{aspectosGenerales.tasa_impuesto}}%):&nbsp;&nbsp;&nbsp;&nbsp;S/ {{this.pedido.igv| number:'1.2-2' }}</div>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div fxLayout="row" class="divider" fxLayoutAlign="end center">
                            <div>
                              <h2>TOTAL:&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                            </div>
                            <div class="montos">
                              <h2> S/ {{this.pedido.total| number:'1.2-2' }}</h2>
                            </div>
                          </div>  
                    </div>
                     <!-- aviso pedido no valido -->
                     <div
                     *ngIf="listaProductoPedido && listaProductoPedido.length==0" 
                     fxLayout="row" fxLayoutAlign="center center" >
                     <div fxLayout="row" fxLayoutAlign="space-around center">
                        <div>
                            <mat-icon 
                            class="error-color"
                            [@blink]="blinkState">{{Icons.ERROR}}</mat-icon>
                         </div>
                         &nbsp;
                         &nbsp;
                         <div style="margin-top: 10px;" >
                            <h3 class="error-color" [@blink]="blinkState">Pedido no válido, mínimo un producto.</h3>
                         </div>
                         
                        
                        
                        
                     </div>
                 </div>
                </div> 
            </ng-container>

            <div *ngIf="tamanioPantalla=='xs' || tamanioPantalla=='sm'">
                <mat-divider></mat-divider>
            </div>
        
            <!-- columna derecha -->
            <ng-container *ngIf="!isLoading">    
                <div fxLayout="column">        
                    <ng-container *ngIf="pedidoTraspaso.ambiente_mesa.length==0">
                        <div fxLayout="column" *ngIf="!esDividir && !esMesaExistente" >
                            <div fxHide.gt-xs fxShow style="margin-bottom: 10px;" >
                                <mat-divider></mat-divider>
                            </div>
                            <h3>No se tienen asignaciones.</h3>
                        </div>
                        <div *ngIf="esDividir "
                            fxLayout="row" 
                            fxLayoutAlign="center start" style="margin-top: 50px; margin-bottom: 10px;">
                        <app-spinner></app-spinner>
                    </div>                 
                    </ng-container>
                   
                    
                    <div  
                        fxFlex.gt-sm="50" 
                        fxFlex.xs="100"
                        [ngClass]="tamanioPantalla=='xs'?'col-right--xs':'col-right--xs-md'"   
                         >
                         <!-- para traspaso en mesas ocupada -->
                         <ng-container *ngIf="esMesaExistente">
                            <mat-card-header>
                                <mat-card-title>Pedido:&nbsp;{{pedidoOcupado.codigo_pedido}}&nbsp;&nbsp;(DESTINO)</mat-card-title>
                                <mat-card-subtitle fxLayout="row" fxLayoutGap="20px">
                                  <div fxLayout="column" style="width: 240px;" >
                                    <div>Usuario: {{pedidoOcupado.usuario_creacion | hideMail }}</div>
                                    <div>Fecha: {{pedidoOcupado.fecha_key.anio}}/{{pedidoOcupado.fecha_key.mes}}/{{pedidoOcupado.fecha_key.dia}}</div>
                                    <div>Hora: {{pedidoOcupado.fecha_key.hora}}:{{pedidoOcupado.fecha_key.minuto}}:{{pedidoOcupado.fecha_key.segundo}}</div>
                                  </div>                           
                                </mat-card-subtitle>
                              </mat-card-header>
                              <div 
                                    fxLayout="row" 
                                    fxLayoutGap="10px" 
                                    style="margin-left: 18px; margin-top: 10px;">
                                <div>
                                    <h3>Mesas: </h3>
                                </div>
                                
                                <mat-chip-list fxLayout="row wrap" fxLayoutAlign="start start">
                                    <ng-container *ngFor="let config of pedidoOcupado.ambiente_mesa; let i = index">
                                        <mat-chip *ngIf="config.es_vigente" [matTooltip]="config.mesa_estado.toUpperCase().replace('_',' ')"
                                            [@blink]="(config.mesa_estado=== EstadosMesaType.RESERVADO ||
                                                            config.mesa_estado=== EstadosMesaType.DISPONIBLE
                                                            )? blinkState:''" [ngClass]="{
                                                              'chip-estado-mesa-disponible': config.mesa_estado===EstadosMesaType.DISPONIBLE,
                                                              'chip-estado-mesa-reservado': config.mesa_estado===EstadosMesaType.RESERVADO,
                                                              'chip-estado-mesa-ocupado': config.mesa_estado===EstadosMesaType.OCUPADO,
                                                              'chip-estado-mesa-proceso': config.mesa_estado===EstadosMesaType.EN_PROCESO
                                                              }" 
                                                              removable
                                                              (removed)="removerMesaOcupado(config)">
                                            {{config.mesa_numero}}
                                            <div *ngIf="pedidoOcupado.es_ambiente_mesa_derivado && i+1== pedidoOcupado.ambiente_mesa.length ">
                                                -{{pedidoOcupado.comentario_ambiente_mesa_derivado}}
                                            </div>
                                            <button matChipRemove>
                                                <mat-icon>{{Icons.CLOSE}}</mat-icon>
                                            </button>
                                        </mat-chip>                         
                                    </ng-container>                                                                        
                                </mat-chip-list>
                            </div>
                            <mat-divider></mat-divider>
                            <div fxLayout="row" fxLayoutGap="10px" >
                                <div style="padding-top: 10px;" >
                                    <span>Productos Existentes:</span>
                                </div>                                
                                <app-button  color="gray" type="icon" [icon_fig]="Icons.VISIBILITY" matTooltip="Ver Productos"                                
                                (click)="verPedidoOcupado()" matTooltipPosition="above"></app-button>                                   
                            </div>
                            <mat-divider></mat-divider>
                            
                            <div fxLayoutGap="10px" fxLayout="column" style="margin-top: 10px;">
                                <ng-container *ngFor="let product of listaProductoPedidoTraspaso; let i= index">
                                    <div fxLayout="row">
                                        <div [ngClass]="esPantallaXs?'nombres-precio-xs':'nombres-precio'">
                                            <mat-chip-list>
                                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                                    <div>
                                                        <mat-chip 
                                                            fxLayout="row" fxLayoutAlign="space-between center" 
                                                            [ngStyle]="{'width': tamanioPantalla=='xs'?'250px':'290px'}" 
                                                            [ngClass]="{                              
                                                                        'chip-pendiente-revision': product.estado_producto_vigente==EstadosProductoType.PENDIENTE_REVISION,
                                                                        'chip-solicitado': product.es_modificado,
                                                                        'chip-rechazado': product.es_eliminado,
                                                                        'chip-pendiente': product.estado_producto_vigente==EstadosProductoType.PENDIENTE,
                                                                        'chip-proceso': product.estado_producto_vigente==EstadosProductoType.EN_PROCESO,
                                                                        'chip-terminado': product.estado_producto_vigente==EstadosProductoType.TERMINADO
                                                                    }" 
                                                            selectable
                                                            (click)="traspasarProductoReturn(i)"                                                    
                                                            [matTooltip]="'Estado: '+product.estado_producto_vigente.toUpperCase()" matTooltipPosition="after">
                                                        
                                                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                                                <div style="margin-right: 5px; font-weight:bold;">{{product.cantidad_producto}}</div>
                                                                <div *ngIf="product.es_modificado">
                                                                    (-{{product.cantidad_producto_reducido}}) &nbsp;
                                                                </div>
                                                                <div style="margin-right: 5px;"> {{product.nombre_producto}}</div>
                                                                <div>(P.U. {{product.precio_unitario | number:'1.2-2' }})</div>
                                                                &nbsp;
                                                                &nbsp;
                                                                <div *ngIf="product.descuento" [@blink]="blinkState" matTooltip="Descuento aplicado">
                                                                    -{{product.descuento!.porcentaje_descuento}}%&nbsp;(x{{product.descuento!.cantidad_producto}})
                                                                </div>
                                                                &nbsp;
                                                                <div *ngIf="product.tiene_incidencia==true" [@blink]="blinkState" [matTooltip]="product.incidencia!.motivo">
                                                                    <mat-icon class="mat-18">{{Icons.INFO}}</mat-icon>
                                                                </div>
                                                            </div>
                                                            <mat-icon>{{Icons.CHEVRON_LEFT}}</mat-icon>
                                                        </mat-chip>
                                                    </div>
                                
                                                    <div fxLayout="row">                                                    
                                                        <div class="precio-total" fxHide.xs fxShow>                                                        
                                                            {{product.precio_total | number:'1.2-2'}}
                                                        </div>                                                      
                                                    </div>
                                
                                                </div>
                                            </mat-chip-list>
                                        </div>
                                
                                    </div>
                                
                                    <ng-container *ngIf="product.lista_caract_seleccionada && product.lista_caract_seleccionada.length>0">
                                        <ul *ngFor="let caract of product.lista_caract_seleccionada" class="caracteristica-item">
                                            <li>
                                                <div fxLayout="row" class="caracteristica">
                                                    <mat-icon class="mat-18">{{Icons.ARROW_RIGHT}}</mat-icon>
                                                    <span>{{caract.cantidad}}&nbsp;</span>
                                                    <span *ngIf="caract.nombre_producto">
                                                        {{caract.nombre_producto}}&nbsp;
                                                    </span>
                                                    <span>{{caract.caracteristica_seleccionada}}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="listaProductoPedidoTraspaso.length==0 && esMesaExistente" >
                                    <span>No se tiene elementos traspasados.</span>
                                </ng-container>       
                                <mat-divider></mat-divider>                              
                                  
                            </div>

                         </ng-container>

                         <!-- para traspaso(mesa nueva) y division -->
                        <ng-container *ngIf="pedidoTraspaso.ambiente_mesa.length>0 && !esMesaExistente ">
                                 <mat-card-header>
                                    <mat-card-title>Pedido:&nbsp;{{pedidoTraspaso.codigo_pedido}}&nbsp;&nbsp;(DESTINO)</mat-card-title>
                                    <mat-card-subtitle fxLayout="row" fxLayoutGap="20px">
                                      <div fxLayout="column" style="width: 240px;" >
                                        <div>Usuario: {{pedidoTraspaso.usuario_creacion | hideMail }}</div>
                                        <div>Fecha: {{pedidoTraspaso.fecha_key.anio}}/{{pedidoTraspaso.fecha_key.mes}}/{{pedidoTraspaso.fecha_key.dia}}</div>
                                        <div>Hora: {{pedidoTraspaso.fecha_key.hora}}:{{pedidoTraspaso.fecha_key.minuto}}:{{pedidoTraspaso.fecha_key.segundo}}</div>
                                      </div>                           
                                    </mat-card-subtitle>
                                  </mat-card-header>
                                <div fxLayout="row" fxLayoutGap="10px" style="margin-left: 18px; margin-top: 10px;">
                                    <div>
                                        <h3>Mesas: </h3>
                                    </div>
                                    
                                    <mat-chip-list fxLayout="row wrap" fxLayoutAlign="start start">
                                        <ng-container *ngFor="let config of pedidoTraspaso.ambiente_mesa">
                                            <mat-chip *ngIf="config.es_vigente" [matTooltip]="config.mesa_estado.toUpperCase().replace('_',' ')"
                                                [@blink]="(config.mesa_estado=== EstadosMesaType.RESERVADO ||
                                                                config.mesa_estado=== EstadosMesaType.DISPONIBLE
                                                                )? blinkState:''" [ngClass]="{
                                                                  'chip-estado-mesa-disponible': config.mesa_estado===EstadosMesaType.DISPONIBLE,
                                                                  'chip-estado-mesa-reservado': config.mesa_estado===EstadosMesaType.RESERVADO,
                                                                  'chip-estado-mesa-ocupado': config.mesa_estado===EstadosMesaType.OCUPADO,
                                                                  'chip-estado-mesa-proceso': config.mesa_estado===EstadosMesaType.EN_PROCESO
                                                                  }" 
                                                                  removable
                                                                  (removed)="removerMesa(config)">
                                                {{config.mesa_numero}}
                                                <button *ngIf="!esDividir" matChipRemove>
                                                    <mat-icon>{{Icons.CLOSE}}</mat-icon>
                                                </button>
                                            </mat-chip>                         
                                        </ng-container>
                                    </mat-chip-list>
                                    <div *ngIf="esDividir">
                                        <mat-form-field>
                                            <input type="text" matInput
                                            placeholder="Nombre"
                                            required
                                            name="nombre"
                                            ngModel
                                            [(ngModel)]="comentarioMesaDerivado"
                                            [pattern]="PatternsRegex.onlyGoodCharsWithNumbers"
                                            #nombreInput="ngModel"
                                            [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_10"
                                            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_1"
                                            (ngModelChange)="cambioValorComentario($event)"
                                            oninput="this.value = this.value.toUpperCase()">
                                            <mat-error *ngIf="nombreInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                            <mat-error
                                                *ngIf="nombreInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
                                            <mat-error
                                                *ngIf="nombreInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_10}}</mat-error>
                                            <mat-error
                                                *ngIf="nombreInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_1}}</mat-error>
                                        </mat-form-field>
                                    </div>

                                </div>
                                <mat-divider></mat-divider>
            
                                <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top:10px; color: gray;">        
                                    <span>N° de ítems:&nbsp;{{listaProductoPedidoTraspaso.length}} </span>        
                                    <span>N° de productos:&nbsp;{{totalProductosTraspaso}} </span>
                                </div>
                        
                                <div fxLayoutGap="10px" fxLayout="column" style="margin-top: 10px;">
                                    <ng-container *ngFor="let product of listaProductoPedidoTraspaso; let i= index">
                                        <div fxLayout="row">
                                            <div [ngClass]="esPantallaXs?'nombres-precio-xs':'nombres-precio'">
                                                <mat-chip-list>
                                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                                        <div>
                                                            <mat-chip 
                                                                fxLayout="row" fxLayoutAlign="space-between center" 
                                                                [ngStyle]="{'width': tamanioPantalla=='xs'?'250px':'290px'}" 
                                                                [ngClass]="{                              
                                                                            'chip-pendiente-revision': product.estado_producto_vigente==EstadosProductoType.PENDIENTE_REVISION,
                                                                            'chip-solicitado': product.es_modificado,
                                                                            'chip-rechazado': product.es_eliminado,
                                                                            'chip-pendiente': product.estado_producto_vigente==EstadosProductoType.PENDIENTE,
                                                                            'chip-proceso': product.estado_producto_vigente==EstadosProductoType.EN_PROCESO,
                                                                            'chip-terminado': product.estado_producto_vigente==EstadosProductoType.TERMINADO
                                                                        }" 
                                                                        selectable
                                                                        (click)="traspasarProductoReturn(i)"                                                    
                                                                [matTooltip]="'Estado: '+product.estado_producto_vigente.toUpperCase()" matTooltipPosition="after">
                                                            
                                                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                                                    <div style="margin-right: 5px; font-weight:bold;">{{product.cantidad_producto}}</div>
                                                                    <div *ngIf="product.es_modificado">
                                                                        (-{{product.cantidad_producto_reducido}}) &nbsp;
                                                                    </div>
                                                                    <div style="margin-right: 5px;"> {{product.nombre_producto}}</div>
                                                                    <div>(P.U. {{product.precio_unitario | number:'1.2-2' }})</div>
                                                                    &nbsp;
                                                                    &nbsp;
                                                                    <div *ngIf="product.descuento" [@blink]="blinkState" matTooltip="Descuento aplicado">
                                                                        -{{product.descuento!.porcentaje_descuento}}%&nbsp;(x{{product.descuento!.cantidad_producto}})
                                                                    </div>
                                                                    &nbsp;
                                                                    <div *ngIf="product.tiene_incidencia==true" [@blink]="blinkState" [matTooltip]="product.incidencia!.motivo">
                                                                        <mat-icon class="mat-18">{{Icons.INFO}}</mat-icon>
                                                                    </div>
                                                                </div>
                                                                <mat-icon>{{Icons.CHEVRON_LEFT}}</mat-icon>
                                                            </mat-chip>
                                                        </div>
                                    
                                                        <div fxLayout="row">                                                    
                                                            <div class="precio-total" fxHide.xs fxShow>                                                        
                                                                {{product.precio_total | number:'1.2-2'}}
                                                            </div>                                                      
                                                        </div>
                                    
                                                    </div>
                                                </mat-chip-list>
                                            </div>
                                    
                                        </div>
                                    
                                        <ng-container *ngIf="product.lista_caract_seleccionada && product.lista_caract_seleccionada.length>0">
                                            <ul *ngFor="let caract of product.lista_caract_seleccionada" class="caracteristica-item">
                                                <li>
                                                    <div fxLayout="row" class="caracteristica">
                                                        <mat-icon class="mat-18">{{Icons.ARROW_RIGHT}}</mat-icon>
                                                        <span>{{caract.cantidad}}&nbsp;</span>
                                                        <span *ngIf="caract.nombre_producto">
                                                            {{caract.nombre_producto}}&nbsp;
                                                        </span>
                                                        <span>{{caract.caracteristica_seleccionada}}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="listaProductoPedidoTraspaso.length==0" >
                                        <span>No se tiene elementos</span>
                                    </ng-container>       
                                    <mat-divider></mat-divider>
                                    <div fxLayout="column" fxLayoutAlign="space-between end" fxLayoutGap="10px" class="divider">
                                        <div fxLayout="row">
                                            <div class="etiquetas-montos">Sub Total:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                            <div class="montos">S/ {{this.pedidoTraspaso.sub_total| number:'1.2-2' }}</div>
                                        </div>
                                        <div *ngIf="pedido.servicio_total" fxLayout="row">                        
                                            <div class="montos-subs">Servicio({{aspectosGenerales.tasa_servicio}}%):&nbsp;&nbsp;&nbsp;&nbsp;S/ {{this.pedidoTraspaso.servicio_total| number:'1.2-2' }}</div>
                                        </div>
                                        <div fxLayout="row">                        
                                            <div class="montos-subs">{{aspectosGenerales.sigla_impuesto}}({{aspectosGenerales.tasa_impuesto}}%):&nbsp;&nbsp;&nbsp;&nbsp;S/ {{this.pedidoTraspaso.igv| number:'1.2-2' }}</div>
                                        </div>
                                    </div>
                                    <mat-divider></mat-divider>
                                    <div fxLayout="row" class="divider" fxLayoutAlign="end center">
                                        <div>
                                          <h2>TOTAL:&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                                        </div>
                                        <div class="montos">
                                          <h2> S/ {{this.pedidoTraspaso.total| number:'1.2-2' }}</h2>
                                        </div>
                                      </div>  
                                </div>
                            
                        </ng-container>
                    </div>              
                </div>       
            </ng-container>            
            <!-- botonera xs    -->
            <div fxShow.xs fxHide style="padding-bottom: 20px;" >
                <mat-divider></mat-divider>
                <div style="margin-top: 20px;" fxLayout="row" fxLayoutAlign="space-around center">
                    <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%"
                        [attr_disabled]="(listaProductoPedidoTraspaso.length==0 || listaProductoPedido.length==0)" [attr_type]="'submit'"></app-button>
                    <app-button fxShow fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray"
                        (click)="Limpiar()"></app-button>
                    <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CANCEL"
                        (click)="Limpiar()"></app-button>
                </div>
            </div> 
    
        </div>
        <!-- botonera    -->
        <div fxShow fxHide.xs>
            <mat-divider></mat-divider>
            <div style="margin-top: 20px;" fxLayout="row" fxLayoutAlign="space-around center">
                <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%"
                    [attr_disabled]="(listaProductoPedidoTraspaso.length==0 || listaProductoPedido.length==0)" [attr_type]="'submit'"></app-button>
                <app-button fxShow fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray"
                    (click)="Limpiar()"></app-button>
                <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CANCEL"
                    (click)="Limpiar()"></app-button>
            </div>
        </div>
    </form>    
</div>
