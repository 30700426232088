import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { CodigoPais } from '../cons/common' ;
import { UIService } from './ui.services';
import { Observable } from 'rxjs';
import { TransaccionModel } from './models/trasaccion.model';
import { Utils } from '../helpers/utils';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class FunctionsService{
  constructor(
    private aff: AngularFireFunctions,
    private uiService: UIService
  ) { }

  GetDateServer(){
    const timeServer = this.aff.httpsCallable("GetDateServer");
    return timeServer({}).toPromise();
  }
  SendSMSClient(code:any){
    const resultSMS = this.aff.httpsCallable("SendSMS");
    return resultSMS(CodigoPais.codePeru+code).toPromise();
  }
  GetCaptchaAritmetico(){
    //cambio de estado muestra spinner
    this.uiService.loadingStateChanged.next(true);
    const result = this.aff.httpsCallable("GetCaptchaAritmetico");
    return result("").toPromise();
  }
  SetRegistroColaborador(email:string, password:string):Observable<any> {
    const registroColaborador = this.aff.httpsCallable("SetColaboradorPlataforma");
    return registroColaborador({email: email, password:password });
  }
  VerifyAndResendEmail(email:string):Observable<any> {
    const result = this.aff.httpsCallable("verifyAndResendEmail");
    return result({email: email});
  }
  DeleteColaboradorPlataforma(uid:string):Observable<any> {
    const deleteColaborador = this.aff.httpsCallable("DeleteColaboradorPlataforma");
    return deleteColaborador({uid: uid});
  }

  UpdateColaboradorPlataformaPassword(uid:string, password:string):Observable<any> {
    const updatePass = this.aff.httpsCallable("UpdateColaboradorPlataformaPassword");
    return updatePass({uid: uid, password:password});
  }
  DeleteCuentaAllColaboradores(idEmpresa:string):Observable<TransaccionModel> {
    const deleteAll = this.aff.httpsCallable("DeleteCuentaAllColaboradores");
    return deleteAll({idEmpresa: idEmpresa});
  }
  PostComprobanteElectronico(bodyComprobante:any, url:string, token:string, idEmpresa:string, esCifrar:boolean, esBoleta:boolean){
    const sendComprobante = this.aff.httpsCallable("PostComprobanteElectronico");    
    let data:string = JSON.stringify({bodyComprobante: bodyComprobante, url:url, token:token, idEmpresa:idEmpresa, esBoleta:esBoleta});
    let cadenaFinal='';
    if(esCifrar){      
      const key = environment.key.COMPROBANTE_ELECTRONICO_KEY;
      cadenaFinal = Utils.cypher(true, key, data)
    }
    return sendComprobante({cyp: esCifrar, dataCuerpo: esCifrar? cadenaFinal: data});    
  }

}
