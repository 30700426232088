import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
//import { colections, IContadorDocumento, ICostoFacturacion, ICostoModuloRecibo, IFacturacion, IModulosSeleccionados, IRecibo } from 'functions/src/class_interface';
import {colections, documentRecibo} from '../../shared/cons/db.colections'
import { IContadorDocumento} from '../../shared/models/IContadorDocumentos'

import { CostoFacturacion} from '../../shared/components/procesos/gestion-colaborador/models/costo-facturacion.model'
import { Auditoria, documentsEmpresa } from '../cons/db.colections';
import { map } from 'rxjs/operators';
import { Utils } from '../helpers/utils';
import { IEmpresa } from '../components/dialog/models/empresa.model';
import { ComprobanteElectronico } from '../components/dialog/models/comprobante-electronico';
import { deleteField, serverTimestamp, Timestamp } from 'firebase/firestore';
import { TransaccionModel } from './models/trasaccion.model';
import * as moment from "moment";
import { DatosPlataforma } from '../cons/common';
import { Facturacion } from '../components/procesos/gestion-colaborador/models/facturacion.model';
import { ICostoModuloRecibo } from '../components/procesos/gestion-colaborador/models/ICostoModuloRecibo';
import { IRecibo } from '../components/procesos/gestion-colaborador/models/IRecibo';
import { IModulosSeleccionados } from '../components/dialog/models/IModuloEmpresa';
import { IComprobanteElectronico } from '../models/IComprobanteElectronico';

@Injectable({
  providedIn: 'root'
})
export class SpConsolaService {
  esAutenticadoConsola$ = new Subject<boolean>();
  transaccion: TransaccionModel = new TransaccionModel();
  
  constructor(
    private db: AngularFirestore,
  ) { }

  async getDeudaEmpresa(ruc: string) {
    const refEmpresa = this.db.collection(colections.EMPRESA);
    const docEmpresa = await this.db.collection(colections.EMPRESA, ref=>ref.where(documentsEmpresa.RUC,'==',ruc)
      .where(documentsEmpresa.ES_BORRADO,'==',false)).get().toPromise();
    if(!docEmpresa.empty){
      const idEmpresa = docEmpresa.docs[0].id;
      const empresaDoc = docEmpresa.docs[0].data() as IEmpresa;
      empresaDoc.id = idEmpresa;
      const refFactura = await refEmpresa.doc(idEmpresa).collection(colections.FACTURACION).get().toPromise();
      let reciboRet:IRecibo | null = null;

      if (!refFactura.empty) {
        const docFactura = refFactura.docs[0].data() as Facturacion;
        docFactura.id = refFactura.docs[0].id;
        const docRecibo = await refEmpresa.doc(idEmpresa).collection(colections.FACTURACION).doc(docFactura.id)
          .collection(colections.RECIBO, (ref) => ref.where(documentRecibo.PENDIENTE_PAGO, '==', true)).get().toPromise();
          if(!docRecibo.empty){
            reciboRet = docRecibo.docs[0].data() as IRecibo;
            reciboRet.id = docRecibo.docs[0].id;
            const data = {empresa: empresaDoc, factura:docFactura, recibo:reciboRet}
            
            
            return data;
          }else{
            const data = {empresa: empresaDoc, factura:docFactura, recibo:null}
            return data;
          }
  
      }else{
        return  null;
      }
    }else{
      return null;
    }
  }

  getEmpresa(ruc: string) {
    return this.db.collection(colections.EMPRESA, ref => ref
      .where(Auditoria.es_vigente, '==', true)
      .where(Auditoria.es_borrado, '==', false)
      .where(documentsEmpresa.RUC, '==', ruc)
    ).get().toPromise().then(doc => {
      if (!doc.empty) {
        const datos = Utils.convertDate(doc.docs[0].data()) as IEmpresa;
        const id = doc.docs[0].id;
        const data = { id, ...datos } as IEmpresa;
        const empresasDoc: any[] | null = [];
        empresasDoc.push(data);
        return empresasDoc;
      } else {
        return null;
      }
    });
  }

  async updateEmpresaComprobante(comprobante:ComprobanteElectronico, idEmpresa:string){
    const docComprobante = Utils.SerializeJsonToDb(comprobante);
    docComprobante.fecha_alta_pse = serverTimestamp();
    try{
      const transactionResult = this.db.firestore.runTransaction(async (trans) => {
          const empRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
          const comprobante = this.db.collection(colections.EMPRESA).doc(idEmpresa).collection(colections.COMPROBANTE_ELECTRONICO).doc();

          trans.update(empRef.ref,{comprobante_electronico: docComprobante });
          const comprobanteElect:IComprobanteElectronico={
            numero_inicial_boleta:0,
            numero_actual_boleta:0,
            numero_inicial_factura:0,
            numero_actual_factura:0,
            fecha_creacion:serverTimestamp()
          }
          trans.set(comprobante.ref,comprobanteElect);

          this.transaccion.tx = true;
          return this.transaccion;

        });
        return transactionResult;
    }
    catch(error){
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

  /*
    FUNCTION: efectuarCorteServicio()
    PROPOSITO: Generar el corte del servicio, se ejecuta cada  30  de cada mes, agrega el campo es_suspendido_por_pagar:true
    (el corte será ejecutado por otra funcion o CRONOJOB: efectuarCorteServicio).
    IMPORTANTE: Este metodo solo se ejecuta cuando el CRONOJOB no funciona, existe la replica de este metodo adaptado en sintaxis, la cual es la CloudFunction efectuarCorteServicio
  */

  async efectuarCorteServicio(){
    const docEmpresas = await  this.db.collection(colections.EMPRESA, ref=>ref.where(documentsEmpresa.ES_BORRADO,'==',false)
    .where(documentsEmpresa.ES_SIN_PAGO,'==',false)).get().toPromise();

    if(!docEmpresas.empty){
      try{
        docEmpresas.docs.map(async empresa=>{
          const empresaDoc = empresa.data() as IEmpresa;
          empresaDoc.id = empresa.id;

          //**upd tran.facturacion**//     
          const docFacturaRef = this.db.collection(colections.EMPRESA).doc(empresa.id).collection(colections.FACTURACION);
          const docFactura = await docFacturaRef.get().toPromise();
            
          if (!docFactura.empty) {
            const facturaDoc = docFactura.docs[0].data() as Facturacion;
            facturaDoc.id = docFactura.docs[0].id;

                      
            //tiene recibo pendiente de pago?            
            const docReciboPendiente = await this.db.collection(colections.EMPRESA).doc(empresa.id).collection(colections.FACTURACION).doc(facturaDoc.id)
            .collection(colections.RECIBO, ref=>ref.where(documentRecibo.PENDIENTE_PAGO,'==',true)).get().toPromise();

            if(!docReciboPendiente.empty){
                this.db.collection(colections.EMPRESA).doc(empresa.id).update({es_suspendido_por_pagar:true});
              }
            }    
        });
        this.transaccion={tx:true};
        return this.transaccion;
      }
      catch(error){
        this.transaccion={tx:false, error:error};
        return this.transaccion;
      }
    }
    else{
      return { tx: false, error: 'No hay empresas disponibles.' };     
    }    
  }



  /*
    FUNCTION: generarFacturaReciboTodos()
    PROPOSITO: Generar los recibos de pago para los negocios sin deuda anterior, la generacion de los recibos se lleva a cabo cada 25 de cada mes, todo recibo
    es facturado por 30 días, la fecha de vencimiento es cada 30 ( el corte será ejecutado por otra funcion o CRONOJOB: efectuarCorteServicio).
    IMPORTANTE: Este metodo solo se ejecuta cuando el CRONOJOB no funciona, existe la replica de este metodo adaptado en sintaxis, la cual es la CloudFunction CalcularRecibo
  */
  async generarFacturaReciboTodos(){
    const docEmpresas = await  this.db.collection(colections.EMPRESA, ref=>ref.where(documentsEmpresa.ES_BORRADO,'==',false)
    .where(documentsEmpresa.ES_SIN_PAGO,'==',false)).get().toPromise();    
    if(!docEmpresas.empty){
      try{
        //1.1 actualizando programaciones especiales
        const transacciones = docEmpresas.docs.map(empresa=>{
          return this.db.firestore.runTransaction(async (trans) => {
            const empresaDoc = empresa.data() as IEmpresa;
            empresaDoc.id = empresa.id;          

            //**upd tran.facturacion**//     
            const docFacturaRef = this.db.collection(colections.EMPRESA).doc(empresa.id).collection(colections.FACTURACION);
            const docFactura = await docFacturaRef.get().toPromise();        
            
            if (!docFactura.empty) {
              const facturaDoc = docFactura.docs[0].data() as Facturacion;
              facturaDoc.id = docFactura.docs[0].id;

              //tiene recibo pendiente de pago?
              const reciboRef = this.db.collection(colections.EMPRESA).doc(empresa.id).collection(colections.FACTURACION).doc(facturaDoc.id).collection(colections.RECIBO);
              const docReciboPendiente = await this.db.collection(colections.EMPRESA).doc(empresa.id).collection(colections.FACTURACION).doc(facturaDoc.id).collection(colections.RECIBO, ref=>ref
                .where(documentRecibo.PENDIENTE_PAGO,'==',true)
              ).get().toPromise();


              //cuando no tiene documentos pendientes por pagar
              if(docReciboPendiente.empty){
                //IMPORTANTE: CALCULO RECIBO//
                //****************************//
                const facturacionDoc = facturaDoc;
                const fechaActual = new Date(moment().format());//fechaISO8601              
                const fechaRegistro = new Date(facturaDoc.fecha_registro.seconds *1000);  //revisar si tiene que hacerse con esta fechaActual, ya que periodicamente se tiene que facturar              
                const diferenciaMilis = Number(fechaActual.getTime() - fechaRegistro.getTime());              
                const diferenciaEnDias = Math.floor(diferenciaMilis / (1000 * 60 * 60 * 24));
                //console.log(diferenciaEnDias);
                let costo_sub_total_modulos: ICostoModuloRecibo[] = [];
                let costo_total_modulos = 0;

                // cuando la facturación tiene más de un mes
                if (diferenciaEnDias >= DatosPlataforma.DIAS_FACTURACION) {                                                              
                  facturacionDoc.costo_facturacion.forEach(costo_fact => {                 
                    const cantidad_usuario_adicional = Number(costo_fact.cantidad_total_usuarios) - Number(costo_fact.cantidad_usuarios_x_defecto);
                    const total_costo_adicional = Number(cantidad_usuario_adicional) * Number(costo_fact.costo_usuario_adicional);
                    const costo_modulo_recibo: ICostoModuloRecibo = {
                      codigo_modulo: costo_fact.codigo_modulo,
                      nombre_modulo: costo_fact.nombre_modulo,
                      costo_base: costo_fact.costo_modulo - total_costo_adicional, //costo base
                      concepto_costo_base: 'Costo módulo incluye: ' +'('+costo_fact.cantidad_usuarios_x_defecto.toString()+')'+ ' usuarios(s)',
                      cantidad_usuario_adicional: cantidad_usuario_adicional,
                      costo_usuario_adicional: costo_fact.costo_usuario_adicional,
                      concepto_costo_adicional:'('+ cantidad_usuario_adicional.toString()+')' + ' usuarios ' + '* '+'('+costo_fact.costo_usuario_adicional.toString()+')',
                      total_costo_adicional: total_costo_adicional,
                      total_costo_modulo: costo_fact.costo_modulo,//ya incluye el costo total + adicionales
                      dias_operativos:30
                    }
                    costo_sub_total_modulos.push(costo_modulo_recibo);
                  });
                }else{
                  //cuando la facturacuión es menor al mes
                  if(diferenciaEnDias < DatosPlataforma.DIAS_FACTURACION && diferenciaEnDias>0){
                    facturacionDoc.costo_facturacion.forEach(costo_fact => {
                      const cantidad_usuario_adicional = Number(costo_fact.cantidad_total_usuarios) - Number(costo_fact.cantidad_usuarios_x_defecto);
                      const total_costo_adicional = Number(cantidad_usuario_adicional) * Number(costo_fact.costo_usuario_adicional);
                      const total_costo_modulo_prorrateado = (costo_fact.costo_modulo/30) * diferenciaEnDias;
                      const costo_modulo_recibo: ICostoModuloRecibo = {
                        codigo_modulo: costo_fact.codigo_modulo,
                        nombre_modulo: costo_fact.nombre_modulo,
                        costo_base: costo_fact.costo_modulo-total_costo_adicional,
                        concepto_costo_base: 'Costo módulo incluye: ' +'('+costo_fact.cantidad_usuarios_x_defecto.toString()+')'+ ' usuarios(s)',
                        cantidad_usuario_adicional: cantidad_usuario_adicional,
                        costo_usuario_adicional: costo_fact.costo_usuario_adicional,
                        concepto_costo_adicional:'('+ cantidad_usuario_adicional.toString()+')' + ' usuarios ' + '* '+'('+costo_fact.costo_usuario_adicional.toString()+')',
                        total_costo_adicional: total_costo_adicional,
                        total_costo_modulo: total_costo_modulo_prorrateado,
                        dias_operativos: diferenciaEnDias
                      }
                      costo_sub_total_modulos.push(costo_modulo_recibo);
                    });                                 
                  }
                }
                //generamos el costo total por todos los modulos                
                costo_sub_total_modulos.forEach(costo => {
                  costo_total_modulos = costo_total_modulos + costo.total_costo_modulo;
                });            
                              
                //costo final mensual o parcial
                const costo_subtotal = Number(costo_total_modulos);
                const costo_impuesto = Number(costo_subtotal * DatosPlataforma.TASA_IMPUESTO);
                const costo_final_c_impuesto = Number(costo_subtotal) + costo_impuesto; //aplicando redondeo SUNAT
                  
                //calculando periodo actual
                const year = fechaActual.getFullYear();
                const month = (fechaActual.getMonth() + 1).toString().padStart(2, '0'); // Agrega un 0 al principio si es necesario
                const day = fechaActual.getDate().toString().padStart(2, '0'); // Agrega un 0 al principio si es necesario
                  
                const reciboDoc: IRecibo = {
                  costo_sub_total_modulos: costo_sub_total_modulos,
                  costo_total_modulos: costo_total_modulos,
                  tasa_impuesto: DatosPlataforma.TASA_IMPUESTO,                
                  costo_final: Utils.redondeoSUNAT(costo_final_c_impuesto),//incluye impuestos y redondeo SUNAT
                  costo_impuesto: costo_impuesto,
                  periodo: `${year}${month}${day}`,
                  fecha_calculo: fechaActual,
                  ultimo_dia_pago: new Date(new Date().setDate(new Date().getDate() + 5)),
                  pendiente_pago: true
                }                           
                trans.set(reciboRef.doc().ref, reciboDoc);
                
                //ACTUALIZANDO PROGRAMACIONES PENDIENTES//

                //buscando facturacion con modulos a quitar
                const facturaUpdMod = facturaDoc.costo_facturacion.filter(costo => (costo.pendiente_anulacion_modulo === true));
                const facturaUpdDec = facturaDoc.costo_facturacion.filter(costo => (costo.es_pendiente_calculo_variacion === true));

                //**buscando factura con anulacion modulo
                if (facturaUpdMod.length > 0) {
                  //seleccionamos todos los modulos pendientes de anulacion y los quitamos
                  const costoFacturacionUpd = facturaDoc.costo_facturacion.filter(costo => (costo.pendiente_anulacion_modulo === undefined));
                  if (costoFacturacionUpd.length > 0) {
                    facturaDoc.costo_facturacion = costoFacturacionUpd;
                  }
                }

                //**buscando factura con decrementos de vacantes de usuarios              
                if (facturaUpdDec.length > 0) {
                  facturaUpdDec.forEach(costo_facturacionObj => {

                    //documento facturacion
                    //recalculando los inputs de la facturacion
                    const cantidad_reducir = costo_facturacionObj.cantidad_usuarios_variacion!=undefined? costo_facturacionObj.cantidad_usuarios_variacion.cantidad_reduccion : 0;
                    const costo_reducir = cantidad_reducir * costo_facturacionObj.costo_usuario_adicional;
                    const cantidad_total_usuarios = costo_facturacionObj.cantidad_total_usuarios - cantidad_reducir;
                    const costo_modulo = costo_facturacionObj.costo_modulo - costo_reducir;

                    //creamos el nuevo objeto, rescantando las propiedades necesarias anteriores
                    const costoFacturacionNuevo: CostoFacturacion = { ...costo_facturacionObj };
                    delete costoFacturacionNuevo.cantidad_usuarios_variacion;
                    costoFacturacionNuevo.es_pendiente_calculo_variacion = false;
                    costoFacturacionNuevo.cantidad_total_usuarios = cantidad_total_usuarios;
                    costoFacturacionNuevo.costo_modulo = costo_modulo;

                    //buscamos el indica del objeto a reemplazar
                    const indiceReemplazo = facturaDoc.costo_facturacion.findIndex(objCostoFacturacion => objCostoFacturacion.codigo_modulo === costo_facturacionObj.codigo_modulo);
                    if (indiceReemplazo !== -1) {
                      facturaDoc.costo_facturacion[indiceReemplazo] = costoFacturacionNuevo;
                    }

                    //actualizando empresa (modulos respectivos con decremento de usuarios)
                    const indiceReemplazoModEmpresa = empresaDoc.lista_modulos_seleccionados!.findIndex(mod=>mod.codigo=== costo_facturacionObj.codigo_modulo);
                    if(indiceReemplazoModEmpresa!==-1){
                      empresaDoc.lista_modulos_seleccionados![indiceReemplazoModEmpresa].cantidad_total_usuarios = cantidad_total_usuarios;
                      empresaDoc.lista_modulos_seleccionados![indiceReemplazoModEmpresa].costo_total = costo_modulo;

                    }                 
                  });
                }             

                trans.update(docFactura.docs[0].ref, {
                  costo_facturacion: facturaDoc.costo_facturacion,            
                  fecha_modificacion: serverTimestamp()
                });              

                //1.1. actualizando modulos de empresa, caso: anulacion programado anulacion y reduccion de usuarios en caso de que exista
                const modulosSelUpd = empresaDoc.lista_modulos_seleccionados!.filter((modulo: IModulosSeleccionados) => modulo.pendiente_anulacion_modulo === true);
                if (modulosSelUpd.length > 0) {
                  const modulosSelPermanente = empresaDoc.lista_modulos_seleccionados!.filter((modulo: IModulosSeleccionados) =>modulo.pendiente_anulacion_modulo === undefined);         
                  trans.update(empresa.ref, { lista_modulos_seleccionados: modulosSelPermanente });           
                }else{
                  trans.update(empresa.ref, { lista_modulos_seleccionados: empresaDoc.lista_modulos_seleccionados });   
                }
                
                if (modulosSelUpd.length > 0) {
                  //1.2. actualizando modulos de empresa, caso: reduccion variacion usuarios colaborador resumen
                  const docColaboradorResumen = await this.db.collection(colections.EMPRESA).doc(empresa.id).collection(colections.COLABORADOR_RESUMEN).get().toPromise();
                  if (!docColaboradorResumen.empty) {
                    const resumen_colabDoc = docColaboradorResumen.docs[0].data() as IContadorDocumento;
                    const modResumenUpd = resumen_colabDoc.cantidad_modulos_seleccionados.filter((moduloSel) => moduloSel.pendiente_anulacion_modulo === true);

                    if (modResumenUpd.length > 0) {
                      const modResumenPermanente = resumen_colabDoc.cantidad_modulos_seleccionados.filter((moduloSel) => moduloSel.pendiente_anulacion_modulo === undefined );
                      trans.update(docColaboradorResumen.docs[0].ref, {
                        cantidad_modulos_seleccionados: modResumenPermanente,
                        pendiente_anulacion_modulo: deleteField(),
                      });
                    }
                  }            
                }

              }
              else{
                //cuando tiene recibos pendientes de pago, no genera nuevo recibo
                /*el job que corre el 30 corta el servicio
                agrega a empresa es_suspendido_por_pago:true o false
                */              
              }                                                    
            }

            //return result
            this.transaccion={tx:true};          
          });
        });

        await Promise.all(transacciones);
        return { tx: true };        
      }
      catch(error){
        this.transaccion={tx:false, error:error};
        return this.transaccion;
      } 
    }
    else{
      return { tx: false, error: 'No hay empresas disponibles.' };     
    }
    
  }
  
}
