<div *ngIf="pathImage" [matTooltip]="tipoUsuario==null?'':tipoUsuario" [matMenuTriggerFor]="menu">
  <img [src]="pathImage" [alt]="" aria-label="Usuario" class="icono-iniciales">
</div>
<div *ngIf="!pathImage" [matTooltip]="tipoUsuario==null?'':tipoUsuario" [matMenuTriggerFor]="menu">
  <span class="large-avatar" [style.background-color]="color$ | async">
    {{iniciales$ | async}}
  </span>
</div>
<mat-menu #menu="matMenu" yPosition="below">
  <ng-container *ngFor="let opcion of menuAvatarUsuario">
    <button mat-menu-item [disabled]="opcion.disabled" *ngIf="!opcion.hidden" (click)="onClick(opcion.id)" >
      <mat-icon class="icon-list">{{opcion.icono}}</mat-icon>
      <span>{{opcion.titulo}}</span>
    </button>
  </ng-container>
</mat-menu>
