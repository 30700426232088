export class CodigoPais{
  public static codePeru:string ="+51"
}
export class DatosPlataforma{
  public static readonly NOMBRE_EMPRESA:string ="Synaptica S.R.L.";
  public static readonly NOMBRE_PLATAFORMA:string = "Modula";
  public static readonly EMAIL_EMPRESA:string = "soporte.tecnico@modula.pe";
  public static readonly NUMERO_SOPORTE: string= "948161449";
  public static readonly DIAS_FACTURACION: number= 25;
  public static readonly DIAS_CORTE_SERVICIO: number= 30;
  
  public static readonly TASA_IMPUESTO:number=0.18;
}
//const email_empresa:string = DatosPlataforma.NOMBRE_PLATAFORMA.toLowerCase()+ DatosPlataforma.EMAIL_EMPRESA;

export class Msjs{
  //turno
  public static readonly MSJ_ERROR_DIVIDIR_PEDIDO:string ="No es posible dividir el pedido nuevamente.";
  public static readonly MSJ_ERROR_MEDIO_PAGO:string ="No tiene una configuración activa de medios de pago aceptados.";
  public static readonly MSJ_ERROR_TASA_SERVICIO:string ="No tiene una configuración activa de tasa de servicio.";
  public static readonly MSJ_ERROR_AMBIENTE_MESAS:string ="No tiene una configuración activa de ambietes y mesas.";
  public static readonly MSJ_ERROR_NO_TURNO:string ="No tiene un turno abierto";
  public static readonly MSJ_ERROR_SI_TURNO:string ="Tiene un turno APERTURADO, no se pueden realizar cambios.";
  public static readonly MSJ_ERROR_NECESARIA_CARACTERISTICA:string ="Es necesario seleccionar una característica.";
  public static readonly MSJ_INFO_PRODUCTO_PERSONALIZABLE:string ="El producto tiene características. Agregar desde el Catálogo";

  public static readonly MSJ_CANTIDAD_MAXIMA_USUARIOS:string ="Para el módulo {0}, ya alcanzaste el número máximo de {1} usuarios.";
  public static readonly MSJ_RESPUESTA_INCORRECTA:string ="El resultado es INCORRECTO.";
  public static readonly MSJ_ENVIO_SMS:string ="Se te envió un SMS con el código a tu teléfono.";
  public static readonly MSJ_ENVIO_SMS_DOBLE:string ="Ya reenviaste más de un código, vuelve a iniciar el registro y verifica tu número.";
  public static readonly MSJ_ACEPTAR:string ="Aceptar";
  public static readonly MSJ_CODIGO_OK_NUEVO_USUARIO:string ="El código es CORRECTO, crea tu usuario.";
  public static readonly MSJ_CODIGO_ERROR:string ="El código es INCORRECTO.";
  public static readonly MSJ_PASSWORD_DISTITNTO_ERROR:string ="Las contraseñas no coinciden.";
  public static readonly MSJ_SIGNUP_ERROR_EMAIL_USADO:string ="El correo electrónico ya está en uso.";
  public static readonly MSJ_RUC_EXISTE:string ="El RUC ya está en uso.";
  public static readonly MSJ_SIGNUP_ERROR_EMAIL_USADO_CTA:string ="El correo electrónico ya está en uso en otro negocio.";
  public static readonly MSJ_LOGIN_ERROR_USUARIO:string ="El correo electrónico no se encuentra.";
  public static readonly MSJ_LOGIN_ERROR_ENVIO_RESET_PASSWORD:string ="Ocurrió un error al enviar el correo para recuperar tu cuenta, contáctate con soporte técnico.";
  public static readonly MSJ_LOGIN_ERROR_PASSWORD:string ="Error en la contraseña.";
  public static readonly MSJ_ACCESO_NO_AUTORIZADO:string ="Acceso no autorizado.";
  public static readonly MSJ_ERROR_DESCONOCIDO:string ="Error desconocido, contacte con el administrador.";
  public static readonly MSJ_ROL_ASOCIADO_COLABORADOR:string ="No se puede eliminar tiene $ colaborador(es) asociado(s) al rol, primero quite el rol a dichos colaboradores.";
  public static readonly MSJ_ERROR_FORMULARIO:string ="El formulario contiene errores o datos en blanco.";
  public static readonly MSJ_ERROR_CANTIDAD_MINIMA_AMBIENTE:string ="El formulario ya tiene la cantidad mínima de ambientes.";
  public static readonly MSJ_ERROR_SELECCIONAR_ESTADO:string ="Debe seleaccionar un ESTADO.";
  public static readonly MSJ_ERROR_SELECCIONAR_CRITERIO:string ="Debe seleccionar un criterio de búsqueda.";
  public static readonly MSJ_ERROR_INGRESAR_CRITERIO:string ="Debe ingresar un criterio de búsqueda.";
  public static readonly MSJ_INFO_DUPLICIDAD_MODULOS:string ="Solo se permite un rol por módulo, el rol será reemplazado.";
  public static readonly MSJ_ERROR_MULTIPLICIDAD_ROL:string ="El rol ya se encuentra registado.";
  public static readonly MSJ_ERROR_FALTA_ROL:string ="Debe seleccionar un rol.";
  public static readonly MSJ_REGISTRO_SATISFACTORIO:string ="Te registraste correctamente, se te envió un correo electronico, validalo e inicia sesión.";
  public static readonly MSJ_VALIDA_EMAIL:string ="Se te envió un correo electronico, valídalo e inicia sesión.";
  public static readonly MSJ_RESET_PASSWORD_EMAIL:string ="Se te envió un correo electronico, para recuperar tu contraseña.";
  public static readonly MSJ_COMPLETA_REGISTRO:string ="Iniciaste sesión, ahora completa tu registro.";
  public static readonly MSJ_VALIDACION_COMPLETA:string ="Validación completa, puedes continuar.";
  public static readonly MSJ_REGISTRO_COMPLETO:string ="Completaste el registro, puedes usar la plataforma.";
  public static readonly MSJ_ARCHIVO_ERROR:string ="El archivo no es válido: ";
  public static readonly MSJ_MAXIMO_NRO_ARCHIVOS:string ="Se permiten $ archivos como máximo.";
  public static readonly MSJ_ARCHIVOS_DUPLICADOS:string ="No se permite archivos duplicados.";
  public static readonly MSJ_MAXIMO_TAMANIO_ARCHIVOS:string ="El archivo deber un tamaño máximo de $Kb.";
  public static readonly MSJ_GUARDADO_OK:string ="Se guardó correctamente.";
  public static readonly MSJ_ERROR_DUPLICIDAD_URL_PARCIAL:string ="Ya existe la URL parcial, no se puede guardar.";
  public static readonly MSJ_DSCTO_ERROR:string ="Se requiere un DESCUENTO (%).";
  public static readonly MSJ_LISTA_PRODUCTO_ERROR:string ="No se cargó la lista de producto existente";
  public static readonly MSJ_ERROR_URL:string ="No existe la URL solicitada.";
  public static readonly MSJ_SE_TIENE_DSCTO:string ="El producto tiene DESCUENTO (%), asignado. No se puede traspasar."; 
  public static readonly MSJ_SE_TIENE_INCIDENCIA:string ="El producto tiene INCIDENCIA, asignada. No se puede traspasar."; 
  public static readonly MSJ_DSCTO_MOTIVO_ERROR:string ="Se requiere un MOTIVO de descuento.";  
  public static readonly MSJ_DSCTO_ERROR_MAYOR100:string ="No se permite un descuento mayor al 100%.";
  public static readonly MSJ_DSCTO_ERROR_EXISTE:string ="Ya existe el DESCUENTO.";
  public static readonly MSJ_DSCTO_ERROR_NO_EXISTE:string ="No se tiene DESCUENTOS configurados.";
  public static readonly MSJ_INCIDENCIA_ERROR:string ="Se requiere un motivo de INCIDENCIA.";
  public static readonly MSJ_INCIDENCIA_ERROR_EXISTE:string ="Ya existe la INCIDENCIA.";
  public static readonly MSJ_REDUCIR_AMBIENTE_NO:string ="Para eliminar un ambiente use el botón de eliminar ambiente.";
  public static readonly MSJ_SIN_CAMBIOS:string ="No se tienen cambios.";
  public static readonly MSJ_TASA_CERO:string ="No se puede guardar una tasa 0.";
  public static readonly MSJ_MONTO_ERROR:string ="Monto no válido.";
  public static readonly MSJ_MONTO_EXEDIDO:string ="Monto no válido, se exedió el total del pedido";
  public static readonly MSJ_AGREGASTE_OK:string ="Se agregó: $";
  public static readonly MSJ_CODIGO_EXISTENTE:string ="Ya existe el código de $.";
  public static readonly MSJ_NO_TIENE_IMAGEN:string ="No existe imagenes.";
  public static readonly MSJ_PRECIO_CERO:string ="No se permite el precio 0.00 del producto.";
  public static readonly MSJ_SIN_STOCK_PRODUCTO:string ="No se tiene en stock el producto";
  public static readonly MSJ_DOBLE_PRODUCTO:string ="No se permite agregar dos veces el mismo producto.";
  public static readonly MSJ_PRODUCTO_AGREGADO_CANTIDAD:string ="Se agrego 1 unidad más de: ";
  public static readonly MSJ_PEDIDO_PROCESO:string ="Ya tiene un pedido en proceso.";
  public static readonly MSJ_EDICION_PROCESO:string ="Ya tiene una edición en proceso.";
  public static readonly MSJ_SIN_PRODUCTO:string ="No se tienen productos agregados.";
  public static readonly MSJ_PRODUCTO_PREPAGADO:string ="El producto ya fue Pre Pagado.";
  public static readonly MSJ_PRODUCTO_PREPAGADO_ERROR:string ="No se permite pre pagar todos los productos.";
  public static readonly MSJ_PRODUCTO_CANTIDAD_MINIMA:string ="Ya tiene la cantidad mínima del producto.";
  public static readonly MSJ_PRODUCTO_CANTIDAD_MINIMA_SOLICITAR_ANULACION:string ="Ya tiene la cantidad mínima del producto. Solo el ADMINISTRADOR puede anular un pedido.";
  public static readonly MSJ_PRODUCTO_TERMINADO:string ="No se puede editar un producto TERMINADO.";
  public static readonly MSJ_PRODUCTO_DESCUENTO:string ="No se puede editar un producto con DESCUENTO.";
  public static readonly MSJ_PRODUCTO_INCIDENCIA:string ="No se puede editar un producto con INCIDENCIA.";
  public static readonly MSJ_PRODUCTO_EN_PROCESO:string ="No se puede editar un producto EN PROCESO.";
  public static readonly MSJ_PRODUCTO_NO_PENDIENTE:string ="No se puede editar un producto que no esta PENDIENTE.";
  public static readonly MSJ_PRODUCTO_SELECCIONE:string ="Seleccione el (los) producto(s).";
  public static readonly MSJ_PRODUCTO_DIFERENTE:string ="La cantidad seleccionada es distinta a la cantidad referida para el descuento.";
  public static readonly MSJ_PRODUCTO_CANTIDAD_MINIMA_NECESARIA:string ="la cantidad mínima del producto es 1.";
  public static readonly MSJ_GENERO_BOLETA:string ="Se generó la boleta de venta.";  
  public static readonly MSJ_BOLETA_MAYOR_700:string ="No se puede emitir una boleta sin nombre con un monto mayor a S/ 699.00 .";  
  public static readonly MSJ_GENERO_FACTURA:string ="Se generó la factura.";
  public static readonly MSJ_DOBLE_CRUZADO:string ="No se permite agregar un producto que ya esta referenciado: $.";
  public static readonly MSJ_GUARDADO_PENDIENTE:string ="No olvide GUARDAR los cambios.";
  public static readonly MSJ_ELIMINADO_OK:string ="Se eliminó correctamente.";
  public static readonly MSJ_CUENTA_CREADA_OK:string ="La cuenta fue creada, antes del inicio de sesión se debe validar el correo. Si no tienes el correo verifica la bandeja de correo no deseado.";
  public static readonly MSJ_CUENTA_SUSPENDIDA_COLABORADOR:string ="Tu cuenta fue suspendida por el administrador. Si crees que es un error, contáctate con tu administrador.";
  public static readonly MSJ_DOBLE_SESION:string ="Tienes una sesión activa en otro dispositivo, se cerrará esa sesión e iniciarás sesión en este dispositivo.";
  public static readonly MSJ_DOBLE_SESION_CERRAR:string ="Iniciaste sesión en otro dispositivo, se cerrará tu sesión aquí.";
  public static readonly MSJ_DOBLE_SESION_NO_ACTIVO:string ="No se tiene una sesión ACTIVA, se cerrará tu sesión en este dispositivo.";
  public static readonly MSJ_CUENTA_ELIMINADA_COLABORADOR:string ="Tu cuenta fue eliminada por el administrador. Si crees que es un error, contáctate con tu administrador.";
  public static readonly MSJ_CANTIDAD_NO_VALIDA:string ="La cantidad en $ no es válida, se reemplazará por la cantidad mínima de usuarios.";
  public static readonly MSJ_CANTIDAD_NO_VALIDA_EDITAR:string ="La cantidad en $ no es válida, verifique la cantidad mínima de usuarios para el módulo.";
  public static readonly MSJ_REQUIERE_SELECCION:string ="Debe seleccionar un(os) modulo(s) y poner la cantidad de usuarios.";
  public static readonly TITULO_CONFIRMACION:string ="Confirmar";
  public static readonly TITULO_SUSPENCION:string ="Cuenta Suspendida";
  public static readonly TITULO_SUSPENCION_DEUDA:string ="Cuenta Suspendida: TIENES UNA DEUDA PENDIENTE";
  public static readonly TITULO_ACTIVACION:string ="Cuenta Activada";
  public static readonly TITULO_ELIMINACION:string ="Empresa Eliminada";
  public static readonly MENSAJE_CUENTA_SUSPENDIDA:string ="Se SUSPENDIÓ la cuenta, actívalo cuando lo requieras (sujeto a los cargos por mensualidad).";
  public static readonly MENSAJE_CUENTA_SUSPENDIDA_SIN_PAGO:string ="Se SUSPENDIÓ la cuenta por falta de pago";
  public static readonly MENSAJE_CUENTA_ACTIVADA:string ="Se ACTIVÓ la cuenta, ya puedes usar la plataforma";
  public static readonly MENSAJE_EMPRESA_ELIMINADA:string ="Se ELIMINÓ la empresa, puedes volver a iniciar sesión y configurar tu empresa.";
  public static readonly MENSAJE_EMPRESA_ELIMINADA_USUARIO:string ="Se ELIMINÓ la empresa, ya no puedes tener una sesión.";
  public static readonly MENSAJE_CONFIRMACION:string ="¿Está seguro de guardar los cambios?";
  public static readonly MENSAJE_CONFIRMACION_SINCRONIZAR:string ="¿Está seguro de actualizar la carta?";
  public static readonly MENSAJE_CONFIRMACION_REMOVER:string ="¿Está seguro de remover el item seleccionado?";
  public static readonly MENSAJE_CONFIRMACION_SEPARAR:string ="¿Está seguro de dividir el pedido, esta operación no podrá deshacerse?";
  public static readonly MENSAJE_CONFIRMACION_NEXT_ESTADO:string ="¿Está seguro de pasar al siguiente estado todos los productos?";
  public static readonly MENSAJE_CONFIRMACION_TERMINO_RAPIDO:string ="¿Está seguro de finalizar los productos del pedido?";
  public static readonly MENSAJE_ANULACION_PEDIDO:string ="¿Está seguro de anular el pedido?";
  public static readonly MENSAJE_CERRAR_PEDIDO:string ="¿Está seguro de IMPRIMIR?, esta acción CERRARÁ el pedido: $.";
  public static readonly MENSAJE_ABRIR_PEDIDO:string ="¿Está seguro de ABRIR el pedido: $?, el pedido volverá al estado TERMINADO.";
  public static readonly MENSAJE_CONFIRMACION_SOLICITUD:string ="¿Está seguro de soliciar un cambio al administrador?";  
  public static readonly MENSAJE_CONFIRMACION_ELIMINAR_MESA:string ="¿Está seguro de liberar la mesa?";
  public static readonly MENSAJE_CONFIRMACION_SELECCIONAR_MESA:string ="Se seleccionó la mesa: ";
  public static readonly MENSAJE_CONFIRMACION_MODIFICAR:string ="¿Está seguro de modificar?";
  public static readonly MENSAJE_CONFIRMACION_CONFIRMAR_LIBERAR_MESA:string ="Se liberó la mesa.";
  public static readonly MENSAJE_CONFIRMACION_ACTIVAR:string ="¿Está seguro de ACTIVAR la cuenta?";
  public static readonly MENSAJE_CONFIRMACION_INACTIVAR:string ="¿Está seguro de INACTIVAR la cuenta?";
  public static readonly MENSAJE_CONFIRMACION_ACTIVAR_OTROS:string ="¿Está seguro de ACTIVAR el $?";
  public static readonly MENSAJE_CONFIRMACION_INACTIVAR_OTROS:string ="¿Está seguro de INACTIVAR el $?";
  public static readonly MENSAJE_CONFIRMACION_CONSERVA_ROL:string ="¿Está seguro de guardar los cambios, si tienes roles personalizados permanecerán en los modulos activos correspondientes.?";
  public static readonly MENSAJE_CONFIRMACION_CONSECUENCIAS_MODIFICAR:string ="¿Está seguro de guardar los cambios, esto podría afectar otras funcionalidades y usuarios del módulo?";
  public static readonly MENSAJE_CONFIRMACION_CONSECUENCIAS_ELIMINAR:string ="¿Está seguro de eliminar?";
  public static readonly MENSAJE_CONFIRMACION_CONSECUENCIAS_ELIMINAR_PRODUCTO:string ="¿Está seguro de eliminar?, si el producto es parte de otros productos o catálogos, también se quitará la referencia (sub producto) de dichos productos y/o catálogos.";
  public static readonly MENSAJE_CONFIRMACION_CONSECUENCIAS_ELIMINAR_COLABORADOR:string ="¿Está seguro de eliminar?, se ELIMINARÁ el usuario seleccionado.";
  public static readonly MENSAJE_CONFIRMACION_PAUSAR_SUBS:string ="¿Estás seguro de PAUSAR tu suscripión?. No podrás realizar ninguna acción en la plataforma. Conservarás los cargos generados por el período de facturación en curso. Para pausar digita el código: ";
  public static readonly MENSAJE_CONFIRMACION_ACTIVAR_SUBS:string ="¿Estás seguro de ACTIVAR tu suscripción?";
  public static readonly MENSAJE_CONFIRMACION_ELIMINAR_SUBS:string =`¿Estás seguro de ELIMINAR tu empresa?.
  Se eliminará tu empresa y su configuración dentro de las siguientes 24 horas.
  Automáticamente se cerrará tu sesión, vuelve a iniciar sesión para configurar una nueva empresa.
  Si tienes costos acumulados se adjuntarán a la nueva empresa.
  Si quieres eliminar tu cuenta personal envía un correo electrónico a `+DatosPlataforma.EMAIL_EMPRESA+` .
  Para confirmar digita el código: `;

  public static readonly MENSAJE_CONFIRMACION_CERRAR_VENTANA:string ="¿Está seguro de cerrar la ventana?";
  public static readonly MENSAJE_STEPS:string ="Debe completar este paso para poder avanzar.";
  public static readonly MENSAJE_CANTIDAD_USUARIOS_DEFECTO:string ="Este modulo tiene $ usuarios por defecto incluidos en el costo.";
  public static readonly MENSAJE_CANTIDAD_USUARIOS_INCREMENTO:string ="Está incrementando el número de usuarios, esto INCREMENTARÁ el costo en la facturación actual. Confirme para continuar.";
  public static readonly MENSAJE_CANTIDAD_USUARIOS_DECREMENTO:string ="Está reduciendo el número de usuarios, esta acción REDUCIRÁ el costo en la facturación POSTERIOR a la facturación en curso. Confirme para continuar.";
  public static readonly MENSAJE_CANTIDAD_USUARIOS_DECREMENTO_UPDATE:string ="Tiene pendiente una reducción de cantidad de usuarios, ¿quiere acumular la reducción?. Confirme para continuar.";
  public static readonly MENSAJE_CANTIDAD_USUARIOS_DECREMENTO_AVISO:string ="Tiene pendiente una reducción de cantidad de usuarios, quite la reducción para continuar.";
  public static readonly MENSAJE_PENDIENTE_REDUCCION_USUARIOS:string ="PENDIENTE: Reducción de $ usuario(s).";
  public static readonly MENSAJE_CANTIDAD_USUARIOS_MATRICULADOS:string =`Este modulo ya tiene $ usuarios registrados, no se puede desactivar el módulo ni reducir el # de usuarios,
  solo aumentarlos. Si quiere reducir el # de usuarios, primero quite el acceso a los colaboradores que tienen asignado el módulo.`;
  public static readonly MENSAJE_PREGUNTA_USUARIOS_MODULOS:string =`Está inhabilitando el módulo,
  esta acción REDUCIRÁ el costo en la facturación POSTERIOR a la facturación en curso. Confirme para continuar.
  `;
  public static readonly MENSAJE_USUARIOS_MODULOS_PENDIENTE_DESACTIVADO:string =`El módulo seleccionado esta inhabilitando y pendiente de desactivación.`;
  public static readonly MENSAJE_NINGUN_MODULO:string =`No se pudo GUARDAR, tienes 0 modulo(s) seleccionados.`;
  public static readonly OPCION_EXISTENTE:string ="Ya existe la opción.";
  public static readonly MSJ_DOBLE_MESA:string ="Mesa ya seleccionada.";
  public static readonly MSJ_MEDIO_PAGO_OBLIGATORIO:string ="Debe seleccionar mínimo un medio de pago .";
  public static readonly MSJ_SIN_MESA:string ="Debe elegir una(s) mesa(s) antes.";
  

}

export class Msjs_Validations{
  public static readonly MSJ_VAL_CAMPO_VACIO:string ="El campo esta vacío.";
  public static readonly MSJ_VAL_CAMPO_VACIO_FORMATO_INCORRECTO:string ="El campo esta vacío o la fecha no es válida.";
  public static readonly MSJ_VAL_FECHA_MAXIMA_INCORRECTA:string ="Debes ser mayor de edad.";
  public static readonly MSJ_VAL_FECHA_MINIMO_INCORRECTA:string ="Debes ingresar una fecha mínima válida.";
  public static readonly MSJ_VAL_SOLO_NUMEROS:string ="Solo se permiten números.";
  public static readonly MSJ_VAL_SOLO_SERIE_BOLETA:string ="Se permite una serie de boleta B(más máximo 8 dígitos).";
  public static readonly MSJ_VAL_SOLO_SERIE_FACTURA:string ="Se permite una serie de factura F(más máximo 8 dígitos).";
  public static readonly MSJ_VAL_SOLO_HORAS_MINUTOS:string ="Solo se permiten horas y minutos.";
  public static readonly MSJ_VAL_MIN6_DIGITOS:string ="Mínimo 6 dígitos.";
  public static readonly MSJ_VAL_MAX6_DIGITOS:string ="Máximo 6 dígitos.";
  public static readonly MSJ_VAL_MIN9_DIGITOS:string ="Mínimo 9 dígitos.";
  public static readonly MSJ_VAL_MAX9_DIGITOS:string ="Máximo 9 dígitos.";
  public static readonly MSJ_VAL_MIN8_DIGITOS:string ="Mínimo 8 dígitos.";
  public static readonly MSJ_VAL_MIN1_DIGITOS:string ="Mínimo 1 dígito.";
  public static readonly MSJ_VAL_MAX2_DIGITOS:string ="Máximo 2 dígitos.";
  public static readonly MSJ_VAL_MAX4_DIGITOS:string ="Máximo 4 dígitos.";
  public static readonly MSJ_VAL_MAX8_DIGITOS:string ="Máximo 8 dígitos.";
  public static readonly MSJ_VAL_REQ9_DIGITOS_MOVIL:string ="Ingrese los 9 dígitos de su número móvil.";
  public static readonly MSJ_VAL_REQ6_DIGITOS_CODIGO:string ="Ingrese los 6 dígitos de tu código.";
  public static readonly MSJ_VAL_SOLO_LETRAS_1PALABRA: string = 'Solo letras, sin espacios en blanco.';
  public static readonly MSJ_VAL_SOLO_LETRAS_2PALABRAS: string = 'Solo letras y un espacio en blanco entre nombres.';
  public static readonly MSJ_VAL_SOLO_LETRAS_NUMEROS_CON_ESPACIO_INTERMEDIO: string = 'Solo letras y números con espacio en blanco entre nombres.';
  public static readonly MSJ_VAL_SOLO_LETRAS_NUMEROS_PUNTO: string = 'Solo letras, números y el punto.';
  public static readonly MSJ_VAL_SOLO_LETRAS_Y_NUMEROS: string = 'Solo letras y/o números, sin espacios al inicio o final.';
  public static readonly MSJ_VAL_SOLO_EMAIL: string = 'Correo electrónico no válido.';
  public static readonly MSJ_VAL_PASSWORD: string = 'Contraseña no válida (debe contener mínimo 1 mayúscula, 1 caracter especial, 1 número).';
  public static readonly MSJ_HINT_PASSWORD: string = 'Mínimo 8 caractéres (debe contener 1 mayúscula, 1 caracter especial, 1 número).';
  public static readonly MSJ_HINT_PASSWORD_IGUAL: string = 'La contraseña debe ser igual a la contraseña anterior.';
  public static readonly MSJ_HINT_CODIGO_IGUAL: string = 'El código ingresado es distinto.';
  public static readonly MSJ_VAL_SOLO_URL: string = 'Solo se permite una URL válida.';

  //MESAJES INFO
  public static readonly MSJ_IMPUESTO: string = 'Es el impuesto general aplicado a las ventas.';
  public static readonly MSJ_IMPUESTO_NO_EXISTE: string = 'No existe una tasa de impuesto IGV configurado.';
  public static readonly MSJ_SERVICIO: string = 'Es la tasa aplicada por servicio de consumo.';
  public static readonly MSJ_MINIMO_PRODUCTO_PREPAGO: string = 'Se debe tener más de un producto para realizar un PREPAGO, si tiene un solo producto realize un pago COMPLETO.';
  public static readonly MSJ_MINIMO_PRODUCTO_TRASLADADO: string = 'El producto ya esta trasladado.';
  public static readonly MSJ_MINIMO_CANTIDAD_PRODUCTO: string = 'Ya tiene la cantidad minima de producto.';
  public static readonly MSJ_ERROR_MONTO_TOTAL: string = 'El monto total ingresado no coincide con el monto total a pagar.';

  //MENSAJES MAX_LENGTH_BD
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_1:string ="Mínimo 1 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3:string ="Mínimo 3 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_4:string ="Mínimo 4 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MAX_3:string ="Máximo 3 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MAX_4:string ="Máximo 4 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_6:string ="Mínimo 6 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_5:string ="Mínimo 5 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MAX_6:string ="Máximo 6 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8:string ="Mínimo 8 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MAX_8:string ="Máximo 8 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MAX_12:string ="Máximo 12 caracteres.";


  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_9:string ="Mínimo 9 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MAX_9:string ="Máximo 9 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_10:string ="Mínimo 10 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MAX_10:string ="Máximo 10 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MAX_15:string ="Máximo 15 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_15:string ="Mínimo 15 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_11:string ="Mínimo 11 dígitos.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MAX_11:string ="Máximo 11 dígitos.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8DIGITOS:string ="Mínimo 8 dígitos.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8CARACTERES:string ="Mínimo 8 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MAX_8DIGITOS:string ="Máximo 8 dígitos.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_10DIGITOS:string ="Mínimo 10 dígitos.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MAX_10DIGITOS:string ="Máximo 10 dígitos.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MAX_9DIGITOS:string ="Máximo 9 dígitos.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_9DIGITOS:string ="Míximo 9 dígitos.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MIN_1DIGITOS:string ="Mínimo 1 dígito.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MAX_5DIGITOS:string ="Máximo 5 dígitos.";
  public static readonly MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30:string ="Máximo 30 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_SMALL_MAX_50:string ="Máximo 50 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_MEDIUM_MAX_100:string ="Máximo 100 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_LARGE_MAX_250:string ="Máximo 250 caracteres.";
  public static readonly MSJ_VAL_DB_LENGTH_X_LARGE_MAX_400:string ="Máximo 400 caracteres.";
}

export class Length_Database{
  public static readonly DB_LENGTH_X_SMALL_6: number =6;
  public static readonly DB_LENGTH_X_SMALL_MIN_1: number =1;
  public static readonly DB_LENGTH_X_SMALL_MIN_3: number =3;
  public static readonly DB_LENGTH_X_SMALL_MIN_4: number =4;
  public static readonly DB_LENGTH_X_SMALL_MIN_5: number =5;
  public static readonly DB_LENGTH_X_SMALL_MIN_9: number =9;
  public static readonly DB_LENGTH_X_SMALL_MIN_8: number =8;
  public static readonly DB_LENGTH_X_SMALL_MAX_8: number =8;
  
  public static readonly DB_LENGTH_X_SMALL_MAX_12: number =12;
  public static readonly DB_LENGTH_X_SMALL_MIN_10: number =10;
  public static readonly DB_LENGTH_X_SMALL_MAX_10: number =10;
  public static readonly DB_LENGTH_X_SMALL_MAX_15: number =15;
  public static readonly DB_LENGTH_X_SMALL_MIN_11: number =11;
  public static readonly DB_LENGTH_X_SMALL_MAX_11: number =11;
  public static readonly DB_LENGTH_X_SMALL_MAX_30: number =30;
  public static readonly DB_LENGTH_SMALL_MAX_50: number =50;
  public static readonly DB_LENGTH_MEDIUM_MAX_100: number =100;
  public static readonly DB_LENGTH_LARGE_MAX_250: number =250;
  public static readonly DB_LENGTH_X_LARGE_MAX_400: number =400;
}

export class PatternsRegex {
  //forms
  public static onlyCharsTwoWordsSpanish: RegExp = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/i;
  public static onlyCharsOneWordsSpanish: RegExp = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/i;
  public static onlyCharsTwoWordsNoSpanish: RegExp = /^\w+\s?\w+?$/i;
  public static onlyCharsOneWordsNoSpanish: RegExp = /^\w+$/i;
  public static onlyDateddmmyyyy: RegExp = /^(3[01]|[12][0-9]|0?[1-9])\/(1[0-2]|0?[1-9])\/(?:[0-9]{2})?[0-9]{4}$/g;
  public static onlyImages: RegExp = /().(jpe?g|gif|png|bmp)/i;
  public static onlyGoodCharsWithNumbers: RegExp = /^\w[a-zA-Z0-9_]*$/i;
  public static onlyUrl: RegExp = /^(https?:\/\/)?(([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}(:\d+)?(\/[-a-zA-Z\d%_.~+]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(#[\w-]*)?$/;
  public static onlyGoodCharsWithNumbersAndSpace: RegExp = /^[A-Za-zÁáÉéÍíÓóÚúÜüÑñ0-9]+[A-Za-zÁáÉéÍíÓóÚúÜüÑñ0-9 _]*[A-Za-zÁáÉéÍíÓóÚúÜüÑñ0-9]+$/;
  public static onlyEmail: RegExp = /^\w+\.?\w+?\.?\w+?@\w+\.[a-z]{2,6}$/
  //public static onlyEmail: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  public static onlyDecimalIntegerSigned: RegExp = /^((\d*)|-(\d*))\.?\d*$/;
  public static onlyDecimalIntegerUnsigned: RegExp = /^(\d*)\.?\d*$/;
  public static onlyIntegerSigned: RegExp = /^\-?\d+$/;
  public static onlyIntegerUnsigned: RegExp = /^\d+$/;
  public static onlyPassportOrDNIOrCE: RegExp = /^(?:\d{8}|\d{9}|[a-zA-Z0-9]{8,12})$/;

  public static onlyPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-]).{8,30}$/;
  public static onlyNumberPhone = /^\d{9}$/;
  public static onlyNumberDNI = /^\d{8}$/;
  public static onlyHour = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  public static onlyWordSpanishWithSpaceBlank = /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ ]*$/;
  public static onlyWordSpanishNumberWithSpaceBlank = /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ0-9 ]+$/;
  public static onlyWordSpanishNumberPoint = /^[a-zA-Z0-9]+[a-zA-Z0-9._]*[a-zA-Z0-9]+$/;
  public static onlySerieBoleta = /^B\d{4,8}$/;
  public static onlySerieFactura =/^F\d{4,8}$/;
}

export abstract class FormsConst {
  static readonly TIPO_DOCUMENTO: string[] = ['DNI', 'PASSAPORTE'];
  static readonly SEXO: string[] = ['MASCULINO', 'FEMENINO'];
}

export  abstract class MsjFirebase {
  static readonly ERROR_REGISTRO: string = 'auth/email-already-in-use';
  static readonly ERROR_USUARIO: string = 'auth/user-not-found';
  static readonly ERROR_PASSWORD: string = 'auth/wrong-password';

}

export  abstract class MsjEstado {
  static readonly ACTIVO: string = 'Activo';
  static readonly INACTIVO: string = 'Inactivo';
  static readonly ABIERTO: string = 'Abierto';
  static readonly CERRADO: string = 'Cerrado';
}

export  abstract class CriteriosBusqueda {
  static readonly nombres: string = 'nombres';
  static readonly numero_documento: string = 'numero_documento';
  static readonly codigo: string = 'codigo';
  static readonly estado: string = 'estado';
  static readonly mesa: string ='mesa';
  static readonly usuario_creacion:string ='usuario_creacion';

}

export abstract class SistType {
  static readonly LOGIN: string = 'login';
  static readonly REGISTRO: string = 'registro';
}

export abstract class SistOperation {
  static readonly NUEVO: string = 'nuevo';
  static readonly EDITAR: string = 'editar';
  static readonly EDITAR_PEDIDO: string = 'editar_pedido';
  static readonly ELIMINAR: string = 'eliminar';
  static readonly ACTIVAR: string = 'activar';
  static readonly ACTIVAR_PERSONA: string = 'activar_persona';
  static readonly SUSPENDER_PERSONA: string = 'suspender_persona';
  static readonly SUSPENDER: string = 'suspender';
  static readonly GESTIONAR_PASSWORD: string = 'gestionar_password';
  static readonly VER: string = 'ver';
  static readonly VER_IMAGEN: string = 'ver_imagen';
  static readonly VER_HISTORIAL: string = 'ver_historial';
  static readonly IMPRIMIR: string = 'imprimir';
  static readonly RESPONDER_SOLICITUD: string = 'responder_solicitud';
  static readonly AGREGAR: string = 'agregar';
  static readonly RETIRAR: string = 'retirar';
  static readonly REINGRESAR: string = 'reingresar';
  static readonly SOLICITAR: string = 'solicitar';
  static readonly PROCESAR_SOLICITUD: string = 'procesar_solicitud';
  static readonly SOLICITAR_AGREGAR: string = 'solicitar_agregar';
  static readonly PROCESAR_AGREGAR: string = 'procesar_agregar';
  static readonly SOLICITAR_RETIRAR: string = 'solicitar_retirar';
  static readonly PROCESAR_RETIRAR: string = 'procesar_retirar';
  static readonly CERRAR: string = 'cerrar';
  static readonly ABRIR: string = 'abrir';
  static readonly PAGO_TOTAL: string = 'pago_total';
  static readonly PAGO_PARCIAL: string = 'pago_parcial';
  static readonly TRASPASAR_PRODUCTO: string = 'traspasar_producto';
  static readonly DIVIDIR_CUENTA: string = 'dividir_cuenta';


}

export abstract class TableTypes{
  static readonly GESTIONAR_COLABORADOR ='gestionar_colaborador';
  static readonly GESTIONAR_PRODUCTO ='gestionar_producto';
  static readonly GESTIONAR_CATALOGO ='gestionar_catalogo';
  static readonly AGREGAR_CATALOGO ='agregar_catalogo';
  static readonly LISTAR_CATALOGO ='listar_catalogo';
  static readonly LISTAR_PRODUCTO ='listar_producto';
  static readonly LISTAR_PRODUCTO_COMBO ='listar_producto_combo';
  static readonly LISTAR_PEDIDOS ='listar_pedidos';
  static readonly REGISTRAR_PEDIDO ='registrar_pedido';
  static readonly TRASPASAR_PEDIDO ='traspasar_pedido';
  static readonly LISTAR_PEDIDOS_CERRADOS ='listar_pedidos_cerrados';
  static readonly LISTAR_PEDIDOS_CAJA ='listar_pedidos_caja';
  static readonly LISTAR_PEDIDOS_C_INCIDENCIA ='listar_pedidos_c_incidencia';

  static readonly CALC_EFECTIVO ='calculadora_efectivo';
  static readonly CALC_TARJETA ='calculadora_tarjeta';
  static readonly CALC_YAPE ='calculadora_yape';
  static readonly CALC_PLIN ='calculadora_plin';
  static readonly CALC_TRANSFERENCIA ='calculadora_tranferencia';
  static readonly CALC_PROPINA ='calculadora_propina';

  static readonly CHAR_EFECTIVO ='keyboard_efectivo';
  static readonly CHAR_TARJETA ='keyboard_tarjeta';
  static readonly CHAR_YAPE ='keyboard_yape';
  static readonly CHAR_PLIN ='keyboard_plin';
  static readonly CHAR_TRANSFERENCIA ='keyboard_tranferencia';
  static readonly CHAR_PROPINA ='keyboard_propina';

  static readonly EMITIR_BOLETA ='emitir_boleta';
}
export abstract class EventoPaginacion{
  static readonly PAGINA_PRIMERA ='pagina_primera';
  static readonly PAGINA_ANTERIOR ='pagina_anterior';
  static readonly PAGINA_SIGUIENTE ='pagina_siguiente';
  static readonly PAGINA_ULTIMA ='pagina_ultima';
  static readonly CAMBIO_TAMANIO_PAGINA ='cambio_tamanio_pagina';
}
export abstract class TamanioPagina{
  static readonly LISTA_TAMANIOS: number[] =[5,10,20];
}


export abstract class EstadoRegistro {
  static readonly REGISTRO_USUARIO:number =0;
  static readonly REGISTRO_EMPRESA:number =1;
  static readonly REGISTRO_MODULO:number =2;
  static readonly REGISTRO_COMPLETO_ADMIN:number =3;
  static readonly REGISTRO_COMPLETO_USUARIO:number =4;
}
export abstract class rolMaster {
  static readonly ADMINSITRADOR_GENERAL:string ='administrador general';
}

interface ITipoArchivoFormato {
  tipo: string;
  tipos_archivos: string[];
}

import { IHorario } from "../components/horario-trabajo/horario.model";
import { MediosPago } from "../components/procesos/gestion-medios-pago/models/MediosPago"

export class horarioTrabajo{
  public static horarioGeneral: IHorario=
  {
    dias:['l','m','x','j','v','s'],
    hora_inicio:'07:00',
    hora_final:'16:00'
  }
}



export class TipoArchivoFormato{
  public static tipoArchivoFormato: ITipoArchivoFormato[]=[
    {
      tipo:'image',
      tipos_archivos:['image/jpg', 'image/png', 'image/jpeg', 'image/gif', 'image/bmp']
    },
  ]
}

export class ListaMediosPago{
  public static listaMediosPago:MediosPago[]=[
    {
      codigo_medio:'01',
      nombre_medio:'Efectivo',
      es_vigente:false
    },
    {
      codigo_medio:'02',
      nombre_medio:'Tarj. Créd./Déb.',
      tasa_medio:0,
      es_vigente:false
    },
    {
      codigo_medio:'03',
      nombre_medio:'Transferencia',
      es_vigente:false
    },
    {
      codigo_medio:'04',
      nombre_medio:'Yape',
      es_vigente:false
    },
    {
      codigo_medio:'05',
      nombre_medio:'Plin',
      es_vigente:false
    },
    {
      codigo_medio:'06',
      nombre_medio:'Propina',
      es_vigente:true
    }
  ]
}

interface ITipoProducto{
  codigo_producto:string,
  nombre_producto:string
}
 export class ListaTipoProducto{
  public static listaTipoProducto: ITipoProducto[]=[
    {
      codigo_producto:'01',
      nombre_producto:'Bebidas'
    },
    {
      codigo_producto:'02',
      nombre_producto:'Piqueos'
    },
    {
      codigo_producto:'03',
      nombre_producto:'Cócteles'
    },
    {
      codigo_producto:'04',
      nombre_producto:'Cervezas'
    },
    {
      codigo_producto:'05',
      nombre_producto:'Platos'
    },
    {
      codigo_producto:'06',
      nombre_producto:'Carnes y parrillas'
    },
    {
      codigo_producto:'07',
      nombre_producto:'Combo'
    },



  ]
 }
