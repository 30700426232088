import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MsjEstado, SistOperation, TableTypes } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import {
  Msjs_Validations,
  PatternsRegex,
  Length_Database,
  Msjs,
  EstadoRegistro,
} from '../../../cons/common';
import { CONFIGURACION, PATH_API_FILES } from 'src/app/shared/cons/config';
import { CColaborador } from './models/ccolaborador.model';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { Utils } from 'src/app/shared/helpers/utils';
import { FileTypes } from 'src/app/shared/types/file-types';
import { CHorario } from '../../horario-trabajo/horario.model';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { NgForm, NgModel, Validators } from '@angular/forms';
import { CModuloRol } from './models/cmodulo-rol.model';
import { IModuloRol } from './models/modulo-rol.model';
import { CRolAcceso, IRolAcceso } from 'src/app/shared/models/item.model';
import { UIService } from 'src/app/shared/services/ui.services';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { Subscription } from 'rxjs';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { HorarioService } from '../../horario-trabajo/horario.service';
import { UploadService } from '../../upload/upload.service';
import { GestionColaboradorBlService } from './services/services.bl/gestion-colaborador.bl.service';
import { CModuloRolSeleccionado } from './models/modulo-rol-seleccionado';
import { BuscarService } from '../../buscar/buscar.service';
import { CBuscar } from '../../buscar/CBuscar';
import { ITableEvento } from '../../table/ITableEvento';
import { ICantidadModuloSel } from 'src/app/shared/models/ICantidadModuloSel';
import { AuthService } from 'src/app/shared/auth/auth.service';

@Component({
  selector: 'app-gestion-colaborador',
  templateUrl: './gestion-colaborador.component.html',
  styleUrls: ['./gestion-colaborador.component.scss']
})

@AutoUnsubscribe

export class GestionColaboradorComponent implements OnInit {
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  readonly Msjs: typeof Msjs = Msjs;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly SistOperation: typeof SistOperation = SistOperation;


  onlyNumbers = Utils.InputOnlyNumbers;
  lSexo: any[] = CONFIGURACION.SEXO;
  lTipo_documento: any[] = CONFIGURACION.TIPO_DOCUMENTO;
  titleModal:string='';
  isLoading:boolean=false;
  maxDate = new Date();
  minDate = new Date();
  readonly Icons: typeof Icons = Icons;
  ccolaborador = new CColaborador();
  contrasenia2!:string;
  errorContrasenia2:boolean =false;
  msjEstado:string  ='';

  pathFileAPI = PATH_API_FILES.USER_IMAGE_URL;
  fileType:string = FileTypes.IMAGE;
  label:string='Foto :';
  esModoEdicion:boolean = false;
  esModoVer:boolean = false;
  estadoColaboradorEdicion:boolean=false;
  sizeMaxHeightWidth:string='alto 200px - ancho 200px';

  showSpinnerHorario2:boolean =false;
  cHorario2 = new CHorario();
  modulos:CModuloRol[] = [];
  rolesDisponibles:IRolAcceso[]=[];
  hide:boolean = true;
  hide2:boolean = true;
  horarioTieneErrorSb!:Subscription;
  horarioTieneError:boolean = false;

  modalSb!: Subscription;
  colaboradorSb!:Subscription;
  //file_upload
  files_upload_subs!:Subscription;
  files_upload:File[] = [];

  //cuando carga las imagenes en modo edicion
  files_uploaded:any[]=[];

  modulosRolesSelTodos:CModuloRolSeleccionado[]=[];
  moduloRoleSelec:CModuloRolSeleccionado = new CModuloRolSeleccionado();
  //validacion correo
  correoExistente:boolean =false;
  correoExistenteCta:boolean =false;
  tieneRoles:boolean =true;

  key:string = TableTypes.GESTIONAR_COLABORADOR;
  busqueda:CBuscar = new CBuscar();

  cantidad_modulos_seleccionados:ICantidadModuloSel[]=[];



  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef: MatDialogRef<GestionColaboradorComponent>,
    private registroService: RegistroService,
    private uiService:UIService,
    private readonly spinnerPageService: SpinnerPageService,
    private horarioService: HorarioService,
    private uploadService:UploadService,
    private gestionColaboradorBlService: GestionColaboradorBlService,
    private buscarService: BuscarService, //sirve para recargar cuando se guarda
    private authService:AuthService

  ) { }

  ngOnInit(): void {

    //llamado por el componente upload
    this.files_upload_subs = this.uploadService.files_upload$.subscribe((uploads)=>{
      this.files_upload = uploads.images_upload;
      //edita cuando eliminas una imagen en modo EDICION
      if(this.esModoEdicion){
        this.files_uploaded =  uploads.images_uploaded;
      }
    });

    //si el formulario hijo horario tiene errores
    this.horarioTieneErrorSb = this.horarioService.hasErrorForm.subscribe((error)=>{
      this.horarioTieneError =  error;
    });

    switch(this.datoPasado.operacion){
      case SistOperation.NUEVO:{
        this.titleModal = 'Nuevo Colaborador';
        //para limitar el numero de usuarios que deben estar por modulo
        this.cantidad_modulos_seleccionados = this.datoPasado.data as ICantidadModuloSel[];
        break;
      }
      case SistOperation.EDITAR:{
        this.titleModal = 'Editar Colaborador';
        this.esModoEdicion=true;//necesario tambien para mostrar imagenes para editar
        this.cargarDatosEdicion();
        //para limitar el numero de usuarios que deben estar por modulo
        this.cantidad_modulos_seleccionados = this.datoPasado.data.cantidad_modulos_seleccionados as ICantidadModuloSel[];
        break;

      }
      case SistOperation.VER:{
        this.esModoVer = true;

        this.titleModal = 'Ver Colaborador';
        this.esModoEdicion=true;//necesario tambien para mostrar imagenes para editar
        this.cargarDatosEdicion();
      }
    }

    this.maxDate = new Date();
    this.minDate = new Date();
    this.minDate.setFullYear(1900);
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 14);

    this.cargarHorarioLaboral();
    this.cargarModulosRoles();

  }

  cargarDatosEdicion(){
    this.ccolaborador = {...this.datoPasado.data as CColaborador};
    this.ccolaborador.modulo_rol.forEach((modulos: CModuloRolSeleccionado)=>{
      this.modulosRolesSelTodos.push(modulos);
    });
    this.cHorario2 = this.ccolaborador.horario_laboral;
    this.files_upload=[];
    this.uploadService.removeAllFiles();
    this.files_uploaded = this.ccolaborador.imagen==undefined?[]: Utils.ObjecToListToArray(this.ccolaborador.imagen);
    this.contrasenia2 = this.ccolaborador.password;
    this.errorContrasenia2 = false;
    this.tieneRoles = true;
    this.estadoColaboradorEdicion = this.ccolaborador.es_vigente;
    this.msjEstado = this.ccolaborador.es_vigente? MsjEstado.ACTIVO: MsjEstado.INACTIVO;


  }

  cargarModulosRoles(){
    this.registroService.empresa.lista_modulos_seleccionados.forEach((mod: { codigo: string; nombre: string; roles: any; })=>{
      if(mod){
        let modulo_rol:IModuloRol= {
          codigo_modulo: mod.codigo,
          nombre_modulo: mod.nombre,
          rol_modulo: Utils.GetArrayMenuRol(mod.roles)
        }

        this.modulos.push(modulo_rol);
      }
    });

    //nuevo colaborador inicia estado por defecto en true
    if(!this.esModoEdicion){
      this.ccolaborador.es_vigente=true;
      this.msjEstado = MsjEstado.ACTIVO
    }


  }

  moduloSelect(codigoModulo: string) {
    //validando cantidad maxima de usuarios
    if (this.cantidad_modulos_seleccionados.length > 0) {
      const moduloSeleccionado = this.cantidad_modulos_seleccionados
        .filter(moduloRol => moduloRol.codigo == codigoModulo)

      if (moduloSeleccionado[0].pendiente_anulacion_modulo) {
        let msj = Msjs.MENSAJE_USUARIOS_MODULOS_PENDIENTE_DESACTIVADO;
        this.uiService.ShowSnackBar(SnackBarType.ERROR, msj, 5000);
        this.moduloRoleSelec.codigo_modulo = null;
        this.rolesDisponibles = [];
        return;
      }

      if (moduloSeleccionado[0].cantidad_total_usuarios_matriculados ==
        moduloSeleccionado[0].cantidad_total_usuarios_permitidos
      ) {
        let msj = Msjs.MSJ_CANTIDAD_MAXIMA_USUARIOS;
        let listaCadena: string[] = [moduloSeleccionado[0].nombre,
        moduloSeleccionado[0].cantidad_total_usuarios_permitidos.toString()];
        msj = Utils.formatString(msj, listaCadena);
        this.uiService.ShowSnackBar(SnackBarType.ERROR, msj, 5000);
        this.moduloRoleSelec.codigo_modulo = null;
        this.rolesDisponibles = [];
        return;
      }
    }
    let moduloSel = this.modulos.filter((modx) => modx.codigo_modulo === codigoModulo)[0];
    this.moduloRoleSelec = {
      codigo_modulo: moduloSel.codigo_modulo,
      nombre_modulo: moduloSel.nombre_modulo,
      acceso: [],// en blanco para ser completado al seleccionar el rol
      rol: '' //en blanco para ser completado al seleccionar el rol
    }
    this.rolesDisponibles = [];
    this.rolesDisponibles = this.modulos.filter((modx) => modx.codigo_modulo === codigoModulo)[0].rol_modulo;

  }

  rolSelect(nombreRol:string){
    //validando si el modulo ya esta agregado
    let modulosRestantes:CModuloRolSeleccionado = this.modulosRolesSelTodos.filter(mod=>mod.codigo_modulo== this.moduloRoleSelec.codigo_modulo)[0];
    if(modulosRestantes){
      this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs.MSJ_INFO_DUPLICIDAD_MODULOS, 3000);
      const indiceReemplazo = this.modulosRolesSelTodos.findIndex(obj=>obj.codigo_modulo===this.moduloRoleSelec.codigo_modulo);
      if(indiceReemplazo!==-1){
        const rolReemplazo = this.rolesDisponibles.filter((rol)=>rol.rol== nombreRol)[0];
        this.moduloRoleSelec.rol= rolReemplazo.rol;
        this.moduloRoleSelec.acceso = rolReemplazo.acceso;
        this.moduloRoleSelec.personalizado = rolReemplazo.personalizado==null?false: rolReemplazo.personalizado?true:false;
        //reemplazando rol
        this.modulosRolesSelTodos.splice(indiceReemplazo,1,{...this.moduloRoleSelec})
      }
    }else{
      //agregando
      const rolSeleccionado = this.rolesDisponibles.filter((rol)=>rol.rol== nombreRol)[0];
      this.moduloRoleSelec.rol =  rolSeleccionado.rol;
      this.moduloRoleSelec.acceso =  rolSeleccionado.acceso;
      this.moduloRoleSelec.personalizado =  rolSeleccionado.personalizado==null?false: rolSeleccionado.personalizado?true:false;
      this.modulosRolesSelTodos.push({...this.moduloRoleSelec});
    }
    this.ccolaborador.modulo_rol = this.modulosRolesSelTodos;
    if(this.modulosRolesSelTodos.length>0){
      this.tieneRoles=true;
    }
    this.moduloRoleSelec.codigo_modulo='';
    this.rolesDisponibles = [];
  }

  onSelectChipModRol(moduloRol: CModuloRolSeleccionado, f:NgForm){
    let modulosRestantes:CModuloRolSeleccionado[] = this.modulosRolesSelTodos.filter(mod=>mod.codigo_modulo!= moduloRol.codigo_modulo);
    if(modulosRestantes.length>0){
      this.modulosRolesSelTodos = modulosRestantes;
      this.tieneRoles = true;
    }
    else{
      this.modulosRolesSelTodos = [];
      this.tieneRoles=false;
    }
    this.ccolaborador.modulo_rol = this.modulosRolesSelTodos;

  }

  onClose(){
    this.dialogRef.close();
  }

  isDateMaxError(){
    const selectedDate = new Date(this.ccolaborador.fecha_nacimiento);
    return selectedDate > this.maxDate?true:false;
  }

  isDateMinError(){
    const selectedDate = new Date(this.ccolaborador.fecha_nacimiento);
    return selectedDate < this.minDate?true:false;
  }

  cargarHorarioLaboral(){
    if(this.registroService.empresa){
      if(this.datoPasado.operacion==SistOperation.NUEVO){
        this.cHorario2 = {...this.registroService.empresa.horario_trabajo_gral};
       }
    }
  }

  validarPassword(){
    this.errorContrasenia2 =false
    if(this.ccolaborador.password!=this.contrasenia2){
      this.errorContrasenia2 =true;
    }
  }
  onChangeEstado(event:any){
    if(event.checked){
      this.msjEstado = MsjEstado.ACTIVO
    }
    else{
      this.msjEstado = MsjEstado.INACTIVO
    }
  }

  validarCorreo(f:NgForm){
    this.correoExistente = false;
    this.correoExistenteCta = false;
    const correoInput = f.form.get('correo');
    if(correoInput && correoInput.valid){
      this.gestionColaboradorBlService.validarCorreoColaborador(this.ccolaborador.correo_electronico).then(res=>{
        if(res.tx){
          this.correoExistente = true;
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_SIGNUP_ERROR_EMAIL_USADO, 3000);
        }

      }).then(()=>{
        this.authService.checkIfEmailExists(this.ccolaborador.correo_electronico)
        .then(exists => {
          if(exists.length>0){
            this.correoExistenteCta = true;
            this.correoExistente = true;
            this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_SIGNUP_ERROR_EMAIL_USADO_CTA, 3000);
          }
        }).catch(error =>{ 
            this.uiService.ShowSnackBar(SnackBarType.ERROR, error, 3000);
          });
      }); ;
    }

  }

  Limpiar(f:NgForm){
    this.modulosRolesSelTodos = new Array();
    this.ccolaborador = new CColaborador();
    this.cargarHorarioLaboral();
    this.cargarModulosRoles();

  }

  onSubmit(f:NgForm){
    if(!f.valid){
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_FORMULARIO, 3000);
    }else{
      //si formulario hijo tiene error
      if(this.horarioTieneError){
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_FORMULARIO, 3000);
        return;
      }
      if(this.correoExistente){
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_SIGNUP_ERROR_EMAIL_USADO, 3000);
        return;
      }
      if(this.correoExistenteCta){
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_SIGNUP_ERROR_EMAIL_USADO_CTA, 3000);
        return;
      }
      if(!this.tieneRoles){
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_FALTA_ROL, 3000);
        return;
      }
      if(this.errorContrasenia2){
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs_Validations.MSJ_HINT_PASSWORD_IGUAL, 3000);
        return;
      }
      this.modalSb= this.uiService.showModalQuestions({title:Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon:Icons.QUESTION_MARK})
      .subscribe(result=>{
        if(result){
          this.ccolaborador.horario_laboral=this.cHorario2;
          //para busqueda
          this.ccolaborador.nombres_completo = Utils.CadenaToArray(this.ccolaborador.nombres+' '+this.ccolaborador.apellido_paterno+' '+this.ccolaborador.apellido_materno);
          this.spinnerPageService.show();
          if(!this.esModoEdicion){
            this.gestionColaboradorBlService.insertColaborador(this.ccolaborador, this.files_upload, this.pathFileAPI).then(res=>{
              if(res.tx){
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_CUENTA_CREADA_OK, 9000);
                this.spinnerPageService.hide();

              }else{
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);
              }
              //para retornar el valor y recargar la tabla princial siempre en cuando la pagina sea mayor a uno
              this.dialogRef.close({tx_reload:true});
            })
          }
          else{
            if(this.esModoEdicion){
              const colaboradorAnterior: CColaborador = {...this.datoPasado.data as CColaborador};
              this.spinnerPageService.show();
              this,this.gestionColaboradorBlService.updateColaborador(
                colaboradorAnterior,
                this.ccolaborador,
                this.files_upload,
                this.files_uploaded,
                this.pathFileAPI,
                this.estadoColaboradorEdicion).then(res=>{
                  if(res.tx){
                    this.uiService.closeModalGestionColaborador();
                    this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 7000);
                    this.spinnerPageService.hide();

                  }else{
                    this.spinnerPageService.hide();
                    this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);
                  }
              });
            }
          }
        }
      });
    }
  }
}
