import { ElementRef, Injectable } from "@angular/core";
import { Form, NgForm } from "@angular/forms";
import { IUsuario } from "../../dialog/models/usuario.model";
import { UsuarioDAService } from "../services.da/usuario.da.service";
import { CONFIGURACION } from "src/app/shared/cons/config";
import { Subscription } from 'rxjs';
import { AuthService } from "../../../auth/auth.service";
import { IImageUploaded } from "../../upload/IImageUploaded";
import { RegistroService } from "src/app/shared/services/registro.service";
import { CUsuario } from "../../dialog/models/cusuario.model";

@Injectable({
  providedIn: 'root'
})

export class UsuarioBLService{
  constructor(
    private usuarioService: UsuarioDAService,
    private authService:AuthService,
    private registroService: RegistroService,
  ){}

  lRoles_primarcas:any[] = CONFIGURACION.ROLES_PRIMARCAS;
  stateAuth$: Subscription = new Subscription();

  existFetchingUsuario(idUsuario:string){
    this.usuarioService.fetchUsuarioById(idUsuario);
  }

  insertNuevoUsuario( f:NgForm, files_upload:File[], pathFileAPI:string, usuario:CUsuario ){
    let usuarioN: IUsuario;
    return this.authService.getUserIDAsync().then(user=>{
      if(user)
        usuarioN ={
        id_usuario_cuenta:user?.uid,
        nombres: f.value.nombres,
        apellido_paterno: f.value.apPaterno,
        apellido_materno: f.value.apMaterno,
        fecha_nacimiento: new Date(usuario.fecha_nacimiento),        
        sexo: f.value.ddlSexo,
        telefono: f.value.telefMovil,
        tipo_documento: f.value.ddlTipoDocumento,
        numero_documento: f.value.numDocumento,
        codigo_departamento: f.value.ddlDepartamento,
        codigo_provincia: f.value.ddlProvincia,
        codigo_distrito: f.value.ddlDistrito,
        direccion: f.value.direccion,
        correo_electronico: user?.email,
        administrador: usuario.tipo_usuario== this.lRoles_primarcas[0].value??true,
        es_borrado:false,
        es_vigente:true,
        usuario_creacion: user?.email,
        tiene_empresa:false,
        tiene_modulos:false

      }
      return this.usuarioService.insertNuevoUsuario(usuarioN, files_upload, pathFileAPI);
    });

  }

  updateUsuario(f: NgForm, files_upload_actual: File[], files_upload_anterior: IImageUploaded[], pathFileAPI: string, usuarioO:CUsuario) {
    let usuario: IUsuario = this.generarUsuario(f, this.registroService.usuario, usuarioO);//consume el usuario persistente
    let addImagen: boolean = files_upload_actual.length > 0 ? true : false;//TODO, si en caso nunca cargo imagenes
    if (addImagen) {
      //cuando se tienen imagenes previamente subidas
      if(this.registroService.usuario.imagen){
        return this.usuarioService.updateUsuarioAniadirReemplazarImagen(usuario, files_upload_actual, files_upload_anterior, pathFileAPI);
      }
      //cuando no se tienen imagenes previamente subidas
      else{
        return this.usuarioService.updateUsuarioAniadirImagen(usuario, files_upload_actual, pathFileAPI);
      }
    }
    else {
      //cuando se tienen imagenes previamente subidas
      if(this.registroService.usuario.imagen){
        //cuando la lista retorna vacia, quiere decir que se quitaron todas las imagenes
        if(files_upload_anterior.length==0){
          return this.usuarioService.updateUsuarioQuitarImagen(usuario, files_upload_anterior, pathFileAPI);
        }else{
          return this.usuarioService.updateUsuarioNoModificarImagen(usuario);
        }

      }
      //cuando no se tienen imagenes previamente subidas
      else{
        return this.usuarioService.updateUsuarioNoModificarImagen(usuario);
      }
    }
  }

  generarUsuario(f:NgForm, usuarioRegistroServ:any, usuarioO:CUsuario){
    let usuario: IUsuario;
    usuario ={
      id_usuario_cuenta:usuarioRegistroServ.id_usuario,
      nombres: f.value.nombres,
      apellido_paterno: f.value.apPaterno,
      apellido_materno: f.value.apMaterno,
      fecha_nacimiento: new Date(usuarioO.fecha_nacimiento), 
      sexo: f.value.ddlSexo,
      telefono: f.value.telefMovil,
      tipo_documento: f.value.ddlTipoDocumento,
      numero_documento: f.value.numDocumento,
      codigo_departamento: f.value.ddlDepartamento,
      codigo_provincia: f.value.ddlProvincia,
      codigo_distrito: f.value.ddlDistrito,
      direccion: f.value.direccion,
      correo_electronico: usuarioRegistroServ.correo_electronico,
      administrador: usuarioRegistroServ.administrador,
      es_borrado:false,
      es_vigente:true,
      usuario_modificacion: usuarioRegistroServ.email,
      tiene_empresa:usuarioRegistroServ.tiene_empresa,
      tiene_modulos:usuarioRegistroServ.tiene_modulos,
      imagen: usuarioRegistroServ.imagen
    }
    return usuario;
  }
}
