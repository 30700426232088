<div fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title >
    <mat-icon class="icon">{{Icons.INFO}}</mat-icon>
    {{titleModal}}
  </div>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <section  style="margin-top: 15px;">
    <p class="texto-ordenado">
      Esta Política de privacidad describe cómo
     {{DatosPlataforma.NOMBRE_EMPRESA}} recopila, utiliza y comparte la información personal de los
     usuarios de la plataforma {{DatosPlataforma.NOMBRE_PLATAFORMA}}. Al utilizar esta plataforma, usted acepta esta Política de privacidad.
    </p>
    <ol>
      <li>
        <h3>Información personal que recopilamos</h3>
        <p class="texto-ordenado">Recopilamos información personal que usted nos proporciona al
          registrarse en la plataforma, como su nombre,
          dirección de correo electrónico, dirección postal y número de teléfono.</p>
        <p class="texto-ordenado">
          También podemos recopilar información técnica, como su dirección IP,
          información de navegación, tipo de dispositivo y sistema operativo, y
          otros datos similares.
        </p>
      </li>
      <li>
        <h3>Uso de la información personal</h3>
        <p class="texto-ordenado">Utilizamos la información personal que recopilamos para proporcionar y
          mejorar nuestros servicios en la plataforma, para personalizar su experiencia
          de usuario, para enviarle correos electrónicos relacionados con la plataforma y
          para fines de marketing y publicidad.</p>
          <p class="texto-ordenado">
            Podemos compartir su información personal con terceros proveedores de
            servicios que nos ayuden a operar la plataforma y prestar
            servicios a los usuarios, siempre y cuando estos terceros estén obligados a
            mantener la confidencialidad y seguridad de su información personal.
          </p>
      </li>
      <li>
        <h3>Cookies y tecnologías similares</h3>
        <p class="texto-ordenado">
          La plataforma puede utilizar cookies y tecnologías similares para recopilar
          información sobre el uso de la plataforma. Las cookies son pequeños archivos
          que se almacenan en su dispositivo cuando utiliza la plataforma.
          Utilizamos estas tecnologías para personalizar su experiencia de usuario y
          para recopilar información sobre cómo se utiliza la plataforma.
        </p>
      </li>
      <li>
        <h3>Seguridad de la información</h3>
        <p class="texto-ordenado">
          Nos comprometemos a proteger la información personal que recopilamos y
          utilizamos. Hacemos todo lo posible para proteger su información personal
          contra la pérdida, el mal uso y el acceso no autorizado,
          y para mantener la confidencialidad y seguridad de su información personal.
        </p>
      </li>
      <li>
        <h3>Derechos del usuario</h3>
        <p class="texto-ordenado">
          Usted tiene derecho a acceder, rectificar, actualizar y
          eliminar la información personal que nos ha proporcionado en la plataforma.
          También puede optar por no recibir correos electrónicos promocionales de
          nuestra parte en cualquier momento.
        </p>
      </li>
      <li>
        <h3>Cambios en nuestra política de privacidad</h3>
        <p class="texto-ordenado">
          Nos reservamos el derecho de actualizar esta Política de privacidad en cualquier
          momento. Si realizamos cambios importantes en esta Política de privacidad,
          le notificaremos por correo electrónico o mediante una publicación
          destacada en la plataforma.
        </p>
      </li>
      <li>
        <h3>Contacto</h3>
        <p class="texto-ordenado">
          Si tiene alguna pregunta o inquietud sobre esta Política de privacidad, no dude en ponerse en contacto con nosotros a través de {{ (DatosPlataforma.NOMBRE_PLATAFORMA).toLowerCase()}} +  {{DatosPlataforma.EMAIL_EMPRESA}}.
        </p>
      </li>

    </ol>
  </section>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Aceptar</button>
</mat-dialog-actions>
