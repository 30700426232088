import { Injectable } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subject, } from "rxjs/Subject";
import { Router } from "@angular/router";
import { StaticRoutes } from "src/app/shared/cons/route.class";
import { StateAuth } from "src/app/shared/models/state-auth.model";
import { UIService } from "src/app/shared/services/ui.services";
import { IUsuario } from "../components/dialog/models/usuario.model";
import { UsuarioDAService } from "../components/services/services.da/usuario.da.service";
import { tap, switchMap, first, catchError, map, filter } from 'rxjs/operators';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { RegistroService } from "src/app/shared/services/registro.service";
import { CookiesTokensService } from "../services/cookies.tokens.service";
import { TransaccionModel } from "../services/models/trasaccion.model";
import { AutoUnsubscribe } from "../helpers/decorators/AutoUnsubscribe";
import { UsuarioColaboradorBlService } from "../components/services/services.bl/usuarioColaborador.bl.service";
import { CColaborador } from "../components/procesos/gestion-colaborador/models/ccolaborador.model";
import { CloseCnxBDService } from "../services/closeCnxBD.service";
import { SnackBarType } from "../types/snackbar-type";
import { Msjs } from "../cons/common";
import { Utils } from "../helpers/utils"
import { setPersistence, getAuth, browserLocalPersistence, browserSessionPersistence, inMemoryPersistence, Auth } from 'firebase/auth';
import { AuthSessionService } from "./auth.session.service";
import { ISession } from "./interface/ISession";
import { SpinnerPageService } from "../components/spinner-page/spinner-page.service";
import { CONFIGURACION } from "../cons/config";

@AutoUnsubscribe

@Injectable({
  providedIn: 'root'
})



export class AuthService {
  authChangeCodes$ = new Subject<StateAuth>();//el subject emite un StateAuth
  userAuth$ = new Subject<IUsuario | null | CColaborador>();
  usuarioSub$!: Subscription;
  getUsuarioByIDSb!: Subscription;
  transaccion: TransaccionModel = new TransaccionModel();
  authStateSb!: Subscription;
  getUsuarioColaboradorSb!: Subscription;
  uniqueSession:ISession | null=null;
  authInstance: Auth;
  lRoles_primarcas: any[] = CONFIGURACION.ROLES_PRIMARCAS;

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private usuarioService: UsuarioDAService,
    private registroService: RegistroService,
    private cookiesService: CookiesTokensService,
    private usuarioColaboradorBlServices: UsuarioColaboradorBlService,
    private closeBdService: CloseCnxBDService,
    private uiService:UIService,
    private authSessionService: AuthSessionService,
    private spinnerPageService: SpinnerPageService,


  ) {
    this.manageAuthenticationState();
    /*
    firebase.auth.Auth.Persistence.LOCAL (Predeterminado): La autenticación persiste incluso si
    se cierra la ventana o pestaña del navegador. Es adecuado para aplicaciones de uso frecuente
    donde deseas que los usuarios permanezcan autenticados.

    firebase.auth.Auth.Persistence.SESSION: La autenticación persiste solo en la sesión actual o
    pestaña del navegador. Se pierde si se cierra la ventana o pestaña.

    firebase.auth.Auth.Persistence.NONE: La autenticación no persiste más allá de la duración
    de la página.
    */
    this.authInstance = getAuth();
    this.setPersistenceType('SESSION');//cambiar aqui para indicar el tipo de persistencia de la sesion
  }


  async setPersistenceType(type: string) {
    switch(type) {
      case 'LOCAL':
        await setPersistence(this.authInstance, browserLocalPersistence);
        break;
      case 'SESSION':
        await setPersistence(this.authInstance, browserSessionPersistence);
        break;
      case 'NONE':
        await setPersistence(this.authInstance, inMemoryPersistence);
        break;
      default:
        throw new Error('Invalid type');
    }
  }

  private manageAuthenticationState(): void {

    this.authStateSb = this.afAuth.authState.pipe(switchMap(user => {
        if (!user) {

          if (!this.router.url.includes('/inicio/')) {
            if(this.router.url.includes('/'+StaticRoutes.SP_CONSOLA)){
              this.router.navigate(['/' + StaticRoutes.SP_CONSOLA]);
            }
            else{
              this.router.navigate(['/inicio/' + StaticRoutes.TOUR]);
            }
            
          }
          this.authChangeCodes$.next({ uid: null, email: null });
          this.cookiesService.cleanUser();
          return EMPTY;

        } 
        else {

          this.authChangeCodes$.next({
            uid: user.uid,
            email: user.email,
            email_verified: user.emailVerified,
            is_autenticated: true
          }); 

          if (!user.emailVerified) {         
            return EMPTY;
          }else{
            //cambia el tipo de sesion a inMemory solo  para administradores de plataforma            
            //ini
              if(this.router.url.includes('/'+StaticRoutes.SP_CONSOLA)){
                this.setPersistenceType('NONE');//LA SESION SEA SOLO EN MEMORIA
              }
            //fin

            //iniciamos sesion                        
            return this.fetchUserOrColaboradorData(user.uid);
          } 
        }
      }),
      catchError(error => {
        console.error('Error managing auth state:', error);
        return [];
      })
    ).subscribe();
  }

  private fetchUserOrColaboradorData(uid: string): Observable<any> {
    this.registroService.usuario=null;
    //llamamos para cargar en cache, primera llamada
    this.usuarioService.fetchUsuarioById(uid);
    return this.usuarioService.usuario$.pipe(
      filter(usuario=> usuario!==undefined),
      switchMap(usuario => {
        if (usuario) {
          return this.handleExistingUser(usuario);
        }
        else{
          this.registroService.usuario = null;
          this.registroService.esSuperAdministrador = null;
          this.registroService.rolesModulosUsuarioColaborador = null;
          this.cookiesService.cleanUser();
          return this.handleUserAsColaborador(uid);
        }

      }),
      catchError(error => {
        console.error('Error fetching user or collaborator data:', error);
        this.userAuth$.next(null);
        this.cookiesService.cleanUser();
        return EMPTY;
      })
    );
  }

  private async handleExistingUser(usuario: IUsuario): Promise<Observable<any>>{
    //cambia el tipo de sesion a inMemory solo  para administradores de plataforma
    //ini
    if (!this.router.url.includes('/' + StaticRoutes.SP_CONSOLA)) {
       //verificamos la sesion, si existe lo cierra, sino existe la genera
       await this.verificarSessionActiva(usuario.id_usuario_cuenta);
    }
    //fin

    //set usuario administrador
    this.registroService.usuario = { ...usuario };
    this.registroService.esSuperAdministrador = true;
    this.registroService.esAdministrador = true;
    this.cookiesService.setUser(usuario);
    this.userAuth$.next(usuario);
    return EMPTY;
  }

  /*Limita la sesión a solo un dispositivo, opera en conjunto con el observable del navbar-admin*/

  private async verificarSessionActiva(id_usuario_cuenta: string) {
    //verificamos si se tiene token existente
    this.authSessionService.getSessionInicio(id_usuario_cuenta).then(async session => {
      if (session === null) {
        this.uniqueSession = {
          id_usuario_cuenta: id_usuario_cuenta,
          token_session: Utils.generateUniqueToken()
        }
        this.authSessionService.setSesion(this.uniqueSession).then(tx => {
          if (tx.tx && this.uniqueSession) {
            this.authSessionService.fetchSessionIdCuentaUsuario(this.uniqueSession);
            this.cookiesService.setSession(this.uniqueSession);
          }
        });

      } else {
        // existe sesion activa
        if (session?.id != null && this.uniqueSession === null) {
          const cookieSession: ISession | null = this.cookiesService.session;
          // verificamos sesion si es propia o de otro dispositivo
          if (cookieSession) {

            //sesion de otro dispositivo
            if (cookieSession.token_session !== session.token_session) {
              this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_DOBLE_SESION_CERRAR, 10000);
              this.logout();
            }
            else {
              //rescatamos la sesion existente
              this.uniqueSession = cookieSession;
              this.authSessionService.fetchSessionIdCuentaUsuario(this.uniqueSession);
            }

          } else {
            this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_DOBLE_SESION, 10000);
            this.authSessionService.deleteSession(session.id);

            //creamos una nueva sesion
            this.uniqueSession = {
              id_usuario_cuenta: id_usuario_cuenta,
              token_session: Utils.generateUniqueToken()
            }
            this.cookiesService.setSession(this.uniqueSession);
            this.authSessionService.setSesion(this.uniqueSession).then(tx=>{
              if(tx.tx){
                if(this.uniqueSession)
                this.authSessionService.fetchSessionIdCuentaUsuario(this.uniqueSession);
              }
            });
          }
        }
      }
    });
  }

 

  private handleUserAsColaborador(uid: string): Observable<any> {
    this.usuarioColaboradorBlServices.existFetchingUsuarioColaborador(uid);
    const usuarioColaborador$ = this.usuarioColaboradorBlServices.getUsuarioColaboradorXEmpresa();

    return usuarioColaborador$.pipe(
      filter(colaborador=> colaborador!==undefined),
      map(async colaborador => {
        if (colaborador) {
          if(colaborador.es_vigente){
            let usuarioColaborador: CColaborador = { ...colaborador }
            usuarioColaborador.password = '';

            //verificamos la sesion, si existe lo cierra, sino existe la genera
            await this.verificarSessionActiva(usuarioColaborador.id_usuario_cuenta);
            //set usuario administrador

            if(colaborador.empresa){
              //set empresa
              this.registroService.empresa = {...colaborador.empresa}
            };
            delete usuarioColaborador.empresa; //delete para tener el objeto mas limpio
            this.cookiesService.setUser(usuarioColaborador);
            //set usuario administrador
            this.registroService.usuario = {...colaborador};
            this.registroService.rolesModulosUsuarioColaborador = [...colaborador.modulo_rol];
            this.registroService.esSuperAdministrador = null;
            this.userAuth$.next(colaborador);
          }else{
            this.uiService.CloseModalLogin();
            this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_CUENTA_SUSPENDIDA_COLABORADOR, 7000);
            this.logout();
          }
        }
        else{
          //cuando se elimina un colaborador desde el administrador
          if(this.registroService.usuario && this.registroService.empresa){        
            this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_CUENTA_ELIMINADA_COLABORADOR, 7000);
            this.logout();

          }else{                    
            //cuando se pierde sesion en un registro nuevo de usuario administrador
            this.registroService.esRegistroAdministrador =  this.lRoles_primarcas[0].value;
            this.userAuth$.next(null);
          }
          this.cookiesService.cleanUser();
          this.registroService.limpiarVariablesRegistro();
        }
        return EMPTY;
      }),
      catchError(error => {
        console.error('Error handling user as collaborator:', error);
        this.userAuth$.next(null);
        this.cookiesService.setUser(null);
        return EMPTY;
      })
    );
  }

  async validarPassword(password: string) {
    try {
      //recuperamos el correo del uuario
      const user = await this.afAuth.currentUser;
      if (!user) {
        //cuando el usuario no esta logueado
        return;
      }
      if (user.email === null) {
        //el usuario esta inexistente
        //console.log('Correo electrónico del usuario es nulo.');
        return;
      }

      const credentials = await user.getIdToken(true);
      const resultado = await this.afAuth.signInWithEmailAndPassword(user.email, password);
      this.transaccion = new TransaccionModel();
      if(resultado!==undefined){
        this.transaccion.tx = true;
        this.transaccion.data = resultado;
      }
      return this.transaccion
    } catch (error) {
      this.transaccion.tx = false;
      this.transaccion.error = true;
      this.transaccion.data = error;
      return this.transaccion;
    }
  }

  //verifica si un email (nombre de usuario existe previamente)
  checkIfEmailExists(email:string){
    return this.afAuth.fetchSignInMethodsForEmail(email);
  }

  async login(correo_electronico: string, password: string) {
    //limpiando token
    this.logout();
    this.closeBdService.CloseCnxBDAll();
    this.registroService.limpiarVariablesRegistro();
    this.afAuth.signInWithEmailAndPassword(correo_electronico, password).then((data)=>{
      //console.log(data);
    }).catch(error => {
        this.authChangeCodes$.next({
          errorCode: error.code,
          errorMessage: error.message,
          is_Error: true,
        });
      });
  }

  logout() {
    //si existe se desuscribe, no existe cuando no verifico mail, genera error en este ultimo caso
    this.spinnerPageService.show();
    if (this.usuarioSub$) {
      this.usuarioSub$.unsubscribe();
    }
    if (this.getUsuarioByIDSb) {
      this.getUsuarioByIDSb.unsubscribe();
    }

    this.cookiesService.cleanUser();
    this.cookiesService.cleanSession();
    this.registroService.limpiarVariablesRegistro();   

    if(this.uniqueSession?.id_usuario_cuenta){
      this.authSessionService.deleteSessionByUser(this.uniqueSession).then(()=>{
        //limpiando el token de sesion
        this.uniqueSession=null;     
        this.closeBdService.CloseCnxBDAll();       
        this.afAuth.signOut();
        this.spinnerPageService.hide();
      });
    }else{
      this.usuarioService.stopFetchingUsuario();
      this.afAuth?.signOut();
      this.spinnerPageService.hide();
    }
  }

  async register(correo_electronico: string, password: string) {
    await this.afAuth.createUserWithEmailAndPassword(correo_electronico, password).then((userCredential) => {
      this.sendVerificationEMail();
      const userId = userCredential.user?.uid;
      const transaccion: TransaccionModel = new TransaccionModel();
      if (userId) {
        transaccion.tx = true;
        transaccion.data = { userID: userId };
      } else {
        transaccion.tx = false;
      }
      return transaccion;
    }).catch(error => {

      this.authChangeCodes$.next({
        errorCode: error.code,
        errorMessage: error.message,
        is_Error: true, 
        email_verified:false
      });

      const transaccion: TransaccionModel = new TransaccionModel();
      transaccion.tx = false;
      transaccion.error = error;
      return transaccion;
    });
  }

  resetPassword(email:string){
    return this.afAuth.sendPasswordResetEmail(email);
  }

  sendVerificationEMail() {
    return this.afAuth.currentUser.then((user) => {
      return user?.sendEmailVerification();
    }).then(() => { });
  }

  async getUserIDAsync() {
    const user = await this.afAuth.authState.pipe(first()).toPromise();
    return user;
  }

  getAuthState() {
    return this.afAuth.authState;
  }
}
