<mat-toolbar  class="nav-principal" >
  <div *ngIf="visibleSidenavSinPago">
    <div class="nav-principal-icon-menu-mobile" *ngIf="visibleMenuMobile" >
      <button mat-icon-button type="button" (click)="onToggleSidenav()" style="width: 10px" >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>  
  <div fxLayout="row">
    <div fxHide.xs fxShow.gt class="avatar-empresa" *ngIf="!estadoRegistroPendiente">
      <app-avatar-image [iniciales]="inicialesAvatarEmp" [pathImage]="imageAvatarEmpresa"></app-avatar-image>
    </div>
    <div *ngIf="estadoRegistroPendiente">
      <div style="margin-top: 8px;" >
        <img class="nav-principal-logo-plataforma" src="../../../../assets/img/icon-modula.png" alt="Modula" height="58px" width="58px" >
      </div>      
    </div>
    <div fxFlex fxLayout="column" [style.marginLeft.px]="5" fxLayoutAlign="start end">
      <div *ngIf="estadoRegistroPendiente"
        [ngClass]="{'nav-sidebar-logo-bussiness-nuevo': estadoRegistroPendiente, 'nav-sidebar-logo-bussiness':!estadoRegistroPendiente}"
        fxLayoutAlign="start end">{{datosEmpresa.NOMBRE_PLATAFORMA}}
      </div>
      <div *ngIf="empresa && !estadoRegistroPendiente" class="nav-sidebar-logo-bussiness"  fxLayoutAlign="start end" fxHide.xs fxShow.gt >{{empresa.nombre_razon | sizeLabel:20 }}</div>
      <div *ngIf="empresa && !estadoRegistroPendiente" class="nav-sidebar-logo-bussiness-small"  fxLayoutAlign="start end" fxHide fxShow.xs >{{empresa.nombre_razon | sizeLabel:10 }}</div>
      <div *ngIf="!estadoRegistroPendiente"  class="nav-sidebar-logo-small" fxLayoutAlign="start end" > soportado por {{datosEmpresa.NOMBRE_PLATAFORMA}}</div>
    </div>
  </div>


  <div fxFlex  fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" >
    <div *ngIf="!estadoRegistroPendiente">
      <app-avisos></app-avisos>
    </div>
    <div *ngIf="!estadoRegistroPendiente">
      <app-semaforo></app-semaforo>
    </div>
    <div *ngIf="!estadoRegistroPendiente">
      <div *ngIf="visibleAvatarSinPago">
        <app-avatar [pathImage]="imageAvatar" [iniciales]="inicialesAvatar" [tipoUsuario]="tipoUsuarioAvatar" [menuAvatarUsuario]="menuAvatarUsuario" ></app-avatar>
      </div>      
    </div>
    <div>
      <app-button *ngIf="es_autenticado" type="outline" text="Salir" fxHide.xs (click)="onLogout()"  ></app-button>
      <app-button *ngIf="es_autenticado" type="fab_icon" icon_fig="logout" fxHide fxShow.xs (click)="onLogout()"></app-button>
    </div>
  </div>
</mat-toolbar>
