<div style="margin-top: 10px; margin-bottom:10px;">
    <div *ngIf="recibo!=null">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center"  fxLayoutAlign.xs="space-between stretch">
            <div fxLayout="row">
                <mat-icon>{{Icons.RECEIPT_LONG}}</mat-icon>
                <div>
                    <h3>Recibo:&nbsp;</h3>
                </div>
                <mat-chip-list>
                    <mat-chip [@blink]="blinkState" class="chip-rechazado" >PENDIENTE</mat-chip>
                </mat-chip-list>             
            </div>
            <div fxLayout="row" matTooltip="Fecha de emisión.">
                <mat-icon>{{Icons.EVENT_NOTE}}</mat-icon>
                <h3>Emisión: &nbsp;{{recibo.fecha_calculo_formateado}}</h3>
            </div>
            <div fxLayout="row" matTooltip="Último día de pago." >
                <mat-icon>{{Icons.CALENDAR_MONTH}}</mat-icon>
                <h3>Vencimiento: &nbsp;{{recibo.ultimo_dia_pago_formateado}}</h3>
            </div>
            <div fxLayout="row">
                <mat-icon>{{Icons.PAYMENTS}}</mat-icon>
                <h3>Deuda: &nbsp;S/ {{recibo.costo_final | number:'1.2-2' }}</h3>
            </div>
        </div>
    </div>
</div>



