<div    [ngClass.gt-sm]="{'container-gt-sm':true}" 
        [ngClass.lt-sm]="{'container-lt-sm':true}" >
        <div fxLayout="column" fxLayoutGap="10px" style="margin-bottom: 20px; margin-top: 10px;" >
            <div fxLayout="row" fxLayout.xs="column">
                <div fxLayout="row" fxFlex="50%">
                    <div class="titulo">Pedido:&nbsp;</div>                
                    <div>{{pedido.codigo_pedido}}</div>
                </div>
                <div fxLayout="row" fxFlex="50%">
                    <div class="titulo">Mesa(s):&nbsp;</div>                
                    <div>{{pedido.mesas_completo}}</div>
                </div>
                           
            </div>
            <div fxLayout="row" fxLayout.xs="column">
                <div fxLayout="row" fxFlex="50%" >
                    <div class="titulo" >Total:&nbsp;</div>                
                    <div>S/ {{pedido.total | number:'1.2-2' }}</div>
                </div> 
                <div fxLayout="row" fxFlex="50%">
                    <div class="titulo">Impuesto IGV:&nbsp;</div>                
                    <div>S/ {{pedido.igv | number:'1.2-2'}}</div>
                </div> 
            </div>
            <div *ngIf="pedido.servicio_total" fxLayout="row" fxLayout.xs="column">
                <div fxLayout="row" fxFlex="50%" >
                    <div class="titulo" >Servicio:&nbsp;</div>                
                    <div>S/ {{pedido.servicio_total | number:'1.2-2'}}</div>
                </div>           
            </div>
            <mat-divider></mat-divider>           
        </div>          
        <mat-tab-group mat-align-tabs="center">
            <mat-tab label="Con Nombre">
                <form #f="ngForm" (ngSubmit)="buscarPorDNI(f)" >
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-top: 10px;">
                        <mat-form-field appearance="outline" class="control-row" style="padding-top: 10px;">
                            <mat-label>DNI</mat-label>
                            <input type="text" 
                                [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8"
                                [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_12"
                                [pattern]="PatternsRegex.onlyPassportOrDNIOrCE" 
                                matInput placeholder="Número de DNI" ngModel
                                name="dni" #ruc="ngModel" 
                                autocomplete="off"
                                (keypress) ="onlyNumbers($event)"
                                (keydown)="onKeydown($event, f)"
                                required>
                            <mat-icon matSuffix>{{Icons.BADGE}}</mat-icon>
                            <mat-error
                                *ngIf="ruc.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                            <mat-error
                                *ngIf="ruc.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_REQ6_DIGITOS_CODIGO}}</mat-error>
                            <mat-error
                                *ngIf="ruc.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_8}}</mat-error>
                            <mat-error
                                *ngIf="ruc.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_12}}</mat-error>
                        </mat-form-field>
                        <div fxLayout="row" fxLayoutGap="20px" >
                            <button mat-stroked-button [disabled]="f.invalid">
                                <mat-icon>{{Icons.SEARCH}}</mat-icon>
                            </button>
                            <button mat-stroked-button type="button" (click)="limpiarBusqueda(f)" >
                                <mat-icon>{{Icons.CLEANING}}</mat-icon>
                            </button>
                        </div>
                    </div>
                </form>             
                <div *ngIf="!isLoading && esBuscado && (dniResponse?.nombres=='' || !dniResponse?.success)  " fxLayout="column" fxLayoutGap="20px" style="margin-top: 20px;" >
                    <span>No se encontró la persona</span>
                    <mat-divider></mat-divider>
                </div>
                <div *ngIf="isLoading" style="margin-top: 20px;">
                    <app-progress-bar></app-progress-bar>
                </div>
                <div *ngIf="dniResponse!=null && (dniResponse!.success && dniResponse.nombres!='') && esBuscado && !isLoading" fxLayout="column" fxLayoutGap="10px" style="margin-top: 20px; margin-bottom: 20px;" >
                    <div fxLayout="row" fxLayout.xs="column">
                        <div fxLayout="row" fxFlex="50%">
                            <div class="titulo">DNI:&nbsp;</div>                
                            <div>{{dniResponse.dni}}</div>
                        </div> 
                        <div fxLayout="row" fxFlex="50%" >
                            <div class="titulo" >Nombres:&nbsp;</div>                
                            <div>{{dniResponse.nombres}}</div>
                        </div>            
                    </div>
                    <div fxLayout="row" fxLayout.xs="column">
                        <div fxLayout="row" fxFlex="50%">
                            <div class="titulo">Apellido Paterno:&nbsp;</div>                
                            <div>{{dniResponse.apellidoPaterno}}</div>
                        </div>
                        <div fxLayout="row" fxFlex="50%">
                            <div class="titulo">Apellido Materno:&nbsp;</div>                
                            <div>{{dniResponse.apellidoMaterno}}</div>
                        </div> 
                    </div>
                    <mat-divider></mat-divider>
                    <div  fxLayout="row" fxLayoutAlign="center center" style="margin-top: 20px;">          
                        <app-button [width]="100" text="Emitir Boleta" [attr_type]="'button'" (click)="emitirBoletaAutomaticoConDatosAutomatico()" ></app-button>    
                    </div>
                </div>
                <form #g="ngForm" (ngSubmit)="emitirBoletaManualCNombre(g)">
                    <div fxLayout="column" *ngIf="!isLoading && (dniResponse?.nombres=='' || !dniResponse?.success)" style="margin-top: 20px;" >
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                            <mat-form-field>
                                <mat-label>Tipo Documento</mat-label>
                                <mat-select required name="ddlTipoDocumento" #ddlTipoDocumento="ngModel" 
                                [(ngModel)]="personaSend.tipoDocumento"
                                >
                                <mat-option *ngFor=" let tdocumento of lTipo_documento"
                                    [value]="tdocumento.id">{{tdocumento.denominacion}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="ddlTipoDocumento.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                          </mat-form-field>
                          <mat-form-field>
                            <input type="text" matInput placeholder="Núm. Documento" name="numDocumento" ngModel
                              [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_9"
                              [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_8" #numDocumento="ngModel"
                              [pattern]="PatternsRegex.onlyPassportOrDNIOrCE" required         
                              (keypress) ="onlyNumbers($event)"
                              [(ngModel)]="personaSend.dni"
                              >
                            <mat-error *ngIf="numDocumento.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                            <mat-error *ngIf="numDocumento.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
                            <mat-error *ngIf="numDocumento.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_MIN8_DIGITOS}}</mat-error>
                            <mat-error *ngIf="numDocumento.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_MAX9_DIGITOS}}</mat-error>
                          </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                            <mat-form-field>
                                <input required type="text" matInput placeholder="Nombres" name="nombres" ngModel
                                  [pattern]="PatternsRegex.onlyCharsTwoWordsNoSpanish" #nombreInput="ngModel"
                                  [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
                                  [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"                  
                                  oninput="this.value = this.value.toUpperCase()"
                                  [(ngModel)]="personaSend.nombres"
                                  >
                                <mat-error *ngIf="nombreInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                <mat-error
                                  *ngIf="nombreInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_2PALABRAS}}</mat-error>
                                <mat-error
                                  *ngIf="nombreInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
                                <mat-error
                                  *ngIf="nombreInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>    
                              </mat-form-field>
                              <mat-form-field>
                                <input type="text" matInput placeholder="Apellido Paterno" name="apPaterno"
                                  [pattern]="PatternsRegex.onlyCharsOneWordsSpanish" #apPaterno="ngModel" required
                                  [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
                                  [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3" ngModel                  
                                  oninput="this.value = this.value.toUpperCase()"
                                  [(ngModel)]="personaSend.apellidoPaterno"
                                  >
                                <mat-error *ngIf="apPaterno.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                <mat-error
                                  *ngIf="apPaterno.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_1PALABRA}}</mat-error>
                                <mat-error
                                  *ngIf="apPaterno.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
                                <mat-error
                                  *ngIf="apPaterno.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
                              </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                            <mat-form-field>
                                <input type="text" matInput placeholder="Apellido Materno" name="apMaterno"
                                  [pattern]="PatternsRegex.onlyCharsOneWordsSpanish" #apMaterno="ngModel" required
                                  [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
                                  [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3" ngModel                  
                                  oninput="this.value = this.value.toUpperCase()"
                                  [(ngModel)]="personaSend.apellidoMaterno"
                                  >
                                <mat-error *ngIf="apMaterno.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                <mat-error
                                  *ngIf="apMaterno.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_1PALABRA}}</mat-error>
                                <mat-error
                                  *ngIf="apMaterno.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
                                <mat-error
                                  *ngIf="apMaterno.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
                              </mat-form-field>  
                        </div>
                        <mat-divider></mat-divider>   
                        <div  fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 20px;">          
                            <app-button [width]="100" text="Emitir Boleta" [attr_type]="'submit'" [attr_disabled]="g.invalid" ></app-button>
                            <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" color="gray"
                                (click)="LimpiarManual(g)"></app-button>
                            <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING"
                                (click)="LimpiarManual(g)"></app-button>
                        </div>
                    </div>
                </form>           
            </mat-tab>
            <mat-tab label="Simple">
                <div  fxLayout="row" fxLayoutAlign="center center" style="margin-top: 20px;">
                        <!-- no se puede emitar boleta sin nombre para montos mayores a 700 -->
                        <app-button [width]="100" text="Emitir Boleta" [attr_type]="'button'" (click)="emitirBoletaSimple()" [attr_disabled]="pedido.total<700?false:true" ></app-button>    
                    </div>                
            </mat-tab>
            
        </mat-tab-group>
</div>
