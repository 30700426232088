import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Length_Database, Msjs_Validations, PatternsRegex } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { Catalogo } from '../../../procesos/dialog/models/Catalogo';
import { IProducto } from '../../../procesos/dialog/models/IProducto';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UIService } from 'src/app/shared/services/ui.services';
import { ICatalogo } from '../../../procesos/dialog/models/ICatalogo';
import { debounceTime, filter, startWith, switchMap } from 'rxjs/operators';
import { DialogCatalogoBlService } from '../../../procesos/dialog/services/services.bl/dialog-catalogo.bl.service';

@Component({
  selector: 'app-buscar-catalogo-into',
  templateUrl: './buscar-catalogo-into.component.html',
  styleUrls: ['./buscar-catalogo-into.component.scss']
})
export class BuscarCatalogoIntoComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
 
  txtComplete = new FormControl('');
  filteredOptions!: Observable<ICatalogo[]>;
  catalogo = new Catalogo();
  catalogoBuscar:string='';

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef: MatDialogRef<BuscarCatalogoIntoComponent>,
    private uiService:UIService,
    private catalogoBLServices: DialogCatalogoBlService
  ) { }

  ngOnInit(): void {
    this.filteredOptions = this.txtComplete.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      filter(value => typeof value === 'string' && value.length >= 3), // Filtro para la longitud mínima de caracteres
      switchMap(value => {
        if (value !== '') {
          return this.catalogoBLServices.getCatalogosAutocomplete(value);        
        } else {
          return [];
        }
      })
    );
  }

  onSelect(catalogo:ICatalogo){
    this.dialogRef.close(catalogo);
  }
  onClose(){
    this.dialogRef.close();
  }

}
