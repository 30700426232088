import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./framework/material.module"
import { SidenavListComponent } from "./components/sidenav-list/sidenav-list.component";
import { NavBarComponent } from './components/nav-bar/nav-bar.component'
import { FlexLayoutModule } from "@angular/flex-layout";
import { ButtonComponent } from './components/button/button.component';
import { UIService } from "./services/ui.services";
import { UINavigateSidenavService } from "./services/ui.navigate-sidenav.services";
import { LoginComponent } from './components/dialog/login/login.component';
import { SignupComponent } from './components/dialog/signup/signup.component';
import { UsuarioComponent } from './components/card-registro/usuario/usuario.component';
import { EmpresaComponent } from './components/card-registro/empresa/empresa.component';
import { FooterComponent } from './components/footer/footer.component';
import { CardInfoComponent } from './components/card-info/card-info.component';
import { CaptchaComponent } from './components/dialog/captcha/captcha.component';
import { CaptchaCodigoComponent } from './components/dialog/captcha-codigo/captcha-codigo.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ErrorSnackBarComponent } from './components/snackbar/error-snackbar.component';
import { InfoSnackBarComponent } from './components/snackbar/info-snackbar.component';
import { SuccessSnackBarComponent } from './components/snackbar/success-snackbar.component';
import { WarningSnackBarComponent } from './components/snackbar/warning-snackbar.component';
import { AvisoxSnackBarComponent } from './components/snackbar/avisox-snackbar.component';
import { NavBarAdminComponent } from './components/nav-bar-admin/nav-bar-admin.component';
import { SidenavListAdminComponent } from './components/sidenav-list-admin/sidenav-list-admin.component';
import { ModuloComponent} from './components/card-registro/modulo/modulo.component';
import { UploadComponent } from './components/upload/upload.component';
import { SizeLabelPipe } from './helpers/pipes/size-label.pipe';
import { SpinnerPageComponent } from './components/spinner-page/spinner-page.component';
import { QuestionsComponent } from './components/dialog/questions/questions.component';
import { ModuloPlataformaComponent } from './components/modulo-plataforma/modulo-plataforma.component';
import { TerminosCondicionesComponent } from './components/dialog/terminos-condiciones/terminos-condiciones.component';
import { CostosFacturacionComponent } from './components/dialog/costos-facturacion/costos-facturacion.component';
import { TerminosPrivacidadComponent } from './components/dialog/terminos-privacidad/terminos-privacidad.component';
import { SemaforoComponent } from './components/semaforo/semaforo.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { AvisosComponent } from './components/avisos/avisos.component';
import { AvatarImageComponent } from './components/avatar-image/avatar-image.component';
import { GestionUsuarioComponent } from './components/dialog/gestion-usuario/gestion-usuario.component';
import { AvisoComponent } from './components/dialog/aviso/aviso.component';
import { GestionRolComponent } from './components/procesos/gestion-rol/gestion-rol.component';
import { TituloModuloComponent } from './components/procesos/titulo-modulo/titulo-modulo.component';
import { ToUpperPipe } from './helpers/pipes/to-upper.pipe';
import { DialogGestionRolComponent } from "./components/procesos/gestion-rol/dialog-gestion-rol/dialog-gestion-rol.component";
import { Ruta404Component } from './components/ruta404/ruta404.component';
import { BuscarComponent } from './components/buscar/buscar.component';
import { ResumenComponent } from './components/resumen/resumen.component';
import { TableComponent } from './components/table/table.component';
import { HorarioTrabajoComponent } from './components/horario-trabajo/horario-trabajo.component';
import { GestionColaboradorComponent } from './components/procesos/gestion-colaborador/gestion-colaborador.component';
import { ReplaceCharPipe } from './helpers/pipes/replace-char.pipe';
import { ToDatePipe } from './helpers/pipes/to-date.pipe';
import { VerificarPasswordComponent } from './components/dialog/verificar-password/verificar-password.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RedondeoEnteroSupPipe } from './helpers/pipes/redondeo-entero-sup.pipe';
import { EncuestaComponent } from './components/formularios-encuesta/encuesta/encuesta.component';
import { NegativizarPipe } from './helpers/pipes/negativizar.pipe';
import { GestionCatalogoComponent } from './components/procesos/gestion-catalogo/gestion-catalogo.component'
import { GestionProductoComponent } from './components/procesos/gestion-producto/gestion-producto.component';
import { DialogCatalogoComponent } from './components/procesos/dialog/dialog-catalogo/dialog-catalogo.component';
import { DialogProductoComponent } from './components/procesos/dialog/dialog-producto/dialog-producto.component';
import { BooleanToLabelPipe } from './helpers/pipes/boolean-to-label.pipe';
import { CarouselComponent } from './components/carousel/carousel.component';
import { VerImagenesComponent } from './components/dialog/ver-imagenes/ver-imagenes.component';
import { DialogBuscarProductoComponent } from './components/procesos/dialog/dialog-buscar-producto/dialog-buscar-producto.component';
import { GestionAmbientesMesasComponent } from './components/procesos/gestion-ambientes-mesas/gestion-ambientes-mesas.component';
import { RegistrarPedidoComponent } from './components/registrar-pedido/registrar-pedido.component';
import { PedidosComponent } from './components/pedidos/pedidos.component';
import { ListarAmbienteMesaComponent } from './components/listar-ambiente-mesa/listar-ambiente-mesa.component';
import { ListarCatalogoComponent } from './components/listar-catalogo/listar-catalogo.component';
import { ListarProductoComponent } from './components/listar-producto/listar-producto.component';
import { GestionAspectosGeneralesComponent } from './components/procesos/gestion-aspectos-generales/gestion-aspectos-generales.component';
import { GestionMediosPagoComponent } from './components/procesos/gestion-medios-pago/gestion-medios-pago.component';
import { GestionTurnoComponent } from './components/gestion-turno/gestion-turno.component';
import { GestionMontoComponent } from './components/gestion-monto/gestion-monto.component';
import { HistorialComponent } from './components/historial/historial.component';
import { HideMailPipe } from './helpers/pipes/hide-mail.pipe';
import { CantidadComponent } from './components/registrar-pedido/dialog/cantidad/cantidad.component';
import { BuscarProductoIntoComponent } from './components/registrar-pedido/dialog/buscar-producto-into/buscar-producto-into.component';
import { BuscarCatalogoIntoComponent } from './components/registrar-pedido/dialog/buscar-catalogo-into/buscar-catalogo-into.component';
import { ListarAmbienteMesaDialogComponent } from "./components/listar-ambiente-mesa-dialog/listar-ambiente-mesa-dialog.component";
import { DialogCaractProductoComponent } from './components/procesos/dialog/dialog-caract-producto/dialog-caract-producto.component';
import { ListarCaracteristicasIntoComponent } from './components/registrar-pedido/dialog/listar-caracteristicas-into/listar-caracteristicas-into.component';
import { ListarProductoComboComponent } from "./components/listar-producto-combo/listar-producto-combo.component";
import { VerPedidoComponent } from './components/ver-pedido/ver-pedido.component';
import { PedidosCerradosComponent } from './components/pedidos-cerrados/pedidos-cerrados.component';
import { GestionCajaCobroComponent } from './components/gestion-caja-cobro/gestion-caja-cobro.component';
import { ImprimirPedidoComponent } from './components/imprimir-pedido/imprimir-pedido.component';
import { CajaCobroComponent } from './components/dialog/caja-cobro/caja-cobro.component';
import { TecladoNumericoVirtualComponent } from './components/teclado-numerico-virtual/teclado-numerico-virtual.component';
import { TecladoCharVirtualComponent } from './components/teclado-char-virtual/teclado-char-virtual.component';
import { GestionPagoParcialComponent } from './components/gestion-pago-parcial/gestion-pago-parcial.component';
import { GestionDescuentosIncidenciasComponent } from './components/procesos/gestion-descuentos-incidencias/gestion-descuentos-incidencias.component';
import { GestionDescuentoComponent } from './components/dialog/gestion-descuento/gestion-descuento.component';
import { GestionIncidenciaComponent } from './components/dialog/gestion-incidencia/gestion-incidencia.component';
import { ProductoCompuestoComponent} from './components/dialog/producto-compuesto/producto-compuesto.component';
import { GestionIncidenciasListaComponent } from "./components/gestion-incidencias-lista/gestion-incidencias-lista.component";
import { GestionOperacionesComponent } from './components/procesos/gestion-operaciones/gestion-operaciones.component';
import { TraspasoProductosComponent } from './components/dialog/traspaso-productos/traspaso-productos.component';
import { ProductoSimpleComponent } from './components/dialog/producto-simple/producto-simple.component';
import { ListarAmbienteMesaOcupadoDialogComponent } from './components/listar-ambiente-mesa-ocupado-dialog/listar-ambiente-mesa-ocupado-dialog.component';
import { GestionExternoCartaComponent } from './components/procesos/gestion-externo-carta/gestion-externo-carta.component';
import { QRCodeModule } from 'angularx-qrcode';
import { GestionComprobanteElectronicoComponent } from './components/procesos/gestion-comprobante-electronico/gestion-comprobante-electronico.component';
import { NavBarConsolaComponent } from './components/nav-bar-consola/nav-bar-consola.component';
import { GestionarComprobantesElectronicoPlatafComponent } from './components/consola/gestionar-comprobantes-electronico-plataf/gestionar-comprobantes-electronico-plataf.component';
import { GestionarReciboComponent } from './components/consola/gestionar-recibo/gestionar-recibo.component';
import { GestionarReconexionServicioComponent } from './components/consola/gestionar-reconexion-servicio/gestionar-reconexion-servicio.component';
import { AlertaPagoPendienteComponent } from './components/procesos/alerta-pago-pendiente/alerta-pago-pendiente.component';
import { EmisionComprobanteElectronicoComponent } from './components/procesos/emision-comprobante-electronico/emision-comprobante-electronico.component';
import { EmisionBoletaVentaComponent } from './components/procesos/emision-boleta-venta/emision-boleta-venta.component';

import { EmisionFacturaComponent } from './components/procesos/emision-factura/emision-factura.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';

const COMPONENTS =[
  SidenavListComponent,
  NavBarComponent,
  ButtonComponent,
  LoginComponent,
  SignupComponent,
  FooterComponent,
  CardInfoComponent,
  CaptchaComponent,
  CaptchaCodigoComponent,
  SpinnerComponent,
  ErrorSnackBarComponent,
  InfoSnackBarComponent,
  SuccessSnackBarComponent,
  WarningSnackBarComponent,
  AvisoxSnackBarComponent,
  UsuarioComponent,
  EmpresaComponent,
  ModuloComponent,
  NavBarAdminComponent,
  SidenavListAdminComponent,
  UploadComponent,
  SpinnerPageComponent,
  QuestionsComponent,
  ModuloPlataformaComponent,
  ModuloComponent,
  TerminosCondicionesComponent,
  CostosFacturacionComponent,
  TerminosPrivacidadComponent,
  SemaforoComponent,
  AvatarComponent,
  AvisosComponent,
  AvatarImageComponent,
  GestionUsuarioComponent,
  AvisoComponent,
  GestionRolComponent,
  DialogGestionRolComponent,
  TituloModuloComponent,
  Ruta404Component,
  BuscarComponent,
  ResumenComponent,
  TableComponent,
  HorarioTrabajoComponent,
  GestionColaboradorComponent,
  VerificarPasswordComponent,
  PaginationComponent,
  EncuestaComponent,
  GestionCatalogoComponent,
  GestionProductoComponent,
  DialogCatalogoComponent,
  DialogProductoComponent,
  CarouselComponent,
  VerImagenesComponent,
  DialogBuscarProductoComponent,
  GestionAmbientesMesasComponent,
  RegistrarPedidoComponent,
  PedidosComponent,
  ListarAmbienteMesaComponent,
  GestionAspectosGeneralesComponent,
  GestionMediosPagoComponent,
  GestionTurnoComponent,
  GestionMontoComponent,
  CantidadComponent,
  BuscarProductoIntoComponent, 
  BuscarCatalogoIntoComponent,
  ListarAmbienteMesaDialogComponent,
  DialogCaractProductoComponent,
  ListarCaracteristicasIntoComponent,
  ListarProductoComboComponent,
  VerPedidoComponent,
  PedidosCerradosComponent,
  GestionCajaCobroComponent,
  ImprimirPedidoComponent, 
  CajaCobroComponent,
  TecladoNumericoVirtualComponent, 
  TecladoCharVirtualComponent,
  GestionPagoParcialComponent, 
  GestionDescuentosIncidenciasComponent,
  GestionDescuentosIncidenciasComponent, 
  GestionDescuentoComponent,
  GestionIncidenciaComponent,
  ProductoCompuestoComponent,
  GestionIncidenciasListaComponent,
  GestionOperacionesComponent,
  TraspasoProductosComponent,
  ProductoSimpleComponent,
  ListarAmbienteMesaOcupadoDialogComponent,
  GestionExternoCartaComponent,
  GestionComprobanteElectronicoComponent,
  NavBarConsolaComponent,
  GestionarComprobantesElectronicoPlatafComponent,
  GestionarReciboComponent,
  GestionarReconexionServicioComponent,
  AlertaPagoPendienteComponent,
  EmisionComprobanteElectronicoComponent,
  EmisionBoletaVentaComponent,
  EmisionFacturaComponent,
  ProgressBarComponent
];

const PIPES=[
  SizeLabelPipe,
  ToUpperPipe,
  ReplaceCharPipe,
  ToDatePipe,
  RedondeoEnteroSupPipe,
  NegativizarPipe,
  BooleanToLabelPipe,
  ListarCatalogoComponent,
  ListarProductoComponent,
  HistorialComponent,
  HideMailPipe
]

const ENTRY_COMPONENTS=[
  LoginComponent,
  SignupComponent,
  SpinnerComponent,
];
const PROVIDERS = [UIService];
const MODULES =[
  CommonModule,
  RouterModule,
  FormsModule,
  RouterModule,
  MaterialModule,
  FlexLayoutModule,
  ReactiveFormsModule,
  QRCodeModule
];

@NgModule({
  declarations:[...COMPONENTS, PIPES,],
  imports:[ ...MODULES],
  exports:[ ...MODULES, ...COMPONENTS],
  providers:[...PROVIDERS],
  entryComponents:[...ENTRY_COMPONENTS]
})
export class SharedModule{}
