import { Injectable } from '@angular/core';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import { Auditoria, colections } from 'src/app/shared/cons/db.colections';
import { map } from 'rxjs/operators';
import { Utils } from '../../../helpers/utils'
import { IModulo } from '../../dialog/models/modulo.model';
import { IModuloEmpresa } from '../../dialog/models/modulo.empresa.model';
import { EmpresaBLService } from '../services.bl/empresa.bl.service';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { MontoModulo } from '../../procesos/gestion-colaborador/models/monto-modulo';
import { ReduccionUsuario } from '../../procesos/gestion-colaborador/models/reduccion-usuario.model';

@AutoUnsubscribe
@Injectable({
  providedIn: 'root'
})



export class ModulosDAService {

  constructor(
    private db: AngularFirestore,
    private empresaBLService: EmpresaBLService
  ) { }

  getModulos() {
    return this.db.collection(colections.SIST_MODULOS, ref => ref
      .where(Auditoria.es_vigente, '==', true)
      .where(Auditoria.es_borrado, '==', false))
      .get()
      .pipe(
        map(querySnapshot => {
          const modulosDoc: any[] | null = [];

          querySnapshot.forEach(doc => {
            const datos = Utils.convertDate(doc.data()) as IModulo;
            const id = doc.id;
            const data = { id, ...datos };
            modulosDoc.push(data);
          });

          return modulosDoc.length > 0 ? modulosDoc : null;
        })
      );
  }
  //inserta los modulos seleccionados en la empresa que corresponde
  insertModulos(modulosSeleccionados:IModuloEmpresa, idEmpresa:string){
    return this.empresaBLService.updateEmpresaModulos(modulosSeleccionados, idEmpresa);
  }
  updateModulos(modulosSeleccionados:IModuloEmpresa, idEmpresa:string, lista_modulos_seleccionados:any[], listaDecremento:ReduccionUsuario[]){
    return this.empresaBLService.updateEmpresaModulosEditar(modulosSeleccionados, idEmpresa, lista_modulos_seleccionados, listaDecremento);
  }
}
