import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IAmbienteMesa } from '../procesos/gestion-ambientes-mesas/models/IAmbienteMesa';

@Injectable({
  providedIn: 'root'
})

export class ListarAmbienteMesaService {
  seleccionMesa$ = new Subject<any>();
  deseleccionarMesa$ = new Subject<any>();
  recargarMesas$ = new Subject();
  constructor() { }
}
