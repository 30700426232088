import { Component, Inject, OnInit } from '@angular/core';
import { IProductoTicket } from '../../registrar-pedido/models/IProductoTicket';
import { Icons } from 'src/app/shared/cons/icons';
import { TipoOperacionProductoCompuesto } from 'src/app/shared/types/tipo-operacion-prod-comp-type';
import { EstadosProductoType } from 'src/app/shared/types/estado-producto-type';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { UIService } from 'src/app/shared/services/ui.services';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';

@Component({
  selector: 'app-producto-simple',
  templateUrl: './producto-simple.component.html',
  styleUrls: ['./producto-simple.component.scss']
})

@AutoUnsubscribe

export class ProductoSimpleComponent implements OnInit {
  producto!:IProductoTicket;
  readonly Icons: typeof Icons = Icons;
  tipo_pago!: TipoOperacionProductoCompuesto;
  readonly EstadosProductoType: typeof EstadosProductoType = EstadosProductoType;
  readonly TipoOperacionProductoCompuesto: typeof TipoOperacionProductoCompuesto = TipoOperacionProductoCompuesto;
  modalSb!: Subscription;
  cantidadSel:number=0;
  esVerSeleccionado:boolean = false;
  listaCantidadProducto:number[]=[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef:MatDialogRef<ProductoSimpleComponent>,
    private readonly spinnerPageService: SpinnerPageService,
    private uiService:UIService,
  ) { }

  ngOnInit(): void {
    
    this.producto = JSON.parse(JSON.stringify(this.datoPasado.producto as IProductoTicket));   
    this.tipo_pago = this.datoPasado.tipo_pago as TipoOperacionProductoCompuesto;
    if(this.tipo_pago===TipoOperacionProductoCompuesto.TRASPASO){  
    
      if(this.datoPasado.esVer!==undefined && this.datoPasado.esVer){
        this.esVerSeleccionado = true;
      }
      //generando cantidad de producto
      let contador=1;
      while (contador <= this.producto.cantidad_producto){
        this.listaCantidadProducto.push(contador);
        contador+=1;
      }     
    }    
  }

  onClose(){
    if(this.tipo_pago== TipoOperacionProductoCompuesto.TRASPASO){
      this.dialogRef.close({cantidadSeleccionada:this.cantidadSel, esTodo:false});
    }
  }
  

}
