import { Component, HostBinding,Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ButtonType } from "../../types/button-type"

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() text: string = "";
  @Input() type: string = ButtonType.NORMAL;
  @Input() color: string = "";
  @Input() icon: boolean = false;
  @Input() width: number = 0; //  if -1 -> 100%
  @Input() icon_fig: string = ""; //  if -1 -> 100%
  @Input() attr_disabled:boolean | null =false;
  @Input() attr_type:string="button"

  @Input()
  @HostBinding("style.width")
  widthTemp: any;

  buttonType = ButtonType;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
     if (changes?.width?.currentValue) {
      if (this.width === -1) {
        this.widthTemp = "100%";
      }
    }
  }

}
