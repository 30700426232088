import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { Turno } from '../models/Turno';
import { Utils } from 'src/app/shared/helpers/utils';
import { serverTimestamp } from 'firebase/firestore';
import { Auditoria, colections, documentMensaje, documentTurno, documentsEmpresa } from 'src/app/shared/cons/db.colections';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CorreccionEfectivo } from '../models/CorreccionEfectivo';
import { SolicitarCambioType } from 'src/app/shared/types/solicitar-cambio-type';
import { Fecha } from '../models/Fecha';
import { Mensaje } from '../models/Mensaje';
import { ProcesoType } from 'src/app/shared/types/proceso-type';
import { VariacionEfectivo } from '../models/VariacionEfectivo';
import { ItemSidenav } from 'src/app/shared/models/item.model';

@Injectable({
  providedIn: 'root'
})
export class GestionMontoDaService {
  transaccion: TransaccionModel = new TransaccionModel();

  private turnoCache: BehaviorSubject<Turno | undefined | null> = new BehaviorSubject<Turno | undefined |null>(undefined);
  public turno$: Observable<Turno | undefined |null> = this.turnoCache.asObservable();
  private fetchSb!: Subscription;


  constructor(
    private db: AngularFirestore,
  ) { }

  fetchTurno(idEmpresa:string,idUsuario:string): void {
    if(this.fetchSb && !this.fetchSb.closed){
      //this.turnoCache.next(this.turnoCache.value);
      return;
    }
    this.fetchSb = this.getTurno(idEmpresa, idUsuario).subscribe(turno => {
      this.turnoCache.next(turno);
    });
  }

  stopFetchingTurno(): void {
    if (this.fetchSb) {
      this.fetchSb.unsubscribe();
    }
    this.turnoCache.next(undefined);
  }

  getTurno(idEmpresa:string, idUsuario:string){
    return this.db.collection(colections.EMPRESA, (ref)=>
    ref.where(Auditoria.es_borrado,'==',false)
    .where(documentsEmpresa.ID_DOC_USUARIO,'==',idUsuario)
    ).doc(idEmpresa).collection(colections.TURNO, (ref1)=>
     ref1.where(Auditoria.es_borrado,'==',false)
     .where(documentTurno.ES_ABIERTO,'==',true)
      .limit(1) )
     .snapshotChanges()
     .pipe(map(turno=>{
       if(turno.length>0){
         const config = Utils.convertDate(turno[0].payload.doc.data()) as Turno;
         config.fecha_registro = Utils.ToDateISO(config.fecha_creacion, true);
         config.id = turno[0].payload.doc.id;
         return config;
       }
       else{
         return null;
       }
     }));
   }

  insertTurno(turno:Turno, idEmpresa:string, correoUsuario:string){
    turno.usuario_creacion = correoUsuario
    const docTurno = Utils.SerializeJsonToDb(turno);
    docTurno.fecha_creacion = serverTimestamp()
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(colections.TURNO);

    return docConfigRef.add(docTurno).then(result=>{
      this.transaccion.tx = true;
      this.transaccion.data = result.id
      return this.transaccion;
    }).catch((error)=>{
      this.transaccion.tx = false;
      this.transaccion.error = error;
      return this.transaccion;
    });
  }

  async updateTurnoReingresoMonto(turno:Turno, idEmpresa:string, correoUsuario:string, montoAnterior:number, 
      esAdministrador:boolean, esProcesar:boolean, accion:SolicitarCambioType |null, goOptionSidenavItem: ItemSidenav){
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(colections.TURNO).doc(turno.id);
    let confMsj:any;
    let mensajeDocs:any;
    const correccionEfectivo:CorreccionEfectivo[]=[];
    const fechaKey:Fecha ={
      anio:turno.fecha_key.anio,
      mes:turno.fecha_key.mes,
      dia:turno.fecha_key.dia,
      hora:turno.fecha_key.hora,
      minuto:turno.fecha_key.minuto,
      segundo:turno.fecha_key.segundo
    }
    let correccionMontoInic:CorreccionEfectivo = new CorreccionEfectivo();
    if(esAdministrador){
      //cuando se procesa una solicitud ACEPTAR o RECHAZAR
      if(esProcesar){
        const montoFuturo = turno.correccion_monto_inicial?.filter(
          correcc=> correcc.estado_cambio=== SolicitarCambioType.SOLICITADO && 
          correcc.proceso=== ProcesoType.CAMBIAR_MONTO_INICIAL && correcc.es_vigente );
          
          //creando nuevo historial
          if(montoFuturo && montoFuturo.length>0 && accion!=null){
            correccionMontoInic = {...montoFuturo[0]};
            correccionMontoInic.fecha_key = fechaKey;
            correccionMontoInic.estado_cambio = accion;
            correccionMontoInic.monto_actual = accion==SolicitarCambioType.APROBADO? turno.monto_inicial_efectivo: montoAnterior;
            correccionMontoInic.usuario_aprobacion = correoUsuario;
            correccionMontoInic.usuario_creacion = montoFuturo[0].usuario_creacion;
            montoFuturo[0].es_vigente=false;

            //cambiando el estado a la solicitud
            turno.correccion_monto_inicial?.forEach(item=>{
              if(item.estado_cambio=== SolicitarCambioType.SOLICITADO && 
                item.proceso=== ProcesoType.CAMBIAR_MONTO_INICIAL){
                  item.es_vigente = false;
                }
            })
            

            //recuperando msj para eliminarlo
            confMsj = await docEmpRef.collection(colections.MENSAJE, ref2=>
              ref2.where(documentMensaje.ID_REF,'==',turno.id)
              .where(documentMensaje.ESTADO_CAMBIO,'==',SolicitarCambioType.SOLICITADO)
              .where(documentMensaje.PROCESO,'==',ProcesoType.CAMBIAR_MONTO_INICIAL)
              ).get().toPromise();

            mensajeDocs = confMsj.docs.map((doc: { ref: any; }) => doc.ref);

          }    

      }else{
        correccionMontoInic={
          estado_cambio: SolicitarCambioType.APROBADO,
          proceso: ProcesoType.CAMBIAR_MONTO_INICIAL,
          fecha_key: fechaKey,
          monto_actual: turno.monto_inicial_efectivo,
          monto_anterior: montoAnterior,
          usuario_creacion:correoUsuario,
          es_vigente:true,
          es_impreso:false
        }
      }     
    }
    else{
      correccionMontoInic={
        estado_cambio: SolicitarCambioType.SOLICITADO,
        proceso: ProcesoType.CAMBIAR_MONTO_INICIAL,
        fecha_key: fechaKey,
        monto_actual: turno.monto_inicial_efectivo,
        monto_anterior: montoAnterior,
        usuario_creacion:correoUsuario,
        es_vigente:true,
        es_impreso:false
      }
    }

    if(turno.correccion_monto_inicial){
      turno.correccion_monto_inicial.push(correccionMontoInic);
    }
    else{
      correccionEfectivo.push(correccionMontoInic);
    }   
    try{
       const txResult = await this.db.firestore.runTransaction(async (transaccion) => {
      
        
        if(!esProcesar){
          transaccion.update(docConfigRef.ref,{
            monto_inicial_efectivo: esAdministrador? turno.monto_inicial_efectivo: montoAnterior,
            correccion_monto_inicial: turno.correccion_monto_inicial ? turno.correccion_monto_inicial: correccionEfectivo,
            usuario_modificacion: correoUsuario,
            fecha_modificacion: serverTimestamp()
          });
        }
        else{
          transaccion.update(docConfigRef.ref,{
            monto_inicial_efectivo: accion==SolicitarCambioType.APROBADO? turno.monto_inicial_efectivo: montoAnterior,
            correccion_monto_inicial: turno.correccion_monto_inicial ? turno.correccion_monto_inicial: correccionEfectivo,
            usuario_modificacion: correoUsuario,
            fecha_modificacion: serverTimestamp()
          });
        }
        
        //insertando mensaje para el administrador
        if(!esAdministrador){
          const mensajeRef = docEmpRef.collection(colections.MENSAJE).doc();
          const mensaje:Mensaje={
            es_vigente:true,
            estado_cambio:SolicitarCambioType.SOLICITADO,
            id_ref: turno.id?turno.id:'',
            usuario_creacion: correoUsuario,
            proceso:ProcesoType.CAMBIAR_MONTO_INICIAL,
            fecha_creacion:serverTimestamp(),
            es_borrado:false,
            actual_pedido: turno.monto_inicial_efectivo,
            anterior_existente: montoAnterior,
            modulo:goOptionSidenavItem.modulo!
          }

          transaccion.set(mensajeRef.ref, mensaje);
        }else{
          //cuando se resuelve la solicitud
          if(esProcesar){            
            transaccion.delete(mensajeDocs[0]);//siempre existirá un solo documento
          }
        }

        this.transaccion.tx = true;
        return this.transaccion;

      });
      return txResult;
    }
    catch(error){
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }

  }

  async updateTurnoAgregarMonto( turno:Turno, idEmpresa:string, correoUsuario:string, esAdministrador:boolean, goOptionSidenavItem:ItemSidenav){
    let variacionEfecExistente: VariacionEfectivo[] = new Array();

    let variacion:VariacionEfectivo={
      monto: turno.monto_inicial_efectivo,
      fecha_key :{...turno.fecha_key},
      es_vigente : true,
      proceso : ProcesoType.CAMBIAR_ANADIR_MONTO,      
      usuario_creacion:correoUsuario,
      estado_cambio: SolicitarCambioType.SOLICITADO,//cuando no es administrador      
    }


    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(turno.id);
    const mensajeRef = docEmpRef.collection(colections.MENSAJE).doc();
    //revisando es administrador
    if(esAdministrador){            
      variacion.estado_cambio = SolicitarCambioType.APROBADO;
      variacion.usuario_aprobacion = correoUsuario;      
      } 
    //buscando si tiene montos añadidos previos
    if(turno.montos_aniadidos && turno.montos_aniadidos.length>0){
      variacionEfecExistente = [...turno.montos_aniadidos];      
    }
    variacionEfecExistente.push(variacion);
    
    //añadimos el objeto nuevo a la lista siendo o no siendo administrador   
    
    try{
      //creamos la transaccion 
      const txResult = await this.db.firestore.runTransaction(async (transaccion) => {
        transaccion.update(docTurnoRef.ref,{
          montos_aniadidos: variacionEfecExistente,
          usuario_modificacion: correoUsuario,
          fecha_modificacion: serverTimestamp()
        });

        if(!esAdministrador){
          const mensaje:Mensaje={
            es_vigente:true,
            estado_cambio:SolicitarCambioType.SOLICITADO,
            id_ref: turno.id?turno.id:'',
            usuario_creacion: correoUsuario,
            proceso:ProcesoType.CAMBIAR_ANADIR_MONTO,
            fecha_creacion:serverTimestamp(),
            es_borrado:false,
            actual_pedido: variacion.monto,
            modulo:goOptionSidenavItem.modulo!           
          }
          transaccion.set(mensajeRef.ref, mensaje);
        }

        this.transaccion.tx = true;
        return this.transaccion;
        
      });
      return txResult;

    }
    catch(error){      
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }

  }
  
  async updateTurnoRetirarMonto( turno:Turno, idEmpresa:string, correoUsuario:string, esAdministrador:boolean, anotacion:string, goOptionSidenavItem:ItemSidenav){
    let variacionEfecExistente: VariacionEfectivo[] = new Array();

    let variacion:VariacionEfectivo={
      monto: turno.monto_inicial_efectivo,
      fecha_key :{...turno.fecha_key},
      es_vigente : true,
      proceso : ProcesoType.CAMBIAR_RETIRAR_MONTO,
      usuario_creacion : correoUsuario,
      anotacion: anotacion,
      estado_cambio: SolicitarCambioType.SOLICITADO,//cuando no es administrador      
    }


    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docTurnoRef = docEmpRef.collection(colections.TURNO).doc(turno.id);
    const mensajeRef = docEmpRef.collection(colections.MENSAJE).doc();
    //revisando es administrador
    if(esAdministrador){            
      variacion.estado_cambio = SolicitarCambioType.APROBADO;
      variacion.usuario_aprobacion = correoUsuario;    
      } 
    //buscando si tiene montos añadidos previos
    if(turno.montos_retirados && turno.montos_retirados.length>0){
      variacionEfecExistente = [...turno.montos_retirados];      
    }
    variacionEfecExistente.push(variacion);
    
    //añadimos el objeto nuevo a la lista siendo o no siendo administrador   
    
    try{
      //creamos la transaccion 
      const txResult = await this.db.firestore.runTransaction(async (transaccion) => {
        transaccion.update(docTurnoRef.ref,{
          montos_retirados: variacionEfecExistente,
          usuario_modificacion: correoUsuario,
          fecha_modificacion: serverTimestamp()
        });

        if(!esAdministrador){
          const mensaje:Mensaje={
            es_vigente:true,
            estado_cambio:SolicitarCambioType.SOLICITADO,
            id_ref: turno.id?turno.id:'',
            usuario_creacion: correoUsuario,
            proceso:ProcesoType.CAMBIAR_RETIRAR_MONTO,
            fecha_creacion:serverTimestamp(),
            es_borrado:false,
            actual_pedido: variacion.monto,
            modulo: goOptionSidenavItem.modulo!           
          }
          transaccion.set(mensajeRef.ref, mensaje);
        }

        this.transaccion.tx = true;
        return this.transaccion;
        
      });
      return txResult;

    }
    catch(error){
      
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }

  }


  async updateTurnoProcesarAgregar(turno:Turno,idEmpresa:string,correoUsuario:string, accion: SolicitarCambioType |null){
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(colections.TURNO).doc(turno.id);
    
    //recuperando msj para eliminarlo
    let confMsj = await docEmpRef.collection(colections.MENSAJE, ref2=>
      ref2.where(documentMensaje.ID_REF,'==',turno.id)
      .where(documentMensaje.ESTADO_CAMBIO,'==',SolicitarCambioType.SOLICITADO)
      .where(documentMensaje.PROCESO,'==',ProcesoType.CAMBIAR_ANADIR_MONTO)
      ).get().toPromise();
    let mensajeDocs = confMsj.docs.map((doc: { ref: any; }) => doc.ref);
    
    
    let listaVariacion:VariacionEfectivo[]= [];
    const fechaKey:Fecha ={
      anio:turno.fecha_key.anio,
      mes:turno.fecha_key.mes,
      dia:turno.fecha_key.dia,
      hora:turno.fecha_key.hora,
      minuto:turno.fecha_key.minuto,
      segundo:turno.fecha_key.segundo
    }     
    if(turno.montos_aniadidos && turno.montos_aniadidos.length>0){
      const usuarioSolicitante = turno.montos_aniadidos.filter(montoAdd=>
        montoAdd.estado_cambio== SolicitarCambioType.SOLICITADO &&
        montoAdd.es_vigente
        );

      //cambiamos de estado la solicitud
      turno.montos_aniadidos.forEach(variacionx=>{
        if(variacionx.estado_cambio==SolicitarCambioType.SOLICITADO && 
          variacionx.es_vigente){
            variacionx.es_vigente = false;
          }
      });
      listaVariacion = turno.montos_aniadidos;
      listaVariacion.push({
        es_vigente: accion== SolicitarCambioType.APROBADO? true: false, // para mostrar solo los vigentes y en el historial los no vigentes
        estado_cambio : accion== SolicitarCambioType.APROBADO? SolicitarCambioType.APROBADO: SolicitarCambioType.RECHAZADO,
        fecha_key : fechaKey,
        monto : turno.monto_inicial_efectivo,
        proceso : ProcesoType.CAMBIAR_ANADIR_MONTO,
        usuario_creacion : usuarioSolicitante[0].usuario_creacion,
        usuario_aprobacion:correoUsuario,        
      });     
    }
    try{
      const txResult = await this.db.firestore.runTransaction(async (transaccion) => {
        //actualizando turno
        transaccion.update(docConfigRef.ref,{
          montos_aniadidos: listaVariacion,
          usuario_modificacion: correoUsuario,
          fecha_modificacion: serverTimestamp()
        });

        //borrando mensaje
        transaccion.delete(mensajeDocs[0]);//siempre existirá un solo documento

        this.transaccion.tx = true;
        return this.transaccion;
        
      });
      return txResult;
    }
    catch(error){
      
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

  async updateTurnoProcesarRetirar(turno:Turno,idEmpresa:string,correoUsuario:string, accion: SolicitarCambioType |null){
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(colections.TURNO).doc(turno.id);
    
    //recuperando msj para eliminarlo
    let confMsj = await docEmpRef.collection(colections.MENSAJE, ref2=>
      ref2.where(documentMensaje.ID_REF,'==',turno.id)
      .where(documentMensaje.ESTADO_CAMBIO,'==',SolicitarCambioType.SOLICITADO)
      .where(documentMensaje.PROCESO,'==',ProcesoType.CAMBIAR_RETIRAR_MONTO)
      ).get().toPromise();
    let mensajeDocs = confMsj.docs.map((doc: { ref: any; }) => doc.ref);
    
    // creamos el nuevo objeto de retiro
    let listaVariacion:VariacionEfectivo[]= [];
    const fechaKey:Fecha ={
      anio:turno.fecha_key.anio,
      mes:turno.fecha_key.mes,
      dia:turno.fecha_key.dia,
      hora:turno.fecha_key.hora,
      minuto:turno.fecha_key.minuto,
      segundo:turno.fecha_key.segundo
    }
    if(turno.montos_retirados && turno.montos_retirados.length>0){
      const usuarioSolicitante = turno.montos_retirados.filter(montoAdd=>
        montoAdd.estado_cambio== SolicitarCambioType.SOLICITADO &&
        montoAdd.es_vigente
        );
      //cambiamos de estado la solicitud
      turno.montos_retirados.forEach(variacionx=>{
        if(variacionx.estado_cambio==SolicitarCambioType.SOLICITADO && 
          variacionx.es_vigente){
            variacionx.es_vigente = false;
          }
      });
      listaVariacion = turno.montos_retirados;
      listaVariacion.push({
        es_vigente: accion== SolicitarCambioType.APROBADO? true: false, // para mostrar solo los vigentes y en el historial los no vigentes
        estado_cambio : accion== SolicitarCambioType.APROBADO? SolicitarCambioType.APROBADO: SolicitarCambioType.RECHAZADO,
        fecha_key : fechaKey,
        monto : turno.monto_inicial_efectivo,
        proceso : ProcesoType.CAMBIAR_RETIRAR_MONTO,
        usuario_creacion : usuarioSolicitante[0].usuario_creacion,
        usuario_aprobacion: correoUsuario,        
      });     
    }
    try{
      const txResult = await this.db.firestore.runTransaction(async (transaccion) => {
        //actualizando turno
        transaccion.update(docConfigRef.ref,{
          montos_retirados: listaVariacion,
          usuario_modificacion: correoUsuario,
          fecha_modificacion: serverTimestamp()
        });

        //borrando mensaje
        transaccion.delete(mensajeDocs[0]);//siempre existirá un solo documento

        this.transaccion.tx = true;
        return this.transaccion;
        
      });
      return txResult;
    }
    catch(error){
      
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

}
