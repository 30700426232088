import { Component, OnInit } from '@angular/core';
import { DatosPlataforma } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';

@Component({
  selector: 'app-terminos-privacidad',
  templateUrl: './terminos-privacidad.component.html',
  styleUrls: ['./terminos-privacidad.component.scss']
})
export class TerminosPrivacidadComponent implements OnInit {

  constructor() { }
  readonly Icons: typeof Icons = Icons;
  titleModal:string='Políticas de privacidad';
  readonly DatosPlataforma: typeof DatosPlataforma = DatosPlataforma;


  ngOnInit(): void {
  }

}
