import { Component, Inject } from "@angular/core";
import { Icons } from "../../cons/icons";
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector:'app-success-snackbar',
  template:`
    <div class="error">
      <mat-icon class="icon" >{{icono}}</mat-icon>
      <span class="msj" >{{msj}}</span>
    </div>
  `,
  styles:[`
    .error{
    display: flex;
    flex-direction: row;
  }
  .msj{
    padding-left:10px;
    padding-top:5px;
    color: var(--white);
    font-size: 14px;
  }
  .icon{
    color: var(--blueA400);
  }
  `]
})
export class SuccessSnackBarComponent{
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any){}
  msj:string = this.data;
  icono:string = Icons.SUCCESS;
}
