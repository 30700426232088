<div fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title >
    <mat-icon class="icon">{{Icons.INFO}}</mat-icon>
    {{titleModal}}
  </div>
  <!-- <app-button type="icon" color="gray" [icon_fig]="CLOSE" (click)="onClose()" ></app-button> -->
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <div *ngFor=" let modulo of datoPasado.listaModulos" >
    <h3 class="title-modulo">{{modulo.nombre}}</h3>
    <p style="text-align: justify;" >{{modulo.descripcion}}
      <span *ngIf="modulo.orden==1">Este modulo no tiene costo.</span>
      <span *ngIf="modulo.orden!=1">El costo de uso es de <span style="font-weight: bold;">S/ {{modulo.costo_modulo}}.00</span>/mes </span>
      <span *ngIf="modulo.orden!=1">, e incluye <span style="font-weight: bold;"> {{modulo.cantidad_usuarios_x_defecto}}</span> usuario(s). </span>
      <span *ngIf="modulo.orden!=1">El costo adicional por usuario es <span style="font-weight: bold;">S/ {{modulo.costo_usuario_adicional}}.00</span>/mes  </span>
    </p>
    <mat-divider></mat-divider>
  </div>
  <div style="margin-top: 15px;">
    <p style="text-align: justify;">La tarifa será abonada a la cuenta corriente de la empresa {{datosEmpresa.NOMBRE_EMPRESA}}, dentro de los últimos 5 días del ciclo mensual de facturación (período de 30 días calendarios contados a partir de la fecha de alta en la plataforma).:</p>
    <ul>
      <li>Banco de Crédito: 1111111111111 </li>
      <li>Banco Interbank: 1111111111111 </li>
    </ul>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Aceptar</button>
</mat-dialog-actions>
