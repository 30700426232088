import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Length_Database, Msjs, Msjs_Validations, PatternsRegex, SistOperation, ListaTipoProducto } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { FileTypes } from 'src/app/shared/types/file-types';
import { Producto } from '../models/Producto';
import { Subscription } from 'rxjs';
import { UploadService } from '../../../upload/upload.service';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { UIService } from 'src/app/shared/services/ui.services';
import { SpinnerPageService } from '../../../spinner-page/spinner-page.service';
import { DialogProductoBlService } from '../services/services.bl/dialog-producto.bl.service';
import { PATH_API_FILES } from 'src/app/shared/cons/config';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { Utils } from 'src/app/shared/helpers/utils';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { DialogBuscarProductoService } from '../dialog-buscar-producto/dialogBuscarProducto.service';
import { IProductoAnidado } from '../models/IProductoAnidado';



@Component({
  selector: 'app-dialog-producto',
  templateUrl: './dialog-producto.component.html',
  styleUrls: ['./dialog-producto.component.scss'],


})
@AutoUnsubscribe
export class DialogProductoComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  readonly Msjs: typeof Msjs = Msjs;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly SistOperation: typeof SistOperation = SistOperation;
  readonly ListaTipoProducto : typeof ListaTipoProducto = ListaTipoProducto;

  fileType:string = FileTypes.IMAGE;
  modalSb!: Subscription;
  pathFileAPI = PATH_API_FILES.PRODUCTO_IMAGE_URL;
  titleModal:string='';
  isLoading:boolean=false;
  esModoEdicion:boolean = false;
  esModoVer:boolean = false;
  label:string='Imágenes (3 máx.):';
  sizeMaxHeightWidth:string='alto 270px - ancho 300px';
  //disabled_controls:boolean=false;
  files_uploaded:any[]=[];
  producto: Producto = new Producto();
  files_upload_subs!:Subscription;
  files_upload:File[] = [];
  fechaHoy!:string;
  estadoProductoEdicion:boolean=false;
  productoAnidadoSb= new Subscription();
  tiene_prod_anidado_anterior:boolean=false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef: MatDialogRef<DialogProductoComponent>,
    private uploadService:UploadService,
    private registroService: RegistroService,
    private uiService:UIService,
    private readonly spinnerPageService: SpinnerPageService,
    private DialogProductoBLService: DialogProductoBlService,
    private functionService: FunctionsService,
    private dialogBuscarProductoSrv:DialogBuscarProductoService
  ) { 

  }

  async ngOnInit(): Promise<void> {

    switch(this.datoPasado.operacion){
      case SistOperation.NUEVO:{
        this.titleModal = 'Nuevo Producto';
        //es true por defecto cuando es nuevo
        this.producto.es_vigente=true;
        this.fechaHoy = await this.functionService.GetDateServer() ;
        break;
      }
      case SistOperation.EDITAR:{
        this.titleModal = 'Editar Producto';
        this.esModoEdicion=true;
        this.cargarDatosEdicion();
        this.fechaHoy = await this.functionService.GetDateServer() ;
        break;

      }
      case SistOperation.VER:{
        this.esModoVer = true;
        this.titleModal = 'Ver Producto';
        this.esModoEdicion=false;
        this.cargarDatosEdicion();
        //this.disabled_controls = true;
      }
    }

    this.files_upload_subs = this.uploadService.files_upload$.subscribe((uploads)=>{
      this.files_upload = uploads.images_upload;
      //edita cuando eliminas una imagen en modo EDICION
      if(this.esModoEdicion){
        this.files_uploaded =  uploads.images_uploaded;
      }
    });

    this.productoAnidadoSb = this.dialogBuscarProductoSrv.productosAnidados.subscribe((listaProductoAnidado:IProductoAnidado[])=>{
      //comprobamos que el producto no se agrege a si mismo
      if(this.esModoEdicion){
        const prodDuplicado = listaProductoAnidado.filter(prodAnid=>prodAnid.id==this.producto.id);
        if(prodDuplicado.length>0){
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_DOBLE_PRODUCTO, 3000);
          if(this.producto.subproductos==undefined){
            this.producto.tiene_subproductos = false;
          }
          return;
        }
        //validando que el prod A contenga a B, pero que B no contenga a A
        const prodCruzado = listaProductoAnidado.filter(
          prodAnid=>prodAnid.producto.id_productos_anidados_completo?.filter(idAnid=> idAnid===this.producto.id)
          );
        if(prodCruzado.length>0){
          let msj = prodCruzado[0].producto.nombre_producto+' contiene a '+this.producto.nombre_producto;
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_DOBLE_CRUZADO.replace('$',msj), 7000);
          if(this.producto.subproductos==undefined){
            this.producto.tiene_subproductos = false;
          }
          return;

        }
      }
      listaProductoAnidado.forEach(productoAniadido=>{
        if(!this.producto.subproductos){
          this.producto.subproductos= new Array();
          this.producto.id_productos_anidados_completo = new Array();
        }
        this.producto.subproductos?.push(productoAniadido);
        this.producto.id_productos_anidados_completo?.push(productoAniadido.id);
      });
    });
  }

  cargarDatosEdicion(){
    this.producto ={...this.datoPasado.data as Producto};
    this.uploadService.removeAllFiles();
    this.files_uploaded = this.producto.imagen==undefined?[]:Utils.ObjecToListToArray(this.producto.imagen);
    this.estadoProductoEdicion = this.producto.es_vigente;
    if(this.producto.tiene_subproductos){
      this.tiene_prod_anidado_anterior = true;
    }

  }

  onClose(){
    this.uiService.closeDialogProducto();
  }

  async changeTipoProducto(valor:string){
    if(this.datoPasado.operacion==SistOperation.NUEVO){
      this.producto.codigo_producto = Utils.generarCodigoRandom(6);
    }
  }

  onSubmit(f:NgForm){
    if(f.valid){
      this.modalSb= this.uiService.showModalQuestions({title:Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon:Icons.QUESTION_MARK})
      .subscribe(async result=>{
        if(result){
          this.spinnerPageService.show();
          if(this.producto.subproductos && this.producto.subproductos.length==0 ){
            delete this.producto.subproductos;
            delete this.producto.id_productos_anidados_completo;
          }
          this.producto.nombres_completo = Utils.sanitizarNombre(this.producto.nombre_producto);
          if(!this.esModoEdicion){
            //comprobar existencia de codigo de producto
            const noExisteCodigo:boolean = await this.DialogProductoBLService.getProductoXCodigo(this.producto.codigo_producto);
            if(!noExisteCodigo){
              this.spinnerPageService.hide();
              const msj = Msjs.MSJ_CODIGO_EXISTENTE.replace('$','producto')
              this.uiService.ShowSnackBar(SnackBarType.WARNING, msj, 5000);
              return;
            }
            //no se permite el precio 0
            if(this.producto.precio_unitario==0){
              this.spinnerPageService.hide();
              this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_PRECIO_CERO, 5000);
              return;
            }
            //this.disabled_controls=true;

            this.DialogProductoBLService.insertProducto(this.producto,this.files_upload,this.pathFileAPI).then(tx=>{
              if(tx.tx){
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
              }
              else{
                this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                this.spinnerPageService.hide();
              }
                //para retornar el valor y recargar la tabla princial siempre en cuando la pagina sea mayor a uno
                this.dialogRef.close({tx_reload:true});
            });

          }
          else{
            this.DialogProductoBLService.updateProducto(
              this.producto, this.files_upload, this.files_uploaded, this.pathFileAPI,
              this.estadoProductoEdicion,
              this.tiene_prod_anidado_anterior
            ).then(res=>{
              if(res.tx){
                this.uiService.closeDialogProducto();
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 7000);
                this.spinnerPageService.hide();
              }else{
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);
              }
            })

          }
        }
      });
    }
  }

  Limpiar(f:NgForm){
    if(this.datoPasado.operacion==SistOperation.NUEVO){
      this.producto = new Producto();
    }else{
      const codigoProducto = this.producto.codigo_producto;
      this.producto = new Producto();
      this.producto.codigo_producto = codigoProducto;
    }
  }

  AniadirProducto(e:any){
    this.producto.tiene_subproductos=!this.producto.tiene_subproductos
    if(e.checked){
      this.uiService.showDialogBuscarAniadirProducto(null);
    }else{
      if(this.producto.subproductos){
        this.producto.subproductos=[];
        this.producto.id_productos_anidados_completo=[];
        //TODO: EN EL UPDATE ELIMINAR ESTOS DOS CAMPOS
      }
    }
  }

  AniadirProductoBtn(){
    this.uiService.showDialogBuscarAniadirProducto(null);
    
  }

  AniadirCaracteristicas(){
    this.producto.tiene_caracteristicas=!this.producto.tiene_caracteristicas
    if(this.producto.tiene_caracteristicas){
      const dialogCaract = this.uiService.showDialogCaractProducto(null);
      dialogCaract.afterClosed().subscribe((caracteristicas:string[])=>{
        if(caracteristicas.length>0){
          this.producto.lista_caracteristicas = caracteristicas;
        }
        else{
          this.producto.tiene_caracteristicas=false;
        }
      });

    }else{
      this.producto.lista_caracteristicas=[];
    }
  }

  AniadirCaracteristicasBtn(){
    const dialogCaract = this.uiService.showDialogCaractProducto(null);
      dialogCaract.afterClosed().subscribe((caracteristicas:string[])=>{
        if(caracteristicas.length>0){
          caracteristicas.forEach(car=>{
            this.producto.lista_caracteristicas?.push(car);
          })
          
        }        
      });
  }

  completarDecimal(event:any){
    let valor = parseFloat(event.target.value);
    if (!isNaN(valor)) {
      event.target.value = valor.toFixed(2);
    }
  }

  seleccionarTodo(event:any){
    event.target.select();
  }
  removerProducto(producto:IProductoAnidado){
    if(this.producto.subproductos){
      const listaDepurada = this.producto.subproductos.filter(prod=>prod.id!==producto.id);
      this.producto.subproductos = [...listaDepurada];
      if(this.producto.subproductos.length==0){
        this.producto.tiene_subproductos = false;
      }
    }


  }

  removerCaracteristica(indice:number){
    this.producto.lista_caracteristicas?.splice(indice,1);
  }



}
