import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { IDescuento } from '../../dialog/models/IDescuento';
import { Utils } from 'src/app/shared/helpers/utils';
import { serverTimestamp } from 'firebase/firestore';
import { colections } from 'functions/src/class_interface';
import { Auditoria, documentsEmpresa } from 'src/app/shared/cons/db.colections';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GestionDescuentosDaService {
  transaccion: TransaccionModel = new TransaccionModel();

  private dsctoCache: BehaviorSubject<IDescuento | undefined | null> = new BehaviorSubject<IDescuento | undefined |null>(undefined);
  public dscto$: Observable<IDescuento | undefined |null> = this.dsctoCache.asObservable();
  private fetchSb!: Subscription;


  constructor(
    private db: AngularFirestore,
  ) { }

  fetchDscto(idEmpresa:string,idUsuario:string): void {   
    if(this.fetchSb &&!this.fetchSb.closed){
      this.dsctoCache.next(this.dsctoCache.value);
      return;
    }

    this.fetchSb = this.getConfiguracionDesc(idEmpresa, idUsuario).subscribe(tasas => {
      this.dsctoCache.next(tasas);
    });
  }

  stopFetchingDscto(): void {
    if (this.fetchSb) {
      this.fetchSb.unsubscribe();
    }
    this.dsctoCache.next(undefined);
  }

  insertDscto(idEmpresa:string, correoUsuario:string, dsctoIncidencia: IDescuento){
    dsctoIncidencia.usuario_creacion = correoUsuario;
    const confDscto = Utils.SerializeJsonToDb(dsctoIncidencia);
    confDscto.fecha_creacion = serverTimestamp();
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(colections.CONFIGURACION_DESCUENTOS);
    return docConfigRef.add(confDscto).then(result=>{
      this.transaccion.tx = true;
      this.transaccion.data = result.id
      return this.transaccion;
    }).catch((error)=>{
      this.transaccion.tx = false;
      this.transaccion.error = error;
      return this.transaccion;
    });
  }

  getConfiguracionDesc(idEmpresa:string, idUsuario:string){
    return this.db.collection(colections.EMPRESA, (ref)=>
    ref.where(Auditoria.es_borrado,'==',false)
    .where(documentsEmpresa.ID_DOC_USUARIO,'==',idUsuario)
    ).doc(idEmpresa).collection(colections.CONFIGURACION_DESCUENTOS, (ref1)=>
     ref1.where(Auditoria.es_borrado,'==',false).limit(1) )
     .snapshotChanges()
     .pipe(
      map(configuracion=>{
       if(configuracion.length>0){
         const config = Utils.convertDate(configuracion[0].payload.doc.data()) as IDescuento;
         config.id = configuracion[0].payload.doc.id;
         return config;
       }
       else{
         return null;
       }
     }));
   }
  
   updateDcto(dscto:IDescuento, idEmpresa:string, correoUsuario:string){
    const idDscto = dscto.id;
    delete dscto.id;
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(colections.CONFIGURACION_DESCUENTOS);

    return docConfigRef.doc(idDscto).update({
      lista_descuento: dscto.lista_descuento,      
      fecha_modificacion: serverTimestamp(),
      usuario_modificacion: correoUsuario
    }).then(result=>{
      this.transaccion.tx = true;
      this.transaccion.data = result;
      return this.transaccion;
    }).catch((error)=>{
      this.transaccion.tx = false;
      this.transaccion.error = error;
      return this.transaccion;
    });
  }

}
