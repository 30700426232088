import { Injectable } from '@angular/core';
import { GestionAmbientesDaService } from '../../../gestion-ambientes-mesas/services/services.da/gestion-ambientes.da.service';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { AspectosGenerales } from '../../models/AspectosGenerales';
import { GestionAspectosDaService } from '../services.da/gestion-aspectos.da.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GestionAspectosBlService {

  constructor(
    private gestionAspectosDAServices:GestionAspectosDaService,
    private registroService: RegistroService
  ) { }

  existFetchTasas(){
    const idEmpresa = this.registroService.empresa.id;
    const idUsuario = this.registroService.usuario.id;
    this.gestionAspectosDAServices.fetchTasasMesa(idEmpresa,idUsuario);
  }

  getTasas():Observable<AspectosGenerales | null | undefined>{
    return this.gestionAspectosDAServices.tasas$;
  }


  insertTasas(aspectosGenerales:AspectosGenerales){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionAspectosDAServices.insertTasas(aspectosGenerales, idEmpresa,correoUsuario);

  }
  updateTasas(aspectosGenerales:AspectosGenerales, idConfig:string){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.gestionAspectosDAServices.updateTasas(aspectosGenerales,idEmpresa,correoUsuario, idConfig);
  }
}
