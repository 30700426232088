import { Component, Input, OnInit } from '@angular/core';
import { KeyboardService } from '../../services/ui.teclado.service';
import { ITableEvento } from '../table/ITableEvento';

@Component({
  selector: 'app-teclado-char-virtual',
  templateUrl: './teclado-char-virtual.component.html',
  styleUrls: ['./teclado-char-virtual.component.scss']
})
export class TecladoCharVirtualComponent implements OnInit {

  @Input() key:string ='';
  constructor(private KeyboardService: KeyboardService) { }

  ngOnInit(): void {
  }

  sendKey(input: string) {
    const callEvento:ITableEvento = {
      key:this.key,
      data:input
    }
    this.KeyboardService.keyPress(callEvento);
  }

}
