import { AuditoriaSet } from "src/app/shared/models/auditoria.models";
import { ICatalogo } from "./ICatalogo";
import { IProducto } from "./IProducto";
import { ICarrousel } from "./ICarrousel";

export class Catalogo implements ICatalogo, AuditoriaSet{
  nombre_catalogo='';
  nombres_completo:string[]=[];
  auto_numerico!:number;
  codigo_catalogo:string='';
  descripcion_catalogo:string='';
  codigo_productos_anidados_completo:string[]=[];
  lista_productos:IProducto[]=[];
  es_borrado: boolean=false;
  es_vigente: boolean=false;
  usuario_creacion: any;
  usuario_modificacion?: any;
  fecha_creacion?: any;
  fecha_modificacion?: any;
  id?:string


  //propiedades utilitarias
  estado?:string;
  fecha_registro?:string
  masElementos?: boolean
  totalRegistros_query?:number
  previousPage?: boolean;
  nextPage?:boolean;
  numeracion?:number;
  totalRegistrosActivos?:number;
  totalRegistrosInactivos?:number;
  totalRegistros_doc?:number;
  total_productos?:number
 

  ref?:any
}
