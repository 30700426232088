import { Component, OnInit, Input, ViewChild, TemplateRef, OnDestroy, Inject } from '@angular/core';

import { Icons } from 'src/app/shared/cons/icons';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, OnDestroy {
  readonly Icons: typeof Icons = Icons;
  currentIndex = 0;
  autoplayInterval: any;
  @Input() slides:any[]=[];
  @Input() visibleEtiquetaFoto:boolean = true;

  constructor(

  ) {

  }

  ngOnInit(): void {
    this.startAutoplay();   
  }

  ngOnDestroy() {
    this.stopAutoplay();
  }

  startAutoplay() {
    this.autoplayInterval = setInterval(() => {
      this.nextImage();
    }, 3500); // Cambia la imagen cada 3 segundos
  }

  stopAutoplay() {
    if (this.autoplayInterval) {
      clearInterval(this.autoplayInterval);
    }
  }

  previousImage() {
    this.stopAutoplay();
    this.currentIndex = this.currentIndex > 0 ? this.currentIndex - 1 : this.slides.length - 1;
    this.startAutoplay();
  }

  nextImage() {
    this.stopAutoplay();
    this.currentIndex = this.currentIndex < this.slides.length - 1 ? this.currentIndex + 1 : 0;
    this.startAutoplay();
  }
}
