import { Injectable } from '@angular/core';
import { Utils } from 'src/app/shared/helpers/utils';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { serverTimestamp } from 'firebase/firestore';
import { colections } from 'functions/src/class_interface';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Auditoria, documentsEmpresa } from 'src/app/shared/cons/db.colections';
import { map } from 'rxjs/operators';
import { IIncidencia } from '../../dialog/models/IInsidencia';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GestionIncidenciasDaService {
  transaccion: TransaccionModel = new TransaccionModel();

  private incidCache: BehaviorSubject<IIncidencia | undefined | null> = new BehaviorSubject<IIncidencia | undefined |null>(undefined);
  public incidencia$: Observable<IIncidencia | undefined |null> = this.incidCache.asObservable();
  private fetchSb!: Subscription;

  constructor(
    private db: AngularFirestore,
  ) { }

  fetchIncidencia(idEmpresa:string,idUsuario:string): void {   
    if(this.fetchSb && !this.fetchSb.closed){
      this.incidCache.next(this.incidCache.value);
      return;
    }

    this.fetchSb = this.getConfiguracionIncidencia(idEmpresa, idUsuario).subscribe(tasas => {
      this.incidCache.next(tasas);
    });
  }

  stopFetchingIncidencia(): void {
    this.fetchSb?.unsubscribe();
    this.incidCache.next(undefined);
  }

  insertIncidencia(idEmpresa:string, correoUsuario:string, dsctoIncidencia: IIncidencia){
    dsctoIncidencia.usuario_creacion = correoUsuario;
    const confDscto = Utils.SerializeJsonToDb(dsctoIncidencia);
    confDscto.fecha_creacion = serverTimestamp();
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(colections.CONFIGURACION_INCIDENCIAS);
    return docConfigRef.add(confDscto).then(result=>{
      this.transaccion.tx = true;
      this.transaccion.data = result.id
      return this.transaccion;
    }).catch((error)=>{
      this.transaccion.tx = false;
      this.transaccion.error = error;
      return this.transaccion;
    });
  }

  getConfiguracionIncidencia(idEmpresa:string, idUsuario:string){
    return this.db.collection(colections.EMPRESA, (ref)=>
    ref.where(Auditoria.es_borrado,'==',false)
    .where(documentsEmpresa.ID_DOC_USUARIO,'==',idUsuario)
    ).doc(idEmpresa).collection(colections.CONFIGURACION_INCIDENCIAS, (ref1)=>
     ref1.where(Auditoria.es_borrado,'==',false).limit(1) )
     .snapshotChanges()
     .pipe(
      map(configuracion=>{
       if(configuracion.length>0){
         const config = Utils.convertDate(configuracion[0].payload.doc.data()) as IIncidencia;
         config.id = configuracion[0].payload.doc.id;
         return config;
       }
       else{
         return null;
       }
     }));
   }

   updateIncidencia(dscto:IIncidencia, idEmpresa:string, correoUsuario:string){
    const idDscto = dscto.id;
    delete dscto.id;
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(colections.CONFIGURACION_INCIDENCIAS);

    return docConfigRef.doc(idDscto).update({
      lista_incidencia: dscto.lista_incidencia,      
      fecha_modificacion: serverTimestamp(),
      usuario_modificacion: correoUsuario
    }).then(result=>{
      this.transaccion.tx = true;
      this.transaccion.data = result;
      return this.transaccion;
    }).catch((error)=>{
      this.transaccion.tx = false;
      this.transaccion.error = error;
      return this.transaccion;
    });
  }
}
