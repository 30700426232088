<div fxLayout="row" fxLayoutAlign="space-between start" class="header-dialog" >
  <div mat-dialog-title fxLayout="row" class="dialog-title">
    Registrarme
    <div class="title-step">
      (1 de 3)
    </div>
  </div>
  <app-button type="icon" color="gray" [icon_fig]="CLOSE" (click)="onClose()" ></app-button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
    <section>
      <h4>Realiza la operación:</h4>
    </section>
    <section *ngIf="isLoading" >
      <app-spinner></app-spinner>
    </section>
    <section *ngIf="!isLoading">
      <form fxLayout="column" #f="ngForm" (ngSubmit)="onSubmit(f)" >
        <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutGap.xs="0px" >
          <mat-form-field fxFlex="50%">
            <mat-label>
              <span class="label-captcha">{{lCaptcha[0]}} {{lCaptcha[3]}} {{lCaptcha[1]}}</span>
            </mat-label>
            <input
              class="input-captcha"
              type="text"
              matInput
              placeholder="Resuelva"
              ngModel
              name="operacion"
              disabled="true" >
          </mat-form-field>
          <mat-form-field >
            <input
              class="input-captcha"
              type="text"
              matInput
              placeholder="Resultado"
              ngModel
              pattern="[0-9]*"
              name="resultado"
              #resultadoInput="ngModel"
              required
              (keypress) ="onlyNumbers($event)"
              >
            <mat-error *ngIf="resultadoInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="resultadoInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
          </mat-form-field>
        </div>
        <mat-form-field [hintLabel]="Msjs_Validations.MSJ_VAL_REQ9_DIGITOS_MOVIL" >
          <mat-label>Número Teléfono Movil</mat-label>
          <input
            class="input-captcha"
            type="text"
            matInput
            ngModel
            name="numeroMovil"
            placeholder="000 000 000"
            required
            minlength="9"
            maxlength="9"
            pattern="[0-9]*"
            #numTelefonoInput="ngModel"
            (keypress) ="onlyNumbers($event)"
            >
            <mat-hint align="end" >{{numTelefonoInput.value?.length}} / 9</mat-hint>
            <mat-error *ngIf="numTelefonoInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="numTelefonoInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
            <mat-error *ngIf="numTelefonoInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_MIN9_DIGITOS}}</mat-error>
            <mat-error *ngIf="numTelefonoInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_MAX9_DIGITOS}}</mat-error>
        </mat-form-field>

        <div class="actions-dialog" fxLayout="row" fxLayoutAlign="space-between end" fxFlex>
          <app-button [width]="100" text="Continuar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="f.invalid" [attr_type]="'submit'" ></app-button>
          <app-button fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray" (click)="onClose()" ></app-button>
          <app-button fxShow.xs fxHide  type="outline" color="gray-b0" [icon_fig]="CANCEL" (click)="onClose()" ></app-button>
        </div>
      </form>
    </section>
  </mat-dialog-content>
