import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Utils } from 'src/app/shared/helpers/utils';
import { UploadService } from '../../upload/upload.service';
import { IEmpresa } from '../../dialog/models/empresa.model';
import { serverTimestamp, deleteField, runTransaction, getFirestore } from 'firebase/firestore';
import { Auditoria, colections, documentColaborador, documentRecibo, documentsEmpresa, documentsUsuarioColaborador } from 'src/app/shared/cons/db.colections';
import { UsuarioDAService } from './usuario.da.service';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { IModuloEmpresa } from '../../dialog/models/modulo.empresa.model';

import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { IUsuario } from '../../dialog/models/usuario.model';
import { IImageUploaded } from '../../upload/IImageUploaded';
import { url } from 'inspector';
import { IRolAcceso } from 'src/app/shared/models/item.model';
import { CodigoPais, SistOperation } from 'src/app/shared/cons/common';
import { IHorario } from '../../horario-trabajo/horario.model';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { CModuloRolSeleccionado } from '../../procesos/gestion-colaborador/models/modulo-rol-seleccionado';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { UsuarioColaboradorDaService } from './usuarioColaborador.da.service';
import { CColaborador } from '../../procesos/gestion-colaborador/models/ccolaborador.model';
import { CookiesTokensService } from 'src/app/shared/services/cookies.tokens.service';
import { Facturacion } from '../../procesos/gestion-colaborador/models/facturacion.model';
import { CostoFacturacion } from '../../procesos/gestion-colaborador/models/costo-facturacion.model';
import { IContadorDocumento } from 'src/app/shared/models/IContadorDocumentos';
import { CostoFacturacionVariacion } from '../../procesos/gestion-colaborador/models/costo-facturacion-variacion';
import { MontoModulo } from '../../procesos/gestion-colaborador/models/monto-modulo';
import { ReduccionUsuario } from '../../procesos/gestion-colaborador/models/reduccion-usuario.model';
import { IRecibo } from '../../procesos/gestion-colaborador/models/IRecibo';



@Injectable({
  providedIn: 'root',
})
@AutoUnsubscribe
export class EmpresaDAService {
  transaccion: TransaccionModel = new TransaccionModel();
  //subject que servirá de cache
  private empresaCache: BehaviorSubject<IEmpresa | null | undefined> =
    new BehaviorSubject<IEmpresa | null | undefined>(null);
  //variable expuesta pra consumo
  public empresa$: Observable<IEmpresa | null | undefined> =
    this.empresaCache.asObservable();
  //eliminar la suscripcion
  private fetchEmpresaSubscription!: Subscription;

  constructor(
    private db: AngularFirestore,
    private uploadService: UploadService,
    private registroService: RegistroService,
    private functionService: FunctionsService,
    private usuarioDAService: UsuarioDAService,
    private usuarioColaboradorDaService: UsuarioColaboradorDaService,
    private cookiesService: CookiesTokensService
  ) {}

  private removeUtilProperties(empresa: IEmpresa): IEmpresa {
    delete empresa.id;
    return empresa;
  }
  /*
  metodos cache
  */
  //solo llamar para recargar esta informacion
  fetchEmpresaById(idUsuario: string): void {
    if (
      this.fetchEmpresaSubscription &&
      !this.fetchEmpresaSubscription.closed
    ) {
      this.empresaCache.next(this.empresaCache.value);
      return;
    }
    // if(this.fetchEmpresaSubscription){
    //   this.fetchEmpresaSubscription.unsubscribe();
    // }
    this.fetchEmpresaSubscription = this.getEmpresaSnapshot(
      idUsuario
    ).subscribe((empresa) => {
      this.empresaCache.next(empresa);
    });
  }

  stopFetchingEmpresa(): void {
    if (this.fetchEmpresaSubscription) {
      this.fetchEmpresaSubscription.unsubscribe();
    }
    this.empresaCache.next(undefined);
  }
  /********************/

  private getEmpresaSnapshot(idUsuario: string) {
    return this.db
      .collection(colections.EMPRESA, (ref) =>
        ref
          .where(documentsEmpresa.ID_USUARIO_CUENTA, '==', idUsuario)
          .where(Auditoria.es_borrado, '==', false)
          .limit(1)
      )
      .snapshotChanges()
      .pipe(
        map((empresa) => {
          if (empresa.length > 0) {
            const datos = Utils.convertDate(
              empresa[0].payload.doc.data()
            ) as IEmpresa;
            const id = empresa[0].payload.doc.id;
            const data = { id, ...datos } as IEmpresa;
            return data;
          } else {
            return null;
          }
        })
      );
  }

  validarRUCUnico(ruc: string) {
    return this.db
      .collection(colections.EMPRESA, (ref) =>
        ref.where(documentsEmpresa.RUC, '==', ruc)
      )
      .get()
      .toPromise();
  }

  insertNuevaEmpresa(
    empresa: IEmpresa,
    files_upload: File[],
    pathFileAPI: string
  ) {
    empresa = { ...this.removeUtilProperties(empresa) };

    const docEmpresa = Utils.SerializeJsonToDb(empresa);
    docEmpresa.fecha_creacion = serverTimestamp();
    docEmpresa.id_doc_usuario = this.registroService.usuario.id;

    //si no contiene archivos a subir
    if (files_upload.length == 0) {
      return this.db
        .collection(colections.EMPRESA)
        .add(docEmpresa)
        .then((res) => {
          return this.db
            .collection(colections.USUARIO)
            .doc(this.registroService.usuario.id)
            .update({
              tiene_empresa: true,
              usuario_modificacion:
                this.registroService.usuario.correo_electronico,
              fecha_modificacion: serverTimestamp(),
            })
            .then(() => {
              //seteando empresa por unica vez
              this.registroService.empresa = empresa;
              this.registroService.empresa.id = res.id;
              this.registroService.usuario.tiene_empresa = true; // indica lo que esta en memoria y bd
              return { tx: true };
            });
        });
    }
    //si tiene archivos para subir TODO: escritura bucle
    else {
      return this.uploadService
        .onSaveFiles(files_upload, pathFileAPI)
        .then((urls) => {
          docEmpresa.logo_empresa = urls;
          return this.db
            .collection(colections.EMPRESA)
            .add(docEmpresa)
            .then((res) => {
              return this.db
                .collection(colections.USUARIO)
                .doc(this.registroService.usuario.id)
                .update({
                  tiene_empresa: true,
                  usuario_modificacion:
                    this.registroService.usuario.correo_electronico,
                  fecha_modificacion: serverTimestamp(),
                })
                .then(() => {
                  this.registroService.empresa = empresa;
                  this.registroService.empresa.logo_empresa = urls;
                  this.registroService.empresa.id = res.id;
                  this.registroService.usuario.tiene_empresa = true;
                  return { tx: true };
                });
            });
        });
    }
  }

  //se recupera con get para no escuchar a los cambios a partir del pipe
  getEmpresa(idUsuario: string | undefined) {
    return this.db
      .collection(colections.EMPRESA, (ref) =>
        ref
          //.where(Auditoria.es_vigente, '==', true)
          .where(Auditoria.es_borrado, '==', false)
          .where(documentsEmpresa.ID_USUARIO_CUENTA, '==', idUsuario)
      )
      .get()
      .toPromise()
      .then((doc) => {
        if (!doc.empty) {
          const datos = Utils.convertDate(doc.docs[0].data()) as IEmpresa;
          const id = doc.docs[0].id;
          const data = { id, ...datos } as IEmpresa;
          const empresasDoc: any[] | null = [];
          empresasDoc.push(data);
          return empresasDoc;
        } else {
          return null;
        }
      });
  }

  //editar modulos de empresa
  async updateEmpresaModulosEditar(
    modulosSeleccionados: IModuloEmpresa,
    idEmpresa: string,
    lista_modulos_seleccionadosAnteriores: any[],
    lista_decremento: ReduccionUsuario[]
  ): Promise<any> {
    //rescatando rol personalizado si existiera
    let rolesPersonalizadosTodos: any[] = [];
    lista_modulos_seleccionadosAnteriores.forEach((modulo) => {
      const roles = Utils.ObjectToArrayRol(modulo.roles);
      const rolesPersonalizados = roles.filter(
        (rolItem) => rolItem.personalizado == true
      );
      if (rolesPersonalizados.length > 0) {
        rolesPersonalizadosTodos.push({
          codigo: modulo.codigo,
          roles: rolesPersonalizados,
        });
      }
    });
    //merge de roles plantilla y rol personalizado
    if (rolesPersonalizadosTodos.length > 0) {
      modulosSeleccionados.lista_modulos_seleccionados.forEach((modulos) => {
        let moduloXModificar = rolesPersonalizadosTodos.filter(
          (rolP) => rolP.codigo == modulos.codigo
        )[0];
        if (moduloXModificar) {
          const rolPlantilla = Utils.ObjectToArrayRol(modulos.roles); //convirtiendo a array
          const rolTodos = rolPlantilla.concat(moduloXModificar.roles);
          modulos.roles = Utils.ArrayToObjectToObjectsRol(rolTodos); //convirtiendo a rol
        }
      });
    }
    //facturacion
    try {
      const transactionResult = this.db.firestore.runTransaction(
        async (trans) => {
          //formamos referencias
          const empRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
          const facturaRef = empRef.collection(colections.FACTURACION);
          const colab_resumenRef = empRef.collection(
            colections.COLABORADOR_RESUMEN
          );

          //facturacion
          let docFacturacion: Facturacion = new Facturacion();
          let idDocumentoFacturacion: string = '';
          await facturaRef
            .get()
            .toPromise()
            .then((facturacionSnapshot) => {
              if (!facturacionSnapshot.empty) {
                docFacturacion = Utils.convertDate(
                  facturacionSnapshot.docs[0].data()
                ) as Facturacion;
                idDocumentoFacturacion = facturacionSnapshot.docs[0].id;
              }
            });
          //seteando nueva actualizacion de facturacion
          docFacturacion.costo_facturacion =
            await this.generarFacturacionActualizada(
              modulosSeleccionados,
              docFacturacion,
              lista_decremento
            );
          //docFacturacion.lista_decremento =  lista_decremento;

          const finalDocCostoFacturacion = Utils.SerializeJsonToDb(
            docFacturacion.costo_facturacion
          );
          // finalDocFacturacion.fecha_modificacion = serverTimestamp();

          //resumen colaborador
          let docResumenColaborador!: IContadorDocumento;
          let idResumenColaborador: string = '';
          await colab_resumenRef
            .get()
            .toPromise()
            .then((resumenDoc) => {
              if (!resumenDoc.empty) {
                docResumenColaborador = Utils.convertDate(
                  resumenDoc.docs[0].data()
                ) as IContadorDocumento;
                idResumenColaborador = resumenDoc.docs[0].id;
              }
            });
          if (
            docResumenColaborador != undefined &&
            docResumenColaborador != null
          ) {
            docResumenColaborador = await this.generarResumenColaborador(
              docResumenColaborador,
              docFacturacion
            );
            const finalDocResumen = Utils.SerializeJsonToDb(
              docResumenColaborador
            );
            finalDocResumen.fecha_actualizacion = serverTimestamp();
            //updates
            trans.update(
              colab_resumenRef.doc(idResumenColaborador).ref,
              finalDocResumen
            );
          }

          //updates
          trans.update(empRef.ref, {
            lista_modulos_seleccionados: JSON.parse(
              JSON.stringify(modulosSeleccionados.lista_modulos_seleccionados)
            ),
            fecha_modificacion: serverTimestamp(),
            usuario_modificacion:
              this.registroService.usuario.correo_electronico,
          });

          trans.update(facturaRef.doc(idDocumentoFacturacion).ref, {
            costo_facturacion: finalDocCostoFacturacion,
            fecha_modificacion: serverTimestamp(),
          });

          this.transaccion = {
            tx: true,
          };
          return this.transaccion;
        }
      );
      return transactionResult;
    } catch (error) {
      //console.log(error);
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

  async generarCostoFacturacion(
    modulosSeleccionados: IModuloEmpresa
  ): Promise<CostoFacturacion[]> {
    let listaCostoFacturacion: CostoFacturacion[] = [];
    const fechaRegistro = await this.functionService.GetDateServer();
    modulosSeleccionados.lista_modulos_seleccionados.forEach((modulo) => {
      const costoFacturacion: CostoFacturacion = new CostoFacturacion();
      costoFacturacion.cantidad_total_usuarios = modulo.cantidad_total_usuarios;
      costoFacturacion.cantidad_usuarios_x_defecto =
        modulo.cantidad_usuarios_x_defecto;
      costoFacturacion.codigo_modulo = modulo.codigo;
      costoFacturacion.costo_usuario_adicional = modulo.costo_usuario_adicional;
      costoFacturacion.costo_modulo = modulo.costo_total;
      costoFacturacion.nombre_modulo = modulo.menu[0].modulo;
      costoFacturacion.fecha_creacion = fechaRegistro;
      costoFacturacion.es_activo = true;

      listaCostoFacturacion.push(costoFacturacion);
    });
    return listaCostoFacturacion;
  }

  generarCalculoVariacionUsuarioXModulo(
    moduloSelecionado: any,
    cantidadVariada: number,
    es_aumento_usuarios: boolean
  ): CostoFacturacionVariacion {
    const costoFactVariacion: CostoFacturacionVariacion =
      new CostoFacturacionVariacion();
    costoFactVariacion.cantidad_variacion = cantidadVariada;
    costoFactVariacion.costo_generado_total =
      moduloSelecionado.costo_usuario_adicional * cantidadVariada;
    costoFactVariacion.costo_generado_unitario =
      moduloSelecionado.costo_usuario_adicional;
    costoFactVariacion.es_aumento_usuarios = es_aumento_usuarios;
    return costoFactVariacion;
  }

  async generarFacturacionActualizada(
    modulosSeleccionados: IModuloEmpresa,
    facturacionExistente: Facturacion,
    lista_decremento: ReduccionUsuario[]
  ): Promise<CostoFacturacion[]> {
    let listaCostoFacturacion: Facturacion = new Facturacion();
    const fechaRegistro = await this.functionService.GetDateServer();

    //falseando todos los modulos actuales para luego activarlos y confirmando modulos existentes.
    facturacionExistente.costo_facturacion.forEach((modulo) => {
      modulo.es_activo = false;
      modulo.es_borrado = false;
    });
    facturacionExistente.costo_facturacion.forEach((modulo) => {
      modulosSeleccionados.lista_modulos_seleccionados.forEach(
        (moduloSelec) => {
          if (modulo.codigo_modulo == moduloSelec.codigo) {
            modulo.es_activo = true;
            modulo.es_borrado = false;
            modulo.cantidad_total_usuarios =
              moduloSelec.cantidad_total_usuarios;
            //si no viene la propiedad, se debe remover
            if (moduloSelec.pendiente_anulacion_modulo == undefined) {
              if (modulo.pendiente_anulacion_modulo) {
                delete modulo.pendiente_anulacion_modulo;
              }
            }
            if (moduloSelec.pendiente_anulacion_modulo) {
              modulo.pendiente_anulacion_modulo = true;
            }
          }
        }
      );
    });
    //obteniendo los modulos confirmados
    listaCostoFacturacion = { ...facturacionExistente };

    //obteniendo nuevos modulos
    modulosSeleccionados.lista_modulos_seleccionados.forEach(
      (modulosSelect) => (modulosSelect.dummy_prop = true)
    );
    facturacionExistente.costo_facturacion.forEach((modulo, index) => {
      modulosSeleccionados.lista_modulos_seleccionados.forEach(
        (modulosSelect) => {
          if (modulo.codigo_modulo === modulosSelect.codigo) {
            modulosSelect.dummy_prop = false;
            //actualizando facturacion en caso de incrementar usuarios a algun modulo
            if (modulosSelect.costo_total != modulo.costo_modulo) {
              listaCostoFacturacion.costo_facturacion[
                index
              ].cantidad_total_usuarios = modulosSelect.cantidad_total_usuarios;
              listaCostoFacturacion.costo_facturacion[index].costo_modulo =
                modulosSelect.costo_total;
            }
          }
        }
      );
    });

    modulosSeleccionados.lista_modulos_seleccionados.forEach((moduloSelec) => {
      if (moduloSelec.dummy_prop == true) {
        const costoFacturacionNuevo = new CostoFacturacion();
        costoFacturacionNuevo.cantidad_total_usuarios =
          moduloSelec.cantidad_total_usuarios;
        costoFacturacionNuevo.cantidad_usuarios_x_defecto =
          moduloSelec.cantidad_usuarios_x_defecto;
        costoFacturacionNuevo.codigo_modulo = moduloSelec.codigo;
        costoFacturacionNuevo.costo_usuario_adicional =
          moduloSelec.costo_usuario_adicional;
        costoFacturacionNuevo.costo_modulo = moduloSelec.costo_total;
        costoFacturacionNuevo.nombre_modulo = moduloSelec.menu[0].modulo;
        costoFacturacionNuevo.fecha_creacion = fechaRegistro;
        costoFacturacionNuevo.es_activo = true;
        listaCostoFacturacion.costo_facturacion.push(costoFacturacionNuevo);
      }
    });

    //ini*limpieza de objeto porque funciona como puntero en js
    modulosSeleccionados.lista_modulos_seleccionados.forEach(
      (modulosSelect) => {
        delete modulosSelect.dummy_prop;
      }
    );
    //fin*

    //agregando variacion
    listaCostoFacturacion.costo_facturacion.forEach((moduloSelec) => {
      const listaDecremento = lista_decremento.filter(
        (item_dec) => item_dec.codigo_modulo == moduloSelec.codigo_modulo
      )[0];
      if (listaDecremento) {
        const objReduccion: ReduccionUsuario = new ReduccionUsuario();
        objReduccion.cantidad_reduccion = listaDecremento.cantidad_reduccion;
        objReduccion.cantidad_total_usuarios =
          listaDecremento.cantidad_total_usuarios;
        objReduccion.codigo_modulo = listaDecremento.codigo_modulo;
        objReduccion.es_decremento = true;
        objReduccion.fecha_registro = fechaRegistro;
        moduloSelec.cantidad_usuarios_variacion = objReduccion;
        moduloSelec.es_pendiente_calculo_variacion = true;
      } else {
        delete moduloSelec.cantidad_usuarios_variacion;
        moduloSelec.es_pendiente_calculo_variacion = false;
      }
    });

    return listaCostoFacturacion.costo_facturacion;
  }

  async generarResumenColaborador(
    docResumenColaborador: IContadorDocumento,
    docFacturacion: Facturacion
  ) {
    docResumenColaborador.cantidad_modulos_seleccionados.forEach(
      (modulo_resumen) => {
        docFacturacion.costo_facturacion.forEach((costo_facturacion) => {
          if (modulo_resumen.codigo === costo_facturacion.codigo_modulo) {
            //para actualizar la cantidad en aso exista variacion
            if (costo_facturacion.cantidad_usuarios_variacion != undefined) {
              modulo_resumen.cantidad_total_usuarios_permitidos =
                costo_facturacion.cantidad_usuarios_variacion
                  .cantidad_total_usuarios -
                costo_facturacion.cantidad_usuarios_variacion
                  .cantidad_reduccion;
            } else {
              //en caso no mantenemos la cantidad original
              modulo_resumen.cantidad_total_usuarios_permitidos =
                costo_facturacion.cantidad_total_usuarios;
            }
            if (costo_facturacion.pendiente_anulacion_modulo) {
              modulo_resumen.pendiente_anulacion_modulo = true;
            }
            if (costo_facturacion.pendiente_anulacion_modulo == undefined) {
              if (modulo_resumen.pendiente_anulacion_modulo) {
                delete modulo_resumen.pendiente_anulacion_modulo;
              }
            }
          }
        });
      }
    );
    const docResumen = { ...docResumenColaborador };
    return docResumen;
  }

  async updateEmpresaEstadoPago(empresa: any) {
    const empresaRef = this.db.collection(colections.EMPRESA).doc(empresa.empresa.id);
    const facturaRef = empresaRef.collection(colections.FACTURACION).doc(empresa.factura.id);
    const reciboRef = facturaRef.collection(colections.RECIBO).doc(empresa.recibo.id);
  
    try {
      const txResult = this.db.firestore.runTransaction(async (transaccion) => {
          transaccion.update(empresaRef.ref, { es_sin_pago: false });
          transaccion.update(reciboRef.ref, { pendiente_pago: false });
          this.transaccion = {
            tx: true,
          };
          return this.transaccion;
        }
      );

      return this.transaccion;
    } catch (error) {
      this.transaccion.tx = false;
      this.transaccion.data = error;
      return this.transaccion;
    }
    //probar esto
  }

  async updateEmpresaModulos(
    modulosSeleccionados: IModuloEmpresa,
    idEmpresa: string,
    idUsuario: string
  ): Promise<any> {
    const costoFacturacion: CostoFacturacion[] =
      await this.generarCostoFacturacion(modulosSeleccionados);
    try {
      let newFacturacionDoc: string = '';
      const transactionResult = await this.db.firestore.runTransaction(
        async (transaccion) => {
          const empRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
          const usuarioRef = this.db
            .collection(colections.USUARIO)
            .doc(this.registroService.usuario.id);
          const facturaRef = empRef.collection(colections.FACTURACION);

          const docFacturacion: Facturacion = new Facturacion();
          docFacturacion.dias_periodo_facturable = 30;
          docFacturacion.costo_facturacion = costoFacturacion;
          docFacturacion.id_empresa = idEmpresa;
          docFacturacion.id_usuario_cuenta = idUsuario;
          //docFacturacion.es_periodo_prueba = true;

          const finalDocFacturacion = Utils.SerializeJsonToDb(docFacturacion);
          finalDocFacturacion.fecha_registro = serverTimestamp();

          transaccion.update(usuarioRef.ref, {
            tiene_modulos: true,
            usuario_modificacion:
              this.registroService.usuario.correo_electronico,
            fecha_modificacion: serverTimestamp(),
          });
          transaccion.update(empRef.ref, {
            fecha_modificacion: serverTimestamp(),
            lista_modulos_seleccionados: JSON.parse(
              JSON.stringify(modulosSeleccionados.lista_modulos_seleccionados)
            ),
            usuario_modificacion:
              this.registroService.usuario.correo_electronico,
          });
          transaccion.set(facturaRef.doc().ref, finalDocFacturacion);
          this.transaccion = {
            tx: true,
            data: {
              idFacturacion: newFacturacionDoc,
            },
          };
          //se setea a mano por el asincronismo de la transaccion
          this.registroService.usuario.tiene_modulos = true;
          //se setea a mano por el asincronismo de la transaccion
          return this.transaccion;
        }
      );
      return transactionResult;
    } catch (error) {
      this.transaccion = { tx: false, error: error };
      return this.transaccion;
    }
  }

  updateEmpresaEstado(
    idDocEmpresa: string,
    estadoEmpresa: boolean,
    usuario: string
  ) {
    return this.db
      .collection(colections.EMPRESA)
      .doc(idDocEmpresa)
      .update({
        fecha_modificacion: serverTimestamp(),
        usuario_modificacion: usuario,
        es_vigente: !estadoEmpresa,
      })
      .then(() => {
        return { tx: true };
      })
      .catch((e) => {
        return {
          tx: false,
          e: e,
        };
      });
  }

  updateEmpresaEstadoEliminacion(
    idDocEmpresa: string,
    usuario: string,
    id_doc_usuario: string
  ) {
    this.transaccion = {
      tx: false,
      data: null,
    };

    //se realiza un borrado logico, se conserva su foto
    return this.db
      .collection(colections.EMPRESA)
      .doc(idDocEmpresa)
      .update({
        fecha_modificacion: serverTimestamp(),
        usuario_modificacion: usuario,
        es_vigente: false,
        es_borrado: true,
      })
      .then(() => {
        return this.db
          .collection(colections.USUARIO)
          .doc(id_doc_usuario)
          .update({
            fecha_modificacion: serverTimestamp(),
            usuario_modificacion: usuario,
            es_vigente: true,
            tiene_empresa: false,
            tiene_modulos: false,
          })
          .then(async () => {
            //funcion que elimina logicamente todos los colaboradores(tran.usuario_colaborador) y sus cuentas en firestore
            const resultTx = await this.functionService
              .DeleteCuentaAllColaboradores(idDocEmpresa)
              .toPromise();
            //console.log(resultTx);
            if (resultTx && resultTx.tx) {
              this.transaccion = {
                tx: resultTx.tx ? true : false,
                data: null,
              };
            }
            return this.transaccion;
          });
      });
  }

  updateEmpresaEliminarImagenPrevia(
    empresa: IEmpresa,
    files_uploaded: IImageUploaded[],
    pathFileAPI: string
  ) {
    empresa = { ...this.removeUtilProperties(empresa) };

    return new Promise<void>((resolve, reject) => {
      this.uploadService
        .depuraFiles(
          pathFileAPI,
          files_uploaded,
          this.registroService.empresa.logo_empresa
        )
        .then(() => {
          return this.db.firestore
            .collection(colections.EMPRESA)
            .doc(this.registroService.empresa.id)
            .update({ logo_empresa: deleteField() })
            .then(() => {
              if (this.registroService.empresa.logo_empresa) {
                delete this.registroService.empresa.logo_empresa;
              }
            })
            .then(() => {
              resolve();
              return { tx: true };
            })
            .catch((e) => {
              return {
                tx: false,
                e: e,
              };
            });
        });
    });
  }

  //imagen nueva y reemplaza a una o varias anteriores
  updateEmpresaAniadirReemplazarImagen(
    empresa: IEmpresa,
    files_upload_actual: File[],
    files_upload_anterior: IImageUploaded[],
    pathFileAPI: string
  ) {
    empresa = { ...this.removeUtilProperties(empresa) };

    let urlsAll: any;
    return new Promise<void>((resolve, reject) => {
      //depura files soporta que no se le envia imagenes previamnte cargadas tanto en nuevo y en edicion
      //depura files conserva imagenees residuales (files_upload) subidas de todo el grupo anterior
      this.uploadService
        .depuraFiles(
          pathFileAPI,
          files_upload_anterior,
          this.registroService.empresa.logo_empresa
        )
        .then(() => {
          return this.uploadService.onSaveFiles(
            files_upload_actual,
            pathFileAPI
          );
        })
        .then((urls) => {
          urlsAll = urls;
          const doc = Utils.SerializeJsonToDb(empresa);
          doc.fecha_modificacion = serverTimestamp();
          doc.usuario_modificacion =
            this.registroService.usuario.correo_electronico;
          doc.logo_empresa = urlsAll;
          return this.db.firestore
            .collection(colections.EMPRESA)
            .doc(this.registroService.empresa.id)
            .update(doc)
            .then(() => {
              return { tx: true };
            });
        })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //imagen nueva, no existe imagen anterior
  updateEmpresaAniadirImagen(
    empresa: IEmpresa,
    files_upload_actual: File[],
    pathFileAPI: string
  ) {
    empresa = { ...this.removeUtilProperties(empresa) };

    return new Promise<void>((resolve, reject) => {
      this.uploadService
        .onSaveFiles(files_upload_actual, pathFileAPI)
        .then((urls) => {
          const doc = Utils.SerializeJsonToDb(empresa);
          doc.fecha_modificacion = serverTimestamp();
          doc.usuario_modificacion =
            this.registroService.usuario.correo_electronico;
          doc.logo_empresa = urls;
          return this.db.firestore
            .collection(colections.EMPRESA)
            .doc(this.registroService.empresa.id)
            .update(doc)
            .then(() => {
              return { tx: true };
            });
        })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //se elimina la imagen anterior y no se reemplaa con ninguna
  updateEmpresaQuitarImagen(
    empresa: IEmpresa,
    files_upload_anterior: IImageUploaded[],
    pathFileAPI: string
  ) {
    empresa = { ...this.removeUtilProperties(empresa) };

    return new Promise<void>((resolve, reject) => {
      this.uploadService
        .depuraFiles(
          pathFileAPI,
          files_upload_anterior,
          this.registroService.empresa.logo_empresa
        )
        .then(() => {
          const doc = Utils.SerializeJsonToDb(empresa);
          doc.fecha_modificacion = serverTimestamp();
          doc.usuario_modificacion =
            this.registroService.usuario.correo_electronico;
          doc.logo_empresa = deleteField();
          return this.db.firestore
            .collection(colections.EMPRESA)
            .doc(this.registroService.empresa.id)
            .update(doc)
            .then(() => {
              if (this.registroService.empresa.logo_empresa) {
                delete this.registroService.empresa.logo_empresa;
              }
              return { tx: true };
            });
        })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateEmpresaNoModificarImagen(empresa: IEmpresa) {
    empresa = { ...this.removeUtilProperties(empresa) };

    return new Promise<void>((resolve, reject) => {
      const doc = Utils.SerializeJsonToDb(empresa);
      doc.fecha_modificacion = serverTimestamp();
      doc.usuario_modificacion =
        this.registroService.usuario.correo_electronico;
      this.db
        .collection(colections.EMPRESA)
        .doc(this.registroService.empresa.id)
        .update(doc)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updateEmpresaModuloRoles(
    rolesAniadidos: IRolAcceso[],
    indexModulo: number,
    indexRol: number,
    idDocEmpresa: string,
    lista_modulos_seleccionados: any[],
    usuario: string,
    operacion: string
  ) {
    const objRolA_Modificar = Utils.ObjectToArrayRol(
      lista_modulos_seleccionados[indexModulo].roles
    )[indexRol];
    const objRolModificado = rolesAniadidos[indexRol];
    lista_modulos_seleccionados[indexModulo].roles =
      Utils.ArrayToObjectToObjectsRol(rolesAniadidos);
    const moduloEditado = lista_modulos_seleccionados[indexModulo];
    const empresaRef = this.db
      .collection(colections.EMPRESA)
      .doc(idDocEmpresa).ref;
    const batch = this.db.firestore.batch(); // Crear una nueva instancia de batch

    let modulosRolesColaborador: CModuloRolSeleccionado[] = []; //cuando hay mas un modulo

    try {
      // Realizar operaciones de escritura de empresa tanto para NUEVO y EDICION
      batch.update(empresaRef, {
        fecha_modificacion: serverTimestamp(),
        usuario_modificacion: usuario,
        lista_modulos_seleccionados: JSON.parse(
          JSON.stringify(lista_modulos_seleccionados)
        ),
      });

      //solo cuando se edita se busca en la subcoleccion colaboradores
      if (operacion == SistOperation.EDITAR) {
        // Realizar operaciones de lectura
        const colaboradoresQuerySnapshot = await empresaRef
          .collection(colections.COLABORADOR)
          .where(documentColaborador.MODULO_ROL, 'array-contains', {
            codigo_modulo: moduloEditado.codigo,
            nombre_modulo: moduloEditado.nombre,
            rol: objRolA_Modificar.rol,
            personalizado: true,
            acceso: objRolA_Modificar.acceso,
          })
          .get();
        colaboradoresQuerySnapshot.forEach((colaboradorDoc) => {
          const colaboradorId = colaboradorDoc.id;
          const colaboradorData = colaboradorDoc.data(); //para rescatar los datos en caso de que tenga dos modulos
          const colaboradorRef = empresaRef
            .collection(colections.COLABORADOR)
            .doc(colaboradorId);

          if (colaboradorData) {
            //cuando tiene mas de un modulo asignado, creamos el objeto para no perder el modulo distinto al editado
            if (colaboradorData.modulo_rol.length > 1) {
              modulosRolesColaborador = new Array();
              colaboradorData.modulo_rol.forEach(
                (itemRol: CModuloRolSeleccionado) => {
                  if (itemRol.codigo_modulo == moduloEditado.codigo) {
                    itemRol.codigo_modulo = moduloEditado.codigo;
                    itemRol.nombre_modulo = moduloEditado.nombre;
                    itemRol.rol = objRolModificado.rol;
                    itemRol.personalizado = true;
                    itemRol.acceso = objRolModificado.acceso;
                  }
                }
              );
              modulosRolesColaborador = [...colaboradorData.modulo_rol];
            }
            //cuando solo tiene un modulo asignado
            else {
              modulosRolesColaborador = new Array();
              modulosRolesColaborador.push({
                codigo_modulo: moduloEditado.codigo,
                nombre_modulo: moduloEditado.nombre,
                rol: objRolModificado.rol,
                personalizado: true,
                acceso: objRolModificado.acceso,
              });
            }
          }
          batch.update(colaboradorRef, {
            modulo_rol: JSON.parse(JSON.stringify(modulosRolesColaborador)),
          });
        });
      }

      await batch.commit();

      this.registroService.empresa.lista_modulos_seleccionados =
        lista_modulos_seleccionados;
      this.registroService.empresa.usuario_modificacion =
        this.registroService.usuario.correo_electronico;

      return {
        tx: true,
        data: '',
      };
    } catch (error) {
      return {
        tx: false,
        e: error,
      };
    }
  }

  deleteEmpresaModuloRoles(
    rolesAniadidos: IRolAcceso[],
    indexModulo: number,
    idDocEmpresa: string,
    lista_modulos_seleccionados: any[],
    usuario: string
  ) {
    //modificando con el nuevo rol
    lista_modulos_seleccionados[indexModulo].roles = rolesAniadidos;

    return this.db
      .collection(colections.EMPRESA)
      .doc(idDocEmpresa)
      .update({
        fecha_modificacion: serverTimestamp(),
        usuario_modificacion: usuario,
        lista_modulos_seleccionados: JSON.parse(
          JSON.stringify(lista_modulos_seleccionados)
        ),
      })
      .then(() => {
        //TODO: se debe actualizar el rol en el usuario colaborador********* liberar usuarios en
        //caso se elimine un rol
        return { tx: true };
      })
      .catch((e) => {
        return {
          tx: false,
          e: e,
        };
      });
  }

  updateEmpresaHorarioLaboral(
    horarioLaboral: IHorario,
    idEmpresa: string,
    usuario: string
  ) {
    return this.db
      .collection(colections.EMPRESA)
      .doc(idEmpresa)
      .update({
        fecha_modificacion: serverTimestamp(),
        usuario_modificacion: usuario,
        horario_trabajo_gral: horarioLaboral,
      })
      .then(() => {
        return { tx: true };
      })
      .catch((error) => {
        return {
          tx: false,
          e: error,
        };
      });
  }

  //get usuario administrador/ colaborador y empresa, con seteo a mano

  getUsuarioAdministradorColaboradorEmpresa(uid: string) {
    //asumimos que se actualizó con f5
    this.usuarioDAService.fetchUsuarioById(uid);
    let getUsuarioSb!: Subscription;
    let getColaboradorSb!: Subscription;
    let getEmpresaSb!: Subscription;

    getUsuarioSb = this.usuarioDAService.usuario$.subscribe((user) => {
      //si usuario administrador
      if (user) {
        //cargamos empresa
        this.fetchEmpresaById(user.id_usuario_cuenta);
        getEmpresaSb = this.empresa$.subscribe((empresa) => {
          if (empresa) {
            this.registroService.empresa = empresa;
            this.registroService.esSuperAdministrador = true;
            this.registroService.esAdministrador = true;
            this.registroService.empresa.lista_modulos_seleccionados =
              Utils.ObjecModuloToListToArray(
                this.registroService.empresa.lista_modulos_seleccionados
              );
            this.registroService.usuario = user;
            this.cookiesService.setUser(user);
            //recarga de usuario
            this.registroService.reloadUsuario$.next(true);
            getUsuarioSb.unsubscribe();
            getEmpresaSb.unsubscribe();
          }
        });
      } else {
        //si usuario colaborador
        this.usuarioColaboradorDaService.fetchUserColabEmpById(uid);
        getColaboradorSb =
          this.usuarioColaboradorDaService.usuarioColaboradorEmpresa$.subscribe(
            (colaborador) => {
              if (colaborador) {
                this.registroService.usuario = colaborador;
                this.registroService.empresa = colaborador?.empresa;
                //cargando roles y modulos
                this.registroService.rolesModulosUsuarioColaborador =
                  colaborador!.modulo_rol;
                this.registroService.esAdministrador = false; //se actualizará al accesar a cada ruta del modulo
                this.registroService.esSuperAdministrador = null;
                //tanto para this.registroService.esAdministrador se actualizara el valor
                //al momento de seleccionar un sidenav

                this.cookiesService.setUser(colaborador);
                //recarga de usuario
                this.registroService.reloadUsuario$.next(true);
                getColaboradorSb.unsubscribe();
                getUsuarioSb.unsubscribe();
              } else {
                //si no tiene usuario administrador, ni usuario colaborador, quiere decir que esta en modo registro usuario
                this.registroService.nextStep$.next();
              }
            }
          );
      }
    });
  }
}
