<div class="contenedor-index" *ngIf="visible1" [@myInsertRemoveTrigger] >
<div>
  <div class="title-general" >
    <img src="../../../../../assets/img/QUINAY2.png" class="imagen2" alt="">
    <div class="titulo-index">
      Queremos conocer tu opinión.
    </div>
    <div class="boton-index" >
      <button class="boton-index-color" mat-flat-button (click)="irEncuesta()" >Comenzar</button>
    </div>
  </div>
</div>
</div>
<div *ngIf="visible2" [@myInsertRemoveTrigger]>
  <div fxLayout="row" fxLayoutAlign="center center">
    <img src="../../../../../assets/img/QUINAY2.png" class="imagen" alt="">
  </div>
  <mat-divider></mat-divider>
  <mat-stepper #stepper orientation="vertical" linear="true">
    <mat-step [errorMessage]="errorMsg" editable="false" >
      <ng-template matStepLabel>
        <div style="margin-top: 20px;">
          <h3>Selecciona quien te atendió.</h3>
        </div>
      </ng-template>
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <mat-form-field style="width: 30%;">
          <mat-label>Mesero</mat-label>
          <mat-select placeholder="Seleccione" required ngModel #mesero="ngModel" name="mesero" [(ngModel)]="encuesta.codigo_mozo">
            <mat-option *ngFor=" let mozo of lMozo" [value]="mozo.codigo">{{mozo.nombres}}</mat-option>
          </mat-select>
          <mat-error *ngIf="mesero.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
        </mat-form-field>
      </div>
      <div style="margin-top: 20px;">
        <button mat-raised-button class="btn-siguiente" matStepperNext
          [disabled]="encuesta.codigo_mozo==0?true:false" (click)="nextMozo()" >Siguiente</button>
      </div>
    </mat-step>
    <mat-step [errorMessage]="errorMsg" editable="false" *ngIf="this.validMozoFlag" >
      <ng-template matStepLabel>
        <div style="margin-top: 20px;">
          <h3>¿Como calificarías nuestro servicio?</h3>
        </div>
      </ng-template>
      <section style="margin-top: 20px;" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="10px">
        <button mat-fab class="btn-muy-insatisfecho" aria-label=" muy insatisfecho" matStepperNext (click)="sendServicio(1)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_VERY_DISSATISFIED}}</mat-icon>
        </button>
        <button mat-fab class="btn-insatisfecho" aria-label="insatisfecho" matStepperNext (click)="sendServicio(2)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_DISSATISFIED}}</mat-icon>
        </button>
        <button mat-fab class="btn-neutro" aria-label="neutro" matStepperNext (click)="sendServicio(3)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_NEUTRAL}}</mat-icon>
        </button>
        <button mat-fab class="btn-satisfecho" aria-label="satisfecho" matStepperNext (click)="sendServicio(4)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_SATISFIED}}</mat-icon>
        </button>
        <button mat-fab class="btn-muy-satisfecho" aria-label="muy satisfecho" matStepperNext (click)="sendServicio(5)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_VERY_SATISFIED}}</mat-icon>
        </button>
      </section>
    </mat-step>
    <mat-step [errorMessage]="errorMsg" editable="false" *ngIf="this.validCoctelesFlag">
      <ng-template matStepLabel>
        <div style="margin-top: 20px;">
          <h3>¿Como calificarías nuestros CÓCTELES?</h3>
        </div>
      </ng-template>
      <section style="margin-top: 20px;" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="10px">
        <button mat-fab class="btn-muy-insatisfecho" aria-label=" muy insatisfecho" matStepperNext
          (click)="sendCocteles(1)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_VERY_DISSATISFIED}}</mat-icon>
        </button>
        <button mat-fab class="btn-insatisfecho" aria-label="insatisfecho" matStepperNext (click)="sendCocteles(2)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_DISSATISFIED}}</mat-icon>
        </button>
        <button mat-fab class="btn-neutro" aria-label="neutro" matStepperNext (click)="sendCocteles(3)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_NEUTRAL}}</mat-icon>
        </button>
        <button mat-fab class="btn-satisfecho" aria-label="satisfecho" matStepperNext (click)="sendCocteles(4)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_SATISFIED}}</mat-icon>
        </button>
        <button mat-fab class="btn-muy-satisfecho" aria-label="muy satisfecho" matStepperNext (click)="sendCocteles(5)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_VERY_SATISFIED}}</mat-icon>
        </button>
      </section>
    </mat-step>
    <mat-step [errorMessage]="errorMsg" editable="false" *ngIf="this.validPiqueosFlag">
      <ng-template matStepLabel>
        <div style="margin-top: 20px;">
          <h3>¿Como calificarías nuestros PIQUEOS?</h3>
        </div>
      </ng-template>
      <section style="margin-top: 20px;" fxLayout="row" fxLayoutAlign="space-around center"fxLayoutGap="10px">
        <button mat-fab class="btn-muy-insatisfecho" aria-label=" muy insatisfecho" matStepperNext
          (click)="sendPiqueo(1)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_VERY_DISSATISFIED}}</mat-icon>
        </button>
        <button mat-fab class="btn-insatisfecho" aria-label="insatisfecho" matStepperNext (click)="sendPiqueo(2)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_DISSATISFIED}}</mat-icon>
        </button>
        <button mat-fab class="btn-neutro" aria-label="neutro" matStepperNext (click)="sendPiqueo(3)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_NEUTRAL}}</mat-icon>
        </button>
        <button mat-fab class="btn-satisfecho" aria-label="satisfecho" matStepperNext (click)="sendPiqueo(4)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_SATISFIED}}</mat-icon>
        </button>
        <button mat-fab class="btn-muy-satisfecho" aria-label="muy satisfecho" matStepperNext (click)="sendPiqueo(5)">
          <mat-icon class="large-icon">{{Icons.SENTIMENT_VERY_SATISFIED}}</mat-icon>
        </button>
      </section>
    </mat-step>
    <mat-step [errorMessage]="errorMsg" editable="false" *ngIf="this.validComentarioFlag" >
      <ng-template matStepLabel>
        <div style="margin-top: 20px;">
          <h3>Déjanos un comentario.</h3>
        </div>
      </ng-template>
      <section style="margin-top: 20px;" fxLayout="row" fxLayoutAlign="space-around center">
        <mat-form-field class="example-full-width">
          <textarea
            type="text"
            required matInput ngModel placeholder="Comentario" name="comentario"
            [pattern]="PatternsRegex.onlyWordSpanishNumberWithSpaceBlank"
            #comentarioInput="ngModel"
            minlength="10"
            maxlength="100"
            [(ngModel)]="encuesta.comentario"></textarea>
          <mat-error *ngIf="comentarioInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          <mat-error *ngIf="comentarioInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
          <mat-error *ngIf="comentarioInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_10}}</mat-error>
          <mat-error *ngIf="comentarioInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_MEDIUM_MAX_100}}</mat-error>
        </mat-form-field>
      </section>
      <section style="margin-top: 20px;" fxLayout="row" fxLayoutAlign="space-around center">
        <button mat-raised-button class="btn-omitir" (click)="sendComentario()" >Omitir</button>
        <button mat-raised-button class="btn-siguiente"
          [disabled]="comentarioInput.hasError('required') ||
          comentarioInput.hasError('minlength') ||
          comentarioInput.hasError('pattern')"
          (click)="sendComentario()">Siguiente</button>
      </section>
    </mat-step>
    <mat-step [errorMessage]="errorMsg" editable="false" *ngIf="this.validDatosFlag" >
      <ng-template matStepLabel>
        <div class="subtitulo">
          <span><h3>¿Quieres ser parte del club Quinay?</h3></span>
          <span><h5>Participarás en shows y eventos VIP</h5></span>
        </div>
      </ng-template>
      <section fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <div fxLayout="row" fxLayoutGap="30px" fxLayout.xs="column" fxLayoutGap.xs="20px">
          <mat-form-field>
            <mat-label>Nombres</mat-label>
            <input
            type="text"
             matInput ngModel
             name="nombre"
             #nombreInput="ngModel"
             [pattern]="PatternsRegex.onlyCharsTwoWordsSpanish"
             minlength="3"
             maxlength="50"
             required
             oninput="this.value = this.value.toUpperCase()"
            [(ngModel)]="encuesta.cliente.nombres">
            <mat-error *ngIf="nombreInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            <mat-error *ngIf="nombreInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
            <mat-error *ngIf="nombreInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
            <mat-error *ngIf="nombreInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Apellido Paterno</mat-label>
            <input
              type="text"
              matInput ngModel name="appaterno"
              [pattern]="PatternsRegex.onlyCharsOneWordsSpanish"
              minlength="3"
              maxlength="30"
              required
              #appaternoInput="ngModel"
              oninput="this.value = this.value.toUpperCase()"
              [(ngModel)]="encuesta.cliente.apellido_paterno">
              <mat-error *ngIf="appaternoInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
              <mat-error *ngIf="appaternoInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
              <mat-error *ngIf="appaternoInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
              <mat-error *ngIf="appaternoInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column" >
          <mat-form-field>
            <mat-label>Apellido Materno</mat-label>
            <input
              type="text"
              matInput ngModel name="apmaterno"
              required
              #apmaternoInput="ngModel"
              [pattern]="PatternsRegex.onlyCharsOneWordsSpanish"
              minlength="3"
              maxlength="30"
              oninput="this.value = this.value.toUpperCase()"
              [(ngModel)]="encuesta.cliente.apellido_materno">
              <mat-error *ngIf="apmaternoInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
              <mat-error *ngIf="apmaternoInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
              <mat-error *ngIf="apmaternoInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
              <mat-error *ngIf="apmaternoInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Sexo</mat-label>
            <mat-select placeholder="Selecciona" name="sexo" #sexoDdl="ngModel" [(ngModel)]="encuesta.cliente.sexo" required>
              <mat-option value="m">Masulino</mat-option>
              <mat-option value="f">Femenino</mat-option>
            </mat-select>
            <mat-error *ngIf="sexoDdl.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column">
          <mat-form-field>
            <mat-label>Correo electrónico</mat-label>
            <input
              type="text"
              matInput ngModel name="correo" #correoInput="ngModel"
              required
              [pattern]="PatternsRegex.onlyEmail"
              oninput="this.value = this.value.toLowerCase()"
              autocomplete="new-off"
              autocomplete="off"
              [(ngModel)]="encuesta.cliente.correo_electronico">
              <mat-error *ngIf="correoInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
              <mat-error *ngIf="correoInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_EMAIL}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Número de telefono</mat-label>
            <input type="text"
            matInput ngModel name="telefono" #telefonoInput="ngModel"
            required
            autocomplete="new-off"
            autocomplete="off"
            minlength="9"
            maxlength="9"
            [pattern]="PatternsRegex.onlyNumberPhone"
            (keypress) ="onlyNumbers($event)"
              [(ngModel)]="encuesta.cliente.numero_telefono">
              <mat-error *ngIf="telefonoInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
              <mat-error *ngIf="telefonoInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_REQ9_DIGITOS_MOVIL}}</mat-error>
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-around center" style="text-align: center;">
          <h1>GRACIAS</h1>
          <h4>Tu opinión es muy importante para nosotros.</h4>
        </div>
      </section>
      <section style="margin-top: 20px;" fxLayout="row" fxLayoutAlign="space-around center">
        <button mat-raised-button class="btn-omitir" (click)="Guardar()" >Omitir</button>
        <button mat-raised-button class="btn-siguiente" (click)="Guardar()"
        [disabled]="
        nombreInput.hasError('required')||
        nombreInput.hasError('pattern') ||
        nombreInput.hasError('minlength') ||
        nombreInput.hasError('maxlength')||
        appaternoInput.hasError('required')||
        appaternoInput.hasError('pattern') ||
        appaternoInput.hasError('minlength') ||
        appaternoInput.hasError('maxlength')||
        apmaternoInput.hasError('required')||
        apmaternoInput.hasError('pattern') ||
        apmaternoInput.hasError('minlength') ||
        apmaternoInput.hasError('maxlength') ||
        correoInput.hasError('required')||
        correoInput.hasError('pattern') ||
        telefonoInput.hasError('required')||
        telefonoInput.hasError('pattern') "
        >Finalizar</button>
      </section>
    </mat-step>
  </mat-stepper>
</div>
