import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ItemSidenav } from '../../models/item.model';
import { UIService } from '../../services/ui.services';
import { AutoUnsubscribe } from "../../helpers/decorators/AutoUnsubscribe";

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
@AutoUnsubscribe

export class SidenavListComponent implements OnInit {
  @Input() list:any=[];
  @Output() closeSidenav = new EventEmitter<void>()
  selectedRouteSubs!: Subscription;

  constructor( private uiService: UIService ) { }

  ngOnInit(): void {
    this.selectedRouteSubs = this.uiService.selectedRoute.subscribe(item=>{
      this.list.forEach((item_anterior:ItemSidenav)=>item_anterior.active=false);
      this.list.filter((elemento:ItemSidenav)=>{
        elemento.active=elemento.nombre==item.nombre?? true;
      })
    });
  }
  onClose(opcion:ItemSidenav){
    this.closeSidenav.emit();
    this.uiService.selectedRoute.next(opcion);
  }

  openModalSignup(){
    this.closeSidenav.emit();
    this.uiService.ShowModalCaptcha();
  }
  Closed(){
    this.closeSidenav.emit();
  }
}
