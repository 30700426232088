import { Component, Inject, Input, OnInit } from '@angular/core';
import { Icons } from '../../cons/icons';
import { EstadosMesaType } from '../../types/estado-mesa-type';
import { Subscription } from 'rxjs';
import { IAmbienteMesa } from '../procesos/gestion-ambientes-mesas/models/IAmbienteMesa';
import { GestionAmbientesBlService } from '../procesos/gestion-ambientes-mesas/services/services.bl/gestion-ambientes.bl.service';
import { RegistroService } from '../../services/registro.service';
import { ListarAmbienteMesaService } from '../listar-ambiente-mesa/listar-ambiente-mesa.service';
import { UIService } from '../../services/ui.services';
import { SnackBarType } from '../../types/snackbar-type';
import { Msjs } from '../../cons/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';

@Component({
  selector: 'app-listar-ambiente-mesa-dialog',
  templateUrl: './listar-ambiente-mesa-dialog.component.html',
  styleUrls: ['./listar-ambiente-mesa-dialog.component.scss']
})

@AutoUnsubscribe

export class ListarAmbienteMesaDialogComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  readonly EstadosMesaType: typeof EstadosMesaType = EstadosMesaType;
  configSb = new Subscription();
  recargaSb!: Subscription;
  isLoading: boolean = false;
  configuracionAmbiente: IAmbienteMesa[] = new Array();
  existeConfig: boolean = false;
  isExpanded: boolean = true;
  ambienteSeleccionado: string = '';
  mesaSeleccionado: number = 0
  deSelMesa = new Subscription();
  usuario: any;
  esAdministrador: boolean = false;
  mesasSeleccionadasLocal: IAmbienteMesa[] = [];
  esEditar: boolean = false;
  tipoTabla:string='';
  ambientesUnicos: string[] = new Array();

  startY: number = 0;
  endY: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef: MatDialogRef<ListarAmbienteMesaDialogComponent>,
    private gestionAmbienteBLService: GestionAmbientesBlService,
    private registroService: RegistroService,
    private listarAmbienteMesaService: ListarAmbienteMesaService,
    private uiService: UIService,
  ) { }

  ngOnInit(): void {
    if (this.registroService.empresa) {
      this.inicializarComponente();
      this.esEditar = this.datoPasado.esEditar;
      this.tipoTabla = this.datoPasado.tipoTabla;
    }

    this.deSelMesa = this.listarAmbienteMesaService.deseleccionarMesa$.subscribe(ambMesa => {
      if(ambMesa.tipoTabla===this.tipoTabla){
        this.configuracionAmbiente.forEach(ambMesaConfig=>{
          if(ambMesaConfig.ambiente===ambMesa.mesaAmbiente.ambiente && 
            ambMesaConfig.mesa_numero===ambMesa.mesaAmbiente.mesa_numero ){

            ambMesaConfig.mesa_estado = EstadosMesaType.DISPONIBLE;

          }
        });
      }
    
    });

    this.recargaSb = this.listarAmbienteMesaService.recargarMesas$.subscribe(()=>{
      //vuelve a renderizar las mesas       
      this.isExpanded = false;      
    });

  }

  touchStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY;
  }

  touchMove(event: TouchEvent) {
    this.endY = event.touches[0].clientY;
  }

  touchEnd(event: TouchEvent) {
    if (this.startY > this.endY + 30) {
      //console.log('Deslizado hacia arriba');
      // Acción específica al deslizar hacia arriba
    } else if (this.startY < this.endY - 30) {
      //console.log('Deslizado hacia abajo');
      // Acción específica al deslizar hacia abajo
    }
  }

  inicializarComponente(){
    this.isLoading = true;
    this.mesasSeleccionadasLocal = [];
    this.cargarConfiguracion();
    this.usuario = this.registroService.usuario;
    if (this.registroService.esAdministrador) {
      this.esAdministrador = true;
    }
  }

  onClose(){
    this.dialogRef.close();
    //this.uiService.closeModalListarAmbienteMesaD();
  }

  cargarConfiguracion() {    
    this.gestionAmbienteBLService.existFetchAmbienteMesa();
    this.configSb?.unsubscribe();
    this.configSb = this.gestionAmbienteBLService.getAmbienteMesa().subscribe(configAmb => {
      if (configAmb) {
        this.isLoading = false;
        this.configuracionAmbiente = [];
        this.ambientesUnicos = [];
        this.configuracionAmbiente = configAmb;
        this.existeConfig = true;
        

        //extrayendo ambientes
        let ambientesUnicos = new Set<string>();
        this.configuracionAmbiente.forEach(amb_mesa => {
          ambientesUnicos.add(amb_mesa.ambiente);
        });

        const ambientesUnicosArray = Array.from(ambientesUnicos);
        const orderAmbMesa: any[] = [];
        ambientesUnicosArray.forEach(ambiente => {
          const amb_orden = configAmb.filter(amb_mesa1 => amb_mesa1.ambiente == ambiente)[0];
          orderAmbMesa.push({
            ambiente: ambiente,
            orderAmbMesa: amb_orden.orden
          });
        });
        orderAmbMesa.sort((a, b) => {
          return a.orderAmbMesa - b.orderAmbMesa
        }//para orden descendente invertir el orden
        );
        orderAmbMesa.forEach(obj => {
          this.ambientesUnicos.push(obj.ambiente);
        });
        //ordenando por dos criterios
        this.configuracionAmbiente.sort((a, b) => {
          // Primero compara por la propiedad 'edad'
          if (a.orden < b.orden) return -1;
          if (a.orden > b.orden) return 1;

          // Si la 'edad' es igual, entonces compara por 'nombre'
          if (a.mesa_numero < b.mesa_numero) return -1;
          if (a.mesa_numero > b.mesa_numero) return 1;

          return 0; // Los objetos son iguales en términos de ordenación
        });
        //cuando ocurre un cambio para mostrar el mensaje en caso de liberar mesa
        if(this.mesasSeleccionadasLocal.length>0){
          let flagMesaLocalEncontrada:boolean = false;
          let mesaLocal!:IAmbienteMesa
          let index:number=0;
          for(const elem of this.mesasSeleccionadasLocal){
            if(flagMesaLocalEncontrada){
              break;
            }
            for(const retElem of configAmb){
              if(elem.ambiente==retElem.ambiente && 
                elem.mesa_numero== retElem.mesa_numero &&
                elem.mesa_estado !== retElem.mesa_estado &&
                elem.mesa_estado== EstadosMesaType.EN_PROCESO &&
                retElem.mesa_estado== EstadosMesaType.DISPONIBLE
                ){
                  //const  msj =': # '+ elem.mesa_numero.toString();
                  this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs.MENSAJE_CONFIRMACION_CONFIRMAR_LIBERAR_MESA, 3000);
                  //para desconectar en su propio componente
                  this.listarAmbienteMesaService.seleccionMesa$.next({mesaAmbMesa:retElem, tipoTabla:this.tipoTabla});
                  mesaLocal = retElem;
                  flagMesaLocalEncontrada = true; 
                  break;
                }
            }
            
          }
          if(flagMesaLocalEncontrada){
            this.mesasSeleccionadasLocal.splice(index,1);
          }
          index+=1;
        }
      }
      else {
        this.isLoading = false;
      }

    });
  }

  seleccionarMesa(mesa: IAmbienteMesa) {
    let mesaAmbMesa: IAmbienteMesa;
    this.isLoading = true;

    //deseleccionando la mesa
    if (mesa.mesa_estado == EstadosMesaType.EN_PROCESO) {
      mesa.mesa_estado = EstadosMesaType.DISPONIBLE;
      mesaAmbMesa = mesa;
    }
    else {
      //seleccionando la mesa      
      this.ambienteSeleccionado = mesa.ambiente;
      this.mesaSeleccionado = mesa.mesa_numero;
      mesa.mesa_estado = !this.esEditar ? EstadosMesaType.EN_PROCESO : EstadosMesaType.RESERVADO;
      mesaAmbMesa = mesa;

      const  msj =': # '+ mesa.mesa_numero.toString();
      this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs.MENSAJE_CONFIRMACION_SELECCIONAR_MESA+msj, 5000);
    }

    this.listarAmbienteMesaService.seleccionMesa$.next({mesaAmbMesa:mesaAmbMesa, tipoTabla:this.tipoTabla});
    this.gestionAmbienteBLService.updateEstadoMesa(mesaAmbMesa).then(() => this.isLoading = false);
    //almacenando localmente las mesas
    this.mesasSeleccionadasLocal.push(mesaAmbMesa);

  }

}
