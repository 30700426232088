<div class="keyboard">
  <div class="row">
    <button class="teclas-letras" mat-raised-button (click)="sendKey('1')">1</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('2')">2</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('3')">3</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('4')">4</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('5')">5</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('6')">6</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('7')">7</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('8')">8</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('9')">9</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('0')">0</button>
  </div>
  <div class="row">
    <button class="teclas-letras" mat-raised-button (click)="sendKey('Q')">Q</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('W')">W</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('E')">E</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('R')">R</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('T')">T</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('Y')">Y</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('U')">U</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('I')">I</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('O')">O</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('P')">P</button>
  </div>
  <div class="row">
    <button class="teclas-letras" mat-raised-button (click)="sendKey('A')">A</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('S')">S</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('D')">D</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('F')">F</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('G')">G</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('H')">H</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('J')">J</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('K')">K</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('L')">L</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('Ñ')">Ñ</button>
  </div>
  <div class="row">
    <button class="teclas-letras" mat-raised-button (click)="sendKey('Z')">Z</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('X')">X</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('C')">C</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('V')">V</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('B')">B</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('N')">N</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('M')">M</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey(',')">,</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('.')">.</button>
    <button class="teclas-letras" mat-raised-button (click)="sendKey('delete_btn')">Del</button>
  </div>
  <div class="row">
    <button mat-raised-button class="space-bar" (click)="sendKey(' ')">Espacio</button> <!-- Barra espaciadora -->
  </div>
</div>
  