import { Injectable } from '@angular/core';
import { AspectosGenerales } from '../../models/AspectosGenerales';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Utils } from 'src/app/shared/helpers/utils';
import { serverTimestamp } from 'firebase/firestore';
import { Auditoria, colections, documentsEmpresa } from 'src/app/shared/cons/db.colections';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GestionAspectosDaService {
  transaccion: TransaccionModel = new TransaccionModel();

  private TasasCache: BehaviorSubject<AspectosGenerales | undefined | null> = new BehaviorSubject<AspectosGenerales | undefined |null>(undefined);
  public tasas$: Observable<AspectosGenerales | undefined |null> = this.TasasCache.asObservable();
  private fetchSb!: Subscription;

  constructor(
    private db: AngularFirestore,
  ) { }

  fetchTasasMesa(idEmpresa:string,idUsuario:string): void {
    // if(this.fetchSb){
    //   this.fetchSb.unsubscribe();
    // }
    if(this.fetchSb &&!this.fetchSb.closed){
      this.TasasCache.next(this.TasasCache.value);
      return;
    }

    this.fetchSb = this.getConfiguracionTasas(idEmpresa, idUsuario).subscribe(tasas => {
      this.TasasCache.next(tasas);
    });
  }

  stopFetchingTasas(): void {
    if (this.fetchSb) {
      this.fetchSb.unsubscribe();
    }
    this.TasasCache.next(undefined);
  }

  getConfiguracionTasas(idEmpresa:string, idUsuario:string){
    return this.db.collection(colections.EMPRESA, (ref)=>
    ref.where(Auditoria.es_borrado,'==',false)
    .where(documentsEmpresa.ID_DOC_USUARIO,'==',idUsuario)
    ).doc(idEmpresa).collection(colections.CONFIGURACION_TASAS, (ref1)=>
     ref1.where(Auditoria.es_borrado,'==',false).limit(1) )
     .snapshotChanges()
     .pipe(
      map(configuracion=>{
       if(configuracion.length>0){
         const config = Utils.convertDate(configuracion[0].payload.doc.data()) as AspectosGenerales;
         config.id = configuracion[0].payload.doc.id;
         return config;
       }
       else{
         return null;
       }
     }));
   }

  insertTasas(aspectosGenerales:AspectosGenerales, idEmpresa:string,correoUsuario:string){
    aspectosGenerales.usuario_creacion = correoUsuario
    const docConfigAmbiente = Utils.SerializeJsonToDb(aspectosGenerales);
    docConfigAmbiente.fecha_creacion = serverTimestamp()
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(colections.CONFIGURACION_TASAS);

    return docConfigRef.add(docConfigAmbiente).then(result=>{
      this.transaccion.tx = true;
      this.transaccion.data = result.id
      return this.transaccion;
    }).catch((error)=>{
      this.transaccion.tx = false;
      this.transaccion.error = error;
      return this.transaccion;
    });
  }

  updateTasas(aspectosGenerales:AspectosGenerales, idEmpresa:string, correoUsuario:string, idConfig:string){
    delete aspectosGenerales.id;
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(colections.CONFIGURACION_TASAS);

    return docConfigRef.doc(idConfig).update({
      sigla_impuesto: aspectosGenerales.sigla_impuesto,
      tasa_impuesto:aspectosGenerales.tasa_impuesto,
      incluir_servicio: aspectosGenerales.incluir_servicio,
      tasa_servicio: aspectosGenerales.tasa_servicio,
      fecha_modificacion: serverTimestamp(),
      usuario_modificacion: correoUsuario
    }).then(result=>{
      this.transaccion.tx = true;
      this.transaccion.data = result;
      return this.transaccion;
    }).catch((error)=>{
      this.transaccion.tx = false;
      this.transaccion.error = error;
      return this.transaccion;
    });
  }

}
