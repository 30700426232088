import { Component, Input, OnInit } from '@angular/core';
import { Icons } from '../../cons/icons';
import { MensajeBlService } from '../gestion-monto/services/mensaje.bl.service';
import { Observable, Subscription } from 'rxjs';
import { Mensaje } from '../gestion-monto/models/Mensaje';
import { UIService } from '../../services/ui.services';
import { SnackBarType } from '../../types/snackbar-type';
import { Msjs } from '../../cons/common';
import { ProcesoType } from '../../types/proceso-type';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { RegistroService } from '../../services/registro.service';
import { Utils } from '../../helpers/utils';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.scss']
})
@AutoUnsubscribe
export class AvisosComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;  
  mensajes!: Observable<Mensaje[] | null | undefined>;
  subs!: Subscription;


  constructor(
    private mensajeBLService: MensajeBlService,
    private uiService:UIService,
    private registroService:RegistroService
  ) { }

  ngOnInit(): void {

    //carga inicial de mensajes
    if(this.registroService.empresa){
      const admGen='administrador general';
      this.mensajeBLService.fetchMensajeByEmpresa();
      if(this.registroService.esSuperAdministrador){
        this.mensajes = this.mensajeBLService.getMensajeCache();
      }
      else{
        this.mensajes = this.mensajeBLService.getMensajeCache().pipe(
          map(msjs => {
            // Si msjs es null o undefined, retorna un array vacío
            if (!msjs) {
              return [];
            }
            // console.log(this.registroService.usuario.correo_electronico);
            // console.log(this.registroService.esSuperAdministrador);
            // console.log( this.registroService.rolesModulosUsuarioColaborador);
            // console.log( msjs);
            // Aplica el filtro solo si msjs no es null o undefined
            return msjs.filter(msj => {
              const res = this.registroService.rolesModulosUsuarioColaborador?.filter(modRol=>modRol.nombre_modulo==msj.modulo && modRol.rol==admGen);
              if(msj.usuario_creacion === this.registroService.usuario.correo_electronico || 
                this.registroService.esSuperAdministrador==true || res && res.length>0){
                  return msj;

              }else{
                return null;
              }
              
            });
          })
        );
      }   
    }    

    //muestra los mensajes emergentes
    this.subs= this.mensajeBLService.getMensajeCache().subscribe(msjx=>{
      if(msjx && msjx?.length>0){
        if(this.registroService.esSuperAdministrador==true){
          this.mostrarMsj(msjx[msjx.length-1], 7000);
        }else{
          //mostrando mensajes para el administrador dependiendo del modulo o del creador del mensje para que tenga constancia de envió la petición
          let listaMsjs:Mensaje[]=[];
          this.registroService.rolesModulosUsuarioColaborador!.forEach(modRol=>{
            msjx.forEach(msj=>{
              if(msj.modulo==modRol.nombre_modulo &&( this.registroService.esAdministrador || msj.usuario_creacion== this.registroService.usuario.correo_electronico)){
                listaMsjs.push(msj);

              }});
          });
          if(listaMsjs.length>0){
            this.mostrarMsj(listaMsjs[listaMsjs.length-1], 7000);
          }
                    
        }          
      }
    });
  }
  mostrarMsj(item:Mensaje, time:number=7000){
    let msj=''
    switch(item.proceso){
      case (ProcesoType.CAMBIAR_MONTO_INICIAL):{
        msj = 'Proceso '+item.proceso+' - Estado: '+item.estado_cambio.toUpperCase()+
        ' | '+ 'anterior: S/ '+
        item.anterior_existente+' → solicitado: S/ '+item.actual_pedido+
        ' (usuario: '+item.usuario_creacion.split('@')[0]+')';
        break;
      }
      case (ProcesoType.CAMBIAR_ANADIR_MONTO):{
        msj = 'Proceso '+item.proceso+' - Estado: '+item.estado_cambio.toUpperCase()+
        ' | '+'Efectivo Añadido: S/ '+item.actual_pedido+
        ' (usuario: '+item.usuario_creacion.split('@')[0]+')';
        break;
      }
      case (ProcesoType.CAMBIAR_RETIRAR_MONTO):{
        msj = 'Proceso '+item.proceso+' - Estado: '+item.estado_cambio.toUpperCase()+
        ' | '+'Efectivo Retirado: S/ '+item.actual_pedido+
        ' (usuario: '+item.usuario_creacion.split('@')[0]+')';
        break;
      }
      case (ProcesoType.CAMBIAR_IMPRIMIR_ANADIR):{
        msj = 'Proceso '+item.proceso+' - Estado: '+item.estado_cambio.toUpperCase()+
        ' | '+'Reimprimir comprobante: S/ '+item.actual_pedido+
        ' (usuario: '+item.usuario_creacion.split('@')[0]+')';
        break;
      }
      case (ProcesoType.CAMBIAR_IMPRIMIR_RETIRAR):{
        msj = 'Proceso '+item.proceso+' - Estado: '+item.estado_cambio.toUpperCase()+
        ' | '+'Reimprimir comprobante: S/ '+item.actual_pedido+
        ' (usuario: '+item.usuario_creacion.split('@')[0]+')';
        break;
      }
      case (ProcesoType.CAMBIAR_VARIAR_MESA):{
        msj = 'Proceso '+item.proceso+' - Estado: '+item.estado_cambio.toUpperCase()+
        ' | '+'Variación Mesa: Actual(N°'+item.anterior_existente+') --> Solicitado(N°'+item.actual_pedido+
        ' '+item.accion+')(usuario: '+item.usuario_creacion.split('@')[0]+')';
        break;
      }
      case (ProcesoType.VARIAR_PRODUCTO):{
        msj = 'Proceso '+item.proceso+' - Estado: '+item.estado_cambio.toUpperCase()+
        ' | '+'Variación Producto: Actual: (N°'+item.anterior_existente+')   --> Solicitado: (N°'+item.actual_pedido+
        ')(usuario: '+item.usuario_creacion.split('@')[0]+')';
        break;
      }
      default:{
        break;
      }
    
    
    }    
    this.uiService.ShowSnackBar(SnackBarType.INFO, msj, time);
  }

}
