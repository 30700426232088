import { Component, OnInit,  } from '@angular/core';
import { Icons } from 'src/app/shared/cons/icons';
import { UINavigateSidenavService } from '../../../services/ui.navigate-sidenav.services';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ItemSidenav, IRolAcceso } from 'src/app/shared/models/item.model';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { Utils } from 'src/app/shared/helpers/utils';
import { StaticRoutes } from 'src/app/shared/cons/route.class';
import { ModulosType } from 'src/app/shared/types/modules-type';
import { UIService } from 'src/app/shared/services/ui.services';
import { Msjs, SistOperation, rolMaster } from 'src/app/shared/cons/common';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { CRol } from './models/crol.model';
import { EmpresaBLService } from '../../services/services.bl/empresa.bl.service';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { GestionColaboradorBlService } from '../gestion-colaborador/services/services.bl/gestion-colaborador.bl.service';



@Component({
  selector: 'app-gestion-rol',
  templateUrl: './gestion-rol.component.html',
  styleUrls: ['./gestion-rol.component.scss']
})

@AutoUnsubscribe


export class GestionRolComponent implements OnInit {
  selectedRouteSubs: Subscription = new Subscription();
  readonly Icons: typeof Icons = Icons;
  roles:IRolAcceso[]=[];
  rolesLoaded: boolean = false;
  rolAccesoMaster!: IRolAcceso;
  item!:ItemSidenav
  reloadRolesAccesosSubs: Subscription = new Subscription();
  sbModalQuest!:Subscription;
  indexModulo:number=0;
  indexRol:number=0;
  rolesAniadidos:IRolAcceso[]=[];
  loadEmpresaSb: Subscription = new Subscription();
  moduloSeleccionado:any;

  moduloEliminable:boolean=true;
  reloadRolesSb = new Subscription();



  constructor(
    private uiNavigateSide: UINavigateSidenavService,
    private registroService: RegistroService,
    private uiService:UIService,
    private readonly spinnerPageService: SpinnerPageService,
    private empresaBLService: EmpresaBLService,
    private gestionColaboradorBlService:GestionColaboradorBlService

  ) { }

  ngOnInit(): void {

    this.loadEmpresaSb = this.registroService.loadEmpresa$.subscribe(() => {
      //tomamos de memoria el item seleccionado
      if(this.uiNavigateSide.goOptionSidenavItem){
        this.cargarRolesAccesos();
      }
    });

    this.reloadRolesSb = this.uiService.reloadRoles$.subscribe(()=>{
      this.cargarRolesAccesos();
    });
  }

  cargarRolesAccesos() {
    this.item = this.uiNavigateSide.goOptionSidenavItem;
    const modulo = this.registroService.empresa.lista_modulos_seleccionados
    .find((modulo: { roles: any; codigo: string }) => modulo.codigo == this.item.codigo);
    if (modulo) {
      this.moduloSeleccionado = modulo;//es el modulo seleccionado al expandir el expander
      this.roles = Utils.GetArrayMenuRol(modulo.roles);
      return;
    }
  }
  onSelect(rol:any){

  }
  openModal(){
    this.getAccesos();
    this.uiService.showModalGestionRol({operacion:SistOperation.NUEVO, data: this.rolAccesoMaster, item:this.item });
  }

  editarRolPersonalizado(rol:IRolAcceso, indiceRol:number){
    this.getAccesos();
    this.uiService.showModalGestionRol({operacion:SistOperation.EDITAR, data: this.rolAccesoMaster, item:this.item, rol: rol, indiceRol:indiceRol });
  }

  eliminarRolPersonalizado(rol: IRolAcceso) {
    rol.codigo_modulo = this.moduloSeleccionado.codigo;
    rol.nombre_modulo = this.moduloSeleccionado.nombre;

    this.sbModalQuest = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION_CONSECUENCIAS_ELIMINAR, icon: Icons.QUESTION_MARK })
      .subscribe(async result => {
        if (result) {
          const validarUsoRol = await this.gestionColaboradorBlService.getColaboradorXModuloRol(rol).then((transaccion) => {
            if (transaccion.tx) {
              this.moduloEliminable = false;
              this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ROL_ASOCIADO_COLABORADOR.replace('$', transaccion.data.numero_elementos), 4000);
              return;
            } else{
              if (!transaccion.tx && transaccion.error==undefined) {
                this.moduloEliminable = true;

                return;
              }else{
                this.moduloEliminable = false;
                this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);
              }
            }
          });

          if (this.moduloEliminable) {
            this.spinnerPageService.show();
            this.registroService.empresa.lista_modulos_seleccionados.forEach((modulo: { codigo: string; roles: CRol[] }, indice: number) => {
              if (modulo.codigo == this.item.codigo) {

                //guardando el indice
                this.indexModulo = indice;

                //añadiendo el nuevo rol
                this.rolesAniadidos = Utils.GetArrayMenuRol(modulo.roles);

                this.rolesAniadidos.forEach((itemRol: { rol: string, acceso: string[] }, indiceRol: number) => {
                  if (itemRol.rol.toLowerCase() === rol.rol.toLowerCase()) {
                    this.indexRol = indiceRol;
                  }
                });

                //eliminando el rol
                this.rolesAniadidos.splice(this.indexRol, 1);
              }
            });
            this.empresaBLService.deleteEmpresaModuloRoles(this.rolesAniadidos, this.indexModulo)
              .then((result: any) => {
                if (result.tx) {
                  this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                  this.spinnerPageService.hide();
                  this.cargarRolesAccesos();
                  //recarga los roles en el componente padre
                  //this.uiService.reloadRolesAccesos$.next();
                } else {
                  this.spinnerPageService.show();
                  this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);

                }
              });

          }


        }
      });
  }

  getAccesos(){
    let finalArray = new Array();
    let keysObject:string[] =Object.keys(Object.assign({}, this.roles));
    keysObject.find((obj1:any )=>{
      if(this.roles[obj1].rol==rolMaster.ADMINSITRADOR_GENERAL){
        this.rolAccesoMaster={
          rol:this.roles[obj1].rol,
          acceso:this.roles[obj1].acceso
        }
        return;
      }
    });
  }

}
