import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StaticRoutes } from 'src/app/shared/cons/route.class';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { CartaBlService } from './services/carta-bl.service';
import { SpinnerPageService } from 'src/app/shared/components/spinner-page/spinner-page.service';
import { CartaDigital } from 'src/app/shared/components/procesos/gestion-externo-carta/models/CartaDigital';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';
import { Catalogo } from 'src/app/shared/components/procesos/dialog/models/Catalogo';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { Msjs } from 'src/app/shared/cons/common';

@Component({
  selector: 'app-carta',
  templateUrl: './carta.component.html',
  styleUrls: ['./carta.component.scss']
})

@AutoUnsubscribe

export class CartaComponent implements OnInit {
  cartaId:string | null =null;
  paramSubs!:Subscription;
  cartaSb!:Subscription;
  carta:CartaDigital= new CartaDigital();
  readonly Icons: typeof Icons = Icons;
  isLoading=true;
  showFiller = false;
  selectedTabIndex:number=0;

  constructor(
    private route:ActivatedRoute,
    private cartaBlService: CartaBlService,
    private readonly spinnerPageService: SpinnerPageService,
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private uiService: UIService,
    private router: Router,
  ) { 
    this.matIconRegistry.addSvgIcon(
      'icon-wath', // Nombre del ícono para usar en el template
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/icons/whatssap.svg') 
    );
    this.matIconRegistry.addSvgIcon(
      'icon-insta', // Nombre del ícono para usar en el template
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/icons/instagram.svg') 
    );
    this.matIconRegistry.addSvgIcon(
      'icon-face', // Nombre del ícono para usar en el template
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/icons/facebook.svg') 
    );
    this.matIconRegistry.addSvgIcon(
      'icon-wath-black', // Nombre del ícono para usar en el template
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/icons/whats-black.svg') 
    );
    this.matIconRegistry.addSvgIcon(
      'icon-insta-black', // Nombre del ícono para usar en el template
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/icons/insta-black.svg') 
    );
    this.matIconRegistry.addSvgIcon(
      'icon-face-black', // Nombre del ícono para usar en el template
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/icons/face-black.svg') 
    );
    
  }

  ngOnInit(): void {
    
    this.paramSubs = this.route.paramMap.subscribe(params => {
      this.cartaId = params.get('id');      
      if(this.cartaId!=null){        
        this.cartaSb = this.cartaBlService.getCartaExterno(this.cartaId!).subscribe(carta=>{
          if(carta!=null){         
            this.carta = JSON.parse(JSON.stringify(carta));            
            this.isLoading = false;
            this.cdRef.detectChanges();
          }
          else{
            this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_URL,3000);
            //redirigir al index
            this.isLoading = false;
            this.router.navigate(['/inicio/' + StaticRoutes.TOUR]);
          }
        });
      }
      

    });
  }

  onClose(index:number){    
    this.selectedTabIndex= index;
  }

  abrirWathsAppLink(){
    const msj = "Hola, quiero informes"
    const url = `https://wa.me/${'51'+this.carta.telefono!}?text=${encodeURIComponent(msj)}`;

    window.open(url, '_blank');
  }

  abrirInstaLink(){
    window.open(this.carta.ruta_instagram, '_blank');
  }

  abrirFaceLink(){
    window.open(this.carta.ruta_facebook, '_blank');
  }


}
