<mat-toolbar class="nav-principal">
    <div>
        <img class="nav-principal-logo-plataforma" src="../../../../assets/img/icon-modula.png" alt="Modula">
        <!-- <mat-icon class="icono-logo" >{{Icons.WEBHOOK}}</mat-icon> -->
    </div>
    <div style="padding-top: 10px;" class="nav-sidebar-logo-text">MConsola</div>    
    <div fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
        <app-button *ngIf="es_autenticado" type="outline" text="Salir" fxHide.xs (click)="onLogout()"  ></app-button>
        <app-button *ngIf="es_autenticado" type="fab_icon" icon_fig="logout" fxHide fxShow.xs (click)="onLogout()"></app-button>
    </div>
</mat-toolbar>