import { Component, Input, OnInit } from '@angular/core';
import { Utils } from '../../helpers/utils';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-avatar-image',
  templateUrl: './avatar-image.component.html',
  styleUrls: ['./avatar-image.component.scss']
})
export class AvatarImageComponent implements OnInit {
  private _iniciales: string | null = null;
  private inicialesSubject = new BehaviorSubject<string | null>(null);
  iniciales$ = this.inicialesSubject.asObservable();

  private colorSubject = new BehaviorSubject<string>('#FFFFFF');
  color$ = this.colorSubject.asObservable();

  @Input() pathImage!:string | null;
  @Input() set iniciales(value: string | null) {
    this._iniciales = value==null?'': value ;
    this.color = Utils.generateColor(this._iniciales);
    this.inicialesSubject.next(value);
    this.colorSubject.next(this.color);
  }
  @Input() tipoUsuario!:string | null;
  color:string='#FFFFFF';

  constructor() {


  }

  ngOnInit(): void {
  }

}
