import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Icons } from 'src/app/shared/cons/icons';
import { Pedido } from '../../registrar-pedido/models/Pedido';
import { Observable, Subscription } from 'rxjs';
import { UIService } from 'src/app/shared/services/ui.services';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { blinkAnimation } from 'src/app/shared/animations/animations';
import { IProductoTicket } from '../../registrar-pedido/models/IProductoTicket';
import { AspectosGenerales } from '../gestion-aspectos-generales/models/AspectosGenerales';
import { Fecha } from 'functions/src/class_interface';

@Component({
  selector: 'app-emision-comprobante-electronico',
  templateUrl: './emision-comprobante-electronico.component.html',
  styleUrls: ['./emision-comprobante-electronico.component.scss'],
  animations: [blinkAnimation],
})

@AutoUnsubscribe

export class EmisionComprobanteElectronicoComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  pedido = new Pedido();
  loading:boolean = false;
  showLoadingSubs!:Subscription;

  closeDialogSubs!:Subscription;
  blinkState: string = 'inactive';
  emiteComprobante:boolean = false;

 

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef:MatDialogRef<EmisionComprobanteElectronicoComponent>,
    private uiService: UIService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    setInterval(() => {
      this.blinkState = this.blinkState === 'active' ? 'inactive' : 'active';
    }, 1000);

    this.showLoadingSubs = this.uiService.loadingSend$.subscribe(flag=>{
      this.loading = flag;
    });

    this.closeDialogSubs = this.uiService.closeDialogComprobanteElect$.subscribe(flag=>{
      if(flag){
        this.emiteComprobante = flag
        this.onClose();
      }
    });

    
  }

  onClose(){
    //emitimos si se va imprimir o no el comprobante
    this.uiService.printComprobante$.next(this.emiteComprobante);
    this.dialogRef.close();
  }

}
