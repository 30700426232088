<div fxLayout="row" fxLayoutGap="30px" fxLayoutGap.xs="10px" fxLayout.xs="column" style="width:100%" fxLayoutAlign="center start" >
    <div style="min-width:300px;" >
        <div *ngIf="isLoadingCarta">
            <app-spinner></app-spinner>
        </div>        
        <form #f="ngForm" (ngSubmit)="onSubmit(f)">
            <mat-card *ngIf="!isLoadingCarta">
                <mat-card-title>Gestionar Carta Digital</mat-card-title>
                <mat-card-subtitle>Genera un código Qr para una URL externa del catálogo</mat-card-subtitle>            
                <mat-card-content>
                    <div  fxLayout="column" fxLayoutGap="10px">
                        <mat-form-field style="width:300px" >
                            <mat-label>URL parcial</mat-label>
                            <input 
                                type="text" 
                                matInput 
                                placeholder="Ruta" 
                                required 
                                minlength="3" 
                                maxlength="30"
                                name="ruta" 
                                ngModel 
                                [(ngModel)]="cartaDigital.ruta_parcial"
                                [pattern]="PatternsRegex.onlyCharsOneWordsSpanish" 
                                #txtRutaInput="ngModel"
                                oninput="this.value = this.value.toLowerCase()"
                                (blur)="generarQr()"                       
                                [disabled]="modoOperacion ==SistOperation.VER">
                                <mat-icon matSuffix>{{Icons.LINK}}</mat-icon>
                                <mat-error
                                    *ngIf="txtRutaInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                <mat-error
                                    *ngIf="txtRutaInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_1PALABRA}}</mat-error>
                                <mat-error
                                    *ngIf="txtRutaInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
                                <mat-error
                                    *ngIf="txtRutaInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_5}}</mat-error>
                        </mat-form-field>
                        <mat-form-field style="width:300px" >
                            <mat-label>Número de Teléfono</mat-label>
                            <input 
                                type="text" 
                                matInput 
                                placeholder="# de WhatsApp"                                 
                                minlength="9" 
                                maxlength="9"
                                name="whats" 
                                ngModel 
                                [(ngModel)]="telefono"
                                [pattern]="PatternsRegex.onlyNumberPhone" 
                                #txtWhats="ngModel"
                                oninput="this.value = this.value.toLowerCase()"                                         
                                [disabled]="modoOperacion ==SistOperation.VER">
                                <mat-icon matSuffix svgIcon="icon-wath-black"></mat-icon>
                                <mat-error
                                    *ngIf="txtWhats.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                <mat-error
                                    *ngIf="txtWhats.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_REQ9_DIGITOS_MOVIL}}</mat-error>
                                <mat-error
                                    *ngIf="txtWhats.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_9DIGITOS}}</mat-error>
                                <mat-error
                                    *ngIf="txtWhats.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_9}}</mat-error>
                        </mat-form-field>
                        <mat-form-field style="width:300px" >
                            <mat-label>Instagram</mat-label>
                            <input 
                                type="text" 
                                matInput 
                                placeholder="Dirección"                                  
                                minlength="10" 
                                maxlength="100"
                                name="insta" 
                                ngModel 
                                [(ngModel)]="ruta_instagram"
                                [pattern]="PatternsRegex.onlyUrl" 
                                #txtInsta="ngModel"
                                oninput="this.value = this.value.toLowerCase()"                                              
                                [disabled]="modoOperacion ==SistOperation.VER">
                                <mat-icon matSuffix svgIcon="icon-insta-black"></mat-icon>
                                <mat-error
                                    *ngIf="txtInsta.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                <mat-error
                                    *ngIf="txtInsta.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_URL}}</mat-error>
                                <mat-error
                                    *ngIf="txtInsta.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_MEDIUM_MAX_100}}</mat-error>
                                <mat-error
                                    *ngIf="txtInsta.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_10}}</mat-error>
                        </mat-form-field>
                        <mat-form-field style="width:300px" >
                            <mat-label>Facebook</mat-label>
                            <input 
                                type="text" 
                                matInput 
                                placeholder="Dirección"                                 
                                minlength="10" 
                                maxlength="100"
                                name="face" 
                                ngModel 
                                [(ngModel)]="ruta_facebook"
                                [pattern]="PatternsRegex.onlyUrl" 
                                #txtFace="ngModel"
                                oninput="this.value = this.value.toLowerCase()"                                                
                                [disabled]="modoOperacion ==SistOperation.VER">
                                <mat-icon matSuffix svgIcon="icon-face-black"></mat-icon>
                                <mat-error
                                    *ngIf="txtFace.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                                <mat-error
                                    *ngIf="txtFace.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_URL}}</mat-error>
                                <mat-error
                                    *ngIf="txtFace.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_MEDIUM_MAX_100}}</mat-error>
                                <mat-error
                                    *ngIf="txtFace.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_10}}</mat-error>
                        </mat-form-field>
                        <mat-slide-toggle (change)="onChangeTogleCarta($event)"
                            color="primary"
                            name="estado"
                            ngModel
                            #estado="ngModel"
                            [disabled]="modoOperacion ==SistOperation.VER"
                            [(ngModel)]="cartaDigital.es_vigente">
                            {{msjEstado}}
                            <span  class="label" > (Estado de la carta).</span>
                        </mat-slide-toggle>
                     
                        <button *ngIf="existeConfCartaDigital && !esEditar" mat-stroked-button matTooltip="Sincronizar Carta Digital" 
                            class="estado-pendiente" (click)="sincronizarCarta()">
                            <mat-icon class="mat-18">{{Icons.MENU_BOOK}}</mat-icon>
                            <span fxShow.gt-md fxHide.lt-md style="margin-left: 5px;">Sincronizar Carta</span>
                        </button>
    
                        <div class="qrcodeImage" *ngIf="cartaDigital.tiene_qr" fxLayout="column" fxLayoutAlign="center center">
                            <qrcode [qrdata]="qrData" [width]="256" [errorCorrectionLevel]="'M'" 
                            [allowEmptyString]="true"
                            ariaLabel='QR Code image with the following content...'
                            [cssClass]="'center'"
                            [colorLight]="'#ffffffff'"
                            [elementType]="'canvas'"                                                                                  
                            #parent                      
                            ></qrcode>
                            <app-button color="gray" type="icon" 
                            [icon_fig]="Icons.DOWNLOAD" matTooltip="Descargar Qr"
                            matTooltipPosition="above" (click)="guardarQr(parent)"></app-button>
                        </div> 
                    </div>                
                </mat-card-content>
                <mat-card-actions div fxLayout="row" [fxLayoutAlign]="modoOperacion==SistOperation.VER? 'end center':'space-between center'" >
                    <app-button [width]="100" text="Guardar" 
                       *ngIf="modoOperacion !=SistOperation.VER" [attr_disabled]="(f.invalid)"  [attr_type]="'submit'"></app-button>
                    <app-button fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray" *ngIf="modoOperacion !=SistOperation.VER"
                        (click)="cancelarCarta()"></app-button>
                    <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CANCEL" *ngIf="modoOperacion !=SistOperation.VER"
                        (click)="cancelarCarta()"></app-button>
                    <app-button type="icon" color="gray" [icon_fig]="Icons.EDIT" (click)="editarCarta()" matTooltip="Editar" *ngIf="modoOperacion ==SistOperation.VER" 
                        matTooltipPosition="above"></app-button>                
                </mat-card-actions>
            </mat-card>
        </form>        
    </div> 
</div>