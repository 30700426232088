<mat-drawer-container *ngIf="!isLoading"  class="container" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="over">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title-catalogos" >CATÁLOGOS</span>
            <button mat-icon-button  (click)="drawer.toggle()">
                <mat-icon>{{Icons.CLOSE}}</mat-icon>
            </button>
        </div>
        <mat-nav-list>
            <mat-list-item *ngFor="let catalogo of carta.lista_catalogo; let j=index;"  [routerLinkActive]="['is-active']" (click)="onClose(j); drawer.toggle()">
                <div  fxLayout="column">
                    <span>{{catalogo.nombre_catalogo}}</span>
                    <span class="total-productos">{{catalogo.lista_productos.length}}&nbsp; Productos</span>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </mat-drawer>
    <div class="sidenav-content">
        <div  fxLayout="column">

            <!-- toolbar -->
            <mat-toolbar class="mat-elevation-z4">
                <mat-toolbar-row>
                    <button mat-icon-button class="example-icon" (click)="drawer.toggle()">
                        <mat-icon>menu</mat-icon>
                    </button>
                    <div style="margin-top: 8px; margin-left: 10px;" *ngIf="carta.logo_empresa" >
                        <img [ngClass.xs]="{'imagen-xs':true}" [ngClass.gt-xs]="{'imagen':true}"  [src]="carta.logo_empresa">         
                    </div>
                    <div style="margin-left: 20px;">                        
                        <span style="font-size: 16pt;" >{{carta.nombre_razon}}</span>
                    </div>

                    <div fxFlex fxLayout="row" fxLayoutAlign="end center" fxShow fxHide.xs>                       
                        <button mat-icon-button *ngIf="carta.telefono" (click)="abrirWathsAppLink()">
                            <mat-icon svgIcon="icon-wath"></mat-icon>
                        </button>
                        <button mat-icon-button  *ngIf="carta.ruta_instagram" (click)="abrirInstaLink()" >
                            <mat-icon svgIcon="icon-insta"></mat-icon>
                        </button>
                        <button mat-icon-button  *ngIf="carta.ruta_facebook" (click)="abrirFaceLink()" >
                            <mat-icon svgIcon="icon-face"></mat-icon>
                        </button>
                    </div>                                                        
                </mat-toolbar-row>
            </mat-toolbar>

            <!-- botones flotantes -->
            <div class="floating-button" fxShow.xs fxHide fxLayout="column">
                <button mat-icon-button *ngIf="carta.telefono" (click)="abrirWathsAppLink()"  >
                    <mat-icon svgIcon="icon-wath-black"></mat-icon>
                </button>
                <button mat-icon-button  *ngIf="carta.ruta_instagram" (click)="abrirInstaLink()" >
                    <mat-icon svgIcon="icon-insta-black"></mat-icon>
                </button>
                <button mat-icon-button  *ngIf="carta.ruta_facebook" (click)="abrirFaceLink()" >
                    <mat-icon svgIcon="icon-face-black"></mat-icon>
                </button>          
            </div>

            <!-- pestañas -->
            <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="selectedTabIndex">
                <mat-tab [label]="catalogo.nombre_catalogo +' ('+catalogo.lista_productos.length+')'" matBadge="6" *ngFor="let catalogo of carta.lista_catalogo; let i=index;" >
                    <!-- cards -->
                     <div class="scrollable">
                        <div  fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="space-around start" fxLayoutAlign.lt-sm="space-around center" fxLayout.lt-sm="column" style="margin-top: 10px;" >
                     
                            <mat-card *ngFor="let producto of catalogo.lista_productos; let z= index;"  [ngClass.xs]="{'card-producto-xs':true}" [ngClass.gt-xs]="{'card-producto':true}">
                                <mat-card-header>                              
                                  <mat-card-title>{{producto.nombre_producto}}</mat-card-title>                            
                                </mat-card-header>
                                <mat-card-subtitle>
                                    <span style="font-weight: bold;" >
                                        S/ {{producto.precio_unitario | number:'1.2-2'}}
                                    </span>
                                </mat-card-subtitle>
                                <mat-card-content>
                                    <div fxLayout="column" fxLayoutGap="20px" >
                                        <app-carousel [visibleEtiquetaFoto]="false" *ngIf="producto.lista_imagen_carrousel!=undefined && producto.lista_imagen_carrousel.length>=1" [slides]="producto.lista_imagen_carrousel" ></app-carousel>
                                        <div *ngIf="producto.lista_imagen_carrousel==undefined">
                                            <div class="no-image" fxLayout="column" fxLayoutAlign="center center" >
                                                <div>
                                                    <mat-icon>{{Icons.NO_PHOTOGRAPHY}}</mat-icon>
                                                </div>
                                                <div>
                                                    <h3>Sin imagen</h3>
                                                </div>
                                            </div>
                                        </div>                                        
                                        <div>
                                            <p>
                                               {{producto.descripcion_producto}}
                                            </p>      
                                        </div>
                                    </div>                                                                      
                                </mat-card-content>                                                        
                            </mat-card>
                        </div>                      
                     </div>                    
                </mat-tab>                                       
            </mat-tab-group>       
        </div>       
    </div>
</mat-drawer-container>
<div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="space-around center" style="height: 100%; width: 100%;">
    <app-spinner></app-spinner>
</div>