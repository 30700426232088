<div *ngIf="!tieneTurnoAperturado">
    <div *ngIf="isLoading" style="margin-top: 20px;">
        <app-spinner></app-spinner>
    </div>
    <div *ngIf="!isLoading" style="margin-left: 10px; margin-top: 10px;">
        <h4>No se aperturó un turno.</h4>
    </div>
</div>
<div fxLayout="column" *ngIf="tieneTurnoAperturado" style="overflow: hidden;">
    <mat-accordion displayMode="default" [multi]="true" [hideToggle]="false">
      <mat-expansion-panel [expanded]="isExpanded" (expandedChange)="panelExpandedChange($event)">
        <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
          <mat-panel-title>
            <mat-icon>{{Icons.SEARCH}}</mat-icon>
            <span class="titulo-accordeon">Búsqueda</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-buscar [busqueda]="buscar"></app-buscar>
        <mat-action-row>
        </mat-action-row>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
          <mat-panel-title>
            <mat-icon>{{Icons.TABLE_BAR}}</mat-icon>
            <span class="titulo-accordeon">Mesas</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-chip-list>
          <mat-chip style="cursor: pointer;" *ngFor="let mesa of listadoMesas" removable="false" selectable="true"
            (click)="seleccionarMesa(mesa)">{{mesa}}</mat-chip>
        </mat-chip-list>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="grupo-botones" >
      <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between center">
        <div fxLayoutGap="5px">
          <button mat-stroked-button matTooltip="Restablecer" class="restablecer" (click)="restablecerEstado()">
            <mat-icon class="mat-18">{{Icons.RESTORE}}</mat-icon>
            <span fxShow.gt-md fxHide.lt-md style="margin-left: 5px;"> Restablecer</span>
          </button>
        </div>
      </div>
    </div>
    <!-- imprimir comanda -->
    <app-imprimir-pedido 
    [pedidoImprimir]="pedidoImprimir" 
    [totalProductoImprimir]="totalProductoImprimir"
    [totalItemsImprimir]="totalItemsImprimir"
    [listaProductoPedido]="listaProductoPedido"
    [listaMesasImprimir]="listaMesasImprimir"
    [fechaHoy]="fechaHoy"
    [key]="tipoTabla"
   ></app-imprimir-pedido>
  <!-- <div id="comandaPedCerrado" class="imprimir">
    <section>
      <div class="logo-com">
        <img *ngIf="logoEmpresa" [src]="logoEmpresa" alt="Logotipo" height="80px">
      </div>
      <div *ngIf="empresa" class="empresa-com">
        <div>
          <h3>{{empresa.nombre_razon}}</h3>
        </div>
        <div>
          RUC&nbsp;{{empresa.ruc}}
        </div>
      </div>
    </section>
    <div>=======================================</div>
    <section>
      <div class="encabezado-com">
        <div class="columna-com">Usuario Pedido: {{pedidoImprimir.usuario_creacion | hideMail }}</div>
        <div class="columna-com" *ngIf="usuarioImprimir">Usuario Imp.: {{usuarioImprimir | hideMail }}</div>
        <div class="columna-com">Fecha:
          {{fechaHoy.dia}}/{{fechaHoy.mes}}/{{fechaHoy.anio}}
        </div>
        <div class="columna-com">Hora:
          {{fechaHoy.hora}}:{{fechaHoy.minuto}}:{{fechaHoy.segundo}}</div>
        <div>=======================================</div>
        <div class="mesas-com">
          <h3>Mesas:&nbsp;{{listaMesasImprimir}}</h3>
        </div>
        <div>=======================================</div>
      </div>
      <div class="items-com">
        <div>
          <span>N° de ítems:&nbsp;{{totalItemsImprimir}} </span>
        </div>
        <div><span>N° de productos:&nbsp;{{totalProductoImprimir}} </span></div>
      </div>
      <ul class="lista-com">

        <li *ngFor="let prod of listaProductoPedido" class="lista-com-ul">
          <div class="lista-com-elemento-todo">
            <div>
              {{prod.cantidad_producto}}
              &nbsp;
              {{prod.nombre_producto}}
              &nbsp;
              ({{prod.precio_unitario | number:'1.2-2'}})
            </div>
            <div class="precio-total">
              {{prod.precio_total | number:'1.2-2'}}
            </div>
          </div>

        </li>
      </ul>
      <div>=======================================</div>
      <div class="totales-com">
        <div>
          <div>Sub Total:&nbsp;S/&nbsp;{{pedidoImprimir.sub_total| number:'1.2-2' }}</div>
        </div>
        <div *ngIf="pedidoImprimir.servicio">
          <div>Servicio:&nbsp;S/&nbsp;{{pedidoImprimir.servicio| number:'1.2-2' }}</div>
        </div>
        <div>
          <div>Impuesto (IGV) :&nbsp;S/&nbsp;{{pedidoImprimir.igv| number:'1.2-2' }}</div>
        </div>
      </div>
      <div>=======================================</div>
      <div class="totales-item">
        <div>
          <h2>TOTAL:&nbsp;&nbsp;</h2>
        </div>
        <div class="montos">
          <h2> S/ {{pedidoImprimir.total| number:'1.2-2' }}</h2>
        </div>
      </div>
      <div>=======================================</div>
    </section>
  </div>   -->
    <div class="container-table" style="margin-top: 10px;" >
      <app-table [dataTable]="dataTable" [key]="tipoTabla" [showPagination]="false" [rowSelection]="false"></app-table>
    </div>
  </div>