<div fxLayout="row" fxLayoutGap="30px" fxLayoutGap.xs="10px" fxLayout.xs="column" style="width:100%" fxLayoutAlign="center start" >
    <div style="min-width:300px;" >
        <app-spinner *ngIf="isLoading"></app-spinner>
        <mat-card *ngIf="!isLoading">
            <mat-card-title>Gestionar Descuentos</mat-card-title>
            <mat-card-subtitle>% de Descuento</mat-card-subtitle>
            <mat-card-content>
                <div fxLayout="row" fxLayoutGap="10px" >
                    <mat-form-field style="width:250px" >
                        <mat-label>Cantidad</mat-label>
                        <input 
                            type="text" 
                            matInput 
                            placeholder="Cantidad" 
                            required 
                            minlength="1" 
                            maxlength="3"
                            name="cantidad" 
                            ngModel 
                            [(ngModel)]="txtDscto"
                            [pattern]="PatternsRegex.onlyDecimalIntegerUnsigned" 
                            #txtDsctoInput="ngModel" 
                            (keydown)="($event.keyCode < 48 || ($event.keyCode > 57 && $event.keyCode < 96) ||
                                $event.keyCode > 105) &&
                                [8, 9, 37, 38, 39, 40, 46].indexOf($event.keyCode) === -1 ? $event.preventDefault() : null"
                            [disabled]="modoOperacion ==SistOperation.VER">
                            <mat-error
                                *ngIf="txtDsctoInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                            <mat-error
                                *ngIf="txtDsctoInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
                            <mat-error
                                *ngIf="txtDsctoInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_5DIGITOS}}</mat-error>
                            <mat-error
                                *ngIf="txtDsctoInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_1DIGITOS}}</mat-error>
                    </mat-form-field>                
                </div>
                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field style="width:250px">
                        <mat-label>Motivo</mat-label>
                        <input 
                        type="text" 
                        matInput 
                        placeholder="motivo" 
                        required 
                        minlength="3" 
                        maxlength="50"
                        name="motivo" 
                        ngModel 
                        [(ngModel)]="txtMotivo"
                        [pattern]="PatternsRegex.onlyGoodCharsWithNumbersAndSpace" 
                        #txtMotivoInput="ngModel"      
                        [disabled]="modoOperacion ==SistOperation.VER">
                        <mat-error
                            *ngIf="txtMotivoInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                        <mat-error
                            *ngIf="txtMotivoInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_NUMEROS_CON_ESPACIO_INTERMEDIO}}</mat-error>
                        <mat-error
                            *ngIf="txtMotivoInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
                        <mat-error
                            *ngIf="txtMotivoInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>       
                    </mat-form-field>
                    <button mat-mini-fab color="primary" (click)="aniadirDscto()"  [disabled]="modoOperacion ==SistOperation.VER" >
                        <mat-icon>{{Icons.ADD}}</mat-icon>
                      </button>
                </div>           
                <mat-divider></mat-divider>
                <div style="margin-top: 10px;" >
                    <ng-container *ngIf="listaDscto.length==0">
                        <h4>No existen DESCUENTOS.</h4>
                    </ng-container>
                    <ng-container *ngIf="listaDscto.length>0">
                        <mat-chip-list *ngFor="let descto of listaDscto; let i=index">
                            <mat-chip [removable]="modoOperacion!=SistOperation.VER" (removed)="removerDscto(descto)">
                                {{descto.item}}% - {{descto.motivo}}
                                <button matChipRemove *ngIf="removable">
                                    <mat-icon>{{Icons.CANCEL}}</mat-icon>
                                </button>
                            </mat-chip>
                        </mat-chip-list>
                    </ng-container>              
                </div>                
            </mat-card-content>
            <mat-card-actions div fxLayout="row" [fxLayoutAlign]="modoOperacion==SistOperation.VER? 'end center':'space-between center'" >
                <app-button [width]="100" text="Guardar" 
                   *ngIf="modoOperacion !=SistOperation.VER && listaDscto.length>0" (click)="guardarDscto()" ></app-button>
                <app-button fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray" *ngIf="modoOperacion !=SistOperation.VER"
                    (click)="cancelarDscto()"></app-button>
                <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CANCEL" *ngIf="modoOperacion !=SistOperation.VER"
                    (click)="cancelarDscto()"></app-button>
                <app-button type="icon" color="gray" [icon_fig]="Icons.EDIT" (click)="editarDscto()" matTooltip="Editar" *ngIf="modoOperacion ==SistOperation.VER" 
                    matTooltipPosition="above"></app-button>                
            </mat-card-actions>
        </mat-card>
    </div>
    <div  style="min-width:300px;" >
        <app-spinner *ngIf="isLoadingIncidencia"></app-spinner>        
        <mat-card *ngIf="!isLoadingIncidencia">
            <mat-card-title>Gestionar Incidencias</mat-card-title>
            <mat-card-subtitle>Motivos de Incidencia</mat-card-subtitle>
            <mat-card-content>
                <div fxLayout="row" fxLayoutGap="10px" >
                    <mat-form-field style="width:250px" >
                        <mat-label>Motivo</mat-label>
                        <input 
                            type="text" 
                            matInput 
                            placeholder="Motivo" 
                            required 
                            minlength="3" 
                            maxlength="50"
                            name="motivo" 
                            ngModel 
                            [(ngModel)]="txtIncidencia"
                            [pattern]="PatternsRegex.onlyGoodCharsWithNumbersAndSpace" 
                            #txtIncidenciaInput="ngModel"                            
                            [disabled]="modoOperacionIncid ==SistOperation.VER">
                        <mat-error
                            *ngIf="txtIncidenciaInput.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
                        <mat-error
                            *ngIf="txtIncidenciaInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_NUMEROS_CON_ESPACIO_INTERMEDIO}}</mat-error>
                        <mat-error
                            *ngIf="txtIncidenciaInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_SMALL_MAX_50}}</mat-error>
                        <mat-error
                            *ngIf="txtIncidenciaInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
                    </mat-form-field>
                    <button mat-mini-fab color="primary" (click)="aniadirIncidencia()"  [disabled]="modoOperacionIncid ==SistOperation.VER" >
                        <mat-icon>{{Icons.ADD}}</mat-icon>
                      </button>
                </div>
                <mat-divider></mat-divider>
                <div style="margin-top: 10px;" >
                    <ng-container *ngIf="listaIncidencia.length==0">
                        <h4>No existen INCIDENCIAS.</h4>
                    </ng-container>
                    <ng-container *ngIf="listaIncidencia.length>0">
                        <mat-chip-list *ngFor="let incidencia of listaIncidencia; let i=index">
                            <mat-chip [removable]="modoOperacionIncid!=SistOperation.VER" (removed)="removerIncidencia(incidencia)">
                                {{incidencia.item}}
                                <button matChipRemove *ngIf="removable">
                                    <mat-icon>{{Icons.CANCEL}}</mat-icon>
                                </button>
                            </mat-chip>
                        </mat-chip-list>
                    </ng-container>              
                </div>   
            </mat-card-content>
            <mat-card-actions div fxLayout="row" [fxLayoutAlign]="modoOperacionIncid==SistOperation.VER? 'end center':'space-between center'" >
                <app-button [width]="100" text="Guardar" 
                   *ngIf="modoOperacionIncid !=SistOperation.VER && listaIncidencia.length>0" (click)="guardarIncidencia()" ></app-button>
                <app-button fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray" *ngIf="modoOperacionIncid !=SistOperation.VER"
                    (click)="cancelarIncidencia()"></app-button>
                <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CANCEL" *ngIf="modoOperacionIncid !=SistOperation.VER"
                    (click)="cancelarIncidencia()"></app-button>
                <app-button type="icon" color="gray" [icon_fig]="Icons.EDIT" (click)="editarIncidencia()" matTooltip="Editar" *ngIf="modoOperacionIncid ==SistOperation.VER" 
                    matTooltipPosition="above"></app-button>                
            </mat-card-actions>
        </mat-card>
    </div>

</div>