import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ITableEvento } from '../components/table/ITableEvento';

@Injectable({
    providedIn: 'root',
})

export class KeyboardService {
    private inputSource = new Subject<ITableEvento>();
    private keyPressedSource = new Subject<ITableEvento>();
    currentKeyPressed = this.keyPressedSource.asObservable();

    currentInput = this.inputSource.asObservable();

    constructor() { }

    changeInput(input: ITableEvento) {       
        this.inputSource.next(input);
    }
    keyPress(key: ITableEvento) {
        this.keyPressedSource.next(key);
      }
}