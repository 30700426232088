import { AuditoriaSet } from "src/app/shared/models/auditoria.models";
import { Catalogo } from "../../dialog/models/Catalogo";

export class CartaDigital implements AuditoriaSet{    
    es_borrado: boolean=false;
    es_vigente: boolean = false;
    fecha_creacion?: any;
    fecha_modificacion?: any;
    usuario_creacion?: any;
    usuario_modificacion?: any;
    ruta_parcial:string='';
    telefono?:string;
    ruta_instagram?:string;
    ruta_facebook?:string;
    tiene_qr:boolean=false;
    id_empresa:string='';
    nombre_razon:string='';
    direccion: string='';
    logo_empresa?: string;
    //
    id?:string;
    lista_catalogo?:Catalogo[]
}