import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { DatosPlataforma } from './shared/cons/common';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from './shared/helpers/decorators/AutoUnsubscribe';

@AutoUnsubscribe
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title:string =  DatosPlataforma.NOMBRE_PLATAFORMA;
  swUpdateSb!:Subscription;

  constructor(private swUpdate: SwUpdate) {}

  ngOnInit() {
    this.swUpdateSb = this.swUpdate.available.subscribe(() => {
      if (confirm('Tenemos una nueva versión disponible de '+ this.title.toUpperCase()+'. Por favor ACEPTA para actualizar.')) {
        window.location.reload();
      }
    });
  }
}
