import { Component, Inject, Input, OnInit } from '@angular/core';
import { Icons } from '../../cons/icons';
import { Historial } from './models/Historial';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SolicitarCambioType } from '../../types/solicitar-cambio-type';
import { EstadosProductoType } from '../../types/estado-producto-type';
import { EstadosPedidoType } from '../../types/estado-pedido-type';
import { ProcesoType } from '../../types/proceso-type';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss']
})
export class HistorialComponent implements OnInit {
  isLoading:boolean=false;
  readonly Icons: typeof Icons = Icons;
  readonly SolicitarCambioType: typeof SolicitarCambioType = SolicitarCambioType;
  readonly EstadosProductoType: typeof EstadosProductoType = EstadosProductoType;
  readonly EstadosPedidoType: typeof EstadosPedidoType = EstadosPedidoType;
  readonly ProcesoType: typeof ProcesoType = ProcesoType;
  
  listaHistorial:Historial[]=[];
  titulo:string='';
  startY: number = 0;
  endY: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef: MatDialogRef<HistorialComponent>,
  ) {
    this.listaHistorial = this.datoPasado.data;
    if(this.datoPasado.titulo){
      this.titulo = this.datoPasado.titulo;
    }
    

  }
  touchStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY;
  }

  touchMove(event: TouchEvent) {
    this.endY = event.touches[0].clientY;
  }

  touchEnd(event: TouchEvent) {
    if (this.startY > this.endY + 30) {      
      // Acción específica al deslizar hacia arriba
    } else if (this.startY < this.endY - 30) {      
      // Acción específica al deslizar hacia abajo
    }
  }

  ngOnInit(): void {
  }

  onClose(){
    this.dialogRef.close();

  }

}
