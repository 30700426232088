<div style="max-width: 400px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div mat-dialog-title class="dialog-title">Pedido:&nbsp;{{pedido.codigo_pedido}} 
            <button mat-icon-button matTooltip="Historial Pedido" (click)="mostrarHistorialPedido()">
                <mat-icon>{{Icons.EVENT_NOTE}}</mat-icon>
            </button>
        </div>
        <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"></app-button>
    </div>    
    <mat-divider></mat-divider>
    <mat-dialog-content
    (touchstart)="touchStart($event)"
    (touchmove)="touchMove($event)"
    (touchend)="touchEnd($event)"
    class="touchable-content-ver" 
    >
        <section *ngIf="!isLoading" >
            <div fxLayout="column">
                <div>Usuario: {{pedido.usuario_creacion | hideMail }}</div>
                <div>Fecha: {{pedido.fecha_key.anio}}/{{pedido.fecha_key.mes}}/{{pedido.fecha_key.dia}}</div>
                <div>Hora: {{pedido.fecha_key.hora}}:{{pedido.fecha_key.minuto}}:{{pedido.fecha_key.segundo}}</div>
                <div *ngIf="pedido.proceso===ProcesoType.TRASPASO_PRODUCTO_DESTINO" >Proceso: {{pedido.proceso}}</div>
                <div *ngIf="pedido.proceso===ProcesoType.TRASPASO_PRODUCTO_DESTINO" matTooltip="Código de Pedido Origen" >Código: ({{pedido.codigo_pedido_origen}})</div>

            </div>
            <div fxLayout="row" style="padding-top: 10px;">
                <div fxLayout="row" fxLayoutGap="10px">
                    <h3>Mesas:</h3>
                    <div>
                        <mat-chip-list fxLayout="row wrap" fxLayoutAlign="start start">
                            <ng-container *ngFor="let mesa of pedido.ambiente_mesa; let i=index">
                                <mat-chip>
                                    <span>{{mesa.mesa_numero}}</span>
                                    <div *ngIf="pedido.es_ambiente_mesa_derivado && i+1== pedido.ambiente_mesa.length ">
                                        -{{pedido.comentario_ambiente_mesa_derivado}}
                                    </div>
                                </mat-chip>                                
                            </ng-container>
                        </mat-chip-list>
                    </div>                    
                </div>
                <div>
                    <button mat-icon-button matTooltip="Historial Mesa" (click)="mostrarHistorialMesa()">
                      <mat-icon>{{Icons.EVENT_NOTE}}</mat-icon>
                    </button>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-top:10px; color: gray;">
                <div>
                    <span>N° de ítems:&nbsp;{{listaProductoPedido.length}} </span>
                    <button mat-icon-button matTooltip="Historial Productos" (click)="mostrarHistorialProductos()">
                        <mat-icon>{{Icons.EVENT_NOTE}}</mat-icon>
                    </button>
                
                </div>
                <div><span>N° de productos:&nbsp;{{totalProductos}} </span></div>
            </div>           
            
            <div fxLayoutGap="10px" fxLayout="column" style="margin-top: 10px;">
                <ul style="list-style-type: none; ">
                    <li *ngFor="let prod of listaProductoPedido" style="margin-top:10px;margin-left: -50px;">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div fxLayout="row" style="font-size: 10pt;" >
                                <div class="semaforo" [matTooltip]="prod.estado_producto_vigente.replace('_',' ').toUpperCase()" [ngClass]="{                                    
                                    'chip-pendiente': prod.estado_producto_vigente===EstadosProductoType.PENDIENTE,
                                    'chip-proceso': prod.estado_producto_vigente===EstadosProductoType.EN_PROCESO,
                                    'chip-terminado': prod.estado_producto_vigente===EstadosProductoType.TERMINADO,
                                    'chip-rechazado': prod.estado_producto_vigente===EstadosProductoType.RECHAZADO,
                                    'chip-anulado': prod.estado_producto_vigente===EstadosProductoType.ANULADO,
                                    'chip-pendiente-revision': prod.estado_producto_vigente===EstadosProductoType.PENDIENTE_REVISION}" >
                                </div>
                                <div style="margin-left: 10px;" >
                                    <span>{{prod.cantidad_producto}}</span>
                                    &nbsp;
                                </div>
                                <div *ngIf="prod.es_pagado!=undefined && prod.es_pagado" matBadge="P" [matTooltip]="prod.tiene_descuento?'PrePagado incluye(-'+prod.descuento?.porcentaje_descuento+'%)' :'PrePagado'">
                                    <span>{{prod.nombre_producto}}</span>
                                    
                                    &nbsp;
                                </div>
                                <div *ngIf="prod.es_pagado==undefined">
                                    <span>{{prod.nombre_producto}}</span>
                                    &nbsp;
                                </div>
                                <div>
                                    &nbsp;
                                    &nbsp;                                  
                                    <span>(P.U. {{prod.precio_unitario | number:'1.2-2'}})</span>
                                    &nbsp;
                                </div>
                                <div *ngIf="prod.tiene_descuento" style="margin-top: -5px;"                                        
                                [matTooltip]="prod.descuento!.motivo" >
                                    <mat-chip-list>
                                        <mat-chip [@blink]="blinkState"  class="chip-aprobado">-{{prod.descuento?.porcentaje_descuento}}%&nbsp;(x{{prod.descuento?.cantidad_producto}})</mat-chip>                                                
                                    </mat-chip-list>
                                </div>
                                <div *ngIf="prod.tiene_incidencia" style="margin-top: -5px;"                                        
                                [matTooltip]="prod.incidencia!.motivo" >
                                    <mat-chip-list>
                                        <mat-chip [@blink]="blinkState"  class="chip-rechazado">
                                            <mat-icon class="mat-18">{{Icons.INFO}}</mat-icon>
                                            &nbsp;x{{prod.incidencia!.cantidad_producto}}
                                        </mat-chip>                                                    
                                    </mat-chip-list>
                                </div>
                            </div>
                            <div class="precio-total"  >
                                {{prod.precio_total | number:'1.2-2'}}
                            </div>                           
                        </div>
                    </li>
                </ul>
                <mat-divider></mat-divider>
                <div fxLayout="column" fxLayoutAlign="space-between end" fxLayoutGap="10px" class="divider">
                    <div fxLayout="row">
                        <div class="etiquetas-montos">Sub Total:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div class="montos">S/ {{this.pedido.sub_total| number:'1.2-2' }}</div>
                    </div>
                    <div *ngIf="pedido.servicio_total" fxLayout="row" class="titulo" >                        
                        <div class="montos-subs">Servicio({{aspectosGenerales.tasa_servicio}}%):&nbsp;&nbsp;&nbsp;&nbsp;S/ {{this.pedido.servicio_subtotal| number:'1.2-2' }}</div>                        
                    </div>
                    <!-- <div *ngIf="pedido.servicio_total" fxLayout="row" class="titulo">                                                
                        <div class="montos-subs"> IGV Serv.({{aspectosGenerales.tasa_impuesto}}%):&nbsp;&nbsp;&nbsp;&nbsp;S/ {{this.pedido.servicio_igv| number:'1.2-2' }}</div>
                    </div> -->
                    <div fxLayout="row">                        
                        <div class="montos-subs"><span *ngIf="aspectosGenerales.incluir_servicio">Total</span>&nbsp;{{aspectosGenerales.sigla_impuesto}}({{aspectosGenerales.tasa_impuesto}}%):&nbsp;&nbsp;&nbsp;&nbsp;S/ {{this.pedido.igv| number:'1.2-2' }}</div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div fxLayout="row" class="divider" fxLayoutAlign="end center">
                    <div>
                      <h2>TOTAL:&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                    </div>
                    <div class="montos">
                      <h2> S/ {{this.pedido.total| number:'1.2-2' }}</h2>
                    </div>
                  </div>
            </div>
        </section>
        <section *ngIf="isLoading" fxLayout="column" fxLayoutAlign="end center">
            <app-spinner></app-spinner>
        </section>
    </mat-dialog-content>

</div>