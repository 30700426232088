import { Component, OnInit } from '@angular/core';
import { TableTypes, TamanioPagina, SistOperation, EventoPaginacion } from '../../cons/common';
import { Table } from '../table/table.model';
import { Icons } from '../../cons/icons';
import { TableService } from '../table/table.service';
import { UIService } from '../../services/ui.services';
import { DialogCatalogoBlService } from '../procesos/dialog/services/services.bl/dialog-catalogo.bl.service';
import { RegistroService } from '../../services/registro.service';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { Subscription } from 'rxjs';
import { ITableFiltroCatalogo } from '../procesos/dialog/models/ITableCatalogoFiltro';
import { Catalogo } from '../procesos/dialog/models/Catalogo';
import { BuscarService } from '../buscar/buscar.service';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { PaginationService } from '../pagination/pagination.service';
import { ITableEvento } from '../table/ITableEvento';
import { Producto } from '../procesos/dialog/models/Producto';

@Component({
  selector: 'app-listar-catalogo',
  templateUrl: './listar-catalogo.component.html',
  styleUrls: ['./listar-catalogo.component.scss']
})
@AutoUnsubscribe
export class ListarCatalogoComponent implements OnInit {
  tipoTabla:string = TableTypes.LISTAR_CATALOGO;
  readonly Icons: typeof Icons = Icons;
  dataTable:Table = new Table();
  listaCatalogoSb= new Subscription();
  paginacionCatalogoSb = new Subscription();
  rowSelectCatalogoSb = new Subscription();
  rowSelectCatalogoVerSb = new Subscription();
  catalogoSeleccionado = new Catalogo(); // guarda el catalogo que se selecciona
  isLoading:boolean = false;

  constructor(
    private uiService: UIService,
    private tableService: TableService,
    private catalogoBLService: DialogCatalogoBlService,
    private registroService: RegistroService,
    private spinnerPageService: SpinnerPageService,
    private buscarService: BuscarService,
    private paginacionService: PaginationService,
  ) {
        //definimos las propiedades de la tabla
        this.dataTable.modoXs = ['numeracion', 'nombre_catalogo', 'codigo_catalogo', 'operaciones'];
        this.dataTable.modoM = ['numeracion', 'nombre_catalogo', 'estado', 'operaciones'];
        this.dataTable.modoLg = ['numeracion', 'nombre_catalogo', 'codigo_catalogo', 'total_productos', 'estado', 'operaciones'];
        this.dataTable.columnaEstado = 'estado';
        this.dataTable.columnaImagen = '';
        this.dataTable.columnaEstiloEstado = 'estado';
        this.dataTable.pageSizeOptions = TamanioPagina.LISTA_TAMANIOS
        this.dataTable.pageSize =  TamanioPagina.LISTA_TAMANIOS[1];// tamaño de pagina
        this.dataTable.currentPage = 1; //pagina inicial
        this.dataTable.nextPage = null;
        this.dataTable.previousPage = null;
        this.dataTable.columnaChip = undefined;
        this.dataTable.operaciones = [SistOperation.VER];
        this.dataTable.filtro[0] = null; //filtro nombres
        this.dataTable.filtro[1] = null; //filtro codigo
        this.dataTable.filtro[2] = true; // solo catalogos activos
        this.dataTable.minPage = null;//cambia ordenamiendo descendente
        this.dataTable.maxPage = null;//cambia ordenamiendo ascendente
  }

  ngOnInit(): void {
    //this.spinnerPageService.show(); //se cierra cuando carga la tabla
    //this.isLoading = true;
    if (this.registroService.empresa) {
      this.generarTabla();
    }

     //paginacion de tabla
    this.paginacionCatalogoSb = this.paginacionService.btnPagination$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        switch (callEvento.data.opcion) {
          case EventoPaginacion.PAGINA_PRIMERA: {
            this.dataTable.minPage = true
            this.dataTable.maxPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
            break;
          }
          case EventoPaginacion.PAGINA_ANTERIOR: {
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = true;
            this.dataTable.currentPage = this.dataTable.currentPage - 1;
            this.dataTable.maxPage = null;
            this.dataTable.minPage = null;
            break;
          }
          case EventoPaginacion.PAGINA_SIGUIENTE: {
            this.dataTable.nextPage = true;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = this.dataTable.currentPage + 1;
            this.dataTable.maxPage = null;
            this.dataTable.minPage = null;
            break;
          }
          case EventoPaginacion.PAGINA_ULTIMA: {
            this.dataTable.maxPage = true
            this.dataTable.minPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
            break;
          }
          case EventoPaginacion.CAMBIO_TAMANIO_PAGINA: {
            this.dataTable.maxPage = null
            this.dataTable.minPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
            this.dataTable.pageSize = callEvento.data.tamanio;
            break;
          }
          default: {
            this.dataTable.maxPage = null
            this.dataTable.minPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
          }
        }
        this.generarTabla();

      }
    });

    this.rowSelectCatalogoVerSb =  this.tableService.btnVer$.subscribe((callEvento:ITableEvento)=>{
      if(callEvento.key == TableTypes.LISTAR_CATALOGO){
        this.catalogoSeleccionado = callEvento.data as Catalogo;        
      }

    });



    this.rowSelectCatalogoSb =  this.tableService.rowSelect$.subscribe((callEvento:ITableEvento)=>{
      if(callEvento.key == TableTypes.LISTAR_CATALOGO){
        this.catalogoSeleccionado = callEvento.data as Catalogo;
      }
      
    });


    //cuando se selecciona

  }

  generarTabla() {
    //para no almacenar los observables de paginas previas y solo escuchar lo quee sta en curso
    if (this.listaCatalogoSb) {
      this.listaCatalogoSb.unsubscribe();
    }
  

    this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: true });//loading tabla
    const tablaFiltro:ITableFiltroCatalogo = {
      itemsPerPage: this.dataTable.pageSize,
      currentPage: this.dataTable.currentPage,
      nombresFiltro: this.dataTable.filtro[0],
      codigoFiltro: this.dataTable.filtro[1],
      estadoFiltro: this.dataTable.filtro[2],
      nextPage: this.dataTable.nextPage,
      previousPage: this.dataTable.previousPage,
      minPage: this.dataTable.minPage,
      maxPage: this.dataTable.maxPage,
    }

    this.listaCatalogoSb = this.catalogoBLService.getCatalogoResumenCombinado(
      tablaFiltro////click boton fin
    ).subscribe((catalogos: Catalogo[]) => {
        if (catalogos[0].totalRegistros_query != undefined && catalogos[0].totalRegistros_query > 0) {
          this.dataTable.data = [...catalogos];
          //total de registros en la bd para mostrar paginacion
          this.dataTable.totalRows = catalogos[0].totalRegistros_query;
               
        }
        else {
          if(tablaFiltro.currentPage>1){
            this.dataTable.currentPage = tablaFiltro.currentPage-1;
            this.dataTable.nextPage = false;
            this.generarTabla();
          }
          else{
            this.dataTable.data = new Array();
            this.dataTable.totalRows = 0;
          }
          
        }
        this.tableService.loadDataTable$.next(this.tipoTabla);//refrescamos el objeto tabla
        this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: false });//loading tabla

        //para actualizar los productos del catalogo seleccionado ante cualquier cambio del catalogo
        if(this.catalogoSeleccionado.codigo_catalogo!=''){
          const cataActualizado = catalogos.filter(cat=>cat.codigo_catalogo==this.catalogoSeleccionado.codigo_catalogo);
          //lanzando actualización de fila
          if(cataActualizado.length>0){
            this.tableService.rowSelect$.next({key: TableTypes.LISTAR_CATALOGO, data: cataActualizado[0]});
          }          
        }       
        
        //this.spinnerPageService.hide();//cerrado del ngOnInit
      }, error => {
        console.error('Error en el Observable:', error);
      },
        () => {          
          //cuando se completa el observable
        }) ;
  }

}
