import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  Length_Database,
  Msjs_Validations,
  PatternsRegex,
  Msjs,
  MsjEstado,
  EstadoRegistro
} from 'src/app/shared/cons/common';
import dataUbigeo from '../../../cons/ubigeo-inei.json';
import dataDepartamento from '../../../cons/departamento-inei.json';
import * as Enumerable from 'linq-es2015';
import { FileTypes } from 'src/app/shared/types/file-types';
import { Icons } from 'src/app/shared/cons/icons';
import { Utils } from 'src/app/shared/helpers/utils'
import { UIService } from 'src/app/shared/services/ui.services';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { EmpresaBLService } from '../../services/services.bl/empresa.bl.service';
import { Subscription, forkJoin, timer,from } from 'rxjs';
import { UploadService } from '../../upload/upload.service';
import { PATH_API_FILES } from 'src/app/shared/cons/config';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { CEmpresa } from '../../dialog/models/cempresa.model';
import { AutoUnsubscribe } from "../../../helpers/decorators/AutoUnsubscribe";

import { Router } from "@angular/router";
import { StaticRoutes } from 'src/app/shared/cons/route.class';
import { delay, delayWhen, tap } from 'rxjs/operators';
import { IEmpresa } from '../../dialog/models/empresa.model';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})

@AutoUnsubscribe

export class EmpresaComponent implements OnInit {
   //form
  disabled_controls:boolean=false;
  lDepartamento: any[] = new Array<any>();
  lProvincia: any[] = new Array<any>();
  lDistrito: any[] = new Array<any>();
  lUbigeo: any[] = new Array<any>();
  readonly Icons: typeof Icons = Icons;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  readonly Msjs: typeof Msjs = Msjs;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Length_Database: typeof Length_Database = Length_Database;
  onlyNumbers = Utils.InputOnlyNumbers;
  maxDate = new Date();
  minDate = new Date();
  toogleEnabled:boolean=false;
  fileType:string = FileTypes.IMAGE;
  label:string='Logo :';
  files_upload_subs!:Subscription;
  files_upload:File[] = [];
  pathFileAPI = PATH_API_FILES.EMPRESA_IMAGE_URL;
  msjEstado:string= MsjEstado.INACTIVO;
  cempresa = new CEmpresa();
  esModoEdicion:boolean = false;
  files_uploaded:any[]=[];
  sbEmpresa!: Subscription;
  sbReloadEmpresa!: Subscription;
  sizeMaxHeightWidth:string='alto 200px - ancho 200px';
  modalSb!: Subscription;
  rucExiste:boolean = false;


  constructor(
    private uiService:UIService,
    private readonly spinnerPageService: SpinnerPageService,
    private empresaBlService: EmpresaBLService,
    private uploadService:UploadService,
    private registroService: RegistroService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.maxDate = new Date();
    this.minDate = new Date();
    this.minDate.setFullYear(1800);
    this.maxDate.setFullYear(this.maxDate.getFullYear());

    dataDepartamento.forEach((dep) => {
      this.lDepartamento.push(dep);
    });
    dataUbigeo.forEach((ubigeo) => {
      this.lUbigeo.push(ubigeo);
    });

    this.files_upload_subs = this.uploadService.files_upload$.subscribe((uploads)=>{
      this.files_upload = uploads.images_upload;
      //edita cuando eliminas una imagen en modo EDICION
      if(this.esModoEdicion){
        this.files_uploaded =  uploads.images_uploaded;
      }
    });




    //EDICION, guarda y vuelve a expandir
    //por defecto es edicion
    this.sbReloadEmpresa = this.uiService.reloadEmpresa$.subscribe(() => {
      //ENTRANDO A EDICION
      if ((this.registroService.estado == EstadoRegistro.REGISTRO_COMPLETO_ADMIN)
      ) {
        this.esModoEdicion = true;
        this.disabled_controls=false;
        this.cargarDatosEmpresa();
      }
      else {
        this.esModoEdicion = false;
        this.spinnerPageService.hide();
      }
    });

    if(!this.esModoEdicion){
      this.spinnerPageService.hide();
    }


  }

  async validarRUCUnico(f:NgForm){
    this.rucExiste = false;
    const rucInput = f.form.get('ruc');
    if(rucInput){
      const empresaRUC = await this.empresaBlService.validarRUCUnico(this.cempresa.ruc);
      if(!empresaRUC.empty){
        this.rucExiste = true;
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_RUC_EXISTE, 3000);
      }

    }

  }

  cargarDatosEmpresa(){
    this.cempresa.nombre_razon = this.registroService.empresa.nombre_razon;
    this.cempresa.ruc= this.registroService.empresa.ruc;
    this.cempresa.giro_negocio = this.registroService.empresa.giro_negocio;
    this.cempresa.correo_electronico = this.registroService.empresa.correo_electronico;
    this.cempresa.inicio_operacion = this.registroService.empresa.inicio_operacion;
    this.cempresa.telefono1 = this.registroService.empresa.telefono1;
    this.cempresa.telefono2 = this.registroService.empresa.telefono2;
    this.cempresa.es_vigente = this.registroService.empresa.es_vigente;
    if(this.registroService.empresa.es_vigente){
      this.msjEstado = MsjEstado.ACTIVO;
    }
    this.cempresa.codigo_departamento = this.registroService.empresa.codigo_departamento;
    this.departamentoSelect(this.cempresa.codigo_departamento);
    this.cempresa.codigo_provincia = this.registroService.empresa.codigo_provincia;
    this.provinciaSelect(this.cempresa.codigo_provincia);
    this.cempresa.codigo_distrito = this.registroService.empresa.codigo_distrito;
    this.cempresa.direccion = this.registroService.empresa.direccion;
    this.files_upload=[];
    //reiniciamos el componente upload, para ue no se quede pegado la ultima subida
    this.uploadService.removeAllFiles();
    this.files_uploaded = this.registroService.empresa.logo_empresa ==undefined?[]:Utils.ObjecToListToArray(this.registroService.empresa.logo_empresa);
  }



  onSubmit(f:NgForm){
    if(f.valid && !this.rucExiste){
      if(this.cempresa.inicio_operacion=='' || this.cempresa.inicio_operacion== undefined){
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs_Validations.MSJ_VAL_FECHA_MINIMO_INCORRECTA, 3000);          
        return;
      }
      this.modalSb= this.uiService.showModalQuestions({title:Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon:Icons.QUESTION_MARK}).subscribe(result=>{
        if(result){
          this.spinnerPageService.show();
          //nueva empresa
          if(!this.esModoEdicion){
            this.disabled_controls=true;
            const observables=[
              from(this.empresaBlService.insertEmpresa(f,this.files_upload, this.pathFileAPI, this.cempresa)),
            ]
            this.sbEmpresa = forkJoin(observables).pipe(
              tap(()=>{
                if(this.registroService.empresa){
                  this.registroService.estado = EstadoRegistro.REGISTRO_MODULO;//activa o desactiva los tabs de registro persona, empresa, modulos
                  this.registroService.nextStep$.next(EstadoRegistro.REGISTRO_MODULO);
                }
              }),delayWhen(()=>timer(100))).subscribe(()=>{

                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
              },(error)=>{
                this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);
              });
          }
          else{
            //edicion empresa
            this.disabled_controls=true;
            const observables = [
              from(this.empresaBlService.updateEmpresa(f, this.files_upload,this.files_uploaded, this.pathFileAPI, this.cempresa))
            ];
            this.sbEmpresa = forkJoin(observables).pipe(
              tap(()=>{
              this.uiService.isExpandedGEmpresa$.next(false);
              if(!f.value.estado){
                this.router.navigate(['/' + StaticRoutes.ADMINISTRADOR + '/' + StaticRoutes.PANEL_ADMINISTRADOR]);
              }
            }),delayWhen(() => timer(100))).subscribe(()=>{
              this.spinnerPageService.hide();
              this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);

            }, error=>{
              this.uiService.isExpandedGEmpresa$.next(false);
              this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_DESCONOCIDO, 3000);
              this.spinnerPageService.hide();
            });
          }
        }
      });
    }else{
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_RUC_EXISTE, 3000);
    }
  }

  onChange(event:any){
    if(event.checked){
      this.msjEstado = MsjEstado.ACTIVO
    }
    else{
      this.msjEstado = MsjEstado.INACTIVO
    }
  }

  departamentoSelect(codigoDepartamento: any) {
    this.cempresa.codigo_provincia = '';
    this.cempresa.codigo_distrito = '';
    this.lProvincia = [];
    this.lDistrito = [];
    codigoDepartamento = codigoDepartamento.substr(0, 2);
    this.lProvincia = Enumerable.asEnumerable(this.lUbigeo)
      .Where((prov) => prov.codDepartamento == codigoDepartamento)
      .Distinct((x) => x.codProvincia)
      .Select((x) => x)
      .ToArray();
  }

  provinciaSelect(codigo: any) {
    this.lDistrito = [];
    let codigoDepartamento = this.cempresa.codigo_departamento.substr(0, 2);
    this.lDistrito = Enumerable.asEnumerable(this.lUbigeo)
      .Where(
        (prov) =>
          prov.codProvincia == codigo &&
          prov.codDepartamento == codigoDepartamento
      )
      .Distinct((x) => x.codDistrito)
      .Select((x) => x)
      .ToArray();
  }

  Limpiar(f:NgForm){
    this.cempresa = new CEmpresa();
    if(this.files_upload.length>0){
      //comunica que debe quitarse todos los archivos
      this.uploadService.removeAllFiles();
    }
  }
















}
