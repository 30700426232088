import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { ItemPermanente, ItemSidenav } from '../../models/item.model';
import { UINavigateSidenavService } from '../../services/ui.navigate-sidenav.services';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { Subscription } from 'rxjs';
import { Icons } from '../../cons/icons';
import { StaticRoutes } from '../../cons/route.class';
import { RegistroService } from '../../services/registro.service';
import { Utils } from '../../helpers/utils';
import { CColaborador } from '../procesos/gestion-colaborador/models/ccolaborador.model';

@Component({
  selector: 'app-sidenav-list-admin',
  templateUrl: './sidenav-list-admin.component.html',
  styleUrls: ['./sidenav-list-admin.component.scss']
})

@AutoUnsubscribe

export class SidenavListAdminComponent implements OnInit {
  @Input() list:any=[];
  @Output() closeSidenav = new EventEmitter<void>()
  selectedRouteSubs!: Subscription;
  reloadOptionsMenuSubs!: Subscription;
  readonly Icons: typeof Icons = Icons;
  menu_permanente:ItemSidenav[]=[
    {
      active: false,
      icono: Icons.DASHBOARD,
      nombre: "Panel",
      link: StaticRoutes.PANEL_ADMINISTRADOR,
      visible: true,
      visible_text: false,
      orden:1,
      codigo:'001'
    },
    {
    active: false,
    icono: Icons.CASES,
    nombre: "Empresa",
    link: StaticRoutes.EMPRESA,
    visible: true,
    visible_text: false,
    orden:2,
    codigo:'002'
  }
]
modulos_distintos:string[]=[];
esAdministrador:boolean=false;
loadUsuarioSb!:Subscription


  constructor(
    private uiNavigateSide: UINavigateSidenavService,
    private registroService: RegistroService,
    private ngZone: NgZone

    ) { }

  ngOnInit(): void {
    //cuando carga
    this.diferenciarEsAdministrador();

    //sub menu de opciones
    this.selectedRouteSubs = this.uiNavigateSide.goOptionSidenav$.subscribe(item => {
      //items permanentes
      this.menu_permanente.forEach((itemPerma: ItemSidenav) => itemPerma.active = false);
      this.menu_permanente.filter((itemPerma: ItemSidenav) => {
        itemPerma.active = itemPerma.nombre == item.nombre ?? true;
      });

      //items de los modulos
      this.list.forEach((item_anterior: ItemSidenav) => item_anterior.active = false);
      this.list.filter((elemento: ItemSidenav) => {
        elemento.active = elemento.nombre == item.nombre ?? true;
      });
    });

    //lanzado de layout admin
    this.reloadOptionsMenuSubs = this.uiNavigateSide.reloadOptionsMenu$.subscribe((listaItemSidenav) => {
      this.diferenciarEsAdministrador();

      this.modulos_distintos = [];
      if (listaItemSidenav) {
        this.list = Utils.ObjecListToArrayMenu(listaItemSidenav);
      }
      this.extraerModulos();
    });
  }

  diferenciarEsAdministrador(){
    //para determinar superadministrador
    if( this.registroService.usuario &&  this.registroService.esSuperAdministrador==true){    
      this.esAdministrador = true;     
    }
  }

  extraerModulos(){
    this.list.forEach((element: {
      active: boolean;
      icono: string;
      link: string;
      nombre: string;
      visible: boolean;
      modulo: string;
      codigo:string;
     }) => {
      if (this.modulos_distintos.indexOf(element.modulo) === -1) {
        this.modulos_distintos.push(element.modulo);
      }
      this.menu_permanente[0].active=true;
    });
  }


  onClose(opcion:ItemSidenav){
    this.closeSidenav.emit();
    this.uiNavigateSide.goOptionSidenavItem=opcion;

    //setea si es administrador para el modulo seleccionado 
    this.registroService.esAdministrador = Utils.esAdministrador(
      this.registroService.usuario, 
      this.registroService.esSuperAdministrador,
      this.registroService.rolesModulosUsuarioColaborador,
      opcion);

    //console.log(opcion)
    
    this.uiNavigateSide.goOptionSidenav$.next(opcion); 
  }

}
