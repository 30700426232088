<div fxLayout="row" fxLayoutAlign="center center">
  <mat-card fxFlex="80%" fxFlex.xs="100%">
    <mat-card-header>
      <mat-card-title>Tasas de Impuestos y Servicio</mat-card-title>
      <mat-card-subtitle>Configuración de tasas: </mat-card-subtitle>
    </mat-card-header>
    <mat-divider></mat-divider>
    <form #f="ngForm" (ngSubmit)="onSubmit(f)">
      <section *ngIf="!isLoading">
        <mat-card-content fxLayout="column" fxLayoutAlign="center center" style="margin-top: 20px;" fxLayoutGap="10px">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <h4>Impuesto: {{aspectosGenerales.sigla_impuesto}}</h4>
            <h4>Tasa de impuesto: {{aspectosGenerales.tasa_impuesto}}%</h4>                                  
          </div>          
          <mat-chip-list *ngIf="!existeConfig" >
            <mat-chip class="chip-asignar" [@blink]="blinkState">Sin asignar</mat-chip>
          </mat-chip-list>
          <div>
            <mat-divider></mat-divider>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">

            <mat-form-field fxFlex='50%' fxFlex.xs="100%">
              <mat-label>Incluir Tasa de Servicio</mat-label>
              <mat-select (selectionChange)="changeTipoProducto($event.value)" required name="incluyeServicio"
                [(ngModel)]="aspectosGenerales.incluir_servicio" [(value)]="aspectosGenerales.incluir_servicio"
                [disabled]="!esEditar" #incluyeServicio="ngModel">
                <mat-option [value]="true">Si</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
              <mat-error
                *ngIf="incluyeServicio.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex='50%' fxFlex.xs="100%" *ngIf="aspectosGenerales.incluir_servicio" >
              <input type="text" matInput placeholder="Tasa de servicio" required name="tasaServicio" ngModel
                [(ngModel)]="aspectosGenerales.tasa_servicio" [pattern]="PatternsRegex.onlyIntegerUnsigned"
                #tasaServicio="ngModel" [disabled]="!esEditar" (focus)="seleccionarTodo($event)">
              <span matSuffix>%</span>
              <mat-error *ngIf="tasaServicio.hasError('required')">{{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}</mat-error>
              <mat-error *ngIf="tasaServicio.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_NUMEROS}}</mat-error>
            </mat-form-field>
            <mat-icon [matTooltip]="Msjs_Validations.MSJ_SERVICIO">{{Icons.INFO}}</mat-icon>
          </div>
        </mat-card-content>
        <mat-card-actions *ngIf="!esEditar" fxLayout="row" fxLayoutAlign="end center" [ngStyle.lt-md]="{}"
          [ngStyle.gt-md]="{'margin-left': '35%', 'margin-right': '35%'}">
          <app-button type="icon" color="gray" [icon_fig]="Icons.EDIT" (click)="editarTasas()" matTooltip="Editar"
            matTooltipPosition="above"></app-button>
        </mat-card-actions>
        <mat-card-actions fxLayout="row" fxLayoutAlign="space-around center" *ngIf="esEditar" [ngStyle.lt-md]="{}"
          [ngStyle.gt-md]="{'margin-left': '35%', 'margin-right': '35%'}">
          <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%" [attr_disabled]="(f.invalid)"
            [attr_type]="'submit'"></app-button>
          <app-button fxHide.xs type="outline" [width]="100" text="Cancelar" color="gray"
            (click)="Limpiar(f)"></app-button>
          <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CANCEL"
            (click)="Limpiar(f)"></app-button>
        </mat-card-actions>
      </section>
    </form>
    <section *ngIf="isLoading">
      <app-spinner></app-spinner>
    </section>
  </mat-card>
</div>
