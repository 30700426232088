import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Icons } from 'src/app/shared/cons/icons';
import { Pedido } from '../../registrar-pedido/models/Pedido';

@Component({
  selector: 'app-emision-comprobante-electronico',
  templateUrl: './emision-comprobante-electronico.component.html',
  styleUrls: ['./emision-comprobante-electronico.component.scss']
})
export class EmisionComprobanteElectronicoComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  pedido = new Pedido();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef:MatDialogRef<EmisionComprobanteElectronicoComponent>,
  ) { }

  ngOnInit(): void {
  }

  onClose(){
    this.dialogRef.close({pedido:this.pedido});
  }

}
