<div style="max-width: 300px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div mat-dialog-title class="dialog-title">Personalizar Producto</div>
        <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"
            [attr_disabled]="isLoading"></app-button>
    </div>
    <mat-divider></mat-divider>
    <mat-dialog-content style="min-width: 230px; margin-top: 20px;">
        <div>
          <span>Producto:&nbsp;{{productoCombo.nombre_producto}}</span>
        </div>
        <section>
            <form *ngIf="miFormulario" [formGroup]="miFormulario" (ngSubmit)="onSubmit()">
                <mat-dialog-content style="min-width: 250px; margin-top: 20px;">
                  <section>
                    <div formArrayName="subProductos" fxLayout="column" fxLayoutGap="15px">
                      <ng-container *ngFor="let subProducto of subProductosFormArray.controls; let i=index" [formGroupName]="i">
                        <div fxLayout="row" fxLayout.xs="column">
                          <div fxLayout="row">
                            <mat-label>(1)&nbsp;{{subProductosCombo[i].producto.nombre_producto}}</mat-label>
                            <span *ngIf="!subProductosCombo[i].producto.tiene_caracteristicas"> &nbsp;(No requiere.)</span>
                          </div>                          
                          <mat-form-field *ngIf="subProductosCombo[i].producto.tiene_caracteristicas">
                            <mat-select required formControlName="caracteristica">
                              <mat-option *ngFor="let car of subProductosCombo[i].producto.lista_caracteristicas" [value]="car">
                                {{ car }}
                              </mat-option>
                            </mat-select>
                            <mat-error *ngIf="subProductosFormArray.at(i).get('caracteristica')?.hasError('required')">
                              {{Msjs_Validations.MSJ_VAL_CAMPO_VACIO}}
                            </mat-error>                          
                          </mat-form-field>                          
                        </div>
                      </ng-container>
                    </div>
                  </section>
                </mat-dialog-content>
                <!-- Botones de acción -->
                <div style="margin-top: 10px;" >
                  <mat-divider></mat-divider>
                </div>                
                <mat-card-actions fxLayout="row" fxLayoutAlign="space-around center" style="margin-top: 20px;">
                    <app-button [width]="100" text="Guardar" fxFlex="70%" fxFlex.xs="60%"
                      [attr_type]="'submit'" [attr_disabled]="!this.miFormulario.get('subProductos')?.valid"></app-button>
                    <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" color="gray"
                      (click)="Limpiar()"></app-button>
                    <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING"
                      (click)="Limpiar()"></app-button>
                  </mat-card-actions>
              </form>
        </section>
    </mat-dialog-content>
</div>