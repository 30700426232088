import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { StaticRoutes } from 'src/app/shared/cons/route.class';
import { Icons } from 'src/app/shared/cons/icons';
import { Observable } from 'rxjs';
import { ItemSidenav } from 'src/app/shared/models/item.model';
import { UINavigateSidenavService } from 'src/app/shared/services/ui.navigate-sidenav.services';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-titulo-modulo',
  templateUrl: './titulo-modulo.component.html',
  styleUrls: ['./titulo-modulo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class TituloModuloComponent implements OnInit {
  titulo:string = '';
  readonly Icons: typeof Icons = Icons;
  item$!:Observable<ItemSidenav>;
  itemFirts!:string | null;
  iconoFirst!:string | null;

  constructor(
    private location: Location,
    private uiNavigateSide: UINavigateSidenavService,
  ) { }

  ngOnInit(): void {
    const ruta:string | null  = this.extraerCadena();
    const modulo:string = 'Modulo: ';
    switch (ruta) {
      case StaticRoutes.RESTAURANT:{
        this.titulo = 'Gestión de Restaurante';
        break;
      }
      case StaticRoutes.EMPRESA:{
        this.titulo = 'Gestión de Empresa';
        break;
      }
      case StaticRoutes.CRM:{
        this.titulo = 'Gestión de Clientes y CRM';
        break;
      }
      case StaticRoutes.PANEL_ADMINISTRADOR:{
        this.titulo = 'Panel de Control';
        break;
      }
      default:{
        this.titulo = '';
      }
    }
    //forzando la inicializacion con un valor
    if(this.uiNavigateSide.goOptionSidenavItem){
      this.itemFirts =  this.uiNavigateSide.goOptionSidenavItem.nombre;
      this.iconoFirst =  this.uiNavigateSide.goOptionSidenavItem.icono;
    }
    
    //recibiendo el nombre del item seleccionado
    this.item$ = this.uiNavigateSide.goOptionSidenav$.pipe(
      tap(item => {        
        this.itemFirts = null;
        this.iconoFirst = null;
        
      })
    );
  }
  extraerCadena(){
    const regex = /^\/[^\/]+\/([^\/]+)/;
    const match = regex.exec(this.location.path());

    if (match && match.length >= 2) {
      return match[1];
    }

    return null;
  }

}
