import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Length_Database, Msjs_Validations, PatternsRegex } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { Utils } from 'src/app/shared/helpers/utils';
import { GetApiDNIRUCService } from 'src/app/shared/services/get-api-dniruc.service';
import { IGetAPIRUC } from 'src/app/shared/services/models/IGetAPIRUC';
import { Pedido } from '../../registrar-pedido/models/Pedido';

@Component({
  selector: 'app-emision-factura',
  templateUrl: './emision-factura.component.html',
  styleUrls: ['./emision-factura.component.scss']
})

@AutoUnsubscribe

export class EmisionFacturaComponent implements OnInit {
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Icons: typeof Icons = Icons;
  readonly Msjs_Validations:typeof Msjs_Validations = Msjs_Validations;
  isLoading:boolean=false;
  getAPIEmpresaSub!:Subscription;
  esBuscado:boolean = false;
  rucResponse: IGetAPIRUC | null=null;
  onlyNumbers = Utils.InputOnlyNumbers;
  pedido = new Pedido();
  
  constructor(
    private getApiDNIRUCService: GetApiDNIRUCService,
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
  ) { }

  ngOnInit(): void {
    this.pedido = this.datoPasado.dato as Pedido;
  }

  buscarPorRUC(f:NgForm){
    if(f.valid){
      this.isLoading = true;
      this.getAPIEmpresaSub = this.getApiDNIRUCService.getRUC(f.value.ruc).subscribe(res=>{
        if(res.ruc && res.razonSocial!="" ){
          this.esBuscado = true;
          this.rucResponse = res as IGetAPIRUC;
          this.isLoading = false;
          console.log(res)           
        }else{
          this.esBuscado = true;
          this.rucResponse = null;
          this.isLoading = false; 
        }
        
      });
    }  
  }

  emitirFacturaAutomatico(){}

  
  onKeydown(event: KeyboardEvent, f:NgForm): void {
    if (event.key === 'Enter') {
      this.buscarPorRUC(f); // Llama al método que quieras ejecutar
    }
  }

  limpiarBusqueda(f:NgForm){
    this.esBuscado = true;
    this.rucResponse = null;
    this.isLoading = false; 
    f.reset();
  }

}
