<div style="max-width: 300px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div mat-dialog-title class="dialog-title">Añadir Características</div>
        <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"
            [attr_disabled]="isLoading"></app-button>
    </div>
    <mat-divider></mat-divider>
    <mat-dialog-content style="min-width: 250px; margin-top: 20px;">
        <section>
            <form #f="ngForm" (ngSubmit)="onSubmit(f)" fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutGap="15px">
                    <mat-form-field style="width: 100%;">
                        <input type="text" matInput 
                            ngModel name="caracteristica" [(ngModel)]="caracteristica"
                            placeholder="Característica" [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
                            [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
                            #caractInput="ngModel"
                            [pattern]="PatternsRegex.onlyGoodCharsWithNumbersAndSpace"
                            oninput="this.value = this.value.toUpperCase()">
                            <mat-error
                            *ngIf="caractInput.hasError('pattern')">{{Msjs_Validations.MSJ_VAL_SOLO_LETRAS_Y_NUMEROS}}</mat-error>
                          <mat-error
                            *ngIf="caractInput.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
                          <mat-error
                            *ngIf="caractInput.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
                    </mat-form-field>
                    <div fxHide.xs style="margin-bottom: 10px;">
                        <button mat-mini-fab color="primary" class="upload-btn" type="button"
                            (click)="AgregarCaracteristica()" matTooltip="Agregar Característica"
                            matTooltipPosition="before">
                            <mat-icon>{{Icons.ADD}}
                            </mat-icon>
                        </button>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10px" *ngIf="listaCaracteristicas.length>0">
                    <mat-label>Características</mat-label>
                    <mat-chip-list>
                        <ng-container *ngFor="let caract of listaCaracteristicas; let i=index">
                            <mat-chip removable="true" (removed)="removerCaracteristica(i)">{{caract}}
                                <button matChipRemove>
                                    <mat-icon>{{Icons.CANCEL}}</mat-icon>
                                </button>
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
    
                </div>
                <mat-divider style="margin-bottom: 20px; margin-top: 20px;"></mat-divider>
                <mat-card-actions>
                    <app-button [width]="100" text="Agregar" fxFlex="70%" fxFlex.xs="60%"
                        [attr_type]="'submit'" [attr_disabled]="listaCaracteristicas.length==0" ></app-button>
                    <app-button fxHide.xs type="outline" [width]="100" text="Limpiar" color="gray"
                        (click)="Limpiar(f)"></app-button>
                    <app-button fxShow.xs fxHide type="outline" color="gray-b0" [icon_fig]="Icons.CLEANING"
                        (click)="Limpiar(f)"></app-button>
                </mat-card-actions>
            </form>
        </section>
    
    </mat-dialog-content>
</div>
