import { Component, Input, OnInit } from '@angular/core';
import { Icons } from '../../cons/icons';
import { IMenuItems } from '../../models/menu-items';
import { CONFIGURACION } from '../../cons/config';
import { UIService } from '../../services/ui.services';
import { Msjs } from '../../cons/common';
import { Utils } from '../../helpers/utils';
import { EmpresaBLService } from '../services/services.bl/empresa.bl.service';
import { RegistroService } from '../../services/registro.service';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { StaticRoutes } from '../../cons/route.class';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
@AutoUnsubscribe

export class AvatarComponent implements OnInit {
  private _iniciales: string | null = null;
  private inicialesSubject = new BehaviorSubject<string | null>(null);
  iniciales$ = this.inicialesSubject.asObservable();

  private colorSubject = new BehaviorSubject<string>('#FFFFFF');
  color$ = this.colorSubject.asObservable();


  color:string='#FFFFFF';

  readonly Icons: typeof Icons = Icons;
  dialogSb!: Subscription;

  @Input() pathImage!:string | null;
  @Input() set iniciales(value: string | null) {
    this._iniciales = value==null?'': value ;
    this.color = Utils.generateColor(this._iniciales);
    this.inicialesSubject.next(value);
    this.colorSubject.next(this.color);
  }

  @Input() tipoUsuario!:string | null;
  @Input() menuAvatarUsuario!:IMenuItems[];





  constructor(
    private uiServices: UIService,
    private empresaBlService: EmpresaBLService,
    private registroService: RegistroService,
    private spinnerPageService: SpinnerPageService,
    private authService:AuthService,
    private router: Router,
    ) {

  }

  ngOnInit(): void {
  }

  recargarAlActualizar() {
    //redirige al index siempre en cuando este registrado
    if (this.registroService.usuario.administrador
      && this.registroService.usuario.tiene_empresa
      && this.registroService.usuario.tiene_modulos) {
      this.router.navigate(['/' + StaticRoutes.ADMINISTRADOR + '/' + StaticRoutes.PANEL_ADMINISTRADOR]);
    }
    //cuando no es administrador
    if(!this.registroService.usuario.administrador){
      this.router.navigate(['/' + StaticRoutes.ADMINISTRADOR + '/' + StaticRoutes.PANEL_ADMINISTRADOR]);
    }
    /*Tambien se puede obtener la url del sidenav desde los modulos de la empresa y sus roles*/
  }

  onClick(opcion:number | undefined){
    //rol administrador
    if(this.tipoUsuario== CONFIGURACION.ROLES_PRIMARCAS[0].denominacion){
      switch (opcion) {
        case CONFIGURACION.OPCIONES_AVATAR_USUARIO_ADMINISTRADOR[0].id :{
          if(this.registroService.empresa.es_vigente){
            this.uiServices.showModalGestionarUsuario({});
          }else{
            this.uiServices.showModalAviso({
              title:Msjs.TITULO_SUSPENCION,
              message: Msjs.MENSAJE_CUENTA_SUSPENDIDA,
              icon:Icons.INFO,
            });          }

          break;
        }

        //SUSPENDER suscripcion
        case CONFIGURACION.OPCIONES_AVATAR_USUARIO_ADMINISTRADOR[1].id :{
          //pausar suscripcion
          const codigo = Utils.generarCodigo();
          this.dialogSb = this.uiServices.showModalQuestions({
            title:Msjs.TITULO_CONFIRMACION,
            message: Msjs.MENSAJE_CONFIRMACION_PAUSAR_SUBS,
            icon:Icons.QUESTION_MARK,
            tieneCodigo:true,
            codigo:codigo
          }).subscribe(result=>{
            if(result){
              this.spinnerPageService.show();
              //cambiamos las opciones del menu Activar suscripcion
              this.registroService.cambiarMenuSuscripcion$.next(false);
              this.empresaBlService.updateEmpresaEstado().then(()=>{
                this.spinnerPageService.hide();
                this.recargarAlActualizar();
                this.uiServices.showModalAviso({
                  title:Msjs.TITULO_SUSPENCION,
                  message: Msjs.MENSAJE_CUENTA_SUSPENDIDA,
                  icon:Icons.WARNING,
                });
              });
          }});
          break;
        }

        //ACTIVAR suscripcion
        case CONFIGURACION.OPCIONES_AVATAR_USUARIO_ADMINISTRADOR[2].id :{
          this.dialogSb = this.uiServices.showModalQuestions({
            title:Msjs.TITULO_CONFIRMACION,
            message: Msjs.MENSAJE_CONFIRMACION_ACTIVAR_SUBS,
            icon:Icons.QUESTION_MARK,
            tieneCodigo:false
          }).subscribe(result=>{
            if(result){
              this.spinnerPageService.show();
              //cambiamos las opciones del menu Desactivar suscripcion
              this.registroService.cambiarMenuSuscripcion$.next(true);
              this.empresaBlService.updateEmpresaEstado().then(()=>{
                this.spinnerPageService.hide();              
                this.uiServices.showModalAviso({
                  title:Msjs.TITULO_ACTIVACION,
                  message: Msjs.MENSAJE_CUENTA_ACTIVADA,
                  icon:Icons.INFO,
                });
              });
          }});
          break;
        }

        //ELIMINAR empresa
        case CONFIGURACION.OPCIONES_AVATAR_USUARIO_ADMINISTRADOR[3].id :{
          if(!this.registroService.empresa.es_vigente){
            this.uiServices.showModalAviso({
              title:Msjs.TITULO_SUSPENCION,
              message: Msjs.MENSAJE_CUENTA_SUSPENDIDA,
              icon:Icons.INFO,
            });
          }else{
            const codigo = Utils.generarCodigo();
            this.dialogSb = this.uiServices.showModalQuestions({
              title:Msjs.TITULO_CONFIRMACION,
              message: Msjs.MENSAJE_CONFIRMACION_ELIMINAR_SUBS,
              icon:Icons.QUESTION_MARK,
              tieneCodigo:true,
              codigo:codigo
            }).subscribe(result=>{
              if(result){
                this.spinnerPageService.show();
                //eliminamos la empresa logicamente y cerramos sesión para que vuelva a configurar
                this.empresaBlService.updateEmpresaEstadoEliminacion().then(()=>{
                  const administrador_user:boolean = this.registroService.usuario.administrador;
                  if(administrador_user){
                    this.spinnerPageService.hide();
                    this.uiServices.showModalAviso({
                      title:Msjs.TITULO_ELIMINACION,
                      message: Msjs.MENSAJE_EMPRESA_ELIMINADA,
                      icon:Icons.INFO,
                    });
                  }else{
                    this.spinnerPageService.hide();
                    this.uiServices.showModalAviso({
                      title:Msjs.TITULO_ELIMINACION,
                      message: Msjs.MENSAJE_EMPRESA_ELIMINADA_USUARIO,
                      icon:Icons.INFO,
                    });
                  }
                  this.authService.logout();
                });
            }});
          }
          break;
        }

        default:
          break;
      }
    }
    else{
      //rol operador
      if(this.tipoUsuario== CONFIGURACION.ROLES_PRIMARCAS[1].denominacion ){
        switch (opcion) {
          case CONFIGURACION.OPCIONES_AVATAR_USUARIO_OPERADOR[0].id :{
            break;
          }
          case CONFIGURACION.OPCIONES_AVATAR_USUARIO_OPERADOR[1].id :{
            break;
          }
          default:
            break;
        }
      }
    }


  }

}
