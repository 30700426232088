import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Auditoria, colections, documentsEmpresa } from 'src/app/shared/cons/db.colections';
import { Utils } from 'src/app/shared/helpers/utils';
import { deleteField, serverTimestamp } from 'firebase/firestore';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { IConfiguracionAmbiente } from '../../models/IConfiguracionAmbiente';
import { IMesaAmbiente } from 'src/app/shared/components/registrar-pedido/models/IMesaAmbiente';
import { EstadosMesaType } from 'src/app/shared/types/estado-mesa-type';
import { IAmbienteMesa } from '../../models/IAmbienteMesa';

@Injectable({
  providedIn: 'root'
})
export class GestionAmbientesDaService {
  transaccion: TransaccionModel = new TransaccionModel();

  private ambienteMesasCache: BehaviorSubject<IAmbienteMesa[] | undefined | null> = new BehaviorSubject<IAmbienteMesa[] | undefined |null>(undefined);
  public ambienteMesa$: Observable<IAmbienteMesa[] | undefined |null> = this.ambienteMesasCache.asObservable();
  private fetchSb!: Subscription;

  constructor(
    private db: AngularFirestore,
  ) { }


  fetchAmbienteMesa(idEmpresa:string): void {
    //si ya existe subscripcion emite el ultimo valor
    if(this.fetchSb && !this.fetchSb.closed){
      //this.ambienteMesasCache.next(this.ambienteMesasCache.value);
      return;
    }

    //si no existe subscripcion, carga una nueva subscripcion
    this.fetchSb = this.getConfiguracionAmbiente(idEmpresa).subscribe(ambienteMesas => {      
      this.ambienteMesasCache.next(ambienteMesas);
    });
  }

  stopFetchingAmbienteMesa(): void {
    if (this.fetchSb) {
      this.fetchSb.unsubscribe();
    }
    this.ambienteMesasCache.next(undefined);
  }

  getConfiguracionAmbiente(idEmpresa: string) {
    return this.db.collection(colections.EMPRESA, ref =>
      ref.where(Auditoria.es_borrado, '==', false))
      .doc(idEmpresa).collection(colections.AMBIENTE_MESA, ref1 =>
      ref1.where(Auditoria.es_vigente, '==', true))
      .snapshotChanges()
      .pipe(
        map(listaAmbMesa => {
          if (listaAmbMesa.length > 0) {
            // Reinicia el array dentro del pipe para asegurarte de que esté vacío en cada actualización
            let docConfigAmb: IAmbienteMesa[] = [];
            listaAmbMesa.forEach(amb_mesa => {
              const config = Utils.convertDate( amb_mesa.payload.doc.data()) as IAmbienteMesa;
              config.id = amb_mesa.payload.doc.id;
              docConfigAmb.push(config);
            });
            return docConfigAmb;
          } else {
            return null;
          }
        })
      );
  }
  /* solo mantener lo vigente en pedido, historial y cambios de estado en historial */
  

  insertConfiguracionAmbiente(idEmpresa:string, listaAmbientes:IAmbienteMesa[]){   
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(colections.AMBIENTE_MESA);
    //insertar las mesas en batch
      const batch = this.db.firestore.batch();
      listaAmbientes.forEach(doc=>{
        doc.fecha_creacion = serverTimestamp();
        const docRef = docConfigRef.doc().ref;
        batch.set(docRef, doc);

      });
      return  batch.commit().then(result=>{
        this.transaccion.tx = true; 
        return this.transaccion;           
      }).catch(error=>{
        this.transaccion.tx = false;
        this.transaccion.error = error;
        return this.transaccion;
      });
  }

  async updateConfiguracionAmbienteDisponible(idEmpresa:string){
    this.stopFetchingAmbienteMesa(); //detenemos el observable
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(colections.AMBIENTE_MESA);
    await docConfigRef.get().toPromise().then(docAmbMESA=>{
      const batch = this.db.firestore.batch();
      docAmbMESA.docs.forEach(doc => {
            batch.update(doc.ref,{mesa_estado:EstadosMesaType.DISPONIBLE});
        });
        batch.commit();
    }); 
  }

  async updateConfiguracionAmbiente(config:IAmbienteMesa[], idEmpresa:string,correoUsuario:string){
    this.stopFetchingAmbienteMesa(); //detenemos el observable
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
    const docConfigRef = docEmpRef.collection(colections.AMBIENTE_MESA);
    await docConfigRef.get().toPromise().then(docAmbMESA=>{
      const batch = this.db.firestore.batch();
      docAmbMESA.docs.forEach(doc => {
            batch.delete(doc.ref);
        });
        return batch.commit();
    });
    const batch1 = this.db.firestore.batch();
    config.forEach(doc=>{
      doc.fecha_creacion = serverTimestamp();
      const docRef = docConfigRef.doc().ref;
      batch1.set(docRef, doc);

    });
    return  batch1.commit().then(result=>{
      this.transaccion.tx = true;
      this.fetchAmbienteMesa(idEmpresa);//cacheamos el observable
      return this.transaccion;           
    }).catch(error=>{
      this.transaccion.tx = false;
      this.transaccion.error = error;
      return this.transaccion;
    });
  }

  async updateEstadoMesa(mesaAmbiente: IAmbienteMesa, idEmpresa: string, correoUsuario:string) {
    const docEmpRef = this.db.collection(colections.EMPRESA).doc(idEmpresa);
      const docConfigRef = docEmpRef.collection(colections.AMBIENTE_MESA);
      return docConfigRef.doc(mesaAmbiente.id).update({
        mesa_estado:mesaAmbiente.mesa_estado,
        fecha_modificacion:serverTimestamp(),
        usuario_modificacion:correoUsuario
      }).then(()=>{
        this.transaccion = {
          tx: true,
        };
      }).catch(error=>{
        this.transaccion = { tx: false, error: error };
        return this.transaccion;
      });
  }
}
