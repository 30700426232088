<div fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title >
    <mat-icon class="icon">{{Icons.INFO}}</mat-icon>
    {{titleModal}}
  </div>
</div>
<mat-divider></mat-divider>
<mat-dialog-content  class="dialog-content">
  <section  style="margin-top: 15px;">
    <h3>{{message}}</h3>
  </section>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Aceptar</button>
</mat-dialog-actions>
