import { Component, OnInit } from '@angular/core';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';
import { TableService } from '../../table/table.service';
import { PaginationService } from '../../pagination/pagination.service';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { BuscarService } from '../../buscar/buscar.service';
import { CriteriosBusqueda, EventoPaginacion, MsjEstado, Msjs, Msjs_Validations, PatternsRegex, SistOperation, TableTypes, TamanioPagina } from 'src/app/shared/cons/common';
import { PATH_API_FILES } from 'src/app/shared/cons/config';
import { Subscription } from 'rxjs';
import { Producto } from '../dialog/models/Producto';
import { Table } from '../../table/table.model';
import { CResumen } from '../../resumen/CResumen';
import { CBuscar } from '../../buscar/CBuscar';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { DialogProductoBlService } from '../dialog/services/services.bl/dialog-producto.bl.service';
import { ITableFiltroProducto } from '../dialog/models/ITableFiltroProducto';
import { ITableEvento } from '../../table/ITableEvento';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';

@Component({
  selector: 'app-gestion-producto',
  templateUrl: './gestion-producto.component.html',
  styleUrls: ['./gestion-producto.component.scss']
})

@AutoUnsubscribe
export class GestionProductoComponent implements OnInit {
  readonly EventoPaginacion: typeof EventoPaginacion = EventoPaginacion;
  readonly TamanioPagina: typeof TamanioPagina = TamanioPagina;
  readonly Icons: typeof Icons = Icons;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  esEditarActivarInactivar:boolean = false;// indica si el query tenia elementos en el tiempo t - 1;
  esEliminar:boolean = false;//indica que se eliminó un registro;
  pathFileAPI = PATH_API_FILES.PRODUCTO_IMAGE_URL;
  listaProductoSb!: Subscription;
  modalSb = new Subscription();
  resumenSb = new Subscription();

  listaProductos:Producto[] |null = [];
  dataTable:Table = new Table();

  btnEditarSb= new Subscription();
  btnEditarActivarProductoSb= new Subscription();
  btnEditarInactivarProductoSb= new Subscription();
  btnVerSb= new Subscription();
  btnVerImagenSb= new Subscription();
  btnEliminarSb= new Subscription();
  btnBuscarSb= new Subscription();
  btnLimpiarSb= new Subscription();
  paginacionProductoSb = new Subscription();

  tipoTabla:string = TableTypes.GESTIONAR_PRODUCTO;

  resumen:CResumen = new CResumen();
  buscar:CBuscar = new CBuscar();
  isExpanded:boolean = false;
  dialogRefSb= new Subscription();



  constructor(
    private uiService: UIService,
    private tableService: TableService,
    private paginacionService: PaginationService,
    private spinnerPageService: SpinnerPageService,
    private buscarService: BuscarService,
    private registroService: RegistroService,
    private DialogProductoBLService: DialogProductoBlService,
  ) {

    //definimos las propiedades de la tabla
    this.dataTable.modoXs = ['numeracion', 'nombre_producto', 'codigo_producto', 'operaciones'];
    this.dataTable.modoM = ['numeracion', 'nombre_producto', 'estado', 'total_stock','fecha_registro', 'operaciones'];
    this.dataTable.modoLg = ['numeracion', 'nombre_producto', 'codigo_producto', 'tiene_subproductos_label','total_stock', 'cantidad_imagenes', 'estado', 'fecha_registro', 'operaciones'];
    this.dataTable.columnaEstado = 'estado';
    this.dataTable.columnaImagen = 'imagen';
    this.dataTable.columnaEstiloEstado = 'estado';
    this.dataTable.pageSizeOptions = TamanioPagina.LISTA_TAMANIOS
    this.dataTable.pageSize =  TamanioPagina.LISTA_TAMANIOS[0];// tamaño de pagina
    this.dataTable.currentPage = 1; //pagina inicial
    this.dataTable.nextPage = null;
    this.dataTable.previousPage = null;
    this.dataTable.columnaChip = undefined;
    this.dataTable.operaciones = [SistOperation.EDITAR, SistOperation.ACTIVAR, SistOperation.SUSPENDER, SistOperation.VER, SistOperation.VER_IMAGEN, SistOperation.ELIMINAR];
    this.dataTable.filtro[0] = null; //filtro nombres
    this.dataTable.filtro[1] = null; //filtro codigo
    this.dataTable.filtro[2] = null; //filtro estado
    this.dataTable.minPage = null;//cambia ordenamiendo descendente
    this.dataTable.maxPage = null;//cambia ordenamiendo ascendente


  }

  ngOnInit(): void {

    this.cargarBusqueda();

    //observable que carga usuario colaborador vinculado con el objeto Tabla
    if (this.registroService.empresa) {
      this.generarTabla();
      this.cargarResumen();
    }

     //paginacion de tabla
     this.paginacionProductoSb = this.paginacionService.btnPagination$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        switch (callEvento.data.opcion) {
          case EventoPaginacion.PAGINA_PRIMERA: {
            this.dataTable.minPage = true
            this.dataTable.maxPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
            break;
          }
          case EventoPaginacion.PAGINA_ANTERIOR: {
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = true;
            this.dataTable.currentPage = this.dataTable.currentPage - 1;
            this.dataTable.maxPage = null;
            this.dataTable.minPage = null;
            break;
          }
          case EventoPaginacion.PAGINA_SIGUIENTE: {
            this.dataTable.nextPage = true;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = this.dataTable.currentPage + 1;
            this.dataTable.maxPage = null;
            this.dataTable.minPage = null;
            break;
          }
          case EventoPaginacion.PAGINA_ULTIMA: {
            this.dataTable.maxPage = true
            this.dataTable.minPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
            break;
          }
          case EventoPaginacion.CAMBIO_TAMANIO_PAGINA: {
            this.dataTable.maxPage = null
            this.dataTable.minPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
            this.dataTable.pageSize = callEvento.data.tamanio;
            break;
          }
          default: {
            this.dataTable.maxPage = null
            this.dataTable.minPage = null
            this.dataTable.nextPage = null;
            this.dataTable.previousPage = null;
            this.dataTable.currentPage = 1;
          }
        }
        this.generarTabla();

      }
    });


    //busqueda
    this.btnBuscarSb = this.buscarService.goBuscar$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        this.dataTable.filtro[0] = null; //filtro nombres
        this.dataTable.filtro[1] = null; //filtro nro documento
        this.dataTable.filtro[2] = null; //filtro estado
        this.dataTable.minPage = null;
        this.dataTable.maxPage = null;
        this.dataTable.nextPage = null;
        this.dataTable.previousPage = null;
        this.dataTable.currentPage = 1;
        this.dataTable.pageSize = TamanioPagina.LISTA_TAMANIOS[0];//tamaño de pagina
        const data: CBuscar = callEvento.data;

        switch (data.criterio_seleccionado) {
          case CriteriosBusqueda.nombres: {
            this.dataTable.filtro[0] = data.valor_criterio; //filtro nombres
            break;
          }
          case CriteriosBusqueda.codigo: {
            this.dataTable.filtro[1] = data.valor_criterio; //CODIGO PRODUCTO
            break;
          }
          case CriteriosBusqueda.estado: {
            this.dataTable.filtro[2] = data.estado_seleccionado == MsjEstado.ACTIVO ? true : false; //filtro estado
            break;
          }
          default: {
            this.dataTable.filtro[0] = null; //filtro nombres
            this.dataTable.filtro[1] = null; //codigo
            this.dataTable.filtro[2] = null; //filtro estado
          }
        }


        this.generarTabla();
      }
    });

    //limpiar
    this.btnLimpiarSb = this.buscarService.goLimpiar$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        this.reloadTableIndex();
      }
    });

    //boton Editar Colaborador
    this.btnEditarSb = this.tableService.btnEditar$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        //flag si previamente hay mas elementos
        if (this.dataTable.filtro.some((elemento) => elemento !== null)) {
          this.esEditarActivarInactivar = true;
        }
        //
        this.uiService.closeDialogProducto();
        this.uiService.showDialogProducto({ operacion: SistOperation.EDITAR, data:callEvento.data });
      }
    });

    this.btnEditarActivarProductoSb = this.tableService.btnActivar$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        this.uiService.closeModalQuestions();
        this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION_ACTIVAR_OTROS.replace('$','producto'), icon: Icons.QUESTION_MARK }).subscribe(result => {
          if (result) {
            this.spinnerPageService.show();
            //flag si previamente hay mas elementos
            if (this.dataTable.filtro.some((elemento) => elemento !== null)) {
              this.esEditarActivarInactivar = true;
            }
            //
            this.DialogProductoBLService.updateProductoEstado(callEvento.data, true).then(resultTx => {
              if (resultTx.tx) {
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.spinnerPageService.hide();

              } else {
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.ERROR, resultTx.error, 3000);
              }
            });
          }
        });
      }
    });

    this.btnEditarInactivarProductoSb = this.tableService.btnSuspender$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        this.uiService.closeModalQuestions();
        this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION_INACTIVAR_OTROS.replace('$','producto'), icon: Icons.QUESTION_MARK }).subscribe(result => {
          if (result) {
            this.spinnerPageService.show();
            //flag si previamente hay mas elementos
            if (this.dataTable.filtro.some((elemento) => elemento !== null)) {
              this.esEditarActivarInactivar = true;
            }
            //
            this.DialogProductoBLService.updateProductoEstado(callEvento.data, false).then(resultTx => {
              if (resultTx.tx) {
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.spinnerPageService.hide();

              } else {
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.ERROR, resultTx.error, 3000);
              }
            });
          }
        });
      }
    });

    this.btnVerSb = this.tableService.btnVer$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        this.uiService.closeDialogProducto();
        this.uiService.showDialogProducto({ operacion: SistOperation.VER, data: callEvento.data });
      }

    });

    this.btnEliminarSb = this.tableService.btnEliminar$.subscribe((callEvento: ITableEvento)=>{
      if (this.tipoTabla == callEvento.key) {
        this.uiService.closeModalQuestions();
        this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION_CONSECUENCIAS_ELIMINAR_PRODUCTO, icon: Icons.QUESTION_MARK }).subscribe(result => {
          if(result){
            this.spinnerPageService.show();
            this.DialogProductoBLService.deleteProducto(callEvento.data, this.pathFileAPI).then(resultTx=>{
              if (resultTx.tx) {
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.spinnerPageService.hide();
                //cuando se borra un elemento debe recargarse e iniciar en la primera página ya que el paginado ya cambió
                this.reloadTableIndex();

              } else {
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.ERROR, resultTx.error, 3000);
              }
            });
          }
        });
      }
    });

    this.btnVerImagenSb = this.tableService.btnVerImagen$.subscribe((callEvento:ITableEvento)=>{
      if (this.tipoTabla == callEvento.key) {
        if(callEvento.data.lista_imagen.length==0){
          this.uiService.ShowSnackBar(SnackBarType.WARNING, Msjs.MSJ_NO_TIENE_IMAGEN, 3000);
          return;
        }
        this.uiService.closeDialogVerImagenes();
        this.uiService.showDialogVerImagenes(callEvento)
      }
    });





  }

  cargarBusqueda() {
    this.buscar.lista_criterio = [CriteriosBusqueda.nombres, CriteriosBusqueda.codigo, CriteriosBusqueda.estado];
    this.buscar.key = this.tipoTabla;
    this.buscar.lista_estado = [MsjEstado.ACTIVO, MsjEstado.INACTIVO];
  }

  generarTabla() {
    //para no almacenar los observables de paginas previas y solo escuchar lo quee sta en curso
    if (this.listaProductoSb) {
      this.listaProductoSb.unsubscribe();
    }

    this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: true });//loading tabla
    const tablaFiltro:ITableFiltroProducto = {
      itemsPerPage: this.dataTable.pageSize,
      currentPage: this.dataTable.currentPage,
      nombresFiltro: this.dataTable.filtro[0],
      codigoFiltro: this.dataTable.filtro[1],
      estadoFiltro: this.dataTable.filtro[2],
      nextPage: this.dataTable.nextPage,
      previousPage: this.dataTable.previousPage,
      minPage: this.dataTable.minPage,
      maxPage: this.dataTable.maxPage,
    }

    this.listaProductoSb = this.DialogProductoBLService.getProductoResumenCombinado(
      tablaFiltro////click boton fin
    ).subscribe((productos: Producto[]) => {
        if (productos[0].totalRegistros_query != undefined && productos[0].totalRegistros_query > 0) {
          this.dataTable.data = [...productos];
          //total de registros en la bd para mostrar paginacion
          this.dataTable.totalRows = productos[0].totalRegistros_query;
        }
        else {
          if(tablaFiltro.currentPage>1){
            this.dataTable.currentPage = tablaFiltro.currentPage-1;
            this.dataTable.nextPage = false;
            this.generarTabla();
          }
          else{
            this.dataTable.data = new Array();
            this.dataTable.totalRows = 0;
          }
        }
        //reinicia la busqueda si edita filtrado o elimina
        if (this.esEditarActivarInactivar || this.esEliminar) {
          this.esEditarActivarInactivar = false;
          this.esEliminar = false;
          const callEvento: ITableEvento = {
            key: this.tipoTabla,
            data: this.simularBusqueda()
          }
          this.buscarService.goBuscar$.next(callEvento);
          return;
        }
        //para resumen
        this.resumen.valor1 = productos[0].totalRegistrosActivos? productos[0].totalRegistrosActivos:0;
        this.resumen.valor2 = productos[0].totalRegistrosInactivos? productos[0].totalRegistrosInactivos:0;

        this.tableService.loadDataTable$.next(this.tipoTabla);//refrescamos el objeto tabla
        this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: false });//loading tabla
      }, error => {
        console.error('Error en el Observable:', error);
      },
        () => {
          //cuando se completa el observable
        });
  }

  cargarResumen(){
    this.resumen.icono1 = Icons.TOGGLE_ON;
    this.resumen.icono2 = Icons.TOGGLE_OFF;
    this.resumen.texto1 = 'Productos activos';
    this.resumen.texto2 = 'Productos inactivos';
  }

  simularBusqueda(){
    const busquedaS:CBuscar = new CBuscar();
    busquedaS.criterio_seleccionado= this.dataTable.filtro[0] !== null ? CriteriosBusqueda.nombres
                : this.dataTable.filtro[1] !== null ? CriteriosBusqueda.codigo
                  : this.dataTable.filtro[2] !== null ? CriteriosBusqueda.estado : '';
    this.dataTable.filtro[0] !== null ? busquedaS.valor_criterio = this.dataTable.filtro[0]
    : this.dataTable.filtro[1] !== null ? busquedaS.valor_criterio = this.dataTable.filtro[1]
      : this.dataTable.filtro[2] !== null ? busquedaS.estado_seleccionado = this.dataTable.filtro[1]:'';

      return busquedaS;

  }

  btnRestablecer() {
    const callEvento: ITableEvento = {
      key: this.tipoTabla,
      data: null
    }
    this.buscarService.goLimpiarInput$.next(callEvento);
    this.isExpanded = !this.isExpanded;
  }

  panelExpandedChange(expanded: boolean) {
    this.isExpanded = expanded;
  }

  openModal(){
    const dialogRef = this.uiService.showDialogProducto({operacion: SistOperation.NUEVO,
      data:null
    });

    this.dialogRefSb = dialogRef.afterClosed().subscribe((resultado:any)=>{
      if(resultado){
        if(resultado.tx_reload && this.dataTable.currentPage>1){
          this.reloadTableIndex();
         }
      }
    });
  }

  reloadTableIndex(){
    this.dataTable.filtro[0] = null, //filtro nombres
    this.dataTable.filtro[1] = null, //filtro codigo
    this.dataTable.filtro[2] = null, //filtro estado
    this.dataTable.currentPage = 1;
    this.dataTable.nextPage = null;
    this.dataTable.previousPage = null;
    this.dataTable.minPage = null;
    this.dataTable.maxPage = null;
    this.dataTable.pageSize = TamanioPagina.LISTA_TAMANIOS[0];//tamaño de pagina
    this.generarTabla();
  }

  ngOnDestroy(){
    this.DialogProductoBLService.utilCleanFlags();
  }


}
