import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Icons } from 'src/app/shared/cons/icons';
import { UIService } from 'src/app/shared/services/ui.services';
import { GestionOperacionesBLService } from './services/gestion-operaciones.bl.service';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { ModoOperacion } from './models/ModoOperacion';
import { Msjs } from 'src/app/shared/cons/common';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-gestion-operaciones',
  templateUrl: './gestion-operaciones.component.html',
  styleUrls: ['./gestion-operaciones.component.scss']
})

@AutoUnsubscribe

export class GestionOperacionesComponent implements OnInit {
  isLoading:boolean = true;
  checked = false;
  disabled = false;
  esEditar:boolean = false;
  readonly Icons: typeof Icons = Icons;
  existeConfig:boolean = false;
  reloadModoOperacionSb = new Subscription();
  configSb = new Subscription();
  modoOp: ModoOperacion = new ModoOperacion();
  modalSb = new Subscription();

  constructor(
    private uiService: UIService,
    private readonly spinnerPageService: SpinnerPageService,
    private gestionOpBlService:GestionOperacionesBLService,
    private registroService:RegistroService
  ) { }

  ngOnInit(): void {
    this.reloadModoOperacionSb = this.uiService.reloadModoOperacion$.subscribe(()=>{
      this.cargarConfiguracion();
    });    
  }

  cargarConfiguracion(){
    this.isLoading = true;
    this.gestionOpBlService.existFetchDscto();
    if(this.configSb){
       this.configSb.unsubscribe();
    }
    this.configSb = this.gestionOpBlService.getModoOp()
    .pipe(filter(modoOp => modoOp !== undefined))
    .subscribe(modoOp=>{
        if(modoOp){
          this.isLoading = false;
          this.modoOp = modoOp;
          this.existeConfig=true;
        }
        else{
          this.isLoading=false;
        }
      });
  }

  changeModoOperacion(){
    this.modoOp.es_individualizado =!this.modoOp.es_individualizado;    
  }

  editarModoOperacion(){
    this.esEditar = true;
  }

  Limpiar(f:NgForm){
    if(this.existeConfig){
      this.cargarConfiguracion();
    }
    this.esEditar = false;
  }

  onSubmit(f:NgForm){
    if(!f.invalid){     
      this.modalSb= this.uiService.showModalQuestions({title:Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon:Icons.QUESTION_MARK})
      .subscribe(result=>{
        if(result){
          this.spinnerPageService.show();
          if(!this.existeConfig){
            this.gestionOpBlService.insertModoOp(this.modoOp).then(transac=>{
              if(transac.tx){
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.esEditar = false;
              }
              else{
                this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.error, 3000);
                this.spinnerPageService.hide();
              }
            });
          }
          else{
            this.gestionOpBlService.updateModoOp(this.modoOp).then(transac=>{
              if(transac.tx){
                this.spinnerPageService.hide();
                this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
                this.esEditar = false;
              }
              else{
                this.uiService.ShowSnackBar(SnackBarType.ERROR, transac.error, 3000);
                this.spinnerPageService.hide();
              }
            });
          }
        }
      });
    }
  }
}
