<ng-container *ngIf="mensajes | async as mensajesActual" >
  <div [matMenuTriggerFor]="mensajesActual.length>0? belowMenu:menuVacio" class="aviso" >
    <mat-icon [matBadge]="mensajesActual.length" [matBadgeColor]="mensajesActual.length==0?'primary':'warn'" >{{Icons.CHAT_BUBBLE}}</mat-icon>
  </div>
  <mat-menu #belowMenu="matMenu" yPosition="below">
    <button *ngFor="let item of mensajesActual" [matTooltip]="'Estado: '+item.estado_cambio | uppercase"  (click)="mostrarMsj(item)" mat-menu-item>{{item.proceso}}</button>    
  </mat-menu>
  <mat-menu #menuVacio="matMenu" yPosition="below">
    <button mat-menu-item>No se tienen mensajes</button>    
  </mat-menu>
</ng-container>


