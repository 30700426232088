<div fxLayout="row" fxLayoutAlign="space-between start">
    <div mat-dialog-title class="dialog-title">Buscar Catálogo</div>
    <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"></app-button>
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-content style="min-width: 250px; margin-top: 20px;">
   <section>
    <form #f="ngForm">
      <div fxLayout="column" fxLayoutGap="15px">
        <mat-form-field style="width: 100%;">
          <mat-label>Nombre de Catálogo</mat-label>
          <input  type="text"
          matInput
          [formControl]="txtComplete"
          [matAutocomplete]="auto"
          [maxlength]="Length_Database.DB_LENGTH_X_SMALL_MAX_30"
          [minlength]="Length_Database.DB_LENGTH_X_SMALL_MIN_3"
          [pattern]="PatternsRegex.onlyGoodCharsWithNumbersAndSpace"
          oninput="this.value = this.value.toUpperCase()"
          ngModel         
          [(ngModel)]="catalogoBuscar"
          >
          <mat-icon matSuffix>{{Icons.SEARCH}}</mat-icon>
          <mat-hint>Ingrese una palabra del nombre de catálogo</mat-hint>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.nombre_catalogo" (click)="onSelect(option)" >
              {{ option.nombre_catalogo}} | {{ option.codigo_catalogo}}
            </mat-option>
          </mat-autocomplete>          
        <mat-error
          *ngIf="txtComplete.hasError('maxlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MAX_30}}</mat-error>
        <mat-error
          *ngIf="txtComplete.hasError('minlength')">{{Msjs_Validations.MSJ_VAL_DB_LENGTH_X_SMALL_MIN_3}}</mat-error>
        </mat-form-field>     
      </div>    
    </form>
   </section>
  </mat-dialog-content>
  